import { useEffect, useState } from 'react';
import { useGetAssessmentUserLazyQuery, useGetCurrentUserQuery, UserRole } from '../graphql';
import { useAuth } from './useAuth';
import { ServerError } from '@apollo/client';
import { EMAIL_VERIFICATION_ERROR } from '../common/constants/application';
import { getSignInURL } from '../auth/authHelpers';
import { useNavigate } from 'react-router-dom';
import { AssessmentRoles } from '../common/constants/assessmentRoles';

export interface ApplicationUser {
    id?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    userRole?: UserRole;
    assessmentRoles?: AssessmentRoles[];
    groups?: string[];
    timezone?: string;
    vibeId?: string;
}

type AuthErrorMessageId = `errorPage.accessDenied.${string}`;

const NO_ASSESSMENT_USER_MESSAGE = 'Error DDB-0002 - Error could not find User with ID';

export const useUserInfo = () => {
    const [user, setUser] = useState<ApplicationUser>();
    const [authErrorMessageId, setAuthErrorMessageId] = useState<AuthErrorMessageId | undefined>(
        undefined,
    );
    const navigate = useNavigate();

    const { data, error, loading } = useGetCurrentUserQuery();
    const [
        getAssessmentUser,
        { data: assessmentUserData, error: assessmentUserError, loading: assessmentDataLoading },
    ] = useGetAssessmentUserLazyQuery();

    const { login } = useAuth();

    useEffect(() => {
        if ((error?.networkError as ServerError)?.statusCode === 403) {
            setAuthErrorMessageId('errorPage.accessDenied.403Error');
        } else if (
            error?.networkError?.message.includes(EMAIL_VERIFICATION_ERROR) ||
            error?.message?.includes(EMAIL_VERIFICATION_ERROR)
        ) {
            const message = error.networkError?.message.includes(EMAIL_VERIFICATION_ERROR)
                ? error.networkError.message
                : error.message;
            const parsedString = message.substring(message.indexOf('.') + 1, message.length);
            const parsedJson = JSON.parse(parsedString);
            window.location.href = getSignInURL({
                identity_provider: parsedJson.audiencePath,
                require_email_verification: 'true',
            });
        } else if (error) {
            const newPathname =
                window.location.pathname.charAt(0) === '/'
                    ? window.location.pathname.substring(1)
                    : window.location.pathname;
            localStorage.setItem('requestedURI', newPathname);
            login();
        }
    }, [data, error]);

    useEffect(() => {
        if (assessmentUserError?.message === NO_ASSESSMENT_USER_MESSAGE) {
            setAuthErrorMessageId('errorPage.accessDenied.403Error');
        } else if (data && !loading) {
            getAssessmentUser({
                variables: {
                    id: data.currentUser.gandalfDetails?.vibeId!,
                },
            });
        }
    }, [data, loading, assessmentUserError]);

    useEffect(() => {
        if (data && assessmentUserData) {
            const requestedURI = localStorage.getItem('requestedURI');
            if (requestedURI) {
                localStorage.removeItem('requestedURI');
                navigate(requestedURI);
            }
            setUser({
                id: data.currentUser.id,
                firstName: data.currentUser.firstName,
                lastName: data.currentUser.lastName,
                email: data.currentUser.emailAddress,
                userRole: data.currentUser.userRole,
                assessmentRoles: assessmentUserData.assessmentUser!.roles as AssessmentRoles[],
                vibeId: data.currentUser.gandalfDetails?.vibeId,
            });
        }
    }, [data, assessmentUserData]);

    return {
        user,
        loading,
        isAuthError: !!authErrorMessageId,
        authErrorMessageId,
        assessmentDataLoading,
    };
};
