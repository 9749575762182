// TODO: Remove this definition once we're able to import from aws-tc-assessments-common
export enum AssessmentRoles {
    ADMIN = 'ADMIN',
    ASSESSMENTS_MANAGER = 'ASSESSMENTS_MANAGER',
    READ_ONLY = 'READ_ONLY',
}

export const assessmentUserStatusConfig = [
    {
        label: 'Active',
        value: 'Active',
    },
    {
        label: 'Inactive',
        value: 'Inactive',
    },
];

export const AssessmentUserRolesConfig: Record<string, { label: string; value: string }> = {
    ADMIN: {
        label: 'Admin',
        value: 'ADMIN',
    },
    ASSESSMENTS_MANAGER: {
        label: 'Manager',
        value: 'ASSESSMENTS_MANAGER',
    },
    READ_ONLY: {
        label: 'Read Only',
        value: 'READ_ONLY',
    },
} as const;
