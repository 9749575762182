import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { AttributeEditor, FormField, Input, Select } from '@amzn/awsui-components-react';
import {
    CreateQuestionBankMutationVariables,
    useGetLearningObjectivesLazyQuery,
} from '../../graphql';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';

export interface LearningObjectiveAttributeEditorItem {
    id: string;
}

export interface LearningObjectiveAttributeEditorProps {
    handleFormValueChange: (formUpdates: Partial<CreateQuestionBankMutationVariables>) => void;
    errors: any;
    controlArrayErrors?: any;
    learningObjectiveAttributeEditorItems: LearningObjectiveAttributeEditorItem[];
    setLearningObjectiveAttributeEditorItems: Dispatch<
        SetStateAction<LearningObjectiveAttributeEditorItem[]>
    >;
    selectedPrograms?: string[];
}

export const LearningObjectiveAttributeEditorTestId = 'learning-objective-form-label';

const LearningObjectiveAttributeEditor = ({
    learningObjectiveAttributeEditorItems,
    setLearningObjectiveAttributeEditorItems,
    handleFormValueChange,
    controlArrayErrors,
    selectedPrograms,
}: LearningObjectiveAttributeEditorProps) => {
    const [learningObjectives, setLearningObjectives] = useState<OptionDefinition[]>([]);
    const [learningObjectiveDict, setLearningObjectiveDict] = useState({});
    /** TODO For now just getting all learning objectives. We will need to do a search query as
     * the learning objectives increase in number
     * **/
    const [getLearningObjectives, { data, loading }] = useGetLearningObjectivesLazyQuery();

    useEffect(() => {
        if (selectedPrograms && selectedPrograms?.length > 0) {
            handleGetLearningObjectives();
        }
    }, [data, loading, selectedPrograms]);

    const handleGetLearningObjectives = async () => {
        const { data } = await getLearningObjectives({
            variables: {
                status: 'Active',
                programs: selectedPrograms,
                size: 500,
            },
        });

        let learningObjectiveDict = {};
        data!.assessmentLearningObjectives.metadataObjects.forEach((objective) => {
            // Learning objective dictionary
            learningObjectiveDict = {
                ...learningObjectiveDict,
                [objective.id]: objective.name,
            };
        });

        const learningObjectives = data!.assessmentLearningObjectives.metadataObjects.map(
            (objective) => ({
                label: objective.name,
                value: objective.id,
            }),
        );

        setLearningObjectives(learningObjectives);
        setLearningObjectiveDict(learningObjectiveDict);
    };

    return (
        <FormField
            data-testid={LearningObjectiveAttributeEditorTestId}
            stretch
            label="Learning objectives"
        >
            <AttributeEditor
                onAddButtonClick={() =>
                    setLearningObjectiveAttributeEditorItems([
                        ...learningObjectiveAttributeEditorItems,
                        { id: '' },
                    ])
                }
                onRemoveButtonClick={({ detail: { itemIndex } }) => {
                    const tempItems = [...learningObjectiveAttributeEditorItems];
                    tempItems.splice(itemIndex, 1);
                    handleFormValueChange({
                        associatedMetadata: tempItems.map((item) => ({
                            id: item.id,
                            metadataType: 'LEARNING_OBJECTIVE',
                        })),
                    });
                    setLearningObjectiveAttributeEditorItems(tempItems);
                }}
                items={learningObjectiveAttributeEditorItems}
                addButtonText="Add learning objective"
                removeButtonText="Remove"
                definition={[
                    {
                        errorText: (item, index) => {
                            if (
                                controlArrayErrors?.learningObjectiveAttributeEditorItems &&
                                controlArrayErrors?.learningObjectiveAttributeEditorItems[index] &&
                                controlArrayErrors?.learningObjectiveAttributeEditorItems[index].id
                            ) {
                                return 'Required';
                            }
                        },
                        control: (item, index) => {
                            return (
                                <Select
                                    loadingText="Loading learning objectives"
                                    selectedOption={
                                        item.id !== '' && item.id in learningObjectiveDict
                                            ? {
                                                  value: item.id,
                                                  label: learningObjectiveDict[
                                                      item.id as keyof typeof learningObjectiveDict
                                                  ],
                                              }
                                            : null
                                    }
                                    onChange={({ detail }) => {
                                        const updatedItems = [
                                            ...learningObjectiveAttributeEditorItems,
                                        ];
                                        updatedItems[index].id = detail.selectedOption.value!;
                                        setLearningObjectiveAttributeEditorItems(updatedItems);
                                        handleFormValueChange({
                                            associatedMetadata: updatedItems.map((item) => ({
                                                id: item.id,
                                                metadataType: 'LEARNING_OBJECTIVE',
                                            })),
                                        });
                                    }}
                                    filteringType="auto"
                                    options={learningObjectives}
                                    placeholder="Select learning objective"
                                    empty="No learning objectives available."
                                />
                            );
                        },
                    },
                ]}
                empty="No learning objectives."
            />
        </FormField>
    );
};

export default LearningObjectiveAttributeEditor;
