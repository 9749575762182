import { useContext } from 'react';
import { AuthContext } from './AuthContext';

export const useAuth = (redirectTo?: string) => {
    const { login: _login, logout: _logout, ...rest } = useContext(AuthContext);

    const login = (redirectToAfterLogin?: string) => {
        _login(redirectToAfterLogin ?? redirectTo);
    };

    const logout = (redirectToAfterLogout?: string) => {
        _logout(redirectToAfterLogout ?? redirectTo);
    };

    return {
        login,
        logout,
        ...rest,
    };
};
