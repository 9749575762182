import { CreateLearningObjectiveMutationVariables } from '../../graphql';

export enum MetadataObjectStatus {
    INACTIVE = 'Inactive',
    ACTIVE = 'Active',
}

export type MetadataMutationVariables = CreateLearningObjectiveMutationVariables;

export const metadataInitialFormValues: MetadataMutationVariables = {
    name: '',
    status: MetadataObjectStatus.ACTIVE,
    programs: [],
    associatedMetadata: [],
};
