import React, { useEffect, useState } from 'react';
import { FormField, Multiselect, Select } from '@amzn/awsui-components-react';
import { handleSelectedOption, handleSelectedOptions } from '../../../utils/formUtils';
import { useApolloClient } from '@apollo/client';
import { GetCurrentUserDocument, useGetAssessmentUserLazyQuery } from '../../../graphql';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';

export const ProgramSelectTestId = 'program-select-label';
export const ProgramSingleSelectInput = 'program-single-select';
export const ProgramMultiSelectInput = 'program-multi-select';

export interface ProgramSelectProps {
    formValues: any;
    handleFormValueChange: (formUpdates: any) => void;
    errors: any;
    multipe?: boolean;
}

const ProgramSelect = ({
    formValues,
    handleFormValueChange,
    errors,
    multipe,
}: ProgramSelectProps) => {
    const client = useApolloClient();
    const [programs, setPrograms] = useState<OptionDefinition[]>([]);
    const [vibeId, setVibeId] = useState<string | null>(null);
    const [getAssessmentUser, { data: assessmentUserData, loading, error }] =
        useGetAssessmentUserLazyQuery();

    useEffect(() => {
        const {
            currentUser: {
                gandalfDetails: { vibeId },
            },
        } = client.readQuery({
            query: GetCurrentUserDocument,
        });
        setVibeId(vibeId);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await getAssessmentUser({
                    variables: {
                        id: vibeId!,
                    },
                });
                if (data?.assessmentUser?.programs) {
                    // Handle empty programs array case
                    if (
                        formValues.programs.length === 0 &&
                        data.assessmentUser.programs.length === 1
                    ) {
                        handleFormValueChange({
                            programs: [data.assessmentUser.programs[0]],
                        });
                    }

                    // Set programs using the returned data
                    setPrograms(
                        data.assessmentUser.programs.map((program) => ({
                            label: program,
                            value: program,
                        })),
                    );
                }
            } catch (err) {
                // Handle any errors that occur during the query
                console.error('Error fetching assessment user:', err);
            }
        };
        if (vibeId) {
            fetchData();
        }
    }, [vibeId]);

    const singleSelect = () => {
        return (
            <Select
                data-testid={ProgramSingleSelectInput}
                selectedOption={handleSelectedOption({
                    selectedOption: formValues.programs[0],
                    options: programs,
                })}
                onChange={(e) => {
                    handleFormValueChange({
                        programs: [e.detail.selectedOption.value!],
                    });
                }}
                options={programs}
                placeholder="Select program"
            />
        );
    };

    const multiSelect = () => {
        return (
            <Multiselect
                data-testid={ProgramMultiSelectInput}
                selectedOptions={handleSelectedOptions({
                    selectedOptions: formValues.programs ?? [],
                    options: programs,
                })}
                onChange={(e) => {
                    handleFormValueChange({
                        programs: e.detail.selectedOptions.map((option) => option.value!),
                    });
                }}
                options={programs}
                placeholder="Select program"
            />
        );
    };

    return (
        <FormField
            data-testid={ProgramSelectTestId}
            label="Program"
            errorText={errors.programs ?? null}
        >
            {multipe ? multiSelect() : singleSelect()}
        </FormField>
    );
};

export default ProgramSelect;
