export const TABLE_DEFAULT_PAGE_SIZE = 25;

const tablePageSizePreference = {
    title: 'Select page size',
    options: [
        { value: 10, label: '10 items' },
        { value: 25, label: '25 items' },
        { value: 50, label: '50 items' },
        { value: 100, label: '100 items' },
    ],
};

const tableWrapLinesPreference = {
    label: 'Wrap lines',
    description: 'Select to see all the text and wrap the lines',
};

const tableStripedRowsPreference = {
    label: 'Striped rows',
    description: 'Select to add alternating shaded rows',
};

const tableContentDensityPreference = {
    label: 'Compact mode',
    description: 'Select to display content in a denser, more compact mode',
};

const tableContentDisplayPreference = {
    title: 'Column preferences',
    description: 'Customize the visibility and order of the columns.',
};

export const tableCollectionPreferencesProps = {
    title: 'Preferences',
    confirmLabel: 'Confirm',
    cancelLabel: 'Cancel',
    pageSizePreference: tablePageSizePreference,
    contentDisplayPreference: tableContentDisplayPreference,
    wrapLinesPreference: tableWrapLinesPreference,
    stripedRowsPreference: tableStripedRowsPreference,
    contentDensityPreference: tableContentDensityPreference,
};
