import React from 'react';
import { Autosuggest, FormField } from '@amzn/awsui-components-react';
import { AssessmentMetadataObject, AssessmentQuestionInput } from '../../../graphql';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { Dictionary } from '../../../interfaces/dictionary';

export const associatedMetadataLabelId = 'associated-data-autosuggest-label';

export interface AssociatedMetadataAutoSuggestProps {
    formValues: Partial<AssessmentMetadataObject>;
    handleFormValueChange: (formUpdates: Partial<AssessmentMetadataObject>) => void;
    errors: any;
    learningObjectiveOptions: OptionDefinition[];
    learningObjectiveDict?: Dictionary<AssessmentMetadataObject>;
}

const AssociatedMetadataAutoSuggest = ({
    formValues,
    handleFormValueChange,
    errors,
    learningObjectiveOptions,
    learningObjectiveDict,
}: AssociatedMetadataAutoSuggestProps) => {
    const [value, setValue] = React.useState(formValues.associatedMetadata![0].id ?? '');
    return (
        <FormField
            data-testid={associatedMetadataLabelId}
            label="Learning objective"
            errorText={errors.learningObjectives ?? null}
        >
            <Autosuggest
                value={
                    learningObjectiveDict?.hasOwnProperty(value)
                        ? learningObjectiveDict![value].name
                        : value
                }
                onChange={(e) => {
                    setValue(e.detail.value!);
                    handleFormValueChange({
                        associatedMetadata: [],
                    });
                }}
                options={learningObjectiveOptions}
                placeholder="Select learning objective"
                empty="No learning objectives available."
                enteredTextLabel={(text) => `Use: "${text}"`}
            />
        </FormField>
    );
};

export default AssociatedMetadataAutoSuggest;
