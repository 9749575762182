import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import {
    Box,
    Button,
    ButtonDropdown,
    CollectionPreferences,
    CollectionPreferencesProps,
    Header,
    Link,
    Pagination,
    PropertyFilterProps,
    Select,
    SpaceBetween,
    Table,
    TableProps,
} from '@amzn/awsui-components-react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setBreadcrumbs, setContentType } from '../../../reducers/navigationReducer';
import { QUESTION_CREATE_ROUTE, QUESTIONS_LIST_ROUTE } from '../../../router/router';
import QuestionsListPropertyFilter, {
    LOCAL_STORAGE_QUESTION_FILTER_KEY,
    QuestionsListPropertyFilterProps,
    StoredQuestionFilters,
} from './QuestionsListPropertyFilter';
import {
    AssessmentQuestion,
    useUpdateAssessmentQuestionMutation,
    useGetAssessmentUsersLazyQuery,
} from '../../../graphql/';
import { Dictionary } from '../../../interfaces/dictionary';
import { EmptyState, LegalModal, LegalModalProps } from '../../../components';
import { QuestionStatus } from '../../../common/constants/questions';
import { useNotifications } from '../../../context/NotificationsProvider';
import { Workbook } from 'exceljs';
import { ExcelUtil } from '../../../utils/excel';
import { useQuestions } from '../../../hooks/useQuestion';
import { tableCollectionPreferencesProps } from '../../../common/constants/tablePreferences';
import { isProd } from '../../../common/nodeEnvironment';

interface QuestionTableHeaderProps {
    setShowLegalModal: Dispatch<SetStateAction<boolean>>;
    questions: AssessmentQuestion[];
    currentCount: number;
}

// table preference values from local storage
export const LOCAL_STORAGE_QUESTIONS_TABLE_PREFERENCES_KEY = 'questionsTablePreferences';
export const userTablePreferences = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_QUESTIONS_TABLE_PREFERENCES_KEY) || '{}',
);

// table column width values from local storage
const LOCAL_STORAGE_QUESTIONS_TABLE_COLUMN_WIDTHS_KEY = 'questionsTableColumnWidths';
const userTableColumnWidths = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_QUESTIONS_TABLE_COLUMN_WIDTHS_KEY) || '[]',
);

const QuestionsTableHeader = ({
    setShowLegalModal,
    questions,
    currentCount,
}: QuestionTableHeaderProps) => {
    const navigate = useNavigate();

    const onButtonItemClick = ({ detail }: any) => {
        if (detail.id === 'create-ai') {
            setShowLegalModal(true);
        } else {
            navigate(QUESTION_CREATE_ROUTE.path);
        }
    };

    const handleExportClick = () => {
        const workbook = new Workbook();
        const excelUtil = new ExcelUtil(workbook);
        excelUtil
            .buildAssessmentQuestionExportWorkbook()
            .populateAssessmentQuestionExportWorkbook(questions)
            .styleWorkbook()
            .cellProtection()
            .writeWorkbook();
    };

    return (
        <Header
            counter={`(${currentCount})`}
            actions={
                <SpaceBetween size="xs" direction="horizontal">
                    <SpaceBetween size="xs" direction="horizontal">
                        <Button onClick={handleExportClick}>Export questions</Button>
                        <ButtonDropdown
                            variant="primary"
                            onItemClick={onButtonItemClick}
                            items={[
                                { text: 'With GenAI', id: 'create-ai', disabled: false },
                                { text: 'With manual entry', id: 'create', disabled: false },
                            ]}
                        >
                            Create question
                        </ButtonDropdown>
                    </SpaceBetween>
                </SpaceBetween>
            }
        >
            Questions
        </Header>
    );
};

const QuestionList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showLegalModal, setShowLegalModal] = useState(false);
    const { addNotification } = useNotifications();
    const [query, setQuery] = useState<PropertyFilterProps.Query>({
        tokens: [],
        operation: 'and',
    });
    const [usersInfoDict, setUsersInfoDict] = useState<Dictionary<string>>({});

    const {
        questions,
        learningObjectiveDict,
        getQuestions,
        handlePaginationChange,
        currentPageIndex,
        currentCount,
        pagesCount,
        isLoading,
        getQuestionsByPropertyFilter,
        pageSize,
        setPageSize,
    } = useQuestions();

    const [preferences, setPreferences] = useState({
        pageSize,
        wrapLines: false,
        contentDisplay: [
            { id: 'questionText', visible: true },
            { id: 'status', visible: true },
            { id: 'type', visible: true },
            { id: 'difficulty', visible: true },
            { id: 'learningObjective', visible: true },
            { id: 'programs', visible: true },
            { id: 'scoringMethod', visible: true },
            // TODO: Remove prod conditional once CHIRONASSMNTS-459 is complete
            ...(isProd()
                ? []
                : [
                      { id: 'modifiedByName', visible: false },
                      { id: 'createdByName', visible: false },
                  ]),
            { id: 'actions', visible: true },
        ],
        ...userTablePreferences,
    } as CollectionPreferencesProps.Preferences);

    // queries
    const [updateQuestion] = useUpdateAssessmentQuestionMutation();
    const [getUsers] = useGetAssessmentUsersLazyQuery();

    useEffect(() => {
        dispatch(
            setBreadcrumbs([
                {
                    text: QUESTIONS_LIST_ROUTE.title,
                    href: QUESTIONS_LIST_ROUTE.path,
                },
            ]),
        );
        dispatch(setContentType('table'));
    }, [dispatch]);

    useEffect(() => {
        const storedFilterString = localStorage.getItem(LOCAL_STORAGE_QUESTION_FILTER_KEY);
        if (storedFilterString) {
            let savedFilters = JSON.parse(storedFilterString) as StoredQuestionFilters;
            try {
                if (savedFilters.propertyFilters) {
                    setQuery(savedFilters.propertyFilters);
                }
                getQuestionsByPropertyFilter(savedFilters);
            } catch (e) {}
        } else {
            getQuestions();
        }
    }, [pageSize]);

    useEffect(() => {
        if (questions.length > 0) {
            handleGetUserInfo(questions);
        }
    }, [questions]);

    const getUsersNamesForIds = async (userIds: Set<string>): Promise<any> => {
        const { data } = await getUsers({
            variables: {
                id: Array.from(userIds),
            },
        });
        let userDict = {};
        const users = data?.assessmentUsers?.users;
        if (users) {
            users.forEach((u) => {
                userDict = {
                    ...userDict,
                    [u.id]: u.name,
                };
            });
        }
        return userDict;
    };

    const handleGetUserInfo = async (questions: AssessmentQuestion[]) => {
        const userIds = new Set<string>();
        questions.forEach((q) => {
            q.modifiedBy && userIds.add(q.modifiedBy);
            q.createdBy && userIds.add(q.createdBy);
        });
        const usersInfoDict = await getUsersNamesForIds(userIds);
        setUsersInfoDict(usersInfoDict);
    };

    const handleInlineEdit = async ({
        updatedQuestion,
        column,
        newValue,
        currentTableItem,
    }: {
        updatedQuestion: AssessmentQuestion;
        column: TableProps.ColumnDefinition<AssessmentQuestion>;
        newValue: any;
        currentTableItem: AssessmentQuestion;
    }) => {
        try {
            const {
                id,
                version,
                status,
                type,
                scoringMethod,
                answers,
                difficulty,
                questionText,
                programs,
                learningObjectives,
            } = updatedQuestion;
            const { data } = await updateQuestion({
                variables: {
                    id: id!,
                    version: version!,
                    status: status!,
                    type: type!,
                    scoringMethod: scoringMethod!,
                    answers: answers?.map(({ answerText, isCorrect, explanation }) => ({
                        answerText: answerText!,
                        isCorrect: isCorrect!,
                        explanation: explanation ?? '',
                    }))!,
                    difficulty: difficulty!,
                    questionText: questionText!,
                    programs: programs!,
                    learningObjectives: learningObjectives!,
                },
            });
            // @ts-ignore - TODO Manually updating the table till more research on updating the cache https://www.apollographql.com/docs/react/data/mutations/#updating-the-cache-directly
            currentTableItem[column.id] = newValue;
            addNotification({
                id: `edit-question-${Date.now()}`,
                ...(data?.updateAssessmentQuestion
                    ? {
                          type: 'success',
                          content: 'Question was updated successfully.',
                      }
                    : {
                          type: 'error',
                          content: 'There was an error updating the question.',
                      }),
            });
        } catch (error) {
            addNotification({
                id: `error-edit-question-${Date.now()}`,
                type: 'error',
                content: 'There was an error updating the question.',
            });
        }
    };

    const handleTableSubmitEdit = async (
        item: AssessmentQuestion,
        column: TableProps.ColumnDefinition<AssessmentQuestion>,
        newValue: any,
    ) => {
        const updatedQuestion = {
            ...item,
            // @ts-ignore - mapping the back to lo id's
            learningObjectives: item.learningObjectives!.map((lo) => lo.id),
            [`${column.id}`]: newValue,
        };
        await handleInlineEdit({
            updatedQuestion,
            column,
            newValue,
            currentTableItem: item,
        });
    };

    const handleTablePreferencesConfirm = async ({ detail }: any) => {
        setPreferences(detail);
        localStorage.setItem(LOCAL_STORAGE_QUESTIONS_TABLE_PREFERENCES_KEY, JSON.stringify(detail));
        setPageSize(detail.pageSize || pageSize);
    };

    const handleTableColumnWidthChange = async ({ detail }: any) => {
        let columnWidths: Record<string, number> = {};
        for (const ix in columnDefinitions) {
            columnWidths[columnDefinitions[ix].id] = detail.widths[ix];
        }
        localStorage.setItem(
            LOCAL_STORAGE_QUESTIONS_TABLE_COLUMN_WIDTHS_KEY,
            JSON.stringify(columnWidths),
        );
    };

    const columnDefinitions = useMemo(() => {
        return [
            {
                id: 'questionText',
                header: 'Question Text',
                isRowHeader: true,
                width: userTableColumnWidths['questionText'] || 500,
                cell: (item: AssessmentQuestion) => (
                    <Link
                        variant="secondary"
                        href={`/questions/${item.id}/version/${item.version}`}
                        onFollow={(e) => {
                            e.preventDefault();
                            navigate(`/questions/${item.id}/version/${item.version}`, {
                                state: { ...item },
                            });
                        }}
                    >
                        {item.questionText}
                    </Link>
                ),
            },
            {
                id: 'learningObjective',
                header: 'Learning Objective',
                isRowHeader: true,
                width: userTableColumnWidths['learningObjective'] || undefined,
                cell: (item: AssessmentQuestion) =>
                    //@ts-ignore - Ignoring to avoid complicating typing - This is the whole LO meta object
                    item.learningObjectives![0] ? item.learningObjectives![0].name : '',
            },
            {
                id: 'status',
                header: 'Status',
                cell: (item: AssessmentQuestion) => item.status,
                isRowHeader: true,
                minWidth: 176,
                width: userTableColumnWidths['status'] || 176,
                editConfig: {
                    ariaLabel: 'Type',
                    editIconAriaLabel: 'editable',
                    editingCell: (
                        item: AssessmentQuestion,
                        {
                            currentValue,
                            setValue,
                        }: { currentValue: string; setValue: (item: any) => void },
                    ) => {
                        const value = currentValue ?? item.status;
                        const questionStatusOptions = Object.keys(QuestionStatus).map((prop) => ({
                            value: QuestionStatus[prop as keyof typeof QuestionStatus],
                            label: QuestionStatus[prop as keyof typeof QuestionStatus],
                        }));
                        return (
                            <Select
                                autoFocus={true}
                                expandToViewport={true}
                                selectedOption={
                                    questionStatusOptions.find(
                                        (option) => option.value === value,
                                    ) ?? null
                                }
                                onChange={(event) => {
                                    setValue(event.detail.selectedOption.value ?? currentValue);
                                }}
                                options={questionStatusOptions}
                            />
                        );
                    },
                },
            },
            {
                id: 'type',
                header: 'Type',
                isRowHeader: true,
                width: userTableColumnWidths['type'] || 150,
                cell: (item: AssessmentQuestion) => item.type,
            },
            {
                id: 'programs',
                header: 'Programs',
                isRowHeader: true,
                width: userTableColumnWidths['programs'] || undefined,
                cell: (item: AssessmentQuestion) => item.programs?.join(', '),
            },
            {
                id: 'difficulty',
                header: 'Difficulty',
                isRowHeader: true,
                width: userTableColumnWidths['difficulty'] || 110,
                cell: (item: AssessmentQuestion) => item.difficulty,
            },
            {
                id: 'scoringMethod',
                header: 'Scoring Method',
                isRowHeader: true,
                width: userTableColumnWidths['scoringMethod'] || 150,
                cell: (item: AssessmentQuestion) => item.scoringMethod,
            },
            // TODO: Remove prod conditional once CHIRONASSMNTS-459 is complete
            ...(isProd()
                ? []
                : [
                      {
                          id: 'createdByName',
                          header: 'Created By',
                          width: userTableColumnWidths['createdByName'] || undefined,
                          cell: (item: AssessmentQuestion) =>
                              (item.createdBy && usersInfoDict[item.createdBy]) || '',
                      },
                      {
                          id: 'modifiedByName',
                          header: 'Modifed By',
                          width: userTableColumnWidths['modifiedByName'] || undefined,
                          cell: (item: AssessmentQuestion) =>
                              (item.modifiedBy && usersInfoDict[item.modifiedBy]) || '',
                      },
                  ]),
            {
                id: 'actions',
                header: 'Actions',
                width: userTableColumnWidths['actions'] || 100,
                cell: (item: AssessmentQuestion) => (
                    <SpaceBetween direction="vertical" size="xs">
                        <Button
                            href={`/questions/${item.id}/version/${item.version}/edit`}
                            onFollow={(e) => {
                                e.preventDefault();
                                navigate(`/questions/${item.id}/version/${item.version}/edit`, {
                                    state: {
                                        ...item,
                                        learningObjectives:
                                            // @ts-ignore
                                            item.learningObjectives!.map((lo) => lo.id),
                                    },
                                });
                            }}
                            variant="inline-link"
                        >
                            Edit
                        </Button>
                    </SpaceBetween>
                ),
            },
        ];
    }, [learningObjectiveDict, usersInfoDict]);

    const questionsListPropertyFilterProps: QuestionsListPropertyFilterProps = {
        query,
        setQuery,
        getQuestionsByPropertyFilter,
        initialLearningObjectiveDict: learningObjectiveDict,
    };

    const questionsTableHeaderProps: QuestionTableHeaderProps = {
        setShowLegalModal,
        questions,
        currentCount,
    };

    const legalModalProps: LegalModalProps = {
        showLegalModal,
        setShowLegalModal,
    };

    const collectionPreferencesProps = {
        ...tableCollectionPreferencesProps,
        contentDisplayPreference: {
            ...tableCollectionPreferencesProps.contentDisplayPreference,
            options: columnDefinitions.map(({ id, header }) => ({
                id,
                label: header,
                alwaysVisible: ['questionText', 'actions'].includes(id),
            })),
        },
    };

    // Table component properties
    const TableHeader = <QuestionsTableHeader {...questionsTableHeaderProps} />;
    const TableFilter = <QuestionsListPropertyFilter {...questionsListPropertyFilterProps} />;
    const tablePaginationProps = {
        currentPageIndex: currentPageIndex,
        pagesCount: pagesCount,
        onChange: handlePaginationChange,
    };
    const tableAriaLabels = {
        activateEditLabel: (column: any, item: any) => `Edit ${item.questionText} ${column.header}`,
        cancelEditLabel: (column: any) => `Cancel editing ${column.header}`,
        submitEditLabel: (column: any) => `Submit editing ${column.header}`,
        tableLabel: 'Question list with inline editing for the question status.',
    };

    return (
        <>
            {showLegalModal && <LegalModal {...legalModalProps} />}
            <Table
                stickyHeader={true}
                header={TableHeader}
                variant="full-page"
                loading={isLoading}
                loadingText="Loading questions"
                resizableColumns
                enableKeyboardNavigation
                items={questions}
                columnDefinitions={columnDefinitions}
                stickyColumns={{ first: 0, last: 1 }}
                filter={TableFilter}
                pagination={<Pagination {...tablePaginationProps} />}
                empty={<EmptyState displayMessage="No questions found." />}
                ariaLabels={tableAriaLabels}
                submitEdit={handleTableSubmitEdit}
                wrapLines={preferences.wrapLines}
                stripedRows={preferences.stripedRows}
                contentDensity={preferences.contentDensity}
                columnDisplay={preferences.contentDisplay}
                onColumnWidthsChange={handleTableColumnWidthChange}
                preferences={
                    <CollectionPreferences
                        {...collectionPreferencesProps}
                        preferences={preferences}
                        onConfirm={handleTablePreferencesConfirm}
                    />
                }
            />
        </>
    );
};

export default QuestionList;
