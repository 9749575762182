export enum LearningObjectiveDetailIds {
    NameLabel = 'learningobjective-name-label',
    StatusLabel = 'learningobjective-status-label',
    ProgramLabel = 'learningobjective-program-label',
}

export enum LearningObjectiveEditId {
    FormId = 'learningobjective-edit-form-id',
    SaveButton = 'learningobjective-save-button',
    CancelButton = 'learningobjective-cancel-button',
}

export enum MetaDataIds {
    // Form field labels
    Status = 'metadata-status-label',
    Name = 'metadata-name-label',

    // Form field controls
    NameTextArea = 'metadata-name-textarea',
    StatusSelect = 'metadata-status-select',
    ProgramSelect = 'metadata-program-select',

    // Form field editors
    LearningObjectivesEditor = 'metadata-learning-objectives-editor',

    // Buttons
    SaveButton = 'metadata-save-button',
    CancelButton = 'metadata-cancel-button',

    // Containers
    Form = 'metadata-form',
    Modal = 'metadata-modal',
    Table = 'metadata-table',
    Tabs = 'metadata-tabs'
}
