import React, { useEffect, useState } from 'react';
import { QUESTION_EDIT_ROUTE, QUESTIONS_LIST_ROUTE } from '../../router/router';
import { setBreadcrumbs, setContentType } from '../../reducers/navigationReducer';
import { useDispatch } from 'react-redux';
import { Button, Form, Header, SpaceBetween, Spinner } from '@amzn/awsui-components-react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
    AssessmentQuestionUpdateInput,
    useGetAssessmentQuestionQuery,
    useUpdateAssessmentQuestionMutation,
} from '../../graphql';
import useFormValidation from '../../hooks/useFormValidation';
import { QUESTION_INPUT_VALIDATION_FIELDS } from '../../common/constants/validations';
import { useNotifications } from '../../context/NotificationsProvider';
import {
    initialFormValues,
    QUESTION_WITH_MULTIPLE_CORRECT_ANSWERS,
    QUESTION_WITHOUT_ANSWERS_ERROR,
} from '../../common/constants/questions';
import QuestionForm, { QuestionFormProps } from '../../components/questions/forms/QuestionForm';
import { QuestionIds } from '../../common/dataTestIds/question';

const QuestionEdit = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { addNotification } = useNotifications();
    const { id = '', version } = useParams();
    const [mode, setMode] = useState('edit');

    const [formValues, setFormValues] = useState<Partial<AssessmentQuestionUpdateInput>>(
        state ?? initialFormValues,
    );
    const { isInvalid, validateForm, errors } =
        useFormValidation<Partial<AssessmentQuestionUpdateInput>>();
    const [updateQuestion, { loading: updatingQuestion }] = useUpdateAssessmentQuestionMutation();

    const { data, loading } = useGetAssessmentQuestionQuery({
        variables: {
            id,
            version: Number(version),
        },
        fetchPolicy: 'cache-and-network',
    });

    const runInputValidations = () =>
        validateForm(formValues!, {
            required: QUESTION_INPUT_VALIDATION_FIELDS.REQUIRED,
        });

    useEffect(() => {
        dispatch(
            setBreadcrumbs([
                {
                    text: QUESTIONS_LIST_ROUTE.title,
                    href: QUESTIONS_LIST_ROUTE.path,
                },
                {
                    text: QUESTION_EDIT_ROUTE.title,
                    href: QUESTION_EDIT_ROUTE.path,
                },
            ]),
        );
        dispatch(setContentType('form'));
    }, [dispatch]);

    useEffect(() => {
        if (isInvalid) {
            runInputValidations();
        }
    }, [formValues, isInvalid, validateForm]);

    useEffect(() => {
        if (data && !loading) {
            const dataToFormValues: {} = {
                ...data!.assessmentQuestion!,
            };
            setFormValues(dataToFormValues);
        }
    }, [data, loading]);

    const handleFormValueChange = (formUpdates: Partial<AssessmentQuestionUpdateInput>) => {
        setFormValues({ ...formValues, ...formUpdates });
    };

    const handleEditQuestion = async () => {
        if (formValues.answers!.length < 2) {
            return addNotification({
                id: `edit-question-${Date.now()}`,
                type: 'error',
                content: QUESTION_WITHOUT_ANSWERS_ERROR,
            });
        }

        const correctAnswers = formValues.answers!.filter((answer) => answer.isCorrect);
        if (correctAnswers.length > 1) {
            return addNotification({
                id: `edit-question-${Date.now()}`,
                type: 'error',
                content: QUESTION_WITH_MULTIPLE_CORRECT_ANSWERS,
            });
        }

        const invalid = runInputValidations();
        if (invalid) {
            return;
        }

        try {
            const {
                id,
                version,
                status,
                type,
                scoringMethod,
                answers,
                difficulty,
                questionText,
                programs,
                learningObjectives,
            } = formValues;
            const { data } = await updateQuestion({
                variables: {
                    id: id!,
                    version: version!,
                    status: status!,
                    type: type!,
                    scoringMethod: scoringMethod!,
                    answers: answers?.map(({ answerText, isCorrect, explanation }) => ({
                        answerText,
                        isCorrect,
                        explanation: explanation ?? '',
                    }))!,
                    difficulty: difficulty!,
                    questionText: questionText!,
                    programs: programs!,
                    learningObjectives: learningObjectives!,
                },
            });
            addNotification({
                id: `edit-question-${Date.now()}`,
                ...(data?.updateAssessmentQuestion
                    ? {
                          type: 'success',
                          content: 'Question was updated successfully.',
                      }
                    : {
                          type: 'error',
                          content: 'There was an error updating the question.',
                      }),
            });

            if (data?.updateAssessmentQuestion) {
                const question = data.updateAssessmentQuestion;
                navigate(`/questions/${question?.id}/version/${question?.version}`, {
                    state: { ...question },
                });
            }
        } catch (error) {
            addNotification({
                id: `error-edit-question-${Date.now()}`,
                type: 'error',
                content: 'There was an error updating the question.',
            });
        }
    };

    const editFormProps: QuestionFormProps = {
        handleFormValueChange,
        formValues,
        mode,
        errors,
    };

    return (
        <Form
            data-testid={QuestionIds.FormId}
            actions={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button onClick={() => navigate(-1)} formAction="none" variant="link">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleEditQuestion}
                        disabled={updatingQuestion}
                        variant="primary"
                    >
                        {updatingQuestion ? 'Saving' : 'Save'}
                    </Button>
                </SpaceBetween>
            }
            header={<Header variant="h1">Edit Question</Header>}
        >
            <QuestionForm {...editFormProps} />
        </Form>
    );
};

export default QuestionEdit;
