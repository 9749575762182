import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { AttributeEditor, Autosuggest, FormField } from '@amzn/awsui-components-react';
import {
    AssessmentMetadataObject,
    AssessmentQuestionInput,
    useGetQuestionBanksLazyQuery,
} from '../../../graphql';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { Dictionary } from '../../../interfaces/dictionary';

export const QuestionBankAttributeEditorFormField = 'question-bank-attribute-editor';

export interface QuestionBankAttributeEditorItem {
    id: string;
}

export interface QuestionBankAttributeEditorProps {
    handleFormValueChange: (formUpdates: Partial<AssessmentQuestionInput>) => void;
    errors: any;
    selectedPrograms?: string[];
    selectedLearningObjective: string;
    questionBankAttributeEditorItems: QuestionBankAttributeEditorItem[];
    setQuestionBankAttributeEditorItems: Dispatch<
        SetStateAction<QuestionBankAttributeEditorItem[]>
    >;
}

const QuestionBankAttributeEditor = ({
    selectedPrograms,
    selectedLearningObjective,
    questionBankAttributeEditorItems,
    handleFormValueChange,
    setQuestionBankAttributeEditorItems,
}: QuestionBankAttributeEditorProps) => {
    const [getQuestionBanks] = useGetQuestionBanksLazyQuery();
    const [questionBankDict, setQuestionBankDict] =
        useState<Dictionary<AssessmentMetadataObject>>();
    const [questionBankOptions, setQuestionBankOptions] = useState<OptionDefinition[]>([]);

    useEffect(() => {
        if (selectedPrograms && selectedPrograms?.length > 0 && selectedLearningObjective) {
            handleGetQuestionBanks();
        }
    }, [selectedPrograms, selectedLearningObjective]);

    const handleGetQuestionBanks = async () => {
        const { data } = await getQuestionBanks({
            variables: {
                status: 'Active',
                programs: selectedPrograms,
                size: 500,
            },
        });
        let options: OptionDefinition[] = [];
        let questionBankDict = {};
        data!.assessmentQuestionBanks.metadataObjects
            .filter((questionBank) =>
                questionBank.associatedMetadata?.some(
                    (metadata) => metadata.id === selectedLearningObjective,
                ),
            )
            .forEach((questionBank) => {
                options.push({
                    value: questionBank.id,
                    label: questionBank.name,
                });
                questionBankDict = {
                    ...questionBankDict,
                    [questionBank.id]: questionBank,
                };
            });

        setQuestionBankDict(questionBankDict);
        setQuestionBankOptions(options);
    };
    return (
        <FormField
            data-testid={QuestionBankAttributeEditorFormField}
            description="Select one or more question banks where generated questions will be automatically saved."
            label={
                <span>
                    Question banks <i>- optional</i>{' '}
                </span>
            }
        >
            <AttributeEditor
                items={questionBankAttributeEditorItems}
                definition={[
                    {
                        control: (item, index) => {
                            return (
                                <Autosuggest
                                    value={
                                        questionBankDict?.hasOwnProperty(item.id)
                                            ? questionBankDict![item.id].name
                                            : item.id
                                    }
                                    onChange={({ detail }) => {
                                        const updatedItems = [...questionBankAttributeEditorItems];
                                        updatedItems[index].id = detail.value!;
                                        handleFormValueChange({
                                            questionBanks: updatedItems.map((item) => item.id),
                                        });
                                    }}
                                    options={questionBankOptions}
                                    placeholder="Select question bank"
                                    empty="No question banks available."
                                    enteredTextLabel={(text) => `Use: "${text}"`}
                                />
                            );
                        },
                    },
                ]}
                addButtonText="Add question bank"
                removeButtonText="Remove"
                onAddButtonClick={() =>
                    setQuestionBankAttributeEditorItems([
                        ...questionBankAttributeEditorItems,
                        { id: '' },
                    ])
                }
                onRemoveButtonClick={({ detail: { itemIndex } }) => {
                    const tempItems = [...questionBankAttributeEditorItems];
                    tempItems.splice(itemIndex, 1);
                    handleFormValueChange({
                        questionBanks: tempItems.map((item) => item.id),
                    });
                    setQuestionBankAttributeEditorItems(tempItems);
                }}
            />
        </FormField>
    );
};

export default QuestionBankAttributeEditor;
