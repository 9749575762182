import React, { useEffect } from 'react';
import {
    ColumnLayout,
    Container,
    FormField,
    Header,
    RadioGroup,
    Select,
    SpaceBetween,
} from '@amzn/awsui-components-react';
import { ControlledInput } from '@amzn/react-hook-form-polaris';
import { AssessmentUpdateInput } from '../../graphql';
import { handleSelectedOption } from '../../utils/formUtils';
import {
    IsCorrectAnswersShown,
    IsDetailedResultsEnabled,
    IsSubmittedResponsesShown,
    QuestionsDisplaySetting,
    QuestionsOrdering,
    ScoreDisplay,
} from '../../common/constants/assessments';
import { get, UseFormReturn } from 'react-hook-form';
import { convertMilliSecondsToHours, convertMilliSecondsToMinutes } from '../../utils/timeUtils';
import { useNotifications } from '../../context/NotificationsProvider';
import { AssessmentSettingsForm } from '../../common/dataTestIds/assessmentSettingsForm';

export interface AssessmentSettingsFormFieldsProp {
    handleFormValueChange: (formUpdates: Partial<AssessmentUpdateInput>) => void;
    formValues: Partial<AssessmentUpdateInput>;
    form: UseFormReturn<Partial<AssessmentUpdateInput>, any, undefined>;
}

export enum AssessmentSettingsFieldNames {
    WAIT_TIME = 'waitTime',
    MAX_ATTEMPTS = 'maxAttempts',
    PASSING_SCORE = 'passingScore',
    TIME_LIMIT = 'timeLimit',
}

const AssessmentSettingsFormFields = ({
    handleFormValueChange,
    formValues,
    form,
}: AssessmentSettingsFormFieldsProp) => {
    const { removeAllNotifications } = useNotifications();

    const {
        control,
        setValue,
        formState: { errors },
    } = form;

    useEffect(() => {
        setValue(
            AssessmentSettingsFieldNames.WAIT_TIME,
            convertMilliSecondsToHours(formValues?.waitTime || 0),
        );
        setValue(
            AssessmentSettingsFieldNames.TIME_LIMIT,
            convertMilliSecondsToMinutes(formValues?.timeLimit || 0),
        );
        removeAllNotifications();
    }, []);

    const questionDisplaySettingOptions = Object.keys(QuestionsDisplaySetting).map((prop) => ({
        value: QuestionsDisplaySetting[prop as keyof typeof QuestionsDisplaySetting],
        label: QuestionsDisplaySetting[prop as keyof typeof QuestionsDisplaySetting],
    }));

    const correctAnswersShownOptions = Object.keys(IsCorrectAnswersShown).map((prop) => ({
        value: IsCorrectAnswersShown[prop as keyof typeof IsCorrectAnswersShown],
        label: IsCorrectAnswersShown[prop as keyof typeof IsCorrectAnswersShown],
    }));

    const detailedResultsOptions = Object.keys(IsDetailedResultsEnabled).map((prop) => ({
        value: IsDetailedResultsEnabled[prop as keyof typeof IsDetailedResultsEnabled],
        label: IsDetailedResultsEnabled[prop as keyof typeof IsDetailedResultsEnabled],
    }));

    const submittedResponseOptions = Object.keys(IsSubmittedResponsesShown).map((prop) => ({
        value: IsSubmittedResponsesShown[prop as keyof typeof IsSubmittedResponsesShown],
        label: IsSubmittedResponsesShown[prop as keyof typeof IsSubmittedResponsesShown],
    }));

    const questionOrderingOptions = Object.keys(QuestionsOrdering).map((prop) => ({
        value: QuestionsOrdering[prop as keyof typeof QuestionsOrdering],
        label: QuestionsOrdering[prop as keyof typeof QuestionsOrdering],
    }));

    const scoreDisplayOptions = Object.keys(ScoreDisplay).map((prop) => ({
        value: ScoreDisplay[prop as keyof typeof ScoreDisplay],
        label: ScoreDisplay[prop as keyof typeof ScoreDisplay],
    }));

    return (
        <Container header={<Header variant="h2">Assessment Settings</Header>}>
            <SpaceBetween direction="vertical" size="l">
                <FormField
                    data-testid={AssessmentSettingsForm.QuestionDisplayLabel}
                    label="Question display setting"
                    description="Description needed"
                >
                    <Select
                        selectedOption={handleSelectedOption({
                            selectedOption: formValues.displaySetting!,
                            options: questionDisplaySettingOptions,
                        })}
                        onChange={(e) => {
                            handleFormValueChange({
                                displaySetting: e.detail.selectedOption.value!,
                            });
                        }}
                        options={questionDisplaySettingOptions}
                    />
                </FormField>
                <FormField
                    data-testid={AssessmentSettingsForm.CorrectAnswerLabel}
                    label="Show correct answers"
                    description="Description needed"
                >
                    <Select
                        selectedOption={handleSelectedOption({
                            selectedOption: formValues.isCorrectAnswersShown!,
                            options: correctAnswersShownOptions,
                        })}
                        onChange={(e) => {
                            handleFormValueChange({
                                isCorrectAnswersShown: e.detail.selectedOption.value!,
                            });
                        }}
                        options={correctAnswersShownOptions}
                    />
                </FormField>
                <FormField
                    data-testid={AssessmentSettingsForm.DetailedResultsLabel}
                    label="Show detailed results"
                    description="Description needed"
                >
                    <Select
                        selectedOption={handleSelectedOption({
                            selectedOption: formValues.isDetailedResultsEnabled!,
                            options: detailedResultsOptions,
                        })}
                        onChange={(e) => {
                            handleFormValueChange({
                                isDetailedResultsEnabled: e.detail.selectedOption.value!,
                            });
                        }}
                        options={detailedResultsOptions}
                    />
                </FormField>
                <FormField
                    data-testid={AssessmentSettingsForm.ShowSubmittedResponsesLabel}
                    label="Show submitted responses"
                    description="Description needed"
                >
                    <Select
                        selectedOption={handleSelectedOption({
                            selectedOption: formValues.isSubmittedResponsesShown!,
                            options: submittedResponseOptions,
                        })}
                        onChange={(e) => {
                            handleFormValueChange({
                                isSubmittedResponsesShown: e.detail.selectedOption.value!,
                            });
                        }}
                        options={submittedResponseOptions}
                    />
                </FormField>
                <FormField
                    data-testid={AssessmentSettingsForm.QuestionOrderingLabel}
                    label="Question ordering"
                    description="Description needed"
                >
                    <Select
                        selectedOption={handleSelectedOption({
                            selectedOption: formValues.questionOrdering!,
                            options: questionOrderingOptions,
                        })}
                        onChange={(e) => {
                            handleFormValueChange({
                                questionOrdering: e.detail.selectedOption.value!,
                            });
                        }}
                        options={questionOrderingOptions}
                    />
                </FormField>
                <FormField
                    data-testid={AssessmentSettingsForm.ScoreDisplayLabel}
                    label="Score display"
                    description="Description needed"
                >
                    <Select
                        selectedOption={handleSelectedOption({
                            selectedOption: formValues.scoreDisplay!,
                            options: scoreDisplayOptions,
                        })}
                        onChange={(e) => {
                            handleFormValueChange({
                                scoreDisplay: e.detail.selectedOption.value!,
                            });
                        }}
                        options={scoreDisplayOptions}
                    />
                </FormField>

                <ColumnLayout columns={3} variant="text-grid">
                    <SpaceBetween direction="vertical" size="l">
                        <FormField
                            data-testid={AssessmentSettingsForm.ObjectiveScoreEnabledLabel}
                            label="Objective score enabled"
                        >
                            <RadioGroup
                                onChange={({ detail }) =>
                                    handleFormValueChange({
                                        isCategoryScoreEnabled: detail.value === 'true',
                                    })
                                }
                                value={formValues.isCategoryScoreEnabled!.toString()}
                                items={[
                                    { value: 'true', label: 'True' },
                                    { value: 'false', label: 'False' },
                                ]}
                            />
                        </FormField>
                        <FormField
                            data-testid={AssessmentSettingsForm.CopyPasteLabel}
                            label="Copy/Paste enabled"
                        >
                            <RadioGroup
                                onChange={({ detail }) =>
                                    handleFormValueChange({
                                        isCopyPasteEnabled: detail.value === 'true',
                                    })
                                }
                                value={formValues.isCopyPasteEnabled!.toString()}
                                items={[
                                    { value: 'true', label: 'True' },
                                    { value: 'false', label: 'False' },
                                ]}
                            />
                        </FormField>
                        <FormField
                            data-testid={AssessmentSettingsForm.ShowFinalScoreLabel}
                            label="Show final score"
                        >
                            <RadioGroup
                                onChange={({ detail }) =>
                                    handleFormValueChange({
                                        isFinalScoreEnabled: detail.value === 'true',
                                    })
                                }
                                value={formValues.isFinalScoreEnabled?.toString() ?? null}
                                items={[
                                    { value: 'true', label: 'True' },
                                    { value: 'false', label: 'False' },
                                ]}
                            />
                        </FormField>
                        <FormField
                            data-testid={AssessmentSettingsForm.FlagQuestionsEnabledLabel}
                            label="Flag questions enabled"
                        >
                            <RadioGroup
                                onChange={({ detail }) =>
                                    handleFormValueChange({
                                        isFlaggingEnabled: detail.value === 'true',
                                    })
                                }
                                value={formValues.isFlaggingEnabled?.toString() ?? null}
                                items={[
                                    { value: 'true', label: 'True' },
                                    { value: 'false', label: 'False' },
                                ]}
                            />
                        </FormField>
                    </SpaceBetween>
                    <SpaceBetween direction="vertical" size="l">
                        <FormField
                            data-testid={AssessmentSettingsForm.BackNextButtonEnabledLabel}
                            label="Back and next buttons enabled"
                        >
                            <RadioGroup
                                onChange={({ detail }) =>
                                    handleFormValueChange({
                                        isNextBackEnabled: detail.value === 'true',
                                    })
                                }
                                value={formValues.isNextBackEnabled?.toString() ?? null}
                                items={[
                                    { value: 'true', label: 'True' },
                                    { value: 'false', label: 'False' },
                                ]}
                            />
                        </FormField>

                        <FormField
                            data-testid={AssessmentSettingsForm.NotesEnabled}
                            label="Notes enabled"
                        >
                            <RadioGroup
                                onChange={({ detail }) =>
                                    handleFormValueChange({
                                        isNotesEnabled: detail.value === 'true',
                                    })
                                }
                                value={formValues.isNotesEnabled?.toString() ?? null}
                                items={[
                                    { value: 'true', label: 'True' },
                                    { value: 'false', label: 'False' },
                                ]}
                            />
                        </FormField>

                        <FormField
                            data-testid={AssessmentSettingsForm.PauseAssessmentEnabled}
                            label="Pause assessment enabled"
                        >
                            <RadioGroup
                                onChange={({ detail }) =>
                                    handleFormValueChange({
                                        isPausingEnabled: detail.value === 'true',
                                    })
                                }
                                value={formValues.isPausingEnabled?.toString() ?? null}
                                items={[
                                    { value: 'true', label: 'True' },
                                    { value: 'false', label: 'False' },
                                ]}
                            />
                        </FormField>
                        <FormField
                            data-testid={AssessmentSettingsForm.MaxAttemptsLabel}
                            label="Maximum number of attempts"
                            errorText={get(
                                errors,
                                `${AssessmentSettingsFieldNames.MAX_ATTEMPTS}.message`,
                            )}
                        >
                            <ControlledInput
                                name={AssessmentSettingsFieldNames.MAX_ATTEMPTS}
                                type="number"
                                inputMode="numeric"
                                control={control}
                                rules={{
                                    required: {
                                        message: 'You must set maximum number of attempts',
                                        value: true,
                                    },
                                    min: {
                                        message:
                                            'Please give valid maximum number of attempts which should be >= 1',
                                        value: 1,
                                    },
                                }}
                                onChange={({ detail }) =>
                                    handleFormValueChange({
                                        maxAttempts: parseInt(detail.value),
                                    })
                                }
                            />
                        </FormField>
                    </SpaceBetween>
                    <SpaceBetween direction="vertical" size="l">
                        <FormField
                            data-testid={AssessmentSettingsForm.PassingScoreLabel}
                            label="Passing score"
                            errorText={get(
                                errors,
                                `${AssessmentSettingsFieldNames.PASSING_SCORE}.message`,
                            )}
                        >
                            <ControlledInput
                                name={AssessmentSettingsFieldNames.PASSING_SCORE}
                                inputMode="numeric"
                                type="number"
                                control={control}
                                rules={{
                                    min: {
                                        message:
                                            'Please give valid passing score which should be >= 0',
                                        value: 0,
                                    },
                                }}
                                onChange={({ detail }) =>
                                    handleFormValueChange({
                                        passingScore: parseInt(detail.value),
                                    })
                                }
                            />
                        </FormField>
                        <FormField
                            data-testid={AssessmentSettingsForm.TimeLimitLabel}
                            label="Time limit (minutes)"
                            errorText={get(
                                errors,
                                `${AssessmentSettingsFieldNames.TIME_LIMIT}.message`,
                            )}
                        >
                            <ControlledInput
                                name={AssessmentSettingsFieldNames.TIME_LIMIT}
                                type="number"
                                inputMode="numeric"
                                control={control}
                                rules={{
                                    min: {
                                        message:
                                            'Please give valid time limit which should be >= 0',
                                        value: 0,
                                    },
                                }}
                                onChange={({ detail }) =>
                                    handleFormValueChange({ timeLimit: parseInt(detail.value) })
                                }
                            />
                        </FormField>
                        <FormField
                            data-testid={AssessmentSettingsForm.WaitTimeLabel}
                            label={'Wait time (hours)'}
                            errorText={get(
                                errors,
                                `${AssessmentSettingsFieldNames.WAIT_TIME}.message`,
                            )}
                        >
                            <ControlledInput
                                name={AssessmentSettingsFieldNames.WAIT_TIME}
                                type="number"
                                inputMode="decimal"
                                control={control}
                                onChange={({ detail }) =>
                                    handleFormValueChange({
                                        waitTime: parseFloat(detail.value),
                                    })
                                }
                                rules={{
                                    min: {
                                        message: 'Please give valid wait time which should be >= 0',
                                        value: 0,
                                    },
                                }}
                            />
                        </FormField>
                    </SpaceBetween>
                </ColumnLayout>
            </SpaceBetween>
        </Container>
    );
};

export default AssessmentSettingsFormFields;
