import React from 'react';
import { AssessmentInput, AssessmentUpdateInput } from '../../../../graphql';
import {
    AssessmentSettingsFormFields,
    AssessmentSettingsFormFieldsProp,
} from '../../../../components';
import { UseFormReturn } from 'react-hook-form';

export interface WizardAssessmentSettingsProps {
    handleFormValueChange: (formUpdates: Partial<AssessmentInput>) => void;
    formValues: AssessmentInput;
    form: UseFormReturn<Partial<AssessmentUpdateInput>, any, undefined>;
}
const WizardAssessmentSettings = ({
    formValues,
    handleFormValueChange,
    form,
}: WizardAssessmentSettingsProps) => {
    const assessmentSettingsFormFieldProps: AssessmentSettingsFormFieldsProp = {
        handleFormValueChange,
        formValues,
        form,
    };

    return <AssessmentSettingsFormFields {...assessmentSettingsFormFieldProps} />;
};

export default WizardAssessmentSettings;
