import React, { Dispatch, SetStateAction, useState } from 'react';
import { AttributeEditor, Form, Select, Textarea } from '@amzn/awsui-components-react';
import QuestionTextInput, {
    QuestionTextInputProps,
} from '../../common/formFields/QuestionTextInput';
import { GenAiQuestion, isAnswerCorrect } from './GenAiQuestionCards';
import { AssessmentQuestionInput } from '../../../graphql';
import { handleSelectedOption } from '../../../utils/formUtils';

interface GenAiAnswerOptions {
    answerText: string;
    isCorrectAnswer: string;
    rationale: string;
}

export interface GenAiQuestionEditProps {
    genAiQuestion: GenAiQuestion;
    setFormValues: Dispatch<SetStateAction<AssessmentQuestionInput>>;
    formValues: AssessmentQuestionInput;
}

const GenAiQuestionEdit = ({
    genAiQuestion,
    setFormValues,
    formValues,
}: GenAiQuestionEditProps) => {
    const [answerAttributeEditorItems, setAnswerAttributeEditorItems] = useState<
        GenAiAnswerOptions[]
    >(
        genAiQuestion.options.map((option) => ({
            answerText: option,
            isCorrectAnswer: `${isAnswerCorrect(genAiQuestion, option)}`,
            rationale: genAiQuestion.rationale[option],
        })),
    );

    const handleAnswerAttributeEditorItems = (item: GenAiAnswerOptions, index: number) => {
        answerAttributeEditorItems[index] = item;
        setAnswerAttributeEditorItems(answerAttributeEditorItems);
        setFormValues({
            ...formValues,
            answers: answerAttributeEditorItems.map((answer) => ({
                answerText: answer.answerText,
                isCorrect: answer.isCorrectAnswer === 'true',
                explanation: answer.rationale,
            })),
        });
    };

    const handleAnswerAttributeEditorRemoveItems = (index: number) => {
        const tempItems = [...answerAttributeEditorItems];
        tempItems.splice(index, 1);
        setAnswerAttributeEditorItems(tempItems);
        setFormValues({
            ...formValues,
            answers: tempItems.map((answer) => ({
                answerText: answer.answerText,
                isCorrect: answer.isCorrectAnswer === 'true',
                explanation: answer.rationale,
            })),
        });
    };

    const handleFormValueChange = (question: AssessmentQuestionInput) => {
        setFormValues({ ...formValues, ...question });
    };

    const questionTextInputProps: QuestionTextInputProps = {
        formValues,
        handleFormValueChange,
    };

    const correctAnswerOptions = [
        { label: 'True', value: 'true' },
        { label: 'False', value: 'false' },
    ];

    return (
        <Form>
            <QuestionTextInput {...questionTextInputProps} />
            <AttributeEditor
                items={answerAttributeEditorItems}
                addButtonText="Add answer"
                onRemoveButtonClick={({ detail: { itemIndex } }) => {
                    handleAnswerAttributeEditorRemoveItems(itemIndex);
                }}
                onAddButtonClick={() =>
                    setAnswerAttributeEditorItems([
                        ...answerAttributeEditorItems,
                        { answerText: '', rationale: '', isCorrectAnswer: 'false' },
                    ])
                }
                removeButtonText="Remove answer"
                definition={[
                    {
                        label: 'Answer text',
                        control: (item, index) => (
                            <Textarea
                                value={item.answerText}
                                onChange={({ detail }) =>
                                    handleAnswerAttributeEditorItems(
                                        {
                                            ...item,
                                            answerText: detail.value,
                                        },
                                        index,
                                    )
                                }
                                placeholder="Enter Answer"
                            />
                        ),
                    },
                    {
                        label: 'Rationale',
                        control: (item, index) => (
                            <Textarea
                                onChange={({ detail }) =>
                                    handleAnswerAttributeEditorItems(
                                        {
                                            ...item,
                                            rationale: detail.value,
                                        },
                                        index,
                                    )
                                }
                                value={item.rationale}
                                placeholder="Enter rationale text"
                            />
                        ),
                    },
                    {
                        label: 'Correct answer',
                        control: (item, index) => (
                            <Select
                                selectedOption={handleSelectedOption({
                                    selectedOption: item.isCorrectAnswer,
                                    options: correctAnswerOptions,
                                })}
                                onChange={({ detail }) => {
                                    handleAnswerAttributeEditorItems(
                                        {
                                            ...item,
                                            isCorrectAnswer: detail.selectedOption.value!,
                                        },
                                        index,
                                    );
                                }}
                                options={correctAnswerOptions}
                            />
                        ),
                    },
                ]}
                empty="No answers"
            />
        </Form>
    );
};

export default GenAiQuestionEdit;
