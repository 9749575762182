import {
    AssessmentLearningObjectiveAttributeEditorItem,
    LearningObjectiveAttributeEditorItem,
} from '../../components';
import {
    AssessmentInput,
    AssessmentQuestionInput,
    AssessmentQuestionUpdateInput,
    CreateLearningObjectiveMutationVariables,
    CreateQuestionBankMutationVariables,
} from '../../graphql';
import { ValidationType } from '../../hooks/useFormValidation';

type ValidationKeys = 'REQUIRED';

export const QUESTION_INPUT_VALIDATION_FIELDS: {
    [key in ValidationKeys]: Array<keyof Partial<AssessmentQuestionInput>>;
} = {
    REQUIRED: ['questionText', 'learningObjectives', 'programs'],
};

export const GENAI_PROMPT_VALIDATION_FIELDS: {
    [key in ValidationKeys]: Array<keyof Partial<AssessmentQuestionInput>>;
} = {
    REQUIRED: ['learningObjectives', 'programs'],
};

export const ASSESSMENT_DETAIL_VALIDATION_FIELDS: {
    [key in ValidationKeys]: Array<keyof Partial<AssessmentInput>>;
} = {
    REQUIRED: ['title'],
};

interface AssessmentLearningObjectiveControlArrayFormValues {
    assessmentLearningObjectiveAttributeEditorItems: AssessmentLearningObjectiveAttributeEditorItem;
}

export const assessmentLearningObjectiveAttributeValidationConfig: {
    [assessmentLearningObjectiveControlArrayFormValuesKey in keyof AssessmentLearningObjectiveControlArrayFormValues]: {
        [key in ValidationType]?: Array<
            keyof AssessmentLearningObjectiveControlArrayFormValues[assessmentLearningObjectiveControlArrayFormValuesKey]
        >;
    };
} = {
    assessmentLearningObjectiveAttributeEditorItems: {
        required: ['id', 'numQuestions'],
    },
};

export const LEARNING_OBJECTIVE_VALIDATION_FIELDS: {
    [key in ValidationKeys]: Array<keyof Partial<CreateLearningObjectiveMutationVariables>>;
} = {
    REQUIRED: ['programs', 'name'],
};

export const QUESTION_BANK_VALIDATION_FIELDS: {
    [key in ValidationKeys]: Array<keyof Partial<CreateQuestionBankMutationVariables>>;
} = {
    REQUIRED: ['programs', 'name'],
};

export const ASSOCIATED_META_ITEMS_VALIDATION_FIELDS: {
    [key in ValidationKeys]: Array<keyof Partial<LearningObjectiveAttributeEditorItem>>;
} = {
    REQUIRED: ['id'],
};
