export enum LearningObjectiveDetailIds {
    NameLabel = 'learningobjective-name-label',
    StatusLabel = 'learningobjective-status-label',
    ProgramLabel = 'learningobjective-program-label',
}

export enum LearningObjectiveEditId {
    FormId = 'learningobjective-edit-form-id',
}

export enum MetaDataIds {
    Status = 'metadata-status-label',
    Name = 'metadata-name-label',
}
