export const FILE_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const FILE_EXTENSION = '.xlsx';

export const EXCEL_ASSESSMENT_QUESTIONS = [
    { header: 'Id', key: 'id' },
    { header: 'Question type', key: 'type', width: 20 },
    { header: 'Learning objective', key: 'learningObjectives', width: 50 },
    { header: 'Question', key: 'questionText', width: 50 },
    { header: 'Answer Choice', key: 'answerChoice-0', width: 50 },
    { header: 'Correct Y/N', key: 'isCorrect-0', width: 13 },
    { header: 'Explanation', key: 'explanation-0', width: 50 },
    { header: 'Answer Choice', key: 'answerChoice-1', width: 50 },
    { header: 'Correct Y/N', key: 'isCorrect-1', width: 13 },
    { header: 'Explanation', key: 'explanation-1', width: 50 },
    { header: 'Answer Choice', key: 'answerChoice-2', width: 50 },
    { header: 'Correct Y/N', key: 'isCorrect-2', width: 13 },
    { header: 'Explanation', key: 'explanation-2', width: 50 },
    { header: 'Answer Choice', key: 'answerChoice-3', width: 50 },
    { header: 'Correct Y/N', key: 'isCorrect-3', width: 13 },
    { header: 'Explanation', key: 'explanation-3', width: 50 },
    { header: 'Answer Choice', key: 'answerChoice-4', width: 50 },
    { header: 'Correct Y/N', key: 'isCorrect-4', width: 13 },
    { header: 'Explanation', key: 'explanation-4', width: 50 },
    { header: 'Answer Choice', key: 'answerChoice-5', width: 50 },
    { header: 'Correct Y/N', key: 'isCorrect-5', width: 13 },
    { header: 'Explanation', key: 'explanation-5', width: 50 },
    { header: 'Status', key: 'status', width: 10 },
    { header: 'Difficulty', key: 'difficulty', width: 10 },
];
