import { APPLICATION_URL } from '../common/constants/application';
import { getNodeEnvironment, NodeEnvironment } from '../common/nodeEnvironment';

export const getApplicationURL = (): string => {
    const nodeEnvironment: NodeEnvironment = getNodeEnvironment();
    switch (nodeEnvironment) {
        case NodeEnvironment.LOCAL: {
            return APPLICATION_URL.LOCAL;
        }
        case NodeEnvironment.BETA: {
            return APPLICATION_URL.BETA;
        }
        case NodeEnvironment.GAMMA: {
            return APPLICATION_URL.GAMMA;
        }
        case NodeEnvironment.PROD: {
            return APPLICATION_URL.PROD;
        }
        default: {
            return APPLICATION_URL.DEVELOPMENT;
        }
    }
};

export const waitFor = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) {
        return text;
    } else {
        return text.substring(0, maxLength) + '...';
    }
};
