import { Button, Container, Form, Header, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    MetadataObjectNameTextArea,
    MetadataObjectNameTextAreaProps,
    MetadataObjectStatusSelect,
    MetadataObjectStatusSelectProps,
} from '../../components';
import {
    AssessmentLanguage,
    CreateLearningObjectiveMutationVariables,
    useCreateLearningObjectiveMutation,
} from '../../graphql';
import { metadataInitialFormValues } from '../../common/constants/metadataObject';
import { useNotifications } from '../../context/NotificationsProvider';
import { ProgramSelectProps, ProgramSelect } from '../../components/common/formFields';
import useFormValidation from '../../hooks/useFormValidation';
import { LEARNING_OBJECTIVE_VALIDATION_FIELDS } from '../../common/constants/validations';

const LearningObjectiveCreate = () => {
    const navigate = useNavigate();
    const [formValues, setFormValues] =
        useState<Partial<CreateLearningObjectiveMutationVariables>>(metadataInitialFormValues);
    const { addNotification } = useNotifications();
    const { isInvalid, validateForm, errors } =
        useFormValidation<Partial<CreateLearningObjectiveMutationVariables>>();
    const runInputValidations = () => {
        return validateForm(formValues!, {
            required: LEARNING_OBJECTIVE_VALIDATION_FIELDS.REQUIRED,
        });
    };

    const [createAssessmentLearningObjective, { loading }] = useCreateLearningObjectiveMutation();

    useEffect(() => {
        if (isInvalid) {
            runInputValidations();
        }
    }, [formValues, isInvalid, validateForm]);

    const handleFormValueChange = (
        formUpdates: Partial<CreateLearningObjectiveMutationVariables>,
    ) => {
        setFormValues({ ...formValues, ...formUpdates });
    };

    const handleCreateLearningObjective = async () => {
        let invalid = runInputValidations();
        if (invalid) {
            return;
        }
        try {
            const { data } = await createAssessmentLearningObjective({
                variables: {
                    name: formValues.name!,
                    status: formValues.status!,
                    language: AssessmentLanguage.En,
                    programs: formValues.programs!,
                },
            });

            addNotification({
                id: `create-learning-objective-${Date.now()}`,
                ...(data?.createAssessmentLearningObjective
                    ? {
                          type: 'success',
                          content: 'Learning objective created successfully.',
                      }
                    : {
                          type: 'error',
                          content: 'There was an error creating the learning objective.',
                      }),
            });
            const { id, version } = data?.createAssessmentLearningObjective!;
            navigate(`/learning-objectives/${id}/version/${version}`);
        } catch (error) {}
    };

    const metadataObjectStatusProps: MetadataObjectStatusSelectProps = {
        formValues,
        handleFormValueChange,
    };

    const metadataObjectNameTextAreaProps: MetadataObjectNameTextAreaProps = {
        formValues,
        handleFormValueChange,
        keyName: 'name',
        label: 'Name',
    };

    const programSelectProps: ProgramSelectProps = {
        formValues: formValues,
        handleFormValueChange,
        errors,
    };

    return (
        <Form
            actions={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button
                        onClick={() => navigate('/learning-objectives')}
                        formAction="none"
                        variant="link"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleCreateLearningObjective}
                        disabled={loading}
                        variant="primary"
                    >
                        {loading ? 'Saving' : 'Save'}
                    </Button>
                </SpaceBetween>
            }
            header={<Header variant="h1">Create Learning Objective</Header>}
        >
            <Container header={<Header variant="h2">Learning objective details</Header>}>
                <SpaceBetween direction="vertical" size="l">
                    <MetadataObjectNameTextArea {...metadataObjectNameTextAreaProps} />
                    <MetadataObjectStatusSelect {...metadataObjectStatusProps} />
                    <ProgramSelect {...programSelectProps} />
                </SpaceBetween>
            </Container>
        </Form>
    );
};

export default LearningObjectiveCreate;
