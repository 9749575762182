import { Box, Header, SpaceBetween, AppLayout, TopNavigation } from '@amzn/awsui-components-react';
import React from 'react';
import { useAuth } from '../../hooks/useAuth/useAuth';
import { Footer } from '../../components';
import { APPLICATION_NAME } from '../../common/constants/application';

export const AccessDenied = () => {
    // const { authErrorMessageId } = useAuth();
    const content = () => {
        return (
            <Box className="error-center">
                <SpaceBetween size="m">
                    <Box>
                        <img src="/public/error_page.svg" alt="error" />
                        <Header variant="h1"> Access Denied </Header>
                        <p>You do not have access to this page.</p>
                        <p>
                            Request access by submitting a{' '}
                            <a href="https://issues.amazon.com/issues/create?template=ae44826f-0f8c-4801-a1ca-095ab8f3d7a4">
                                ticket
                            </a>
                            .
                        </p>
                        {/* <p>{authErrorMessageId}</p> */}
                    </Box>
                    <br />
                </SpaceBetween>
            </Box>
        );
    };

    return (
        <>
            <TopNavigation
                identity={{
                    href: '/',
                    title: APPLICATION_NAME,
                    logo: {
                        src: '/public/aws.png',
                        alt: 'Service',
                    },
                }}
            />
            <AppLayout content={content()} toolsHide navigationHide />
            <Footer />
        </>
    );
};
