import React, { useEffect, useMemo, useState } from 'react';
import { Alert, Box, Button, Header, SpaceBetween, Table } from '@amzn/awsui-components-react';
import {
    AssessmentInput,
    AssessmentQuestion,
    AssessmentUpdateInput,
    useGetAssessmentQuestionsLazyQuery,
    useGetLearningObjectivesLazyQuery,
} from '../../graphql';
import { Dictionary } from '../../interfaces/dictionary';
import SelectQuestionCheckbox, {
    SelectQuestionCheckboxProps,
} from '../common/formFields/SelectQuestionCheckbox';

export interface AssessmentQuestionListProps {
    formValues: Partial<AssessmentInput>;
    learningObjectiveId: string;
    handleFormValueChange: (formUpdates: Partial<AssessmentUpdateInput>) => void;
    handleShowAddQuestionsModal: (learningObjectiveId: string) => void;
    errors?: any;
}
const AssessmentQuestionList = ({
    formValues,
    learningObjectiveId,
    handleShowAddQuestionsModal,
    handleFormValueChange,
    errors,
}: AssessmentQuestionListProps) => {
    const [questions, setQuestions] = useState<Array<AssessmentQuestion>>([]);
    const [learningObjectiveDict, setLearningObjectiveDict] = useState({});
    const [formValueSelectedQuestions, setFormValueSelectedQuestions] = useState(
        new Set<AssessmentQuestion>(),
    );

    const [getFilteredQuestions] = useGetAssessmentQuestionsLazyQuery({
        fetchPolicy: 'network-only',
    });
    const [getLearningObjectives] = useGetLearningObjectivesLazyQuery();
    useEffect(() => {
        handleGetQuestions();
    }, [formValues]);
    const [questionDict, setQuestionDict] = useState<Dictionary<AssessmentQuestion>>();

    const handleGetQuestions = async () => {
        const questionsByLearningObjective = formValues.questions!.filter(
            (question) => question.learningObjectives![0] === learningObjectiveId,
        );
        if (questionsByLearningObjective.length > 0) {
            const { data } = await getFilteredQuestions({
                variables: {
                    id: questionsByLearningObjective.map((question) => question.id),
                },
            });

            let tempQuestionDict = {};
            if (data?.assessmentQuestions && data?.assessmentQuestions.questions.length !== 0) {
                data?.assessmentQuestions?.questions.forEach((question) => {
                    // Questions dictionary
                    tempQuestionDict = {
                        ...tempQuestionDict,
                        [question.id]: question,
                    };
                });
                setQuestionDict(tempQuestionDict);
                const questions = [...data?.assessmentQuestions?.questions!];
                const sortedQuestions = questions.sort((a, b) =>
                    a.modifiedTimestamp! < b.modifiedTimestamp! ? 1 : -1,
                );

                handleGetLearningObjectives({
                    questions: sortedQuestions,
                    learningObjectives: [learningObjectiveId],
                });
            }
        } else {
            handleGetLearningObjectives({
                questions: [],
                learningObjectives: formValues.learningObjectives!.map((lo) => lo.id),
            });
        }
    };

    const handleGetLearningObjectives = async ({
        questions,
        learningObjectives,
    }: {
        questions: AssessmentQuestion[];
        learningObjectives: string[];
    }) => {
        const { data } = await getLearningObjectives({
            variables: {
                learningObjectives,
                size: 500,
            },
        });
        let learningObjectiveDict = {};
        data!.assessmentLearningObjectives.metadataObjects.forEach((objective) => {
            // Learning objective dictionary
            learningObjectiveDict = {
                ...learningObjectiveDict,
                [objective.id]: objective.name,
            };
        });
        const questionsWithLO = questions.map((question) => {
            return {
                ...question,
                learningObjectives: question.learningObjectives?.map(
                    (lo) => learningObjectiveDict[lo as keyof typeof learningObjectiveDict],
                ),
            };
        });
        setQuestions(questionsWithLO);
        setLearningObjectiveDict(learningObjectiveDict);
    };

    const handleFormValueSelectedQuestionChange = (selectedQuestions: Set<AssessmentQuestion>) =>
        setFormValueSelectedQuestions(new Set(Array.from(selectedQuestions)));

    const handleSelectedFormValueQuestions = () => {
        const formValueQuestions = formValues.questions!;
        const questionsToDelete = Array.from(formValueSelectedQuestions!);
        const questions = formValueQuestions.filter(
            (formValueQuestion) =>
                !questionsToDelete.some((toDelete) => formValueQuestion.id === toDelete.id),
        );
        handleFormValueChange({ questions });
        setFormValueSelectedQuestions!(new Set());
    };

    const columnDefinitions = useMemo(() => {
        return [
            {
                id: 'selectQuestion',
                header: '',
                cell: (item: AssessmentQuestion) => {
                    const selectQuestionCheckboxProps: SelectQuestionCheckboxProps = {
                        item: questionDict
                            ? questionDict[item.id as keyof typeof questionDict]
                            : null,
                        selectedQuestions: formValueSelectedQuestions,
                        handleSelectedQuestionChange: handleFormValueSelectedQuestionChange,
                    };

                    return <SelectQuestionCheckbox {...selectQuestionCheckboxProps} />;
                },
            },
            {
                id: 'questionText',
                header: 'Question Text',
                cell: (item: AssessmentQuestion) =>
                    questionDict && questionDict[item.id] ? questionDict[item.id].questionText : '',
                isRowHeader: true,
            },
            {
                id: 'status',
                header: 'Status',
                cell: (item: AssessmentQuestion) =>
                    questionDict && questionDict[item.id] ? questionDict[item.id].status : '',
                isRowHeader: true,
            },
            {
                id: 'type',
                header: 'Type',
                cell: (item: AssessmentQuestion) =>
                    questionDict && questionDict[item.id] ? questionDict[item.id].type : '',
                isRowHeader: true,
            },
            {
                id: 'programs',
                header: 'Programs',
                cell: (item: AssessmentQuestion) =>
                    questionDict && questionDict[item.id]
                        ? questionDict[item.id].programs?.join(', ')
                        : '',
                isRowHeader: true,
            },
            {
                id: 'difficulty',
                header: 'Difficulty',
                cell: (item: AssessmentQuestion) =>
                    questionDict && questionDict[item.id] ? questionDict[item.id].difficulty : '',
                isRowHeader: true,
            },
            {
                id: 'scoringMethod',
                header: 'Scoring Method',
                cell: (item: AssessmentQuestion) =>
                    questionDict && questionDict[item.id]
                        ? questionDict[item.id].scoringMethod
                        : '',
                isRowHeader: true,
            },
        ];
    }, [questionDict, learningObjectiveDict]);

    return (
        <>
            <Table
                header={
                    <SpaceBetween size="s" direction="vertical">
                        {errors && errors.questions && (
                            <Alert statusIconAriaLabel="Error" type="error">
                                {errors.questions}
                            </Alert>
                        )}
                        <Header
                            className="add-question-h3"
                            variant="h3"
                            actions={
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Button
                                        disabled={!formValueSelectedQuestions?.size}
                                        onClick={handleSelectedFormValueQuestions}
                                    >
                                        Delete
                                    </Button>
                                    <Button
                                        onClick={() =>
                                            handleShowAddQuestionsModal(learningObjectiveId)
                                        }
                                    >
                                        Add questions
                                    </Button>
                                </SpaceBetween>
                            }
                        >
                            {
                                learningObjectiveDict[
                                    learningObjectiveId as keyof typeof learningObjectiveDict
                                ]
                            }
                        </Header>
                    </SpaceBetween>
                }
                items={questions ?? []}
                columnDefinitions={columnDefinitions}
                enableKeyboardNavigation
                empty={
                    <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
                        This assessment has no questions.
                    </Box>
                }
            />
        </>
    );
};

export default AssessmentQuestionList;
