export enum UserMgmtTestIds {
    AddUserButtonId = 'add-user-button-id',
    AddUserFormCancelButton = 'add-user-form-cancel',
    AddUserFormId = 'add-user-form-id',
    AddUserFormSubmissionButton = 'add-user-form-submit',
    AddUserModalId = 'add-user-modal-id',
    AliasAddUserFormField = 'alias-form-field',
    EmailAddUserFormField = 'alias-form-field',
    ProgramsAddUserFormField = 'programs-form-field',
    ReloadButtonId = 'reload-button-id',
    RolesAddUserFormField = 'role-form-field',
    RolesUpdateUserFormField = 'update-roles-form-field',
    StatusUpdateUserFormField = 'update-status-form-field',
    UpdateUserContainer = 'update-user-container',
    UpdateUserEmailLabel = 'update-user-name-label',
    UpdateUserFormCancelButton = 'update-user-form-cancel',
    UpdateUserFormId = 'update-user-form-id',
    UpdateUserFormSubmissionButton = 'update-user-form-submit',
    UpdateUserLoading = 'update-user-loading',
    UpdateUserModalId = 'update-user-modal-id',
    UpdateUserNameLabel = 'update-user-name-label',
    UserListAccessDenied = 'user-mgmt-access-denied',
    UserListBlankPlaceholders = 'user-list-blank-placeholders',
    UserListError = 'user-list-error',
    UserListLoading = 'user-mgmt-loading',
    UserListSearchInput = 'user-list-search-input',
    UserMgmtAccessDenied = 'user-mgmt-access-denied',
}
