import React, { useEffect, useMemo, useState } from 'react';
import { Button, StatusIndicator, Table } from '@amzn/awsui-components-react';
import {
    AssessmentAnswerInput,
    AssessmentQuestion,
    AssessmentQuestionInput,
    useUpdateAssessmentQuestionMutation,
} from '../../../graphql';
import AnswerListTableHeader, { AnswerListTableHeaderProps } from './AnswerListTableHeader';
import AnswerModal, { AnswerModalProps } from '../AnswerModal';
import { useParams } from 'react-router-dom';

export interface AnswerListProps {
    formValues: Partial<AssessmentQuestionInput>;
    handleFormValueChange: (formUpdates: Partial<AssessmentQuestionInput>) => void;
    mode: string;
    question?: Partial<AssessmentQuestion>;
}

const initialAssessmentAnswerInput = {
    answerText: '',
    explanation: '',
    isCorrect: false,
};

const AnswerList = ({ formValues, handleFormValueChange, mode }: AnswerListProps) => {
    const { id = '', version } = useParams();
    const [isAnswerModalVisible, setIsAnswerModalVisible] = useState(false);
    const [formValue, setFormValue] = useState<AssessmentAnswerInput>(initialAssessmentAnswerInput);
    const [editIndex, setEditIndex] = useState<number>();
    const [answerItems, setAnswerItems] = useState<AssessmentAnswerInput[]>([]);
    const [answerModalFormType, setAnswerModalFormType] = useState('createAnswerForm');
    const [updateQuestion] = useUpdateAssessmentQuestionMutation();

    const columnDefinitions = useMemo(() => {
        const definitions = [
            {
                id: 'answerText',
                header: 'Answer Text',
                cell: (item: AssessmentAnswerInput) => item.answerText,
                isRowHeader: true,
            },
            {
                id: 'answerCorrectValue',
                header: 'Correct Answer',
                cell: (item: AssessmentAnswerInput) =>
                    item.isCorrect ? (
                        <StatusIndicator>Yes</StatusIndicator>
                    ) : (
                        <StatusIndicator type="error">No</StatusIndicator>
                    ),
                isRowHeader: true,
            },
            {
                id: 'answerExplanationText',
                header: 'Answer Explanation Text',
                cell: (item: AssessmentAnswerInput) => item.explanation,
                isRowHeader: true,
            },
        ];

        if (mode !== 'detail') {
            return [
                ...definitions,
                {
                    id: 'answerEdit',
                    header: 'Edit',
                    cell: (item: AssessmentAnswerInput) => (
                        <Button
                            onClick={() => handleEditAnswerModal(item, formValues)}
                            variant="inline-link"
                        >
                            Edit
                        </Button>
                    ),
                },
                {
                    id: 'answerRemove',
                    header: 'Remove',
                    cell: (item: AssessmentAnswerInput) => (
                        <Button
                            onClick={() => handleRemoveAnswerToQuestion(item)}
                            variant="inline-link"
                        >
                            Remove
                        </Button>
                    ),
                },
            ];
        }
        return definitions;
    }, [formValues]);

    useEffect(() => {
        if (formValues?.answers && formValues.answers.length !== 0) {
            setAnswerItems(formValues.answers);
        }
    }, [formValues?.answers]);

    const handleAnswerFormValueChange = (answer: AssessmentAnswerInput) => {
        setFormValue({ ...formValue, ...answer });
    };

    const handleAnswerSaveToQuestion = () => {
        const existingAnswers = formValues.answers as AssessmentAnswerInput[];
        handleFormValueChange({ answers: [...existingAnswers!, formValue] });
        handleCloseModal();
    };

    const handleAnswerUpdateToQuestion = async (answer: AssessmentAnswerInput) => {
        const newAnswerArray = [...formValues.answers!];
        newAnswerArray[editIndex!] = answer;
        handleFormValueChange({ answers: newAnswerArray });
        handleCloseModal();
        if (answerModalFormType === 'editAnswerForm') {
            const {
                status,
                type,
                scoringMethod,
                difficulty,
                questionText,
                programs,
                learningObjectives,
            } = formValues;
            await updateQuestion({
                variables: {
                    id: id!,
                    version: Number(version!),
                    status: status!,
                    type: type!,
                    scoringMethod: scoringMethod!,
                    answers: newAnswerArray?.map(({ answerText, isCorrect, explanation }) => ({
                        answerText,
                        isCorrect,
                        explanation: explanation ?? '',
                    }))!,
                    difficulty: difficulty!,
                    questionText: questionText!,
                    programs: programs!,
                    learningObjectives: learningObjectives!,
                },
            });
        }
    };

    const handleRemoveAnswerToQuestion = (item: AssessmentAnswerInput) => {
        // Without a unique id, a text match on 2 fields is the only option for indexing
        const index = formValues.answers!.findIndex(
            (value) =>
                value.answerText === item.answerText && value.explanation === item.explanation,
        );
        const updatedAnswers = [...formValues.answers!];
        updatedAnswers.splice(index!, 1);
        handleFormValueChange({
            answers: updatedAnswers,
        });
        setAnswerItems(updatedAnswers);
    };

    const handleCloseModal = () => {
        setFormValue(initialAssessmentAnswerInput);
        setIsAnswerModalVisible(false);
    };

    const handleEditAnswerModal = (
        item: AssessmentAnswerInput,
        formValues: Partial<AssessmentQuestionInput>,
    ) => {
        // Without a unique id, a text match on 2 fields is the only option for indexing
        const index = formValues.answers!.findIndex(
            (value) =>
                value.answerText === item.answerText && value.explanation === item.explanation,
        );
        setEditIndex(index);
        setFormValue(formValues.answers![index]);
        setIsAnswerModalVisible(true);
        setAnswerModalFormType('editAnswerForm');
    };

    const answerModalProps: AnswerModalProps = {
        isVisible: isAnswerModalVisible,
        handleCloseModal,
        formValue,
        questionInput: formValues,
        handleFormValueChange: handleAnswerFormValueChange,
        handleAnswerSaveToQuestion,
        handleAnswerUpdateToQuestion,
        answerModalFormType,
    };

    const answerTableHeaderProps: AnswerListTableHeaderProps = {
        setIsAnswerModalVisible,
        setAnswerModalFormType,
        mode,
    };

    return (
        <>
            <Table
                header={<AnswerListTableHeader {...answerTableHeaderProps} />}
                items={answerItems}
                columnDefinitions={columnDefinitions}
                resizableColumns
            />
            {formValues && <AnswerModal {...answerModalProps} />}
        </>
    );
};

export default AnswerList;
