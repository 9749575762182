import React, { useEffect, useMemo, useState } from 'react';
import {
    Box,
    Button,
    Header,
    Link,
    Pagination,
    PropertyFilterProps,
    SpaceBetween,
    Table,
} from '@amzn/awsui-components-react';
import { useDispatch } from 'react-redux';
import { setBreadcrumbs, setContentType } from '../../../reducers/navigationReducer';
import { ASSESSMENT_CREATE_ROUTE, ASSESSMENT_LIST_ROUTE } from '../../../router/router';
import {
    Assessment,
    useGetAssessmentsLazyQuery,
    useGetLearningObjectivesLazyQuery,
} from '../../../graphql';
import AssessmentsListPropertyFilter, {
    LOCAL_STORAGE_ASSESSMENTS_FILTER_KEY,
    StoredAssessmentsFilters,
} from './AssessmentsListPropertyFilter';
import { useNavigate } from 'react-router-dom';
import { truncateText } from '../../../utils/appUtils';

const AssessmentListTableHeader = () => {
    const navigate = useNavigate();

    return (
        <Header
            actions={
                <SpaceBetween size="xs" direction="horizontal">
                    <SpaceBetween size="xs" direction="horizontal">
                        <Button
                            onClick={() => navigate(ASSESSMENT_CREATE_ROUTE.path)}
                            variant="primary"
                        >
                            Create assessment
                        </Button>
                    </SpaceBetween>
                </SpaceBetween>
            }
        >
            Assessments
        </Header>
    );
};

const AssessmentList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [assessments, setAssessments] = useState<Assessment[]>([]);
    const [query, setQuery] = useState<PropertyFilterProps.Query>({
        tokens: [],
        operation: 'and',
    });
    const [learningObjectiveDict, setLearningObjectiveDict] = useState({});

    const [getFilteredAssessments, { data, loading: assessmentsLoading }] =
        useGetAssessmentsLazyQuery({
            fetchPolicy: 'network-only',
        });

    const [getAssessmentsLearningObjectives, { data: learningObjectivesData }] =
        useGetLearningObjectivesLazyQuery({
            fetchPolicy: 'network-only',
        });

    useEffect(() => {
        dispatch(
            setBreadcrumbs([
                {
                    text: ASSESSMENT_LIST_ROUTE.title,
                    href: ASSESSMENT_LIST_ROUTE.path,
                },
            ]),
        );
        dispatch(setContentType('table'));
    }, [dispatch]);

    useEffect(() => {
        const storedFilterString = localStorage.getItem(LOCAL_STORAGE_ASSESSMENTS_FILTER_KEY);
        if (storedFilterString) {
            try {
                let savedFilters = JSON.parse(storedFilterString) as StoredAssessmentsFilters;
                try {
                    if (savedFilters.propertyFilters) {
                        setQuery(savedFilters.propertyFilters);
                    }
                    handleGetAssessments(savedFilters);
                } catch (e) {}
            } catch (e) {}
        } else {
            handleGetAssessments();
        }
    }, []);

    const handleGetAssessments = async (filters?: any) => {
        let tokens = [];
        if (filters && filters.propertyFilters.tokens.length !== 0) {
            tokens = filters.propertyFilters.tokens.map(
                (token: PropertyFilterProps.FilteringOption) => {
                    if (token.propertyKey === 'difficulty') {
                        return { difficulty: [Number(token.value)] };
                    }
                    return { [token.propertyKey]: [token.value] };
                },
            );
        }
        const searchQuery = Object.assign({}, ...tokens);
        const { data } = await getFilteredAssessments({
            variables: {
                ...searchQuery,
            },
        });
        const assessments = [...data?.assessments?.assessments!];
        const sortedAssessments = assessments.sort((a, b) =>
            a.modifiedTimestamp! < b.modifiedTimestamp! ? 1 : -1,
        );
        handleGetAssessmentsLearningObjectives(sortedAssessments!);
    };

    const handleGetAssessmentsLearningObjectives = async (assessments: Assessment[]) => {
        let learningObjectives: string[] = [];
        assessments.forEach((assessment) => {
            assessment.learningObjectives?.forEach((lo) => {
                learningObjectives.push(lo.id);
            });
        });
        const { data } = await getAssessmentsLearningObjectives({
            variables: {
                learningObjectives,
                size: 500,
            },
        });
        let learningObjectiveDict = {};
        data!.assessmentLearningObjectives.metadataObjects.forEach((objective) => {
            // Learning objective dictionary
            learningObjectiveDict = {
                ...learningObjectiveDict,
                [objective.id]: objective.name,
            };
        });
        const assessmentsWithLO = assessments.map((assessment) => {
            return {
                ...assessment,
                learningObjectives: assessment.learningObjectives?.map((lo) => ({
                    ...lo,
                    learningObjective:
                        learningObjectiveDict[lo.id as keyof typeof learningObjectiveDict],
                })),
            };
        });
        setLearningObjectiveDict(learningObjectiveDict);
        setAssessments(assessmentsWithLO);
    };

    const columnDefinitions = useMemo(() => {
        return [
            {
                id: 'assessmentTitle',
                header: 'Title',
                cell: (item: Assessment) => (
                    <Link
                        variant="secondary"
                        href={`/assessments/${item.id}/version/${item.version}`}
                        onFollow={(e) => {
                            e.preventDefault();
                            navigate(`/assessments/${item.id}/version/${item.version}`, {
                                state: { ...item },
                            });
                        }}
                    >
                        {item.title}
                    </Link>
                ),
                isRowHeader: true,
            },
            {
                id: 'assessmentStatus',
                header: 'Status',
                cell: (item: Assessment) => item.status,
                isRowHeader: true,
            },
            {
                id: 'assessmentLearningObjectives',
                header: 'Learning objectives',
                cell: (item: Assessment) =>
                    truncateText(
                        item
                            .learningObjectives!.map(
                                (lo) =>
                                    learningObjectiveDict[
                                        lo.id as keyof typeof learningObjectiveDict
                                    ],
                            )
                            .join(', '),
                        500,
                    ),
                isRowHeader: true,
            },
            {
                id: 'assessmentPrograms',
                header: 'Programs',
                cell: (item: Assessment) => item.programs?.join(', '),
                isRowHeader: true,
            },
            {
                id: 'assessmentVersion',
                header: 'Version',
                cell: (item: Assessment) => item.version,
                isRowHeader: true,
            },
        ];
    }, [learningObjectiveDict]);

    const assessmentsListPropertyFilterProps = {
        query,
        setQuery,
        handleGetAssessments,
    };

    return (
        <Table
            stickyHeader={true}
            header={<AssessmentListTableHeader />}
            variant="full-page"
            items={assessments}
            columnDefinitions={columnDefinitions}
            loadingText="Loading assessments"
            enableKeyboardNavigation
            filter={<AssessmentsListPropertyFilter {...assessmentsListPropertyFilterProps} />}
            pagination={<Pagination currentPageIndex={1} pagesCount={1} onChange={() => {}} />}
            empty={
                <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
                    No assessments found.
                </Box>
            }
            loading={assessmentsLoading}
        />
    );
};

export default AssessmentList;
