import React from 'react';
import { Badge, Box, Header } from '@amzn/awsui-components-react';
import { GenAiQuestion } from './GenAiQuestionCards';

export interface GenAiQuetionCardHeaderProps {
    genAiQuestion: GenAiQuestion;
    isEditMode: boolean;
}

const GenAiQuestionCardHeader = ({ genAiQuestion, isEditMode }: GenAiQuetionCardHeaderProps) => {
    return (
        <Header variant="h2" description={<strong>{genAiQuestion.question}</strong>}>
            {`Question ${genAiQuestion.index + 1}`}
            <Box padding={{ top: 's', bottom: 's' }}>
                <Badge>Multiple Choice</Badge>
            </Box>
        </Header>
    );
};

export default GenAiQuestionCardHeader;
