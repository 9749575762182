import { PropertyFilter, PropertyFilterProps } from '@amzn/awsui-components-react';
import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { AssessmentStatus } from '../../../common/constants/assessments';
import { I18N_STRINGS } from '../../../common/constants/propertyFilterKeys';

export const LOCAL_STORAGE_ASSESSMENTS_FILTER_KEY = 'assessments-filter-key';

export interface StoredAssessmentsFilters {
    propertyFilters?: any;
}
interface AssessmentsListPropertyFilterProps {
    query: PropertyFilterProps.Query;
    setQuery: Dispatch<SetStateAction<PropertyFilterProps.Query>>;
    handleGetAssessments: (filters: any) => Promise<void>;
}

const AssessmentsListPropertyFilter = ({
    query,
    setQuery,
    handleGetAssessments,
}: AssessmentsListPropertyFilterProps) => {
    const assessmentsFilterProperties: Array<PropertyFilterProps.FilteringProperty> =
        useMemo(() => {
            return [
                {
                    key: 'status',
                    operators: ['='],
                    propertyLabel: 'Status',
                    groupValuesLabel: 'Assessment status value',
                },
            ];
        }, []);

    const assessmentFilterOptions = useMemo(() => {
        let filteringOptions = Object.keys(AssessmentStatus).map((status) => ({
            propertyKey: 'status',
            value: AssessmentStatus[status as keyof typeof AssessmentStatus],
        }));

        filteringOptions = [...filteringOptions];
        return filteringOptions;
    }, []);

    const handleFilterUpdate = async (e: any) => {
        setQuery(e.detail);
        const storedFilters = localStorage.getItem(LOCAL_STORAGE_ASSESSMENTS_FILTER_KEY);
        let newFilter = {} as StoredAssessmentsFilters;
        storedFilters
            ? (newFilter = {
                  ...JSON.parse(storedFilters),
                  propertyFilters: e.detail,
              })
            : (newFilter = { propertyFilters: e.detail });

        localStorage.setItem(
            LOCAL_STORAGE_ASSESSMENTS_FILTER_KEY,
            JSON.stringify({ ...newFilter }),
        );
        handleGetAssessments({ propertyFilters: e.detail });
    };

    return (
        <div style={{ flex: 1 }}>
            <PropertyFilter
                onChange={handleFilterUpdate}
                query={query}
                expandToViewport
                filteringOptions={assessmentFilterOptions}
                filteringProperties={assessmentsFilterProperties}
                hideOperations
                i18nStrings={I18N_STRINGS}
            />
        </div>
    );
};

export default AssessmentsListPropertyFilter;
