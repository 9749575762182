import Dashboard from './Dashboard';
import QuestionsList from './questions/questionsList/QuestionsList';
import QuestionCreate from './questions/QuestionCreate';
import { AccessDenied } from './errors/AccessDenied';
import QuestionDetail from './questions/QuestionDetail';
import QuestionEdit from './questions/QuestionEdit';
import QuestionCreateGenAi from './questions/genAi/QuestionCreateGenAi';
import QuestionBankList from './questionBanks/questionBankList/QuestionBankList';
import AssessmentList from './assessments/assessmentsList/AssessmentList';
import AssessmentWizardCreate from './assessments/assessmentWizard/AssessmentWizardCreate';
import AssessmentDetail from './assessments/AssessmentDetail';
import LearningObjectiveList from './learningObjectives/learningObjectiveList/LearningObjectiveList';
import LearningObjectiveCreate from './learningObjectives/LearningObjectiveCreate';
import LearningObjectiveDetail from './learningObjectives/LearningObjectiveDetail';
import LearningObjectiveEdit from './learningObjectives/LearningObjectiveEdit';
import AssessmentEditDetails from './assessments/AssessmentEdit/AssessmentEditDetails';
import AssessmentEditQuestions from './assessments/AssessmentEdit/AssessmentEditQuestions';
import AssessmentEditSettings from './assessments/AssessmentEdit/AssessmentEditSettings';
export {
    Dashboard,
    QuestionsList,
    QuestionCreate,
    AccessDenied,
    QuestionDetail,
    QuestionEdit,
    QuestionCreateGenAi,
    QuestionBankList,
    AssessmentList,
    LearningObjectiveList,
    AssessmentDetail,
    AssessmentWizardCreate,
    LearningObjectiveCreate,
    LearningObjectiveDetail,
    LearningObjectiveEdit,
    AssessmentEditDetails,
    AssessmentEditQuestions,
    AssessmentEditSettings,
};
