import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import {
    Box,
    Button,
    ButtonDropdown,
    Header,
    Link,
    Pagination,
    PropertyFilterProps,
    Select,
    SpaceBetween,
    Table,
    TableProps,
} from '@amzn/awsui-components-react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setBreadcrumbs, setContentType } from '../../../reducers/navigationReducer';
import { QUESTION_CREATE_ROUTE, QUESTIONS_LIST_ROUTE } from '../../../router/router';
import QuestionsListPropertyFilter, {
    LOCAL_STORAGE_QUESTION_FILTER_KEY,
    QuestionsListPropertyFilterProps,
    StoredQuestionFilters,
} from './QuestionsListPropertyFilter';
import {
    AssessmentMetadataObject,
    AssessmentQuestion,
    useGetAssessmentQuestionsLazyQuery,
    useGetLearningObjectivesLazyQuery,
    useUpdateAssessmentQuestionMutation,
} from '../../../graphql/';
import { Dictionary } from '../../../interfaces/dictionary';
import { LegalModal, LegalModalProps } from '../../../components';
import { QuestionStatus } from '../../../common/constants/questions';
import { useNotifications } from '../../../context/NotificationsProvider';
import { Workbook } from 'exceljs';
import { ExcelUtil } from '../../../utils/excel';

interface QuestionTableHeaderProps {
    setShowLegalModal: Dispatch<SetStateAction<boolean>>;
    questions: AssessmentQuestion[];
    currentCount: number;
}

const QuestionsTableHeader = ({
    setShowLegalModal,
    questions,
    currentCount,
}: QuestionTableHeaderProps) => {
    const navigate = useNavigate();

    const onButtonItemClick = ({ detail }: any) => {
        if (detail.id === 'create-ai') {
            setShowLegalModal(true);
        } else {
            navigate(QUESTION_CREATE_ROUTE.path);
        }
    };

    const handleExportClick = () => {
        const workbook = new Workbook();
        const excelUtil = new ExcelUtil(workbook);
        excelUtil
            .buildAssessmentQuestionExportWorkbook()
            .populateAssessmentQuestionExportWorkbook(questions)
            .styleWorkbook()
            .cellProtection()
            .writeWorkbook();
    };

    return (
        <Header
            counter={`(${currentCount})`}
            actions={
                <SpaceBetween size="xs" direction="horizontal">
                    <SpaceBetween size="xs" direction="horizontal">
                        <Button onClick={handleExportClick}>Export questions</Button>
                        <ButtonDropdown
                            variant="primary"
                            onItemClick={onButtonItemClick}
                            items={[
                                { text: 'With GenAI', id: 'create-ai', disabled: false },
                                { text: 'With manual entry', id: 'create', disabled: false },
                            ]}
                        >
                            Create question
                        </ButtonDropdown>
                    </SpaceBetween>
                </SpaceBetween>
            }
        >
            Questions
        </Header>
    );
};

const QuestionList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [questions, setQuestions] = useState<Array<AssessmentQuestion>>([]);
    const [showLegalModal, setShowLegalModal] = useState(false);
    const { addNotification } = useNotifications();
    const [learningObjectiveDict, setLearningObjectiveDict] =
        useState<Dictionary<AssessmentMetadataObject>>();
    const [currentPageIndex, setCurrentPageIndex] = useState(1);
    const [_, setFrom] = useState(0);
    const [size] = useState(100);
    const [currentCount, setCurrentCount] = useState(0);
    const [pagesCount, setPagesCount] = useState(1);
    const [isTableLoading, setIsTableLoading] = useState(false);
    const [query, setQuery] = useState<PropertyFilterProps.Query>({
        tokens: [],
        operation: 'and',
    });

    const [getFilteredQuestions, { fetchMore }] = useGetAssessmentQuestionsLazyQuery({
        fetchPolicy: 'network-only',
    });

    const [updateQuestion] = useUpdateAssessmentQuestionMutation();

    const [getLearningObjectives] = useGetLearningObjectivesLazyQuery({
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        dispatch(
            setBreadcrumbs([
                {
                    text: QUESTIONS_LIST_ROUTE.title,
                    href: QUESTIONS_LIST_ROUTE.path,
                },
            ]),
        );
        dispatch(setContentType('table'));
    }, [dispatch]);

    useEffect(() => {
        const storedFilterString = localStorage.getItem(LOCAL_STORAGE_QUESTION_FILTER_KEY);

        if (storedFilterString) {
            let savedFilters = JSON.parse(storedFilterString) as StoredQuestionFilters;
            try {
                if (savedFilters.propertyFilters) {
                    setQuery(savedFilters.propertyFilters);
                }
                handleGetQuestions(savedFilters);
            } catch (e) {}
        } else {
            handleGetQuestions();
        }
    }, []);

    const handleGetQuestions = async (filters?: any) => {
        setIsTableLoading(true);
        let tokens = [];
        if (filters && filters.propertyFilters.tokens.length !== 0) {
            tokens = filters.propertyFilters.tokens.map(
                (token: PropertyFilterProps.FilteringOption) => {
                    if (token.propertyKey === 'difficulty') {
                        return { difficulty: [Number(token.value)] };
                    }
                    if (token.propertyKey === 'learningObjectives') {
                        return { learningObjectives: [token.value] };
                    }
                    return { [token.propertyKey]: [token.value] };
                },
            );
        }

        const searchQuery = tokens.reduce((acc: any, token: any) => {
            const key = Object.keys(token)[0];
            const value = token[key][0];
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(value);
            return acc;
        }, {});

        const { data } = await getFilteredQuestions({
            variables: {
                ...searchQuery,
            },
        });

        const questions = data?.assessmentQuestions?.questions!;
        if (questions) {
            const totalCount = data.assessmentQuestions?.totalCount!;
            const totalPagesCount = Math.ceil(totalCount / size);
            setPagesCount(totalPagesCount);
            setCurrentCount(totalCount);
            const sortedQuestions = [...questions].sort((a, b) =>
                a.modifiedTimestamp! < b.modifiedTimestamp! ? 1 : -1,
            );
            handleGetLearningObjectives(sortedQuestions, searchQuery.learningObjectives);
        }
    };

    const handleGetLearningObjectives = async (
        questions: AssessmentQuestion[],
        additionalLearningObjectiveIds: string[] = [],
    ) => {
        let learningObjectives: string[] = additionalLearningObjectiveIds;
        questions.forEach((question) => {
            question.learningObjectives?.forEach((lo) => {
                learningObjectives.push(lo);
            });
        });
        const { data } = await getLearningObjectives({
            variables: {
                learningObjectives,
            },
        });
        let learningObjectiveDict = {};
        data!.assessmentLearningObjectives.metadataObjects.forEach((objective) => {
            // Learning objective dictionary
            learningObjectiveDict = {
                ...learningObjectiveDict,
                [objective.id]: objective,
            };
        });
        const questionsWithLO = questions.map((question) => {
            return {
                ...question,
                learningObjectives: question.learningObjectives?.map(
                    (lo) => learningObjectiveDict[lo as keyof typeof learningObjectiveDict],
                ),
            };
        });
        setQuestions(questionsWithLO);
        setLearningObjectiveDict(learningObjectiveDict);
        setIsTableLoading(false);
    };

    const handlePaginationChange = async (event: any) => {
        setIsTableLoading(true);
        const { currentPageIndex } = event.detail;

        const updatedPageIndex = currentPageIndex;
        const from = (updatedPageIndex - 1) * size;

        setCurrentPageIndex(updatedPageIndex);
        setFrom(from);
        const questionsData = await fetchMore({
            variables: {
                from,
                size,
            },
        });
        const questions = questionsData.data.assessmentQuestions?.questions!;
        if (questions) {
            const totalCount = questionsData.data.assessmentQuestions?.totalCount!;
            const totalPagesCount = Math.ceil(totalCount / size);
            setPagesCount(totalPagesCount);
            setCurrentCount(totalCount);
            const sortedQuestions = [...questions].sort((a, b) =>
                a.modifiedTimestamp! < b.modifiedTimestamp! ? 1 : -1,
            );
            handleGetLearningObjectives(sortedQuestions);
        }
    };

    const handleInlineEdit = async ({
        updatedQuestion,
        column,
        newValue,
        currentTableItem,
    }: {
        updatedQuestion: AssessmentQuestion;
        column: TableProps.ColumnDefinition<AssessmentQuestion>;
        newValue: any;
        currentTableItem: AssessmentQuestion;
    }) => {
        try {
            const {
                id,
                version,
                status,
                type,
                scoringMethod,
                answers,
                difficulty,
                questionText,
                programs,
                learningObjectives,
            } = updatedQuestion;
            const { data } = await updateQuestion({
                variables: {
                    id: id!,
                    version: version!,
                    status: status!,
                    type: type!,
                    scoringMethod: scoringMethod!,
                    answers: answers?.map(({ answerText, isCorrect, explanation }) => ({
                        answerText: answerText!,
                        isCorrect: isCorrect!,
                        explanation: explanation ?? '',
                    }))!,
                    difficulty: difficulty!,
                    questionText: questionText!,
                    programs: programs!,
                    learningObjectives: learningObjectives!,
                },
            });
            // @ts-ignore - TODO Manually updating the table till more research on updating the cache https://www.apollographql.com/docs/react/data/mutations/#updating-the-cache-directly
            currentTableItem[column.id] = newValue;
            addNotification({
                id: `edit-question-${Date.now()}`,
                ...(data?.updateAssessmentQuestion
                    ? {
                          type: 'success',
                          content: 'Question was updated successfully.',
                      }
                    : {
                          type: 'error',
                          content: 'There was an error updating the question.',
                      }),
            });
        } catch (error) {
            addNotification({
                id: `error-edit-question-${Date.now()}`,
                type: 'error',
                content: 'There was an error updating the question.',
            });
        }
    };

    const columnDefinitions = useMemo(() => {
        return [
            {
                id: 'questionText',
                header: 'Question Text',
                cell: (item: AssessmentQuestion) => (
                    <Link
                        variant="secondary"
                        href={`/questions/${item.id}/version/${item.version}`}
                        onFollow={(e) => {
                            e.preventDefault();
                            navigate(`/questions/${item.id}/version/${item.version}`, {
                                state: { ...item },
                            });
                        }}
                    >
                        {item.questionText}
                    </Link>
                ),
            },
            {
                id: 'learningObjective',
                header: 'Learning Objective',
                cell: (item: AssessmentQuestion) =>
                    //@ts-ignore - Ignoring to avoid complicating typing - This is the whole LO meta object
                    item.learningObjectives![0] ? item.learningObjectives![0].name : '',
            },
            {
                id: 'status',
                header: 'Status',
                cell: (item: AssessmentQuestion) => item.status,
                minWidth: 200,
                editConfig: {
                    ariaLabel: 'Type',
                    editIconAriaLabel: 'editable',
                    editingCell: (
                        item: AssessmentQuestion,
                        {
                            currentValue,
                            setValue,
                        }: { currentValue: string; setValue: (item: any) => void },
                    ) => {
                        const value = currentValue ?? item.status;
                        const questionStatusOptions = Object.keys(QuestionStatus).map((prop) => ({
                            value: QuestionStatus[prop as keyof typeof QuestionStatus],
                            label: QuestionStatus[prop as keyof typeof QuestionStatus],
                        }));
                        return (
                            <Select
                                autoFocus={true}
                                expandToViewport={true}
                                selectedOption={
                                    questionStatusOptions.find(
                                        (option) => option.value === value,
                                    ) ?? null
                                }
                                onChange={(event) => {
                                    setValue(event.detail.selectedOption.value ?? currentValue);
                                }}
                                options={questionStatusOptions}
                            />
                        );
                    },
                },
            },
            {
                id: 'type',
                header: 'Type',
                cell: (item: AssessmentQuestion) => item.type,
            },
            {
                id: 'programs',
                header: 'Programs',
                cell: (item: AssessmentQuestion) => item.programs?.join(', '),
            },
            {
                id: 'difficulty',
                header: 'Difficulty',
                cell: (item: AssessmentQuestion) => item.difficulty,
            },
            {
                id: 'scoringMethod',
                header: 'Scoring Method',
                cell: (item: AssessmentQuestion) => item.scoringMethod,
            },
            {
                id: 'actions',
                header: 'Actions',
                cell: (item: AssessmentQuestion) => (
                    <SpaceBetween direction="vertical" size="xs">
                        <Button
                            href={`/questions/${item.id}/version/${item.version}/edit`}
                            onFollow={(e) => {
                                e.preventDefault();
                                navigate(`/questions/${item.id}/version/${item.version}/edit`, {
                                    state: {
                                        ...item,
                                        learningObjectives:
                                            // @ts-ignore
                                            item.learningObjectives!.map((lo) => lo.id),
                                    },
                                });
                            }}
                            variant="inline-link"
                        >
                            Edit
                        </Button>
                    </SpaceBetween>
                ),
            },
        ];
    }, [learningObjectiveDict]);

    const questionsListPropertyFilterProps: QuestionsListPropertyFilterProps = {
        query,
        setQuery,
        handleGetQuestions,
        initialLearningObjectiveDict: learningObjectiveDict,
    };

    const questionsTableHeaderProps: QuestionTableHeaderProps = {
        setShowLegalModal,
        questions,
        currentCount,
    };

    const legalModalProps: LegalModalProps = {
        showLegalModal,
        setShowLegalModal,
    };

    return (
        <>
            {showLegalModal && <LegalModal {...legalModalProps} />}
            <Table
                stickyHeader={true}
                header={<QuestionsTableHeader {...questionsTableHeaderProps} />}
                variant="full-page"
                items={questions}
                columnDefinitions={columnDefinitions}
                loading={isTableLoading}
                loadingText="Loading questions"
                enableKeyboardNavigation
                filter={<QuestionsListPropertyFilter {...questionsListPropertyFilterProps} />}
                pagination={
                    <Pagination
                        currentPageIndex={currentPageIndex}
                        pagesCount={pagesCount}
                        onChange={handlePaginationChange}
                    />
                }
                empty={
                    <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
                        No questions found.
                    </Box>
                }
                stickyColumns={{ first: 0, last: 1 }}
                ariaLabels={{
                    activateEditLabel: (column, item) =>
                        `Edit ${item.questionText} ${column.header}`,
                    cancelEditLabel: (column) => `Cancel editing ${column.header}`,
                    submitEditLabel: (column) => `Submit editing ${column.header}`,
                    tableLabel: 'Question list with inline editing for the question status.',
                }}
                submitEdit={async (
                    item: AssessmentQuestion,
                    column: TableProps.ColumnDefinition<AssessmentQuestion>,
                    newValue: any,
                ) => {
                    const updatedQuestion = {
                        ...item,
                        // @ts-ignore - mapping the back to lo id's
                        learningObjectives: item.learningObjectives!.map((lo) => lo.id),
                        [`${column.id}`]: newValue,
                    };
                    await handleInlineEdit({
                        updatedQuestion,
                        column,
                        newValue,
                        currentTableItem: item,
                    });
                }}
            />
        </>
    );
};

export default QuestionList;
