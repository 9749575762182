import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setBreadcrumbs, setContentType } from '../../reducers/navigationReducer';
import { QUESTION_DETAIL_ROUTE, QUESTIONS_LIST_ROUTE } from '../../router/router';
import {
    AssessmentQuestion,
    AssessmentQuestionInput,
    useGetAssessmentQuestionQuery,
    useGetLearningObjectivesLazyQuery,
} from '../../graphql';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Button,
    ColumnLayout,
    Container,
    ContentLayout,
    Header,
    SpaceBetween,
} from '@amzn/awsui-components-react';
import { AnswerList, ValueWithLabel } from '../../components';

const QuestionDetail = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id = '', version } = useParams();
    const [question, setQuestion] = useState<AssessmentQuestion>();
    const [_, setLearningObjectiveDict] = useState({});
    const [learningObjectiveName, setLearningObjectiveName] = useState('');

    const { data, loading } = useGetAssessmentQuestionQuery({
        variables: {
            id,
            version: Number(version),
        },
        fetchPolicy: 'cache-and-network',
    });

    const [getLearningObjectives] = useGetLearningObjectivesLazyQuery({
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        dispatch(
            setBreadcrumbs([
                {
                    text: QUESTIONS_LIST_ROUTE.title,
                    href: QUESTIONS_LIST_ROUTE.path,
                },
                {
                    text: QUESTION_DETAIL_ROUTE.title,
                    href: QUESTION_DETAIL_ROUTE.path,
                },
            ]),
        );
        dispatch(setContentType('content'));
    }, [dispatch]);

    useEffect(() => {
        if (!loading && data) {
            const question = data.assessmentQuestion!;
            setQuestion(question);
            handleGetLearningObjectives(question);
        }
    }, [loading, data]);

    const handleGetLearningObjectives = async (question: AssessmentQuestion) => {
        const { data } = await getLearningObjectives({
            variables: {
                learningObjectives: question.learningObjectives,
            },
        });

        let learningObjectiveDict = {};
        data!.assessmentLearningObjectives.metadataObjects.forEach((objective) => {
            // Learning objective dictionary
            learningObjectiveDict = {
                ...learningObjectiveDict,
                [objective.id]: objective,
            };
            setLearningObjectiveName(objective.name);
        });
        setLearningObjectiveDict(learningObjectiveDict);
    };

    const answerListProps = {
        formValues: question as Partial<AssessmentQuestionInput>,
        handleFormValueChange: () => {},
        mode: 'detail',
    };
    return (
        <ContentLayout
            header={
                <Header
                    variant="h1"
                    actions={
                        <SpaceBetween size="s" direction="horizontal">
                            <Button
                                key={`edit-question-button`}
                                data-testid="edit-question-button"
                                onClick={() =>
                                    navigate(
                                        `/questions/${question?.id}/version/${question?.version}/edit`,
                                        {
                                            state: { ...question },
                                        },
                                    )
                                }
                            >
                                Edit Question
                            </Button>
                        </SpaceBetween>
                    }
                >
                    Question Details
                </Header>
            }
        >
            <SpaceBetween size="l">
                <Container>
                    <ColumnLayout columns={2} variant="text-grid">
                        <SpaceBetween size="l">
                            <ValueWithLabel label="Question Type">{question?.type}</ValueWithLabel>
                            <ValueWithLabel label="Question Status">
                                {question?.status}
                            </ValueWithLabel>
                            <ValueWithLabel label="Question Text">
                                {question?.questionText}
                            </ValueWithLabel>
                            <ValueWithLabel label="Scoring Method">
                                {question?.scoringMethod}
                            </ValueWithLabel>
                        </SpaceBetween>

                        <SpaceBetween size="l">
                            <ValueWithLabel label="Program">
                                {question && question.programs!.length
                                    ? question.programs![0]
                                    : ' - '}
                            </ValueWithLabel>
                            <ValueWithLabel label="Difficulty level">
                                {question?.difficulty}
                            </ValueWithLabel>
                            <ValueWithLabel label="Associated Programs">
                                {question?.programs?.length! !== 0
                                    ? question?.programs?.map((program) => program).join(', ')
                                    : '-'}
                            </ValueWithLabel>
                            <ValueWithLabel label="Learning Objectives">
                                {learningObjectiveName}
                            </ValueWithLabel>
                            {/* <ValueWithLabel label="Question Banks">
                                {question?.questionBanks?.length! !== 0
                                    ? question?.questionBanks?.map((bank) => bank).join(', ')
                                    : '-'}
                            </ValueWithLabel>
                            <ValueWithLabel label="Question Tags">
                                {question?.tags?.length! !== 0
                                    ? question?.tags?.map((tag) => tag).join(', ')
                                    : '-'}
                            </ValueWithLabel> */}
                        </SpaceBetween>
                    </ColumnLayout>
                </Container>
                <AnswerList {...answerListProps} />
            </SpaceBetween>
        </ContentLayout>
    );
};

export default QuestionDetail;
