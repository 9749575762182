import { useCallback, useState } from 'react';

const emailRegEx =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export enum ValidationMessages {
    Email = 'The email address entered is invalid.',
    Required = 'Required.',
}
export type ValidationType = 'email' | 'required';
export const validationTypeMap: {
    readonly [key in ValidationType]: (value: any) => string | null;
} = {
    email: (val: any) => (emailRegEx.test(val) || !val ? null : ValidationMessages.Email),
    required: (val: any) => {
        if (Array.isArray(val)) {
            return val.length === 0 ? ValidationMessages.Required : null;
        }
        return val === '' || val == null ? ValidationMessages.Required : null;
    },
};

/**
 * Hook that provides an api for form validation
 * @returns An object containing:
 * isInvalid - a flag that allows us to run validation on each input event after validation is run and the form is deemed invalid
 * errors - an object that contains all of the error messages collected during validation
 * validateForm - a function that takes the formValues and validationConfig (an object containing the fields to validate and each type of validation necessary)
 */
const useFormValidation = <FormValues, ControlArrayFormValues = any>() => {
    type ControlArrayFormErrors = {
        [controlArrayFormValuesKey in keyof ControlArrayFormValues]: {
            [key: number]: {
                [key in keyof ControlArrayFormValues[controlArrayFormValuesKey]]?: string;
            };
        };
    };
    const [isInvalid, setIsInvalid] = useState(false);
    const [isControlArrayInvalid, setIsControlArrayInvalid] = useState(false);
    const [errors, setErrors] = useState({} as { [key in keyof FormValues]?: string });
    const [controlArrayErrors, setControlArrayErrors] = useState({} as ControlArrayFormErrors);

    const runValidation = <J>(
        formValues: J,
        validationConfig: {
            [key in ValidationType]?: Array<keyof J>;
        },
        errorObj: { [key in keyof J]: string },
    ) => {
        let invalid = false;

        Object.entries(validationConfig).forEach(([validationType, fields = []]) => {
            const validator = validationTypeMap[validationType as ValidationType];

            fields.forEach((field) => {
                const message = validator(formValues[field]);

                if (message && !errorObj[field]) {
                    invalid = true;
                    errorObj[field] = message;
                }
            });
        });

        return invalid;
    };

    const validateForm = useCallback(
        (
            formValues: FormValues,
            validationConfig: {
                [key in ValidationType]?: Array<keyof FormValues>;
            },
        ) => {
            const errors = {} as { [key in keyof FormValues]: string };
            const invalid = runValidation(formValues, validationConfig, errors);
            setIsInvalid(invalid);
            setErrors(errors);

            return invalid;
        },
        [],
    );

    const validateFormControlArray = useCallback(
        (
            formValues: {
                [controlArrayFormValuesKey in keyof ControlArrayFormValues]: Array<
                    ControlArrayFormValues[controlArrayFormValuesKey]
                >;
            },
            validationConfig: {
                [controlArrayFormValuesKey in keyof ControlArrayFormValues]: {
                    [key in ValidationType]?: Array<
                        keyof ControlArrayFormValues[controlArrayFormValuesKey]
                    >;
                };
            },
        ) => {
            const arrayErrors = {} as ControlArrayFormErrors;
            const isInvalidList: Array<boolean> = [];

            Object.entries(validationConfig).forEach(
                ([controlArrayKey, controlArrayValidation = []]) => {
                    const typedControlArrayKey = controlArrayKey as keyof ControlArrayFormValues;
                    const controlArrayValueList = formValues[typedControlArrayKey];

                    controlArrayValueList.forEach((value, index) => {
                        const errors = {} as {
                            [key in keyof ControlArrayFormValues[keyof ControlArrayFormValues]]: string;
                        };
                        const invalid = runValidation<
                            ControlArrayFormValues[keyof ControlArrayFormValues]
                        >(
                            value,
                            controlArrayValidation as {
                                [key in ValidationType]?: Array<
                                    keyof ControlArrayFormValues[keyof ControlArrayFormValues]
                                >;
                            },
                            errors,
                        );
                        isInvalidList.push(invalid);

                        arrayErrors[typedControlArrayKey] = {
                            ...arrayErrors[typedControlArrayKey],
                            [index]: errors,
                        };
                    });
                },
            );

            const isInvalid = isInvalidList.some((isInvalid) => isInvalid);

            setIsControlArrayInvalid(isInvalid);
            setControlArrayErrors(arrayErrors);
            return isInvalid;
        },
        [],
    );

    return {
        isInvalid,
        isControlArrayInvalid,
        errors,
        setErrors,
        controlArrayErrors,
        validateForm,
        validateFormControlArray,
    };
};

export default useFormValidation;
