import 'regenerator-runtime';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { EXCEL_ASSESSMENT_QUESTIONS, FILE_EXTENSION, FILE_TYPE } from './constants';
import { Assessment, AssessmentQuestion } from '../../graphql';

export enum WORKSHEETS {
    ASSESSMENT_QUESTIONS = 'Assessment questions',
}

interface ExcelAssessmentQuestion extends Omit<AssessmentQuestion, 'learningObjectives'> {
    learningObjectives: string;
}

interface HEADERS {
    key: string;
    header: string;
    width?: number;
}

export class ExcelUtil {
    workbook: Workbook;

    constructor(workbook: Workbook) {
        this.workbook = workbook;
    }

    public async writeWorkbook() {
        const buffer = await this.workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: FILE_TYPE });
        saveAs(blob, `assessment-questions-export${FILE_EXTENSION}`);
    }

    public buildAssessmentQuestionExportWorkbook() {
        this.workbook.addWorksheet(WORKSHEETS.ASSESSMENT_QUESTIONS, {
            views: [{ state: 'frozen', xSplit: 3, ySplit: 0 }],
        });

        this.workbook.getWorksheet(WORKSHEETS.ASSESSMENT_QUESTIONS)!.columns = this.getHeaders(
            EXCEL_ASSESSMENT_QUESTIONS,
        );
        return this;
    }

    public populateAssessmentQuestionExportWorkbook(assessmentQuestions: AssessmentQuestion[]) {
        assessmentQuestions.forEach((question) => {
            this.populateAssessmentQuestionWorksheet(question);
        });
        return this;
    }

    public styleWorkbook() {
        const ws = this.workbook.getWorksheet(WORKSHEETS.ASSESSMENT_QUESTIONS)!;

        // Header
        let headerRow = ws.getRow(1);
        headerRow.font = {
            size: 15,
            bold: true,
        };

        // All cells
        ws.eachRow((row) => {
            if (row.number !== 1) {
                row.eachCell((cell) => (cell.alignment = { wrapText: true, vertical: 'top' }));
            }
        });

        ws.getColumn('learningObjectives').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'cfe2f3' },
        };

        return this;
    }

    public cellProtection() {
        const ws = this.workbook.getWorksheet(WORKSHEETS.ASSESSMENT_QUESTIONS)!;
        ws.getColumn(1).hidden = true;
        return this;
    }

    private getHeaders(headers: HEADERS[]) {
        return headers.map(({ header, key, width }) => ({ header, key, width }));
    }

    private populateAssessmentQuestionWorksheet(assessmentQuestion: AssessmentQuestion) {
        // @ts-ignore
        const learningObjectives = assessmentQuestion.learningObjectives![0].name;
        const correctAnswers = assessmentQuestion.answers.filter((answer) => answer.isCorrect);
        let rowData = {
            id: assessmentQuestion.id,
            type: assessmentQuestion.type,
            learningObjectives,
            questionText: assessmentQuestion.questionText,
            correctAnswer: correctAnswers.map((answer) => answer.answerText).join(', '),
            explanation: correctAnswers.map((answer) => answer.explanation).join(', '),
            status: assessmentQuestion.status,
            difficulty: assessmentQuestion.difficulty,
        };
        const distractors = assessmentQuestion.answers.filter((answer) => !answer.isCorrect);
        distractors.forEach((distractor, i) => {
            rowData = {
                ...rowData,
                [`distractor-${i}`]: distractor.answerText,
                [`explanation-${i}`]: distractor.explanation,
            };
        });

        this.workbook
            .getWorksheet(WORKSHEETS.ASSESSMENT_QUESTIONS)!
            .addRow(this.getAssessmentQuestionRow(rowData));
    }

    private getAssessmentQuestionRow(assessmentQuestion: Partial<ExcelAssessmentQuestion>) {
        return EXCEL_ASSESSMENT_QUESTIONS.map(
            ({ key }) => assessmentQuestion[key as keyof ExcelAssessmentQuestion],
        );
    }
}
