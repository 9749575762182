import {
    Box,
    Button,
    Header,
    Modal,
    Pagination,
    PropertyFilterProps,
    SpaceBetween,
    Table,
} from '@amzn/awsui-components-react';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { AssessmentInput, AssessmentQuestion } from '../graphql';
import { SelectQuestionCheckbox, SelectQuestionCheckboxProps } from './';
import QuestionsListPropertyFilter, {
    LOCAL_STORAGE_ADD_QUESTION_FILTER_KEY,
    QuestionsListPropertyFilterProps,
    StoredQuestionFilters,
} from '../pages/questions/questionsList/QuestionsListPropertyFilter';
import { useQuestions } from '../hooks/useQuestion';

export interface AddQuestionModalProps {
    showAddQuestionsModal: boolean;
    setShowAddQuestionsModal: Dispatch<SetStateAction<boolean>>;
    formValues: Partial<AssessmentInput>;
    handleFormValueChange: (formUpdates: Partial<AssessmentInput>) => void;
    learningObjective: string;
    selectedQuestions: Set<AssessmentQuestion>;
    setSelectedQuestions: Dispatch<SetStateAction<Set<AssessmentQuestion>>>;
}
const AddQuestionModal = ({
    showAddQuestionsModal,
    setShowAddQuestionsModal,
    formValues,
    handleFormValueChange,
    learningObjective,
    selectedQuestions,
    setSelectedQuestions,
}: AddQuestionModalProps) => {
    const [query, setQuery] = useState<PropertyFilterProps.Query>({
        tokens: [],
        operation: 'and',
    });

    const { questions, getQuestions, isLoading, getQuestionsByPropertyFilter } = useQuestions();

    useEffect(() => {
        const storedFilterString = localStorage.getItem(LOCAL_STORAGE_ADD_QUESTION_FILTER_KEY);

        if (storedFilterString) {
            try {
                let savedFilters = JSON.parse(storedFilterString) as StoredQuestionFilters;
                try {
                    if (savedFilters.propertyFilters) {
                        setQuery(savedFilters.propertyFilters);
                    }
                    getQuestionsByPropertyFilter(savedFilters);
                } catch (e) {}
            } catch (e) {}
        } else {
            getQuestions({ learningObjectives: [learningObjective] });
        }
    }, []);

    const handleSelectedQuestionChange = (selectedQuestions: Set<AssessmentQuestion>) =>
        setSelectedQuestions(new Set(Array.from(selectedQuestions)));

    const handleAddQuestions = () => {
        const existingQuestionIds = new Set(
            formValues.questions?.map((question) => question.id) || [],
        );

        const newQuestions = Array.from(selectedQuestions)
            .filter((question) => !existingQuestionIds.has(question.id))
            .map((question) => ({
                id: question.id,
                learningObjectives: [learningObjective],
            }));

        const updatedQuestions = [...(formValues.questions || []), ...newQuestions];

        handleFormValueChange({ questions: updatedQuestions });
        setShowAddQuestionsModal(false);
        setSelectedQuestions(new Set());
    };

    const columnDefinitions = useMemo(() => {
        return [
            {
                id: 'selectQuestion',
                header: '',
                cell: (item: AssessmentQuestion) => {
                    const selectQuestionCheckboxProps: SelectQuestionCheckboxProps = {
                        item: item,
                        selectedQuestions,
                        handleSelectedQuestionChange,
                    };

                    return <SelectQuestionCheckbox {...selectQuestionCheckboxProps} />;
                },
            },
            {
                id: 'questionText',
                header: 'Question Text',
                cell: (item: AssessmentQuestion) => item.questionText,
                isRowHeader: true,
            },
            {
                id: 'status',
                header: 'Status',
                cell: (item: AssessmentQuestion) => item.status,
                isRowHeader: true,
            },
            {
                id: 'type',
                header: 'Type',
                cell: (item: AssessmentQuestion) => item.type,
                isRowHeader: true,
            },
            {
                id: 'programs',
                header: 'Programs',
                cell: (item: AssessmentQuestion) => item.programs?.join(', '),
                isRowHeader: true,
            },
            {
                id: 'difficulty',
                header: 'Difficulty',
                cell: (item: AssessmentQuestion) => item.difficulty,
                isRowHeader: true,
            },
            {
                id: 'scoringMethod',
                header: 'Scoring Method',
                cell: (item: AssessmentQuestion) => item.scoringMethod,
                isRowHeader: true,
            },
        ];
    }, [selectedQuestions, questions]);

    const questionsListPropertyFilterProps: QuestionsListPropertyFilterProps = {
        query,
        setQuery,
        getQuestionsByPropertyFilter,
    };

    return (
        <Modal
            visible={showAddQuestionsModal}
            onDismiss={() => {
                setSelectedQuestions(new Set());
                setShowAddQuestionsModal(false);
            }}
            size="max"
            header="Select questions for assessment"
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            onClick={() => {
                                setSelectedQuestions(new Set());
                                setShowAddQuestionsModal(false);
                            }}
                            variant="link"
                        >
                            Cancel
                        </Button>
                        <Button onClick={handleAddQuestions} variant="primary">
                            Add questions
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <Table
                header={<Header variant="h1">Questions</Header>}
                variant="embedded"
                items={questions.filter(
                    (question) =>
                        !formValues.questions!.some(
                            (existingQuestion) => existingQuestion.id === question.id,
                        ),
                )}
                columnDefinitions={columnDefinitions}
                loading={isLoading}
                loadingText="Loading questions"
                enableKeyboardNavigation
                filter={<QuestionsListPropertyFilter {...questionsListPropertyFilterProps} />}
                pagination={<Pagination currentPageIndex={1} pagesCount={1} onChange={() => {}} />}
                empty={
                    <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
                        No questions found.
                    </Box>
                }
            />
        </Modal>
    );
};

export default AddQuestionModal;
