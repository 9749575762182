import React, { useEffect, useState } from 'react';
import {
    Button,
    Container,
    Form,
    Header,
    PropertyFilterProps,
    SpaceBetween,
    Tabs,
} from '@amzn/awsui-components-react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
    LearningObjectiveAttributeEditor,
    MetadataObjectNameTextArea,
    MetadataObjectNameTextAreaProps,
    MetadataObjectStatusSelect,
    MetadataObjectStatusSelectProps,
    LearningObjectiveAttributeEditorProps,
    EmbeddedSelectableQuestionsTable,
    EmbeddedSelectableQuestionsTableProps,
    LearningObjectiveAttributeEditorItem,
} from '../../components';
import {
    AssessmentMetadataObjectAssociation,
    AssessmentQuestion,
    CreateQuestionBankMutationVariables,
    UpdateQuestionBankMutationVariables,
} from '../../graphql';
import { useNotifications } from '../../context/NotificationsProvider';
import { ProgramSelect, ProgramSelectProps } from '../../components/common/formFields';
import { useDispatch } from 'react-redux';
import { setBreadcrumbs, setContentType } from '../../reducers/navigationReducer';
import { QUESTION_BANK_LIST_ROUTE } from '../../router/router';
import { useQuestionsContext } from '../../context/QuestionsProvider';
import { waitFor } from '../../utils/appUtils';
import { metadataInitialFormValues } from '../../common/constants/metadataObject';
import { QuestionBankIds } from '../../common/dataTestIds/questionBanks';
import { useQuestionBankContext } from '../../context/QuestionBankProvider';
import QuestionBankQuestionsPropertyFilter, {
    QuestionBankQuestionsPropertyFilterProps,
} from './QuestionBankQuestionsPropertyFilter';
import useFormValidation from '../../hooks/useFormValidation';
import {
    ASSOCIATED_META_ITEMS_VALIDATION_FIELDS,
    QUESTION_BANK_VALIDATION_FIELDS,
} from '../../common/constants/validations';

export const WAIT_FOR_QUESTION_BANK_UPDATE_MS = 1000;

const QuestionBankEdit = () => {
    const [query, setQuery] = useState<PropertyFilterProps.Query>({
        tokens: [],
        operation: 'and',
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id: questionBankId = '', version } = useParams();
    const { state } = useLocation();
    const [selectedQuestions, setSelectedQuestions] = useState(new Set<AssessmentQuestion>());
    const [learningObjectiveAttributeEditorItems, setLearningObjectiveAttributeEditorItems] =
        useState<LearningObjectiveAttributeEditorItem[]>([{ id: '' }]);
    const [formValues, setFormValues] = useState<Partial<UpdateQuestionBankMutationVariables>>(
        state ?? metadataInitialFormValues,
    );
    const { addNotification } = useNotifications();

    const { getQuestions, questions, learningObjectiveDict } = useQuestionsContext();
    const {
        questionBank,
        updatingQuestionBank,
        handleGetQuestionBank,
        handleUpdateQuestions,
        handleUpdateQuestionBank,
        updatingQuestion,
    } = useQuestionBankContext();
    const {
        validateForm,
        validateFormControlArray,
        isInvalid,
        isControlArrayInvalid,
        errors,
        controlArrayErrors,
    } = useFormValidation<Partial<CreateQuestionBankMutationVariables>>();

    const runInputValidations = () => {
        return validateForm(formValues!, {
            required: QUESTION_BANK_VALIDATION_FIELDS.REQUIRED,
        });
    };

    const runArrayInputValidations = () => {
        return validateFormControlArray(
            { learningObjectiveAttributeEditorItems },
            {
                learningObjectiveAttributeEditorItems: {
                    required: ASSOCIATED_META_ITEMS_VALIDATION_FIELDS.REQUIRED,
                },
            },
        );
    };

    useEffect(() => {
        dispatch(
            setBreadcrumbs([
                {
                    text: QUESTION_BANK_LIST_ROUTE.title,
                    href: QUESTION_BANK_LIST_ROUTE.path,
                },
                {
                    text: QUESTION_BANK_LIST_ROUTE.title,
                    href: QUESTION_BANK_LIST_ROUTE.path,
                },
            ]),
        );
        dispatch(setContentType('form'));
    }, [dispatch]);

    useEffect(() => {
        if (questionBank) {
            setFormValues({ ...questionBank });
        } else {
            handleGetQuestionBank(questionBankId, Number(version));
        }
    }, [questionBank]);

    useEffect(() => {
        if (formValues) {
            // @ts-ignore
            const attributeEditorItems = formValues.associatedMetadata!.map((metadata) => ({
                id: metadata.id,
            }));
            setLearningObjectiveAttributeEditorItems(attributeEditorItems);
        }
    }, [formValues.associatedMetadata]);

    useEffect(() => {
        if (isInvalid) {
            runInputValidations();
        }
        if (isControlArrayInvalid) {
            runArrayInputValidations();
        }
    }, [formValues, isInvalid, validateForm]);

    const handleFormValueChange = (formUpdates: Partial<CreateQuestionBankMutationVariables>) => {
        setFormValues({ ...formValues, ...formUpdates });
    };

    const handleSaveQuestionBank = async () => {
        const invalid = runInputValidations();
        const arrayInvalid = runArrayInputValidations();
        const isActivityFormInvalid = invalid || arrayInvalid;
        if (isActivityFormInvalid) {
            return;
        }
        try {
            if (selectedQuestions.size > 0) {
                const updatedSelectedQuestions = Array.from(selectedQuestions).map((question) => ({
                    ...question,
                    questionBanks: [...question.questionBanks!, questionBankId],
                    // @ts-expect-error This is a full metaobject at this point
                    learningObjectives: question.learningObjectives?.map((lo) => lo.id),
                }));
                await handleAddQuestions(updatedSelectedQuestions);
                setSelectedQuestions(new Set());
            }

            const updatedQuestionBank = await handleUpdateQuestionBank({
                questionBankId,
                version: version!,
                formValues,
            });

            addNotification({
                id: `create-question-bank-${Date.now()}`,
                ...(updatedQuestionBank.data?.updateAssessmentQuestionBank
                    ? {
                          type: 'success',
                          content: 'Question bank updated successfully.',
                      }
                    : {
                          type: 'error',
                          content: 'There was an error updating the question bank.',
                      }),
            });
            if (updatedQuestionBank.data?.updateAssessmentQuestionBank) {
                const questionBank = updatedQuestionBank.data?.updateAssessmentQuestionBank;
                navigate(`/question-banks/${questionBank.id}/version/${questionBank.version}`);
            }
        } catch (error) {}
    };

    const handleAddQuestions = async (questions: AssessmentQuestion[]) => {
        await handleUpdateQuestions(questions);
        await waitFor(WAIT_FOR_QUESTION_BANK_UPDATE_MS);
        navigate(`/question-banks/${questionBankId}/version/${version}`);
    };

    const handleSelectedQuestionChange = (selectedQuestions: Set<AssessmentQuestion>) =>
        setSelectedQuestions(new Set(Array.from(selectedQuestions)));

    const handleRemoveQuestions = async () => {
        try {
            const updatePromises = Array.from(selectedQuestions).map((question) => {
                // Filter out the current question bank ID from the question's questionBanks array
                const updatedQuestionBanks =
                    question.questionBanks?.filter((qbId) => qbId !== questionBankId) || [];

                return handleUpdateQuestions(
                    [question].map((q) => ({
                        ...question,
                        questionBanks: updatedQuestionBanks,
                        // @ts-expect-error This is a full metaobject at this point
                        learningObjectives: question.learningObjectives?.map((lo) => lo.id),
                    })),
                );
            });
            await Promise.all(updatePromises);
            await waitFor(WAIT_FOR_QUESTION_BANK_UPDATE_MS);
            setSelectedQuestions(new Set());
            await getQuestions({ questionBanks: [questionBankId] });
            addNotification({
                id: `edit-question-${Date.now()}`,
                type: 'success',
                content: 'Question was updated successfully.',
            });
        } catch (error) {
            addNotification({
                id: `error-edit-question-${Date.now()}`,
                type: 'error',
                content: 'There was an error adding the question to the question bank.',
            });
        }
    };

    const metadataObjectStatusProps: MetadataObjectStatusSelectProps = {
        formValues,
        handleFormValueChange,
    };

    const metadataObjectNameTextAreaProps: MetadataObjectNameTextAreaProps = {
        formValues,
        handleFormValueChange,
        keyName: 'name',
        label: 'Name',
        errors,
    };

    const programSelectProps: ProgramSelectProps = {
        formValues: formValues,
        handleFormValueChange,
        errors,
    };

    const learningObjectiveAttributeEditorProps: LearningObjectiveAttributeEditorProps = {
        learningObjectiveAttributeEditorItems,
        setLearningObjectiveAttributeEditorItems,
        handleFormValueChange,
        selectedPrograms: formValues.programs as [],
        errors,
        controlArrayErrors,
    };

    const questionBankQuestionPropertyFilterProps: QuestionBankQuestionsPropertyFilterProps = {
        query,
        setQuery,
        initialLearningObjectiveDict: learningObjectiveDict,
        getQuestions,
    };

    const listQuestionBankQuestions: EmbeddedSelectableQuestionsTableProps = {
        HeaderAction: (
            <Button
                disabled={selectedQuestions.size === 0 || updatingQuestion}
                onClick={handleRemoveQuestions}
            >
                Remove questions
            </Button>
        ),
        TablePropertyFilter: (
            <QuestionBankQuestionsPropertyFilter {...questionBankQuestionPropertyFilterProps} />
        ),
        selectedQuestions,
        formValues,
        handleSelectedQuestionChange,
        questionFilterVariables: { questionBanks: [questionBankId] },
    };

    const addQuestionBankQuestions: EmbeddedSelectableQuestionsTableProps = {
        HeaderAction: (
            <Button
                disabled={selectedQuestions.size === 0 || updatingQuestion}
                onClick={handleSaveQuestionBank}
            >
                Add questions
            </Button>
        ),
        TablePropertyFilter: (
            <QuestionBankQuestionsPropertyFilter {...questionBankQuestionPropertyFilterProps} />
        ),
        selectedQuestions,
        handleSelectedQuestionChange,
        formValues,
        questionFilterVariables: {
            learningObjectives: formValues
                .associatedMetadata! // @ts-ignore
                .filter((metaValue: any) => metaValue.metadataType === 'LEARNING_OBJECTIVE')
                .map((lo: AssessmentMetadataObjectAssociation) => lo.id),
        },
        questionFilterOptions: { exludeIds: questions?.map((q) => q.id) },
    };

    return (
        <Form
            data-testid={QuestionBankIds.FormId}
            actions={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button
                        onClick={() => navigate('/question-banks')}
                        formAction="none"
                        variant="link"
                    >
                        Cancel
                    </Button>
                    <Button
                        data-testid={QuestionBankIds.SaveButtonId}
                        onClick={handleSaveQuestionBank}
                        disabled={updatingQuestionBank}
                        variant="primary"
                    >
                        {updatingQuestionBank ? 'Saving' : 'Save'}
                    </Button>
                </SpaceBetween>
            }
            header={<Header variant="h1">Edit Question Bank</Header>}
        >
            <Container header={<Header variant="h2">Question bank details</Header>}>
                <SpaceBetween direction="vertical" size="l">
                    <MetadataObjectNameTextArea {...metadataObjectNameTextAreaProps} />
                    <MetadataObjectStatusSelect {...metadataObjectStatusProps} />
                    <ProgramSelect {...programSelectProps} />
                    {formValues.programs && formValues.programs.length > 0 && (
                        <LearningObjectiveAttributeEditor
                            {...learningObjectiveAttributeEditorProps}
                        />
                    )}
                    <Tabs
                        data-testid={QuestionBankIds.EditTabs}
                        tabs={[
                            {
                                label: 'Questions',
                                id: 'questions-tab',
                                content: (
                                    <EmbeddedSelectableQuestionsTable
                                        {...listQuestionBankQuestions}
                                    />
                                ),
                            },
                            {
                                label: 'Add questions',
                                id: 'add-questions-tab',
                                content: (
                                    <EmbeddedSelectableQuestionsTable
                                        {...addQuestionBankQuestions}
                                    />
                                ),
                            },
                        ]}
                    />
                </SpaceBetween>
            </Container>
        </Form>
    );
};

export default QuestionBankEdit;
