import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { router } from './router';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store';
import { ApolloProvider } from '@apollo/client';
import { client } from './common/client';
import { NotificationsProvider } from './context/NotificationsProvider';
import { AuthProvider } from './providers/authProvider';
import { E2E_TEST_ENV_KEY } from './common/constants/application';

const container = document.getElementById('root');
const root = createRoot(container!);

declare global {
    interface Window {
        loadTemporaryCredentials: (credentials: string) => void;
    }
}

// Known Issue: https://cloudscape.aws.dev/versions/from-v21-to-v30/known-migration-issues/#client-side-errors-due-to-resizeobserver
window.addEventListener('error', (e) => {
    if (
        e.message === 'ResizeObserver loop completed with undelivered notifications.' ||
        e.message === 'ResizeObserver loop limit exceeded'
    ) {
        const resizeObserverErrDiv = document.getElementById(
            'webpack-dev-server-client-overlay-div',
        );
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
        if (resizeObserverErr) {
            resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
            resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
    }
});

type testCredentials = {
    lastAuthKey: string;
    lastAuthValue: string;
    accessTokenKey: string;
    accessTokenValue: string;
    idTokenKey: string;
    idTokenValue: string;
};

const cookieString = 'path=/; Secure; SameSite=Strict';
window.loadTemporaryCredentials = (stringifiedCredentials: string) => {
    const credentials = JSON.parse(stringifiedCredentials) as testCredentials;
    document.cookie = `${credentials.accessTokenKey}=${credentials.accessTokenValue}; ${cookieString}`;
    document.cookie = `${credentials.idTokenKey}=${credentials.idTokenValue}; ${cookieString}`;
    document.cookie = `${credentials.lastAuthKey}=${credentials.lastAuthValue}; ${cookieString}`;
    document.cookie = `${E2E_TEST_ENV_KEY}=true; ${cookieString}`;
};

root.render(
    <StrictMode>
        <Provider store={store}>
            <ApolloProvider client={client}>
                <AuthProvider>
                    <NotificationsProvider>
                        <RouterProvider router={router} />
                    </NotificationsProvider>
                </AuthProvider>
            </ApolloProvider>
        </Provider>
    </StrictMode>,
);
