import { Box, Header, Link, Pagination, Table } from '@amzn/awsui-components-react';
import React, { useEffect, useMemo } from 'react';
import { AssessmentQuestion } from '../../graphql';
import { useNavigate } from 'react-router-dom';
import { useQuestionsContext } from '../../context/QuestionsProvider';

export interface EmbeddedQuestionsTableProps {
    TablePropertyFilter: React.ReactNode;
    questionFilterVariables?: any;
}

const EmbeddedQuestionsTable = ({
    TablePropertyFilter,
    questionFilterVariables,
}: EmbeddedQuestionsTableProps) => {
    const navigate = useNavigate();
    const {
        questions,
        isLoading,
        currentPageIndex,
        pagesCount,
        handlePaginationChange,
        currentCount,
        getQuestions,
    } = useQuestionsContext();

    useEffect(() => {
        getQuestions(questionFilterVariables);
    }, []);

    const columnDefinitions = useMemo(() => {
        const cols = [
            {
                id: 'questionText',
                header: 'Question Text',
                cell: (item: AssessmentQuestion) => (
                    <Link
                        target="_blank"
                        variant="secondary"
                        href={`/questions/${item.id}/version/${item.version}`}
                        onFollow={(e) => {
                            e.preventDefault();
                            navigate(`/questions/${item.id}/version/${item.version}`, {
                                state: { ...item },
                            });
                        }}
                    >
                        {item.questionText}
                    </Link>
                ),
            },
            {
                id: 'learningObjective',
                header: 'Learning Objective',
                cell: (item: AssessmentQuestion) =>
                    //@ts-ignore - Ignoring to avoid complicating typing - This is the whole LO meta object
                    item.learningObjectives![0] ? item.learningObjectives![0].name : '',
            },
            {
                id: 'status',
                header: 'Status',
                cell: (item: AssessmentQuestion) => item.status,
                minWidth: 200,
            },
            {
                id: 'type',
                header: 'Type',
                cell: (item: AssessmentQuestion) => item.type,
            },
            {
                id: 'programs',
                header: 'Programs',
                cell: (item: AssessmentQuestion) => item.programs?.join(', '),
            },
            {
                id: 'difficulty',
                header: 'Difficulty',
                cell: (item: AssessmentQuestion) => item.difficulty,
            },
            {
                id: 'scoringMethod',
                header: 'Scoring Method',
                cell: (item: AssessmentQuestion) => item.scoringMethod,
            },
        ];
        return cols;
    }, [questions]);

    return (
        <Table
            header={<Header counter={`(${currentCount})`}>Questions</Header>}
            variant="embedded"
            items={questions}
            columnDefinitions={columnDefinitions}
            loading={isLoading}
            loadingText="Loading questions"
            enableKeyboardNavigation
            filter={TablePropertyFilter}
            pagination={
                <Pagination
                    currentPageIndex={currentPageIndex}
                    pagesCount={pagesCount}
                    onChange={handlePaginationChange}
                />
            }
            empty={
                <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
                    No questions found.
                </Box>
            }
        />
    );
};

export default EmbeddedQuestionsTable;
