import { createAuthUrl, getCurrentHost, AuthEndpoints } from './endpointUtils';

export async function refreshToken() {
    const response: Response = await fetch(
        createAuthUrl(getCurrentHost(), AuthEndpoints.REFRESH_TOKEN),
        {
            method: 'POST',
        },
    );

    if (!response.ok) {
        throw new Error(`Failed to refresh token: status code[${response.status}]`);
    }
}
