import { SelectProps } from '@amzn/awsui-components-react';

export const handleSelectedOption = ({
    selectedOption,
    options,
}: {
    selectedOption: string;
    options: Array<SelectProps.Option>;
}) => {
    if (!selectedOption) {
        return null;
    }
    const option = options.find((option) => option.value === (selectedOption ?? options[0].value));
    return option ? option : null;
};

export const handleSelectedOptions = ({
    selectedOptions,
    options,
}: {
    selectedOptions: string[];
    options: Array<SelectProps.Option>;
}) => {
    if (!selectedOptions || selectedOptions.length === 0) {
        return [];
    }

    return (
        options?.filter(
            (option) =>
                Array.isArray(selectedOptions) &&
                option?.value != null &&
                selectedOptions.includes(option.value),
        ) ?? []
    );
};
