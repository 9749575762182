import React, { Dispatch, SetStateAction } from 'react';
import { Button, Header, SpaceBetween } from '@amzn/awsui-components-react';

export interface AnswerListTableHeaderProps {
    setIsAnswerModalVisible: Dispatch<SetStateAction<boolean>>;
    mode: string;
    setAnswerModalFormType: Dispatch<SetStateAction<string>>;
}
const AnswerListTableHeader = ({
    setIsAnswerModalVisible,
    mode,
    setAnswerModalFormType,
}: AnswerListTableHeaderProps) => {
    const isAnswerCreateMode = mode === 'edit' || mode === 'create';
    const description =
        'Required: If adding answers, 2 or more answers are required to create a question.';

    return (
        <Header
            actions={
                isAnswerCreateMode && (
                    <SpaceBetween size="xs" direction="horizontal">
                        <SpaceBetween size="xs" direction="horizontal">
                            <Button
                                onClick={() => {
                                    setAnswerModalFormType('createAnswerForm');
                                    setIsAnswerModalVisible(true);
                                }}
                            >
                                Create answer
                            </Button>
                        </SpaceBetween>
                    </SpaceBetween>
                )
            }
            description={description}
        >
            Answers
        </Header>
    );
};

export default AnswerListTableHeader;
