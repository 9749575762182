import React, { useEffect, useState } from 'react';
import { Container, FormField, Header, SpaceBetween } from '@amzn/awsui-components-react';
import {
    LearningObjectiveSelect,
    LearningObjectiveSelectProps,
    ProgramSelect,
    ProgramSelectProps,
    QuestionDifficultySelect,
    QuestionDifficultySelectProps,
    QuestionStatusSelect,
    QuestionStatusSelectProps,
    QuestionTypeSelect,
    QuestionTypeSelectProps,
} from '../../common/formFields';
import {
    AssessmentMetadataObject,
    AssessmentQuestionInput,
    useGetLearningObjectivesQuery,
} from '../../../graphql';
import { AnswerList, AnswerListProps } from '../..';
import QuestionTextInput, {
    QuestionTextInputProps,
} from '../../common/formFields/QuestionTextInput';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { Dictionary } from '../../../interfaces/dictionary';

export interface QuestionFormProps {
    mode: string;
    handleFormValueChange: (formUpdates: Partial<AssessmentQuestionInput>) => void;
    formValues: Partial<AssessmentQuestionInput>;
    errors: any;
}

const QuestionForm = ({ mode, handleFormValueChange, formValues, errors }: QuestionFormProps) => {
    const [learningObjectiveDict, setLearningObjectiveDict] =
        useState<Dictionary<AssessmentMetadataObject>>();

    const { data, loading } = useGetLearningObjectivesQuery({
        variables: {
            status: 'Active',
            programs: formValues.programs,
            size: 500,
        },
    });

    useEffect(() => {
        if (data && !loading) {
            let options: OptionDefinition[] = [];
            let learningObjectiveDict = {};

            data.assessmentLearningObjectives.metadataObjects.forEach((objective) => {
                // Option definitions
                options.push({
                    value: objective.id,
                    label: objective.name,
                });
                // Learning objective dictionary
                learningObjectiveDict = {
                    ...learningObjectiveDict,
                    [objective.id]: objective,
                };
            });
            setLearningObjectiveDict(learningObjectiveDict);
            setLearningObjectiveOptions(options);
        }
    }, [data, formValues.programs]);
    const [learningObjectiveOptions, setLearningObjectiveOptions] = useState<OptionDefinition[]>(
        [],
    );

    const questionDifficultySelectProps: QuestionDifficultySelectProps = {
        formValues,
        handleFormValueChange,
    };

    const answerListProps: AnswerListProps = {
        formValues,
        handleFormValueChange,
        mode,
    };

    const questionTypeSelectProps: QuestionTypeSelectProps = {
        formValues,
        handleFormValueChange,
    };

    const questionStatusSelectProps: QuestionStatusSelectProps = {
        formValues,
        handleFormValueChange,
    };

    const questionTextInputProps: QuestionTextInputProps = {
        formValues,
        handleFormValueChange,
        errors,
    };

    const learningObjectiveSelectProps: LearningObjectiveSelectProps = {
        formValues,
        handleFormValueChange,
        errors,
        learningObjectiveOptions,
        learningObjectiveDict,
    };

    const programSelectProps: ProgramSelectProps = {
        formValues: formValues,
        handleFormValueChange,
        errors,
    };

    return (
        <Container header={<Header variant="h2">Question details</Header>}>
            <SpaceBetween direction="vertical" size="l">
                <QuestionTypeSelect {...questionTypeSelectProps} />
                <ProgramSelect {...programSelectProps} />
                <QuestionStatusSelect {...questionStatusSelectProps} />
                {!loading && formValues.programs!.length > 0 && (
                    <LearningObjectiveSelect {...learningObjectiveSelectProps} />
                )}
                <QuestionTextInput {...questionTextInputProps} />
                <QuestionDifficultySelect {...questionDifficultySelectProps} />
                <FormField stretch>
                    <AnswerList {...answerListProps} />
                </FormField>
            </SpaceBetween>
        </Container>
    );
};

export default QuestionForm;
