import {
    Box,
    Button,
    Header,
    Pagination,
    SpaceBetween,
    Table,
    TextFilter,
} from '@amzn/awsui-components-react';
import React from 'react';
import AddUserModal from './addUser/AddUserModal';
import { UserMgmtTestIds } from '../../common/dataTestIds/userMgmt';

export const UsersList = () => {
    const [showAddUserModal, setShowAddUserModal] = React.useState(false);

    const handleAddUserClick = () => {
        setShowAddUserModal(true);
    };
    return (
        <>
            <Table
                renderAriaLive={({ firstIndex, lastIndex, totalItemsCount }) =>
                    `Displaying items ${firstIndex} to ${lastIndex} of ${totalItemsCount}`
                }
                columnDefinitions={[
                    {
                        id: 'name',
                        header: 'Name',
                        cell: (e) => '',
                        sortingField: 'name',
                        isRowHeader: true,
                    },
                    {
                        id: 'alias',
                        header: 'Alias',
                        cell: (e) => '',
                        sortingField: 'alt',
                    },
                    {
                        id: 'role',
                        header: 'Role',
                        cell: (e) => '',
                    },
                    {
                        id: 'status',
                        header: 'Status',
                        cell: (e) => '',
                    },
                ]}
                items={[]}
                loadingText="Loading resources"
                trackBy="name"
                variant="full-page"
                selectionType="multi"
                empty={
                    <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
                        <SpaceBetween size="m">
                            <b>No resources</b>
                        </SpaceBetween>
                    </Box>
                }
                filter={
                    <TextFilter
                        filteringPlaceholder="Find resources"
                        filteringText=""
                        countText="0 matches"
                    />
                }
                header={
                    <Header
                        actions={
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button data-testid={UserMgmtTestIds.RemoveUserButtonId}>
                                    Remove
                                </Button>
                                <Button
                                    variant="primary"
                                    data-testid={UserMgmtTestIds.AddUserButtonId}
                                    onClick={handleAddUserClick}
                                >
                                    Add new user
                                </Button>
                            </SpaceBetween>
                        }
                    >
                        Manage Users
                    </Header>
                }
                pagination={<Pagination currentPageIndex={1} pagesCount={2} />}
            />
            <AddUserModal
                showAddUserModal={showAddUserModal}
                setShowAddUserModal={setShowAddUserModal}
            />
        </>
    );
};
