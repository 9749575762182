import '@amzn/awsui-global-styles/polaris.css';
import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { AppLayout, Box, Flashbar, Spinner, TopNavigation } from '@amzn/awsui-components-react';
import { useSelector } from 'react-redux';
import { selectContentType } from './reducers/navigationReducer';
import { Breadcrumbs, Footer, NavigationSidebar } from './components';
import { APPLICATION_NAME } from './common/constants/application';
import { AccessDenied } from './pages';

import { useNotifications } from './context/NotificationsProvider';
import { useUserInfo } from './hooks/useUserInfo';

const App = () => {
    const contentType = useSelector(selectContentType);
    const [navigationOpen, setNavigationOpen] = useState(false);
    const { notifications } = useNotifications();
    const { loading, user, isAuthError } = useUserInfo();

    if (!loading && isAuthError) {
        return <AccessDenied />;
    }

    if (loading || !user?.hasOwnProperty('id')) {
        return (
            <Box
                padding={{
                    top: 'xxl',
                }}
                textAlign="center"
            >
                <Spinner size="big" variant="disabled" />
            </Box>
        );
    }

    return (
        <>
            <TopNavigation
                identity={{
                    href: '/',
                    title: APPLICATION_NAME,
                    logo: {
                        src: '/public/aws.png',
                        alt: 'Service',
                    },
                }}
            />
            <AppLayout
                toolsHide
                stickyNotifications
                navigation={<NavigationSidebar />}
                ariaLabels={{ navigationClose: 'close' }}
                navigationOpen={navigationOpen}
                onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
                contentType={contentType}
                content={<Outlet />}
                footerSelector="assessment-footer"
                breadcrumbs={<Breadcrumbs />}
                notifications={<Flashbar items={notifications} stackItems />}
            />
            <Footer />
        </>
    );
};

export default App;
