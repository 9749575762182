import { NodeEnvironment, getNodeEnvironment } from '../nodeEnvironment';
export const APPLICATION_NAME = 'Assessment Manager';
export const EMAIL_VERIFICATION_ERROR = 'Email Address is not verified, verification required';

const PRODUCTION_APEX_DOMAIN = 'manager.assessments.skillbuilder.aws';
const PREPRODUCTION_APEX_DOMAIN = 'assessments.skillbuilder.training.aws.dev';
const DEVELOPER_APEX_DOMAIN = `manager.${process.env.USER}.${PREPRODUCTION_APEX_DOMAIN}`;

export const APPLICATION_URL = {
    LOCAL: 'http://localhost:3000',
    DEVELOPMENT: `https://${DEVELOPER_APEX_DOMAIN}`,
    BETA: `https://manager.beta.${PREPRODUCTION_APEX_DOMAIN}`,
    GAMMA: `https://manager.gamma.${PREPRODUCTION_APEX_DOMAIN}`,
    PROD: `https://${PRODUCTION_APEX_DOMAIN}`,
};

const endpoints = {
    [NodeEnvironment.LOCAL]: `${APPLICATION_URL.LOCAL}/graphql`,
    [NodeEnvironment.DEVELOPMENT]: `${APPLICATION_URL.DEVELOPMENT}/graphql`,
    [NodeEnvironment.BETA]: `${APPLICATION_URL.BETA}/graphql`,
    [NodeEnvironment.GAMMA]: `${APPLICATION_URL.GAMMA}/graphql`,
    [NodeEnvironment.PROD]: `${APPLICATION_URL.PROD}/graphql`,
};

export const getClientEndpoint = () => {
    if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
        return endpoints[NodeEnvironment.LOCAL];
    }
    return endpoints[getNodeEnvironment()] || endpoints[NodeEnvironment.LOCAL];
};

export const E2E_TEST_ENV_KEY = 'e2eTestEnvironment';
