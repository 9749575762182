import React from 'react';
import { Box } from '@amzn/awsui-components-react';

interface ValueWithLabelProps {
    label: string;
    children: React.ReactNode;
}

const ValueWithLabel = ({ label, children }: ValueWithLabelProps) => {
    return (
        <div>
            <Box variant="awsui-key-label">{label}</Box>
            <div>{children?.toString()}</div>
        </div>
    );
};

export default ValueWithLabel;
