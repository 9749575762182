import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setBreadcrumbs, setContentType } from '../../reducers/navigationReducer';
import { QUESTION_BANK_DETAIL_ROUTE, QUESTION_BANK_LIST_ROUTE } from '../../router/router';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Box,
    Button,
    ColumnLayout,
    Container,
    ContentLayout,
    Header,
    PropertyFilterProps,
    SpaceBetween,
    Tabs,
} from '@amzn/awsui-components-react';
import { ValueWithLabel } from '../../components';
import EmbeddedQuestionsTable, {
    EmbeddedQuestionsTableProps,
} from '../../components/questions/EmbeddedQuestionsTable';
import { useQuestionBankContext } from '../../context/QuestionBankProvider';
import { QuestionBankIds } from '../../common/dataTestIds/questionBanks';
import QuestionBankQuestionsPropertyFilter, {
    QuestionBankQuestionsPropertyFilterProps,
} from './QuestionBankQuestionsPropertyFilter';
import { useQuestionsContext } from '../../context/QuestionsProvider';

const QuestionBankDetail = () => {
    const dispatch = useDispatch();
    const { id = '', version = 1 } = useParams();
    const navigate = useNavigate();
    const [query, setQuery] = useState<PropertyFilterProps.Query>({
        tokens: [],
        operation: 'and',
    });
    const { questionBank, associatedMetadata, handleGetQuestionBank, handleGetAssociatedMetadata } =
        useQuestionBankContext();

    const { getQuestions, learningObjectiveDict } = useQuestionsContext();

    useEffect(() => {
        dispatch(
            setBreadcrumbs([
                {
                    text: QUESTION_BANK_LIST_ROUTE.title,
                    href: QUESTION_BANK_LIST_ROUTE.path,
                },
                {
                    text: QUESTION_BANK_DETAIL_ROUTE.title,
                    href: QUESTION_BANK_DETAIL_ROUTE.path,
                },
            ]),
        );
        dispatch(setContentType('content'));
    }, [dispatch]);

    useEffect(() => {
        if (id && version) {
            handleGetQuestionBank(id, Number(version));
        }
    }, [id, version]);

    useEffect(() => {
        if (questionBank?.id === id && questionBank.associatedMetadata!.length !== 0) {
            handleGetAssociatedMetadata({
                associatedMetadataIds: questionBank.associatedMetadata!.map((md) => md.id),
            });
        }
    }, [questionBank]);

    const questionBankQuestionPropertyFilterProps: QuestionBankQuestionsPropertyFilterProps = {
        query,
        setQuery,
        initialLearningObjectiveDict: learningObjectiveDict,
        getQuestions,
        questionFilterVariables: {
            questionBanks: [id],
        },
    };

    const embeddedQuestionTableProps: EmbeddedQuestionsTableProps = {
        TablePropertyFilter: (
            <QuestionBankQuestionsPropertyFilter {...questionBankQuestionPropertyFilterProps} />
        ),
        questionFilterVariables: {
            questionBanks: [id],
        },
    };

    return (
        <ContentLayout
            header={
                <Header
                    variant="h1"
                    actions={
                        <SpaceBetween size="s" direction="horizontal">
                            <Button
                                key={`edit-question-bank-button`}
                                data-testid="edit-question-bank-button"
                                onClick={() =>
                                    navigate(
                                        `/question-banks/${questionBank?.id}/version/${questionBank?.version}/edit`,
                                        {
                                            state: { ...questionBank },
                                        },
                                    )
                                }
                            >
                                Edit Question Bank
                            </Button>
                        </SpaceBetween>
                    }
                >
                    Question Bank Details
                </Header>
            }
        >
            <SpaceBetween size="l">
                <Container>
                    <ColumnLayout columns={2} variant="text-grid">
                        <SpaceBetween size="l">
                            <ValueWithLabel testId={QuestionBankIds.Name} label="Name">
                                {questionBank?.name}
                            </ValueWithLabel>
                            <ValueWithLabel testId={QuestionBankIds.Program} label="Program">
                                {questionBank && questionBank.programs.length
                                    ? questionBank.programs[0]
                                    : ' - '}
                            </ValueWithLabel>
                            <ValueWithLabel testId={QuestionBankIds.Status} label="Status">
                                {questionBank?.status}
                            </ValueWithLabel>
                        </SpaceBetween>
                        <SpaceBetween size="l">
                            <div>
                                <Box
                                    data-testid={QuestionBankIds.LearningObjectives}
                                    variant="awsui-key-label"
                                >
                                    Learning Objectives
                                </Box>
                                <div>
                                    {associatedMetadata ? (
                                        <ul>
                                            {associatedMetadata?.map((lo, i) => {
                                                return <li key={i}>{lo.name}</li>;
                                            })}
                                        </ul>
                                    ) : null}
                                </div>
                            </div>
                        </SpaceBetween>
                    </ColumnLayout>
                </Container>

                <Container header={<Header variant="h2">Question bank details</Header>}>
                    <SpaceBetween direction="vertical" size="l">
                        <Tabs
                            tabs={[
                                {
                                    label: 'Questions',
                                    id: 'questions-tab',
                                    content: (
                                        <EmbeddedQuestionsTable {...embeddedQuestionTableProps} />
                                    ),
                                },
                            ]}
                        />
                    </SpaceBetween>
                </Container>
            </SpaceBetween>
        </ContentLayout>
    );
};

export default QuestionBankDetail;
