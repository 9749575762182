import Dashboard from './Dashboard';
import QuestionsList from './questions/questionsList/QuestionsList';
import QuestionCreate from './questions/QuestionCreate';
import { AccessDenied } from './errors/AccessDenied';
import QuestionDetail from './questions/QuestionDetail';
import QuestionEdit from './questions/QuestionEdit';
import QuestionCreateGenAi from './questions/genAi/QuestionCreateGenAi';
import QuestionBankList from './questionBanks/questionBankList/QuestionBankList';
import QuestionBankCreate from './questionBanks/QuestionBankCreate';
import QuestionBankEdit from './questionBanks/QuestionBankEdit';
import QuestionBankDetail from './questionBanks/QuestionBankDetail';
import AssessmentList from './assessments/assessmentsList/AssessmentListPage';
import AssessmentWizardCreate from './assessments/assessmentWizard/AssessmentWizardCreate';
import AssessmentDetail from './assessments/AssessmentDetail';
import LearningObjectiveList from './learningObjectives/learningObjectiveList/LearningObjectiveList';
import LearningObjectiveCreate from './learningObjectives/LearningObjectiveCreate';
import LearningObjectiveDetail from './learningObjectives/LearningObjectiveDetail';
import LearningObjectiveEdit from './learningObjectives/LearningObjectiveEdit';
import AssessmentEditDetails from './assessments/AssessmentEdit/AssessmentEditDetails';
import AssessmentEditQuestions from './assessments/AssessmentEdit/AssessmentEditQuestions';
import AssessmentEditSettings from './assessments/AssessmentEdit/AssessmentEditSettings';
import UsersList from './userManagement/usersList/UsersList';
import UpdateUser from './userManagement/UpdateUser';

export {
    Dashboard,
    QuestionsList,
    QuestionCreate,
    AccessDenied,
    QuestionDetail,
    QuestionEdit,
    QuestionCreateGenAi,
    QuestionBankList,
    QuestionBankCreate,
    QuestionBankEdit,
    QuestionBankDetail,
    AssessmentList,
    LearningObjectiveList,
    AssessmentDetail,
    AssessmentWizardCreate,
    LearningObjectiveCreate,
    LearningObjectiveDetail,
    LearningObjectiveEdit,
    AssessmentEditDetails,
    AssessmentEditQuestions,
    AssessmentEditSettings,
    UsersList,
    UpdateUser,
};
