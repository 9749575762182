import React, { useEffect, useMemo, useState } from 'react';
import {
    Box,
    Button,
    CollectionPreferences,
    CollectionPreferencesProps,
    Header,
    Link,
    Pagination,
    PropertyFilterProps,
    SpaceBetween,
    Table,
    TableProps,
} from '@amzn/awsui-components-react';
import { useNavigate } from 'react-router-dom';
import { truncateText } from '../../utils/appUtils';
import AssessmentsListPropertyFilter, {
    LOCAL_STORAGE_ASSESSMENTS_FILTER_KEY,
    StoredAssessmentsFilters,
} from './AssessmentsListPropertyFilter';
import { Dictionary } from '../../interfaces/dictionary';
import { EmptyState } from '../';
import {
    Assessment,
    useGetAssessmentsLazyQuery,
    useGetAssessmentUsersLazyQuery,
    useGetLearningObjectivesLazyQuery,
} from '../../graphql';
import { ASSESSMENT_CREATE_ROUTE } from '../../router/router';
import {
    tableCollectionPreferencesProps,
    TABLE_DEFAULT_PAGE_SIZE,
} from '../../common/constants/tablePreferences';
import { isProd } from '../../common/nodeEnvironment';

export interface AssessmentsListProps {
    variant?: TableProps.Variant;
    enableAddButton?: boolean;
    description?: string;
    preferencesKey: string;
    preferences?: { [key: string]: any };
    columnWidthsKey: string;
    columnWidths?: { [key: string]: number | undefined };
    visibleColumns?: string[];
    enabledFilters?: string[];
    defaultFilterCriteria?: { [key: string]: any };
}

interface AssessmentTableHeaderProps {
    currentCount: number;
    enableAddButton: boolean;
    description: string | undefined;
}

const AssessmentListTableHeader = ({
    currentCount,
    enableAddButton,
    description,
}: AssessmentTableHeaderProps) => {
    const navigate = useNavigate();
    return (
        <Header
            counter={`(${currentCount})`}
            description={description}
            actions={
                enableAddButton && (
                    <SpaceBetween size="xs" direction="horizontal">
                        <SpaceBetween size="xs" direction="horizontal">
                            <Button
                                onClick={() => navigate(ASSESSMENT_CREATE_ROUTE.path)}
                                variant="primary"
                                data-testid="create-assessment"
                            >
                                Create assessment
                            </Button>
                        </SpaceBetween>
                    </SpaceBetween>
                )
            }
        >
            Assessments
        </Header>
    );
};

const assessmentsTableDefaultPreferences = {
    pageSize: TABLE_DEFAULT_PAGE_SIZE,
    wrapLines: false,
    contentDisplay: [
        { id: 'assessmentTitle', visible: true },
        { id: 'assessmentStatus', visible: true },
        { id: 'assessmentVersion', visible: true },
        { id: 'assessmentLearningObjectives', visible: true },
        { id: 'assessmentPrograms', visible: true },
        // TODO: Remove prod conditional once CHIRONASSMNTS-459 is complete
        ...(isProd()
            ? []
            : [
                  { id: 'modifiedByName', visible: false },
                  { id: 'createdByName', visible: false },
              ]),
    ],
};

const AssessmentList = (props: AssessmentsListProps) => {
    const navigate = useNavigate();
    const [assessments, setAssessments] = useState<Assessment[]>([]);
    const [query, setQuery] = useState<PropertyFilterProps.Query>({
        tokens: [],
        operation: 'and',
    });
    const [learningObjectiveDict, setLearningObjectiveDict] = useState({});
    const [usersInfoDict, setUsersInfoDict] = useState<Dictionary<string>>({});
    const [isLoading, setIsLoading] = useState(true);
    const [currentPageIndex, setCurrentPageIndex] = useState(1);
    const [currentCount, setCurrentCount] = useState(0);
    const [pagesCount, setPagesCount] = useState(1);
    // combine column widths from parameter (props) and local storage, if any
    const [columnWidths, setColumnWidths] = useState({
        ...(props.columnWidths ?? {}),
        ...JSON.parse(localStorage.getItem(props.columnWidthsKey) || '{}'),
    });
    // combine preferences from default, parameter (props), local storage, and visibleColumns parameter
    let combinedPreferences = {
        ...assessmentsTableDefaultPreferences,
        ...(props.preferences ?? {}),
    };
    const visibleColumns = props.visibleColumns ?? [];
    if (visibleColumns.length > 0) {
        combinedPreferences.contentDisplay.forEach((item, index, items) => {
            items[index] = { ...item, visible: visibleColumns.includes(item.id) };
        });
    }
    const [preferences, setPreferences] = useState({
        ...combinedPreferences,
        ...JSON.parse(localStorage.getItem(props.preferencesKey) || '{}'),
    });

    // queries
    const [getFilteredAssessments, { data, fetchMore }] = useGetAssessmentsLazyQuery({
        fetchPolicy: 'cache-and-network',
    });
    const [getAssessmentsLearningObjectives, { data: learningObjectivesData }] =
        useGetLearningObjectivesLazyQuery({
            fetchPolicy: 'cache-and-network',
        });
    const [getUsers] = useGetAssessmentUsersLazyQuery({
        fetchPolicy: 'cache-and-network',
    });

    useEffect(() => {
        const storedFilterString = localStorage.getItem(LOCAL_STORAGE_ASSESSMENTS_FILTER_KEY);
        if (storedFilterString) {
            try {
                let savedFilters = JSON.parse(storedFilterString) as StoredAssessmentsFilters;
                try {
                    if (savedFilters.propertyFilters) {
                        setQuery(savedFilters.propertyFilters);
                    }
                    handleGetAssessments(savedFilters);
                } catch (e) {}
            } catch (e) {}
        } else {
            handleGetAssessments();
        }
    }, [preferences.pageSize]);

    const getUsersNamesForIds = async (userIds: Set<string>): Promise<any> => {
        const { data } = await getUsers({
            variables: {
                id: Array.from(userIds),
            },
        });
        let userDict = {};
        const users = data?.assessmentUsers?.users;
        if (users) {
            users.forEach((u) => {
                userDict = {
                    ...userDict,
                    [u.id]: u.name,
                };
            });
        }
        return userDict;
    };

    const handleGetUserInfo = async (assessments: Assessment[]) => {
        const userIds = new Set<string>();
        assessments.forEach((a) => {
            a.modifiedBy && userIds.add(a.modifiedBy);
            a.createdBy && userIds.add(a.createdBy);
        });
        const usersInfoDict = await getUsersNamesForIds(userIds);
        setUsersInfoDict(usersInfoDict);
    };

    const handleGetAssessments = async (filters?: any) => {
        setIsLoading(true);
        let tokens = { ...props.defaultFilterCriteria };
        if (filters && filters.propertyFilters.tokens.length !== 0) {
            filters.propertyFilters.tokens.forEach((token: PropertyFilterProps.FilteringOption) => {
                if (token.propertyKey === 'difficulty') {
                    tokens[token.propertyKey] = Number(token.value);
                } else if (token.propertyKey === 'searchText') {
                    if (!tokens[token.propertyKey]) {
                        tokens[token.propertyKey] = '';
                    }
                    tokens[token.propertyKey] += token.value + ' ';
                } else {
                    tokens[token.propertyKey] = [token.value];
                }
            });
        }
        const { data } = await getFilteredAssessments({
            variables: {
                ...tokens,
                size: preferences.pageSize,
            },
        });
        const assessments = [...data?.assessments?.assessments!];
        if (assessments) {
            const totalCount = data?.assessments?.totalCount! ?? 0;
            const totalPagesCount = Math.ceil(totalCount / preferences.pageSize);
            if (currentPageIndex > totalPagesCount) {
                // if the current page index > number of total pages, reset current index to page 1
                // this can happen when the results per page value is changed to
                // a larger number, e.g. user is on last page, say 100, with 10
                // results per page but changes to 100 results per page, so
                // there are now only 10 pages total but the user's page index
                // is 100.
                setCurrentPageIndex(1);
            }
            setPagesCount(totalPagesCount);
            setCurrentCount(totalCount);
        }
        const sortedAssessments = assessments.sort((a, b) =>
            a.modifiedTimestamp! < b.modifiedTimestamp! ? 1 : -1,
        );
        handleGetAssessmentsLearningObjectives(sortedAssessments!);
        handleGetUserInfo(sortedAssessments);
        setIsLoading(false);
    };

    const handleGetAssessmentsLearningObjectives = async (assessments: Assessment[]) => {
        let learningObjectives: string[] = [];
        assessments.forEach((assessment) => {
            assessment.learningObjectives?.forEach((lo) => {
                learningObjectives.push(lo.id);
            });
        });
        const { data } = await getAssessmentsLearningObjectives({
            variables: {
                learningObjectives,
                size: 1000,
            },
        });
        let learningObjectiveDict = {};
        data?.assessmentLearningObjectives.metadataObjects.forEach((objective) => {
            // Learning objective dictionary
            learningObjectiveDict = {
                ...learningObjectiveDict,
                [objective.id]: objective.name,
            };
        });
        const assessmentsWithLO = assessments.map((assessment) => {
            return {
                ...assessment,
                learningObjectives: assessment.learningObjectives?.map((lo) => ({
                    ...lo,
                    learningObjective:
                        learningObjectiveDict[lo.id as keyof typeof learningObjectiveDict],
                })),
            };
        });
        setLearningObjectiveDict(learningObjectiveDict);
        setAssessments(assessmentsWithLO);
    };

    const handlePaginationChange = async (event: any) => {
        setIsLoading(true);
        const { currentPageIndex } = event.detail;
        setCurrentPageIndex(currentPageIndex);
        const from = (currentPageIndex - 1) * preferences.pageSize;
        const assessmentsData = await fetchMore({
            variables: {
                from: from,
                size: preferences.pageSize,
            },
        });
        const assessments = assessmentsData?.data?.assessments?.assessments!;
        const totalCount = assessmentsData?.data?.assessments?.totalCount!;
        setCurrentCount(totalCount);
        const totalPagesCount = Math.ceil(totalCount / preferences.pageSize);
        setPagesCount(totalPagesCount);
        const sortedAssessments = assessments?.sort((a, b) =>
            a.modifiedTimestamp! < b.modifiedTimestamp! ? 1 : -1,
        );
        handleGetAssessmentsLearningObjectives(sortedAssessments!);
        handleGetUserInfo(sortedAssessments);
        setIsLoading(false);
    };

    const handleTablePreferencesConfirm = async ({ detail }: any) => {
        setPreferences(detail);
        localStorage.setItem(props.preferencesKey, JSON.stringify(detail));
    };

    const handleTableColumnWidthChange = async ({ detail }: any) => {
        let columnWidths: Record<string, number> = {};
        for (const ix in columnDefinitions) {
            columnWidths[columnDefinitions[ix].id] = detail.widths[ix];
        }
        setColumnWidths(columnWidths);
        localStorage.setItem(props.columnWidthsKey, JSON.stringify(columnWidths));
    };

    const columnDefinitions = useMemo(() => {
        return [
            {
                id: 'assessmentTitle',
                header: 'Title',
                isRowHeader: true,
                width: columnWidths['assessmentTitle'] || 500,
                cell: (item: Assessment) => (
                    <Link
                        variant="secondary"
                        href={`/assessments/${item.id}/version/${item.version}`}
                        onFollow={(e) => {
                            e.preventDefault();
                            navigate(`/assessments/${item.id}/version/${item.version}`, {
                                state: { ...item },
                            });
                        }}
                    >
                        {item.title}
                    </Link>
                ),
            },
            {
                id: 'assessmentStatus',
                header: 'Status',
                isRowHeader: true,
                width: columnWidths['assessmentStatus'] || 120,
                cell: (item: Assessment) => item.status,
            },
            {
                id: 'assessmentLearningObjectives',
                header: 'Learning objectives',
                isRowHeader: true,
                width: columnWidths['assessmentLearningObjectives'] || 500,
                cell: (item: Assessment) =>
                    truncateText(
                        item
                            .learningObjectives!.map(
                                (lo) =>
                                    learningObjectiveDict[
                                        lo.id as keyof typeof learningObjectiveDict
                                    ],
                            )
                            .join(', '),
                        500,
                    ),
            },
            {
                id: 'assessmentPrograms',
                header: 'Programs',
                isRowHeader: true,
                width: columnWidths['assessmentPrograms'] || undefined,
                cell: (item: Assessment) => item.programs?.join(', '),
            },
            {
                id: 'assessmentVersion',
                header: 'Version',
                isRowHeader: true,
                width: columnWidths['assessmentVersion'] || 100,
                cell: (item: Assessment) => item.version,
            },
            // TODO: Remove prod conditional once CHIRONASSMNTS-459 is complete
            ...(isProd()
                ? []
                : [
                      {
                          id: 'createdByName',
                          header: 'Created By',
                          isRowHeader: true,
                          width: columnWidths['createdByName'] || undefined,
                          cell: (item: Assessment) =>
                              (item.createdBy && usersInfoDict[item.createdBy]) || '',
                      },
                      {
                          id: 'modifiedByName',
                          header: 'Modified By',
                          isRowHeader: true,
                          width: columnWidths['modifiedByName'] || undefined,
                          cell: (item: Assessment) =>
                              (item.modifiedBy && usersInfoDict[item.modifiedBy]) || '',
                      },
                  ]),
        ];
    }, [learningObjectiveDict, usersInfoDict]);

    const assessmentsListPropertyFilterProps = {
        query,
        setQuery,
        handleGetAssessments,
        learningObjectiveDict,
        enabledFilters: props.enabledFilters ?? [],
    };

    const assessmentTableHeader: AssessmentTableHeaderProps = {
        currentCount,
        enableAddButton: props.enableAddButton ?? true,
        description: props.description,
    };

    const collectionPreferencesProps = {
        ...tableCollectionPreferencesProps,
        contentDisplayPreference: {
            ...tableCollectionPreferencesProps.contentDisplayPreference,
            options: columnDefinitions.map(({ id, header }) => ({
                id,
                label: header,
                alwaysVisible: ['assessmentTitle'].includes(id),
            })),
        },
    };

    return (
        <Table
            header={<AssessmentListTableHeader {...assessmentTableHeader} />}
            loadingText="Loading assessments"
            resizableColumns
            enableKeyboardNavigation
            stickyHeader={true}
            variant={props.variant || 'full-page'}
            items={assessments}
            columnDefinitions={columnDefinitions}
            filter={<AssessmentsListPropertyFilter {...assessmentsListPropertyFilterProps} />}
            onColumnWidthsChange={handleTableColumnWidthChange}
            wrapLines={preferences.wrapLines}
            stripedRows={preferences.stripedRows}
            contentDensity={preferences.contentDensity}
            columnDisplay={preferences.contentDisplay}
            preferences={
                <CollectionPreferences
                    {...collectionPreferencesProps}
                    preferences={preferences}
                    onConfirm={handleTablePreferencesConfirm}
                />
            }
            pagination={
                <Pagination
                    currentPageIndex={currentPageIndex}
                    pagesCount={pagesCount}
                    onChange={handlePaginationChange}
                />
            }
            empty={<EmptyState displayMessage="No assessments found." />}
            loading={isLoading}
        />
    );
};

export default AssessmentList;
