import React, { useEffect, useMemo, useState } from 'react';
import { Box, Header, Pagination, PropertyFilterProps, Table } from '@amzn/awsui-components-react';
import QuestionsListPropertyFilter, {
    QuestionsListPropertyFilterProps,
} from '../../pages/questions/questionsList/QuestionsListPropertyFilter';
import SelectQuestionCheckbox, {
    SelectQuestionCheckboxProps,
} from '../common/formFields/SelectQuestionCheckbox';
import { AssessmentQuestion } from '../../graphql';
import { useQuestionsContext } from '../../context/QuestionsProvider';
import { GetQuestionOptions } from '../../hooks/useQuestion';

export interface EmbeddedSelectableQuestionsTableProps {
    selectedQuestions: Set<AssessmentQuestion>;
    handleSelectedQuestionChange: (selectedQuestions: Set<AssessmentQuestion>) => void;
    formValues: any;
    questionFilterVariables?: any;
    HeaderAction: React.ReactNode;
    questionFilterOptions?: GetQuestionOptions;
}

const EmbeddedSelectableQuestionsTable = ({
    selectedQuestions,
    handleSelectedQuestionChange,
    HeaderAction,
    questionFilterVariables,
    questionFilterOptions,
}: EmbeddedSelectableQuestionsTableProps) => {
    const [query, setQuery] = useState<PropertyFilterProps.Query>({
        tokens: [],
        operation: 'and',
    });

    const {
        questions,
        isLoading,
        currentPageIndex,
        pagesCount,
        handlePaginationChange,
        learningObjectiveDict,
        getQuestionsByPropertyFilter,
        currentCount,
        getQuestions,
    } = useQuestionsContext();

    useEffect(() => {
        if (questionFilterOptions) {
            getQuestions(questionFilterVariables, questionFilterOptions);
        } else {
            getQuestions(questionFilterVariables);
        }
    }, []);

    const columnDefinitions = useMemo(() => {
        return [
            {
                id: 'selectQuestion',
                header: '',
                cell: (item: AssessmentQuestion) => {
                    const selectQuestionCheckboxProps: SelectQuestionCheckboxProps = {
                        item: item,
                        selectedQuestions,
                        handleSelectedQuestionChange,
                    };

                    return <SelectQuestionCheckbox {...selectQuestionCheckboxProps} />;
                },
            },
            {
                id: 'questionText',
                header: 'Question Text',
                cell: (item: AssessmentQuestion) => item.questionText,
                isRowHeader: true,
            },
            {
                id: 'learningObjective',
                header: 'Learning Objective',
                cell: (item: AssessmentQuestion) =>
                    //@ts-ignore - Ignoring to avoid complicating typing - This is the whole LO meta object
                    item.learningObjectives![0] ? item.learningObjectives![0].name : '',
            },
            {
                id: 'status',
                header: 'Status',
                cell: (item: AssessmentQuestion) => item.status,
                isRowHeader: true,
            },
            {
                id: 'type',
                header: 'Type',
                cell: (item: AssessmentQuestion) => item.type,
                isRowHeader: true,
            },
            {
                id: 'programs',
                header: 'Programs',
                cell: (item: AssessmentQuestion) => item.programs?.join(', '),
                isRowHeader: true,
            },
            {
                id: 'difficulty',
                header: 'Difficulty',
                cell: (item: AssessmentQuestion) => item.difficulty,
                isRowHeader: true,
            },
            {
                id: 'scoringMethod',
                header: 'Scoring Method',
                cell: (item: AssessmentQuestion) => item.scoringMethod,
                isRowHeader: true,
            },
        ];
    }, [selectedQuestions, questions]);

    const questionsListPropertyFilterProps: QuestionsListPropertyFilterProps = {
        query,
        setQuery,
        getQuestionsByPropertyFilter,
        initialLearningObjectiveDict: learningObjectiveDict,
    };

    return (
        <Table
            header={
                <Header
                    counter={!isLoading ? `(${currentCount})` : '(0)'}
                    variant="h1"
                    actions={HeaderAction}
                >
                    Questions
                </Header>
            }
            variant="embedded"
            items={questions}
            columnDefinitions={columnDefinitions}
            loading={isLoading}
            loadingText="Loading questions"
            enableKeyboardNavigation
            filter={<QuestionsListPropertyFilter {...questionsListPropertyFilterProps} />}
            pagination={
                <Pagination
                    currentPageIndex={currentPageIndex}
                    pagesCount={pagesCount}
                    onChange={handlePaginationChange}
                />
            }
            empty={
                <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
                    No questions found.
                </Box>
            }
        />
    );
};

export default EmbeddedSelectableQuestionsTable;
