import React, { useEffect, useState } from 'react';
import { FormField, Select } from '@amzn/awsui-components-react';
import { handleSelectedOption } from '../../../utils/formUtils';
import { useApolloClient } from '@apollo/client';
import { GetCurrentUserDocument, useGetAssessmentUserLazyQuery } from '../../../graphql';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';

export interface ProgramSelectProps {
    formValues: any;
    handleFormValueChange: (formUpdates: any) => void;
    errors: any;
}
const ProgramSelect = ({ formValues, handleFormValueChange, errors }: ProgramSelectProps) => {
    const client = useApolloClient();
    const [programs, setPrograms] = useState<OptionDefinition[]>([]);
    const [getAssessmentUser, { data: assessmentUserData }] = useGetAssessmentUserLazyQuery();

    useEffect(() => {
        const {
            currentUser: {
                gandalfDetails: { vibeId },
            },
        } = client.readQuery({
            query: GetCurrentUserDocument,
        });

        getAssessmentUser({
            variables: {
                id: vibeId,
            },
        });

        if (assessmentUserData) {
            if (
                // Specifically for team members who may have more than 1 program
                formValues.programs.length === 0 &&
                assessmentUserData.assessmentUser!.programs!.length === 1
            ) {
                handleFormValueChange({
                    programs: [assessmentUserData.assessmentUser!.programs![0]],
                });
            }
            setPrograms(
                assessmentUserData.assessmentUser!.programs!.map((program) => ({
                    label: program,
                    value: program,
                })),
            );
        }
    }, [assessmentUserData]);

    return (
        <FormField label="Program" errorText={errors.programs ?? null}>
            <Select
                selectedOption={handleSelectedOption({
                    selectedOption: formValues.programs[0],
                    options: programs,
                })}
                onChange={(e) => {
                    handleFormValueChange({
                        programs: [e.detail.selectedOption.value!],
                    });
                }}
                options={programs}
                placeholder="Select program"
            />
        </FormField>
    );
};

export default ProgramSelect;
