import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setBreadcrumbs, setContentType } from '../../reducers/navigationReducer';
import { ASSESSMENT_DETAIL_ROUTE, ASSESSMENT_LIST_ROUTE } from '../../router/router';
import {
    Assessment,
    AssessmentMetadataObject,
    AssessmentQuestion,
    useGetAssessmentQuery,
    useGetAssessmentQuestionsLazyQuery,
    useGetLearningObjectivesLazyQuery,
} from '../../graphql';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Box,
    Button,
    ColumnLayout,
    Container,
    ContentLayout,
    Header,
    SpaceBetween,
} from '@amzn/awsui-components-react';
import { ValueWithLabel } from '../../components';
import { Dictionary } from '../../interfaces/dictionary';
import { convertMilliSecondsToHours, convertMilliSecondsToMinutes } from '../../utils/timeUtils';
import { AssessmentDetailValueLabel } from '../../common/dataTestIds/assessmentDetails';

const AssessmentDetail = () => {
    const dispatch = useDispatch();
    const { id = '', version } = useParams();
    const [assessment, setAssessment] = useState<Assessment>();
    const navigate = useNavigate();
    const [learningObjectiveDict, setLearningObjectiveDict] =
        useState<Dictionary<AssessmentMetadataObject>>();
    const [questionDict, setQuestionDict] = useState<Dictionary<AssessmentQuestion>>();

    const { data: userAssessmentData, loading } = useGetAssessmentQuery({
        variables: {
            id,
            version: Number(version),
        },
        fetchPolicy: 'cache-and-network',
    });

    const [getAssessmentsLearningObjectives, { data: learningObjectivesData }] =
        useGetLearningObjectivesLazyQuery({
            fetchPolicy: 'network-only',
        });

    const [getFilteredQuestions] = useGetAssessmentQuestionsLazyQuery({
        fetchPolicy: 'cache-first',
    });

    useEffect(() => {
        dispatch(
            setBreadcrumbs([
                {
                    text: ASSESSMENT_LIST_ROUTE.title,
                    href: ASSESSMENT_LIST_ROUTE.path,
                },
                {
                    text: ASSESSMENT_DETAIL_ROUTE.title,
                    href: ASSESSMENT_DETAIL_ROUTE.path,
                },
            ]),
        );
        dispatch(setContentType('content'));
    }, [dispatch]);

    useEffect(() => {
        if (!loading && userAssessmentData) {
            const assessment = userAssessmentData.assessment!;
            handleGetQuestionsForAssessment(assessment);
        }
    }, [loading, userAssessmentData]);

    const handleGetQuestionsForAssessment = async (assessment: Assessment) => {
        const assessmentQuestionIds = [
            ...new Set(assessment.questions!.map((question) => question.id)),
        ];
        const formAssessmentLearningObjectives = [
            ...new Set(assessment.learningObjectives!.map((lo) => lo.id)),
        ];
        const { data } = await getFilteredQuestions({
            variables: {
                id: assessmentQuestionIds,
                size: assessmentQuestionIds.length,
            },
        });
        let tempQuestionDict = {};
        let formQuestionLearningObjectives: string[] = [];
        if (
            assessmentQuestionIds.length !== 0 &&
            data?.assessmentQuestions?.questions.length !== 0
        ) {
            data?.assessmentQuestions?.questions.forEach((question) => {
                // Questions dictionary
                tempQuestionDict = {
                    ...tempQuestionDict,
                    [question.id]: question,
                };
                formQuestionLearningObjectives.push(question.learningObjectives![0]);
            });
            setQuestionDict(tempQuestionDict);
            handleGetAssessmentsLearningObjectives({
                assessment,
                learningObjectives: [
                    ...new Set([
                        ...formQuestionLearningObjectives,
                        ...formAssessmentLearningObjectives,
                    ]),
                ],
            });
        } else {
            setQuestionDict(tempQuestionDict);
            handleGetAssessmentsLearningObjectives({
                assessment,
                learningObjectives: formAssessmentLearningObjectives,
            });
        }
    };

    const handleGetAssessmentsLearningObjectives = async ({
        learningObjectives,
        assessment,
    }: {
        learningObjectives: string[];
        assessment: Assessment;
    }) => {
        const { data } = await getAssessmentsLearningObjectives({
            variables: {
                learningObjectives,
                size: learningObjectives.length,
            },
        });
        let learningObjectiveDict = {};
        data!.assessmentLearningObjectives.metadataObjects.forEach((objective) => {
            // Learning objective dictionary
            learningObjectiveDict = {
                ...learningObjectiveDict,
                [objective.id]: objective,
            };
        });
        const assessmentWithLO = {
            ...assessment,
            learningObjectives: assessment.learningObjectives?.map((lo) => ({
                ...lo,
                learningObjective:
                    learningObjectiveDict[lo.id as keyof typeof learningObjectiveDict],
            })),
        };
        setAssessment(assessmentWithLO);
        setLearningObjectiveDict(learningObjectiveDict);
    };

    return (
        <ContentLayout header={<Header variant="h1">{ASSESSMENT_LIST_ROUTE.title}</Header>}>
            <SpaceBetween size="m">
                <Container
                    header={
                        <Header
                            variant="h1"
                            actions={
                                <Button
                                    key={`edit-question-button`}
                                    data-testid="edit-question-button"
                                    onClick={() =>
                                        navigate(
                                            `/assessments/${assessment?.id}/version/${assessment?.version}/edit/details`,
                                            {
                                                state: { ...assessment },
                                            },
                                        )
                                    }
                                >
                                    Edit
                                </Button>
                            }
                        >
                            Assessment details
                        </Header>
                    }
                >
                    <ColumnLayout columns={2} variant="text-grid">
                        <SpaceBetween size="l">
                            <ValueWithLabel
                                testId={AssessmentDetailValueLabel.AssessmentTitle}
                                label="Title"
                            >
                                {assessment?.title}
                            </ValueWithLabel>
                            <ValueWithLabel
                                testId={AssessmentDetailValueLabel.AssessmentStatus}
                                label="Status"
                            >
                                {assessment?.status}
                            </ValueWithLabel>
                            <ValueWithLabel
                                testId={AssessmentDetailValueLabel.AssessmentProgram}
                                label="Program"
                            >
                                {assessment && assessment.programs!.length
                                    ? assessment.programs![0]
                                    : ' - '}
                            </ValueWithLabel>
                        </SpaceBetween>

                        <SpaceBetween size="l">
                            <div>
                                <Box variant="awsui-key-label">Learning Objectives</Box>
                                <div>
                                    {learningObjectiveDict ? (
                                        <ul>
                                            {assessment?.learningObjectives?.map((lo, i) => (
                                                <li key={i}>{learningObjectiveDict[lo.id].name}</li>
                                            ))}
                                        </ul>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        </SpaceBetween>
                    </ColumnLayout>
                </Container>

                {assessment?.learningObjectives?.map((lo, i) => {
                    return (
                        <Container
                            key={`learning-objective-container-${i}`}
                            header={
                                <Header
                                    variant="h2"
                                    actions={
                                        <Button
                                            key={`edit-question-button`}
                                            data-testid="edit-question-button"
                                            onClick={() =>
                                                navigate(
                                                    `/assessments/${assessment?.id}/version/${assessment?.version}/edit/questions/${lo.id}`,
                                                    {
                                                        state: { ...assessment },
                                                    },
                                                )
                                            }
                                        >
                                            Edit
                                        </Button>
                                    }
                                >
                                    {learningObjectiveDict ? learningObjectiveDict[lo.id].name : ''}
                                </Header>
                            }
                        >
                            <ColumnLayout columns={1} variant="text-grid">
                                <SpaceBetween size="l">
                                    <>
                                        {assessment.questions
                                            ?.filter(
                                                (question) =>
                                                    question.learningObjectives[0] === lo.id,
                                            )
                                            .map((el, index) => (
                                                <ValueWithLabel
                                                    key={`${AssessmentDetailValueLabel.AssessmentLearningObjectives}-${index}`}
                                                    label={`Question ${index + 1}`}
                                                >
                                                    {questionDict
                                                        ? questionDict[el.id].questionText
                                                        : ''}
                                                </ValueWithLabel>
                                            ))}
                                    </>
                                </SpaceBetween>
                            </ColumnLayout>
                        </Container>
                    );
                })}

                <Container
                    header={
                        <Header
                            variant="h1"
                            actions={
                                <Button
                                    onClick={() =>
                                        navigate(
                                            `/assessments/${assessment?.id}/version/${assessment?.version}/edit/settings`,
                                            {
                                                state: { ...assessment },
                                            },
                                        )
                                    }
                                >
                                    Edit
                                </Button>
                            }
                        >
                            Assessment settings
                        </Header>
                    }
                >
                    <ColumnLayout columns={3} variant="text-grid">
                        <SpaceBetween size="l">
                            <ValueWithLabel
                                testId={AssessmentDetailValueLabel.AssessmentObjectiveScore}
                                label="Objective score"
                            >
                                {assessment?.isCategoryScoreEnabled}
                            </ValueWithLabel>
                            <ValueWithLabel
                                testId={AssessmentDetailValueLabel.AssessmentCopyPaste}
                                label="Copy paste"
                            >
                                {assessment?.isCopyPasteEnabled}
                            </ValueWithLabel>
                            <ValueWithLabel
                                testId={AssessmentDetailValueLabel.AssessmentShowCorrectAnswer}
                                label="Show correct answers"
                            >
                                {assessment?.isCorrectAnswersShown}
                            </ValueWithLabel>
                            <ValueWithLabel
                                testId={AssessmentDetailValueLabel.AssessmentShowDetailResult}
                                label="Show detailed results"
                            >
                                {assessment?.isDetailedResultsEnabled}
                            </ValueWithLabel>
                            <ValueWithLabel
                                testId={AssessmentDetailValueLabel.AssessmentShowFinalScore}
                                label="Show final score"
                            >
                                {assessment?.isFinalScoreEnabled}
                            </ValueWithLabel>
                            <ValueWithLabel
                                testId={AssessmentDetailValueLabel.AssessmentFlagQuestionEnabled}
                                label="Flagging questions"
                            >
                                {assessment?.isFlaggingEnabled}
                            </ValueWithLabel>
                        </SpaceBetween>

                        <SpaceBetween size="l">
                            <ValueWithLabel
                                testId={AssessmentDetailValueLabel.AssessmentSkipGoBack}
                                label="Skip and go back"
                            >
                                {assessment?.isNextBackEnabled}
                            </ValueWithLabel>
                            <ValueWithLabel
                                testId={AssessmentDetailValueLabel.AssessmentNotesEnabled}
                                label="Notes"
                            >
                                {assessment?.isNotesEnabled}
                            </ValueWithLabel>
                            <ValueWithLabel
                                testId={AssessmentDetailValueLabel.AssessmentPauseAssessment}
                                label="Pause Assessment"
                            >
                                {assessment?.isPausingEnabled}
                            </ValueWithLabel>
                            <ValueWithLabel
                                testId={AssessmentDetailValueLabel.AssessmentMaxAttempts}
                                label="Maximum number of attempts"
                            >
                                {assessment?.maxAttempts}
                            </ValueWithLabel>
                            <ValueWithLabel
                                testId={AssessmentDetailValueLabel.AssessmentPassingScore}
                                label="Passing score"
                            >
                                {assessment?.passingScore}
                            </ValueWithLabel>
                        </SpaceBetween>

                        <SpaceBetween size="l">
                            <ValueWithLabel
                                testId={AssessmentDetailValueLabel.AssessmentShowSubmittedResponse}
                                label="Show submitted response"
                            >
                                {assessment?.isSubmittedResponsesShown}
                            </ValueWithLabel>
                            <ValueWithLabel
                                testId={AssessmentDetailValueLabel.AssessmentQuestionOrdering}
                                label="Question ordering"
                            >
                                {assessment?.questionOrdering}
                            </ValueWithLabel>
                            <ValueWithLabel
                                testId={AssessmentDetailValueLabel.AssessmentScoreDisplay}
                                label="Score display"
                            >
                                {assessment?.scoreDisplay}
                            </ValueWithLabel>
                            <ValueWithLabel
                                testId={AssessmentDetailValueLabel.AssessmentTimeLimitMinutes}
                                label="Time limit (in minutes)"
                            >
                                {convertMilliSecondsToMinutes(assessment?.timeLimit || 0)}
                            </ValueWithLabel>
                            <ValueWithLabel
                                testId={AssessmentDetailValueLabel.AssessmentTimeLimitHours}
                                label="Wait time (in hours)"
                            >
                                {convertMilliSecondsToHours(assessment?.waitTime || 0)}
                            </ValueWithLabel>
                        </SpaceBetween>
                    </ColumnLayout>
                </Container>
            </SpaceBetween>
        </ContentLayout>
    );
};

export default AssessmentDetail;
