import { FormField, Textarea } from '@amzn/awsui-components-react';
import React from 'react';
import { MetadataMutationVariables } from '../../../common/constants/metadataObject';
import { MetaDataIds } from '../../../common/dataTestIds/metaDatumIds';

export interface MetadataObjectNameTextAreaProps {
    formValues: Partial<MetadataMutationVariables>;
    handleFormValueChange: (values: any) => void;
    errors?: any;
    label: string;
    keyName: string;
}

const MetadataObjectNameTextArea = ({
    formValues,
    handleFormValueChange,
    errors = {
        name: undefined,
    },
    label,
    keyName,
}: MetadataObjectNameTextAreaProps) => {
    return (
        <FormField data-testid={MetaDataIds.Name} label={label} errorText={errors[keyName] ?? null}>
            <Textarea
                data-testid={MetaDataIds.NameTextArea}
                onChange={({ detail }) =>
                    handleFormValueChange({ [keyName.toLocaleLowerCase()]: detail.value })
                }
                value={formValues.name!}
                placeholder={`Enter ${label} text`}
            />
        </FormField>
    );
};

export default MetadataObjectNameTextArea;
