import React, { useState } from 'react';
import {
    Box,
    Button,
    Checkbox,
    FormField,
    Modal,
    SpaceBetween,
    Textarea,
} from '@amzn/awsui-components-react';
import { DislikeReason, FeedbackType } from '../../../graphql';
import { GenAiQuestion } from './GenAiQuestionCards';

export interface FeedbackProps {
    openFeedbackModal: boolean;
    closeFeedbackModal: () => void;
    handleSubmitQuestionFeedBack: (
        feedbackType: FeedbackType,
        selectedQuestion?: GenAiQuestion,
        dislikeReasons?: DislikeReason[],
        additionalContext?: string,
    ) => void;
}

const GenAiQuestionFeedbackModal = React.memo(
    ({ openFeedbackModal, handleSubmitQuestionFeedBack, closeFeedbackModal }: FeedbackProps) => {
        const [feedbackAdditionalContext, setFeedbackAdditionalContext] = useState('');
        const [harmfulChecked, setHarmfulChecked] = useState(false);
        const [incompleteChecked, setIncompleteChecked] = useState(false);
        const [inaccurateChecked, setInaccurateChecked] = useState(false);
        const [otherChecked, setOtherChecked] = useState(false);

        return (
            <Modal
                visible={openFeedbackModal}
                onDismiss={() => closeFeedbackModal()}
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button
                                data-testid={`button-cancel-feedback`}
                                onClick={() => closeFeedbackModal()}
                                variant="link"
                            >
                                Cancel
                            </Button>
                            <Button
                                data-testid={`button-submit-feedback`}
                                variant="primary"
                                onClick={() => {
                                    const dislikeReasons = [];
                                    harmfulChecked && dislikeReasons.push(DislikeReason.Harmful);
                                    inaccurateChecked &&
                                        dislikeReasons.push(DislikeReason.Inaccurate);
                                    incompleteChecked &&
                                        dislikeReasons.push(DislikeReason.Incomplete);
                                    otherChecked && dislikeReasons.push(DislikeReason.Other);

                                    handleSubmitQuestionFeedBack(
                                        FeedbackType.ThumbsDown,
                                        undefined,
                                        dislikeReasons,
                                        feedbackAdditionalContext,
                                    );
                                    closeFeedbackModal();
                                }}
                            >
                                Submit
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
                header="What did you dislike about the question?"
            >
                <Box>
                    <SpaceBetween direction="vertical" size="xs">
                        <Checkbox
                            checked={harmfulChecked}
                            data-testid={`checkbox-option-harmful`}
                            onChange={() => setHarmfulChecked(!harmfulChecked)}
                        >
                            Harmful
                        </Checkbox>
                        <Checkbox
                            checked={incompleteChecked}
                            data-testid={`checkbox-option-incomplete`}
                            onChange={() => setIncompleteChecked(!incompleteChecked)}
                        >
                            Incomplete
                        </Checkbox>
                        <Checkbox
                            checked={inaccurateChecked}
                            data-testid={`checkbox-option-inaccurate`}
                            onChange={() => setInaccurateChecked(!inaccurateChecked)}
                        >
                            Inaccurate
                        </Checkbox>
                        <Checkbox
                            checked={otherChecked}
                            data-testid={`checkbox-option-other`}
                            onChange={() => setOtherChecked(!otherChecked)}
                        >
                            Other
                        </Checkbox>
                        <FormField label="Tell us more -- optional">
                            <Textarea
                                data-testid={`textarea-additional-context`}
                                value={feedbackAdditionalContext}
                                onChange={({ detail }) => {
                                    setFeedbackAdditionalContext(detail.value);
                                }}
                                placeholder="Enter Text"
                            />
                        </FormField>
                    </SpaceBetween>
                </Box>
            </Modal>
        );
    },
);

export default GenAiQuestionFeedbackModal;
