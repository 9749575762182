import { AssessmentQuestionInput } from '../../graphql';

export enum QuestionStatus {
    ARCHIVED = 'Archived',
    DRAFT = 'Draft',
    PUBLISHED = 'Published',
}

export enum QuestionType {
    MULTIPLE_CHOICE = 'Multiple Choice',
    MULTI_SELECT = 'Multi-Select',
    TRUE_OR_FALSE = 'True or False',
}

export enum ScoringMethod {
    STANDARD = 'Standard',
}

export enum QuestionDifficulty {
    OneHundred = 100,
    TwoHundred = 200,
    ThreeHundred = 300,
}

// TODO: As more form fields come online reset these to defaults
export const initialFormValues: AssessmentQuestionInput = {
    questionText: '',
    answers: [],
    status: QuestionStatus.DRAFT,
    type: QuestionType.MULTIPLE_CHOICE,
    difficulty: QuestionDifficulty.OneHundred,
    scoringMethod: 'Standard',
    learningObjectives: [],
    programs: [],
    questionBanks: [],
};

export const QUESTION_WITHOUT_ANSWERS_ERROR =
    'To successfully create a question, you must provide 2 or more answers to the question.';

export const QUESTION_WITH_MULTIPLE_CORRECT_ANSWERS =
    'Multiple choice questions can have only one correct answer.';

// Below enums are related to SynapseService which provides RAG API
export enum RAGApiDatasource {
    AWS_DOCUMENTATION = 'AWSDocumentation',
    CONAN = 'Conan',
}
