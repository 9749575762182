import * as React from 'react';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import { Form, FormField, Input, Select } from '@amzn/awsui-components-react';
import { AssessmentUserRolesConfig } from '../../../common/constants/assessmentRoles';
import ProgramSelect from '../../common/formFields/ProgramSelect';
import { UserMgmtTestIds } from '../../../common/dataTestIds/userMgmt';

type AddUserModalProps = {
    showAddUserModal: boolean;
    setShowAddUserModal: (state: boolean) => void;
};

const AddUserModal = ({ showAddUserModal, setShowAddUserModal }: AddUserModalProps) => {
    //Get all the keys of const AssessmentUserRolesConfig and map to display values
    const assessmentRolesOptions = Object.values(AssessmentUserRolesConfig).map((role: any) => {
        return { label: role.label, value: role.value };
    });

    const initialFormState = {
        alias: '',
        programs: [],
        assessmentRole: null,
    };

    const [addUserFormValues, setAddUserFormValues] = React.useState(initialFormState);

    const [addUserErrors, setAddUserErrors] = React.useState('');

    const handleAddUserFormValuesChange = (updates: any) => {
        setAddUserFormValues({
            ...addUserFormValues,
            ...updates,
        });
    };

    const handleAddUserFormSubmission = () => {
        //TODO: Call createAssessmentUser mutation
        //alert(JSON.stringify(addUserFormValues));
    };

    const handleModalDismiss = () => {
        setShowAddUserModal(false);
        setAddUserFormValues(initialFormState);
    };

    return (
        <Modal
            data-testid={UserMgmtTestIds.AddUserModalId}
            onDismiss={handleModalDismiss}
            visible={showAddUserModal}
            header="Add new user"
        >
            <Form
                data-testid={UserMgmtTestIds.AddUserFormId}
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            data-testid={UserMgmtTestIds.AddUserFormCancelButton}
                            formAction="none"
                            variant="link"
                            onClick={handleModalDismiss}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            data-testid={UserMgmtTestIds.AddUserFormSubmissionButton}
                            onClick={handleAddUserFormSubmission}
                        >
                            Add user
                        </Button>
                    </SpaceBetween>
                }
                errorText={addUserErrors}
            >
                <>
                    <SpaceBetween direction="vertical" size="l">
                        <FormField label="Amazon alias">
                            <Input
                                data-testid={UserMgmtTestIds.AliasAddUserFormField}
                                value={addUserFormValues.alias}
                                onChange={(event) => {
                                    handleAddUserFormValuesChange({
                                        alias: event.detail.value,
                                    });
                                }}
                            />
                        </FormField>
                        <ProgramSelect
                            formValues={addUserFormValues}
                            handleFormValueChange={handleAddUserFormValuesChange}
                            errors={{}}
                            multipe={true}
                        />
                        <FormField label="Role">
                            <Select
                                data-testid={UserMgmtTestIds.RolesAddUserFormField}
                                onChange={({ detail }) => {
                                    handleAddUserFormValuesChange({
                                        assessmentRole: detail.selectedOption,
                                    });
                                }}
                                options={assessmentRolesOptions}
                                selectedOption={addUserFormValues.assessmentRole}
                                placeholder="Select role"
                            />
                        </FormField>
                    </SpaceBetween>
                </>
            </Form>
        </Modal>
    );
};

export default AddUserModal;
