import React, { useEffect, useMemo, useState } from 'react';
import { Alert, Box, Button, Header, SpaceBetween, Table } from '@amzn/awsui-components-react';
import { AssessmentInput, AssessmentQuestion, AssessmentUpdateInput } from '../../graphql';
import SelectQuestionCheckbox, {
    SelectQuestionCheckboxProps,
} from '../common/formFields/SelectQuestionCheckbox';
import { useQuestions } from '../../hooks/useQuestion';

export interface AssessmentQuestionListProps {
    formValues: Partial<AssessmentInput>;
    learningObjectiveId: string;
    handleFormValueChange: (formUpdates: Partial<AssessmentUpdateInput>) => void;
    handleShowAddQuestionsModal: (learningObjectiveId: string) => void;
    errors?: any;
}
const AssessmentQuestionList = ({
    formValues,
    learningObjectiveId,
    handleShowAddQuestionsModal,
    handleFormValueChange,
    errors,
}: AssessmentQuestionListProps) => {
    const [formValueSelectedQuestions, setFormValueSelectedQuestions] = useState(
        new Set<AssessmentQuestion>(),
    );

    const { questions, learningObjectiveDict, getQuestions, handleGetLearningObjectives } =
        useQuestions();

    useEffect(() => {
        if (formValues.questions!.length > 0) {
            const questionsByLearningObjective = formValues.questions!.filter(
                (question) => question.learningObjectives![0] === learningObjectiveId,
            );
            if (questionsByLearningObjective.length > 0) {
                getQuestions({
                    id: questionsByLearningObjective.map((question) => question.id),
                });
            }
        } else {
            handleGetLearningObjectives(
                [],
                formValues.learningObjectives!.map((lo) => lo.id),
            );
        }
    }, [formValues]);

    const handleFormValueSelectedQuestionChange = (selectedQuestions: Set<AssessmentQuestion>) =>
        setFormValueSelectedQuestions(new Set(Array.from(selectedQuestions)));

    const handleSelectedFormValueQuestions = () => {
        const formValueQuestions = formValues.questions!;
        const questionsToDelete = Array.from(formValueSelectedQuestions!);
        const questions = formValueQuestions.filter(
            (formValueQuestion) =>
                !questionsToDelete.some((toDelete) => formValueQuestion.id === toDelete.id),
        );
        handleFormValueChange({ questions });
        setFormValueSelectedQuestions!(new Set());
    };

    const columnDefinitions = useMemo(() => {
        return [
            {
                id: 'selectQuestion',
                header: '',
                cell: (item: AssessmentQuestion) => {
                    const selectQuestionCheckboxProps: SelectQuestionCheckboxProps = {
                        item,
                        selectedQuestions: formValueSelectedQuestions,
                        handleSelectedQuestionChange: handleFormValueSelectedQuestionChange,
                    };

                    return <SelectQuestionCheckbox {...selectQuestionCheckboxProps} />;
                },
            },
            {
                id: 'questionText',
                header: 'Question Text',
                cell: (item: AssessmentQuestion) => item.questionText,
                isRowHeader: true,
            },
            {
                id: 'status',
                header: 'Status',
                cell: (item: AssessmentQuestion) => item.status,
                isRowHeader: true,
            },
            {
                id: 'type',
                header: 'Type',
                cell: (item: AssessmentQuestion) => item.type,
                isRowHeader: true,
            },
            {
                id: 'programs',
                header: 'Programs',
                cell: (item: AssessmentQuestion) => item.programs?.join(', '),
                isRowHeader: true,
            },
            {
                id: 'difficulty',
                header: 'Difficulty',
                cell: (item: AssessmentQuestion) => item.difficulty,
                isRowHeader: true,
            },
            {
                id: 'scoringMethod',
                header: 'Scoring Method',
                cell: (item: AssessmentQuestion) => item.scoringMethod,
                isRowHeader: true,
            },
        ];
    }, [learningObjectiveDict]);

    return (
        <>
            <Table
                header={
                    <SpaceBetween size="s" direction="vertical">
                        {errors && errors.questions && (
                            <Alert statusIconAriaLabel="Error" type="error">
                                {errors.questions}
                            </Alert>
                        )}
                        <Header
                            className="add-question-h3"
                            variant="h3"
                            actions={
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Button
                                        disabled={!formValueSelectedQuestions?.size}
                                        onClick={handleSelectedFormValueQuestions}
                                    >
                                        Delete
                                    </Button>
                                    <Button
                                        onClick={() =>
                                            handleShowAddQuestionsModal(learningObjectiveId)
                                        }
                                    >
                                        Add questions
                                    </Button>
                                </SpaceBetween>
                            }
                        >
                            {learningObjectiveDict
                                ? learningObjectiveDict[
                                      learningObjectiveId as keyof typeof learningObjectiveDict
                                  ].name
                                : ''}
                        </Header>
                    </SpaceBetween>
                }
                items={questions}
                columnDefinitions={columnDefinitions}
                enableKeyboardNavigation
                empty={
                    <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
                        This assessment has no questions.
                    </Box>
                }
                variant="embedded"
            />
        </>
    );
};

export default AssessmentQuestionList;
