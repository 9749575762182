import { FormField, Textarea } from '@amzn/awsui-components-react';
import React from 'react';
import { MetadataMutationVariables } from '../../../common/constants/metadataObject';

export interface MetadataObjectNameTextAreaProps {
    formValues: Partial<MetadataMutationVariables>;
    handleFormValueChange: (values: any) => void;
    errors?: any;
    label: string;
    keyName: string;
}

const MetadataObjectNameTextArea = ({
    formValues,
    handleFormValueChange,
    errors = {
        questionText: undefined,
    },
    label,
    keyName,
}: MetadataObjectNameTextAreaProps) => {
    return (
        <FormField label={label} errorText={errors[keyName] ?? null}>
            <Textarea
                onChange={({ detail }) =>
                    handleFormValueChange({ [keyName.toLocaleLowerCase()]: detail.value })
                }
                value={formValues.name!}
                placeholder={`Enter ${label} text`}
            />
        </FormField>
    );
};

export default MetadataObjectNameTextArea;
