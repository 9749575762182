import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { decodeToken, refreshToken, login as _login, logout as _logout } from './endpoints';
import { AuthContext } from '../../hooks/useAuth/AuthContext';

const TOKEN_REFRESH_TIME_BUFFER_BEFORE_EXPIRY = 20 * 1000;

const getTimeToExpire = (tokenExpiration: number) =>
    tokenExpiration * 1000 - Date.now() - TOKEN_REFRESH_TIME_BUFFER_BEFORE_EXPIRY;

export const AuthProvider = ({ children }: { children: ReactNode }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthed, setIsAuthed] = useState(false);
    const [tokenExpiration, setTokenExpiration] = useState<number | null>(null);

    const login = useCallback(_login, []);
    const logout = useCallback(_logout, []);

    // Determine login state by checking refresh token upon initiation of provider
    useEffect(() => {
        (async () => {
            try {
                await refreshToken();
                const { tokenExpiration } = await decodeToken();
                setTokenExpiration(tokenExpiration);
                setIsLoading(false);
                setIsAuthed(true);
            } catch (e) {
                setIsLoading(false);
                setIsAuthed(false);
            }
        })();
    }, [refreshToken, decodeToken]);

    // handle refreshing of token

    useEffect(() => {
        if (tokenExpiration !== null) {
            const timeoutId = setTimeout(async () => {
                try {
                    await refreshToken();
                    const { tokenExpiration } = await decodeToken();
                    setTokenExpiration(tokenExpiration);
                } catch (e) {
                    setIsAuthed(false);
                }
            }, getTimeToExpire(tokenExpiration));

            return () => clearTimeout(timeoutId);
        }
    }, [tokenExpiration]);

    return (
        <AuthContext.Provider value={{ login, logout, isLoading, isAuthed }}>
            {children}
        </AuthContext.Provider>
    );
};
