import { useState } from 'react';
import {
    AssessmentMetadataObject,
    AssessmentQuestion,
    useGetAssessmentQuestionsLazyQuery,
    useGetLearningObjectivesLazyQuery,
} from '../graphql';
import { PropertyFilterProps } from '@amzn/awsui-components-react';
import { Dictionary } from '../interfaces/dictionary';

export interface GetQuestionOptions {
    exludeIds: string[];
}

export interface GetLearningObjectiveOptions {
    filterVariables: any;
}

export const useQuestions = () => {
    const [questions, setQuestions] = useState<AssessmentQuestion[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [pagesCount, setPagesCount] = useState(0);
    const [currentCount, setCurrentCount] = useState(0);
    const [currentPageIndex, setCurrentPageIndex] = useState(1);
    const [_, setFrom] = useState(0);

    const [learningObjectiveDict, setLearningObjectiveDict] =
        useState<Dictionary<AssessmentMetadataObject>>();
    const [getLearningObjectives] = useGetLearningObjectivesLazyQuery({
        fetchPolicy: 'network-only',
    });
    const [getFilteredQuestions, { fetchMore }] = useGetAssessmentQuestionsLazyQuery({
        fetchPolicy: 'network-only',
    });

    const processTokens = (filters?: any) => {
        if (!filters || filters.propertyFilters.tokens.length === 0) {
            return [];
        }

        return filters.propertyFilters.tokens.map((token: PropertyFilterProps.FilteringOption) => {
            if (token.propertyKey === 'difficulty') {
                return { difficulty: [Number(token.value)] };
            }
            if (token.propertyKey === 'learningObjectives') {
                return { learningObjectives: [token.value] };
            }
            return { [token.propertyKey]: [token.value] };
        });
    };

    const buildSearchQuery = (tokens: any[]) => {
        return tokens.reduce((acc: any, token: any) => {
            const key = Object.keys(token)[0];
            const value = token[key][0];
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(value);
            return acc;
        }, {});
    };

    const handleGetLearningObjectives = async (
        questions: AssessmentQuestion[],
        additionalLearningObjectiveIds: string[] = [],
        learningObjectiveFilterOptions?: GetLearningObjectiveOptions,
    ) => {
        let learningObjectives: string[] = additionalLearningObjectiveIds;
        questions.forEach((question) => {
            question.learningObjectives?.forEach((lo) => {
                learningObjectives.push(lo);
            });
        });

        let fetchVariables = { learningObjectives, size: 1000 };
        if (learningObjectiveFilterOptions?.filterVariables) {
            fetchVariables = {
                ...fetchVariables,
                ...learningObjectiveFilterOptions.filterVariables,
                size: 1000,
            };
        }
        const { data } = await getLearningObjectives({
            variables: {
                ...fetchVariables,
            },
        });

        let newLearningObjectiveDict = {};
        data!.assessmentLearningObjectives.metadataObjects.forEach((objective) => {
            newLearningObjectiveDict = {
                ...newLearningObjectiveDict,
                [objective.id]: objective,
            };
        });

        const questionsWithLO = questions.map((question) => ({
            ...question,
            learningObjectives: question.learningObjectives?.map(
                (lo) => newLearningObjectiveDict[lo as keyof typeof newLearningObjectiveDict],
            ),
        }));

        setQuestions(questionsWithLO);
        setLearningObjectiveDict(newLearningObjectiveDict);
        setIsLoading(false);
    };

    const getQuestionsByPropertyFilter = async (filters?: any) => {
        const tokens = processTokens(filters);
        const searchQuery = buildSearchQuery(tokens);
        await getQuestions(searchQuery);
    };

    const getQuestions = async (searchQuery?: any, questionFilterOptions?: GetQuestionOptions) => {
        setIsLoading(true);

        const { data } = await getFilteredQuestions({
            variables: {
                ...searchQuery,
            },
        });

        const questionsList = data?.assessmentQuestions?.questions!;
        if (questionsList) {
            let totalCount = data.assessmentQuestions?.totalCount!;
            let totalPagesCount = Math.ceil(totalCount / 100); // Currently size is a fixed count
            let sortedQuestions = [...questionsList].sort((a, b) =>
                a.modifiedTimestamp! < b.modifiedTimestamp! ? 1 : -1,
            );

            if (questionFilterOptions?.exludeIds) {
                totalCount = sortedQuestions.length! - questionFilterOptions.exludeIds.length;
                totalPagesCount = Math.ceil(totalCount / 100); // Currently size is a fixed count
                sortedQuestions = sortedQuestions.filter(
                    (question) => !questionFilterOptions.exludeIds.includes(question.id),
                );
            }

            if (searchQuery && searchQuery.learningObjectives) {
                await handleGetLearningObjectives(sortedQuestions, searchQuery.learningObjectives);
            } else {
                await handleGetLearningObjectives(sortedQuestions);
            }
            setPagesCount(totalPagesCount);
            setCurrentCount(totalCount);
        }
    };

    const handlePaginationChange = async (event: any) => {
        setIsLoading(true);
        const { currentPageIndex } = event.detail;

        const updatedPageIndex = currentPageIndex;
        const from = (updatedPageIndex - 1) * 100;

        setCurrentPageIndex(updatedPageIndex);
        setFrom(from);
        const questionsData = await fetchMore({
            variables: {
                from,
                size: 100,
            },
        });
        const questions = questionsData.data.assessmentQuestions?.questions!;
        if (questions) {
            const totalCount = questionsData.data.assessmentQuestions?.totalCount!;
            const totalPagesCount = Math.ceil(totalCount / 100);
            setPagesCount(totalPagesCount);
            setCurrentCount(totalCount);
            const sortedQuestions = [...questions].sort((a, b) =>
                a.modifiedTimestamp! < b.modifiedTimestamp! ? 1 : -1,
            );
            handleGetLearningObjectives(sortedQuestions);
        }
    };

    return {
        questions,
        learningObjectiveDict,
        getQuestions,
        currentCount,
        handlePaginationChange,
        currentPageIndex,
        pagesCount,
        isLoading,
        handleGetLearningObjectives,
        getQuestionsByPropertyFilter,
    };
};
