export const FILE_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const FILE_EXTENSION = '.xlsx';

export const EXCEL_ASSESSMENT_QUESTIONS = [
    { header: 'Id', key: 'id' },
    { header: 'Question type', key: 'type', width: 20 },
    { header: 'Learning objective', key: 'learningObjectives', width: 50 },
    { header: 'Question', key: 'questionText', width: 50 },
    { header: 'Correct answer', key: 'correctAnswer', width: 50 },
    { header: 'Feedback', key: 'explanation', width: 50 },
    { header: 'Distractor', key: 'distractor-0', width: 50 },
    { header: 'Explanation', key: 'explanation-0', width: 50 },
    { header: 'Distractor', key: 'distractor-1', width: 50 },
    { header: 'Explanation', key: 'explanation-1', width: 50 },
    { header: 'Distractor', key: 'distractor-2', width: 50 },
    { header: 'Explanation', key: 'explanation-2', width: 50 },
    { header: 'Distractor', key: 'distractor-3', width: 50 },
    { header: 'Explanation', key: 'explanation-3', width: 50 },
    { header: 'Status', key: 'status', width: 10 },
    { header: 'Difficulty', key: 'difficulty', width: 5 },
];
