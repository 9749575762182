import { QuestionStatus, QuestionType } from '../../common/constants/questions';
import { getAnswerChoiceRequirementDescription } from '../../common/utils';
import { NotificationsContext } from '../../context/NotificationsProvider';
import { AssessmentAnswer } from '../../graphql';

export const isQuestionAnswersValid = (
    questionType: QuestionType | undefined,
    answers: AssessmentAnswer[],
    questionStatus: QuestionStatus,
    addNotification: (notification: NotificationsContext) => void,
): Boolean => {
    let valid = true;
    // Multiple choice question has more than one correct answer
    const numCorrectAnswers = answers.filter((answer) => answer.isCorrect).length;
    const numIncorrectAnswers = answers.length - numCorrectAnswers;
    if (questionType === QuestionType.MULTIPLE_CHOICE && questionStatus !== QuestionStatus.DRAFT) {
        const isValidNumberOfCorrectAnswers = numCorrectAnswers === 1;
        const isValidNumberOfIncorrectAnswers = numIncorrectAnswers === 3;
        if (!isValidNumberOfCorrectAnswers || !isValidNumberOfIncorrectAnswers) {
            valid = false;
            addNotification({
                id: `create-question-${Date.now()}-multiple-correct-answers`,
                type: 'error',
                content: getAnswerChoiceRequirementDescription(questionType),
            });
        }
    }

    // Multi-select question has invalid number of correct/incorrect answer choices
    if (questionType === QuestionType.MULTI_SELECT && questionStatus !== QuestionStatus.DRAFT) {
        const isValidNumberOfCorrectAnswers = numCorrectAnswers === 2 || numCorrectAnswers === 3;
        const isValidNumberOfIncorrectAnswers = numIncorrectAnswers === 3;
        if (!isValidNumberOfCorrectAnswers || !isValidNumberOfIncorrectAnswers) {
            valid = false;
            addNotification({
                id: `create-question-${Date.now()}-multiple-correct-answers`,
                type: 'error',
                content: getAnswerChoiceRequirementDescription(questionType),
            });
        }
    }
    return valid;
};
