import React, { useEffect, useState } from 'react';
import { Wizard } from '@amzn/awsui-components-react';
import { useDispatch } from 'react-redux';
import { AssessmentInput, useCreateAssessmentMutation } from '../../../graphql';
import useFormValidation from '../../../hooks/useFormValidation';
import { setBreadcrumbs, setContentType } from '../../../reducers/navigationReducer';
import { ASSESSMENT_CREATE_ROUTE, ASSESSMENT_LIST_ROUTE } from '../../../router/router';
import WizardAssessmentDetails, {
    WizardAssessmentDetailsProps,
} from './step1/WizardAssessmentDetails';
import WizardAssessmentAddQuestions, {
    WizardAssessmentAddQuestionsProps,
} from './step2/WizardAssessmentAddQuestions';
import WizardAssessmentSettings, {
    WizardAssessmentSettingsProps,
} from './step3/WizardAssessmentSettings';
import WizardReviewAndCreate, { WizardReviewAndCreateProps } from './step4/WizardReviewAndCreate';
import { initialAssessmentFormValues } from '../../../common/constants/assessments';
import {
    ASSESSMENT_DETAIL_VALIDATION_FIELDS,
    learningObjectiveAttributeValidationConfig,
} from '../../../common/constants/validations';
import {
    AssessmentLearningObjectiveAttributeEditor,
    AssessmentLearningObjectiveAttributeEditorItem,
    AssessmentLearningObjectiveAttributeEditorItemProps,
} from '../../../components';
import { useNotifications } from '../../../context/NotificationsProvider';
import { useNavigate } from 'react-router-dom';
import { waitFor } from '../../../utils/appUtils';
import { useForm } from 'react-hook-form';
import { AssessmentSettingsFieldNames } from '../../../components';

const AssessmentWizardCreate = () => {
    const dispatch = useDispatch();
    const { addNotification } = useNotifications();
    const navigate = useNavigate();

    const [formValues, setFormValues] = useState<Partial<AssessmentInput>>(
        initialAssessmentFormValues,
    );
    const [currentStep, setCurrentStep] = useState(0);
    const {
        isInvalid,
        isControlArrayInvalid,
        validateForm,
        errors,
        setErrors,
        controlArrayErrors,
        validateFormControlArray,
    } = useFormValidation<Partial<AssessmentInput>>();
    const [activeStepIndex, setActiveStepIndex] = useState(0);
    const [
        assessmentLearningObjectiveAttributeEditorItems,
        setAssessmentLearningObjectiveAttributeEditorItems,
    ] = useState<AssessmentLearningObjectiveAttributeEditorItem[]>([{ id: '', numQuestions: '' }]);

    const runInputValidations = (stepReason?: string) => {
        let formValidations = {
            isInvalidForm: false,
            isInvalidFormControlArray: false,
        };
        switch (currentStep) {
            // Step 1 Assement details
            case 0:
                formValidations.isInvalidForm = validateForm(formValues!, {
                    required: ASSESSMENT_DETAIL_VALIDATION_FIELDS.REQUIRED,
                });
                formValidations.isInvalidFormControlArray = validateFormControlArray(
                    { assessmentLearningObjectiveAttributeEditorItems },
                    learningObjectiveAttributeValidationConfig,
                );
                break;
            // Step 2 Add questions to assessment
            case 1:
                if (stepReason === 'next') {
                    // TODO: Support multiple learning objectives
                    if (
                        formValues.questions!.length <
                        formValues.learningObjectives![0].numQuestions
                    ) {
                        formValidations.isInvalidForm = true;
                        setErrors({
                            questions: `This learning objective currently requires ${
                                formValues.learningObjectives![0].numQuestions
                            } questions. To change the number of questions, edit the learning objective in step one of the creation wizard.`,
                        });
                    }
                }
                break;
            default:
                formValidations.isInvalidForm = validateForm(formValues!, {
                    required: ASSESSMENT_DETAIL_VALIDATION_FIELDS.REQUIRED,
                });
                formValidations.isInvalidFormControlArray = validateFormControlArray(
                    { assessmentLearningObjectiveAttributeEditorItems },
                    learningObjectiveAttributeValidationConfig,
                );
                return formValidations;
        }
        return formValidations;
    };

    const [createAssessment, { loading }] = useCreateAssessmentMutation();

    const form = useForm<Partial<AssessmentInput>>({
        mode: 'onChange',
        shouldFocusError: true,
        defaultValues: formValues,
    });

    const watchWaitTime = form.watch(AssessmentSettingsFieldNames.WAIT_TIME);
    const watchTimeLimit = form.watch(AssessmentSettingsFieldNames.TIME_LIMIT);

    useEffect(() => {
        dispatch(
            setBreadcrumbs([
                {
                    text: ASSESSMENT_LIST_ROUTE.title,
                    href: ASSESSMENT_LIST_ROUTE.path,
                },
                {
                    text: ASSESSMENT_CREATE_ROUTE.title,
                    href: ASSESSMENT_CREATE_ROUTE.path,
                },
            ]),
        );
        dispatch(setContentType('wizard'));
    }, [dispatch]);

    useEffect(() => {
        if (isInvalid || isControlArrayInvalid) {
            runInputValidations();
        }
    }, [formValues, isInvalid, validateForm, validateFormControlArray, isControlArrayInvalid]);

    const handleCreateAssessment = async () => {
        try {
            const {
                title,
                status,
                passingScore,
                maxAttempts,
                waitTime,
                timeLimit,
                displaySetting,
                programs,
                questions,
                learningObjectives,
                completionMessages,
            } = formValues;
            const { data } = await createAssessment({
                variables: {
                    title: title!,
                    status: status!,
                    passingScore: passingScore!,
                    maxAttempts: maxAttempts!,
                    waitTime: waitTime!,
                    timeLimit: timeLimit!,
                    displaySetting: displaySetting!,
                    programs: programs!,
                    questions: questions!,
                    learningObjectives: learningObjectives!,
                    completionMessages: completionMessages!,
                },
            });
            addNotification({
                id: `create-question-${Date.now()}`,
                ...(data?.createAssessment
                    ? {
                          type: 'success',
                          content: 'Assessment created successfully.',
                      }
                    : {
                          type: 'error',
                          content: 'There was an error creating the assessment.',
                      }),
            });
            waitFor(3000);
            navigate(ASSESSMENT_LIST_ROUTE.path);
        } catch (error) {}
    };

    const handleFormValueChange = (formUpdates: Partial<AssessmentInput>) => {
        setFormValues({ ...formValues, ...formUpdates });
    };

    const wizardReviewAndCreateProps: WizardReviewAndCreateProps = {
        setActiveStepIndex,
        formValues,
    };

    const wizardAssessmentDetailsProps: WizardAssessmentDetailsProps = {
        formValues,
        handleFormValueChange,
        errors,
        controlArrayErrors,
        assessmentLearningObjectiveAttributeEditorItems,
        setAssessmentLearningObjectiveAttributeEditorItems,
    };

    const wizardAssessmentAddQuestionsProps: WizardAssessmentAddQuestionsProps = {
        formValues,
        handleFormValueChange,
        errors,
    };

    const wizardAssessmentSettingsProps: WizardAssessmentSettingsProps = {
        formValues,
        handleFormValueChange,
        form,
    };

    return (
        <form onSubmit={(e) => e.preventDefault()} role="form">
            <Wizard
                i18nStrings={{
                    stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
                    collapsedStepsLabel: (stepNumber, stepsCount) =>
                        `Step ${stepNumber} of ${stepsCount}`,
                    // skipToButtonLabel: () => 'Save as draft', // TODO Saving as draft not currently supported
                    navigationAriaLabel: 'Steps',
                    cancelButton: 'Cancel',
                    previousButton: 'Previous',
                    nextButton: 'Next',
                    submitButton: 'Create assessment',
                    optional: 'optional',
                }}
                onNavigate={({ detail }) => {
                    const { isInvalidForm, isInvalidFormControlArray } = runInputValidations(
                        detail.reason,
                    );

                    if (isInvalidForm || isInvalidFormControlArray) {
                        return;
                    }
                    setCurrentStep(detail.requestedStepIndex);
                    setActiveStepIndex(detail.requestedStepIndex);
                }}
                onSubmit={handleCreateAssessment}
                activeStepIndex={activeStepIndex}
                steps={[
                    {
                        title: 'Assessment details',
                        content: <WizardAssessmentDetails {...wizardAssessmentDetailsProps} />,
                    },
                    {
                        title: 'Add questions',
                        content: (
                            <WizardAssessmentAddQuestions {...wizardAssessmentAddQuestionsProps} />
                        ),
                    },
                    {
                        title: 'Assessment settings',
                        content: <WizardAssessmentSettings {...wizardAssessmentSettingsProps} />,
                    },
                    {
                        title: 'Review and create',
                        content: <WizardReviewAndCreate {...wizardReviewAndCreateProps} />,
                    },
                ]}
            />{' '}
        </form>
    );
};

export default AssessmentWizardCreate;
