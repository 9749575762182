import React, { useEffect, useState } from 'react';
import { SideNavigation, SideNavigationProps } from '@amzn/awsui-components-react';
import { useNavigate } from 'react-router-dom';
import {
    ASSESSMENT_LIST_ROUTE,
    DASHBOARD_ROUTE,
    LEARNING_OBJECTIVE_LIST_ROUTE,
    QUESTIONS_LIST_ROUTE,
} from '../../router/router';

const NavigationSidebar = () => {
    const navigate = useNavigate();
    const [items, setItems] = useState<SideNavigationProps.Item[]>([]);

    useEffect(() => {
        setItems([
            {
                type: 'section',
                text: 'Assessment Manager',
                items: [
                    {
                        type: 'link',
                        text: DASHBOARD_ROUTE.title,
                        href: DASHBOARD_ROUTE.path,
                    },
                    {
                        type: 'link',
                        text: ASSESSMENT_LIST_ROUTE.title,
                        href: ASSESSMENT_LIST_ROUTE.path,
                    },
                    {
                        type: 'link',
                        text: QUESTIONS_LIST_ROUTE.title,
                        href: QUESTIONS_LIST_ROUTE.path,
                    },
                    {
                        type: 'link',
                        text: LEARNING_OBJECTIVE_LIST_ROUTE.title,
                        href: LEARNING_OBJECTIVE_LIST_ROUTE.path,
                    },
                ],
            },
        ]);
    }, []);

    return (
        <SideNavigation
            items={items}
            onFollow={(e) => {
                e.preventDefault();
                navigate(e.detail.href);
            }}
        />
    );
};

export default NavigationSidebar;
