import React from 'react';
import { Alert, Box } from '@amzn/awsui-components-react';
import { UserMgmtTestIds } from '../../common/dataTestIds/userMgmt';

type UserMgmtAccessDeniedProps = {
    message: string;
};

export const UserMgmtAccessDenied = (props: UserMgmtAccessDeniedProps) => {
    return (
        // TODO: Show "Dogs of Amazon" error components.
        <Box
            data-testid={UserMgmtTestIds.UserMgmtAccessDenied}
            textAlign="center"
            fontWeight="light"
            margin="xxl"
        >
            <Alert statusIconAriaLabel="Info" type="error">
                {props.message}
            </Alert>
        </Box>
    );
};
