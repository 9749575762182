import React, { useState } from 'react';
import { Container, Header, SpaceBetween } from '@amzn/awsui-components-react';
import { AssessmentInput, AssessmentQuestion } from '../../../../graphql';
import { AssessmentQuestionList, AssessmentQuestionListProps } from '../../../../components';
import AddQuestionModal, { AddQuestionModalProps } from '../../../../components/AddQuestionModal';

export interface WizardAssessmentAddQuestionsProps {
    handleFormValueChange: (formUpdates: Partial<AssessmentInput>) => void;
    formValues: AssessmentInput;
    errors: any;
}

const WizardAssessmentAddQuestions = ({
    formValues,
    handleFormValueChange,
    errors,
}: WizardAssessmentAddQuestionsProps) => {
    const [showAddQuestionsModal, setShowAddQuestionsModal] = useState(false);
    const [addQuestionsLearningObjective, setAddQuestionsLearningObjective] = useState('');
    const [selectedQuestions, setSelectedQuestions] = useState(new Set<AssessmentQuestion>());

    const handleShowAddQuestionsModal = (learningObjectiveId: string) => {
        setAddQuestionsLearningObjective(learningObjectiveId);
        setShowAddQuestionsModal(true);
    };

    const addQuestionModalProps: AddQuestionModalProps = {
        showAddQuestionsModal,
        setShowAddQuestionsModal,
        formValues,
        handleFormValueChange,
        selectedQuestions,
        setSelectedQuestions,
        learningObjective: addQuestionsLearningObjective,
    };

    return (
        <Container
            header={
                <Header
                    variant="h2"
                    description="Questions can be added for each learning objective below."
                >
                    Add questions
                </Header>
            }
        >
            <SpaceBetween direction="vertical" size="l">
                {formValues.learningObjectives?.map((objective, index) => {
                    const addQuestionListProps: AssessmentQuestionListProps = {
                        formValues,
                        learningObjectiveId: objective.id,
                        handleFormValueChange,
                        handleShowAddQuestionsModal,
                        errors,
                    };
                    return <AssessmentQuestionList key={index} {...addQuestionListProps} />;
                })}
            </SpaceBetween>
            {showAddQuestionsModal && <AddQuestionModal {...addQuestionModalProps} />}
        </Container>
    );
};

export default WizardAssessmentAddQuestions;
