import React, { useEffect, useState } from 'react';
import { Button, Container, Form, Header, SpaceBetween, Tabs } from '@amzn/awsui-components-react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
    LearningObjectiveAttributeEditor,
    MetadataObjectNameTextArea,
    MetadataObjectNameTextAreaProps,
    MetadataObjectStatusSelect,
    MetadataObjectStatusSelectProps,
    LearningObjectiveAttributeEditorProps,
    EmbeddedSelectableQuestionsTable,
    EmbeddedSelectableQuestionsTableProps,
    LearningObjectiveAttributeEditorItem,
} from '../../components';
import {
    AssessmentLanguage,
    AssessmentMetadataObjectAssociation,
    AssessmentQuestion,
    CreateQuestionBankMutationVariables,
    UpdateQuestionBankMutationVariables,
    useGetLearningObjectiveQuery,
    useGetQuestionBankQuery,
    useUpdateAssessmentQuestionMutation,
    useUpdateQuestionBankMutation,
} from '../../graphql';
import { useNotifications } from '../../context/NotificationsProvider';
import { ProgramSelect, ProgramSelectProps } from '../../components/common/formFields';
import { useDispatch } from 'react-redux';
import { setBreadcrumbs, setContentType } from '../../reducers/navigationReducer';
import { QUESTION_BANK_LIST_ROUTE } from '../../router/router';
import { useQuestionsContext } from '../../context/QuestionsProvider';
import { waitFor } from '../../utils/appUtils';
import { metadataInitialFormValues } from '../../common/constants/metadataObject';
import { QuestionBankIds } from '../../common/dataTestIds/questionBanks';

const WAIT_FOR_UPDATE_MS = 1000;

const LearningObjectiveEdit = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id: questionBankId = '', version } = useParams();
    const { state } = useLocation();
    const [selectedQuestions, setSelectedQuestions] = useState(new Set<AssessmentQuestion>());
    const [learningObjectiveAttributeEditorItems, setLearningObjectiveAttributeEditorItems] =
        useState<LearningObjectiveAttributeEditorItem[]>([{ id: '' }]);
    const [formValues, setFormValues] = useState<Partial<UpdateQuestionBankMutationVariables>>(
        state ?? metadataInitialFormValues,
    );
    const { addNotification } = useNotifications();
    const { getQuestions, questions } = useQuestionsContext();

    const [updateQuestionBank, { loading: updatingQuestionBank }] = useUpdateQuestionBankMutation();
    const [updateQuestion, { loading: updatingQuestion }] = useUpdateAssessmentQuestionMutation();

    const { data, loading } = useGetQuestionBankQuery({
        variables: {
            id: questionBankId,
            version: Number(version),
        },
        fetchPolicy: 'cache-and-network',
    });

    useEffect(() => {
        dispatch(
            setBreadcrumbs([
                {
                    text: QUESTION_BANK_LIST_ROUTE.title,
                    href: QUESTION_BANK_LIST_ROUTE.path,
                },
                {
                    text: QUESTION_BANK_LIST_ROUTE.title,
                    href: QUESTION_BANK_LIST_ROUTE.path,
                },
            ]),
        );
        dispatch(setContentType('form'));
    }, [dispatch]);

    useEffect(() => {
        if (data && !loading) {
            setFormValues({ ...data!.assessmentQuestionBank! });
        }
    }, [data, loading]);

    useEffect(() => {
        if (formValues) {
            // @ts-ignore
            const attributeEditorItems = formValues.associatedMetadata!.map((metadata) => ({
                id: metadata.id,
            }));
            setLearningObjectiveAttributeEditorItems(attributeEditorItems);
        }
    }, [formValues.associatedMetadata]);

    const handleFormValueChange = (formUpdates: Partial<CreateQuestionBankMutationVariables>) => {
        setFormValues({ ...formValues, ...formUpdates });
    };

    const handleUpdateQuestionBank = async () => {
        try {
            if (selectedQuestions.size > 0) {
                await handleAddQuestions();
                setSelectedQuestions(new Set());
            }
            const { data } = await updateQuestionBank({
                variables: {
                    id: questionBankId,
                    version: Number(version),
                    status: formValues.status!,
                    name: formValues.name!,
                    programs: formValues.programs!,
                    // @ts-ignore
                    associatedMetadata: formValues.associatedMetadata!.map((md) => ({
                        id: md.id,
                        metadataType: md.metadataType,
                    })),
                },
            });

            addNotification({
                id: `create-question-bank-${Date.now()}`,
                ...(data?.updateAssessmentQuestionBank
                    ? {
                          type: 'success',
                          content: 'Question bank updated successfully.',
                      }
                    : {
                          type: 'error',
                          content: 'There was an error updating the question bank.',
                      }),
            });
            if (data?.updateAssessmentQuestionBank) {
                const questionBank = data?.updateAssessmentQuestionBank;
                navigate(`/question-banks/${questionBank.id}/version/${questionBank.version}`);
            }
        } catch (error) {}
    };

    const handleSelectedQuestionChange = (selectedQuestions: Set<AssessmentQuestion>) =>
        setSelectedQuestions(new Set(Array.from(selectedQuestions)));

    const handleAddQuestions = async () => {
        const newQuestions = Array.from(selectedQuestions);

        const updatePromises = newQuestions.map(
            ({
                id,
                version,
                status,
                type,
                scoringMethod,
                answers,
                difficulty,
                questionText,
                programs,
                learningObjectives,
                questionBanks,
            }) => {
                const questionBankSet = new Set([...questionBanks!, questionBankId]);
                return updateQuestion({
                    variables: {
                        id: id!,
                        version: version!,
                        status: status!,
                        type: type!,
                        scoringMethod: scoringMethod!,
                        answers: answers?.map(({ answerText, isCorrect, explanation }) => ({
                            answerText: answerText!,
                            isCorrect: isCorrect!,
                            explanation: explanation ?? '',
                        }))!,
                        difficulty: difficulty!,
                        questionText: questionText!,
                        programs: programs!,
                        // @ts-expect-error This is a full metaobject at this point
                        learningObjectives: learningObjectives?.map((lo) => lo.id),
                        questionBanks: Array.from(questionBankSet)!,
                    },
                });
            },
        );

        await Promise.all(updatePromises);
        return waitFor(WAIT_FOR_UPDATE_MS);
    };

    const handleRemoveQuestions = async () => {
        try {
            const updatePromises = Array.from(selectedQuestions).map((question) => {
                // Filter out the current question bank ID from the question's questionBanks array
                const updatedQuestionBanks =
                    question.questionBanks?.filter((qbId) => qbId !== questionBankId) || [];

                return updateQuestion({
                    variables: {
                        id: question.id,
                        version: question.version,
                        status: question.status,
                        type: question.type,
                        scoringMethod: question.scoringMethod!,
                        answers: question.answers?.map(
                            ({ answerText, isCorrect, explanation }) => ({
                                answerText: answerText!,
                                isCorrect: isCorrect!,
                                explanation: explanation ?? '',
                            }),
                        ),
                        difficulty: question.difficulty!,
                        questionText: question.questionText!,
                        programs: question.programs!,
                        // @ts-expect-error This is a full metaobject at this point
                        learningObjectives: question.learningObjectives?.map((lo) => lo.id),
                        questionBanks: updatedQuestionBanks, // Updated questionBanks array without current question bank ID
                    },
                });
            });
            await Promise.all(updatePromises);
            await waitFor(WAIT_FOR_UPDATE_MS);
            await getQuestions({ questionBanks: [questionBankId] });
            setSelectedQuestions(new Set());
            addNotification({
                id: `edit-question-${Date.now()}`,
                type: 'success',
                content: 'Question was updated successfully.',
            });
        } catch (error) {
            addNotification({
                id: `error-edit-question-${Date.now()}`,
                type: 'error',
                content: 'There was an error adding the question to the question bank.',
            });
        }
    };

    const metadataObjectStatusProps: MetadataObjectStatusSelectProps = {
        formValues,
        handleFormValueChange,
    };

    const metadataObjectNameTextAreaProps: MetadataObjectNameTextAreaProps = {
        formValues,
        handleFormValueChange,
        keyName: 'name',
        label: 'Name',
    };

    const programSelectProps: ProgramSelectProps = {
        formValues: formValues,
        handleFormValueChange,
        errors: {},
    };

    const learningObjectiveAttributeEditorProps: LearningObjectiveAttributeEditorProps = {
        learningObjectiveAttributeEditorItems,
        setLearningObjectiveAttributeEditorItems,
        handleFormValueChange,
        selectedPrograms: formValues.programs as [],
        errors: [],
    };

    const listQuestionBankQuestions: EmbeddedSelectableQuestionsTableProps = {
        HeaderAction: (
            <Button
                disabled={selectedQuestions.size === 0 || updatingQuestion}
                onClick={handleRemoveQuestions}
            >
                Remove questions
            </Button>
        ),
        selectedQuestions,
        formValues,
        handleSelectedQuestionChange,
        questionFilterVariables: { questionBanks: [questionBankId] },
    };

    const addQuestionBankQuestions: EmbeddedSelectableQuestionsTableProps = {
        HeaderAction: (
            <Button
                disabled={selectedQuestions.size === 0 || updatingQuestion}
                onClick={handleUpdateQuestionBank}
            >
                Add questions
            </Button>
        ),
        selectedQuestions,
        handleSelectedQuestionChange,
        formValues,
        questionFilterVariables: {
            learningObjectives: formValues
                .associatedMetadata! // @ts-ignore
                .filter((metaValue: any) => metaValue.metadataType === 'LEARNING_OBJECTIVE')
                .map((lo: AssessmentMetadataObjectAssociation) => lo.id),
        },
        questionFilterOptions: { exludeIds: questions?.map((q) => q.id) },
    };

    return (
        <Form
            data-testid={QuestionBankIds.FormId}
            actions={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button
                        onClick={() => navigate('/question-banks')}
                        formAction="none"
                        variant="link"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleUpdateQuestionBank}
                        disabled={updatingQuestionBank}
                        variant="primary"
                    >
                        {updatingQuestionBank ? 'Saving' : 'Save'}
                    </Button>
                </SpaceBetween>
            }
            header={<Header variant="h1">Edit Question Bank</Header>}
        >
            <Container header={<Header variant="h2">Question bank details</Header>}>
                <SpaceBetween direction="vertical" size="l">
                    <MetadataObjectNameTextArea {...metadataObjectNameTextAreaProps} />
                    <MetadataObjectStatusSelect {...metadataObjectStatusProps} />
                    <ProgramSelect {...programSelectProps} />
                    {formValues.programs && formValues.programs.length > 0 && (
                        <LearningObjectiveAttributeEditor
                            {...learningObjectiveAttributeEditorProps}
                        />
                    )}
                    <Tabs
                        tabs={[
                            {
                                label: 'Questions',
                                id: 'questions-tab',
                                content: (
                                    <EmbeddedSelectableQuestionsTable
                                        {...listQuestionBankQuestions}
                                    />
                                ),
                            },
                            {
                                label: 'Add questions',
                                id: 'add-questions-tab',
                                content: (
                                    <EmbeddedSelectableQuestionsTable
                                        {...addQuestionBankQuestions}
                                    />
                                ),
                            },
                        ]}
                    />
                </SpaceBetween>
            </Container>
        </Form>
    );
};

export default LearningObjectiveEdit;
