import React, { Dispatch, SetStateAction } from 'react';
import { AssessmentInput } from '../../graphql';
import {
    Container,
    FormField,
    Header,
    Input,
    Select,
    SpaceBetween,
} from '@amzn/awsui-components-react';
import { AssessmentStatus } from '../../common/constants/assessments';
import { handleSelectedOption } from '../../utils/formUtils';
import LearningObjectiveAttributeEditor, {
    LearningObjectiveAttributeEditorItem,
} from '../learningObjectives/AttributeEditor';

export interface AssessmentFormProps {
    handleFormValueChange: (formUpdates: Partial<AssessmentInput>) => void;
    formValues: Partial<AssessmentInput>;
    mode: string;
    errors: any;
    controlArrayErrors: any;
    learningObjectiveAttributeEditorItems: LearningObjectiveAttributeEditorItem[];
    setLearningObjectiveAttributeEditorItems: Dispatch<
        SetStateAction<LearningObjectiveAttributeEditorItem[]>
    >;
}

const AssessmentForm = ({
    learningObjectiveAttributeEditorItems,
    setLearningObjectiveAttributeEditorItems,

    handleFormValueChange,
    formValues,
    mode,
    errors,
    controlArrayErrors,
}: AssessmentFormProps) => {
    const assessmentStatusOptionValues = Object.keys(AssessmentStatus).map(
        (status) => AssessmentStatus[status as keyof typeof AssessmentStatus],
    );

    const assessmentStatusOptions = assessmentStatusOptionValues.map((status) => ({
        value: status,
        label: status,
    }));

    const learningObjectiveAttributeEditorProps = {
        learningObjectiveAttributeEditorItems,
        setLearningObjectiveAttributeEditorItems,
        formValues,
        handleFormValueChange,
        errors,
        controlArrayErrors,
    };

    return (
        <Container header={<Header variant="h2">Assessment details</Header>}>
            <SpaceBetween direction="vertical" size="l">
                <FormField label="Question" errorText={errors.title ?? null}>
                    <Input
                        onChange={({ detail }) => handleFormValueChange({ title: detail.value })}
                        value={formValues.title!}
                        placeholder="Enter assessment title"
                    />
                </FormField>

                <FormField label="Status">
                    <Select
                        selectedOption={handleSelectedOption({
                            selectedOption: formValues.status ?? AssessmentStatus.DRAFT,
                            options: assessmentStatusOptions,
                        })}
                        onChange={(e) => {
                            handleFormValueChange({
                                status: e.detail.selectedOption.value! as AssessmentStatus,
                            });
                        }}
                        options={assessmentStatusOptions}
                    />
                </FormField>
                <LearningObjectiveAttributeEditor {...learningObjectiveAttributeEditorProps} />
            </SpaceBetween>
        </Container>
    );
};

export default AssessmentForm;
