import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    FormField,
    Modal,
    RadioGroup,
    SpaceBetween,
    Textarea,
} from '@amzn/awsui-components-react';
import { AssessmentAnswerInput } from '../../graphql';

export interface AnswerModalProps {
    isVisible: boolean;
    handleFormValueChange: (formUpdates: AssessmentAnswerInput) => void;
    formValue: AssessmentAnswerInput;
    handleAnswerSaveToQuestion: () => void;
    handleAnswerUpdateToQuestion: (answer: AssessmentAnswerInput) => void;
    handleCloseModal: () => void;
    answerModalFormType: string;
}
const AnswerModal = ({
    isVisible,
    handleFormValueChange,
    formValue,
    handleAnswerSaveToQuestion,
    handleAnswerUpdateToQuestion,
    handleCloseModal,
    answerModalFormType,
}: AnswerModalProps) => {
    const [errorText, setErrorText] = useState<string | null>(null);
    const handleSaveAnswer = () => {
        if (formValue.isCorrect && formValue.explanation === '') {
            return setErrorText('Explanation text is required for a correct answer.');
        }
        answerModalFormType === 'createAnswerForm'
            ? handleAnswerSaveToQuestion()
            : handleAnswerUpdateToQuestion(formValue);
    };

    useEffect(() => {
        if (formValue.isCorrect && formValue.explanation !== '') {
            setErrorText(null);
        }
    }, [formValue]);
    return (
        <Modal
            header="Create Answer"
            onDismiss={() => handleCloseModal()}
            size="large"
            visible={isVisible}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button onClick={handleCloseModal} variant="link">
                            Cancel
                        </Button>
                        <Button onClick={handleSaveAnswer} variant="primary">
                            Save
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <SpaceBetween direction="vertical" size="l">
                <FormField
                    label="Correct Answer"
                    description="Multiple choice questions can only have 1 correct answer."
                >
                    <RadioGroup
                        onChange={({ detail }) => {
                            handleFormValueChange({
                                ...formValue,
                                isCorrect: detail.value === 'true',
                            });
                        }}
                        value={`${formValue.isCorrect}`}
                        items={[
                            { value: 'true', label: 'Yes' },
                            { value: 'false', label: 'No' },
                        ]}
                    />
                </FormField>
                <FormField label="Answer Text">
                    <Textarea
                        onChange={({ detail }) =>
                            handleFormValueChange({ ...formValue, answerText: detail.value })
                        }
                        value={formValue.answerText!}
                        placeholder="Enter answer text"
                    />
                </FormField>
                <FormField label="Explanation Text" errorText={errorText ?? null}>
                    <Textarea
                        onChange={({ detail }) =>
                            handleFormValueChange({ ...formValue, explanation: detail.value })
                        }
                        value={formValue.explanation!}
                        placeholder="Enter explanation text"
                    />
                </FormField>
            </SpaceBetween>
        </Modal>
    );
};

export default AnswerModal;
