import { LearningObjectiveAttributeEditorItem } from '../../components/learningObjectives/AttributeEditor';
import {
    AssessmentInput,
    AssessmentQuestionInput,
    CreateLearningObjectiveMutationVariables,
} from '../../graphql';
import { ValidationType } from '../../hooks/useFormValidation';

type ValidationKeys = 'REQUIRED';

export const QUESTION_INPUT_VALIDATION_FIELDS: {
    [key in ValidationKeys]: Array<keyof Partial<AssessmentQuestionInput>>;
} = {
    REQUIRED: ['questionText', 'learningObjectives', 'programs'],
};

export const GENAI_PROMPT_VALIDATION_FIELDS: {
    [key in ValidationKeys]: Array<keyof Partial<AssessmentQuestionInput>>;
} = {
    REQUIRED: ['learningObjectives', 'programs'],
};

export const ASSESSMENT_DETAIL_VALIDATION_FIELDS: {
    [key in ValidationKeys]: Array<keyof Partial<AssessmentInput>>;
} = {
    REQUIRED: ['title'],
};

interface LearningObjectiveControlArrayFormValues {
    learningObjectiveAttributeEditorItems: LearningObjectiveAttributeEditorItem;
}

export const learningObjectiveAttributeValidationConfig: {
    [learningObjectiveControlArrayFormValuesKey in keyof LearningObjectiveControlArrayFormValues]: {
        [key in ValidationType]?: Array<
            keyof LearningObjectiveControlArrayFormValues[learningObjectiveControlArrayFormValuesKey]
        >;
    };
} = {
    learningObjectiveAttributeEditorItems: {
        required: ['id', 'numQuestions'],
    },
};

export const LEARNING_OBJECTIVE_VALIDATION_FIELDS: {
    [key in ValidationKeys]: Array<keyof Partial<CreateLearningObjectiveMutationVariables>>;
} = {
    REQUIRED: ['programs'],
};
