import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Header,
    Link,
    Pagination,
    SpaceBetween,
    Table,
} from '@amzn/awsui-components-react';

import { Dictionary } from '../../../interfaces/dictionary';
import {
    AssessmentUser,
    AuditLogOutput,
    AuditLogItem,
    useGetAssessmentQuestionAuditLogLazyQuery,
    useGetAssessmentUsersLazyQuery,
    useGetLearningObjectivesLazyQuery,
    useGetQuestionBanksLazyQuery,
    // TODO: enable when tags query is available
    //useGetTagsLazyQuery,
} from '../../../graphql/';
import { useParams } from 'react-router-dom';

const PROPERTIES_TO_IGNORE = [
    'modifiedBy',
    'modifiedTimestamp',
    'createdBy',
    'createdTimestamp',
    'recordType',
    'version',
    'localizedContent:\\w+:answers:(\\d+):id',
];
const PROPERTIES_TO_IGNORE_REGEX = new RegExp(`^${PROPERTIES_TO_IGNORE.join('|')}$`);
const PROPERTY_LABELS: Record<string, string> = {
    difficulty: 'Difficulty level',
    scoringMethod: 'Scoring method',
    type: 'Question type',
    status: 'Status',
};

const QUERY_PAGE_SIZE = 20;

const AuditLogList = () => {
    const { id = '' } = useParams();
    const [auditLogItems, setAuditLogItems] = useState<Record<string, any>[]>([]);
    const [usersInfoDict, setUsersInfoDict] = useState<Dictionary<AssessmentUser>>({});
    const [learningObjectiveDict, setLearningObjectiveDict] = useState<Dictionary<string>>({});
    const [questionBankDict, setQuestionBankDict] = useState<Dictionary<string>>({});
    const [tagDict, setTagDict] = useState<Dictionary<string>>({});
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>(1);
    const [paginationMarkers, setPaginationMarkers] = useState<(string | undefined)[]>([]);

    useEffect(() => {
        handleAuditLogQuery();
    }, [pageNumber]);

    // get audit log data
    const [getAssessmentQuestionAuditLog] = useGetAssessmentQuestionAuditLogLazyQuery();
    const handleAuditLogQuery = async () => {
        // get the pagination marker if page number > 1
        const marker = pageNumber > 1 ? paginationMarkers[pageNumber - 2] : undefined;
        // query question audit log items
        const { data } = await getAssessmentQuestionAuditLog({
            variables: {
                id: id,
                delta: true,
                size: QUERY_PAGE_SIZE,
                paginationMarker: marker,
            },
            fetchPolicy: 'cache-and-network',
        });
        if (data && data.assessmentQuestionAuditLog) {
            // map query items to data structure for Table component
            mapAuditLogItems(data.assessmentQuestionAuditLog.auditLog);
            // store pagination marker from query results for current page
            // i.e., this page's pagination marker lets us get the next page of results
            paginationMarkers[pageNumber - 1] = data.assessmentQuestionAuditLog.paginationMarker;
            setPaginationMarkers(paginationMarkers);
            // update the total number of pages for the Pagination component, as
            // one more than the current page number because we don't know the
            // total number of pages, or leave total pages as is if we know this
            // is the last page because the pagination marker is
            // empty/undefined/null
            if (paginationMarkers.at(-1)) {
                setPageCount(pageNumber + 1);
            }
        }
    };
    const mapAuditLogItems = (auditLog: Partial<AuditLogItem>[]) => {
        // map the audit log items to the data structure we need for the Table component
        const items: Record<string, any>[] = [];

        // keep track of user ids and metadata ids so we can retrieve the values
        const userIds = new Set<string>();
        const learningObjectiveIds = new Set<string>();
        const questionBankIds = new Set<string>();
        const tagIds = new Set<string>();

        // iterate and map query items
        for (const item of auditLog) {
            if (item.resourceModifiedBy) userIds.add(item.resourceModifiedBy);
            // create a Table component row for each delta change in the query record
            for (const delta of JSON.parse(item.delta || JSON.stringify([]))) {
                // concatenate the delta path, so it can be tested against regex patterns
                const deltaPath = delta.path.join(':');
                // some property deltas should be ignored for display
                if (PROPERTIES_TO_IGNORE_REGEX.test(deltaPath)) continue;
                // data structure for Table component
                items.push({
                    modifiedTimestamp: item.resourceModifiedTimestamp,
                    modifiedBy: item.resourceModifiedBy,
                    property: deltaPath,
                    oldValue: delta.oldValue,
                    newValue: delta.value,
                });
                // store learning objective ids
                if (deltaPath.match(/^learningObjectives:\d+$/)) {
                    if (delta.oldValue) learningObjectiveIds.add(delta.oldValue);
                    if (delta.newValue) learningObjectiveIds.add(delta.newValue);
                }
                // store question bank ids
                if (deltaPath.match(/^questionBanks:\d+$/)) {
                    if (delta.oldValue) questionBankIds.add(delta.oldValue);
                    if (delta.newValue) questionBankIds.add(delta.newValue);
                }
                // store tag ids
                if (deltaPath.match(/^tags:\d+$/)) {
                    if (delta.oldValue) tagIds.add(delta.oldValue);
                    if (delta.newValue) tagIds.add(delta.newValue);
                }
            }
        }
        setAuditLogItems(items);

        // query for user and metadata display values, from ids
        handleGetUserInfo(userIds);
        handleGetLearningObjectives(learningObjectiveIds);
        handleGetQuestionBanks(questionBankIds);
        // TODO: enable when tags query is available
        //handleGetTags(tagIds);
    };

    // get user map for audit log data
    const [getUsers] = useGetAssessmentUsersLazyQuery({
        fetchPolicy: 'cache-and-network',
    });
    const getUsersForIds = async (userIds: Set<string>): Promise<any> => {
        const { data } = await getUsers({
            variables: {
                id: Array.from(userIds),
            },
        });
        let userDict = {};
        const users = data?.assessmentUsers?.users;
        users?.forEach((u) => {
            userDict = {
                ...userDict,
                [u.id]: u,
            };
        });
        return userDict;
    };
    const handleGetUserInfo = async (userIds: Set<string>) => {
        const usersInfoDict = await getUsersForIds(userIds);
        setUsersInfoDict(usersInfoDict);
    };

    // get learning objective map for audit log data
    const [getLearningObjectives] = useGetLearningObjectivesLazyQuery({
        fetchPolicy: 'cache-and-network',
    });
    const handleGetLearningObjectives = async (ids: Set<string>) => {
        const { data, error } = await getLearningObjectives({
            variables: {
                learningObjectives: Array.from(ids),
            },
        });
        let learningObjectiveDict = {};
        data?.assessmentLearningObjectives.metadataObjects.forEach((item) => {
            learningObjectiveDict = {
                ...learningObjectiveDict,
                [item.id]: item.name,
            };
        });
        setLearningObjectiveDict(learningObjectiveDict);
    };

    // get question banks map for audit log data
    const [getQuestionBanks] = useGetQuestionBanksLazyQuery({
        fetchPolicy: 'cache-and-network',
    });
    const handleGetQuestionBanks = async (ids: Set<string>) => {
        const { data, error } = await getQuestionBanks({
            variables: {
                questionBanks: Array.from(ids),
            },
        });
        let questionBankDict = {};
        data?.assessmentQuestionBanks.metadataObjects.forEach((item) => {
            questionBankDict = {
                ...questionBankDict,
                [item.id]: item.name,
            };
        });
        setQuestionBankDict(questionBankDict);
    };

    // get tags map for audit log data
    // TODO: enable when tags query is available
    /*
    const [getTags] = useGetTagsLazyQuery({
        fetchPolicy: "cache-and-network",
    });
    const handleGetTags = async (ids: Set<string>) => {
        const { data, error } = await getTags({
            variables: {
                tags: Array.from(ids),
            },
        });
        let tagDict = {};
        data?.assessmentTags.metadataObjects.forEach((item) => {
            tagDict = {
                ...tagDict,
                [item.id]: item.name,
            };
        });
        setTagDict(tagDict);
    };
    */

    // get display value for item modified timestamp
    const getTimestampValue = (timestamp: number) => {
        // get date/time for user's locale
        const dateTime = new Date(timestamp).toLocaleString();

        // get short timezone name for user's locale
        const formatted = new Intl.DateTimeFormat('en-US', { timeZoneName: 'short' }).formatToParts(
            timestamp,
        );
        const timezonePart = formatted.find((part) => part.type === 'timeZoneName');
        const timezone = timezonePart ? timezonePart.value : '';

        return `${dateTime} ${timezone}`;
    };

    // get display value for item user id
    const getUserValue = (id: string) => {
        return usersInfoDict[id]?.name || usersInfoDict[id]?.email || id;
    };

    // get display label for property/field
    const getPropertyLabel = (property: string) => {
        // for properties with a simple mapping, return display name
        if (PROPERTY_LABELS[property]) return PROPERTY_LABELS[property];

        // for properties without a simple mapping, match nested arrays and
        // objects via regex, and construct a display name

        let match: any;
        let itemNumber: Number;

        match = property.match(/^programs:(\d+)$/);
        if (match) {
            itemNumber = Number(match[1]) + 1;
            return `Program ${itemNumber}`;
        }

        match = property.match(/^localizedContent:(\w+):questionText$/);
        if (match) {
            return `Question text (${match[1]})`;
        }

        match = property.match(/^localizedContent:(\w+):answers:(\d+):answerText$/);
        if (match) {
            itemNumber = Number(match[2]) + 1;
            return `Answer ${itemNumber} text (${match[1]})`;
        }

        match = property.match(/^localizedContent:(\w+):answers:(\d+):explanation$/);
        if (match) {
            itemNumber = Number(match[2]) + 1;
            return `Answer ${itemNumber} explanation text (${match[1]})`;
        }

        match = property.match(/^learningObjectives:(\d+)$/);
        if (match) {
            itemNumber = Number(match[1]) + 1;
            return `Learning objective ${itemNumber}`;
        }

        match = property.match(/^questionBanks:(\d+)$/);
        if (match) {
            itemNumber = Number(match[1]) + 1;
            return `Question bank ${itemNumber}`;
        }

        match = property.match(/^tags:(\d+)$/);
        if (match) {
            itemNumber = Number(match[1]) + 1;
            return `Tag ${itemNumber}`;
        }

        return property;
    };

    // get display value for a value, if applicable
    const getPropertyValue = (value: string) => {
        if (learningObjectiveDict[value]) {
            return learningObjectiveDict[value];
        }
        if (questionBankDict[value]) {
            return questionBankDict[value];
        }
        if (tagDict[value]) {
            return tagDict[value];
        }
        return value;
    };

    // handle all pagination changes, previous/next and page number selections
    const handlePageChange = (event: any) => {
        setPageNumber(event.detail.currentPageIndex);
    };

    return (
        <Table
            header={<Header description="Change history for this item">Audit log</Header>}
            loadingText="Loading audit log"
            enableKeyboardNavigation
            resizableColumns
            sortingDisabled
            stickyHeader
            wrapLines
            items={auditLogItems}
            empty={
                <Box
                    margin={{ vertical: 'xs' }}
                    textAlign="center"
                    fontWeight="bold"
                    color="inherit"
                >
                    No audit log
                </Box>
            }
            pagination={
                <Pagination
                    // keep pagination open ended unless we know it's the last page of results
                    openEnd={paginationMarkers.at(-1) !== null}
                    currentPageIndex={pageNumber}
                    pagesCount={pageCount}
                    onChange={handlePageChange}
                />
            }
            columnDefinitions={[
                {
                    id: 'modifiedTimestamp',
                    header: 'Change date',
                    cell: (item) => getTimestampValue(item.modifiedTimestamp),
                },
                {
                    id: 'modifiedBy',
                    header: 'Changed by',
                    cell: (item) => getUserValue(item.modifiedBy),
                },
                {
                    id: 'property',
                    header: 'Changed item',
                    cell: (item) => getPropertyLabel(item.property),
                },
                {
                    id: 'oldValue',
                    header: 'Old value',
                    cell: (item) => (
                        <Box color="text-status-inactive">{getPropertyValue(item.oldValue)}</Box>
                    ),
                },
                {
                    id: 'newValue',
                    header: 'New value',
                    cell: (item) => getPropertyValue(item.newValue),
                },
            ]}
        />
    );
};

export default AuditLogList;
