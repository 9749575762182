export enum QuestionIds {
    // Form field labels
    QuestionTypeLabel = 'question-type-label',
    QuestionStatusLabel = 'question-status-label',
    QuestionDifficultyLabel = 'question-difficulty-label',
    QuestionTextLabel = 'question-text-label',

    // Form field controls
    TypeSelect = 'question-type-select',
    StatusSelect = 'question-status-select',
    DifficultySelect = 'question-difficulty-select',
    TextArea = 'question-text-textarea',

    // Buttons
    AddQuestionModalCancelButton = 'question-modal-cancel-button',
    AddQuestionModalAddButton = 'question-modal-add-button',
    SaveButton = 'question-save-button',
    CancelButton = 'question-cancel-button',

    // Containers
    FormId = 'question-form',
    Modal = 'question-modal',
    Table = 'question-table',
    Tabs = 'question-tabs'
}
