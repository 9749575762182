import React, { useEffect } from 'react';
import Header from '@amzn/awsui-components-react/polaris/header';
import { Cards, ContentLayout, Link } from '@amzn/awsui-components-react';
import { useDispatch } from 'react-redux';
import { setBreadcrumbs, setContentType } from '../reducers/navigationReducer';
import {
    ASSESSMENT_LIST_ROUTE,
    DASHBOARD_ROUTE,
    LEARNING_OBJECTIVE_LIST_ROUTE,
    QUESTION_BANK_LIST_ROUTE,
    QUESTIONS_LIST_ROUTE,
} from '../router/router';

const Dashboard = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            setBreadcrumbs([
                {
                    text: DASHBOARD_ROUTE.title,
                    href: DASHBOARD_ROUTE.path,
                },
            ]),
        );
    }, [dispatch]);

    return (
        <ContentLayout header={<Header variant="h1">Dashboard</Header>}>
            <Cards
                ariaLabels={{
                    itemSelectionLabel: (e, item) => `select ${item.name}`,
                    selectionGroupLabel: 'Item selection',
                }}
                cardDefinition={{
                    header: (item) => (
                        <Link href={item.route} fontSize="heading-m">
                            {item.name}
                        </Link>
                    ),
                    sections: [
                        {
                            id: 'description',
                            content: (item) => item.description,
                        },
                    ],
                }}
                cardsPerRow={[{ cards: 1 }, { minWidth: 650, cards: 3 }]}
                items={[
                    {
                        name: LEARNING_OBJECTIVE_LIST_ROUTE.title,
                        description:
                            'Create learning objectives to establish content curriculum and standards that the assessment will measure.',
                        route: LEARNING_OBJECTIVE_LIST_ROUTE.path,
                    },
                    {
                        name: QUESTIONS_LIST_ROUTE.title,
                        description:
                            'Manage questions and answers for Learning Objectives and Assessments.',
                        route: QUESTIONS_LIST_ROUTE.path,
                    },
                    // {
                    //     name: QUESTION_BANK_LIST_ROUTE.title,
                    //     description:
                    //         'Build collections of questions that can be used to populated assessments.',
                    //     route: QUESTION_BANK_LIST_ROUTE.path,
                    // },
                    {
                        name: ASSESSMENT_LIST_ROUTE.title,
                        description:
                            'Create assessments to measure Learner understanding of Learning Objectives and evaluations for certifications.',
                        route: ASSESSMENT_LIST_ROUTE.path,
                    },
                ]}
                visibleSections={['description', 'type']}
            />
        </ContentLayout>
    );
};

export default Dashboard;
