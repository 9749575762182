import { useState } from 'react';
import {
    AssessmentMetadataObject,
    AssessmentQuestion,
    useGetAssessmentQuestionsLazyQuery,
    useGetLearningObjectivesLazyQuery,
} from '../graphql';
import { PropertyFilterProps } from '@amzn/awsui-components-react';
import { Dictionary } from '../interfaces/dictionary';
import { TABLE_DEFAULT_PAGE_SIZE } from '../common/constants/tablePreferences';
import { userTablePreferences } from '../pages/questions/questionsList/QuestionsList';

export interface GetQuestionOptions {
    exludeIds: string[];
}

export interface GetLearningObjectiveOptions {
    filterVariables: any;
}

export const useQuestions = () => {
    const [questions, setQuestions] = useState<AssessmentQuestion[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [pagesCount, setPagesCount] = useState(0);
    const [currentCount, setCurrentCount] = useState(0);
    const [currentPageIndex, setCurrentPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(
        userTablePreferences.pageSize || TABLE_DEFAULT_PAGE_SIZE,
    );
    const [_, setFrom] = useState(0);

    const [learningObjectiveDict, setLearningObjectiveDict] =
        useState<Dictionary<AssessmentMetadataObject>>();
    const [getLearningObjectives] = useGetLearningObjectivesLazyQuery({
        fetchPolicy: 'network-only',
    });
    const [getFilteredQuestions, { fetchMore }] = useGetAssessmentQuestionsLazyQuery({
        fetchPolicy: 'network-only',
    });

    const processTokens = (filters?: any) => {
        if (!filters || filters.propertyFilters.tokens.length === 0) {
            return [];
        }

        return filters.propertyFilters.tokens.map((token: PropertyFilterProps.FilteringOption) => {
            if (token.propertyKey === 'searchText') {
                return { searchText: token.value };
            }
            if (token.propertyKey === 'difficulty') {
                return { difficulty: [Number(token.value)] };
            }
            if (token.propertyKey === 'learningObjectives') {
                return { learningObjectives: [token.value] };
            }
            return { [token.propertyKey]: [token.value] };
        });
    };

    const buildSearchQuery = (tokens: any[]) => {
        return tokens.reduce((acc: any, token: any) => {
            const key = Object.keys(token)[0];
            if (Array.isArray(token[key])) {
                const value = token[key][0];
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(value);
            } else if (typeof token[key] === 'string') {
                if (!acc[key]) {
                    acc[key] = '';
                }
                acc[key] += token[key] + ' ';
            }
            return acc;
        }, {});
    };

    const handleGetLearningObjectives = async (
        questions: AssessmentQuestion[],
        additionalLearningObjectiveIds: string[] = [],
    ) => {
        let learningObjectives: string[] = additionalLearningObjectiveIds;
        questions.forEach((question) => {
            question.learningObjectives?.forEach((lo) => {
                learningObjectives.push(lo);
            });
        });

        const { data } = await getLearningObjectives({
            variables: { learningObjectives, size: 1000 },
        });

        let newLearningObjectiveDict = {};
        data!.assessmentLearningObjectives.metadataObjects.forEach((objective) => {
            newLearningObjectiveDict = {
                ...newLearningObjectiveDict,
                [objective.id]: objective,
            };
        });

        const questionsWithLO = questions.map((question) => ({
            ...question,
            learningObjectives: question.learningObjectives?.map(
                (lo) => newLearningObjectiveDict[lo as keyof typeof newLearningObjectiveDict],
            ),
        }));

        setQuestions(questionsWithLO);
        setLearningObjectiveDict(newLearningObjectiveDict);
        setIsLoading(false);
    };

    const getQuestionsByPropertyFilter = async (filters?: any) => {
        const tokens = processTokens(filters);
        const searchQuery = buildSearchQuery(tokens);
        await getQuestions(searchQuery);
    };

    const getQuestions = async (searchQuery?: any, questionFilterOptions?: GetQuestionOptions) => {
        setIsLoading(true);

        const { data } = await getFilteredQuestions({
            variables: {
                ...searchQuery,
                size: pageSize,
            },
        });

        const questionsList = data?.assessmentQuestions?.questions!;
        if (questionsList) {
            let totalCount = data.assessmentQuestions?.totalCount!;
            let totalPagesCount = Math.ceil(totalCount / pageSize);
            setCurrentCount(totalCount);
            setPagesCount(totalPagesCount);

            let sortedQuestions = [...questionsList].sort((a, b) =>
                a.modifiedTimestamp! < b.modifiedTimestamp! ? 1 : -1,
            );

            if (questionFilterOptions?.exludeIds) {
                totalCount = sortedQuestions.length! - questionFilterOptions.exludeIds.length;
                totalPagesCount = Math.ceil(totalCount / pageSize);
                sortedQuestions = sortedQuestions.filter(
                    (question) => !questionFilterOptions.exludeIds.includes(question.id),
                );
            }

            if (currentPageIndex > totalPagesCount) {
                // if the current page index > number of total pages, reset current index to page 1
                // this can happen when the results per page value is changed to
                // a larger number, e.g. user is on last page, say 100, with 10
                // results per page but changes to 100 results per page, so
                // there are now only 10 pages total but the user's page index
                // is 100.
                setCurrentPageIndex(1);
            }

            if (searchQuery && searchQuery.learningObjectives) {
                await handleGetLearningObjectives(sortedQuestions, searchQuery.learningObjectives);
            } else {
                await handleGetLearningObjectives(sortedQuestions);
            }
            setPagesCount(totalPagesCount);
            setCurrentCount(totalCount);
        }
    };

    const handlePaginationChange = async (event: any) => {
        setIsLoading(true);
        const { currentPageIndex } = event.detail;

        const updatedPageIndex = currentPageIndex;
        const from = (updatedPageIndex - 1) * pageSize;

        setCurrentPageIndex(updatedPageIndex);
        setFrom(from);
        const questionsData = await fetchMore({
            variables: {
                from,
                size: pageSize,
            },
        });
        const questions = questionsData.data.assessmentQuestions?.questions!;
        if (questions) {
            const totalCount = questionsData.data.assessmentQuestions?.totalCount!;
            const totalPagesCount = Math.ceil(totalCount / pageSize);
            setPagesCount(totalPagesCount);
            setCurrentCount(totalCount);
            const sortedQuestions = [...questions].sort((a, b) =>
                a.modifiedTimestamp! < b.modifiedTimestamp! ? 1 : -1,
            );
            handleGetLearningObjectives(sortedQuestions);
        }
    };

    return {
        questions,
        learningObjectiveDict,
        currentCount,
        currentPageIndex,
        pagesCount,
        isLoading,
        pageSize,
        setPageSize,
        getQuestions,
        handlePaginationChange,
        handleGetLearningObjectives,
        getQuestionsByPropertyFilter,
    };
};
