import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setBreadcrumbs, setContentType } from '../../reducers/navigationReducer';
import {
    LEARNING_OBJECTIVE_CREATE_ROUTE,
    LEARNING_OBJECTIVE_DETAIL_ROUTE,
    LEARNING_OBJECTIVE_LIST_ROUTE,
} from '../../router/router';
import {
    AssessmentLanguage,
    AssessmentMetadataObject,
    useGetLearningObjectiveQuery,
} from '../../graphql';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Button,
    ColumnLayout,
    Container,
    ContentLayout,
    Header,
    SpaceBetween,
} from '@amzn/awsui-components-react';
import { ValueWithLabel } from '../../components';

const LearningObjectiveDetail = () => {
    const dispatch = useDispatch();
    const { id = '', version } = useParams();
    const [learningObjective, setLearningObjective] = useState<AssessmentMetadataObject>();
    const navigate = useNavigate();

    const { data, loading } = useGetLearningObjectiveQuery({
        variables: {
            id,
            language: AssessmentLanguage.En,
            version: Number(version),
        },
        fetchPolicy: 'cache-and-network',
    });

    useEffect(() => {
        dispatch(
            setBreadcrumbs([
                {
                    text: LEARNING_OBJECTIVE_LIST_ROUTE.title,
                    href: LEARNING_OBJECTIVE_LIST_ROUTE.path,
                },
                {
                    text: LEARNING_OBJECTIVE_DETAIL_ROUTE.title,
                    href: LEARNING_OBJECTIVE_DETAIL_ROUTE.path,
                },
            ]),
        );
        dispatch(setContentType('content'));
    }, [dispatch]);

    useEffect(() => {
        if (!loading && data) {
            const learningObjective = data.assessmentLearningObjective!;
            setLearningObjective(learningObjective);
        }
    }, [loading, data]);

    return (
        <ContentLayout
            header={
                <Header
                    variant="h1"
                    actions={
                        <SpaceBetween size="s" direction="horizontal">
                            <Button
                                onClick={() => navigate(LEARNING_OBJECTIVE_CREATE_ROUTE.path)}
                                variant="primary"
                            >
                                Create learning objective
                            </Button>
                            <Button
                                key={`edit-learning-objective-button`}
                                data-testid="edit-learning-objective-button"
                                onClick={() =>
                                    navigate(
                                        `/learning-objectives/${learningObjective?.id}/version/${learningObjective?.version}/edit`,
                                        {
                                            state: { ...learningObjective },
                                        },
                                    )
                                }
                            >
                                Edit learning objective
                            </Button>
                        </SpaceBetween>
                    }
                >
                    Learning Objective Details
                </Header>
            }
        >
            <SpaceBetween size="l">
                <Container>
                    <ColumnLayout columns={1} variant="text-grid">
                        <SpaceBetween size="l">
                            <ValueWithLabel label="Name">{learningObjective?.name}</ValueWithLabel>
                            <ValueWithLabel label="Program">
                                {learningObjective && learningObjective.programs.length
                                    ? learningObjective.programs[0]
                                    : ' - '}
                            </ValueWithLabel>
                            <ValueWithLabel label="Status">
                                {learningObjective?.status}
                            </ValueWithLabel>
                        </SpaceBetween>
                    </ColumnLayout>
                </Container>
            </SpaceBetween>
        </ContentLayout>
    );
};

export default LearningObjectiveDetail;
