import React, { useEffect, useState } from 'react';
import { Button, Container, Form, Header, SpaceBetween } from '@amzn/awsui-components-react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
    MetadataObjectNameTextArea,
    MetadataObjectNameTextAreaProps,
    MetadataObjectStatusSelect,
    MetadataObjectStatusSelectProps,
} from '../../components';
import {
    AssessmentLanguage,
    CreateLearningObjectiveMutationVariables,
    UpdateLearningObjectiveMutationVariables,
    useGetLearningObjectiveQuery,
    useUpdateLearningObjectiveMutation,
} from '../../graphql';
import { useNotifications } from '../../context/NotificationsProvider';
import { ProgramSelect, ProgramSelectProps } from '../../components/common/formFields';
import { metadataInitialFormValues } from '../../common/constants/metadataObject';
import { LearningObjectiveEditId } from '../../common/dataTestIds/metaDatumIds';

const LearningObjectiveEdit = () => {
    const navigate = useNavigate();
    const { id = '', version } = useParams();
    const { state } = useLocation();

    const [formValues, setFormValues] = useState<Partial<UpdateLearningObjectiveMutationVariables>>(
        state ?? metadataInitialFormValues,
    );
    const { addNotification } = useNotifications();

    const [updateLearningObjective, { loading: updatingLearningObjective }] =
        useUpdateLearningObjectiveMutation();

    const handleFormValueChange = (
        formUpdates: Partial<CreateLearningObjectiveMutationVariables>,
    ) => {
        setFormValues({ ...formValues, ...formUpdates });
    };

    const { data, loading } = useGetLearningObjectiveQuery({
        variables: {
            id,
            language: AssessmentLanguage.En,
            version: Number(version),
        },
        fetchPolicy: 'cache-and-network',
    });

    useEffect(() => {
        if (!loading && data) {
            const learningObjective = data.assessmentLearningObjective!;
            setFormValues(learningObjective);
        }
    }, [loading, data]);

    const handleUpdateLearningObjective = async () => {
        try {
            const { data } = await updateLearningObjective({
                variables: {
                    id,
                    version: Number(version),
                    status: formValues.status!,
                    name: formValues.name!,
                },
            });

            addNotification({
                id: `create-learning-objective-${Date.now()}`,
                ...(data?.updateAssessmentLearningObjective
                    ? {
                          type: 'success',
                          content: 'Learning objective updated successfully.',
                      }
                    : {
                          type: 'error',
                          content: 'There was an error updating the learning objective.',
                      }),
            });
            if (data?.updateAssessmentLearningObjective) {
                const learningObjective = data.updateAssessmentLearningObjective;
                navigate(
                    `/learning-objectives/${learningObjective.id}/version/${learningObjective.version}`,
                );
            }
        } catch (error) {}
    };

    const metadataObjectStatusProps: MetadataObjectStatusSelectProps = {
        formValues,
        handleFormValueChange,
    };

    const metadataObjectNameTextAreaProps: MetadataObjectNameTextAreaProps = {
        formValues,
        handleFormValueChange,
        keyName: 'name',
        label: 'Name',
    };

    const programSelectProps: ProgramSelectProps = {
        formValues: formValues,
        handleFormValueChange,
        errors: {},
    };

    return (
        <Form
            data-testid={LearningObjectiveEditId.FormId}
            actions={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button
                        onClick={() => navigate('/learning-objectives')}
                        formAction="none"
                        variant="link"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleUpdateLearningObjective}
                        disabled={updatingLearningObjective}
                        variant="primary"
                    >
                        {updatingLearningObjective ? 'Saving' : 'Save'}
                    </Button>
                </SpaceBetween>
            }
            header={<Header variant="h1">Edit Learning Objective</Header>}
        >
            <Container header={<Header variant="h2">Learning objective details</Header>}>
                <SpaceBetween direction="vertical" size="l">
                    <MetadataObjectNameTextArea {...metadataObjectNameTextAreaProps} />
                    <MetadataObjectStatusSelect {...metadataObjectStatusProps} />
                    <ProgramSelect {...programSelectProps} />
                </SpaceBetween>
            </Container>
        </Form>
    );
};

export default LearningObjectiveEdit;
