import { PropertyFilter, PropertyFilterProps } from '@amzn/awsui-components-react';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { AssessmentStatus } from '../../common/constants/assessments';
import { I18N_STRINGS } from '../../common/constants/propertyFilterKeys';
import { GetLearningObjectivesQuery, useGetLearningObjectivesLazyQuery } from '../../graphql';

export const LOCAL_STORAGE_ASSESSMENTS_FILTER_KEY = 'assessments-filter-key';

export interface StoredAssessmentsFilters {
    propertyFilters?: any;
}

interface FilterOption {
    propertyKey: string;
    value: string;
    label?: string;
}

interface AssessmentsListPropertyFilterProps {
    query: PropertyFilterProps.Query;
    setQuery: Dispatch<SetStateAction<PropertyFilterProps.Query>>;
    handleGetAssessments: (filters: any) => Promise<void>;
    enabledFilters?: string[];
}

const AssessmentsListPropertyFilter = ({
    query,
    setQuery,
    handleGetAssessments,
    enabledFilters,
}: AssessmentsListPropertyFilterProps) => {
    const [objectiveValues, setObjectivesState] = useState<
        GetLearningObjectivesQuery | undefined
    >();
    const learningObjectiveIdToNameMap: { [key: string]: string } = {};
    const setObjectives = (newObjectivesValue: GetLearningObjectivesQuery | undefined) => {
        newObjectivesValue?.assessmentLearningObjectives.metadataObjects.forEach(
            (learningObjective) => {
                learningObjectiveIdToNameMap[learningObjective.id] = learningObjective.name;
            },
        );
        setObjectivesState(newObjectivesValue);
    };

    const [getLearningObjectives, { loading: learningObjectivesLoading }] =
        useGetLearningObjectivesLazyQuery();

    const getLearningObjectiveNameFromId = (id: string) => {
        return learningObjectiveIdToNameMap[id] || id;
    };

    const assessmentsFilterProperties: Array<PropertyFilterProps.FilteringProperty> =
        useMemo(() => {
            const availabileFilters = [
                {
                    key: 'searchText',
                    defaultOperator: ':',
                    propertyLabel: 'Title',
                    groupValuesLabel: 'Assessment title value',
                },
                {
                    key: 'status',
                    operators: ['='],
                    propertyLabel: 'Status',
                    groupValuesLabel: 'Assessment status value',
                },
                {
                    key: 'learningObjectives',
                    operators: ['='].map((operator) => ({
                        operator,
                        format: getLearningObjectiveNameFromId,
                    })),
                    propertyLabel: 'Learning objectives',
                    groupValuesLabel: 'Learning objective value',
                },
            ];

            return availabileFilters.filter(
                (filter) =>
                    !enabledFilters ||
                    !enabledFilters.length ||
                    enabledFilters.includes(filter.key),
            );
        }, []);
    useEffect(() => {
        const getObjectives = async () => {
            const { data } = await getLearningObjectives({
                variables: {
                    size: 500,
                },
            });
            setObjectives(data);
        };
        getObjectives();
    }, []);

    const assessmentFilterOptions = useMemo(() => {
        if (objectiveValues) {
            const statusOptions: FilterOption[] = Object.keys(AssessmentStatus).map((status) => ({
                propertyKey: 'status',
                value: AssessmentStatus[status as keyof typeof AssessmentStatus],
            }));
            const lerningObjectiveOptions: FilterOption[] =
                objectiveValues.assessmentLearningObjectives.metadataObjects.map(
                    (learningObjective) => ({
                        propertyKey: 'learningObjectives',
                        value: learningObjective.id,
                        label: learningObjective.name,
                    }),
                );
            return [...statusOptions, ...lerningObjectiveOptions];
        }
    }, [objectiveValues]);

    const handleFilterUpdate = async (e: any) => {
        setQuery(e.detail);
        const storedFilters = localStorage.getItem(LOCAL_STORAGE_ASSESSMENTS_FILTER_KEY);
        let newFilter = {} as StoredAssessmentsFilters;
        storedFilters
            ? (newFilter = {
                  ...JSON.parse(storedFilters),
                  propertyFilters: e.detail,
              })
            : (newFilter = { propertyFilters: e.detail });

        localStorage.setItem(
            LOCAL_STORAGE_ASSESSMENTS_FILTER_KEY,
            JSON.stringify({ ...newFilter }),
        );
        handleGetAssessments({ propertyFilters: e.detail });
    };

    return (
        <div style={{ flex: 1 }}>
            <PropertyFilter
                onChange={handleFilterUpdate}
                query={query}
                expandToViewport
                filteringOptions={assessmentFilterOptions}
                filteringProperties={assessmentsFilterProperties}
                disableFreeTextFiltering={true}
                hideOperations
                i18nStrings={I18N_STRINGS}
            />
        </div>
    );
};

export default AssessmentsListPropertyFilter;
