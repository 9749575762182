import { useState } from 'react';
import { PropertyFilterProps } from '@amzn/awsui-components-react';
import {
    AssessmentMetadataObject,
    AssessmentQuestion,
    UpdateQuestionBankMutationVariables,
    useGetLearningObjectivesLazyQuery,
    useGetQuestionBankLazyQuery,
    useGetQuestionBanksLazyQuery,
    useUpdateAssessmentQuestionMutation,
    useUpdateQuestionBankMutation,
} from '../graphql';

export type AssociatedMetadata = {
    id: string;
    metadataType: string;
};

export const useQuestionBanks = () => {
    const [questionBank, setQuestionBank] = useState<AssessmentMetadataObject>();
    const [questionBanks, setQuestionBanks] = useState<AssessmentMetadataObject[]>([]);
    const [associatedMetadata, setAssociatedMetadata] = useState<AssessmentMetadataObject[]>([]);
    const [getQuestionBank] = useGetQuestionBankLazyQuery();
    const [getQuestionBanks] = useGetQuestionBanksLazyQuery();
    const [getLearningObjectives] = useGetLearningObjectivesLazyQuery();
    const [updateQuestion, { loading: updatingQuestion }] = useUpdateAssessmentQuestionMutation();
    const [updateQuestionBank, { loading: updatingQuestionBank }] = useUpdateQuestionBankMutation();

    const handleGetQuestionBank = async (id: string, version: number = 1) => {
        const { data } = await getQuestionBank({ variables: { id, version } });
        setQuestionBank(data?.assessmentQuestionBank);
    };

    const handleGetQuestionBanks = async (searchQuery?: any) => {
        const { data } = await getQuestionBanks({
            variables: {
                ...searchQuery,
            },
        });
        setQuestionBanks(data?.assessmentQuestionBanks.metadataObjects!);
    };

    const handleGetAssociatedMetadata = async ({
        associatedMetadataIds,
    }: {
        associatedMetadataIds: string[];
    }) => {
        const { data } = await getLearningObjectives({
            variables: {
                learningObjectives: associatedMetadataIds,
            },
        });
        setAssociatedMetadata(data?.assessmentLearningObjectives.metadataObjects!);
    };

    const buildSearchQuery = (tokens: any[]) => {
        return tokens.reduce((acc: any, token: any) => {
            const key = Object.keys(token)[0];
            if (Array.isArray(token[key])) {
                const value = token[key][0];
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(value);
            } else if (typeof token[key] === 'string') {
                if (!acc[key]) {
                    acc[key] = '';
                }
                acc[key] += token[key] + ' ';
            }
            return acc;
        }, {});
    };

    const handleGetQuestionBanksByProperyFilter = async (filters?: any) => {
        let tokens = [];
        if (filters && filters.propertyFilters.tokens.length !== 0) {
            tokens = filters.propertyFilters.tokens.map(
                (token: PropertyFilterProps.FilteringOption) => {
                    if (token.propertyKey === 'searchText') {
                        return { searchText: token.value };
                    }
                    return { [token.propertyKey]: [token.value] };
                },
            );
        }
        const searchQuery = buildSearchQuery(tokens);
        const { data } = await getQuestionBanks({
            variables: {
                ...searchQuery,
            },
        });
        const questionBanks = [...data?.assessmentQuestionBanks.metadataObjects!];
        const sortedQuestionBanks = questionBanks.sort((a, b) =>
            a.modifiedTimestamp! < b.modifiedTimestamp! ? 1 : -1,
        );
        setQuestionBanks(sortedQuestionBanks);
    };

    const handleUpdateQuestionBank = async ({
        questionBankId,
        version,
        formValues,
    }: {
        questionBankId: string;
        version: string;
        formValues: Partial<UpdateQuestionBankMutationVariables>;
    }) => {
        return await updateQuestionBank({
            variables: {
                id: questionBankId,
                version: Number(version),
                status: formValues.status!,
                name: formValues.name!,
                programs: formValues.programs!,
                // @ts-ignore
                associatedMetadata: formValues.associatedMetadata!.map((md) => ({
                    id: md.id,
                    metadataType: md.metadataType,
                })),
            },
        });
    };

    const handleUpdateQuestions = async (questions: Partial<AssessmentQuestion>[]) => {
        const updatePromises = questions.map(
            ({
                id,
                version,
                status,
                type,
                scoringMethod,
                answers,
                difficulty,
                questionText,
                programs,
                learningObjectives,
                questionBanks,
            }) => {
                return updateQuestion({
                    variables: {
                        id: id!,
                        version: version!,
                        status: status!,
                        type: type!,
                        scoringMethod: scoringMethod!,
                        answers: answers?.map(({ answerText, isCorrect, explanation }) => ({
                            answerText: answerText!,
                            isCorrect: isCorrect!,
                            explanation: explanation ?? '',
                        }))!,
                        difficulty: difficulty!,
                        questionText: questionText!,
                        programs: programs!,
                        learningObjectives: learningObjectives!,
                        questionBanks: questionBanks!,
                    },
                });
            },
        );
        await Promise.all(updatePromises);
    };

    return {
        questionBank,
        questionBanks,
        associatedMetadata,
        updatingQuestionBank,
        updatingQuestion,
        handleGetQuestionBank,
        handleGetQuestionBanks,
        handleGetQuestionBanksByProperyFilter,
        handleGetAssociatedMetadata,
        handleUpdateQuestions,
        handleUpdateQuestionBank,
    };
};
