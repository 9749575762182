import { Button, Container, Form, Header, SpaceBetween, Tabs } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    LearningObjectiveAttributeEditor,
    LearningObjectiveAttributeEditorItem,
    LearningObjectiveAttributeEditorProps,
    MetadataObjectNameTextArea,
    MetadataObjectNameTextAreaProps,
    MetadataObjectStatusSelect,
    MetadataObjectStatusSelectProps,
} from '../../components';
import {
    AssessmentLanguage,
    AssessmentQuestion,
    CreateQuestionBankMutationVariables,
    useCreateQuestionBankMutation,
} from '../../graphql';
import { metadataInitialFormValues } from '../../common/constants/metadataObject';
import { useNotifications } from '../../context/NotificationsProvider';
import { ProgramSelectProps, ProgramSelect } from '../../components/common/formFields';
import useFormValidation from '../../hooks/useFormValidation';
import { QUESTION_BANK_VALIDATION_FIELDS } from '../../common/constants/validations';
import { useDispatch } from 'react-redux';
import { setBreadcrumbs, setContentType } from '../../reducers/navigationReducer';
import { QUESTION_BANK_CREATE_ROUTE, QUESTION_BANK_LIST_ROUTE } from '../../router/router';

const QuestionBankCreate = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formValues, setFormValues] = useState(metadataInitialFormValues);
    const [learningObjectiveAttributeEditorItems, setLearningObjectiveAttributeEditorItems] =
        useState<LearningObjectiveAttributeEditorItem[]>([{ id: '' }]);
    const [selectedQuestions, setSelectedQuestions] = useState(new Set<AssessmentQuestion>());

    const { addNotification } = useNotifications();
    const { isInvalid, validateForm, errors } =
        useFormValidation<Partial<CreateQuestionBankMutationVariables>>();
    const runInputValidations = () => {
        return validateForm(formValues!, {
            required: QUESTION_BANK_VALIDATION_FIELDS.REQUIRED,
        });
    };

    const [createAssessmentQuestionBank, { loading }] = useCreateQuestionBankMutation();

    useEffect(() => {
        dispatch(
            setBreadcrumbs([
                {
                    text: QUESTION_BANK_LIST_ROUTE.title,
                    href: QUESTION_BANK_LIST_ROUTE.path,
                },
                {
                    text: QUESTION_BANK_CREATE_ROUTE.title,
                    href: QUESTION_BANK_CREATE_ROUTE.path,
                },
            ]),
        );
        dispatch(setContentType('form'));
    }, [dispatch]);

    useEffect(() => {
        if (isInvalid) {
            runInputValidations();
        }
    }, [formValues, isInvalid, validateForm]);

    const handleFormValueChange = (formUpdates: Partial<CreateQuestionBankMutationVariables>) => {
        setFormValues({ ...formValues, ...formUpdates });
    };

    const handleSaveQuestionBank = async () => {
        let invalid = runInputValidations();
        if (invalid) {
            return;
        }
        try {
            const { data } = await createAssessmentQuestionBank({
                variables: {
                    name: formValues.name!,
                    status: formValues.status!,
                    language: AssessmentLanguage.En,
                    programs: formValues.programs!,
                    associatedMetadata: formValues.associatedMetadata!,
                },
            });

            addNotification({
                id: `create-learning-objective-${Date.now()}`,
                ...(data?.createAssessmentQuestionBank
                    ? {
                          type: 'success',
                          content: 'Question bank created successfully.',
                      }
                    : {
                          type: 'error',
                          content: 'There was an error creating the question bank.',
                      }),
            });
            const { id, version } = data?.createAssessmentQuestionBank!;
            navigate(`/question-banks/${id}/version/${version}/edit`);
        } catch (error) {}
    };

    const handleSelectedQuestionChange = (selectedQuestions: Set<AssessmentQuestion>) =>
        setSelectedQuestions(new Set(Array.from(selectedQuestions)));

    const metadataObjectStatusProps: MetadataObjectStatusSelectProps = {
        formValues,
        handleFormValueChange,
    };

    const metadataObjectNameTextAreaProps: MetadataObjectNameTextAreaProps = {
        formValues,
        handleFormValueChange,
        keyName: 'name',
        label: 'Name',
    };

    const programSelectProps: ProgramSelectProps = {
        formValues: formValues,
        handleFormValueChange,
        errors,
    };

    const learningObjectiveAttributeEditorProps: LearningObjectiveAttributeEditorProps = {
        learningObjectiveAttributeEditorItems,
        setLearningObjectiveAttributeEditorItems,
        handleFormValueChange,
        errors,
        selectedPrograms: formValues.programs as [],
    };

    return (
        <Form
            actions={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button
                        onClick={() => navigate('/question-banks')}
                        formAction="none"
                        variant="link"
                    >
                        Cancel
                    </Button>
                    <Button onClick={handleSaveQuestionBank} disabled={loading} variant="primary">
                        {loading ? 'Saving' : 'Save'}
                    </Button>
                </SpaceBetween>
            }
            header={<Header variant="h1">Create Question bank</Header>}
        >
            <Container header={<Header variant="h2">Question bank details</Header>}>
                <SpaceBetween direction="vertical" size="l">
                    <MetadataObjectNameTextArea {...metadataObjectNameTextAreaProps} />
                    <MetadataObjectStatusSelect {...metadataObjectStatusProps} />
                    <ProgramSelect {...programSelectProps} />
                    {formValues.programs && formValues.programs.length > 0 && (
                        <LearningObjectiveAttributeEditor
                            {...learningObjectiveAttributeEditorProps}
                        />
                    )}
                </SpaceBetween>
            </Container>
        </Form>
    );
};

export default QuestionBankCreate;
