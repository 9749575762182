import React from 'react';
import { FormField, Select } from '@amzn/awsui-components-react';
import { handleSelectedOption } from '../../../utils/formUtils';
import { AssessmentQuestionInput } from '../../../graphql';
import { QuestionType } from '../../../common/constants/questions';
import { QuestionIds } from '../../../common/dataTestIds/question';
import { getNodeEnvironment, NodeEnvironment } from '../../../common/nodeEnvironment';

export interface QuestionTypeSelectProps {
    formValues: Partial<AssessmentQuestionInput>;
    handleFormValueChange: (formUpdates: Partial<AssessmentQuestionInput>) => void;
}
const QuestionTypeSelect = ({ formValues, handleFormValueChange }: QuestionTypeSelectProps) => {
    const questionTypeOptionValues = Object.keys(QuestionType).map(
        (status) => QuestionType[status as keyof typeof QuestionType],
    );

    const questionTypeOptions = questionTypeOptionValues.map((type) => ({
        value: type,
        label: type,
    }));
    const temporaryTypeOptions = [
        { value: QuestionType.MULTIPLE_CHOICE, label: QuestionType.MULTIPLE_CHOICE },
        { value: QuestionType.MULTI_SELECT, label: QuestionType.MULTI_SELECT },
    ];

    return (
        <FormField data-testid={QuestionIds.QuestionTypeLabel} label="Question type">
            <Select
                selectedOption={handleSelectedOption({
                    selectedOption: formValues.type ?? QuestionType.MULTIPLE_CHOICE,
                    options: questionTypeOptions,
                })}
                onChange={(e) => {
                    handleFormValueChange({
                        type: e.detail.selectedOption.value!,
                    });
                }}
                options={temporaryTypeOptions}
            />
        </FormField>
    );
};

export default QuestionTypeSelect;
