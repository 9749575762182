import React from 'react';
import { Box, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import LoadingBar from '@amzn/awsui-chat-components/loading-bar';
import './loadingModal.scss';

export interface LoadingModalProps {
    isLoadingModalVisible: boolean;
    setIsLoadingModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoadingModal = ({ isLoadingModalVisible, setIsLoadingModalVisible }: LoadingModalProps) => {
    return (
        <Modal
            size="medium"
            closeAriaLabel="loading-modal-close-disabled"
            visible={isLoadingModalVisible}
            onDismiss={() => setIsLoadingModalVisible(false)}
        >
            <SpaceBetween size="l">
                <div className="center-align">
                    <Box float="left" padding={{ left: 'xxl' }}>
                        <img src="/public/dog.svg" alt="loading" />
                    </Box>
                    <Box variant="h2" float="left" padding={{ left: 'xxl' }}>
                        Generating your questions.
                        <br />
                        Please stand by...
                    </Box>
                </div>
                <Box textAlign="center">
                    <LoadingBar variant="gen-ai" />
                </Box>
            </SpaceBetween>
        </Modal>
    );
};

export default LoadingModal;
