export enum NodeEnvironment {
    LOCAL = 'local',
    DEVELOPMENT = 'development',
    BETA = 'beta',
    PROD = 'prod',
    GAMMA = 'gamma',
}

export const getNodeEnvironment = (
    environmentValue: string = process.env.NODE_ENV || '',
): NodeEnvironment => {
    if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
        return NodeEnvironment.LOCAL;
    }

    const environment = Object.keys(NodeEnvironment).filter(
        (key) => key === environmentValue.toUpperCase(),
    )[0];

    if (!environment) {
        throw new Error(`Node environment [${environmentValue}] is not valid`);
    }
    return NodeEnvironment[environment as keyof typeof NodeEnvironment];
};

export const isProd = (): boolean => {
    const environment = getNodeEnvironment();
    return environment === NodeEnvironment.PROD;
};
