import React, { useEffect, useState } from 'react';
import {
    AssessmentStatusSelect,
    AssessmentStatusSelectProps,
    ProgramSelect,
    ProgramSelectProps,
} from '../../../components/common/formFields';
import {
    Button,
    Container,
    Form,
    FormField,
    Header,
    Input,
    SpaceBetween,
} from '@amzn/awsui-components-react';
import {
    AssessmentInput,
    AssessmentUpdateInput,
    useGetAssessmentQuery,
    useUpdateAssessmentMutation,
} from '../../../graphql';
import useFormValidation from '../../../hooks/useFormValidation';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ASSESSMENT_EDIT_DETAILS_ROUTE, ASSESSMENT_LIST_ROUTE } from '../../../router/router';
import { setBreadcrumbs, setContentType } from '../../../reducers/navigationReducer';
import { useNotifications } from '../../../context/NotificationsProvider';
import { initialAssessmentFormValues } from '../../../common/constants/assessments';
import { AssessmentDetailsEditForm } from '../../../common/dataTestIds/assessmentDetails';
import {
    AssessmentLearningObjectiveAttributeEditor,
    AssessmentLearningObjectiveAttributeEditorItem,
    AssessmentLearningObjectiveAttributeEditorItemProps,
} from '../../../components';

const AssessmentEditDetails = () => {
    const { state } = useLocation();
    const dispatch = useDispatch();
    const { addNotification } = useNotifications();
    const navigate = useNavigate();
    const { id = '', version } = useParams();
    const [formValues, setFormValues] = useState<Partial<AssessmentUpdateInput>>(
        state ?? initialAssessmentFormValues,
    );
    const [
        assessmentLearningObjectiveAttributeEditorItems,
        setAssessmentLearningObjectiveAttributeEditorItems,
    ] = useState<AssessmentLearningObjectiveAttributeEditorItem[]>([{ id: '', numQuestions: '' }]);
    const { errors, controlArrayErrors } = useFormValidation<Partial<AssessmentInput>>();

    const [updateAssessment, { loading: updatingAssessment }] = useUpdateAssessmentMutation();

    const { data, loading } = useGetAssessmentQuery({
        variables: {
            id,
            version: Number(version),
        },
        fetchPolicy: 'cache-first',
    });

    useEffect(() => {
        dispatch(
            setBreadcrumbs([
                {
                    text: ASSESSMENT_LIST_ROUTE.title,
                    href: ASSESSMENT_LIST_ROUTE.path,
                },
                {
                    text: ASSESSMENT_EDIT_DETAILS_ROUTE.title,
                    href: `/assessments/${formValues.id}/version/${formValues.version}`,
                },
                {
                    text: formValues.title,
                },
            ]),
        );
        dispatch(setContentType('form'));
    }, [dispatch, formValues]);

    useEffect(() => {
        if (!loading && data) {
            const assessment = data.assessment!;
            setFormValues(assessment);
        }
    }, [loading, data]);

    useEffect(() => {
        if (formValues) {
            const attributeEditorItems = formValues.learningObjectives!.map((lo) => ({
                id: lo.id,
                numQuestions: lo.numQuestions.toString(),
            }));
            setAssessmentLearningObjectiveAttributeEditorItems(attributeEditorItems);
        }
    }, [formValues.learningObjectives]);

    const handleFormValueChange = (formUpdates: Partial<AssessmentUpdateInput>) => {
        setFormValues({ ...formValues, ...formUpdates });
    };

    const handleEditAssessment = async () => {
        const {
            id,
            version,
            title,
            status,
            passingScore,
            maxAttempts,
            waitTime,
            timeLimit,
            displaySetting,
            programs,
            questions,
            learningObjectives,
            completionMessages,
            isCorrectAnswersShown,
            isDetailedResultsEnabled,
            isSubmittedResponsesShown,
            questionOrdering,
            scoreDisplay,
            isCategoryScoreEnabled,
            isCopyPasteEnabled,
            isFinalScoreEnabled,
            isFlaggingEnabled,
            isNextBackEnabled,
            isNotesEnabled,
            isPausingEnabled,
        } = formValues;
        try {
            const { data } = await updateAssessment({
                variables: {
                    id: id!,
                    version: version!,
                    title: title!,
                    status: status!,
                    passingScore: passingScore!,
                    maxAttempts: maxAttempts!,
                    waitTime: waitTime!,
                    timeLimit: timeLimit!,
                    displaySetting: displaySetting!,
                    programs: programs!,
                    questions: questions!.map(({ id, learningObjectives }) => ({
                        id,
                        learningObjectives,
                    })),
                    learningObjectives: learningObjectives!.map(({ id, numQuestions }) => ({
                        id,
                        numQuestions,
                    })),
                    completionMessages: completionMessages!.map(
                        ({ maxScore, minScore, message }) => ({ maxScore, minScore, message }),
                    ),
                    isCorrectAnswersShown: isCorrectAnswersShown!,
                    isDetailedResultsEnabled: isDetailedResultsEnabled!,
                    isSubmittedResponsesShown: isSubmittedResponsesShown!,
                    questionOrdering: questionOrdering!,
                    scoreDisplay: scoreDisplay!,
                    isCategoryScoreEnabled: isCategoryScoreEnabled!,
                    isCopyPasteEnabled: isCopyPasteEnabled!,
                    isFinalScoreEnabled: isFinalScoreEnabled!,
                    isFlaggingEnabled: isFlaggingEnabled!,
                    isNextBackEnabled: isNextBackEnabled!,
                    isNotesEnabled: isNotesEnabled!,
                    isPausingEnabled: isPausingEnabled!,
                },
            });
            addNotification({
                id: `create-question-${Date.now()}`,
                ...(data?.updateAssessment
                    ? {
                          type: 'success',
                          content: 'Assessment updated successfully.',
                      }
                    : {
                          type: 'error',
                          content: 'There was an error updating the assessment.',
                      }),
            });
            if (data?.updateAssessment) {
                const assessment = data.updateAssessment;
                navigate(`/assessments/${assessment?.id}/version/${assessment?.version}`, {
                    state: { ...assessment },
                });
            }
        } catch (error) {}
    };

    const assessmentStatusSelectProps: AssessmentStatusSelectProps = {
        formValues,
        handleFormValueChange,
    };

    const learningObjectiveAttributeEditorProps: AssessmentLearningObjectiveAttributeEditorItemProps =
        {
            assessmentLearningObjectiveAttributeEditorItems,
            setAssessmentLearningObjectiveAttributeEditorItems,
            handleFormValueChange,
            errors,
            controlArrayErrors,
            selectedPrograms: formValues.programs,
        };

    const programSelectProps: ProgramSelectProps = {
        formValues,
        handleFormValueChange,
        errors: {},
    };

    return (
        <Form
            data-testid={AssessmentDetailsEditForm.FormId}
            actions={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button onClick={() => navigate(-1)} formAction="none" variant="link">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleEditAssessment}
                        disabled={updatingAssessment}
                        variant="primary"
                    >
                        {updatingAssessment ? 'Saving' : 'Save'}
                    </Button>
                </SpaceBetween>
            }
            header={<Header variant="h1">Edit Assessment</Header>}
        >
            <Container header={<Header variant="h2">Assessment details</Header>}>
                <SpaceBetween direction="vertical" size="l">
                    <FormField
                        data-testid={AssessmentDetailsEditForm.AssessmentTitleLabel}
                        label="Title"
                        errorText={errors.title ?? null}
                    >
                        <Input
                            value={formValues.title ?? ''}
                            onChange={({ detail }) =>
                                handleFormValueChange({ title: detail.value })
                            }
                        />
                    </FormField>
                    <ProgramSelect {...programSelectProps} />
                    <AssessmentStatusSelect {...assessmentStatusSelectProps} />
                    <AssessmentLearningObjectiveAttributeEditor
                        {...learningObjectiveAttributeEditorProps}
                    />
                </SpaceBetween>
            </Container>
        </Form>
    );
};

export default AssessmentEditDetails;
