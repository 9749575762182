import React, { useEffect, useState } from 'react';
import { Checkbox } from '@amzn/awsui-components-react';
import { AssessmentQuestion } from '../../../graphql';

export interface SelectQuestionCheckboxProps {
    item: AssessmentQuestion | null;
    selectedQuestions: Set<AssessmentQuestion>;
    handleSelectedQuestionChange: (selectedQuestions: Set<AssessmentQuestion>) => void;
}

const SelectQuestionCheckbox = ({
    item,
    selectedQuestions,
    handleSelectedQuestionChange,
}: SelectQuestionCheckboxProps) => {
    const [checked, setChecked] = useState(false);
    useEffect(() => {
        if (selectedQuestions && selectedQuestions.has(item!)) {
            setChecked(true);
        } else {
            setChecked(false);
        }
    }, [selectedQuestions, item]);

    const handleCheckbox = (checked: boolean) => {
        if (selectedQuestions.has(item!)) {
            selectedQuestions.delete(item!);
        } else {
            selectedQuestions.add(item!);
        }
        handleSelectedQuestionChange(selectedQuestions);
        setChecked(!checked);
    };
    return <Checkbox checked={checked} onChange={() => handleCheckbox(checked)} />;
};

export default SelectQuestionCheckbox;
