import { AssessmentInput } from '../../graphql';

// TODO Pull these from common
export enum AssessmentStatus {
    ARCHIVED = 'Archived',
    DRAFT = 'Draft',
    PUBLISHED = 'Published',
}

export enum IsDetailedResultsEnabled {
    DISABLED = 'Disabled',
    ENABLED_ALWAYS = 'EnabledAlways',
    ENABLED_IF_PASSED = 'EnabledIfPassed',
}

export enum IsSubmittedResponsesShown {
    DISABLED = 'Disabled',
    ENABLED_ALWAYS = 'EnabledAlways',
    ENABLED_IF_PASSED = 'EnabledIfPassed',
}

export enum IsCorrectAnswersShown {
    DISABLED = 'Disabled',
    ENABLED_ALWAYS = 'EnabledAlways',
    ENABLED_IF_PASSED = 'EnabledIfPassed',
}

export enum ScoreDisplay {
    NUMBER = 'Number',
    PERCENTAGE = 'Percentage',
}

export enum QuestionsOrdering {
    BY_CATEGORY = 'By_Category',
    BY_DIFFICULTY = 'By_Difficulty',
    RANDOM = 'Random',
    SEQUENTIAL = 'Sequential',
}

export enum QuestionsDisplaySetting {
    ALL_ON_ONE_PAGE = 'AllOnOnePage',
    ONE_PER_PAGE = 'OnePerPage',
}

export const initialAssessmentFormValues: Partial<AssessmentInput> = {
    completionMessages: [
        {
            maxScore: 1.5,
            message: 'You have completed this assessment',
            minScore: 1,
        },
    ],
    displaySetting: QuestionsDisplaySetting.ONE_PER_PAGE,
    isCategoryScoreEnabled: false,
    isCopyPasteEnabled: true,
    isCorrectAnswersShown: IsCorrectAnswersShown.ENABLED_ALWAYS,
    isDetailedResultsEnabled: IsDetailedResultsEnabled.ENABLED_ALWAYS,
    isFinalScoreEnabled: true,
    isFlaggingEnabled: true,
    isNextBackEnabled: true,
    isNotesEnabled: false,
    isPausingEnabled: false,
    isSubmittedResponsesShown: IsSubmittedResponsesShown.ENABLED_ALWAYS,
    learningObjectives: [
        {
            id: '',
            numQuestions: 0,
        },
    ],
    maxAttempts: 1,
    passingScore: 0,
    programs: [],
    questionOrdering: QuestionsOrdering.RANDOM,
    questions: [],
    scoreDisplay: ScoreDisplay.PERCENTAGE,
    status: AssessmentStatus.DRAFT,
    timeLimit: 0,
    title: '',
    waitTime: 0,
    isConfidenceLevelPromptEnabled: false,
    isConfidenceLevelShown: false,
};
