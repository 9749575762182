import React, { useEffect, useMemo, useState } from 'react';
import {
    Button,
    CollectionPreferences,
    Header,
    Link,
    Pagination,
    PropertyFilterProps,
    SpaceBetween,
    Table,
} from '@amzn/awsui-components-react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setBreadcrumbs, setContentType } from '../../../reducers/navigationReducer';
import {
    LEARNING_OBJECTIVE_CREATE_ROUTE,
    LEARNING_OBJECTIVE_LIST_ROUTE,
} from '../../../router/router';
import LearningObjectiveListPropertyFilter, {
    LearningObjectivesListPropertyFilterProps,
    LOCAL_STORAGE_LEARNING_OBJECTIVE_FILTER_KEY,
    StoredLearningObjectiveFilters,
} from './LearningObjectiveListPropertyFilter';
import {
    AssessmentMetadataObject,
    useGetAssessmentUsersLazyQuery,
    useGetLearningObjectivesLazyQuery,
} from '../../../graphql';
import { Dictionary } from '../../../interfaces/dictionary';
import { EmptyState } from '../../../components';
import {
    tableCollectionPreferencesProps,
    TABLE_DEFAULT_PAGE_SIZE,
} from '../../../common/constants/tablePreferences';
import { isProd } from '../../../common/nodeEnvironment';
import { LearingObjectiveIds } from '../../../common/dataTestIds/learningObjectives';

interface LearningObjectiveTableHeaderProps {
    currentCount: number;
}

const LearningObjectiveListTableHeader = ({ currentCount }: LearningObjectiveTableHeaderProps) => {
    const navigate = useNavigate();
    return (
        <Header
            counter={`(${currentCount})`}
            actions={
                <SpaceBetween size="xs" direction="horizontal">
                    <SpaceBetween size="xs" direction="horizontal">
                        <Button
                            data-testId={LearingObjectiveIds.CreateButton}
                            onClick={() => navigate(LEARNING_OBJECTIVE_CREATE_ROUTE.path)}
                            variant="primary"
                        >
                            Create learning objective
                        </Button>
                    </SpaceBetween>
                </SpaceBetween>
            }
        >
            Learning objectives
        </Header>
    );
};

// table preference values from local storage
const LOCAL_STORAGE_LEARNING_OBJECTIVES_TABLE_PREFERENCES_KEY =
    'learningObjectivesTablePreferences';
const userTablePreferences = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_LEARNING_OBJECTIVES_TABLE_PREFERENCES_KEY) || '{}',
);

// table column width values from local storage
const LOCAL_STORAGE_LEARNING_OBJECTIVES_TABLE_COLUMN_WIDTHS_KEY =
    'learningObjectivesTableColumnWidths';
const userTableColumnWidths = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_LEARNING_OBJECTIVES_TABLE_COLUMN_WIDTHS_KEY) || '[]',
);

const learningObjectivesTableDefaultPreferences = {
    pageSize: TABLE_DEFAULT_PAGE_SIZE,
    wrapLines: false,
    contentDisplay: [
        { id: 'learningObjectiveName', visible: true },
        { id: 'learningObjectiveStatus', visible: true },
        { id: 'learningObjectivePrograms', visible: true },
        // TODO: Remove prod conditional once CHIRONASSMNTS-459 is complete
        ...(isProd()
            ? []
            : [
                  { id: 'modifiedByName', visible: false },
                  { id: 'createdByName', visible: false },
              ]),
        { id: 'actions', visible: true },
    ],
};

const LearningObjectiveList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [currentPageIndex, setCurrentPageIndex] = useState(1);
    const [_, setFrom] = useState(0);
    const [currentCount, setCurrentCount] = useState(0);
    const [pagesCount, setPagesCount] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [usersInfoDict, setUsersInfoDict] = useState<Dictionary<string>>({});
    const [learningObjectives, setLearningObjectives] = useState<Array<AssessmentMetadataObject>>(
        [],
    );
    const [query, setQuery] = useState<PropertyFilterProps.Query>({
        tokens: [],
        operation: 'and',
    });
    const [preferences, setPreferences] = useState({
        ...learningObjectivesTableDefaultPreferences,
        ...userTablePreferences,
    });

    // queries
    const [getLearningObjectives, { fetchMore }] = useGetLearningObjectivesLazyQuery({
        fetchPolicy: 'cache-and-network',
    });
    const [getUsers] = useGetAssessmentUsersLazyQuery({
        fetchPolicy: 'cache-and-network',
    });

    useEffect(() => {
        dispatch(
            setBreadcrumbs([
                {
                    text: LEARNING_OBJECTIVE_LIST_ROUTE.title,
                    href: LEARNING_OBJECTIVE_LIST_ROUTE.path,
                },
            ]),
        );
        dispatch(setContentType('table'));
    }, [dispatch]);

    useEffect(() => {
        const storedFilterString = localStorage.getItem(
            LOCAL_STORAGE_LEARNING_OBJECTIVE_FILTER_KEY,
        );

        if (storedFilterString) {
            try {
                let savedFilters = JSON.parse(storedFilterString) as StoredLearningObjectiveFilters;
                try {
                    if (savedFilters.propertyFilters) {
                        setQuery(savedFilters.propertyFilters);
                    }
                    handleGetLearningObjectives(savedFilters);
                } catch (e) {}
            } catch (e) {}
        } else {
            handleGetLearningObjectives();
        }
    }, [preferences.pageSize]);

    const getUsersNamesForIds = async (userIds: Set<string>): Promise<any> => {
        const { data } = await getUsers({
            variables: {
                id: Array.from(userIds),
            },
        });
        let userDict = {};
        const users = data?.assessmentUsers?.users;
        if (users) {
            users.forEach((u) => {
                userDict = {
                    ...userDict,
                    [u.id]: u.name,
                };
            });
        }
        return userDict;
    };

    const handleGetUserInfo = async (learningObjectives: AssessmentMetadataObject[]) => {
        const userIds = new Set<string>();
        learningObjectives.forEach((lo) => {
            lo.modifiedBy && userIds.add(lo.modifiedBy);
            lo.createdBy && userIds.add(lo.createdBy);
        });
        const usersInfoDict = await getUsersNamesForIds(userIds);
        setUsersInfoDict(usersInfoDict);
    };

    const buildSearchQuery = (tokens: any[]) => {
        return tokens.reduce((acc: any, token: any) => {
            const key = Object.keys(token)[0];
            if (Array.isArray(token[key])) {
                const value = token[key][0];
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(value);
            } else if (typeof token[key] === 'string') {
                if (!acc[key]) {
                    acc[key] = '';
                }
                acc[key] += token[key] + ' ';
            }
            return acc;
        }, {});
    };

    const handleGetLearningObjectives = async (filters?: any) => {
        setIsLoading(true);
        let tokens = [];
        if (filters && filters.propertyFilters.tokens.length !== 0) {
            tokens = filters.propertyFilters.tokens.map(
                (token: PropertyFilterProps.FilteringOption) => {
                    if (token.propertyKey === 'searchText') {
                        return { searchText: token.value };
                    }
                    return { [token.propertyKey]: [token.value] };
                },
            );
        }
        const searchQuery = buildSearchQuery(tokens);
        const { data } = await getLearningObjectives({
            variables: {
                ...searchQuery,
                size: preferences.pageSize,
            },
        });
        const learningObjectives = [...data?.assessmentLearningObjectives.metadataObjects!];
        const sortedLearningObjectives = learningObjectives.sort((a, b) =>
            a.modifiedTimestamp! < b.modifiedTimestamp! ? 1 : -1,
        );
        const totalCount = data?.assessmentLearningObjectives?.totalCount!;
        const totalPagesCount = Math.ceil(totalCount / preferences.pageSize);
        if (currentPageIndex > totalPagesCount) {
            // if the current page index > number of total pages, reset current index to page 1
            // this can happen when the results per page value is changed to
            // a larger number, e.g. user is on last page, say 100, with 10
            // results per page but changes to 100 results per page, so
            // there are now only 10 pages total but the user's page index
            // is 100.
            setCurrentPageIndex(1);
        }
        setPagesCount(totalPagesCount);
        setCurrentCount(totalCount);
        let learningObjectiveDict = {};
        learningObjectives.forEach((objective) => {
            // Learning objective dictionary
            learningObjectiveDict = {
                ...learningObjectiveDict,
                [objective.id]: objective,
            };
        });
        handleGetUserInfo(sortedLearningObjectives);
        setLearningObjectives(sortedLearningObjectives);
        setIsLoading(false);
    };

    const handlePaginationChange = async (event: any) => {
        setIsLoading(true);
        const { currentPageIndex } = event.detail;

        const updatedPageIndex = currentPageIndex;
        const from = (updatedPageIndex - 1) * preferences.pageSize;

        setCurrentPageIndex(updatedPageIndex);
        setFrom(from);
        const learningObjectiveData = await fetchMore({
            variables: {
                from: from,
                size: preferences.pageSize,
            },
        });
        const learningObjectives =
            learningObjectiveData.data.assessmentLearningObjectives.metadataObjects;
        if (learningObjectives) {
            const totalCount = learningObjectiveData.data.assessmentLearningObjectives?.totalCount!;
            const totalPagesCount = Math.ceil(totalCount / preferences.pageSize);
            const sortedLearningObjectives = learningObjectives.sort((a, b) =>
                a.modifiedTimestamp! < b.modifiedTimestamp! ? 1 : -1,
            );
            setPagesCount(totalPagesCount);
            setCurrentCount(totalCount);
            setLearningObjectives(sortedLearningObjectives);
            handleGetUserInfo(sortedLearningObjectives);
            setIsLoading(false);
        }
    };

    const handleTablePreferencesConfirm = async ({ detail }: any) => {
        setPreferences(detail);
        localStorage.setItem(
            LOCAL_STORAGE_LEARNING_OBJECTIVES_TABLE_PREFERENCES_KEY,
            JSON.stringify(detail),
        );
    };

    const handleTableColumnWidthChange = async ({ detail }: any) => {
        let columnWidths: Record<string, number> = {};
        for (const ix in columnDefinitions) {
            columnWidths[columnDefinitions[ix].id] = detail.widths[ix];
        }
        localStorage.setItem(
            LOCAL_STORAGE_LEARNING_OBJECTIVES_TABLE_COLUMN_WIDTHS_KEY,
            JSON.stringify(columnWidths),
        );
    };

    const columnDefinitions = useMemo(() => {
        return [
            {
                id: 'learningObjectiveName',
                header: 'Learning objective name',
                isRowHeader: true,
                width: userTableColumnWidths['learningObjectiveName'] || 800,
                cell: (item: AssessmentMetadataObject) => (
                    <Link
                        variant="secondary"
                        href={`/learning-objectives/${item.id}/version/${item.version}`}
                        onFollow={(e) => {
                            e.preventDefault();
                            navigate(`/learning-objectives/${item.id}/version/${item.version}`, {
                                state: { ...item },
                            });
                        }}
                    >
                        {item.name}
                    </Link>
                ),
            },
            {
                id: 'learningObjectivePrograms',
                header: 'Programs',
                isRowHeader: true,
                width: userTableColumnWidths['learningObjectivePrograms'] || 500,
                cell: (item: AssessmentMetadataObject) => item.programs?.join(', '),
            },
            {
                id: 'learningObjectiveStatus',
                header: 'Status',
                isRowHeader: true,
                width: userTableColumnWidths['learningObjectiveStatus'] || 120,
                cell: (item: AssessmentMetadataObject) => item.status,
            },
            // TODO: Remove prod conditional once CHIRONASSMNTS-459 is complete
            ...(isProd()
                ? []
                : [
                      {
                          id: 'createdByName',
                          header: 'Created By',
                          isRowHeader: true,
                          width: userTableColumnWidths['createdByName'] || undefined,
                          cell: (item: AssessmentMetadataObject) =>
                              (item.createdBy && usersInfoDict[item.createdBy]) || '',
                      },
                      {
                          id: 'modifiedByName',
                          header: 'Modifed By',
                          isRowHeader: true,
                          width: userTableColumnWidths['modifiedByName'] || undefined,
                          cell: (item: AssessmentMetadataObject) =>
                              (item.modifiedBy && usersInfoDict[item.modifiedBy]) || '',
                      },
                  ]),
            {
                id: 'actions',
                header: 'Actions',
                width: userTableColumnWidths['actions'] || 100,
                cell: (item: AssessmentMetadataObject) => (
                    <SpaceBetween direction="vertical" size="xs">
                        <Button
                            href={`/learning-objectives/${item.id}/version/${item.version}/edit`}
                            onFollow={(e) => {
                                e.preventDefault();
                                navigate(
                                    `/learning-objectives/${item.id}/version/${item.version}/edit`,
                                    {
                                        state: { ...item },
                                    },
                                );
                            }}
                            variant="inline-link"
                        >
                            Edit
                        </Button>
                    </SpaceBetween>
                ),
            },
        ];
    }, [usersInfoDict]);

    const learningObjectiveTableHeader: LearningObjectiveTableHeaderProps = {
        currentCount,
    };

    const learningObjectivesListFilterProps: LearningObjectivesListPropertyFilterProps = {
        query,
        setQuery,
        handleGetLearningObjectives,
    };

    const collectionPreferencesProps = {
        ...tableCollectionPreferencesProps,
        contentDisplayPreference: {
            ...tableCollectionPreferencesProps.contentDisplayPreference,
            options: columnDefinitions.map(({ id, header }) => ({
                id,
                label: header,
                alwaysVisible: ['learningObjectiveName', 'actions'].includes(id),
            })),
        },
    };
    return (
        <Table
            data-testid={LearingObjectiveIds.Table}
            stickyHeader={true}
            header={<LearningObjectiveListTableHeader {...learningObjectiveTableHeader} />}
            variant="full-page"
            items={learningObjectives}
            columnDefinitions={columnDefinitions}
            loadingText="Loading learning objectives"
            loading={isLoading}
            enableKeyboardNavigation
            resizableColumns
            pagination={
                <Pagination
                    currentPageIndex={currentPageIndex}
                    pagesCount={pagesCount}
                    onChange={handlePaginationChange}
                />
            }
            filter={<LearningObjectiveListPropertyFilter {...learningObjectivesListFilterProps} />}
            empty={<EmptyState displayMessage="No learning objectives found." />}
            stickyColumns={{ first: 0, last: 1 }}
            wrapLines={preferences.wrapLines}
            stripedRows={preferences.stripedRows}
            contentDensity={preferences.contentDensity}
            columnDisplay={preferences.contentDisplay}
            onColumnWidthsChange={handleTableColumnWidthChange}
            preferences={
                <CollectionPreferences
                    {...collectionPreferencesProps}
                    preferences={preferences}
                    onConfirm={handleTablePreferencesConfirm}
                />
            }
        />
    );
};

export default LearningObjectiveList;
