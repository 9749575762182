import React from 'react';
import { FormField, Select } from '@amzn/awsui-components-react';
import { handleSelectedOption } from '../../../utils/formUtils';
import { AssessmentInput } from '../../../graphql';
import { AssessmentStatus } from '../../../common/constants/assessments';

export interface AssessmentStatusSelectProps {
    formValues: Partial<AssessmentInput>;
    handleFormValueChange: (formUpdates: Partial<AssessmentInput>) => void;
}

const AssessmentStatusSelect = ({
    formValues,
    handleFormValueChange,
}: AssessmentStatusSelectProps) => {
    const assessmentStatusOptions = Object.keys(AssessmentStatus).map((status) => ({
        value: AssessmentStatus[status as keyof typeof AssessmentStatus],
        label: AssessmentStatus[status as keyof typeof AssessmentStatus],
    }));

    return (
        <FormField label="Status">
            <Select
                selectedOption={handleSelectedOption({
                    selectedOption: formValues.status ?? AssessmentStatus.DRAFT,
                    options: assessmentStatusOptions,
                })}
                onChange={(e) => {
                    handleFormValueChange({
                        status: e.detail.selectedOption.value! as AssessmentStatus,
                    });
                }}
                options={assessmentStatusOptions}
            />
        </FormField>
    );
};

export default AssessmentStatusSelect;
