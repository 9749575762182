export enum QuestionDetails {
    FormId = 'question-detail-form0id',
    TitleLabel = 'question-detail-title-label',
    StatusLabel = 'question-status-label',
    QuestionTextLabel = 'question-text-label',
    QuestionScoringMethodLabel = 'question-scoring-label',
    QuestionProgramLabel = 'question-program-label',
    QuestionDifficultyLabel = 'question-difficulty-label',
    QuestionLearningObjectivesLabel = 'question-learningobjectives-label',
}
