import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setBreadcrumbs, setContentType } from '../../reducers/navigationReducer';
import { QUESTION_BANK_DETAIL_ROUTE, QUESTION_BANK_LIST_ROUTE } from '../../router/router';
import {
    AssessmentLanguage,
    AssessmentMetadataObject,
    useGetQuestionBankQuery,
} from '../../graphql';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Box,
    Button,
    ColumnLayout,
    Container,
    ContentLayout,
    Header,
    SpaceBetween,
    Tabs,
} from '@amzn/awsui-components-react';
import { ValueWithLabel } from '../../components';
import EmbeddedQuestionsTable from '../../components/questions/EmbeddedQuestionsTable';
import { useQuestionsContext } from '../../context/QuestionsProvider';

const QuestionBankDetail = () => {
    const dispatch = useDispatch();
    const { id = '', version } = useParams();
    const [questionBank, setQuestionBank] = useState<AssessmentMetadataObject>();
    const navigate = useNavigate();
    const { learningObjectiveDict, handleGetLearningObjectives } = useQuestionsContext();

    const { data, loading } = useGetQuestionBankQuery({
        variables: {
            id,
            language: AssessmentLanguage.En,
            version: Number(version),
        },
        fetchPolicy: 'cache-and-network',
    });

    useEffect(() => {
        dispatch(
            setBreadcrumbs([
                {
                    text: QUESTION_BANK_LIST_ROUTE.title,
                    href: QUESTION_BANK_LIST_ROUTE.path,
                },
                {
                    text: QUESTION_BANK_DETAIL_ROUTE.title,
                    href: QUESTION_BANK_DETAIL_ROUTE.path,
                },
            ]),
        );
        dispatch(setContentType('content'));
    }, [dispatch]);

    useEffect(() => {
        if (!loading && data) {
            const questionBank = data.assessmentQuestionBank!;
            setQuestionBank(questionBank);
            const learningObjectives = questionBank
                .associatedMetadata!.filter((md) => md.metadataType === 'LEARNING_OBJECTIVE')
                .map((lo) => lo.id);
            handleGetLearningObjectives([], learningObjectives);
        }
    }, [loading, data]);

    return (
        <ContentLayout
            header={
                <Header
                    variant="h1"
                    actions={
                        <SpaceBetween size="s" direction="horizontal">
                            <Button
                                key={`edit-question-bank-button`}
                                data-testid="edit-question-bank-button"
                                onClick={() =>
                                    navigate(
                                        `/question-banks/${questionBank?.id}/version/${questionBank?.version}/edit`,
                                        {
                                            state: { ...questionBank },
                                        },
                                    )
                                }
                            >
                                Edit Question Bank
                            </Button>
                        </SpaceBetween>
                    }
                >
                    Question Bank Details
                </Header>
            }
        >
            <SpaceBetween size="l">
                <Container>
                    <ColumnLayout columns={2} variant="text-grid">
                        <SpaceBetween size="l">
                            <ValueWithLabel label="Name">{questionBank?.name}</ValueWithLabel>
                            <ValueWithLabel label="Program">
                                {questionBank && questionBank.programs.length
                                    ? questionBank.programs[0]
                                    : ' - '}
                            </ValueWithLabel>
                            <ValueWithLabel label="Status">{questionBank?.status}</ValueWithLabel>
                        </SpaceBetween>
                        <SpaceBetween size="l">
                            <div>
                                <Box variant="awsui-key-label">Learning Objectives</Box>
                                <div>
                                    {learningObjectiveDict ? (
                                        <ul>
                                            {questionBank
                                                ?.associatedMetadata!.filter(
                                                    (md) =>
                                                        md.metadataType === 'LEARNING_OBJECTIVE',
                                                )
                                                ?.map((lo, i) => {
                                                    return (
                                                        <li key={i}>
                                                            {learningObjectiveDict[lo.id].name}
                                                        </li>
                                                    );
                                                })}
                                        </ul>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        </SpaceBetween>
                    </ColumnLayout>
                </Container>

                <Container header={<Header variant="h2">Question bank details</Header>}>
                    <SpaceBetween direction="vertical" size="l">
                        <Tabs
                            tabs={[
                                {
                                    label: 'Questions',
                                    id: 'questions-tab',
                                    content: <EmbeddedQuestionsTable questionBankIds={[id]} />,
                                },
                            ]}
                        />
                    </SpaceBetween>
                </Container>
            </SpaceBetween>
        </ContentLayout>
    );
};

export default QuestionBankDetail;
