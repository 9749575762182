import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { router } from './router';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store';
import { ApolloProvider } from '@apollo/client';
import { client } from './common/client';
import { NotificationsProvider } from './context/NotificationsProvider';
import { AuthProvider } from './providers/authProvider';

const container = document.getElementById('root');
const root = createRoot(container!);

// Known Issue: https://cloudscape.aws.dev/versions/from-v21-to-v30/known-migration-issues/#client-side-errors-due-to-resizeobserver
window.addEventListener('error', (e) => {
    if (
        e.message === 'ResizeObserver loop completed with undelivered notifications.' ||
        e.message === 'ResizeObserver loop limit exceeded'
    ) {
        const resizeObserverErrDiv = document.getElementById(
            'webpack-dev-server-client-overlay-div',
        );
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
        if (resizeObserverErr) {
            resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
            resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
    }
});

root.render(
    <StrictMode>
        <Provider store={store}>
            <ApolloProvider client={client}>
                <AuthProvider>
                    <NotificationsProvider>
                        <RouterProvider router={router} />
                    </NotificationsProvider>
                </AuthProvider>
            </ApolloProvider>
        </Provider>
    </StrictMode>,
);
