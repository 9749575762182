import { QuestionType } from './constants/questions';

export const getAnswerChoiceRequirementDescription = (questionType?: string): string => {
    switch (questionType) {
        case QuestionType.MULTIPLE_CHOICE:
            return 'Multiple choice questions must have 1 correct answer and 3 distractors.';
        case QuestionType.MULTI_SELECT:
            return 'Multi-select questions must have at 2 or 3 correct answers and 3 distractors.';
        default:
            return 'Please select whether this is the correct answer.';
    }
};
