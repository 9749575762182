import { PropertyFilter, PropertyFilterProps } from '@amzn/awsui-components-react';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { AssessmentStatus } from '../../../common/constants/assessments';
import { I18N_STRINGS } from '../../../common/constants/propertyFilterKeys';
import { GetLearningObjectivesQuery, useGetLearningObjectivesLazyQuery } from '../../../graphql';

export const LOCAL_STORAGE_ASSESSMENTS_FILTER_KEY = 'assessments-filter-key';

export interface StoredAssessmentsFilters {
    propertyFilters?: any;
}

interface FilterOption {
    propertyKey: string;
    value: string;
}

interface AssessmentsListPropertyFilterProps {
    query: PropertyFilterProps.Query;
    setQuery: Dispatch<SetStateAction<PropertyFilterProps.Query>>;
    handleGetAssessments: (filters: any) => Promise<void>;
}

const AssessmentsListPropertyFilter = ({
    query,
    setQuery,
    handleGetAssessments,
}: AssessmentsListPropertyFilterProps) => {
    const [objectiveValues, setObjectives] = useState<GetLearningObjectivesQuery | undefined>();
    const [getLearningObjectives, { loading: learningObjectivesLoading }] =
        useGetLearningObjectivesLazyQuery();
    const assessmentsFilterProperties: Array<PropertyFilterProps.FilteringProperty> =
        useMemo(() => {
            return [
                {
                    key: 'status',
                    operators: ['='],
                    propertyLabel: 'Status',
                    groupValuesLabel: 'Assessment status value',
                },
                {
                    key: 'learningObjective',
                    operators: ['='],
                    propertyLabel: 'Learning objectives',
                    groupValuesLabel: 'Learning objective value',
                },
            ];
        }, []);

        useEffect(() => {
            const getObjectives =  async () => {
                const { data } = await getLearningObjectives({
                    variables: {},
                });
                setObjectives(data);
            } 
            getObjectives();
        }, []);

    const assessmentFilterOptions = useMemo(() => {
        if(objectiveValues) {
            let filteringOptions: FilterOption[] = [];
            let statusOptions: FilterOption[] = Object.keys(AssessmentStatus).map((status) => ({
                propertyKey: 'status',
                value: AssessmentStatus[status as keyof typeof AssessmentStatus],
            }));
            let lerningObjectiveOptions: FilterOption[] = objectiveValues.assessmentLearningObjectives.metadataObjects.map((program) => ({
                propertyKey: 'learningObjective',
                value: program.name,
            }));
            filteringOptions = [...statusOptions, ...lerningObjectiveOptions];
            return filteringOptions;
        }
    }, [objectiveValues]);

    const handleFilterUpdate = async (e: any) => {
        setQuery(e.detail);
        const storedFilters = localStorage.getItem(LOCAL_STORAGE_ASSESSMENTS_FILTER_KEY);
        let newFilter = {} as StoredAssessmentsFilters;
        storedFilters
            ? (newFilter = {
                  ...JSON.parse(storedFilters),
                  propertyFilters: e.detail,
              })
            : (newFilter = { propertyFilters: e.detail });

        localStorage.setItem(
            LOCAL_STORAGE_ASSESSMENTS_FILTER_KEY,
            JSON.stringify({ ...newFilter }),
        );
        handleGetAssessments({ propertyFilters: e.detail });
    };

    return (
        <div style={{ flex: 1 }}>
            <PropertyFilter
                onChange={handleFilterUpdate}
                query={query}
                expandToViewport
                filteringOptions={assessmentFilterOptions}
                filteringProperties={assessmentsFilterProperties}
                hideOperations
                i18nStrings={I18N_STRINGS}
            />
        </div>
    );
};

export default AssessmentsListPropertyFilter;
