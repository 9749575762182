import React, { useEffect, useMemo, useState } from 'react';
import {
    Box,
    Button,
    Header,
    Link,
    Pagination,
    PropertyFilterProps,
    SpaceBetween,
    Table,
} from '@amzn/awsui-components-react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setBreadcrumbs, setContentType } from '../../../reducers/navigationReducer';
import { QUESTION_BANK_CREATE_ROUTE, QUESTION_BANK_LIST_ROUTE } from '../../../router/router';
import { AssessmentMetadataObject, useGetQuestionBanksLazyQuery } from '../../../graphql';
import QuestionBankListPropertyFilter, {
    LOCAL_STORAGE_QUESTION_BANK_FILTER_KEY,
    QuestionBanksPropertyFilterProps,
    StoredQuestionBankFilters,
} from './QuestionBankListPropertyFilter';

const QuestionBankTableHeader = () => {
    const navigate = useNavigate();

    return (
        <Header
            actions={
                <SpaceBetween size="xs" direction="horizontal">
                    <SpaceBetween size="xs" direction="horizontal">
                        <Button
                            onClick={() => navigate(QUESTION_BANK_CREATE_ROUTE.path)}
                            variant="primary"
                        >
                            Create question bank
                        </Button>
                    </SpaceBetween>
                </SpaceBetween>
            }
        >
            Question banks
        </Header>
    );
};
const QuestionBankList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [questionBanks, setQuestionBanks] = useState<Array<AssessmentMetadataObject>>([]);

    const [query, setQuery] = useState<PropertyFilterProps.Query>({
        tokens: [],
        operation: 'and',
    });

    const [getQuestionBanks, { data, loading }] = useGetQuestionBanksLazyQuery({
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        dispatch(
            setBreadcrumbs([
                {
                    text: QUESTION_BANK_LIST_ROUTE.title,
                    href: QUESTION_BANK_LIST_ROUTE.path,
                },
            ]),
        );
        dispatch(setContentType('table'));
    }, [dispatch]);

    useEffect(() => {
        const storedFilterString = localStorage.getItem(LOCAL_STORAGE_QUESTION_BANK_FILTER_KEY);

        if (storedFilterString) {
            try {
                let savedFilters = JSON.parse(storedFilterString) as StoredQuestionBankFilters;
                try {
                    if (savedFilters.propertyFilters) {
                        setQuery(savedFilters.propertyFilters);
                    }
                    handleGetQuestionBanks(savedFilters);
                } catch (e) {}
            } catch (e) {}
        } else {
            handleGetQuestionBanks();
        }
    }, []);

    const handleGetQuestionBanks = async (filters?: any) => {
        let tokens = [];
        if (filters && filters.propertyFilters.tokens.length !== 0) {
            tokens = filters.propertyFilters.tokens.map(
                (token: PropertyFilterProps.FilteringOption) => {
                    return { [token.propertyKey]: [token.value] };
                },
            );
        }
        const searchQuery = Object.assign({}, ...tokens);
        const { data } = await getQuestionBanks({
            variables: {
                ...searchQuery,
            },
        });
        const questionBanks = [...data?.assessmentQuestionBanks.metadataObjects!];
        const sortedQuestionBanks = questionBanks.sort((a, b) =>
            a.modifiedTimestamp! < b.modifiedTimestamp! ? 1 : -1,
        );
        setQuestionBanks(sortedQuestionBanks);
    };

    const columnDefinitions = useMemo(() => {
        return [
            {
                id: 'questionBankName',
                header: 'Question bank name',
                cell: (item: AssessmentMetadataObject) => (
                    <Link
                        variant="secondary"
                        href={`/question-banks/${item.id}/version/${item.version}`}
                        onFollow={(e) => {
                            e.preventDefault();
                            navigate(`/question-banks/${item.id}/version/${item.version}`, {
                                state: { ...item },
                            });
                        }}
                    >
                        {item.name}
                    </Link>
                ),
                isRowHeader: true,
            },
            {
                id: 'questionBankPrograms',
                header: 'Programs',
                cell: (item: AssessmentMetadataObject) => item.programs?.join(', '),
                isRowHeader: true,
            },
            {
                id: 'questionBankStatus',
                header: 'Status',
                cell: (item: AssessmentMetadataObject) => item.status,
                isRowHeader: true,
            },
            {
                id: 'actions',
                header: 'Actions',
                cell: (item: AssessmentMetadataObject) => (
                    <SpaceBetween direction="vertical" size="xs">
                        <Button
                            onClick={() => {
                                navigate(
                                    `/question-banks/${item.id}/version/${item.version}/edit`,
                                    {
                                        state: { ...item },
                                    },
                                );
                            }}
                            variant="inline-link"
                        >
                            Edit
                        </Button>
                    </SpaceBetween>
                ),
            },
        ];
    }, []);

    const questionBankListPropertyFilter: QuestionBanksPropertyFilterProps = {
        query,
        setQuery,
        handleGetQuestionBanks,
    };

    return (
        <Table
            stickyHeader={true}
            header={<QuestionBankTableHeader />}
            variant="full-page"
            items={questionBanks}
            columnDefinitions={columnDefinitions}
            loadingText="Loading questions banks"
            enableKeyboardNavigation
            pagination={<Pagination currentPageIndex={1} pagesCount={1} onChange={() => {}} />}
            filter={<QuestionBankListPropertyFilter {...questionBankListPropertyFilter} />}
            empty={
                <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
                    No question banks found.
                </Box>
            }
        />
    );
};

export default QuestionBankList;
