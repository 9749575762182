import { AssessmentLearningObjectiveAttributeEditorItem } from '../../components';
import {
    AssessmentInput,
    AssessmentQuestionInput,
    CreateLearningObjectiveMutationVariables,
    CreateQuestionBankMutationVariables,
} from '../../graphql';
import { ValidationType } from '../../hooks/useFormValidation';

type ValidationKeys = 'REQUIRED';

export const QUESTION_INPUT_VALIDATION_FIELDS: {
    [key in ValidationKeys]: Array<keyof Partial<AssessmentQuestionInput>>;
} = {
    REQUIRED: ['questionText', 'learningObjectives', 'programs'],
};

export const GENAI_PROMPT_VALIDATION_FIELDS: {
    [key in ValidationKeys]: Array<keyof Partial<AssessmentQuestionInput>>;
} = {
    REQUIRED: ['learningObjectives', 'programs'],
};

export const ASSESSMENT_DETAIL_VALIDATION_FIELDS: {
    [key in ValidationKeys]: Array<keyof Partial<AssessmentInput>>;
} = {
    REQUIRED: ['title'],
};

interface LearningObjectiveControlArrayFormValues {
    assessmentLearningObjectiveAttributeEditorItems: AssessmentLearningObjectiveAttributeEditorItem;
}

export const learningObjectiveAttributeValidationConfig: {
    [learningObjectiveControlArrayFormValuesKey in keyof LearningObjectiveControlArrayFormValues]: {
        [key in ValidationType]?: Array<
            keyof LearningObjectiveControlArrayFormValues[learningObjectiveControlArrayFormValuesKey]
        >;
    };
} = {
    assessmentLearningObjectiveAttributeEditorItems: {
        required: ['id', 'numQuestions'],
    },
};

export const LEARNING_OBJECTIVE_VALIDATION_FIELDS: {
    [key in ValidationKeys]: Array<keyof Partial<CreateLearningObjectiveMutationVariables>>;
} = {
    REQUIRED: ['programs'],
};

export const QUESTION_BANK_VALIDATION_FIELDS: {
    [key in ValidationKeys]: Array<keyof Partial<CreateQuestionBankMutationVariables>>;
} = {
    REQUIRED: ['programs'],
};
