import { isProd } from './nodeEnvironment';

class FeatureFlag {
    public isEnabled: Function;
    constructor(isEnabled: Function) {
        this.isEnabled = isEnabled;
    }
}

export const FeatureFlags = {
    // TODO: Enable user management feature flag once CHIRONASSMNTS-459 is complete
    userManagement: new FeatureFlag(() => !isProd()),

    // TODO: Enable audit log feature flag once CHIRONASSMNTS-459 is complete
    auditLog: new FeatureFlag(() => !isProd()),

    // TODO: Enable question feedback feature flag once CHIRONASSMNTS-459 is complete
    questionFeedback: new FeatureFlag(() => !isProd()),

    // TODO: Enable rag feature flag when ready
    rag: new FeatureFlag(() => true),

    // TODO: Enable when player changes are complete
    confidenceLevelSettings: new FeatureFlag(() => !isProd()),

    copyPasteSetting: new FeatureFlag(() => false),
    showBackAndNextButtonSetting: new FeatureFlag(() => false),
    notesSetting: new FeatureFlag(() => false),
    scoreDisplaySetting: new FeatureFlag(() => false),
    questionDisplaySetting: new FeatureFlag(() => false),
};
