import React from 'react';
import { Box, Link } from '@amzn/awsui-components-react';
import './footer.scss';

const insertYear = (s: string) => `${new Date().getFullYear()} ${s}`;

const Footer = () => {
    return (
        <footer className="assessment-footer">
            <div className="max-width-container">
                <div className="footer-wrapper">
                    <ul className="footer-body-links awsui-list-unstyled awsui-util-t-c">
                        <li>
                            <Link
                                external
                                externalIconAriaLabel="Opens in a new tab"
                                href="https://w.amazon.com/bin/view/AWS_Training_and_Certification/Product_Development/Chiron/Assessments/User_guide/#HAssessmentmanageruserguide"
                            >
                                User guide
                            </Link>
                        </li>
                        <li>
                            <Link
                                external
                                externalIconAriaLabel="Opens in a new tab"
                                href="https://issues.amazon.com/issues/create?template=ae44826f-0f8c-4801-a1ca-095ab8f3d7a4"
                            >
                                Need access?
                            </Link>
                        </li>
                        <li>
                            <Link
                                external
                                externalIconAriaLabel="Opens in a new tab"
                                href="https://issues.amazon.com/issues/create?template=d74eb94c-448d-44ea-bd0e-74fb39910ba2"
                            >
                                Submit feature request
                            </Link>
                        </li>
                    </ul>
                    <div className="footer-body-copyright">
                        <Box variant="p">
                            &copy;{' '}
                            {insertYear(
                                'Amazon Web Services, Inc. or its affiliates. All rights reserved.',
                            )}
                        </Box>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
