export enum AssessmentDetailsEditForm {
    FormId = 'assessment-details-form',
    AssessmentTitleLabel = 'assessment-detail-title-label',
    AssessmentStatusLabel = 'assessment-status-label',
}

export enum AssessmentDetailValueLabel {
    AssessmentTitle = 'assessment-title-value-label',
    AssessmentStatus = 'assessment-status-value-label',
    AssessmentProgram = 'assessment-program-value-label',
    AssessmentId = 'assessment-id-value-label',
    AssessmentLearningObjectives = 'assessment-learning-objectives-value-label',
    AssessmentObjectiveScore = 'assessment-objective-score-value-label',
    AssessmentCopyPaste = 'assessment-copy-paste-value-label',
    AssessmentShowCorrectAnswer = 'assessment-show-correct-answer-value-label',
    AssessmentShowDetailResult = 'assessment-show-detail-result-value-label',
    AssessmentShowFinalScore = 'assessment-show-final-score-value-label',
    AssessmentFlagQuestionEnabled = 'assessment-flag-question-enabled-value-label',
    AssessmentSkipGoBack = 'assessment-skip-value-label',
    AssessmentNotesEnabled = 'assessment-notes-enabled-value-label',
    AssessmentPauseAssessment = 'assessment-pause-value-label',
    AssessmentMaxAttempts = 'assessment-max-attempts-value-label',
    AssessmentPassingScore = 'assessment-passing-score-value-label',
    AssessmentShowSubmittedResponse = 'assessment-show-submitted-response-value-label',
    AssessmentQuestionOrdering = 'assessment-question-ordering-value-label',
    AssessmentScoreDisplay = 'assessment-score-display-value-label',
    AssessmentTimeLimitMinutes = 'assessment-time-limit-minutes-value-label',
    AssessmentTimeLimitHours = 'assessment-time-limit-hours-value-label',
    AssessmentConfidenceLevelPromptEnabledLabel = 'assessment-confidence-level-prompt-enabled-label',
    AssessmentConfidenceLevelShownLabel = 'assessment-confidence-level-shown-label',
}
