import React, { useEffect, useState } from 'react';
import { Container, FormField, Header, SpaceBetween } from '@amzn/awsui-components-react';
import {
    LearningObjectiveSelect,
    LearningObjectiveSelectProps,
    QuestionDifficultySelect,
    QuestionDifficultySelectProps,
    QuestionStatusSelect,
    QuestionStatusSelectProps,
    QuestionTypeSelect,
    QuestionTypeSelectProps,
    ProgramSelect,
    ProgramSelectProps,
} from '../../common/formFields';
import {
    AssessmentMetadataObject,
    AssessmentQuestionInput,
    useGetLearningObjectivesLazyQuery,
} from '../../../graphql';
import {
    AnswerList,
    AnswerListProps,
    QuestionBankAttributeEditor,
    QuestionBankAttributeEditorProps,
} from '../..';
import QuestionTextInput, {
    QuestionTextInputProps,
} from '../../common/formFields/QuestionTextInput';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { Dictionary } from '../../../interfaces/dictionary';
import { QuestionBankAttributeEditorItem } from '../../common/formFields/QuestionBankAttributeEditor';

export interface QuestionFormProps {
    mode: string;
    handleFormValueChange: (formUpdates: Partial<AssessmentQuestionInput>) => void;
    formValues: Partial<AssessmentQuestionInput>;
    errors: any;
}

const QuestionForm = ({ mode, handleFormValueChange, formValues, errors }: QuestionFormProps) => {
    const [learningObjectiveDict, setLearningObjectiveDict] =
        useState<Dictionary<AssessmentMetadataObject>>();
    const [learningObjectiveOptions, setLearningObjectiveOptions] = useState<OptionDefinition[]>(
        [],
    );
    const [questionBankAttributeEditorItems, setQuestionBankAttributeEditorItems] = useState<
        QuestionBankAttributeEditorItem[]
    >([{ id: '' }]);

    const [getLearningObjectives, { data, loading }] = useGetLearningObjectivesLazyQuery({});

    useEffect(() => {
        if (formValues.programs?.length! > 0) {
            getLearningObjectives({
                variables: {
                    status: 'Active',
                    programs: formValues.programs,
                    size: 500,
                },
            });
        }
    }, [formValues.programs]);

    useEffect(() => {
        if (data && !loading) {
            let options: OptionDefinition[] = [];
            let learningObjectiveDict = {};

            data.assessmentLearningObjectives.metadataObjects.forEach((objective) => {
                options.push({
                    value: objective.id,
                    label: objective.name,
                });
                learningObjectiveDict = {
                    ...learningObjectiveDict,
                    [objective.id]: objective,
                };
            });
            setLearningObjectiveDict(learningObjectiveDict);
            setLearningObjectiveOptions(options);
        }
    }, [data, formValues.programs]);

    useEffect(() => {
        if (formValues) {
            const attributeEditorItems = formValues.questionBanks!.map((id) => ({
                id,
            }));
            setQuestionBankAttributeEditorItems(attributeEditorItems);
        }
    }, [formValues.questionBanks]);

    const questionDifficultySelectProps: QuestionDifficultySelectProps = {
        formValues,
        handleFormValueChange,
    };

    const answerListProps: AnswerListProps = {
        formValues,
        handleFormValueChange,
        mode,
    };

    const questionTypeSelectProps: QuestionTypeSelectProps = {
        formValues,
        handleFormValueChange,
    };

    const questionStatusSelectProps: QuestionStatusSelectProps = {
        formValues,
        handleFormValueChange,
    };

    const questionTextInputProps: QuestionTextInputProps = {
        formValues,
        handleFormValueChange,
        errors,
    };

    const learningObjectiveSelectProps: LearningObjectiveSelectProps = {
        formValues,
        handleFormValueChange,
        errors,
        learningObjectiveOptions,
        learningObjectiveDict,
    };

    const programSelectProps: ProgramSelectProps = {
        formValues: formValues,
        handleFormValueChange,
        errors,
    };

    const questionBankAtttributeEditorProps: QuestionBankAttributeEditorProps = {
        errors,
        selectedPrograms: formValues.programs,
        selectedLearningObjective: formValues ? formValues.learningObjectives![0] : '',
        questionBankAttributeEditorItems,
        handleFormValueChange,
        setQuestionBankAttributeEditorItems,
    };

    return (
        <Container header={<Header variant="h2">Question details</Header>}>
            <SpaceBetween direction="vertical" size="l">
                <QuestionTypeSelect {...questionTypeSelectProps} />
                <ProgramSelect {...programSelectProps} />
                <QuestionStatusSelect {...questionStatusSelectProps} />
                {!loading && formValues.programs!.length > 0 && (
                    <LearningObjectiveSelect {...learningObjectiveSelectProps} />
                )}

                {!loading &&
                    formValues.programs!.length > 0 &&
                    formValues.learningObjectives!.length > 0 && (
                        <QuestionBankAttributeEditor {...questionBankAtttributeEditorProps} />
                    )}
                <QuestionTextInput {...questionTextInputProps} />
                <QuestionDifficultySelect {...questionDifficultySelectProps} />
                <FormField stretch>
                    <AnswerList {...answerListProps} />
                </FormField>
            </SpaceBetween>
        </Container>
    );
};

export default QuestionForm;
