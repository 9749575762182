import React, { Dispatch, SetStateAction } from 'react';
import { Container, FormField, Header, Input, SpaceBetween } from '@amzn/awsui-components-react';
import {
    AssessmentLearningObjectiveAttributeEditor,
    AssessmentLearningObjectiveAttributeEditorItem,
    AssessmentLearningObjectiveAttributeEditorItemProps,
} from '../../../../components';
import { AssessmentInput } from '../../../../graphql';
import {
    AssessmentStatusSelect,
    AssessmentStatusSelectProps,
    ProgramSelect,
    ProgramSelectProps,
} from '../../../../components/common/formFields';

export interface WizardAssessmentDetailsProps {
    handleFormValueChange: (formUpdates: Partial<AssessmentInput>) => void;
    formValues: AssessmentInput;
    errors: any;
    controlArrayErrors: any;
    assessmentLearningObjectiveAttributeEditorItems: AssessmentLearningObjectiveAttributeEditorItem[];
    setAssessmentLearningObjectiveAttributeEditorItems: Dispatch<
        SetStateAction<AssessmentLearningObjectiveAttributeEditorItem[]>
    >;
}

const WizardAssessmentDetails = ({
    formValues,
    handleFormValueChange,
    errors,
    assessmentLearningObjectiveAttributeEditorItems,
    setAssessmentLearningObjectiveAttributeEditorItems,
    controlArrayErrors,
}: WizardAssessmentDetailsProps) => {
    const assessmentStatusSelectProps: AssessmentStatusSelectProps = {
        formValues,
        handleFormValueChange,
    };

    const learningObjectiveAttributeEditorProps: AssessmentLearningObjectiveAttributeEditorItemProps =
        {
            assessmentLearningObjectiveAttributeEditorItems,
            setAssessmentLearningObjectiveAttributeEditorItems,
            handleFormValueChange,
            errors,
            controlArrayErrors,
            selectedPrograms: formValues.programs,
        };

    const programSelectProps: ProgramSelectProps = {
        formValues,
        handleFormValueChange,
        errors,
    };

    return (
        <Container header={<Header variant="h2">Assessment details</Header>}>
            <SpaceBetween direction="vertical" size="l">
                <FormField label="Title" errorText={errors.title ?? null}>
                    <Input
                        value={formValues.title ?? ''}
                        onChange={({ detail }) => handleFormValueChange({ title: detail.value })}
                    />
                </FormField>
                <ProgramSelect {...programSelectProps} />
                <AssessmentStatusSelect {...assessmentStatusSelectProps} />
                {formValues.programs && formValues.programs.length > 0 && (
                    <AssessmentLearningObjectiveAttributeEditor
                        {...learningObjectiveAttributeEditorProps}
                    />
                )}
            </SpaceBetween>
        </Container>
    );
};

export default WizardAssessmentDetails;
