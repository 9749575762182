import { PropertyFilter, PropertyFilterProps } from '@amzn/awsui-components-react';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { GetCurrentUserDocument, useGetAssessmentUserLazyQuery } from '../../../graphql';
import { client } from '../../../common/client';
import { I18N_STRINGS } from '../../../common/constants/propertyFilterKeys';
import { MetadataObjectStatus } from '../../../common/constants/metadataObject';

export const LOCAL_STORAGE_LEARNING_OBJECTIVE_FILTER_KEY =
    'assessments-learning-objectives-filter-key';

export interface StoredLearningObjectiveFilters {
    propertyFilters?: any;
}

export interface LearningObjectivesListPropertyFilterProps {
    query: PropertyFilterProps.Query;
    setQuery: Dispatch<SetStateAction<PropertyFilterProps.Query>>;
    handleGetLearningObjectives: (filters: any) => Promise<void>;
}

interface FilterOption {
    propertyKey: string;
    value: string;
}

const LearningObjectivesListPropertyFilter = ({
    query,
    setQuery,
    handleGetLearningObjectives,
}: LearningObjectivesListPropertyFilterProps) => {
    const [programs, setPrograms] = useState<string[]>([]);
    const [filteringOptions, setFilteringOptions] =
        useState<PropertyFilterProps.FilteringOption[]>();
    const [getAssessmentUser, { data: assessmentUserData }] = useGetAssessmentUserLazyQuery();

    useEffect(() => {
        const readCurrentUser = client.readQuery({
            query: GetCurrentUserDocument,
        });

        if (readCurrentUser && readCurrentUser.currentUser) {
            getAssessmentUser({
                variables: {
                    id: readCurrentUser.currentUser.gandalfDetails.vibeId,
                },
            });
        }

        if (assessmentUserData) {
            setPrograms(assessmentUserData.assessmentUser?.programs!);
        }
    }, [assessmentUserData]);

    const learningObjectiveFilterProperties: Array<PropertyFilterProps.FilteringProperty> =
        useMemo(() => {
            return [
                {
                    key: 'searchText',
                    defaultOperator: ':',
                    propertyLabel: 'Name',
                    groupValuesLabel: 'Name value',
                },
                {
                    key: 'status',
                    operators: ['='],
                    propertyLabel: 'Status',
                    groupValuesLabel: 'Status value',
                },
                {
                    key: 'programs',
                    operators: ['='],
                    propertyLabel: 'Programs',
                    groupValuesLabel: 'Program value',
                },
            ];
        }, []);

    const handleFilterUpdate = async (e: any) => {
        setQuery(e.detail);
        const storedFilters = localStorage.getItem(LOCAL_STORAGE_LEARNING_OBJECTIVE_FILTER_KEY);
        let newFilter = {} as StoredLearningObjectiveFilters;
        storedFilters
            ? (newFilter = {
                  ...JSON.parse(storedFilters),
                  propertyFilters: e.detail,
              })
            : (newFilter = { propertyFilters: e.detail });

        localStorage.setItem(
            LOCAL_STORAGE_LEARNING_OBJECTIVE_FILTER_KEY,
            JSON.stringify({ ...newFilter }),
        );
        handleGetLearningObjectives({ propertyFilters: e.detail });
    };

    return (
        <div style={{ flex: 1 }}>
            <PropertyFilter
                onChange={handleFilterUpdate}
                query={query}
                expandToViewport
                filteringOptions={filteringOptions}
                filteringProperties={learningObjectiveFilterProperties}
                disableFreeTextFiltering={true}
                hideOperations
                i18nStrings={I18N_STRINGS}
                filteringLoadingText="Loading..."
                onLoadItems={async () => {
                    const status = Object.keys(MetadataObjectStatus).map(
                        (status) =>
                            MetadataObjectStatus[status as keyof typeof MetadataObjectStatus],
                    );

                    let filteringOptions: FilterOption[] = [];

                    let programOptions = [{ propertyKey: 'programs', value: '' }];
                    if (programs.length > 0) {
                        programOptions = programs.map((program) => ({
                            value: program,
                            propertyKey: 'programs',
                        }));
                    }

                    filteringOptions = [
                        ...filteringOptions,
                        ...status.map((status) => ({
                            propertyKey: 'status',
                            value: status,
                        })),
                        ...programOptions,
                    ];

                    setFilteringOptions(filteringOptions);
                }}
            />
        </div>
    );
};

export default LearningObjectivesListPropertyFilter;
