import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { PropertyFilter, PropertyFilterProps } from '@amzn/awsui-components-react';
import { Dictionary } from '../../../interfaces/dictionary';
import {
    AssessmentMetadataObject,
    GetCurrentUserDocument,
    useGetAssessmentUserLazyQuery,
    useGetLearningObjectivesLazyQuery,
} from '../../../graphql';
import { I18N_STRINGS } from '../../../common/constants/propertyFilterKeys';
import { client } from '../../../common/client';
import { StoredQuestionFilters } from '../../questions/questionsList/QuestionsListPropertyFilter';
import { MetadataObjectStatus } from '../../../common/constants/metadataObject';

export const LOCAL_STORAGE_QUESTION_BANK_FILTER_KEY = 'assessments-question-banks-filter-key';

export interface StoredQuestionBankFilters {
    propertyFilters?: any;
}
export interface QuestionBanksPropertyFilterProps {
    query: PropertyFilterProps.Query;
    setQuery: Dispatch<SetStateAction<PropertyFilterProps.Query>>;
    handleGetQuestionBanks: (filters: any) => Promise<void>;
}

interface FilterOption {
    propertyKey: string;
    value: string;
}

const QuestionsListPropertyFilter = ({
    query,
    setQuery,
    handleGetQuestionBanks,
}: QuestionBanksPropertyFilterProps) => {
    const [programs, setPrograms] = useState<string[]>([]);
    const [filteringOptions, setFilteringOptions] =
        useState<PropertyFilterProps.FilteringOption[]>();
    const [getAssessmentUser, { data: assessmentUserData }] = useGetAssessmentUserLazyQuery();
    const [learningObjectiveDict, setLearningObjectiveDict] =
        useState<Dictionary<AssessmentMetadataObject>>();
    const [
        getLearningObjectives,
        { data: learningObjectiveData, loading: learningObjectiveLoading },
    ] = useGetLearningObjectivesLazyQuery({
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        const readCurrentUser = client.readQuery({
            query: GetCurrentUserDocument,
        });

        if (readCurrentUser && readCurrentUser.currentUser) {
            getAssessmentUser({
                variables: {
                    id: readCurrentUser.currentUser.gandalfDetails.vibeId,
                },
            });
        }

        if (assessmentUserData) {
            setPrograms(assessmentUserData.assessmentUser?.programs!);
        }
    }, [assessmentUserData]);

    const questionsFilterProperties: Array<PropertyFilterProps.FilteringProperty> = useMemo(() => {
        return [
            {
                key: 'searchText',
                defaultOperator: ':',
                propertyLabel: 'Name',
                groupValuesLabel: 'Question text value',
            },
            {
                key: 'status',
                operators: ['='],
                propertyLabel: 'Status',
                groupValuesLabel: 'Question status value',
            },
            {
                key: 'programs',
                operators: ['='],
                propertyLabel: 'Programs',
                groupValuesLabel: 'Program value',
            },
        ];
    }, [learningObjectiveDict]);

    const handleFilterUpdate = async (e: any) => {
        setQuery(e.detail);
        const storedFilters = localStorage.getItem(LOCAL_STORAGE_QUESTION_BANK_FILTER_KEY);
        let newFilter = {} as StoredQuestionFilters;
        storedFilters
            ? (newFilter = {
                  ...JSON.parse(storedFilters),
                  propertyFilters: e.detail,
              })
            : (newFilter = { propertyFilters: e.detail });

        localStorage.setItem(
            LOCAL_STORAGE_QUESTION_BANK_FILTER_KEY,
            JSON.stringify({ ...newFilter }),
        );
        handleGetQuestionBanks({ propertyFilters: e.detail });
    };

    return (
        <div style={{ flex: 1 }}>
            <PropertyFilter
                onChange={handleFilterUpdate}
                query={query}
                expandToViewport
                filteringOptions={filteringOptions}
                filteringProperties={questionsFilterProperties}
                disableFreeTextFiltering={true}
                hideOperations
                i18nStrings={I18N_STRINGS}
                filteringStatusType={learningObjectiveLoading ? 'loading' : 'finished'}
                filteringLoadingText="Loading..."
                onLoadItems={async ({ detail: { filteringProperty } }) => {
                    const status = Object.keys(MetadataObjectStatus).map(
                        (status) =>
                            MetadataObjectStatus[status as keyof typeof MetadataObjectStatus],
                    );
                    let filteringOptions: FilterOption[] = [];

                    let programOptions = [{ propertyKey: 'programs', value: '' }];
                    if (programs.length > 0) {
                        programOptions = programs.map((program) => ({
                            value: program,
                            propertyKey: 'programs',
                        }));
                    }

                    filteringOptions = [
                        ...filteringOptions,
                        ...status.map((status) => ({
                            propertyKey: 'status',
                            value: status,
                        })),
                        ...programOptions,
                    ];

                    setFilteringOptions(filteringOptions);
                }}
            />
        </div>
    );
};

export default QuestionsListPropertyFilter;
