import { Box, Button, Link, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import React, { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { QUESTION_CREATE_GENAI_ROUTE } from '../../router/router';

export interface LegalModalProps {
    setShowLegalModal: Dispatch<SetStateAction<boolean>>;
    showLegalModal: boolean;
}

const LegalModal = ({ setShowLegalModal, showLegalModal }: LegalModalProps) => {
    const navigate = useNavigate();

    return (
        <Modal
            visible={showLegalModal}
            onDismiss={() => setShowLegalModal(false)}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button onClick={() => setShowLegalModal(false)} variant="link">
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => {
                                setShowLegalModal(false);
                                navigate(QUESTION_CREATE_GENAI_ROUTE.path);
                            }}
                        >
                            I understand and wish to proceed
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header="Welcome to [Assessments AI]"
        >
            <Box variant="p">
                <strong>WARNING:</strong> This beta is a proof of concept meant to evaluate certain
                Generative AI tooling, and it should not be used as a substitute for your
                professional judgment or relied on for use cases that require accuracy. It's
                imperative to perform an independent (i.e., without using the Foundational Model or
                any other tool) and human subject matter expert review of any output to confirm the
                accuracy of any statements made in the output.
            </Box>
            <Box variant="p">Your use of Assessments is subject to the following policies:</Box>
            <ul>
                <li>
                    <Link
                        external
                        href="https://pathfinder.legal.amazon.dev/#/page/AWSLegalSalesandMarketing-GenerativeAI-InternalUseofGenerativeAI/live"
                    >
                        Internal Use of Generative AI Policy,
                    </Link>
                </li>
                <li>
                    <Link external href="https://aws.amazon.com/aup/">
                        AWS Acceptable Use Policy,
                    </Link>
                </li>
                <li>
                    <Link
                        external
                        href="https://aws.amazon.com/machine-learning/responsible-ai/policy/"
                    >
                        AWS Responsible AI Policy,
                    </Link>
                </li>
                <li>
                    <Link
                        external
                        href="https://wisdom.corp.amazon.com/Pages/Activity_Private-Information-Protocols.aspx"
                    >
                        Private Information Protocols,
                    </Link>
                </li>
                <li>
                    <Link external href="https://policy.a2z.com/docs/42682/publication">
                        AWS Data Handling Standard,
                    </Link>
                </li>
                <li>
                    <Link
                        external
                        href="https://policy.a2z.com/?code=958d9c7c-fa43-447a-a9f9-c280e1ba49e6"
                    >
                        AWS Data Classification and Handling Policy, and
                    </Link>
                </li>
                <li>
                    <Link
                        external
                        href="https://inside.amazon.com/en/services/legal/us/confidentialityandnda/Pages/ConfidentialInformationandNDAGuidelinesandPolicy.aspx"
                    >
                        Confidential Information and NDA Guidelines and Policy.
                    </Link>
                </li>
            </ul>
            <Box variant="p">
                Please affirm that you have reviewed these policies in order to proceed.
            </Box>
        </Modal>
    );
};

export default LegalModal;
