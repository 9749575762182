import { createAuthUrl, AuthEndpoints, CurrentUser, getCurrentHost } from './endpointUtils';

export async function decodeToken(): Promise<CurrentUser> {
    const response: Response = await fetch(
        createAuthUrl(getCurrentHost(), AuthEndpoints.DECODE_TOKEN),
    );
    if (!response.ok) {
        throw new Error(`Failed to fetch current user: status code[${response.status}]`);
    }

    return (await response.json()) as CurrentUser;
}
