import {
    Box,
    Header,
    Link,
    Pagination,
    PropertyFilterProps,
    Table,
} from '@amzn/awsui-components-react';
import React, { useEffect, useMemo, useState } from 'react';
import QuestionsListPropertyFilter, {
    LOCAL_STORAGE_ADD_QUESTION_TO_QUESTION_BANK_FILTER_KEY,
    QuestionsListPropertyFilterProps,
    StoredQuestionFilters,
} from '../../pages/questions/questionsList/QuestionsListPropertyFilter';
import { AssessmentQuestion } from '../../graphql';
import { useQuestions } from '../../hooks/useQuestion';
import { useNavigate } from 'react-router-dom';

const EmbeddedQuestionsTable = ({ questionBankIds }: { questionBankIds?: string[] }) => {
    const navigate = useNavigate();

    const [query, setQuery] = useState<PropertyFilterProps.Query>({
        tokens: [],
        operation: 'and',
    });
    const {
        questions,
        learningObjectiveDict,
        getQuestions,
        handlePaginationChange,
        currentPageIndex,
        pagesCount,
        isLoading,
        getQuestionsByPropertyFilter,
        currentCount,
    } = useQuestions();
    useEffect(() => {
        const storedFilterString = localStorage.getItem(
            LOCAL_STORAGE_ADD_QUESTION_TO_QUESTION_BANK_FILTER_KEY,
        );

        if (storedFilterString) {
            try {
                let savedFilters = JSON.parse(storedFilterString) as StoredQuestionFilters;
                try {
                    if (savedFilters.propertyFilters) {
                        setQuery(savedFilters.propertyFilters);
                    }
                    getQuestionsByPropertyFilter(savedFilters);
                } catch (e) {}
            } catch (e) {}
        } else {
            getQuestions({ questionBanks: questionBankIds });
        }
    }, []);

    const columnDefinitions = useMemo(() => {
        const cols = [
            {
                id: 'questionText',
                header: 'Question Text',
                cell: (item: AssessmentQuestion) => (
                    <Link
                        target="_blank"
                        variant="secondary"
                        href={`/questions/${item.id}/version/${item.version}`}
                        onFollow={(e) => {
                            e.preventDefault();
                            navigate(`/questions/${item.id}/version/${item.version}`, {
                                state: { ...item },
                            });
                        }}
                    >
                        {item.questionText}
                    </Link>
                ),
            },
            {
                id: 'learningObjective',
                header: 'Learning Objective',
                cell: (item: AssessmentQuestion) =>
                    //@ts-ignore - Ignoring to avoid complicating typing - This is the whole LO meta object
                    item.learningObjectives![0] ? item.learningObjectives![0].name : '',
            },
            {
                id: 'status',
                header: 'Status',
                cell: (item: AssessmentQuestion) => item.status,
                minWidth: 200,
            },
            {
                id: 'type',
                header: 'Type',
                cell: (item: AssessmentQuestion) => item.type,
            },
            {
                id: 'programs',
                header: 'Programs',
                cell: (item: AssessmentQuestion) => item.programs?.join(', '),
            },
            {
                id: 'difficulty',
                header: 'Difficulty',
                cell: (item: AssessmentQuestion) => item.difficulty,
            },
            {
                id: 'scoringMethod',
                header: 'Scoring Method',
                cell: (item: AssessmentQuestion) => item.scoringMethod,
            },
        ];
        return cols;
    }, [learningObjectiveDict]);

    const questionsListPropertyFilterProps: QuestionsListPropertyFilterProps = {
        query,
        setQuery,
        getQuestionsByPropertyFilter,
    };
    return (
        <Table
            header={<Header counter={`(${currentCount})`}>Questions</Header>}
            variant="embedded"
            items={questions}
            columnDefinitions={columnDefinitions}
            loading={isLoading}
            loadingText="Loading questions"
            enableKeyboardNavigation
            filter={<QuestionsListPropertyFilter {...questionsListPropertyFilterProps} />}
            pagination={
                <Pagination
                    currentPageIndex={currentPageIndex}
                    pagesCount={pagesCount}
                    onChange={handlePaginationChange}
                />
            }
            empty={
                <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
                    No questions found.
                </Box>
            }
        />
    );
};

export default EmbeddedQuestionsTable;
