export const convertHoursToMilliSeconds = (hours: number) => {
    return Math.floor(hours * 60 * 60 * 1000);
};

export const convertMinutesToMilliSeconds = (minutes: number) => {
    return Math.floor(minutes * 60 * 1000);
};

export const convertMilliSecondsToHours = (milliseconds: number) => {
    return Math.floor(milliseconds / 3600000);
};

export const convertMilliSecondsToMinutes = (milliseconds: number) => {
    return Math.floor(milliseconds / 60000);
};
