import { FormField, Select } from '@amzn/awsui-components-react';
import React from 'react';
import { handleSelectedOption } from '../../../utils/formUtils';
import { AssessmentQuestionInput } from '../../../graphql';
import { QuestionStatus } from '../../../common/constants/questions';

export interface QuestionStatusSelectProps {
    formValues: Partial<AssessmentQuestionInput>;
    handleFormValueChange: (formUpdates: Partial<AssessmentQuestionInput>) => void;
}

const QuestionStatusSelect = ({ formValues, handleFormValueChange }: QuestionStatusSelectProps) => {
    const questionStatusOptions = Object.keys(QuestionStatus).map((prop) => ({
        value: QuestionStatus[prop as keyof typeof QuestionStatus],
        label: QuestionStatus[prop as keyof typeof QuestionStatus],
    }));

    return (
        <FormField label="Status">
            <Select
                selectedOption={handleSelectedOption({
                    selectedOption: formValues.status ?? QuestionStatus.DRAFT,
                    options: questionStatusOptions,
                })}
                onChange={(e) => {
                    handleFormValueChange({
                        status: e.detail.selectedOption.value! as QuestionStatus,
                    });
                }}
                options={questionStatusOptions}
            />
        </FormField>
    );
};

export default QuestionStatusSelect;
