import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setBreadcrumbs, setContentType } from '../../reducers/navigationReducer';
import { QUESTIONS_LIST_ROUTE, QUESTION_CREATE_ROUTE } from '../../router/router';
import QuestionForm from '../../components/questions/forms/QuestionForm';
import { Button, Form, Header, SpaceBetween } from '@amzn/awsui-components-react';
import {
    initialFormValues,
    QUESTION_WITH_MULTIPLE_CORRECT_ANSWERS,
    QUESTION_WITHOUT_ANSWERS_ERROR,
} from '../../common/constants/questions';
import { AssessmentQuestionInput, useCreateAssessmentQuestionMutation } from '../../graphql';
import { useNotifications } from '../../context/NotificationsProvider';
import { useNavigate } from 'react-router-dom';
import { QUESTION_INPUT_VALIDATION_FIELDS } from '../../common/constants/validations';
import useFormValidation from '../../hooks/useFormValidation';

const QuestionCreate = () => {
    const dispatch = useDispatch();
    const [formValues, setFormValues] = useState(initialFormValues);
    const [createQuestion, { loading }] = useCreateAssessmentQuestionMutation();
    const { addNotification } = useNotifications();
    const navigate = useNavigate();
    const { isInvalid, validateForm, errors } =
        useFormValidation<Partial<AssessmentQuestionInput>>();
    const runInputValidations = () => {
        return validateForm(formValues!, {
            required: QUESTION_INPUT_VALIDATION_FIELDS.REQUIRED,
        });
    };

    useEffect(() => {
        dispatch(
            setBreadcrumbs([
                {
                    text: QUESTIONS_LIST_ROUTE.title,
                    href: QUESTIONS_LIST_ROUTE.path,
                },
                {
                    text: QUESTION_CREATE_ROUTE.title,
                    href: QUESTION_CREATE_ROUTE.path,
                },
            ]),
        );
        dispatch(setContentType('form'));
    }, [dispatch]);

    useEffect(() => {
        if (isInvalid) {
            runInputValidations();
        }
    }, [formValues, isInvalid, validateForm]);

    const handleFormValueChange = (formUpdates: Partial<AssessmentQuestionInput>) => {
        setFormValues({ ...formValues, ...formUpdates });
    };

    const handleCreateQuestion = async () => {
        let invalid = runInputValidations();
        // TODO More clever validations checker will likely be required
        // Answer less than 2
        if (formValues.answers.length < 2) {
            invalid = true;
            addNotification({
                id: `create-question-${Date.now()}`,
                type: 'error',
                content: QUESTION_WITHOUT_ANSWERS_ERROR,
            });
        }

        // Correct answer is greater than 1
        const answersWithSingleIsCorrect = formValues.answers.filter((answer) => answer.isCorrect);
        if (answersWithSingleIsCorrect.length > 1) {
            invalid = true;
            addNotification({
                id: `create-question-${Date.now()}-multiple-correct-answers`,
                type: 'error',
                content: 'Multiple choice questions can only have 1 correct answer.',
            });
        }

        // No correct answer
        const answerMustHaveCorrect = formValues.answers.filter((answer) => answer.isCorrect);
        if (answerMustHaveCorrect.length === 0) {
            invalid = true;
            addNotification({
                id: `create-question-${Date.now()}-no-correct-answers`,
                type: 'error',
                content: 'Multiple choice questions must have 1 correct answer.',
            });
        }

        const correctAnswers = formValues.answers!.filter((answer) => answer.isCorrect);
        if (correctAnswers.length > 1) {
            invalid = true;
            addNotification({
                id: `edit-question-${Date.now()}`,
                type: 'error',
                content: QUESTION_WITH_MULTIPLE_CORRECT_ANSWERS,
            });
        }

        if (invalid) {
            return;
        }

        try {
            const { data } = await createQuestion({
                variables: {
                    ...formValues,
                },
            });

            addNotification({
                id: `create-question-${Date.now()}`,
                ...(data?.createAssessmentQuestion
                    ? {
                          type: 'success',
                          content: 'Question created successfully.',
                      }
                    : {
                          type: 'error',
                          content: 'There was an error creating the question.',
                      }),
            });

            if (data?.createAssessmentQuestion) {
                const question = data.createAssessmentQuestion;
                navigate(`/questions/${question?.id}/version/${question?.version}`, {
                    state: { ...question },
                });
            }
        } catch (error) {
            addNotification({
                id: `error-create-question-${Date.now()}`,
                type: 'error',
                content: 'There was an error creating the question.',
            });
        }
    };

    const createFormProps = {
        handleFormValueChange,
        formValues,
        mode: 'create',
        errors,
    };

    return (
        <Form
            actions={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button onClick={() => navigate('/questions')} formAction="none" variant="link">
                        Cancel
                    </Button>
                    <Button onClick={handleCreateQuestion} disabled={loading} variant="primary">
                        {loading ? 'Saving' : 'Save'}
                    </Button>
                </SpaceBetween>
            }
            header={<Header variant="h1">Create Question</Header>}
        >
            <QuestionForm {...createFormProps} />
        </Form>
    );
};

export default QuestionCreate;
