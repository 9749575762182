import React from 'react';
import { FormField, Select } from '@amzn/awsui-components-react';
import { handleSelectedOption } from '../../../utils/formUtils';
import { AssessmentQuestionInput } from '../../../graphql';
import { QuestionDifficulty } from '../../../common/constants/questions';
import { QuestionIds } from '../../../common/dataTestIds/question';

export interface QuestionDifficultySelectProps {
    formValues: Partial<AssessmentQuestionInput>;
    handleFormValueChange: (formUpdates: Partial<AssessmentQuestionInput>) => void;
}
const QuestionDifficultySelect = ({
    formValues,
    handleFormValueChange,
}: QuestionDifficultySelectProps) => {
    const difficultyOptionValues = Object.keys(QuestionDifficulty).filter((v) => !isNaN(Number(v)));
    const difficultyOptions = difficultyOptionValues.map((difficulty) => ({
        value: difficulty,
        label: difficulty,
    }));

    return (
        <FormField data-testid={QuestionIds.QuestionDifficultyLabel} label="Difficulty level">
            <Select
                selectedOption={handleSelectedOption({
                    selectedOption: formValues.difficulty!.toString(),
                    options: difficultyOptions,
                })}
                onChange={(e) => {
                    handleFormValueChange({
                        difficulty: parseInt(e.detail.selectedOption.value!),
                    });
                }}
                options={difficultyOptions}
            />
        </FormField>
    );
};

export default QuestionDifficultySelect;
