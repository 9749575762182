import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { getClientEndpoint } from './constants/application';

const httpLink = createHttpLink({
    uri: getClientEndpoint(),
    credentials: 'include',
    preserveHeaderCase: true,
});

export const client = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache(),
});
