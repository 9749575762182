import React from 'react';
import { FormField, Select } from '@amzn/awsui-components-react';
import { handleSelectedOption } from '../../../utils/formUtils';
import {
    MetadataMutationVariables,
    MetadataObjectStatus,
} from '../../../common/constants/metadataObject';

export interface MetadataObjectStatusSelectProps {
    formValues: Partial<MetadataMutationVariables>;
    handleFormValueChange: (formUpdates: Partial<MetadataMutationVariables>) => void;
}

const MetadataObjectStatusSelect = ({
    formValues,
    handleFormValueChange,
}: MetadataObjectStatusSelectProps) => {
    const questionStatusOptionValues = Object.keys(MetadataObjectStatus).map(
        (status) => MetadataObjectStatus[status as keyof typeof MetadataObjectStatus],
    );
    const questionStatusOptions = questionStatusOptionValues.map((status) => ({
        value: status,
        label: status,
    }));

    return (
        <FormField label="Status">
            <Select
                selectedOption={handleSelectedOption({
                    selectedOption: formValues.status ?? MetadataObjectStatus.ACTIVE,
                    options: questionStatusOptions,
                })}
                onChange={(e) => {
                    handleFormValueChange({
                        status: e.detail.selectedOption.value! as MetadataObjectStatus,
                    });
                }}
                options={questionStatusOptions}
            />
        </FormField>
    );
};

export default MetadataObjectStatusSelect;
