import { getApplicationURL } from '../utils/appUtils';

export interface GandalfProps {
    identity_provider: string;
    require_email_verification: 'true' | 'false';
}

export enum AuthEndpoint {
    SIGN_IN = 'signIn',
    SIGN_OUT = 'signOut',
    REFRESH_TOKEN = 'refreshToken',
}

export const addParamAndEndpointToBuildFullAuthURL = (
    endpoint: AuthEndpoint,
    gandalfProps?: GandalfProps,
): string => {
    const applicationURL = getApplicationURL();
    const urlWithPath = new URL(`${applicationURL}/${endpoint}`);
    urlWithPath.searchParams.append('redirect_uri', applicationURL);
    if (gandalfProps) {
        urlWithPath.searchParams.append('identity_provider', gandalfProps.identity_provider);
        urlWithPath.searchParams.append(
            'require_email_verification',
            gandalfProps.require_email_verification,
        );
    }
    return urlWithPath.toString();
};

export const getSignInURL = (gandalfProps?: GandalfProps) => {
    return addParamAndEndpointToBuildFullAuthURL(AuthEndpoint.SIGN_IN, gandalfProps);
};

export const signOut = async (): Promise<Response> => {
    const signOutURL = addParamAndEndpointToBuildFullAuthURL(AuthEndpoint.SIGN_OUT);
    const signOutResponse = await fetch(signOutURL, {
        credentials: 'same-origin',
    });
    return signOutResponse;
};

export const refreshToken = async (): Promise<Response> => {
    try {
        const refreshTokenURL = addParamAndEndpointToBuildFullAuthURL(AuthEndpoint.REFRESH_TOKEN);
        const refreshTokenResponse = await fetch(refreshTokenURL, { method: 'POST' });
        return refreshTokenResponse;
    } catch (err) {
        throw Error('Error refreshing token');
    }
};
