import { BreadcrumbGroup } from '@amzn/awsui-components-react';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectBreadcrumbs } from '../../reducers/navigationReducer';

const Breadcrumbs = () => {
    const navigate = useNavigate();
    const breadcrumbs = useSelector(selectBreadcrumbs);
    return (
        <BreadcrumbGroup
            items={breadcrumbs}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
            onFollow={(e) => {
                e.preventDefault();
                navigate(e.detail.href);
            }}
        />
    );
};

export default Breadcrumbs;
