import React, { useEffect, useState } from 'react';
import { Button, Form, Header, SpaceBetween } from '@amzn/awsui-components-react';
import {
    AssessmentUpdateInput,
    useGetAssessmentQuery,
    useUpdateAssessmentMutation,
} from '../../../graphql';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ASSESSMENT_EDIT_DETAILS_ROUTE, ASSESSMENT_LIST_ROUTE } from '../../../router/router';
import { setBreadcrumbs, setContentType } from '../../../reducers/navigationReducer';
import { useNotifications } from '../../../context/NotificationsProvider';
import { initialAssessmentFormValues } from '../../../common/constants/assessments';
import { convertHoursToMilliSeconds, convertMinutesToMilliSeconds } from '../../../utils/timeUtils';
import { useForm } from 'react-hook-form';
import {
    AssessmentSettingsFieldNames,
    AssessmentSettingsFormFields,
    AssessmentSettingsFormFieldsProp,
} from '../../../components';

const AssessmentEditSettings = () => {
    const { state } = useLocation();
    const { id = '', version } = useParams();
    const dispatch = useDispatch();
    const { addNotification } = useNotifications();
    const navigate = useNavigate();
    const [updateAssessment, { loading: updatingAssessment }] = useUpdateAssessmentMutation();

    const [formValues, setFormValues] = useState<Partial<AssessmentUpdateInput>>(
        state ?? initialAssessmentFormValues,
    );

    const { data, loading } = useGetAssessmentQuery({
        variables: {
            id,
            version: Number(version),
        },
        fetchPolicy: 'cache-first',
    });

    const form = useForm<Partial<AssessmentUpdateInput>>({
        mode: 'onChange',
        shouldFocusError: true,
        defaultValues: formValues,
    });

    const watchWaitTime = form.watch(AssessmentSettingsFieldNames.WAIT_TIME);
    const watchTimeLimit = form.watch(AssessmentSettingsFieldNames.TIME_LIMIT);

    useEffect(() => {
        if (!loading && data) {
            const assessment = data.assessment!;
            setFormValues(assessment);
        }
    }, [loading, data]);

    useEffect(() => {
        dispatch(
            setBreadcrumbs([
                {
                    text: ASSESSMENT_LIST_ROUTE.title,
                    href: ASSESSMENT_LIST_ROUTE.path,
                },
                {
                    text: ASSESSMENT_EDIT_DETAILS_ROUTE.title,
                    href: ASSESSMENT_EDIT_DETAILS_ROUTE.path,
                },
            ]),
        );
        dispatch(setContentType('form'));
    }, [dispatch]);

    const handleFormValueChange = (formUpdates: Partial<AssessmentUpdateInput>) => {
        setFormValues({ ...formValues, ...formUpdates });
    };

    const handleEditAssessment = async () => {
        if (!(Object.values(form.formState.errors).length >= 1)) {
            // update assessment only when form validation errors does not exist
            const {
                id,
                version,
                title,
                status,
                passingScore,
                maxAttempts,
                displaySetting,
                programs,
                questions,
                learningObjectives,
                completionMessages,
                isCorrectAnswersShown,
                isDetailedResultsEnabled,
                isSubmittedResponsesShown,
                questionOrdering,
                scoreDisplay,
                isCategoryScoreEnabled,
                isCopyPasteEnabled,
                isFinalScoreEnabled,
                isFlaggingEnabled,
                isNextBackEnabled,
                isNotesEnabled,
                isPausingEnabled,
            } = formValues;
            try {
                const { data } = await updateAssessment({
                    variables: {
                        id: id!,
                        version: version!,
                        title: title!,
                        status: status!,
                        passingScore: passingScore || 0,
                        maxAttempts: maxAttempts!,
                        waitTime: convertHoursToMilliSeconds(watchWaitTime || 0),
                        timeLimit: convertMinutesToMilliSeconds(watchTimeLimit || 0),
                        displaySetting: displaySetting!,
                        programs: programs!,
                        questions: questions!.map(({ id, learningObjectives }) => ({
                            id,
                            learningObjectives,
                        })),
                        learningObjectives: learningObjectives!.map(({ id, numQuestions }) => ({
                            id,
                            numQuestions,
                        })),
                        completionMessages: completionMessages!.map(
                            ({ maxScore, minScore, message }) => ({ maxScore, minScore, message }),
                        ),
                        isCorrectAnswersShown: isCorrectAnswersShown!,
                        isDetailedResultsEnabled: isDetailedResultsEnabled!,
                        isSubmittedResponsesShown: isSubmittedResponsesShown!,
                        questionOrdering: questionOrdering!,
                        scoreDisplay: scoreDisplay!,
                        isCategoryScoreEnabled: isCategoryScoreEnabled!,
                        isCopyPasteEnabled: isCopyPasteEnabled!,
                        isFinalScoreEnabled: isFinalScoreEnabled!,
                        isFlaggingEnabled: isFlaggingEnabled!,
                        isNextBackEnabled: isNextBackEnabled!,
                        isNotesEnabled: isNotesEnabled!,
                        isPausingEnabled: isPausingEnabled!,
                    },
                });
                addNotification({
                    id: `update-assessment-${Date.now()}`,
                    ...(data?.updateAssessment
                        ? {
                              type: 'success',
                              content: 'Assessment updated successfully.',
                          }
                        : {
                              type: 'error',
                              content: 'There was an error updating the assessment.',
                          }),
                });
                if (data?.updateAssessment) {
                    const assessment = data.updateAssessment;
                    navigate(`/assessments/${assessment?.id}/version/${assessment?.version}`, {
                        state: { ...assessment },
                    });
                }
            } catch (error) {}
        }
    };

    const assessmentSettingsFormFieldProps: AssessmentSettingsFormFieldsProp = {
        handleFormValueChange,
        formValues,
        form,
    };

    return (
        <Form
            actions={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button onClick={() => navigate(-1)} formAction="none" variant="link">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleEditAssessment}
                        disabled={updatingAssessment}
                        variant="primary"
                    >
                        {updatingAssessment ? 'Saving' : 'Save'}
                    </Button>
                </SpaceBetween>
            }
            header={<Header variant="h1">Edit Assessment</Header>}
        >
            <AssessmentSettingsFormFields {...assessmentSettingsFormFieldProps} />
        </Form>
    );
};

export default AssessmentEditSettings;
