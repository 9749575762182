import { FormField, Textarea } from '@amzn/awsui-components-react';
import React from 'react';
import { AssessmentQuestionInput } from '../../../graphql';
import { QuestionIds } from '../../../common/dataTestIds/question';

export interface QuestionTextInputProps {
    formValues: Partial<AssessmentQuestionInput>;
    handleFormValueChange: (values: any) => void;
    errors?: any;
}

const QuestionTextInput = ({
    formValues,
    handleFormValueChange,
    errors = {
        questionText: undefined,
    },
}: QuestionTextInputProps) => {
    return (
        <FormField
            data-testid={QuestionIds.QuestionTextLabel}
            label="Question"
            errorText={errors.questionText ?? null}
        >
            <Textarea
                onChange={({ detail }) => handleFormValueChange({ questionText: detail.value })}
                value={formValues.questionText!}
                placeholder="Enter question text"
            />
        </FormField>
    );
};

export default QuestionTextInput;
