export interface CurrentUser {
    tokenExpiration: number;
    userAttributes: { [key: string]: any };
}

export const AuthEndpoints = {
    SIGN_IN: 'signIn',
    SIGN_OUT: 'signOut',
    REFRESH_TOKEN: 'refreshToken',
    DECODE_TOKEN: 'decodeToken',
} as const;

export function createAuthUrl(
    currentHost: string,
    endpoint: (typeof AuthEndpoints)[keyof typeof AuthEndpoints],
    redirectUri?: string,
): string {
    const urlWithPath = new URL(`${currentHost}/${endpoint}`);
    urlWithPath.searchParams.append('redirect_uri', redirectUri ?? currentHost);
    return urlWithPath.toString();
}

export function getCurrentHost() {
    return `${window.location.protocol}//${window.location.host}`;
}
