import React from 'react';
import { Assessment, AssessmentInput } from '../../graphql';
import { ColumnLayout } from '@amzn/awsui-components-react';
import ValueWithLabel from '../common/ValueWithLabel';
import { AssessmentDetailValueLabel } from '../../common/dataTestIds/assessmentDetails';
import { FeatureFlags } from '../../common/featureFlags';
import { convertMilliSecondsToHours, convertMilliSecondsToMinutes } from '../../utils/timeUtils';

interface AssessmentSettingsReviewProps {
    assessment: Assessment | Partial<AssessmentInput> | undefined;
}

export const AssessmentSettingsReview = (props: AssessmentSettingsReviewProps) => {
    const { assessment } = props;
    return (
        <ColumnLayout columns={3} variant="text-grid">
            <ValueWithLabel
                testId={AssessmentDetailValueLabel.AssessmentShowCorrectAnswer}
                label="Show correct answers"
            >
                {assessment?.isCorrectAnswersShown}
            </ValueWithLabel>

            <ValueWithLabel
                testId={AssessmentDetailValueLabel.AssessmentShowDetailResult}
                label="Show rationales"
            >
                {assessment?.isDetailedResultsEnabled}
            </ValueWithLabel>

            <ValueWithLabel
                testId={AssessmentDetailValueLabel.AssessmentShowSubmittedResponse}
                label="Show submitted response"
            >
                {assessment?.isSubmittedResponsesShown}
            </ValueWithLabel>

            <ValueWithLabel
                testId={AssessmentDetailValueLabel.AssessmentShowFinalScore}
                label="Show final score"
            >
                {assessment?.isFinalScoreEnabled}
            </ValueWithLabel>

            {FeatureFlags.confidenceLevelSettings.isEnabled() && (
                <ValueWithLabel
                    testId={AssessmentDetailValueLabel.AssessmentConfidenceLevelShownLabel}
                    label="Confidence Level Responses Shown"
                >
                    {assessment?.isConfidenceLevelShown}
                </ValueWithLabel>
            )}

            <ValueWithLabel
                testId={AssessmentDetailValueLabel.AssessmentQuestionOrdering}
                label="Question ordering"
            >
                {assessment?.questionOrdering}
            </ValueWithLabel>

            <ValueWithLabel
                testId={AssessmentDetailValueLabel.AssessmentObjectiveScore}
                label="Objective score enabled"
            >
                {assessment?.isCategoryScoreEnabled}
            </ValueWithLabel>

            <ValueWithLabel
                testId={AssessmentDetailValueLabel.AssessmentFlagQuestionEnabled}
                label="Flagging questions enabled"
            >
                {assessment?.isFlaggingEnabled}
            </ValueWithLabel>

            {FeatureFlags.confidenceLevelSettings.isEnabled() && (
                <ValueWithLabel
                    testId={AssessmentDetailValueLabel.AssessmentConfidenceLevelPromptEnabledLabel}
                    label="Confidence Level Prompts Enabled"
                >
                    {assessment?.isConfidenceLevelPromptEnabled}
                </ValueWithLabel>
            )}

            <ValueWithLabel
                testId={AssessmentDetailValueLabel.AssessmentPauseAssessment}
                label="Pause Assessment"
            >
                {assessment?.isPausingEnabled}
            </ValueWithLabel>

            <ValueWithLabel
                testId={AssessmentDetailValueLabel.AssessmentMaxAttempts}
                label="Maximum number of attempts"
            >
                {assessment?.maxAttempts}
            </ValueWithLabel>

            <ValueWithLabel
                testId={AssessmentDetailValueLabel.AssessmentPassingScore}
                label="Passing score"
            >
                {assessment?.passingScore}
            </ValueWithLabel>

            <ValueWithLabel
                testId={AssessmentDetailValueLabel.AssessmentTimeLimitMinutes}
                label="Time limit (in minutes)"
            >
                {convertMilliSecondsToMinutes(assessment?.timeLimit || 0)}
            </ValueWithLabel>

            <ValueWithLabel
                testId={AssessmentDetailValueLabel.AssessmentTimeLimitHours}
                label="Wait time (in hours)"
            >
                {convertMilliSecondsToHours(assessment?.waitTime || 0)}
            </ValueWithLabel>

            {FeatureFlags.showBackAndNextButtonSetting.isEnabled() && (
                <ValueWithLabel
                    testId={AssessmentDetailValueLabel.AssessmentSkipGoBack}
                    label="Skip and go back"
                >
                    {assessment?.isNextBackEnabled}
                </ValueWithLabel>
            )}

            {FeatureFlags.notesSetting.isEnabled() && (
                <ValueWithLabel
                    testId={AssessmentDetailValueLabel.AssessmentNotesEnabled}
                    label="Notes"
                >
                    {assessment?.isNotesEnabled}
                </ValueWithLabel>
            )}

            {FeatureFlags.scoreDisplaySetting.isEnabled() && (
                <ValueWithLabel
                    testId={AssessmentDetailValueLabel.AssessmentScoreDisplay}
                    label="Score display"
                >
                    {assessment?.scoreDisplay}
                </ValueWithLabel>
            )}

            {FeatureFlags.copyPasteSetting.isEnabled() && (
                <ValueWithLabel
                    testId={AssessmentDetailValueLabel.AssessmentCopyPaste}
                    label="Copy paste"
                >
                    {assessment?.isCopyPasteEnabled}
                </ValueWithLabel>
            )}
        </ColumnLayout>
    );
};
