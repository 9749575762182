import { AppLayoutProps } from '@amzn/awsui-components-react';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store/store';
import { DASHBOARD_ROUTE } from '../router/router';

interface NavigationStateProps {
    breadcrumbs: Array<any>;
    contentType: AppLayoutProps.ContentType | undefined;
    isLoaded: boolean;
}

export const navigationSlice = createSlice({
    name: 'navigation',
    initialState: {
        breadcrumbs: [],
        contentType: 'default',
        isLoaded: false,
    } as NavigationStateProps,
    reducers: {
        setBreadcrumbs: (state, newBreadcrumbs) => {
            state.isLoaded = false;
            state.breadcrumbs = [
                {
                    text: 'Assessment Manager',
                    href: DASHBOARD_ROUTE.path,
                },
                ...newBreadcrumbs.payload,
            ];
            state.isLoaded = true;
        },
        setContentType: (state, newContentType) => {
            state.contentType = newContentType.payload as AppLayoutProps.ContentType | undefined;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setBreadcrumbs, setContentType } = navigationSlice.actions;

export const selectBreadcrumbs = (state: RootState) => state.breadcrumbs.breadcrumbs;
export const selectBreadcrumbsIsLoaded = (state: RootState) => state.breadcrumbs.isLoaded;
export const selectContentType = (state: RootState) => state.navigation.contentType;

export default navigationSlice.reducer;
