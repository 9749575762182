export const convertHoursToMilliSeconds = (hours: number) => {
    return Math.floor(hours * 60 * 60 * 1000);
};

export const convertMinutesToMilliSeconds = (minutes: number) => {
    return Math.floor(minutes * 60 * 1000);
};

export const convertMilliSecondsToHours = (milliseconds: number) => {
    return Math.floor(milliseconds / 3600000);
};

export const convertMilliSecondsToMinutes = (milliseconds: number) => {
    return Math.floor(milliseconds / 60000);
};

// get display value for item modified timestamp
export const getTimestampValue = (timestamp: number): string => {
    try {
        // get date/time for user's locale
        const dateTime = new Date(timestamp).toLocaleString();

        // get short timezone name for user's locale
        const formatted = new Intl.DateTimeFormat('en-US', { timeZoneName: 'short' }).formatToParts(
            timestamp,
        );
        const timezonePart = formatted.find((part) => part.type === 'timeZoneName');
        const timezone = timezonePart ? timezonePart.value : '';

        return `${dateTime} ${timezone}`;
    } catch (error) {
        // TODO: Report error when we have the mechanism to do so.
        return 'Not Available';
    }
};
