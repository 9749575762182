import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  AWSDateTime: { input: string; output: string; }
  AWSEmail: { input: string; output: string; }
  AWSJSON: { input: string; output: string; }
  AWSPhone: { input: string; output: string; }
  AWSTimestamp: { input: number; output: number; }
  AWSURL: { input: string; output: string; }
  join__FieldSet: { input: any; output: any; }
  link__Import: { input: any; output: any; }
};

export type AwsAccountInfo = {
  __typename?: 'AWSAccountInfo';
  accountId: Scalars['String']['output'];
  awsAssociation: AwsAssociation;
  billingAddress: AwsAddress;
  name: Scalars['String']['output'];
  status?: Maybe<AccountStatus>;
};

export type AwsAddress = {
  __typename?: 'AWSAddress';
  addressLineOne: Scalars['String']['output'];
  addressLineTwo?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  countryCode: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['AWSPhone']['output']>;
  stateOrProvince?: Maybe<Scalars['String']['output']>;
  zipCode: Scalars['String']['output'];
};

export enum AwsAssociation {
  AwsPartner = 'AWS_PARTNER',
  None = 'NONE'
}

export type AwsPartnerNetworkAttributeInput = {
  companyId?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  competencies?: InputMaybe<Scalars['String']['input']>;
  partnerPathDetails?: InputMaybe<Scalars['String']['input']>;
  partnerTier?: InputMaybe<Scalars['String']['input']>;
  partnerType?: InputMaybe<Scalars['String']['input']>;
  programs?: InputMaybe<Scalars['String']['input']>;
  services?: InputMaybe<Scalars['String']['input']>;
};

export type AwsPartnerNetworkAttributes = {
  __typename?: 'AWSPartnerNetworkAttributes';
  companyId?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  competencies?: Maybe<Scalars['String']['output']>;
  partnerPathDetails?: Maybe<Scalars['String']['output']>;
  partnerTier?: Maybe<Scalars['String']['output']>;
  partnerType?: Maybe<Scalars['String']['output']>;
  programs?: Maybe<Scalars['String']['output']>;
  services?: Maybe<Scalars['String']['output']>;
};

export type AbsoluteScheduleConfiguration = {
  __typename?: 'AbsoluteScheduleConfiguration';
  installments: Array<Installment>;
  recurrence?: Maybe<OfferBillingPeriod>;
};

export enum AccessType {
  Authorized = 'AUTHORIZED',
  NotAuthB2BLicenseAvailable = 'NOT_AUTH_B2B_LICENSE_AVAILABLE',
  NotAuthB2BNoLicense = 'NOT_AUTH_B2B_NO_LICENSE',
  NotAuthB2I = 'NOT_AUTH_B2I'
}

export type AccessTypeResult = {
  __typename?: 'AccessTypeResult';
  accessType: AccessType;
};

export enum AccountStatus {
  Active = 'ACTIVE',
  Closed = 'CLOSED',
  Created = 'CREATED',
  Suspended = 'SUSPENDED',
  Terminated = 'TERMINATED'
}

/** For ACI Course Load level */
export type AciCourseLoadTranscript = TranscriptBase & {
  __typename?: 'AciCourseLoadTranscript';
  aciTranscriptStatus: AciCourseLoadTranscriptStatus;
  auditMetadata: AuditMetadata;
  completionDate?: Maybe<Scalars['AWSDateTime']['output']>;
  digitalTraining?: Maybe<DigitalTranscript>;
  enrollmentDate?: Maybe<Scalars['AWSDateTime']['output']>;
  id: Scalars['ID']['output'];
  learner: User;
  learningActivityGroup: LearningActivityGroup;
  program: ProgramType;
  registration: Registration;
  startDate?: Maybe<Scalars['AWSDateTime']['output']>;
};

export enum AciCourseLoadTranscriptStatus {
  Completed = 'COMPLETED',
  Incomplete = 'INCOMPLETE',
  InProgress = 'IN_PROGRESS',
  NotStarted = 'NOT_STARTED'
}

export type AcknowledgeFulfillmentItemInput = {
  acknowledgedDateTime: Scalars['AWSDateTime']['input'];
  fulfillmentId: Scalars['ID']['input'];
  fulfillmentItemId: Scalars['ID']['input'];
  steps?: InputMaybe<Array<InputMaybe<FulfillmentItemStepInput>>>;
};

export type ActivateCatalogGroupInput = {
  id: Scalars['ID']['input'];
};

export type ActivateCatalogItemVersionInput = {
  id: Scalars['ID']['input'];
  semanticVersion: SemanticVersionInput;
};

export type ActiveInvestmentRequest = {
  __typename?: 'ActiveInvestmentRequest';
  businessUnit?: Maybe<Scalars['String']['output']>;
  irName?: Maybe<Scalars['String']['output']>;
  irStatus?: Maybe<IrStatus>;
  territory?: Maybe<Scalars['String']['output']>;
};

export enum ActivityStatus {
  Active = 'Active',
  Canceled = 'Canceled',
  Completed = 'Completed',
  Hold = 'Hold',
  Tentative = 'Tentative'
}

export type AddCatalogCommentInput = {
  commentLink: Scalars['AWSURL']['input'];
  content: Scalars['String']['input'];
  entityId: Scalars['String']['input'];
};

export type AddCommentInput = {
  author: Scalars['String']['input'];
  content: Scalars['String']['input'];
  entityId: Scalars['String']['input'];
  entityName: Scalars['String']['input'];
  /** Optional, will send notification if included */
  notificationConfig?: InputMaybe<NotificationConfiguration>;
};

export type AddCommentsToFileInput = {
  commentLink: Scalars['AWSURL']['input'];
  content: Scalars['String']['input'];
  entityId: Scalars['String']['input'];
};

export type AddCommentsToLearningContainerInput = {
  commentLink: Scalars['AWSURL']['input'];
  content: Scalars['String']['input'];
  entityId: Scalars['String']['input'];
};

export type AddEntityUserGroupUserInput = {
  groupId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type AddFileEntityCommentInput = {
  commentLink: Scalars['AWSURL']['input'];
  content: Scalars['String']['input'];
  entityId: Scalars['String']['input'];
};

export type AddFulfillmentItemResultDataInput = {
  currentStepIndex?: InputMaybe<Scalars['Int']['input']>;
  fulfillmentId: Scalars['ID']['input'];
  fulfillmentItemId: Scalars['ID']['input'];
  resultData?: InputMaybe<Array<KeyValuePairInput>>;
};

export type AddGroupUserInput = {
  associationStatus?: InputMaybe<UserAssociationStatus>;
  auditMetadata?: InputMaybe<AuditMetadataInput>;
  groupId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type AddLicenseMetadataInput = {
  licenseId: Scalars['ID']['input'];
  licenseMetaData: Array<KeyValuePairInput>;
};

export type AddOrganizationUserInput = {
  associationStatus?: InputMaybe<UserAssociationStatus>;
  auditMetadata?: InputMaybe<AuditMetadataInput>;
  organizationId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
  userRoles?: InputMaybe<Array<RoleName>>;
};

export type AddPaymentMethodToCheckoutSessionInput = {
  paymentMethodId: Scalars['ID']['input'];
  sessionId: Scalars['ID']['input'];
};

export type AddPublishingWorkflowCommentInput = {
  content: Scalars['String']['input'];
  publishingWorkflowId: Scalars['ID']['input'];
};

export type AddPublishingWorkflowTagInput = {
  publishingWorkflowId: Scalars['ID']['input'];
  tag: KeyValuePairInput;
};

export type AddPublishingWorkflowWatcherInput = {
  publishingWorkflowId: Scalars['ID']['input'];
  watcher: Scalars['String']['input'];
};

export type AddSystemUserGroupUserInput = {
  groupId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type AddTeamUserInput = {
  teamId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
  userRoles: Array<RoleName>;
};

export type AddUserAssociationRolesInput = {
  parentResourceId: Scalars['ID']['input'];
  parentResourceType: AssociationResourceType;
  userId: Scalars['ID']['input'];
  userRoles: Array<RoleName>;
};

/** Input for adding a variant to a catalog root item */
export type AddVariantToCatalogRootItemInput = {
  /** Whether this variant should be set as the default variant */
  isDefaultVariant?: InputMaybe<Scalars['Boolean']['input']>;
  /** ID of the root item to add the variant to */
  rootId: Scalars['ID']['input'];
  /** ID of the variant to add */
  variantId: Scalars['ID']['input'];
};

export type AdditionalOwner = {
  __typename?: 'AdditionalOwner';
  additional_owner_email: Scalars['String']['output'];
  additional_owner_name: Scalars['String']['output'];
};

export type Address = {
  __typename?: 'Address';
  addressLineOne: Scalars['String']['output'];
  addressLineTwo?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  countryCode: Scalars['String']['output'];
  name: Scalars['String']['output'];
  stateOrProvince?: Maybe<Scalars['String']['output']>;
  zipCode: Scalars['String']['output'];
};

export type AddressInput = {
  addressLineOne: Scalars['String']['input'];
  addressLineTwo?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  countryCode: Scalars['String']['input'];
  name: Scalars['String']['input'];
  stateOrProvince?: InputMaybe<Scalars['String']['input']>;
  zipCode: Scalars['String']['input'];
};

export type AmsDeliverySession = {
  __typename?: 'AmsDeliverySession';
  endTime?: Maybe<Scalars['AWSTimestamp']['output']>;
  id: Scalars['ID']['output'];
  learningActivity: LearningActivity;
  startTime?: Maybe<Scalars['AWSTimestamp']['output']>;
};

export type AppliedAmountHistory = {
  __typename?: 'AppliedAmountHistory';
  allocationAmount?: Maybe<Scalars['Float']['output']>;
  allocationCurrencyCode: Scalars['String']['output'];
  allocationDate: Scalars['String']['output'];
  allocationId: Scalars['String']['output'];
};

export type ArchiveCatalogItemInput = {
  id: Scalars['ID']['input'];
};

export type ArchiveFileMetadataInput = {
  id: Scalars['ID']['input'];
};

export type ArchiveLearningContainerInput = {
  id: Scalars['ID']['input'];
};

export type Assessment = {
  __typename?: 'Assessment';
  completionMessages?: Maybe<Array<Maybe<AssessmentCompletionMessage>>>;
  createdBy: Scalars['String']['output'];
  createdTimestamp: Scalars['AWSTimestamp']['output'];
  displaySetting?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isCategoryScoreEnabled?: Maybe<Scalars['Boolean']['output']>;
  isCopyPasteEnabled?: Maybe<Scalars['Boolean']['output']>;
  isCorrectAnswersShown?: Maybe<Scalars['String']['output']>;
  isDetailedResultsEnabled?: Maybe<Scalars['String']['output']>;
  isFinalScoreEnabled?: Maybe<Scalars['Boolean']['output']>;
  isFlaggingEnabled?: Maybe<Scalars['Boolean']['output']>;
  isNextBackEnabled?: Maybe<Scalars['Boolean']['output']>;
  isNotesEnabled?: Maybe<Scalars['Boolean']['output']>;
  isPausingEnabled?: Maybe<Scalars['Boolean']['output']>;
  isSkippedQuestionIncorrect: Scalars['Boolean']['output'];
  isSubmittedResponsesShown?: Maybe<Scalars['String']['output']>;
  learningObjectives?: Maybe<Array<Maybe<AssessmentLevelLearningObjective>>>;
  maxAttempts?: Maybe<Scalars['Int']['output']>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTimestamp?: Maybe<Scalars['AWSTimestamp']['output']>;
  passingScore?: Maybe<Scalars['Float']['output']>;
  programs?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  questionOrdering?: Maybe<Scalars['String']['output']>;
  questions?: Maybe<Array<Maybe<AssessmentLevelQuestion>>>;
  recordType: Scalars['String']['output'];
  scoreDisplay?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  timeLimit?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  version: Scalars['Int']['output'];
  waitTime?: Maybe<Scalars['Int']['output']>;
};

export type AssessmentAnswer = {
  __typename?: 'AssessmentAnswer';
  answerText?: Maybe<Scalars['String']['output']>;
  explanation?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isCorrect?: Maybe<Scalars['Boolean']['output']>;
};

export type AssessmentAnswerInput = {
  answerText: Scalars['String']['input'];
  explanation?: InputMaybe<Scalars['String']['input']>;
  isCorrect: Scalars['Boolean']['input'];
};

export type AssessmentAnswerUpdateInput = {
  id: Scalars['ID']['input'];
  isFlagged?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  questionId: Scalars['ID']['input'];
  selectedAnswerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  version: Scalars['Int']['input'];
};

export type AssessmentCompletionMessage = {
  __typename?: 'AssessmentCompletionMessage';
  maxScore?: Maybe<Scalars['Float']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  minScore?: Maybe<Scalars['Float']['output']>;
};

export type AssessmentCompletionMessageInput = {
  maxScore?: InputMaybe<Scalars['Float']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  minScore?: InputMaybe<Scalars['Float']['input']>;
};

export type AssessmentInput = {
  completionMessages?: InputMaybe<Array<InputMaybe<AssessmentCompletionMessageInput>>>;
  displaySetting?: InputMaybe<Scalars['String']['input']>;
  isCategoryScoreEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isCopyPasteEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isCorrectAnswersShown?: InputMaybe<Scalars['String']['input']>;
  isDetailedResultsEnabled?: InputMaybe<Scalars['String']['input']>;
  isFinalScoreEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isFlaggingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isNextBackEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isNotesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isPausingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isSkippedQuestionIncorrect?: InputMaybe<Scalars['Boolean']['input']>;
  isSubmittedResponsesShown?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<AssessmentLanguage>;
  learningObjectives?: InputMaybe<Array<InputMaybe<AssessmentLevelLearningObjectiveInput>>>;
  maxAttempts?: InputMaybe<Scalars['Int']['input']>;
  passingScore?: InputMaybe<Scalars['Float']['input']>;
  programs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  questionOrdering?: InputMaybe<Scalars['String']['input']>;
  questions?: InputMaybe<Array<InputMaybe<AssessmentLevelQuestionInput>>>;
  scoreDisplay?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  timeLimit?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  waitTime?: InputMaybe<Scalars['Int']['input']>;
};

export enum AssessmentLanguage {
  En = 'en'
}

export type AssessmentLevelLearningObjective = {
  __typename?: 'AssessmentLevelLearningObjective';
  id: Scalars['String']['output'];
  numQuestions: Scalars['Int']['output'];
};

export type AssessmentLevelLearningObjectiveInput = {
  id: Scalars['String']['input'];
  numQuestions: Scalars['Int']['input'];
};

export type AssessmentLevelQuestion = {
  __typename?: 'AssessmentLevelQuestion';
  id: Scalars['String']['output'];
  learningObjectives: Array<Maybe<Scalars['String']['output']>>;
};

export type AssessmentLevelQuestionInput = {
  id: Scalars['String']['input'];
  learningObjectives: Array<InputMaybe<Scalars['String']['input']>>;
};

export type AssessmentMetadataInput = {
  associatedMetadata?: InputMaybe<Array<InputMaybe<AssessmentMetadataObjectUpdateAssociationInput>>>;
  language?: InputMaybe<AssessmentLanguage>;
  name: Scalars['String']['input'];
  programs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  status: Scalars['String']['input'];
};

export type AssessmentMetadataObject = {
  __typename?: 'AssessmentMetadataObject';
  associatedMetadata?: Maybe<Array<Maybe<AssessmentMetadataObjectAssociation>>>;
  createdBy: Scalars['String']['output'];
  createdTimestamp: Scalars['AWSTimestamp']['output'];
  id: Scalars['ID']['output'];
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTimestamp?: Maybe<Scalars['AWSTimestamp']['output']>;
  name: Scalars['String']['output'];
  programs: Array<Maybe<Scalars['String']['output']>>;
  recordType: Scalars['String']['output'];
  status: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

export type AssessmentMetadataObjectAssociation = {
  __typename?: 'AssessmentMetadataObjectAssociation';
  id: Scalars['ID']['output'];
  metadataType: Scalars['String']['output'];
};

export type AssessmentMetadataObjectUpdateAssociationInput = {
  id: Scalars['ID']['input'];
  metadataType: Scalars['String']['input'];
};

export type AssessmentMetadataQueryInput = {
  id: Scalars['ID']['input'];
  language?: InputMaybe<AssessmentLanguage>;
  version?: InputMaybe<Scalars['Int']['input']>;
};

export type AssessmentMetadataSearchInput = {
  createdBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  from?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  language?: InputMaybe<AssessmentLanguage>;
  modifiedBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  programs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type AssessmentMetadataSearchOutput = {
  __typename?: 'AssessmentMetadataSearchOutput';
  currentCount: Scalars['Int']['output'];
  metadataObjects: Array<Maybe<AssessmentMetadataObject>>;
  totalCount: Scalars['Int']['output'];
};

export type AssessmentMetadataUpdateInput = {
  associatedMetadata?: InputMaybe<Array<InputMaybe<AssessmentMetadataObjectUpdateAssociationInput>>>;
  id: Scalars['ID']['input'];
  language?: InputMaybe<AssessmentLanguage>;
  name?: InputMaybe<Scalars['String']['input']>;
  programs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  status?: InputMaybe<Scalars['String']['input']>;
  version: Scalars['Int']['input'];
};

export type AssessmentQuestion = {
  __typename?: 'AssessmentQuestion';
  answers: Array<Maybe<AssessmentAnswer>>;
  attachments?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  createdBy: Scalars['String']['output'];
  createdTimestamp: Scalars['AWSTimestamp']['output'];
  difficulty?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  learningObjectives?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  modifiedBy: Scalars['String']['output'];
  modifiedTimestamp: Scalars['AWSTimestamp']['output'];
  programs?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  questionBanks?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  questionText?: Maybe<Scalars['String']['output']>;
  recordType: Scalars['String']['output'];
  scoringMethod?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  type: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

export type AssessmentQuestionInput = {
  answers: Array<InputMaybe<AssessmentAnswerInput>>;
  attachments?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  difficulty?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<AssessmentLanguage>;
  learningObjectives: Array<InputMaybe<Scalars['String']['input']>>;
  programs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  questionBanks?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  questionText: Scalars['String']['input'];
  scoringMethod?: InputMaybe<Scalars['String']['input']>;
  status: Scalars['String']['input'];
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type: Scalars['String']['input'];
};

export type AssessmentQuestionLearningObjectiveInput = {
  additionalContext?: InputMaybe<Scalars['String']['input']>;
  learningObjectiveId: Scalars['String']['input'];
  learningObjectiveVersion: Scalars['Int']['input'];
  numQuestions: Scalars['Int']['input'];
  questionDifficulty: Scalars['Int']['input'];
  questionType: Scalars['String']['input'];
};

export type AssessmentQuestionUpdateInput = {
  answers?: InputMaybe<Array<InputMaybe<AssessmentAnswerInput>>>;
  attachments?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  difficulty?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['String']['input'];
  language?: InputMaybe<AssessmentLanguage>;
  learningObjectives?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  programs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  questionBanks?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  questionText?: InputMaybe<Scalars['String']['input']>;
  scoringMethod?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Scalars['String']['input']>;
  version: Scalars['Int']['input'];
};

export type AssessmentQuestionsInput = {
  createdBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  difficulty?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  from?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  language?: InputMaybe<AssessmentLanguage>;
  learningObjectives?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  programs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  questionBanks?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  scoringMethod?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type AssessmentQuestionsOutput = {
  __typename?: 'AssessmentQuestionsOutput';
  currentCount: Scalars['Int']['output'];
  questions: Array<Maybe<AssessmentQuestion>>;
  totalCount: Scalars['Int']['output'];
};

export type AssessmentQuestionsPollWorkflowInput = {
  executionId: Scalars['String']['input'];
};

export type AssessmentQuestionsPollWorkflowOutput = {
  __typename?: 'AssessmentQuestionsPollWorkflowOutput';
  executionStatus: Scalars['String']['output'];
  input: Scalars['String']['output'];
  output?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
  stopDate?: Maybe<Scalars['String']['output']>;
};

export type AssessmentQuestionsStartWorkflowInput = {
  learningObjectives: Array<InputMaybe<AssessmentQuestionLearningObjectiveInput>>;
  model: Scalars['String']['input'];
  ragConfig?: InputMaybe<AssessmentsRagConfiguration>;
  temperature?: InputMaybe<Scalars['Float']['input']>;
  topK?: InputMaybe<Scalars['Int']['input']>;
  topP?: InputMaybe<Scalars['Float']['input']>;
};

export type AssessmentQuestionsStartWorkflowOutput = {
  __typename?: 'AssessmentQuestionsStartWorkflowOutput';
  executionId?: Maybe<Scalars['String']['output']>;
};

export type AssessmentUpdateInput = {
  completionMessages?: InputMaybe<Array<InputMaybe<AssessmentCompletionMessageInput>>>;
  displaySetting?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isCategoryScoreEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isCopyPasteEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isCorrectAnswersShown?: InputMaybe<Scalars['String']['input']>;
  isDetailedResultsEnabled?: InputMaybe<Scalars['String']['input']>;
  isFinalScoreEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isFlaggingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isNextBackEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isNotesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isPausingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isSubmittedResponsesShown?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<AssessmentLanguage>;
  learningObjectives?: InputMaybe<Array<InputMaybe<AssessmentLevelLearningObjectiveInput>>>;
  maxAttempts?: InputMaybe<Scalars['Int']['input']>;
  passingScore?: InputMaybe<Scalars['Float']['input']>;
  programs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  questionOrdering?: InputMaybe<Scalars['String']['input']>;
  questions?: InputMaybe<Array<InputMaybe<AssessmentLevelQuestionInput>>>;
  scoreDisplay?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  timeLimit?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  version: Scalars['Int']['input'];
  waitTime?: InputMaybe<Scalars['Int']['input']>;
};

export type AssessmentUser = {
  __typename?: 'AssessmentUser';
  createdBy: Scalars['String']['output'];
  createdTimestamp: Scalars['AWSTimestamp']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastSignin?: Maybe<Scalars['AWSTimestamp']['output']>;
  modifiedBy?: Maybe<Scalars['String']['output']>;
  modifiedTimestamp?: Maybe<Scalars['AWSTimestamp']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  programs?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  recordType: Scalars['String']['output'];
  roles: Array<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

export type AssessmentUsersInput = {
  from?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  programs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  size?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type AssessmentUsersOutput = {
  __typename?: 'AssessmentUsersOutput';
  currentCount: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
  users: Array<Maybe<AssessmentUser>>;
};

export type AssessmentsInput = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  createdBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  from?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  language?: InputMaybe<AssessmentLanguage>;
  learningObjectives?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  programs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type AssessmentsOutput = {
  __typename?: 'AssessmentsOutput';
  assessments: Array<Maybe<Assessment>>;
  currentCount: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type AssessmentsRagConfiguration = {
  chunkSize?: InputMaybe<Scalars['String']['input']>;
  datasource?: InputMaybe<Scalars['String']['input']>;
  maxResults?: InputMaybe<Scalars['Int']['input']>;
};

export type AssignLicenseToUserInput = {
  id: Scalars['ID']['input'];
  reassignLicense?: InputMaybe<Scalars['Boolean']['input']>;
  userId: Scalars['ID']['input'];
};

export type AssignLicensesInOrgHierarchyInput = {
  destinationId: Scalars['ID']['input'];
  destinationType: Scalars['String']['input'];
  orderItemId: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
  sourceId: Scalars['ID']['input'];
  sourceType: Scalars['String']['input'];
};

export type AssignLicensesToUserGroupsInput = {
  licenseAllocationId: Scalars['ID']['input'];
  userGroupIds: Array<Scalars['ID']['input']>;
};

export type AssignLicensesToUsersInput = {
  licenseAllocationId: Scalars['ID']['input'];
  userIds: Array<Scalars['ID']['input']>;
};

export enum AssignmentStatus {
  Failed = 'FAILED',
  PartialFailure = 'PARTIAL_FAILURE',
  Success = 'SUCCESS'
}

export type AssociateCatalogItemWithGroupInput = {
  groupId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
};

export type AssociateCatalogRootItemWithGroupInput = {
  groupId: Scalars['ID']['input'];
  rootItemId: Scalars['ID']['input'];
};

export type AssociatedAuthorizationResourceInput = {
  id: Scalars['ID']['input'];
  type: AuthorizationResourceType;
};

export type AssociatedPrincipalInput = {
  id: Scalars['ID']['input'];
  type: AuthorizationPrincipalType;
};

export enum AssociationResourceType {
  EntityUserGroup = 'ENTITY_USER_GROUP',
  Group = 'GROUP',
  Organization = 'ORGANIZATION',
  SystemUserGroup = 'SYSTEM_USER_GROUP',
  Team = 'TEAM'
}

export type AttendancePolicyAttributes = {
  __typename?: 'AttendancePolicyAttributes';
  auditMetadata: AuditMetadata;
  awsAccountIds?: Maybe<Array<Scalars['ID']['output']>>;
  instructorIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  instructorType?: Maybe<Scalars['String']['output']>;
  /** local copy */
  learningActivityId: Scalars['ID']['output'];
  learningActivityStatus: Scalars['String']['output'];
  lmsType: Scalars['String']['output'];
};

export type AttendanceRecord = {
  __typename?: 'AttendanceRecord';
  amsDeliverySession: AmsDeliverySession;
  attendanceDurationPercentage?: Maybe<Scalars['Float']['output']>;
  attendanceStatus: AttendanceStatus;
  auditMetadata: AuditMetadata;
  id: Scalars['ID']['output'];
  learner: User;
  version: Scalars['Int']['output'];
};

export enum AttendanceStatus {
  Attended = 'ATTENDED',
  /**
   * Internally, there is an ATTENDANCE_THRESHOLD (current value is 60).
   * - If attendanceDurationPercentage is [ATTENDANCE_THRESHOLD, 100], attendanceStatus is mapped to ATTENDED;
   * - If attendanceDurationPercentage is (0, ATTENDANCE_THRESHOLD), attendanceStatus is mapped to PARTIALLY_ATTENDED;
   * - If attendanceDurationPercentage is 0, attendanceStatus is mapped to NO_SHOW;
   * NOT_MARKED is the initial state of attendance records created as result of a registration event.
   * REMOVED is the state of attendance records created as result of a withdrawal event.
   * REMOVED status can only be changed back to NOT_MARKED.
   */
  NotMarked = 'NOT_MARKED',
  NoShow = 'NO_SHOW',
  PartiallyAttended = 'PARTIALLY_ATTENDED',
  Removed = 'REMOVED'
}

export type Attribute = {
  __typename?: 'Attribute';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type AttributeInput = {
  name: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export enum Audience {
  Amazon = 'AMAZON',
  Customers = 'CUSTOMERS',
  Partners = 'PARTNERS'
}

export type AuditLogInput = {
  delta?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  paginationMarker?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  sortForward?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AuditLogItem = {
  __typename?: 'AuditLogItem';
  createdTimestamp: Scalars['AWSTimestamp']['output'];
  delta?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  newValue?: Maybe<Scalars['String']['output']>;
  oldValue?: Maybe<Scalars['String']['output']>;
  operation: Scalars['String']['output'];
  resourceAuditSequence: Scalars['AWSTimestamp']['output'];
  resourceModifiedBy: Scalars['String']['output'];
  resourceModifiedTimestamp: Scalars['AWSTimestamp']['output'];
  resourceVersion: Scalars['Int']['output'];
};

export type AuditLogOutput = {
  __typename?: 'AuditLogOutput';
  auditLog: Array<AuditLogItem>;
  currentCount: Scalars['Int']['output'];
  paginationMarker?: Maybe<Scalars['String']['output']>;
};

export type AuditMetadata = {
  __typename?: 'AuditMetadata';
  createdAt: Scalars['AWSDateTime']['output'];
  createdBy: Scalars['String']['output'];
  lastUpdatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
};

export type AuditMetadataInput = {
  createdAt: Scalars['AWSDateTime']['input'];
  createdBy: Scalars['String']['input'];
  lastUpdatedAt: Scalars['AWSDateTime']['input'];
  lastUpdatedBy: Scalars['String']['input'];
};

export enum AuthenticationMethod {
  Apn = 'APN',
  AwsBuilderId = 'AWS_BUILDER_ID',
  Lwa = 'LWA',
  Sso = 'SSO'
}

export enum AuthorizationActionType {
  Access = 'ACCESS',
  View = 'VIEW'
}

export type AuthorizationOrganizationAttribute = {
  __typename?: 'AuthorizationOrganizationAttribute';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type AuthorizationPrincipal = AuthorizationOrganizationAttribute | Group | LearningAccount | Organization | UnknownPrincipal;

export type AuthorizationPrincipalIdentityInput = {
  associatedPrincipals?: InputMaybe<Array<AssociatedPrincipalInput>>;
  id: Scalars['ID']['input'];
  type: AuthorizationPrincipalIdentityType;
};

export enum AuthorizationPrincipalIdentityType {
  Organization = 'ORGANIZATION',
  User = 'USER',
  UserGroup = 'USER_GROUP',
  Vibe = 'VIBE'
}

export enum AuthorizationPrincipalType {
  Organization = 'ORGANIZATION',
  OrganizationType = 'ORGANIZATION_TYPE',
  PreAuth = 'PRE_AUTH',
  UserGroup = 'USER_GROUP'
}

export type AuthorizationResource = CatalogGroup | CatalogItem;

export type AuthorizationResourceInput = {
  associatedResources?: InputMaybe<Array<AssociatedAuthorizationResourceInput>>;
  id: Scalars['ID']['input'];
  type: AuthorizationResourceType;
};

export enum AuthorizationResourceType {
  CatalogGroup = 'CATALOG_GROUP',
  CatalogItem = 'CATALOG_ITEM',
  LearningActivityGroup = 'LEARNING_ACTIVITY_GROUP'
}

export enum AuthorizationResult {
  AuthorizedActive = 'AUTHORIZED_ACTIVE',
  NotAuthorized = 'NOT_AUTHORIZED'
}

export type AuthorizedEntity = Group | Organization | Team;

export enum AuthorizedEntityType {
  Group = 'GROUP',
  Organization = 'ORGANIZATION',
  Team = 'TEAM'
}

export type AuthorizedResourceResult = {
  __typename?: 'AuthorizedResourceResult';
  resourceId: Scalars['ID']['output'];
  resourceType: AuthorizationResourceType;
};

export type AuthorizedResourcesOutput = {
  __typename?: 'AuthorizedResourcesOutput';
  results: Array<AuthorizedResourceResult>;
};

export enum B2BBillingStatus {
  Accrued = 'ACCRUED',
  Billed = 'BILLED',
  BilledViaClipperOcb = 'BILLED_VIA_CLIPPER_OCB',
  BillingFailed = 'BILLING_FAILED',
  BillingInProgress = 'BILLING_IN_PROGRESS',
  BillImmediately = 'BILL_IMMEDIATELY',
  NotApplicable = 'NOT_APPLICABLE',
  ToBeAccrued = 'TO_BE_ACCRUED',
  ToBeBilled = 'TO_BE_BILLED'
}

export type BenefitRules = {
  __typename?: 'BenefitRules';
  maxUsage?: Maybe<Scalars['Int']['output']>;
  /** When an offer is redeemable at checkout (inclusive) */
  redemptionPeriod?: Maybe<RedemptionPeriod>;
};

/** A value to track what type of benefit is being used */
export enum BenefitType {
  Discount = 'DISCOUNT',
  Freetrial = 'FREETRIAL',
  PercentDiscount = 'PERCENT_DISCOUNT'
}

export type BillingActionInput = {
  actionType: BillingActionType;
  invoiceDetail?: InputMaybe<InvoiceDetailInput>;
  orderItemId: Scalars['String']['input'];
};

export enum BillingActionType {
  Accrued = 'ACCRUED',
  BilledViaClipperOcb = 'BILLED_VIA_CLIPPER_OCB',
  BillImmediately = 'BILL_IMMEDIATELY',
  ToBeAccrued = 'TO_BE_ACCRUED',
  ToBeBilled = 'TO_BE_BILLED'
}

export type BillingInvoice = {
  __typename?: 'BillingInvoice';
  aws_sor?: Maybe<Scalars['String']['output']>;
  batch_id?: Maybe<Scalars['String']['output']>;
  bill_to_address_1?: Maybe<Scalars['String']['output']>;
  bill_to_address_2?: Maybe<Scalars['String']['output']>;
  bill_to_aws_account_id?: Maybe<Scalars['String']['output']>;
  bill_to_city?: Maybe<Scalars['String']['output']>;
  bill_to_country?: Maybe<Scalars['String']['output']>;
  bill_to_email?: Maybe<Scalars['String']['output']>;
  bill_to_first_name?: Maybe<Scalars['String']['output']>;
  bill_to_last_name?: Maybe<Scalars['String']['output']>;
  bill_to_postal_code?: Maybe<Scalars['String']['output']>;
  bill_to_state?: Maybe<Scalars['String']['output']>;
  bill_to_tax_id?: Maybe<Scalars['String']['output']>;
  billed_amount?: Maybe<Scalars['Float']['output']>;
  billing_status?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  customer_aws_account_id?: Maybe<Scalars['String']['output']>;
  customer_invoice_notes?: Maybe<Scalars['String']['output']>;
  customer_name?: Maybe<Scalars['String']['output']>;
  do_not_group?: Maybe<Scalars['Boolean']['output']>;
  invoice_reference?: Maybe<Scalars['String']['output']>;
  is_reseller?: Maybe<Scalars['Boolean']['output']>;
  legal_entity_name?: Maybe<Scalars['String']['output']>;
  line_items?: Maybe<Array<Maybe<BillingLineItem>>>;
  mast_invoice_number?: Maybe<Scalars['String']['output']>;
  nmbs_agreement_id?: Maybe<Scalars['String']['output']>;
  nmbs_bill_id?: Maybe<Scalars['String']['output']>;
  nmbs_billing_console_ui_link?: Maybe<Scalars['String']['output']>;
  nmbs_billing_status?: Maybe<Scalars['String']['output']>;
  nmbs_invoice_id?: Maybe<Scalars['String']['output']>;
  number_of_participants?: Maybe<Scalars['Int']['output']>;
  ofa_invoice_number?: Maybe<Scalars['String']['output']>;
  ofa_invoice_url?: Maybe<Scalars['String']['output']>;
  payment_terms?: Maybe<Scalars['String']['output']>;
  po_number?: Maybe<Scalars['String']['output']>;
  prepay?: Maybe<Scalars['Boolean']['output']>;
  processing_error?: Maybe<Scalars['String']['output']>;
  request_for_funds_id?: Maybe<Scalars['String']['output']>;
  s3_path?: Maybe<Scalars['String']['output']>;
  sfdc_opportunity_id?: Maybe<Scalars['String']['output']>;
  tax_rate?: Maybe<Scalars['String']['output']>;
};

export type BillingLineItem = {
  __typename?: 'BillingLineItem';
  item_amount?: Maybe<Scalars['Float']['output']>;
  item_type?: Maybe<Scalars['String']['output']>;
};

export enum BillingPeriod {
  Annual = 'ANNUAL',
  Monthly = 'MONTHLY'
}

export type BulkEntityUserGroupOperationInput = {
  entityUserGroupIds: Array<Scalars['ID']['input']>;
  operation: BulkOperation;
  userIds: Array<Scalars['ID']['input']>;
};

export type BulkEntityUserGroupOperationOutput = {
  __typename?: 'BulkEntityUserGroupOperationOutput';
  failedEntityUserGroupIds: Array<Scalars['ID']['output']>;
  failedUserIds: Array<Scalars['ID']['output']>;
  operation: BulkOperation;
};

export type BulkOperateOrganizationUsersInput = {
  operation: BulkOperation;
  operatorUserId?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
  teamId?: InputMaybe<Scalars['String']['input']>;
  userIds: Array<Scalars['String']['input']>;
};

export type BulkOperateOrganizationUsersOutput = {
  __typename?: 'BulkOperateOrganizationUsersOutput';
  failedUserIds: Array<Scalars['String']['output']>;
  operation: BulkOperation;
  organizationId: Scalars['String']['output'];
  teamId?: Maybe<Scalars['String']['output']>;
};

export enum BulkOperation {
  Add = 'ADD',
  Remove = 'REMOVE'
}

export type BulkOperationFile = {
  __typename?: 'BulkOperationFile';
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  executionLogReportUrl?: Maybe<Scalars['String']['output']>;
  fileId?: Maybe<Scalars['ID']['output']>;
  fileMetadata?: Maybe<BulkOperationFileMetadata>;
  lastUpdatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  learningAccount?: Maybe<LearningAccount>;
  templateUrl?: Maybe<Scalars['String']['output']>;
  uploadLocation?: Maybe<Scalars['String']['output']>;
  userCreationStatus?: Maybe<UserOperationStatus>;
};

export type BulkOperationFileMetadata = {
  __typename?: 'BulkOperationFileMetadata';
  name: Scalars['String']['output'];
  size?: Maybe<Scalars['Int']['output']>;
};

/**
 * Collects information about the user provided file to the operation.
 * The size is used to validate the provided file does not exceed the maximum. This
 * will be checked again against the actual size of the file provided in s3. However,
 * this provides the resolver an ability to provide caller with a synchronous failure.
 */
export type BulkOperationFileMetadataInput = {
  name: Scalars['String']['input'];
  size: Scalars['Int']['input'];
};

export type BulkSystemUserGroupOperationInput = {
  operation: BulkOperation;
  systemUserGroupIds: Array<Scalars['ID']['input']>;
  userIds: Array<Scalars['ID']['input']>;
};

export type BulkSystemUserGroupOperationOutput = {
  __typename?: 'BulkSystemUserGroupOperationOutput';
  failedSystemUserGroupIds: Array<Scalars['ID']['output']>;
  failedUserIds: Array<Scalars['ID']['output']>;
  operation: BulkOperation;
};

export type CancelOrderInput = {
  cancellationReason: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
};

export type CancelOrderItemInput = {
  cancellationCategory?: InputMaybe<CancellationCategory>;
  cancellationReason: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
  orderItemId: Scalars['String']['input'];
};

export enum CancellationCategory {
  CustomerCanceled = 'CUSTOMER_CANCELED',
  FraudMitigation = 'FRAUD_MITIGATION',
  LearningActivityCanceledImmediate = 'LEARNING_ACTIVITY_CANCELED_IMMEDIATE',
  ManualCancellationEndofterm = 'MANUAL_CANCELLATION_ENDOFTERM',
  ManualCancellationImmediate = 'MANUAL_CANCELLATION_IMMEDIATE',
  OpportunityItemCanceledImmediate = 'OPPORTUNITY_ITEM_CANCELED_IMMEDIATE',
  PaymentCanceled = 'PAYMENT_CANCELED'
}

export type CancellationConfiguration = {
  __typename?: 'CancellationConfiguration';
  cancellationOffsetHours?: Maybe<Scalars['Int']['output']>;
  userCancellationOutcome?: Maybe<CancellationOutcome>;
};

export type CancellationDetails = {
  __typename?: 'CancellationDetails';
  cancellationCategory: CancellationCategory;
  cancellationEffectiveAt: Scalars['AWSDateTime']['output'];
  requestedCancellationAt: Scalars['AWSDateTime']['output'];
};

/** To indicate how default cancellation should be handled when a customer attempts to cancel. */
export enum CancellationOutcome {
  EndOfBillingPeriod = 'END_OF_BILLING_PERIOD',
  Immediate = 'IMMEDIATE'
}

export type Capacity = {
  __typename?: 'Capacity';
  subProviderCapacity?: Maybe<Array<SubProviderCapacity>>;
  total: Scalars['Int']['output'];
};

export type CapacityInput = {
  subProviderCapacity?: InputMaybe<Array<SubProviderCapacityInput>>;
  total: Scalars['Int']['input'];
};

/** Configures how the Cart Component is displayed */
export type CartComponent = {
  __typename?: 'CartComponent';
  /** Config of cancel redirect if user doesn't want to finalize checkout */
  cancelRedirectConfig: LinkConfig;
  /** Groups to show in the cart, details what is being checked out */
  groups: Array<CartGroup>;
  /** Heading text of Cart component */
  heading: Scalars['String']['output'];
};

export type CartComponentInput = {
  cancelRedirectConfig: LinkConfigInput;
  groups: Array<CartGroupInput>;
  heading: Scalars['String']['input'];
};

/** Represents a group of items shown in Cart component */
export type CartGroup = {
  __typename?: 'CartGroup';
  /** Text sections to display for items in group */
  items: Array<TextSection>;
  /** Name of group to display */
  name: Scalars['String']['output'];
};

export type CartGroupInput = {
  items: Array<TextSectionInput>;
  name: Scalars['String']['input'];
};

/** Defines the shape/definition of a catalog attribute. */
export type CatalogAttributeSchema = {
  __typename?: 'CatalogAttributeSchema';
  /** The default values or options for custom attribute (for use in LCMS pre-populating forms). */
  defaultValuesOrOptions?: Maybe<Array<Scalars['String']['output']>>;
  /** The attribute key, i.e., the logical identifier of the attribute (e.g., 'domain') */
  key: Scalars['String']['output'];
  /** The key display string key/value pairs by language code. E.g., if I onboard an attribute called 'domain', the display string in English could be 'Domain'. */
  keyDisplayStrings: Array<CatalogLocalizedString>;
  /**
   * 'select' custom attributes (SINGLE_SELECT and MULTI_SELECT) are similar to enums, where a predefined set of possible values is configured.
   * These are the list of those possible options.
   */
  selectOptions?: Maybe<Array<CatalogAttributeSchemaSelectOption>>;
  /** The type of the attribute (e.g., boolean, number, string, single select, multi select, etc.) */
  type: CatalogAttributeType;
};

/** A pagination of CatalogAttributeSchema. */
export type CatalogAttributeSchemaConnection = {
  __typename?: 'CatalogAttributeSchemaConnection';
  /** List of CatalogAttributeSchema nodes */
  nodes: Array<CatalogAttributeSchema>;
  /** Pagination information */
  pageInfo?: Maybe<PageInfo>;
};

export type CatalogAttributeSchemaFilter = {
  attributeKeys?: InputMaybe<Array<Scalars['String']['input']>>;
  languageCodes?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Defines a single valid select option of a SINGLE_SELECT or MULTI_SELECT catalog attribute. */
export type CatalogAttributeSchemaSelectOption = {
  __typename?: 'CatalogAttributeSchemaSelectOption';
  /**
   * The logical identifier of the option. E.g., if I onboard a multi select attribute 'domain', one option may be 'blockchain', and another may be 'architecting'.
   * These would both be options.
   */
  option: Scalars['String']['output'];
  /**
   * The option display string key/value pairs by language code.
   * E.g., for 'blockchain', the English display string might be 'Blockchain'
   */
  optionDisplayStrings: Array<CatalogLocalizedString>;
};

/** Type of a catalog attribute as defined in attribute schema. */
export enum CatalogAttributeType {
  Boolean = 'BOOLEAN',
  LongString = 'LONG_STRING',
  MultiSelect = 'MULTI_SELECT',
  Number = 'NUMBER',
  SingleSelect = 'SINGLE_SELECT',
  String = 'STRING',
  StringList = 'STRING_LIST'
}

/** Audit metadata of catalog entity */
export type CatalogAuditMetadata = {
  __typename?: 'CatalogAuditMetadata';
  /** Timestamp of entity activation */
  activatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  /** Actor who activated the entity */
  activatedBy?: Maybe<Scalars['String']['output']>;
  /** Timestamp of entity archival */
  archivedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  /** Actor who archived the entity */
  archivedBy?: Maybe<Scalars['String']['output']>;
  /** Timestamp of entity creation */
  createdAt: Scalars['AWSDateTime']['output'];
  /** Actor who created the entity */
  createdBy?: Maybe<Scalars['String']['output']>;
  /** Timestamp of entity deactivation */
  deactivatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  /** Actor who deactivated the entity */
  deactivatedBy?: Maybe<Scalars['String']['output']>;
  /** Timestamp when the entity was put into maintenance */
  inMaintenanceAt?: Maybe<Scalars['AWSDateTime']['output']>;
  /** Actor who put the entity into maintenance */
  inMaintenanceBy?: Maybe<Scalars['String']['output']>;
  /** Timestamp of last entity update */
  lastUpdatedAt: Scalars['AWSDateTime']['output'];
  /** Actor who last updated the entity */
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
};

/** Wrapper around boolean attributes to add display strings */
export type CatalogBooleanAttribute = {
  __typename?: 'CatalogBooleanAttribute';
  /** attribute key display string. E.g., `domain` key will have `Domain` display string. Based on caller's language setting. */
  keyDisplayString?: Maybe<Scalars['String']['output']>;
  /** Value of boolean attribute. */
  value: Scalars['Boolean']['output'];
};

/** A Custom attribute on an item */
export type CatalogCustomAttribute = {
  __typename?: 'CatalogCustomAttribute';
  /** Custom attribute key. Matches with some attribute schema */
  key: Scalars['String']['output'];
  /** Custom attribute key display string. E.g., `domain` key will have `Domain` display string. Based on caller's language setting. */
  keyDisplayString?: Maybe<Scalars['String']['output']>;
  /** The catalog namespace this attribute schema is defined in */
  namespaceId: Scalars['String']['output'];
  /** The type of the custom attribute (e.g., boolean, number, string, single select, multi select, etc.) */
  type: CatalogAttributeType;
  /** Values of custom attribute. This is a list for flexibility. But if item is a string, number, or boolean, there will be exactly 1 entry in list. For multi select, may have multiple values. */
  values?: Maybe<Array<Scalars['String']['output']>>;
  /**
   * Display strings of values. This is a list, and will align with values list (e.g., valuesDisplayStrings[0] is the display string for values[0])
   * Only applicable for SINGLE_SELECT and MULTI_SELECT values.
   */
  valuesDisplayStrings?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type CatalogCustomAttributeSchemaSelectOptionInput = {
  option: Scalars['String']['input'];
  optionDisplayStrings: Array<CatalogLocalizedStringInput>;
};

/**
 * Custom identifier used for a catalog entity
 * Another access pattern to query object outside of its generated ID
 */
export type CatalogCustomIdentifier = {
  __typename?: 'CatalogCustomIdentifier';
  /** Identifier name (e.g., 'vendorCourseId', 'labArn', etc.) */
  name: Scalars['String']['output'];
  /** What entity ID identifier points to */
  targetId: Scalars['ID']['output'];
  /** What entity type identifier points to */
  targetType: CatalogCustomIdentifierTargetType;
  /** Whether identifier is used for a single target, or if it can be used for multiple targets (some items share identifiers) */
  unique: Scalars['Boolean']['output'];
  /** Identifier value (e.g., '12345') */
  value: Scalars['String']['output'];
};

export type CatalogCustomIdentifierInput = {
  /** identifier name (e.g., 'vendorCourseId', 'labArn', etc.) */
  name: Scalars['String']['input'];
  /** Whether this identifier is unique to the single target, or if it can be used by multiple targets */
  unique: Scalars['Boolean']['input'];
  /** identifier value (e.g., '12345') */
  value: Scalars['String']['input'];
};

/** Target type custom identifier used for (group or item) */
export enum CatalogCustomIdentifierTargetType {
  CatalogGroup = 'CATALOG_GROUP',
  CatalogItem = 'CATALOG_ITEM',
  CatalogRootItem = 'CATALOG_ROOT_ITEM'
}

/** Duration object of catalog item */
export type CatalogDuration = {
  __typename?: 'CatalogDuration';
  /** Unit of duration. */
  unit: CatalogDurationUnit;
  /** Value of duration. */
  value: Scalars['Float']['output'];
};

/** Input for specifying the duration of a catalog item */
export type CatalogDurationInput = {
  /**
   * If set, item will not have a static duration (don't pass 'unit' or 'value'), but instead will sum the duration of its children
   * Dynamic duration is supported only for LEARNING_PLANS currently. Other items should use static duration
   */
  dynamicDuration?: InputMaybe<Scalars['Boolean']['input']>;
  /** Unit of static duration. Prefer SECONDS currently */
  unit?: InputMaybe<CatalogDurationUnit>;
  /** Value of static duration */
  value?: InputMaybe<Scalars['Float']['input']>;
};

/** Unit used for duration. */
export enum CatalogDurationUnit {
  Days = 'DAYS',
  Hours = 'HOURS',
  Minutes = 'MINUTES',
  Seconds = 'SECONDS'
}

/** DEPRECATED: File, used only today for thumbnails */
export type CatalogFile = {
  __typename?: 'CatalogFile';
  /** URL for viewing the url (used in learner dashboard e.g., search, recs, etc.) */
  cdnUrl?: Maybe<Scalars['String']['output']>;
  /** Unique ID of file */
  id: Scalars['ID']['output'];
  /** Upload url used to publish file. Only for VERC. */
  presignedUploadUrl?: Maybe<Scalars['AWSURL']['output']>;
};

/**
 * A Catalog Group that contains multiple catalog root items
 * When a root is added to (or removed from) a group, the variants come along with it
 */
export type CatalogGroup = {
  __typename?: 'CatalogGroup';
  /** Audit metadata */
  auditMetadata: CatalogAuditMetadata;
  /**
   * Count of Collection type products within the Catalog Group
   * Example: Learning Plan, ACI Course
   */
  collectionCount: Scalars['Int']['output'];
  /** Comments on the group (internal-only, LCMS) */
  comments?: Maybe<EntityComments>;
  /**
   * For use primarily by VERC. Internal Teams should not currently use these.
   * List of custom key/value pair identifiers used to index/query this group, beyond its generated ID.
   */
  customIdentifiers: Array<CatalogCustomIdentifier>;
  /** Description of the group */
  description?: Maybe<Scalars['String']['output']>;
  /**
   * Which internal team is responsible for developing this item (internal only)
   * For valid values, see https://code.amazon.com/packages/AwsTcCatalogService/blobs/52e33e312d9b2cfb50da00d22814931fc2e06a1c/--/src/utils/attributeSchemas.ts#L356
   */
  devTeam?: Maybe<CatalogSingleSelectAttribute>;
  /** Unique identifier of the group */
  id: Scalars['ID']['output'];
  /** Items in the group. This is derived from the root items in the group */
  items: CatalogItemConnection;
  /** Name of the group */
  name: Scalars['String']['output'];
  /**
   * DEPRECATED: Namespace group is in.
   * Groups being updated to no longer live within a namespace
   */
  namespace?: Maybe<CatalogNamespace>;
  /** Individual who own this group (internal only) */
  owner?: Maybe<User>;
  /**
   * Count of Single type products within the Catalog Group
   * Example: Digital Course
   */
  productCount: Scalars['Int']['output'];
  /** Root items in the group. */
  rootItems: CatalogRootItemConnection;
  /**
   * Lifecycle status of group (e.g., ACTIVE or INACTIVE).
   * Only ACTIVE groups can contain root items/items
   */
  status: CatalogGroupStatus;
  /** Key/value tag pairs for LCMS (internal only) */
  tags?: Maybe<Array<CatalogItemTag>>;
  /** Whether item is RESTRICTED (DCT) or GENERAL. Immutable once set. (internal only) */
  visibility?: Maybe<CatalogItemVisibility>;
};


/**
 * A Catalog Group that contains multiple catalog root items
 * When a root is added to (or removed from) a group, the variants come along with it
 */
export type CatalogGroupItemsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


/**
 * A Catalog Group that contains multiple catalog root items
 * When a root is added to (or removed from) a group, the variants come along with it
 */
export type CatalogGroupRootItemsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

/** Paginated list of catalog groups */
export type CatalogGroupConnection = {
  __typename?: 'CatalogGroupConnection';
  /** List of CatalogGroup nodes */
  nodes: Array<CatalogGroup>;
  /** Pagination information */
  pageInfo?: Maybe<PageInfo>;
};

export type CatalogGroupInput = {
  customIdentifiers?: InputMaybe<Array<CatalogCustomIdentifierInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  devTeam?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  owner?: InputMaybe<Scalars['ID']['input']>;
  tags?: InputMaybe<Array<CatalogItemTagInput>>;
  visibility?: InputMaybe<CatalogItemVisibility>;
};

/**
 * Lifecycle status values of Group.
 * When groups are created, they are ACTIVE and empty.
 * They can be deactivated and reactivated.
 * INACTIVE groups cannot contain items
 */
export enum CatalogGroupStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

/** Extension of the Catalog Item so callers can also fetch review data for an item */
export type CatalogItem = {
  __typename?: 'CatalogItem';
  /**
   * Whether the item is compliant for accessibility.
   * Valid for modules only
   */
  accessibilityCompliant?: Maybe<CatalogBooleanAttribute>;
  /**
   * When this item will be activated. Used to configure release rules
   * When date comes, item will be marked as 'ACTIVE'
   * Valid for products only
   */
  activationDate?: Maybe<CatalogStringAttribute>;
  /**
   * Is the item approved for reuse in multiple products.
   * Valid for modules only
   */
  approvedForReuse?: Maybe<CatalogBooleanAttribute>;
  /**
   * Archival flag on if the item has been archived.
   * Only items without active versions can be archived, and they cannot be modified when archived
   */
  archived?: Maybe<Scalars['Boolean']['output']>;
  /** Audit metadata */
  auditMetadata: CatalogAuditMetadata;
  /**
   * Which aws services this training object is relevant for.
   * For valid values, see https://code.amazon.com/packages/AwsTcCatalogService/blobs/52e33e312d9b2cfb50da00d22814931fc2e06a1c/--/src/utils/attributeSchemas.ts#L174
   * Valid for products only
   */
  awsServices?: Maybe<CatalogMultiSelectAttribute>;
  /**
   * The url of a credly badge image to issue.
   * This attribute eventually to be removed in favor of functionality by Rewards Service.
   * Valid for products only
   */
  badgeImageUrl?: Maybe<CatalogStringAttribute>;
  /**
   * The name of a credly badge to issue.
   * This attribute eventually to be removed in favor of functionality by Rewards Service.
   * Valid for products only
   */
  badgeName?: Maybe<CatalogStringAttribute>;
  /**
   * ID of credly badge template to issue.
   * This attribute eventually to be removed in favor of functionality by Rewards Service.
   * Valid for products only
   */
  badgeTemplateId?: Maybe<CatalogStringAttribute>;
  /**
   * More specific than classification, values like LEARNING_PLAN, COURSE, MODULE, etc.
   * Must match with root item if set
   */
  category: CatalogItemCategory;
  /** Children of this item (e.g., a learning plan has product children, other products have modules) */
  children: CatalogItemConnection;
  /** Number of children this item has (to get the actual children, see `children`) */
  childrenCount: Scalars['Int']['output'];
  /**
   * Classification: PRODUCT (something that can be enrolled in or purchased - gets its own transcript) or MODULE (individual piece of content such as xapi or lab).
   * Some products are composed of other nested products or modules.
   * Must match with root item if set
   */
  classification?: Maybe<CatalogItemClassification>;
  /** Comments on catalog item (internal only, LCMS) */
  comments?: Maybe<EntityComments>;
  /**
   * Whether to issue completion badge when item is completed.
   * Valid for products only
   */
  completionBadge?: Maybe<CatalogBooleanAttribute>;
  /**
   * Whether to issue completion certificate when item is completed.
   * Valid for products only
   */
  completionCertificate?: Maybe<CatalogBooleanAttribute>;
  /**
   * Rules for how this product should be considered complete when a customer consumes it
   * Valid for products only
   */
  completionCriteria?: Maybe<CatalogItemCompletionCriteria>;
  /**
   * The training content files. This is internal-only for LCMS, and these files are xAPI format currently
   * Valid for modules only
   */
  contentFiles?: Maybe<Array<FileEntity>>;
  /** Retrieves a single custom attribute by its key and namespace ID */
  customAttributeByKey?: Maybe<CatalogCustomAttribute>;
  /**
   * List of custom attributes added to the item version.
   * Custom attributes are key/value pair attributes that don't exist in this GQL schema, but are onboarded for specific use cases.
   * To onboard a custom attribute, talk with the catalog team.
   */
  customAttributes: Array<CatalogCustomAttribute>;
  /**
   * List of custom key/value pair identifiers used to index/query this item, beyond its generated ID.
   * Currently set at item creation, and are immutable (will likely add more mutating functionality in future)
   */
  customIdentifiers: Array<CatalogCustomIdentifier>;
  /** Default version of current catalog item */
  defaultVersion?: Maybe<CatalogItem>;
  /** Description of the catalog item */
  description?: Maybe<Scalars['String']['output']>;
  /**
   * Which internal team is responsible for developing this item (internal only)
   * For valid values, see https://code.amazon.com/packages/AwsTcCatalogService/blobs/52e33e312d9b2cfb50da00d22814931fc2e06a1c/--/src/utils/attributeSchemas.ts#L356
   */
  devTeam?: Maybe<CatalogSingleSelectAttribute>;
  /**
   * Which technical domain this training object is relevant for.
   * For valid values, see https://code.amazon.com/packages/AwsTcCatalogService/blobs/52e33e312d9b2cfb50da00d22814931fc2e06a1c/--/src/utils/attributeSchemas.ts#L35
   * Valid for products only
   */
  domain?: Maybe<CatalogSingleSelectAttribute>;
  /**
   * The duration of the catalog item.
   * For LEARNING_PLANS, this is calculating by summing the duration of its child products
   * For other categories, it is statically set.
   */
  duration: CatalogDuration;
  /**
   * Indicates which evaluation template (i.e., which survey) should be used by learners when evaluating this item.
   * For valid values, see https://code.amazon.com/packages/AwsTcCatalogService/blobs/52e33e312d9b2cfb50da00d22814931fc2e06a1c/--/src/utils/attributeSchemas.ts#L345
   * Valid for products only
   */
  evaluationTemplate?: Maybe<CatalogSingleSelectAttribute>;
  /**
   * Related Certification exam image url.
   * Valid for products only
   */
  examCertificationImageUrl?: Maybe<CatalogStringAttribute>;
  /**
   * Related Certification exam name.
   * Valid for products only
   */
  examCertificationName?: Maybe<CatalogStringAttribute>;
  /**
   * When this item will be expired. Used to configure end-of-life rules.
   * When date comes, item will be marked as 'INACTIVE'
   * Valid for products only
   */
  expirationDate?: Maybe<CatalogStringAttribute>;
  /**
   * Text goal of completing this item.
   * Free form string
   * Valid for products only
   */
  goal?: Maybe<CatalogStringAttribute>;
  /** Groups item is in. This is derived from what groups this item's root item is in */
  groups: CatalogGroupConnection;
  /**
   * Unique ID of item.
   * Was previously a v4 UUID, but is being updated to be a friendlier, shorter ID.
   */
  id?: Maybe<Scalars['ID']['output']>;
  /**
   * Which industry segments this training object is relevant for.
   * For valid values, see https://code.amazon.com/packages/AwsTcCatalogService/blobs/52e33e312d9b2cfb50da00d22814931fc2e06a1c/--/src/utils/attributeSchemas.ts#L162
   * Valid for products only
   */
  industrySegments?: Maybe<CatalogMultiSelectAttribute>;
  /**
   * Which industry verticals this training object is relevant for.
   * For valid values, see https://code.amazon.com/packages/AwsTcCatalogService/blobs/52e33e312d9b2cfb50da00d22814931fc2e06a1c/--/src/utils/attributeSchemas.ts#L140
   * Valid for products only
   */
  industryVerticals?: Maybe<CatalogMultiSelectAttribute>;
  /**
   * Which audience this training object is relevant for.
   * Free form entries.
   * Valid for products only
   */
  intendedAudience?: Maybe<CatalogStringListAttribute>;
  /** Language code of item content (e.g., en-US). Immutable. */
  languageCode: Scalars['String']['output'];
  /**
   * Which learning styles this training object is relevant for.
   * For valid values, see https://code.amazon.com/packages/AwsTcCatalogService/blobs/mainline/--/src/utils/attributeSchemas.ts
   * Valid for products only
   */
  learningStyles?: Maybe<CatalogMultiSelectAttribute>;
  /**
   * This is a TEMPORARY field! Don't query this in production workflows.
   * It is only provided as a helper for systems that wish to backfill their data from the legacy ID to the newer, short ID.
   * All production workflows should use 'id'
   */
  legacyId?: Maybe<Scalars['ID']['output']>;
  /**
   * The actions the calling user is licensed to perform on this item (e.g., VIEW, VIEW + ACCESS)
   * Applicable for products
   */
  licensedUserActions?: Maybe<Array<CatalogLicenseAction>>;
  /** Modality: DIGITAL, LIVE, or BLENDED */
  modalityDelivery: CatalogModalityDelivery;
  /** Name of the catalog item */
  name: Scalars['String']['output'];
  /**
   * DEPRECATED: The namespace the item is in.
   * Do not use, as we are updating items to not live within a particular namespace.
   */
  namespace?: Maybe<CatalogNamespace>;
  /**
   * For products containing modules, this indicates how the learner can navigate through the module content
   * E.g., if they need to complete modules sequentially, or in any order, etc.
   * For valid values, see https://code.amazon.com/packages/AwsTcCatalogService/blobs/52e33e312d9b2cfb50da00d22814931fc2e06a1c/--/src/utils/attributeSchemas.ts#L338
   * Valid for products only
   */
  navigationSettings?: Maybe<CatalogSingleSelectAttribute>;
  /**
   * Text objectives of completing this item.
   * Free form string list
   * Valid for products only
   */
  objectives?: Maybe<CatalogStringListAttribute>;
  /**
   * Which organization the item is owned by (internal only)
   * For valid values, see https://code.amazon.com/packages/AwsTcCatalogService/blobs/52e33e312d9b2cfb50da00d22814931fc2e06a1c/--/src/utils/attributeSchemas.ts#L394
   */
  organization?: Maybe<CatalogSingleSelectAttribute>;
  /**
   * Outline used both for rendering and for retrieving xAPI metadata (e.g., activity IDs)
   * Valid for modules only
   */
  outline?: Maybe<Scalars['AWSJSON']['output']>;
  /**
   * Parents of this item. E.g., a course may be in a learning plan.
   * Please note that this field is eventually consistent.
   * If an item is added as a child of another item, it may take a few seconds for this field to be populated.
   */
  parents: CatalogItemConnection;
  /**
   * Text pre-requisites for completing this item.
   * Free form string
   * Valid for products only
   */
  prerequisites?: Maybe<CatalogStringAttribute>;
  /**
   * URL of preview video to show on PDP
   * Valid for products only
   */
  previewVideoUrl?: Maybe<Scalars['String']['output']>;
  /**
   * Used for showing free/subscription_needed on course card.
   * This is set automatically based on which catalog groups the item is a part of
   * Valid for products only
   */
  price?: Maybe<CatalogSingleSelectAttribute>;
  /** List of individuals who own this product (internal only) */
  productOwners?: Maybe<Array<User>>;
  /**
   * DEPRECATED: Average rating for this item.
   * This functionality is being replaced by reviewInsights from the Review/Rating Service
   */
  rating?: Maybe<Scalars['Float']['output']>;
  /** Related variant items that share the same root */
  relatedVariants: CatalogItemConnection;
  reviewInsights?: Maybe<ReviewInsights>;
  /**
   * Which job roles this training object is relevant for.
   * For valid values, see https://code.amazon.com/packages/AwsTcCatalogService/blobs/52e33e312d9b2cfb50da00d22814931fc2e06a1c/--/src/utils/attributeSchemas.ts#L120
   * Valid for products only
   */
  roles?: Maybe<CatalogMultiSelectAttribute>;
  /** Root item ID this item variant is a part of (immutable once set) */
  rootId?: Maybe<Scalars['ID']['output']>;
  /** Root item this catalog item variant is a part of */
  rootItem?: Maybe<CatalogRootItem>;
  /** The semantic version object, which parses out the version into major/minor/patch fields */
  semanticVersion: SemanticVersion;
  /**
   * This is a TEMPORARY field! Don't query this in production workflows.
   * It is only provided as a helper for systems that wish to backfill their data from the legacy ID to the newer, short ID.
   * All production workflows should use 'id'
   */
  shortId?: Maybe<Scalars['ID']['output']>;
  /**
   * Which skill level this training object is relevant for.
   * For valid values, see https://code.amazon.com/packages/AwsTcCatalogService/blobs/52e33e312d9b2cfb50da00d22814931fc2e06a1c/--/src/utils/attributeSchemas.ts#L83
   * Valid for products only
   */
  skillLevel?: Maybe<CatalogSingleSelectAttribute>;
  /**
   * SKU for item
   * Valid for products only
   */
  sku?: Maybe<CatalogStringAttribute>;
  /** Files with additional information related to the catalogItem for internal admin use in LCMS */
  sourceFiles?: Maybe<Array<FileEntity>>;
  /** URLS with additional information related to the catalogItem for internal admin use in LCMS */
  sourceUrls?: Maybe<Array<Scalars['AWSURL']['output']>>;
  /** Metadata updates submitted by content creator in LCMS (internal only) - only applies on non-draft version */
  stagedMetadataChange?: Maybe<CatalogItemMetadataChange>;
  /** Lifecycle status of the item version being viewed (e.g., DRAFT, ACTIVE, MAINTENANCE, INACTIVE) */
  status: CatalogItemStatus;
  /** For items that are deprecated or planned to be deprecated, they can have a designated successor which should replace them */
  successor?: Maybe<CatalogItem>;
  /**
   * Indicates any system requirements the learner needs to complete this training (e.g., requires 'linux')
   * Free form string.
   * Valid for GBL only
   */
  systemRequirement?: Maybe<CatalogStringAttribute>;
  /** Key/value tag pairs for LCMS (internal only) */
  tags?: Maybe<Array<Maybe<CatalogItemTag>>>;
  /**
   * Which technologies this training object is relevant for.
   * Free form entries.
   * Valid for products only
   */
  technologies?: Maybe<CatalogStringListAttribute>;
  /** DEPRECATED: The thumbnail for this catalog item */
  thumbnailFile?: Maybe<CatalogFile>;
  /** DEPRECATED: URL of thumbnail image. We don't have thumbnails with HC launch */
  thumbnailImageUrl?: Maybe<Scalars['String']['output']>;
  /**
   * URL of training content (e.g., in LXP or renderer)
   * valid for modules only
   */
  trainingDeliveryUrl?: Maybe<Scalars['String']['output']>;
  /**
   * URL of item PDP. Constructed dynamically based on IDs with friendly SEO slug
   * Valid for products only
   */
  trainingObjectUrl?: Maybe<Scalars['String']['output']>;
  /**
   * More specific than category, values like 'digital_course', 'self_paced_lab', etc.
   * For valid values, see https://code.amazon.com/packages/AwsTcCatalogService/blobs/52e33e312d9b2cfb50da00d22814931fc2e06a1c/--/src/utils/attributeSchemas.ts#L89 (or more recent)
   * Must match with root item if set
   */
  type?: Maybe<CatalogSingleSelectAttribute>;
  /**
   * DEPRECATED: URL of item page in the vendor. Dynamically constructed for digital courses -> vendorID as custom identifier, slug as custom attribute
   * See trainingObjectUrl
   */
  vendorUrl?: Maybe<Scalars['String']['output']>;
  /**
   * The 'id' plus a semantic version suffix (e.g., ABC:001.002.003).
   * Used to identify the given version retrieved.
   */
  versionedId?: Maybe<Scalars['ID']['output']>;
  /**
   * Whether item is RESTRICTED (DCT) or GENERAL. Immutable once set. (internal only)
   * RESTRICTED items filtered from external search/recommendations
   */
  visibility?: Maybe<CatalogItemVisibility>;
  /** Workflows associated with this CatalogItem (internal only) */
  workflows?: Maybe<EntityWorkflows>;
};


/** Extension of the Catalog Item so callers can also fetch review data for an item */
export type CatalogItemChildrenArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


/** Extension of the Catalog Item so callers can also fetch review data for an item */
export type CatalogItemCustomAttributeByKeyArgs = {
  key: Scalars['String']['input'];
  namespaceId?: InputMaybe<Scalars['String']['input']>;
};


/** Extension of the Catalog Item so callers can also fetch review data for an item */
export type CatalogItemGroupsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


/** Extension of the Catalog Item so callers can also fetch review data for an item */
export type CatalogItemParentsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


/** Extension of the Catalog Item so callers can also fetch review data for an item */
export type CatalogItemRelatedVariantsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


/** Extension of the Catalog Item so callers can also fetch review data for an item */
export type CatalogItemWorkflowsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * Category of catalog item.
 * More specific than classification, values like LEARNING_PLAN, COURSE, MODULE, etc.
 * LEARNING_PLAN: Contains other products
 * COURSE: Contains modules
 * MODULE: Modules
 */
export enum CatalogItemCategory {
  Course = 'COURSE',
  LearningPlan = 'LEARNING_PLAN',
  Module = 'MODULE'
}

/**
 * Classification of item. A product is an item that can be enrolled in, purchased, or has a transcript.
 * Some products (e.g., learning plans) contain other products as children, while other products can contain only modules.
 */
export enum CatalogItemClassification {
  Module = 'MODULE',
  Product = 'PRODUCT',
  ProductCollection = 'PRODUCT_COLLECTION'
}

/** Completion criteria as a number of modules finished */
export type CatalogItemCompletionCountCriteria = {
  __typename?: 'CatalogItemCompletionCountCriteria';
  /** count indicates how many of the children modules must be complete for the product to be complete */
  count: Scalars['Int']['output'];
};

/** Input for specifying completion criteria based on count */
export type CatalogItemCompletionCountCriteriaInput = {
  /** Count of items that need to be completed */
  count: Scalars['Int']['input'];
};

/** Union type for different completion criteria of a catalog item */
export type CatalogItemCompletionCriteria = CatalogItemCompletionCountCriteria | CatalogItemCompletionMarkerCriteria | CatalogItemCompletionPercentageCriteria;

/** Completion criteria as a specific child item ID being marked as the end item */
export type CatalogItemCompletionMarkerCriteria = {
  __typename?: 'CatalogItemCompletionMarkerCriteria';
  /** Indicates which child ID needs to be complete for the product to be complete */
  itemId: Scalars['ID']['output'];
};

/** Input for specifying completion criteria based on completing a specific marked child */
export type CatalogItemCompletionMarkerCriteriaInput = {
  /**
   * ID of child which is set as completion marker
   * Must be a valid child ID
   */
  itemId: Scalars['ID']['input'];
};

/** Completion criteria as a percentage of content finished */
export type CatalogItemCompletionPercentageCriteria = {
  __typename?: 'CatalogItemCompletionPercentageCriteria';
  /**
   * percentage indicates what percentage of the children modules must be finished for the product to be complete
   * Currently only valid value is '100'
   */
  percentage: Scalars['Int']['output'];
};

/** Input for specifying completion criteria based on percentage */
export type CatalogItemCompletionPercentageCriteriaInput = {
  /** Percentage of completion required */
  percentage: Scalars['Int']['input'];
};

/** Paginated list of catalog items */
export type CatalogItemConnection = {
  __typename?: 'CatalogItemConnection';
  /** List of CatalogItem nodes */
  nodes: Array<CatalogItem>;
  /** Pagination information */
  pageInfo?: Maybe<PageInfo>;
};

/** DEPRECATED: Input for creating a catalog item */
export type CatalogItemInput = {
  /** See CatalogItem.accessibilityCompliant */
  accessibilityCompliant?: InputMaybe<Scalars['Boolean']['input']>;
  /** See CatalogItem.activationDate */
  activationDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  /** See CatalogItem.approvedForReuse */
  approvedForReuse?: InputMaybe<Scalars['Boolean']['input']>;
  /** See CatalogItem.awsServices */
  awsServices?: InputMaybe<Array<Scalars['String']['input']>>;
  /** See CatalogItem.badgeImageUrl */
  badgeImageUrl?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.badgeName */
  badgeName?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.badgeTemplateId */
  badgeTemplateId?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.category */
  category: CatalogItemCategory;
  /**
   * See CatalogItem.children
   * Use this if the parent should point to any version of the child (i.e., the default version) ; e.g., a learning plan version pointing to a course default version
   * This is allowed for products that contain other products
   */
  childIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /**
   * See CatalogItem.children
   * Use this if the parent should point to a specific version of the child; e.g., a course version pointing to a specific version of a module
   * This is allowed for products that contain modules.
   * For ID "someidentifier" and semantic version 001.000.000, the format of the versioned ID is "someidentifier:001.000.000".
   */
  childVersionedIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** See CatalogItem.classification */
  classification?: InputMaybe<CatalogItemClassification>;
  /** See CatalogItem.completionBadge */
  completionBadge?: InputMaybe<Scalars['Boolean']['input']>;
  /** See CatalogItem.completionCertificate */
  completionCertificate?: InputMaybe<Scalars['Boolean']['input']>;
  /** See CatalogItem.completionCriteria */
  completionCountCriteria?: InputMaybe<CatalogItemCompletionCountCriteriaInput>;
  /** See CatalogItem.completionCriteria */
  completionMarkerCriteria?: InputMaybe<CatalogItemCompletionMarkerCriteriaInput>;
  /** See CatalogItem.completionCriteria */
  completionPercentageCriteria?: InputMaybe<CatalogItemCompletionPercentageCriteriaInput>;
  /** See CatalogItem.contentFiles */
  contentFiles?: InputMaybe<Array<FileEntityIdVersion>>;
  /** See CatalogItem.customAttributes */
  customAttributes?: InputMaybe<Array<CustomAttributeInput>>;
  /** See CatalogItem.customIdentifiers */
  customIdentifiers?: InputMaybe<Array<CatalogCustomIdentifierInput>>;
  /** See CatalogItem.description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.devTeam */
  devTeam?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.domain */
  domain?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.duration */
  duration: CatalogDurationInput;
  /** See CatalogItem.evaluationTemplate */
  evaluationTemplate?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.examCertificationImageUrl */
  examCertificationImageUrl?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.examCertificationName */
  examCertificationName?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.expirationDate */
  expirationDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  /** See CatalogItem.goal */
  goal?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.industrySegments */
  industrySegments?: InputMaybe<Array<Scalars['String']['input']>>;
  /** See CatalogItem.industryVerticals */
  industryVerticals?: InputMaybe<Array<Scalars['String']['input']>>;
  /** See CatalogItem.intendedAudience */
  intendedAudience?: InputMaybe<Array<Scalars['String']['input']>>;
  /** See CatalogItem.languageCode */
  languageCode: Scalars['String']['input'];
  /** See CatalogItem.learningStyles */
  learningStyles?: InputMaybe<Array<Scalars['String']['input']>>;
  /** See CatalogItem.modalityDelivery */
  modalityDelivery: CatalogModalityDelivery;
  /** See CatalogItem.name */
  name: Scalars['String']['input'];
  /** See CatalogItem.navigationSettings */
  navigationSettings?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.objectives */
  objectives?: InputMaybe<Array<Scalars['String']['input']>>;
  /** See CatalogItem.organization */
  organization?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.outline */
  outline?: InputMaybe<Scalars['AWSJSON']['input']>;
  /** See CatalogItem.prerequisites */
  prerequisites?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.previewVideoUrl */
  previewVideoUrl?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.productOwners */
  productOwners?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** See CatalogItem.rating */
  rating?: InputMaybe<Scalars['Float']['input']>;
  /** See CatalogItem.roles */
  roles?: InputMaybe<Array<Scalars['String']['input']>>;
  /** See CatalogItem.skillLevel */
  skillLevel?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.sku */
  sku?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.sourceFiles */
  sourceFiles?: InputMaybe<Array<FileEntityIdVersion>>;
  /** See CatalogItem.sourceUrls */
  sourceUrls?: InputMaybe<Array<Scalars['AWSURL']['input']>>;
  /** See CatalogItem.systemRequirement */
  systemRequirement?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.tags */
  tags?: InputMaybe<Array<CatalogItemTagInput>>;
  /** See CatalogItem.technologies */
  technologies?: InputMaybe<Array<Scalars['String']['input']>>;
  /** See CatalogItem.thumbnailFile */
  thumbnailFileId?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.thumbnailImageUrl */
  thumbnailImageUrl?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.trainingDeliveryUrl */
  trainingDeliveryUrl?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.type */
  type?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.visibility */
  visibility?: InputMaybe<CatalogItemVisibility>;
};

/** Staged Metadata changes for a CatalogItem */
export type CatalogItemMetadataChange = {
  __typename?: 'CatalogItemMetadataChange';
  /**
   * Staged Updated AWS services for the catalog item
   * Which aws services this training object is relevant for.
   * For valid values, see https://code.amazon.com/packages/AwsTcCatalogService/blobs/52e33e312d9b2cfb50da00d22814931fc2e06a1c/--/src/utils/attributeSchemas.ts#L174
   * Valid for products only
   */
  awsServices?: Maybe<CatalogMultiSelectAttribute>;
  /**
   * Staged Updated badge image URL for the catalog item
   * The url of a credly badge image to issue.
   * This attribute eventually to be removed in favor of functionality by Rewards Service.
   * Valid for products only
   */
  badgeImageUrl?: Maybe<CatalogStringAttribute>;
  /**
   * Staged Updated badge name for the catalog item
   * The name of a credly badge to issue.
   * This attribute eventually to be removed in favor of functionality by Rewards Service.
   * Valid for products only
   */
  badgeName?: Maybe<CatalogStringAttribute>;
  /**
   * Staged Updated badge template ID for the catalog item
   * ID of credly badge template to issue.
   * This attribute eventually to be removed in favor of functionality by Rewards Service.
   * Valid for products only
   */
  badgeTemplateId?: Maybe<CatalogStringAttribute>;
  /**
   * Staged Updated completion badge flag for the catalog item
   * Whether to issue completion badge when item is completed.
   * Valid for products only
   */
  completionBadge?: Maybe<CatalogBooleanAttribute>;
  /**
   * Staged Updated completion certificate flag for the catalog item
   * Whether to issue completion certificate when item is completed.
   * Valid for products only
   */
  completionCertificate?: Maybe<CatalogBooleanAttribute>;
  /** Staged Updated description of the catalog item */
  description?: Maybe<Scalars['String']['output']>;
  /**
   * Staged Updated development team for the catalog item
   * Which internal team is responsible for developing this item (internal only)
   * For valid values, see https://code.amazon.com/packages/AwsTcCatalogService/blobs/52e33e312d9b2cfb50da00d22814931fc2e06a1c/--/src/utils/attributeSchemas.ts#L356
   */
  devTeam?: Maybe<CatalogSingleSelectAttribute>;
  /**
   * Staged Updated domain for the catalog item
   * Which technical domain this training object is relevant for.
   * For valid values, see https://code.amazon.com/packages/AwsTcCatalogService/blobs/52e33e312d9b2cfb50da00d22814931fc2e06a1c/--/src/utils/attributeSchemas.ts#L35
   * Valid for products only
   */
  domain?: Maybe<CatalogSingleSelectAttribute>;
  /** Staged Updated duration of the catalog item */
  duration?: Maybe<CatalogDuration>;
  /**
   * Staged Updated evaluation template for the catalog item
   * Indicates which evaluation template (i.e., which survey) should be used by learners when evaluating this item.
   * For valid values, see https://code.amazon.com/packages/AwsTcCatalogService/blobs/52e33e312d9b2cfb50da00d22814931fc2e06a1c/--/src/utils/attributeSchemas.ts#L345
   * Valid for products only
   */
  evaluationTemplate?: Maybe<CatalogSingleSelectAttribute>;
  /**
   * Staged Updated exam certification image URL for the catalog item
   * Related Certification exam image url.
   * Valid for products only
   */
  examCertificationImageUrl?: Maybe<CatalogStringAttribute>;
  /**
   * Staged Updated exam certification name for the catalog item
   * Related Certification exam name.
   * Valid for products only
   */
  examCertificationName?: Maybe<CatalogStringAttribute>;
  /**
   * Staged Updated goal for the catalog item
   * Text goal of completing this item.
   * Free form string
   * Valid for products only
   */
  goal?: Maybe<CatalogStringAttribute>;
  /**
   * Staged Updated industry segments for the catalog item
   * Which industry segments this training object is relevant for.
   * For valid values, see https://code.amazon.com/packages/AwsTcCatalogService/blobs/52e33e312d9b2cfb50da00d22814931fc2e06a1c/--/src/utils/attributeSchemas.ts#L162
   * Valid for products only
   */
  industrySegments?: Maybe<CatalogMultiSelectAttribute>;
  /**
   * Staged Updated industry verticals for the catalog item
   * Which industry verticals this training object is relevant for.
   * For valid values, see https://code.amazon.com/packages/AwsTcCatalogService/blobs/52e33e312d9b2cfb50da00d22814931fc2e06a1c/--/src/utils/attributeSchemas.ts#L140
   * Valid for products only
   */
  industryVerticals?: Maybe<CatalogMultiSelectAttribute>;
  /**
   * Staged Updated intended audience for the catalog item
   * Which audience this training object is relevant for.
   * Free form entries.
   * Valid for products only
   */
  intendedAudience?: Maybe<CatalogStringListAttribute>;
  /** Staged Updated name of the catalog item */
  name?: Maybe<Scalars['String']['output']>;
  /**
   * Staged Updated objectives for the catalog item
   * Text objectives of completing this item.
   * Free form string list
   * Valid for products only
   */
  objectives?: Maybe<CatalogStringListAttribute>;
  /**
   * Staged Updated prerequisites for the catalog item
   * Text pre-requisites for completing this item.
   * Free form string
   * Valid for products only
   */
  prerequisites?: Maybe<CatalogStringAttribute>;
  /**
   * Staged Updated preview video URL for the catalog item
   * URL of preview video to show on PDP
   * Valid for products only
   */
  previewVideoUrl?: Maybe<Scalars['String']['output']>;
  /**
   * Staged Updated product owners for the catalog item
   * List of individuals who own this product (internal only)
   */
  productOwners?: Maybe<Array<User>>;
  /**
   * Staged Updated roles for the catalog item
   * Which job roles this training object is relevant for.
   * For valid values, see https://code.amazon.com/packages/AwsTcCatalogService/blobs/52e33e312d9b2cfb50da00d22814931fc2e06a1c/--/src/utils/attributeSchemas.ts#L120
   * Valid for products only
   */
  roles?: Maybe<CatalogMultiSelectAttribute>;
  /**
   * Staged Updated skill level for the catalog item
   * Which skill level this training object is relevant for.
   * For valid values, see https://code.amazon.com/packages/AwsTcCatalogService/blobs/52e33e312d9b2cfb50da00d22814931fc2e06a1c/--/src/utils/attributeSchemas.ts#L83
   * Valid for products only
   */
  skillLevel?: Maybe<CatalogSingleSelectAttribute>;
  /**
   * Staged Updated system requirement for the catalog item
   * Indicates any system requirements the learner needs to complete this training (e.g., requires 'linux')
   * Free form string.
   * Valid for GBL only
   */
  systemRequirement?: Maybe<CatalogStringAttribute>;
  /**
   * Staged Updated tags for the catalog item
   * Key/value tag pairs for LCMS (internal only)
   */
  tags?: Maybe<Array<Maybe<CatalogItemTag>>>;
  /**
   * Staged Updated technologies for the catalog item
   * Which technologies this training object is relevant for.
   * Free form entries.
   * Valid for products only
   */
  technologies?: Maybe<CatalogStringListAttribute>;
};

/** Input for staging metadata changes of a catalog item */
export type CatalogItemMetadataChangeInput = {
  /** See CatalogItem.awsServices */
  awsServices?: InputMaybe<Array<Scalars['String']['input']>>;
  /** See CatalogItem.badgeImageUrl */
  badgeImageUrl?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.badgeName */
  badgeName?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.badgeTemplateId */
  badgeTemplateId?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.completionBadge */
  completionBadge?: InputMaybe<Scalars['Boolean']['input']>;
  /** See CatalogItem.completionCertificate */
  completionCertificate?: InputMaybe<Scalars['Boolean']['input']>;
  /** See CatalogItem.description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.devTeam */
  devTeam?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.domain */
  domain?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.duration */
  duration?: InputMaybe<CatalogDurationInput>;
  /** See CatalogItem.evaluationTemplate */
  evaluationTemplate?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.examCertificationImageUrl */
  examCertificationImageUrl?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.examCertificationName */
  examCertificationName?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.goal */
  goal?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.industrySegments */
  industrySegments?: InputMaybe<Array<Scalars['String']['input']>>;
  /** See CatalogItem.industryVerticals */
  industryVerticals?: InputMaybe<Array<Scalars['String']['input']>>;
  /** See CatalogItem.intendedAudience */
  intendedAudience?: InputMaybe<Array<Scalars['String']['input']>>;
  /** See CatalogItem.name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.objectives */
  objectives?: InputMaybe<Array<Scalars['String']['input']>>;
  /** See CatalogItem.prerequisites */
  prerequisites?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.previewVideoUrl */
  previewVideoUrl?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.productOwners */
  productOwners?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** See CatalogItem.roles */
  roles?: InputMaybe<Array<Scalars['String']['input']>>;
  /** See CatalogItem.skillLevel */
  skillLevel?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.systemRequirement */
  systemRequirement?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.tags */
  tags?: InputMaybe<Array<CatalogItemTagInput>>;
  /** See CatalogItem.technologies */
  technologies?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** An entity that a review is tied to (e.g. Catalog Item) */
export type CatalogItemReferent = ReviewReferent & {
  __typename?: 'CatalogItemReferent';
  /** Unversioned Catalog ID */
  id: Scalars['ID']['output'];
  /** Language code of catalog item */
  languageCode: Scalars['String']['output'];
  /** Type of referent, should always be CATALOG_ITEM */
  type: ReferentType;
  /** Semantic Version of Catalog Item */
  version: Scalars['String']['output'];
  /** Catalog versioned ID of Catalog Item */
  versionedId: Scalars['ID']['output'];
};

/**
 * Lifecycle status values of Item Version.
 * When an item is first created (or a new version is created), they are created in DRAFT state.
 * These are not yet visible to customers. A version can then be activated, at which point it becomes available to consume
 * A version can be put under maintenance, where the metadata is still visible, but content not available. An item can return from MAINTENANCE to ACTIVE.
 * When a version is finished, it is made INACTIVE which is a terminal state for the version
 */
export enum CatalogItemStatus {
  Active = 'ACTIVE',
  Draft = 'DRAFT',
  Inactive = 'INACTIVE',
  Maintenance = 'MAINTENANCE'
}

/** Key/value pair tag for diverse use cases in LCMS */
export type CatalogItemTag = {
  __typename?: 'CatalogItemTag';
  /** Tag key */
  key: Scalars['String']['output'];
  /** Tag value */
  value: Scalars['String']['output'];
};

/** Input for creating a catalog item tag */
export type CatalogItemTagInput = {
  /** Key of the tag */
  key: Scalars['String']['input'];
  /** Value of the tag */
  value: Scalars['String']['input'];
};

/** A dimension value used by a variant. */
export type CatalogItemVariantDimension = {
  __typename?: 'CatalogItemVariantDimension';
  /** Dimension key of variant (e.g., LANGUAGE) */
  key: CatalogItemVariantDimensionType;
  /** Dimension value of variant (e.g., en-US) */
  value: Scalars['String']['output'];
};

/** Input for a single variant dimension */
export type CatalogItemVariantDimensionInput = {
  /** Key of the variant dimension */
  key: CatalogItemVariantDimensionType;
  /** Value of the variant dimension */
  value: Scalars['String']['input'];
};

/** Possible dimension types of a catalog item variant. Current allowed in only language. */
export enum CatalogItemVariantDimensionType {
  Language = 'LANGUAGE'
}

/** Input for specifying variant dimensions */
export type CatalogItemVariantDimensionsInput = {
  /** List of dimension key-value pairs */
  dimensions?: InputMaybe<Array<CatalogItemVariantDimensionInput>>;
};

/**
 * An instance in the variant dimension space that is occupied by a given variant.
 * For example, if a given root has dimensions of language code, and instance here
 * will be one specific language, with the dimensions of [LANGUAGE: 'en-US']
 */
export type CatalogItemVariantInstance = {
  __typename?: 'CatalogItemVariantInstance';
  /** The dimension values the variant uses. E.g., a language variant may have a language code of 'en-US' as the value here */
  dimensions: Array<CatalogItemVariantDimension>;
};

/** Input for specifying the scope of a catalog item variant */
export type CatalogItemVariantScopeInput = {
  /** Whether to mark this variant as the default variant of its associated root */
  isDefaultVariant?: InputMaybe<Scalars['Boolean']['input']>;
  /** Root ID to associate variant with */
  rootId: Scalars['ID']['input'];
};

/**
 * Whether object is RESTRICTED (DCT) or GENERAL.
 * RESTRICTED items filtered from external search/recommendations
 */
export enum CatalogItemVisibility {
  General = 'GENERAL',
  Restricted = 'RESTRICTED'
}

/**
 * Similar enum to License Service, not reusing that one b/c of constraints with apollo composition
 * Indicates the actions a user can take on a given catalog item
 */
export enum CatalogLicenseAction {
  Access = 'ACCESS',
  View = 'VIEW'
}

/** A common object to return a localized string: language code plus value. */
export type CatalogLocalizedString = {
  __typename?: 'CatalogLocalizedString';
  /** The language code for the localized string (e.g., 'en-US', 'ja-JP') */
  languageCode: Scalars['String']['output'];
  /** The localized string value */
  value: Scalars['String']['output'];
};

export type CatalogLocalizedStringInput = {
  languageCode: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

/** Modality for how item is delivered */
export enum CatalogModalityDelivery {
  Blended = 'BLENDED',
  Digital = 'DIGITAL',
  Live = 'LIVE'
}

/** Wrapper around multi-select (similar to enums) attributes to add display strings */
export type CatalogMultiSelectAttribute = {
  __typename?: 'CatalogMultiSelectAttribute';
  /** attribute key display string. E.g., `domain` key will have `Domain` display string. Based on caller's language setting. */
  keyDisplayString?: Maybe<Scalars['String']['output']>;
  /** Values of multi select attribute. Must come from set of allowed values in attribute schema. */
  values?: Maybe<Array<Scalars['String']['output']>>;
  /**
   * Display strings of values. This is a list, and will align with values list (e.g., valuesDisplayStrings[0] is the display string for values[0]).
   * Based on caller's language preference.
   */
  valuesDisplayStrings?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

/** A catalog namespace allows Catalog Service data producers to define custom attribute schemas to extend the default schema and add more metadata. */
export type CatalogNamespace = {
  __typename?: 'CatalogNamespace';
  /** Audit fields */
  auditMetadata: CatalogAuditMetadata;
  /** Returns paginated custom attribute schemas in namespace. */
  customAttributeSchemas: CatalogAttributeSchemaConnection;
  /** Description of namespace */
  description: Scalars['String']['output'];
  /**
   * DEPRECATED: Returns groups(s) with a custom identifier in a namespace.
   * As groups will no longer live within a namespace, use Query.catalogGroupsByCustomIdentifier
   */
  groupsByCustomIdentifier: CatalogGroupConnection;
  /** Unique ID of the namespace (friendly) */
  id: Scalars['String']['output'];
  /**
   * DEPRECATED: Returns all items in namespace.
   * Items will soon no longer live within an individual namespace.
   */
  items: CatalogItemConnection;
  /**
   * DEPRECATED: Returns item(s) with a custom identifier in a namespace.
   * As items will no longer live within a namespace, use Query.catalogItemsByCustomIdentifier
   */
  itemsByCustomIdentifier: CatalogItemConnection;
};


/** A catalog namespace allows Catalog Service data producers to define custom attribute schemas to extend the default schema and add more metadata. */
export type CatalogNamespaceCustomAttributeSchemasArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


/** A catalog namespace allows Catalog Service data producers to define custom attribute schemas to extend the default schema and add more metadata. */
export type CatalogNamespaceGroupsByCustomIdentifierArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  value: Scalars['String']['input'];
};


/** A catalog namespace allows Catalog Service data producers to define custom attribute schemas to extend the default schema and add more metadata. */
export type CatalogNamespaceItemsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


/** A catalog namespace allows Catalog Service data producers to define custom attribute schemas to extend the default schema and add more metadata. */
export type CatalogNamespaceItemsByCustomIdentifierArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  value: Scalars['String']['input'];
};

/**
 * A Catalog Root Item is used to tie together equivalent Catalog Items under the object.
 * E.g., the same course offered in English and Spanish may have 2 variant Catalog Items,
 * but are both part of the same root item.
 * Used for things like equivalencies in licensing, catalog group membership, training assignment, discovery, and eventually transcript.
 */
export type CatalogRootItem = {
  __typename?: 'CatalogRootItem';
  /** Audit metadata. */
  auditMetadata: CatalogAuditMetadata;
  /**
   * More specific than classification, values like LEARNING_PLAN, COURSE, MODULE, etc.
   * Must match on a root item and its variants
   */
  category: CatalogItemCategory;
  /**
   * Classification: PRODUCT (something that can be enrolled in or purchased - gets its own transcript) or MODULE (individual piece of content such as xapi or lab).
   * Some products are composed of other nested products or modules.
   * Must match on a root item and its variants
   */
  classification: CatalogItemClassification;
  /** List of custom key/value pair identifiers used to index/query this item, beyond its generated ID. */
  customIdentifiers?: Maybe<Array<CatalogCustomIdentifier>>;
  /** The default variant of this root item */
  defaultVariant?: Maybe<CatalogItem>;
  /** Description of the root item */
  description?: Maybe<Scalars['String']['output']>;
  /** Groups this root item is a part of. */
  groups: CatalogGroupConnection;
  /** Modality of delivery: DIGITAL, LIVE, or BLENDED */
  modalityDelivery: CatalogModalityDelivery;
  /** Name of the root item */
  name?: Maybe<Scalars['String']['output']>;
  /**
   * Set of unique dimension values for each variant (the occupied space), but not the variants themselves.
   * E.g., [(Language: en-US, (Language: ja-JP), ...]
   */
  presentVariantInstances?: Maybe<Array<CatalogItemVariantInstance>>;
  /** Unique id of this root item. */
  rootId: Scalars['ID']['output'];
  /**
   * More specific than category, values like 'digital_course', 'self_paced_lab', etc.
   * For valid types, see https://code.amazon.com/packages/AwsTcCatalogService/blobs/52e33e312d9b2cfb50da00d22814931fc2e06a1c/--/src/utils/attributeSchemas.ts#L89 (or more recent)
   * Must match on a root item and its variants
   */
  type: CatalogSingleSelectAttribute;
  /**
   * The available dimensions for variants of this root. Each variant must have a unique set of dimension values.
   * Most obvious dimension is language code, but there can be others.
   */
  variantDimensionTypes: Array<CatalogItemVariantDimensionType>;
  /** Variant items of this root. Will return default version of each variant. */
  variantItems: CatalogItemConnection;
  /** Whether root item is RESTRICTED (DCT) or GENERAL. Immutable once set. (internal only) */
  visibility?: Maybe<CatalogItemVisibility>;
};


/**
 * A Catalog Root Item is used to tie together equivalent Catalog Items under the object.
 * E.g., the same course offered in English and Spanish may have 2 variant Catalog Items,
 * but are both part of the same root item.
 * Used for things like equivalencies in licensing, catalog group membership, training assignment, discovery, and eventually transcript.
 */
export type CatalogRootItemGroupsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


/**
 * A Catalog Root Item is used to tie together equivalent Catalog Items under the object.
 * E.g., the same course offered in English and Spanish may have 2 variant Catalog Items,
 * but are both part of the same root item.
 * Used for things like equivalencies in licensing, catalog group membership, training assignment, discovery, and eventually transcript.
 */
export type CatalogRootItemVariantItemsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

/** Paginated list of catalog root items */
export type CatalogRootItemConnection = {
  __typename?: 'CatalogRootItemConnection';
  /** List of CatalogRootItem nodes */
  nodes: Array<CatalogRootItem>;
  /** Pagination information */
  pageInfo?: Maybe<PageInfo>;
};

/** Wrapper around single select (similar to enums) attributes to add display strings */
export type CatalogSingleSelectAttribute = {
  __typename?: 'CatalogSingleSelectAttribute';
  /** attribute key display string. E.g., `domain` key will have `Domain` display string. Based on caller's language setting. */
  keyDisplayString?: Maybe<Scalars['String']['output']>;
  /** Value of single select attribute. Must come from set of allowed values in attribute schema. */
  value: Scalars['String']['output'];
  /** Display strings of value (from schema). Based on caller's language preference. */
  valueDisplayString?: Maybe<Scalars['String']['output']>;
};

/** Wrapper around string attributes to add display strings */
export type CatalogStringAttribute = {
  __typename?: 'CatalogStringAttribute';
  /** attribute key display string. E.g., `domain` key will have `Domain` display string. Based on caller's language setting. */
  keyDisplayString?: Maybe<Scalars['String']['output']>;
  /** Value of string attribute. */
  value: Scalars['String']['output'];
};

/** Wrapper around string list attributes to add display strings */
export type CatalogStringListAttribute = {
  __typename?: 'CatalogStringListAttribute';
  /** attribute key display string. E.g., `domain` key will have `Domain` display string. Based on caller's language setting. */
  keyDisplayString?: Maybe<Scalars['String']['output']>;
  /** Value of string list attribute. */
  values: Array<Scalars['String']['output']>;
};

export type ChangeAssessmentStatusInput = {
  id: Scalars['ID']['input'];
  version: Scalars['Int']['input'];
};

export type CheckAndAssignLicenseToB2BUserResult = {
  __typename?: 'CheckAndAssignLicenseToB2BUserResult';
  isLicenseAssigned: Scalars['Boolean']['output'];
  isLicenseAvailable: Scalars['Boolean']['output'];
};

/** Checkout Session entity */
export type CheckoutSession = {
  __typename?: 'CheckoutSession';
  /** List of allowed billing countries that a session can accept. */
  allowedPaymentCountries?: Maybe<Array<Scalars['String']['output']>>;
  /**
   * types of payment methods that the session can accept.
   *
   * Applicable to "NEW_ORDER" session types
   */
  allowedPaymentMethodTypes?: Maybe<Array<PaymentMethodType>>;
  /** Audit metadata */
  auditMetadata: AuditMetadata;
  /**
   * Order that is created when session is finalized.
   *
   * Applicable to "NEW_ORDER" and "REPLACE_ORDER_ITEM" session types
   */
  createdOrder?: Maybe<Order>;
  /** Timestamp for when the session will be marked as "EXPIRED" and no longer mutable */
  expiresAt: Scalars['AWSDateTime']['output'];
  /** Unique ID of checkout session */
  id: Scalars['ID']['output'];
  /** Line items for the relevant order to create/update */
  items: Array<CheckoutSessionItem>;
  /** Locale (e.g., "en") to display session text in */
  locale: Scalars['String']['output'];
  /**
   * Payment method to use when finalizing checkout and creating an order
   * Applicable to "NEW_ORDER" session types
   */
  paymentMethod?: Maybe<PaymentMethod>;
  /** Post-checkout page configuration */
  postCheckoutPage: PostCheckoutPage;
  /** Pre-checkout page configuration */
  preCheckoutPage: PreCheckoutPage;
  /** Status of session */
  status: CheckoutSessionStatus;
  /** Type of CheckoutSession */
  type: CheckoutSessionType;
  /** URL to direct customer to so they can finalize checkout */
  url: Scalars['String']['output'];
  /** User session is active for */
  user: User;
};

export type CheckoutSessionConnection = {
  __typename?: 'CheckoutSessionConnection';
  nodes: Array<CheckoutSession>;
  pageInfo?: Maybe<PageInfo>;
};

/** A single item of a checkout session that is being purchased/modified */
export type CheckoutSessionItem = {
  __typename?: 'CheckoutSessionItem';
  /**
   * List for benefits actually applied to the user with selected offer
   * Also used to calculate benefit/free trial start and end time and send it to OrderService
   * So that downstream services can directly utilize this data
   */
  appliedBenefits?: Maybe<Array<OfferBenefit>>;
  /** Unique ID of session item */
  id: Scalars['ID']['output'];
  /** Metadata to associate with new order item when order created */
  metadata?: Maybe<Array<KeyValuePair>>;
  /**
   * Offer to be purchased, associated to line item
   * whether creating a new order or replacing a previously created
   * order item with a new one and a new offer
   */
  offer?: Maybe<Offer>;
  /**
   * Pre-existing order item that should be replaced on checkout.
   * Applicable for "REPLACE_ORDER_ITEM" session types
   */
  orderItemToReplace?: Maybe<OrderItem>;
  /**
   * Quantity of offer to purchase.
   *
   * Applicable for "NEW_ORDER" and "CANCEL_ORDER_ITEM" session types
   */
  quantity?: Maybe<Scalars['Int']['output']>;
};

/** Status of session */
export enum CheckoutSessionStatus {
  Complete = 'COMPLETE',
  Expired = 'EXPIRED',
  Open = 'OPEN',
  PendingCompletion = 'PENDING_COMPLETION'
}

/** A term (e.g., legal) that is shown on the Finalize Checkout Session component */
export type CheckoutSessionTerm = {
  __typename?: 'CheckoutSessionTerm';
  sections: Array<TextSection>;
};

export type CheckoutSessionTermInput = {
  sections: Array<TextSectionInput>;
};

export enum CheckoutSessionType {
  NewOrder = 'NEW_ORDER',
  ReplaceOrderItem = 'REPLACE_ORDER_ITEM'
}

export type ClassAttendanceQueryInput = {
  includeRemoved?: InputMaybe<Scalars['Boolean']['input']>;
  learningActivityId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export enum ClassStatus {
  Cancelled = 'CANCELLED',
  Delivered = 'DELIVERED',
  Hold = 'HOLD',
  New = 'NEW',
  Scheduled = 'SCHEDULED'
}

export type Classroom = {
  __typename?: 'Classroom';
  capacity?: Maybe<Capacity>;
  classroomArn: Scalars['String']['output'];
  courseId: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  endTime: Scalars['AWSTimestamp']['output'];
  errorDetails?: Maybe<Scalars['String']['output']>;
  instructors?: Maybe<Array<Maybe<Scalars['AWSEmail']['output']>>>;
  jamConfig?: Maybe<JamConfig>;
  langLocale: Scalars['String']['output'];
  learningActivityId?: Maybe<Scalars['String']['output']>;
  location: Location;
  providerArn: Scalars['String']['output'];
  startTime: Scalars['AWSTimestamp']['output'];
  status: ClassroomStatus;
  timezone: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

export type ClassroomAck = {
  __typename?: 'ClassroomAck';
  classroomArn: Scalars['String']['output'];
  status: ClassroomStatus;
};

export type ClassroomInput = {
  capacity: CapacityInput;
  clientRequestToken: Scalars['String']['input'];
  courseId: Scalars['String']['input'];
  createdBy?: InputMaybe<Scalars['String']['input']>;
  endTime: Scalars['AWSTimestamp']['input'];
  instructors?: InputMaybe<Array<InputMaybe<Scalars['AWSEmail']['input']>>>;
  jamConfig?: InputMaybe<JamConfigInput>;
  langLocale: Scalars['String']['input'];
  learningActivityId?: InputMaybe<Scalars['String']['input']>;
  location: LocationInput;
  namespace: Scalars['String']['input'];
  providerArn: Scalars['String']['input'];
  startTime: Scalars['AWSTimestamp']['input'];
  timezone: Scalars['String']['input'];
};

export enum ClassroomStatus {
  Cancelled = 'CANCELLED',
  EntitlementCreationFailed = 'ENTITLEMENT_CREATION_FAILED',
  EntitlementUpdateFailed = 'ENTITLEMENT_UPDATE_FAILED',
  Error = 'ERROR',
  InstructorInvitationCreationFailed = 'INSTRUCTOR_INVITATION_CREATION_FAILED',
  InstructorInvitationUpdateFailed = 'INSTRUCTOR_INVITATION_UPDATE_FAILED',
  JamCreationFailed = 'JAM_CREATION_FAILED',
  JamUpdateFailed = 'JAM_UPDATE_FAILED',
  LicenseTransactionFailed = 'LICENSE_TRANSACTION_FAILED',
  Provisioning = 'PROVISIONING',
  ProvisioningFailed = 'PROVISIONING_FAILED',
  Ready = 'READY',
  Updating = 'UPDATING'
}

export type ClassroomTrainingAssignment = {
  __typename?: 'ClassroomTrainingAssignment';
  auditMetadata: AuditMetadata;
  id: Scalars['ID']['output'];
  learningActivity: LearningActivity;
  trainingAssignmentStatus: TrainingAssignmentStatus;
  user: User;
};

export type ClassroomTrainingAssignmentConnection = {
  __typename?: 'ClassroomTrainingAssignmentConnection';
  nodes?: Maybe<Array<ClassroomTrainingAssignment>>;
  pageInfo?: Maybe<PageInfo>;
};

export type ClassroomTrainingAssignmentInput = {
  learningActivityId: Scalars['ID']['input'];
  userIds: Array<Scalars['ID']['input']>;
};

export type ClassroomTrainingAssignmentUserGroupsInput = {
  learningActivityId: Scalars['ID']['input'];
  userGroupIds: Array<Scalars['ID']['input']>;
};

export type Comment = {
  __typename?: 'Comment';
  auditMetadata: AuditMetadata;
  author: Scalars['String']['output'];
  content: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  status: Scalars['String']['output'];
};

export type CommentsConnection = {
  __typename?: 'CommentsConnection';
  nodes: Array<Comment>;
  pageInfo?: Maybe<PageInfo>;
};

export type CommercePlatformConfiguration = {
  __typename?: 'CommercePlatformConfiguration';
  awsAISPLOfferId?: Maybe<Scalars['String']['output']>;
  awsClassicOfferId?: Maybe<Scalars['String']['output']>;
  service: CommercePlatformService;
  serviceCode?: Maybe<Scalars['String']['output']>;
};

export enum CommercePlatformService {
  Sbis = 'SBIS',
  Sdms = 'SDMS'
}

export type CompleteFulfillmentItemInput = {
  completedDateTime: Scalars['AWSDateTime']['input'];
  fulfillmentId: Scalars['ID']['input'];
  fulfillmentItemId: Scalars['ID']['input'];
};

export type CompletePublishingWorkflowStepInput = {
  publishingWorkflowId: Scalars['ID']['input'];
  publishingWorkflowStepId: Scalars['ID']['input'];
  values?: InputMaybe<Scalars['AWSJSON']['input']>;
};

export type CompleteWorkflowStepInput = {
  WorkflowId: Scalars['ID']['input'];
  WorkflowStepId: Scalars['ID']['input'];
  values?: InputMaybe<Array<UserInputWithFieldDefinitionKeyInput>>;
};

export type CompletionCertificate = {
  __typename?: 'CompletionCertificate';
  /** Base64-encoded string for rendering completion certificate PDF */
  pdf: Scalars['String']['output'];
};

/** Configures how the Post Checkout Confirmation Component is displayed */
export type ConfirmationComponent = {
  __typename?: 'ConfirmationComponent';
  /** Heading text of confirmation component */
  heading: Scalars['String']['output'];
  /** Config of success redirect after finalizing checkout */
  successRedirectConfig?: Maybe<LinkConfig>;
  /** Text sections to show in confirmation component */
  textSections: Array<TextSection>;
};

export type ConfirmationComponentInput = {
  heading: Scalars['String']['input'];
  successRedirectConfig?: InputMaybe<LinkConfigInput>;
  textSections: Array<TextSectionInput>;
};

export enum ConsentOutcome {
  Denied = 'DENIED',
  Granted = 'GRANTED'
}

export enum ConsentRequirement {
  NotRequired = 'NOT_REQUIRED',
  Required = 'REQUIRED'
}

export type ContentNode = FileMetadata | LearningContainer;

export enum ContentOrganization {
  Dcc = 'DCC',
  Ft = 'FT',
  Mlu = 'MLU',
  Tc = 'TC'
}

export type ContentPermission = {
  __typename?: 'ContentPermission';
  curriculumDevelopmentOwner?: Maybe<Scalars['String']['output']>;
  curriculumDevelopmentTeamOwner?: Maybe<Scalars['String']['output']>;
  organization: ContentOrganization;
  owner: Scalars['String']['output'];
};

export type ContentPermissionInput = {
  curriculumDevelopmentOwner?: InputMaybe<Scalars['String']['input']>;
  curriculumDevelopmentTeamOwner?: InputMaybe<Scalars['String']['input']>;
};

export type ContentRelatedItem = {
  __typename?: 'ContentRelatedItem';
  /** Optional description of the association. */
  description?: Maybe<Scalars['String']['output']>;
  /** Human-readable name for the content association. Usually the title of the object or link. */
  name?: Maybe<Scalars['String']['output']>;
  /** LearningContainer ID, or File ID, or external URL. */
  relatedItemValue: Scalars['String']['output'];
  /** Relation type. */
  relation?: Maybe<ContentRelation>;
  /** Related item type. */
  type: EntityType;
};

export type ContentRelatedItemConnection = {
  __typename?: 'ContentRelatedItemConnection';
  nodes: Array<ContentRelatedItem>;
  pageInfo?: Maybe<PageInfo>;
};

export type ContentRelatedItemInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  relatedItemType: EntityType;
  /** LearningContainer ID, or File ID, FileEntity ID, or external URL. */
  relatedItemValue: Scalars['String']['input'];
  relation?: InputMaybe<ContentRelation>;
};

export enum ContentRelation {
  IsAuthoringSourceFor = 'IS_AUTHORING_SOURCE_FOR',
  IsGeneratedFromAuthoringSource = 'IS_GENERATED_FROM_AUTHORING_SOURCE',
  IsTranslatedFromSource = 'IS_TRANSLATED_FROM_SOURCE',
  IsTranslationSourceFor = 'IS_TRANSLATION_SOURCE_FOR'
}

export enum ContentState {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED'
}

export enum ContentType {
  Scorm = 'SCORM',
  Tincan = 'TINCAN'
}

export type ContractingRequestCreator = {
  __typename?: 'ContractingRequestCreator';
  email: Scalars['AWSEmail']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phone?: Maybe<Scalars['AWSPhone']['output']>;
};

export type ContractingRequestCreatorInput = {
  email: Scalars['AWSEmail']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['AWSPhone']['input']>;
};

export enum CountryCode {
  Ae = 'AE',
  Ar = 'AR',
  At = 'AT',
  Au = 'AU',
  Be = 'BE',
  Bh = 'BH',
  Br = 'BR',
  Ca = 'CA',
  Ch = 'CH',
  Cl = 'CL',
  Cn = 'CN',
  Co = 'CO',
  De = 'DE',
  Es = 'ES',
  Fi = 'FI',
  Fr = 'FR',
  Gb = 'GB',
  Hk = 'HK',
  Id = 'ID',
  Ie = 'IE',
  Il = 'IL',
  In = 'IN',
  It = 'IT',
  Jp = 'JP',
  Kr = 'KR',
  Lt = 'LT',
  Lu = 'LU',
  Mx = 'MX',
  My = 'MY',
  Nl = 'NL',
  Nz = 'NZ',
  Ph = 'PH',
  Pl = 'PL',
  Pt = 'PT',
  Sa = 'SA',
  Se = 'SE',
  Sg = 'SG',
  Sk = 'SK',
  Th = 'TH',
  Tw = 'TW',
  Us = 'US',
  Vn = 'VN',
  Za = 'ZA'
}

export type CreateAwsAccountPaymentMethodInput = {
  /** AWS JWT which contains account ID to use for payment */
  awsToken: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type CreateAciCourseLoadTranscriptInput = {
  catalogItemId: Scalars['ID']['input'];
  learnerId: Scalars['ID']['input'];
  learningActivityGroupId: Scalars['ID']['input'];
  registrationId: Scalars['ID']['input'];
};

export type CreateAssessmentUserInput = {
  email: Scalars['String']['input'];
  programs: Array<Scalars['String']['input']>;
  roles: Array<Scalars['String']['input']>;
};

export type CreateCatalogCustomAttributeSchemaInput = {
  defaultValuesOrOptions?: InputMaybe<Array<Scalars['String']['input']>>;
  key: Scalars['String']['input'];
  keyDisplayStrings: Array<CatalogLocalizedStringInput>;
  namespaceId: Scalars['String']['input'];
  selectOptions?: InputMaybe<Array<CatalogCustomAttributeSchemaSelectOptionInput>>;
  type: CatalogAttributeType;
};

export type CreateCatalogGroupInput = {
  group: CatalogGroupInput;
  /** DEPRECATED. Do not use */
  namespaceId?: InputMaybe<Scalars['String']['input']>;
};

/** Input for creating a catalog item */
export type CreateCatalogItemInput = {
  /** See CatalogItem.accessibilityCompliant */
  accessibilityCompliant?: InputMaybe<Scalars['Boolean']['input']>;
  /** See CatalogItem.activationDate */
  activationDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  /** See CatalogItem.approvedForReuse */
  approvedForReuse?: InputMaybe<Scalars['Boolean']['input']>;
  /** See CatalogItem.awsServices */
  awsServices?: InputMaybe<Array<Scalars['String']['input']>>;
  /** See CatalogItem.badgeImageUrl */
  badgeImageUrl?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.badgeName */
  badgeName?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.badgeTemplateId */
  badgeTemplateId?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.category */
  category: CatalogItemCategory;
  /**
   * See CatalogItem.children
   * Use this if the parent should point to any version of the child (i.e., the default version) ; e.g., a learning plan version pointing to a course default version
   * This is allowed for products that contain other products
   */
  childIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /**
   * See CatalogItem.children
   * Use this if the parent should point to a specific version of the child; e.g., a course version pointing to a specific version of a module
   * This is allowed for products that contain modules.
   * For ID "someidentifier" and semantic version 001.000.000, the format of the versioned ID is "someidentifier:001.000.000".
   */
  childVersionedIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** See CatalogItem.classification */
  classification: CatalogItemClassification;
  /** See CatalogItem.completionBadge */
  completionBadge?: InputMaybe<Scalars['Boolean']['input']>;
  /** See CatalogItem.completionCertificate */
  completionCertificate?: InputMaybe<Scalars['Boolean']['input']>;
  /** See CatalogItem.completionCriteria */
  completionCountCriteria?: InputMaybe<CatalogItemCompletionCountCriteriaInput>;
  /** See CatalogItem.completionCriteria */
  completionMarkerCriteria?: InputMaybe<CatalogItemCompletionMarkerCriteriaInput>;
  /** See CatalogItem.completionCriteria */
  completionPercentageCriteria?: InputMaybe<CatalogItemCompletionPercentageCriteriaInput>;
  /** See CatalogItem.contentFiles */
  contentFiles?: InputMaybe<Array<FileEntityIdVersion>>;
  /** See CatalogItem.customAttributes */
  customAttributes?: InputMaybe<Array<CustomAttributeInput>>;
  /** See CatalogItem.customIdentifiers */
  customIdentifiers?: InputMaybe<Array<CatalogCustomIdentifierInput>>;
  /** See CatalogItem.description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.devTeam */
  devTeam?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.domain */
  domain?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.duration */
  duration: CatalogDurationInput;
  /** See CatalogItem.evaluationTemplate */
  evaluationTemplate?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.examCertificationImageUrl */
  examCertificationImageUrl?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.examCertificationName */
  examCertificationName?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.expirationDate */
  expirationDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  /** See CatalogItem.goal */
  goal?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.industrySegments */
  industrySegments?: InputMaybe<Array<Scalars['String']['input']>>;
  /** See CatalogItem.industryVerticals */
  industryVerticals?: InputMaybe<Array<Scalars['String']['input']>>;
  /** See CatalogItem.intendedAudience */
  intendedAudience?: InputMaybe<Array<Scalars['String']['input']>>;
  /** See CatalogItem.languageCode */
  languageCode: Scalars['String']['input'];
  /** See CatalogItem.learningStyles */
  learningStyles?: InputMaybe<Array<Scalars['String']['input']>>;
  /** See CatalogItem.modalityDelivery */
  modalityDelivery: CatalogModalityDelivery;
  /** See CatalogItem.name */
  name: Scalars['String']['input'];
  /** See CatalogItem.namespace */
  namespaceId?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.navigationSettings */
  navigationSettings?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.objectives */
  objectives?: InputMaybe<Array<Scalars['String']['input']>>;
  /** See CatalogItem.organization */
  organization?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.outline */
  outline?: InputMaybe<Scalars['AWSJSON']['input']>;
  /** See CatalogItem.prerequisites */
  prerequisites?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.previewVideoUrl */
  previewVideoUrl?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.productOwners */
  productOwners?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** See CatalogItem.rating */
  rating?: InputMaybe<Scalars['Float']['input']>;
  /** See CatalogItem.roles */
  roles?: InputMaybe<Array<Scalars['String']['input']>>;
  /** See CatalogItem.semanticVersion */
  semanticVersion: SemanticVersionInput;
  /** See CatalogItem.skillLevel */
  skillLevel?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.sku */
  sku?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.sourceFiles */
  sourceFiles?: InputMaybe<Array<FileEntityIdVersion>>;
  /** See CatalogItem.sourceUrls */
  sourceUrls?: InputMaybe<Array<Scalars['AWSURL']['input']>>;
  /** See CatalogItem.systemRequirement */
  systemRequirement?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.tags */
  tags?: InputMaybe<Array<CatalogItemTagInput>>;
  /** See CatalogItem.technologies */
  technologies?: InputMaybe<Array<Scalars['String']['input']>>;
  /** See CatalogItem.thumbnailFile */
  thumbnailFileId?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.thumbnailImageUrl */
  thumbnailImageUrl?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.trainingDeliveryUrl */
  trainingDeliveryUrl?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.type */
  type: Scalars['String']['input'];
  /** See CatalogItem.rootItem */
  variantScope?: InputMaybe<CatalogItemVariantScopeInput>;
  /** See CatalogItem.visibility */
  visibility?: InputMaybe<CatalogItemVisibility>;
};

/** Input for creating a new version of a catalog item */
export type CreateCatalogItemVersionInput = {
  /** See CatalogItem.accessibilityCompliant */
  accessibilityCompliant?: InputMaybe<Scalars['Boolean']['input']>;
  /** See CatalogItem.activationDate */
  activationDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  /** See CatalogItem.approvedForReuse */
  approvedForReuse?: InputMaybe<Scalars['Boolean']['input']>;
  /** See CatalogItem.awsServices */
  awsServices?: InputMaybe<Array<Scalars['String']['input']>>;
  /** See CatalogItem.badgeImageUrl */
  badgeImageUrl?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.badgeName */
  badgeName?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.badgeTemplateId */
  badgeTemplateId?: InputMaybe<Scalars['String']['input']>;
  /**
   * See CatalogItem.children
   * Use this if the parent should point to any version of the child (i.e., the default version) ; e.g., a learning plan version pointing to a course default version
   * This is allowed for products that contain other products
   */
  childIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /**
   * See CatalogItem.children
   * Use this if the parent should point to a specific version of the child; e.g., a course version pointing to a specific version of a module
   * This is allowed for products that contain modules.
   * For ID "someidentifier" and semantic version 001.000.000, the format of the versioned ID is "someidentifier:001.000.000".
   */
  childVersionedIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** See CatalogItem.completionBadge */
  completionBadge?: InputMaybe<Scalars['Boolean']['input']>;
  /** See CatalogItem.completionCertificate */
  completionCertificate?: InputMaybe<Scalars['Boolean']['input']>;
  /** See CatalogItem.completionCriteria */
  completionCountCriteria?: InputMaybe<CatalogItemCompletionCountCriteriaInput>;
  /** See CatalogItem.completionCriteria */
  completionMarkerCriteria?: InputMaybe<CatalogItemCompletionMarkerCriteriaInput>;
  /** See CatalogItem.completionCriteria */
  completionPercentageCriteria?: InputMaybe<CatalogItemCompletionPercentageCriteriaInput>;
  /** See CatalogItem.contentFiles */
  contentFiles?: InputMaybe<Array<FileEntityIdVersion>>;
  /** See CatalogItem.customAttributes */
  customAttributes?: InputMaybe<Array<CustomAttributeInput>>;
  /** See CatalogItem.description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.devTeam */
  devTeam?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.domain */
  domain?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.duration */
  duration: CatalogDurationInput;
  /** See CatalogItem.evaluationTemplate */
  evaluationTemplate?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.examCertificationImageUrl */
  examCertificationImageUrl?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.examCertificationName */
  examCertificationName?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.expirationDate */
  expirationDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  /** See CatalogItem.goal */
  goal?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.id */
  id: Scalars['ID']['input'];
  /** See CatalogItem.industrySegments */
  industrySegments?: InputMaybe<Array<Scalars['String']['input']>>;
  /** See CatalogItem.industryVerticals */
  industryVerticals?: InputMaybe<Array<Scalars['String']['input']>>;
  /** See CatalogItem.intendedAudience */
  intendedAudience?: InputMaybe<Array<Scalars['String']['input']>>;
  /** See CatalogItem.learningStyles */
  learningStyles?: InputMaybe<Array<Scalars['String']['input']>>;
  /** See CatalogItem.name */
  name: Scalars['String']['input'];
  /** See CatalogItem.navigationSettings */
  navigationSettings?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.objectives */
  objectives?: InputMaybe<Array<Scalars['String']['input']>>;
  /** See CatalogItem.organization */
  organization?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.outline */
  outline?: InputMaybe<Scalars['AWSJSON']['input']>;
  /** See CatalogItem.prerequisites */
  prerequisites?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.previewVideoUrl */
  previewVideoUrl?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.productOwners */
  productOwners?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** See CatalogItem.rating */
  rating?: InputMaybe<Scalars['Float']['input']>;
  /** See CatalogItem.roles */
  roles?: InputMaybe<Array<Scalars['String']['input']>>;
  /** See CatalogItem.semanticVersion */
  semanticVersion: SemanticVersionInput;
  /** See CatalogItem.skillLevel */
  skillLevel?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.sku */
  sku?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.sourceFiles */
  sourceFiles?: InputMaybe<Array<FileEntityIdVersion>>;
  /** See CatalogItem.sourceUrls */
  sourceUrls?: InputMaybe<Array<Scalars['AWSURL']['input']>>;
  /** See CatalogItem.systemRequirement */
  systemRequirement?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.tags */
  tags?: InputMaybe<Array<CatalogItemTagInput>>;
  /** See CatalogItem.technologies */
  technologies?: InputMaybe<Array<Scalars['String']['input']>>;
  /** See CatalogItem.thumbnailFile */
  thumbnailFileId?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.thumbnailImageUrl */
  thumbnailImageUrl?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.trainingDeliveryUrl */
  trainingDeliveryUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCatalogNamespaceInput = {
  description: Scalars['String']['input'];
  /** Client provided unique ID for namespace; cannot be changed */
  id: Scalars['String']['input'];
};

/** Input for creating a catalog root item */
export type CreateCatalogRootItemInput = {
  /** Category of the root item */
  category: CatalogItemCategory;
  /** Classification of the root item */
  classification: CatalogItemClassification;
  /** Custom identifiers for the root item */
  customIdentifiers?: InputMaybe<Array<CatalogCustomIdentifierInput>>;
  /** Description of the root item */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Modality of delivery for the root item */
  modalityDelivery: CatalogModalityDelivery;
  /** Name of the root item */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Type of the root item */
  type: Scalars['String']['input'];
  /** Available variant dimension types for this root item */
  variantDimensionTypes?: InputMaybe<Array<CatalogItemVariantDimensionType>>;
  /** Visibility of the root item */
  visibility?: InputMaybe<CatalogItemVisibility>;
};

export type CreateDigitalTranscriptInput = {
  catalogItemVersionedId: Scalars['ID']['input'];
  learnerId: Scalars['ID']['input'];
  registrationId: Scalars['ID']['input'];
};

export type CreateEntityUserGroupInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  groupLevel: EntityUserGroupLevel;
  name: Scalars['String']['input'];
  /** Id of organization or team owning this group */
  ownerEntityId: Scalars['ID']['input'];
};

export type CreateExternalUserInput = {
  auditMetadata?: InputMaybe<AuditMetadataInput>;
  emailAddress: Scalars['AWSEmail']['input'];
  firstName: Scalars['String']['input'];
  is3PLearningAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  lastName: Scalars['String']['input'];
  lastSignedInAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  loginMethod?: InputMaybe<Scalars['String']['input']>;
  partnerAttributes?: InputMaybe<AwsPartnerNetworkAttributeInput>;
  profileInput?: InputMaybe<UpdateUserProfileInput>;
  status?: InputMaybe<UserStatus>;
  userRole: UserRole;
  vendorId?: InputMaybe<Scalars['String']['input']>;
  vendorStatus?: InputMaybe<UserStatus>;
  /**
   * The following attributes are only allowed to be used by SERVICE to SERVICE interactions.
   * They are required for importing of external vendor attributes.
   */
  vibeId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateFileDataInput = {
  contentType?: InputMaybe<ContentType>;
  description: Scalars['String']['input'];
  file: CreateFileInput;
  isAccessibilityCompliant?: InputMaybe<Scalars['Boolean']['input']>;
  isApprovedForReuse?: InputMaybe<Scalars['Boolean']['input']>;
  languageCode: LanguageCode;
  location?: InputMaybe<Scalars['String']['input']>;
  lxp?: InputMaybe<Lxp>;
  lxpAttributes?: InputMaybe<Array<UserInputWithFieldDefinitionKeyInput>>;
  permission?: InputMaybe<ContentPermissionInput>;
  productOwner?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<InputMaybe<KeyValuePairInput>>>;
  tempLocation?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type CreateFileEntityInput = {
  customAttributes?: InputMaybe<Scalars['AWSJSON']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  extension: Scalars['String']['input'];
  mimeType: Scalars['String']['input'];
  name: Scalars['String']['input'];
  size: MeasuredValueInput;
  tags?: InputMaybe<Array<KeyValuePairInput>>;
};

export type CreateFileInput = {
  mimeType: Scalars['String']['input'];
  name: Scalars['String']['input'];
  size: Scalars['Int']['input'];
};

export type CreateFileMetadataInput = {
  data: CreateFileDataInput;
};

export type CreateFulfillmentItemUploadUrlInput = {
  fulfillmentId: Scalars['ID']['input'];
  fulfillmentItemId: Scalars['ID']['input'];
  isProd: Scalars['Boolean']['input'];
};

export type CreateGroupInput = {
  auditMetadata?: InputMaybe<AuditMetadataInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  groupLevel?: InputMaybe<EntityUserGroupLevel>;
  name: Scalars['String']['input'];
  ownerEntityId?: InputMaybe<Scalars['ID']['input']>;
  vendorId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateInternalUserInput = {
  emailAddress: Scalars['AWSEmail']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  userRole: UserRole;
};

export type CreateLearningAccountInput = {
  awsAccountId: Scalars['String']['input'];
  awsAssociation?: InputMaybe<AwsAssociation>;
  billingAddress?: InputMaybe<AddressInput>;
  customerSince?: InputMaybe<Scalars['AWSDateTime']['input']>;
  learningAccountSettings?: InputMaybe<OrganizationSettingsInput>;
  learningAccountVisibilityClassification?: InputMaybe<OrganizationVisibilityClassification>;
  name: Scalars['String']['input'];
  organizationType: OrganizationType;
  phoneNumber?: InputMaybe<Scalars['AWSPhone']['input']>;
  sfdcAccountId?: InputMaybe<Scalars['String']['input']>;
  sfdcParentAccountId?: InputMaybe<Scalars['String']['input']>;
  sfdcParentAccountName?: InputMaybe<Scalars['String']['input']>;
  skillBuilderBranchId?: InputMaybe<Scalars['String']['input']>;
  skillBuilderNumericBranchId?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateLearningContainerInput = {
  clientToken?: InputMaybe<Scalars['String']['input']>;
  data: LearningContainerInput;
};

export type CreateLicenseInput = {
  accessDetails: Array<KeyValuePairInput>;
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  orderLineItemId: Scalars['ID']['input'];
  scheduledActivationAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  scheduledTerminationAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userId: Scalars['ID']['input'];
};

export type CreateLicensePolicyInput = {
  actions: Array<AuthorizationActionType>;
  principalId: Scalars['ID']['input'];
  principalType: AuthorizationPrincipalType;
  resourceId: Scalars['ID']['input'];
  resourceType: AuthorizationResourceType;
};

export type CreateLxpAttributeTemplateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  entity: EntityType;
  fieldDefinitions: Array<FieldDefinitionInput>;
  lxp?: InputMaybe<Lxp>;
  name: Scalars['String']['input'];
};

export type CreateNewOrderCheckoutSessionInput = {
  allowedPaymentMethodTypes: Array<PaymentMethodType>;
  clientId: Scalars['String']['input'];
  handleOpenClientSessionStrategy: HandleOpenClientSessionStrategy;
  items: Array<CreateNewOrderCheckoutSessionItemInput>;
  locale: Scalars['String']['input'];
  postCheckoutPage: PostCheckoutPageInput;
  preCheckoutPage: PreCheckoutPageInput;
  userId: Scalars['ID']['input'];
};

export type CreateNewOrderCheckoutSessionItemInput = {
  appliedBenefitIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  metadata?: InputMaybe<Array<KeyValuePairInput>>;
  offerId: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
};

/** Default eligible to free trial if not specify isFreeTrialEligible field */
export type CreateNewSbDigitalOrderCheckoutSessionInput = {
  isFreeTrialEligible?: InputMaybe<Scalars['Boolean']['input']>;
  locale: Scalars['String']['input'];
  offerId: Scalars['String']['input'];
};

export type CreateOfferInput = {
  description: Scalars['String']['input'];
  expiresAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  installmentPlan?: InputMaybe<InstallmentPlanInput>;
  modality: OfferModality;
  name: Scalars['String']['input'];
  offerPrice: Scalars['Float']['input'];
  type: OfferType;
};

export type CreateOrUpdateAttendanceEntitiesInput = {
  activity_status: Scalars['String']['input'];
  attendedCounter: Scalars['Int']['input'];
  customers?: InputMaybe<Array<CustomerInput>>;
  delivery_sessions?: InputMaybe<Array<DeliverySessionInput>>;
  instructors?: InputMaybe<Array<InstructorInput>>;
  lms_type: Scalars['String']['input'];
  pk: Scalars['ID']['input'];
};

export type CreateOrderInput = {
  billingAwsAccountId?: InputMaybe<Scalars['String']['input']>;
  businessDevelopmentManager?: InputMaybe<ProductOnboardingUserInput>;
  items: Array<CreateOrderItemInput>;
  learningAccountId?: InputMaybe<Scalars['String']['input']>;
  noOfExistingLicenses?: InputMaybe<Scalars['Int']['input']>;
  opportunityId?: InputMaybe<Scalars['String']['input']>;
  opportunityName?: InputMaybe<Scalars['String']['input']>;
  organizationClassification?: InputMaybe<OrganizationVisibilityClassification>;
  parentOpportunityId?: InputMaybe<Scalars['String']['input']>;
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<OrderType>;
  typeOfContract?: InputMaybe<TypeOfContract>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOrderItemInput = {
  appliedBenefitIds?: InputMaybe<Array<Scalars['String']['input']>>;
  billingPeriod?: InputMaybe<BillingPeriod>;
  classEndDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  classStartDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  contractingRequestCreator?: InputMaybe<ContractingRequestCreatorInput>;
  freeTrialDetails?: InputMaybe<FreeTrialDetailsInput>;
  metadata?: InputMaybe<Array<KeyValuePairInput>>;
  nmbsBillingDetails?: InputMaybe<NmbsBillingDetailsInput>;
  offerId?: InputMaybe<Scalars['String']['input']>;
  opportunityLineItemId?: InputMaybe<Scalars['String']['input']>;
  productModality?: InputMaybe<ProductModality>;
  productOnboardingDetails?: InputMaybe<ProductOnboardingDetailsInput>;
  productTitle?: InputMaybe<Scalars['String']['input']>;
  quantity: Scalars['Int']['input'];
  ssoEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  subTotal?: InputMaybe<Scalars['Float']['input']>;
  subscriptionType?: InputMaybe<TypeOfContract>;
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateOrganizationInput = {
  /**
   * The following attribute(s) are only allowed to be used by SERVICE to SERVICE interactions.
   * They are required for importing of external vendor attributes.
   */
  auditMetadata?: InputMaybe<AuditMetadataInput>;
  awsAccountId: Scalars['String']['input'];
  awsAssociation?: InputMaybe<AwsAssociation>;
  billingAddress?: InputMaybe<AddressInput>;
  customerSince?: InputMaybe<Scalars['AWSDateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  organizationSettings?: InputMaybe<OrganizationSettingsInput>;
  organizationType: OrganizationType;
  organizationVisibilityClassification?: InputMaybe<OrganizationVisibilityClassification>;
  phoneNumber?: InputMaybe<Scalars['AWSPhone']['input']>;
  sfdcAccountId?: InputMaybe<Scalars['String']['input']>;
  sfdcParentAccountId?: InputMaybe<Scalars['String']['input']>;
  sfdcParentAccountName?: InputMaybe<Scalars['String']['input']>;
  vendorCode?: InputMaybe<Scalars['String']['input']>;
  vendorId?: InputMaybe<Scalars['Int']['input']>;
};

export type CreatePresignedFileUploadUrlInput = {
  /** Provide a FileMetadata ID to associate with the file being uploaded. */
  fileMetadataId: Scalars['ID']['input'];
};

export type CreatePublishingWorkflowInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  learningContainerId: Scalars['ID']['input'];
  learningContainerVersion: Scalars['Int']['input'];
  lxpContentId?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  publishingScope?: InputMaybe<PublishingScope>;
  tags?: InputMaybe<Array<InputMaybe<KeyValuePairInput>>>;
  targetLxpEnvStages?: InputMaybe<Array<EnvStage>>;
  targetPublishDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type CreateReplaceOrderItemCheckoutSessionInput = {
  clientId: Scalars['String']['input'];
  handleOpenClientSessionStrategy: HandleOpenClientSessionStrategy;
  item: CreateReplaceOrderItemCheckoutSessionItemInput;
  locale: Scalars['String']['input'];
  postCheckoutPage: PostCheckoutPageInput;
  preCheckoutPage: PreCheckoutPageInput;
  userId: Scalars['ID']['input'];
};

export type CreateReplaceOrderItemCheckoutSessionItemInput = {
  licenseToTransferIds: Array<Scalars['ID']['input']>;
  metadata?: InputMaybe<Array<KeyValuePairInput>>;
  offerId: Scalars['ID']['input'];
  orderItemToReplaceId: Scalars['ID']['input'];
  orderToReplaceId: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
};

/** Data needed to create or update a review */
export type CreateReviewInput = {
  /** Numerical 1-star, 2-star, etc rating. value must be between 1-5 */
  rating: Scalars['Int']['input'];
  /** Refernet ID, currently only supports catalog item ids */
  referentId: Scalars['ID']['input'];
  /** Referent type, currently only supports CATALOG_ITEM */
  referentType: ReferentType;
  /** User Service ID: Input Validation: Valid userId format -- only services can create requests on behalf of users. An error will be thrown if request is from user and userId is passed in */
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateSystemUserGroupInput = {
  auditMetadata?: InputMaybe<AuditMetadataInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateTeamInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  /** Used to internally create Org-Team associations */
  organizationId: Scalars['ID']['input'];
  teamSettings?: InputMaybe<TeamSettingsInput>;
  vendorId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateTranscriptInput = {
  catalogItemId: Scalars['ID']['input'];
  catalogItemVersionedId: Scalars['String']['input'];
  completedDate?: InputMaybe<Scalars['String']['input']>;
  coursesCompleted?: InputMaybe<Scalars['Int']['input']>;
  dateAssigned?: InputMaybe<Scalars['String']['input']>;
  enrollmentDate?: InputMaybe<Scalars['String']['input']>;
  firstAccessDate?: InputMaybe<Scalars['String']['input']>;
  lastAccessDate?: InputMaybe<Scalars['String']['input']>;
  lastUpdatedAt: Scalars['String']['input'];
  score?: InputMaybe<Scalars['String']['input']>;
  status: TranscriptStatusV1;
  userVibeId?: InputMaybe<Scalars['String']['input']>;
  vendorCatalogItemId: Scalars['Int']['input'];
  vendorCatalogType: Scalars['String']['input'];
  vendorChildCourses?: InputMaybe<Array<Scalars['Int']['input']>>;
  vendorUserId: Scalars['Int']['input'];
};

export type CreateUpdateCatalogFileInput = {
  fileType: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  size: Scalars['Int']['input'];
};

export type CreateUserInput = {
  emailAddress?: InputMaybe<Scalars['AWSEmail']['input']>;
  federatedIdentities?: InputMaybe<Array<FederatedIdentityInput>>;
  hatId?: InputMaybe<Scalars['String']['input']>;
  invitationLoginMethod?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  partnerAttributes?: InputMaybe<AwsPartnerNetworkAttributeInput>;
  vibeId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateWorkflowInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  entityId: Scalars['ID']['input'];
  entityName: Scalars['String']['input'];
  entitySemanticVersion?: InputMaybe<SemanticVersionInput>;
  entityVersion?: InputMaybe<Scalars['Int']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  templateId: Scalars['ID']['input'];
  values?: InputMaybe<Array<UserInputWithFieldDefinitionKeyInput>>;
  workflowStepValues?: InputMaybe<Array<InputMaybe<Array<UserInputWithFieldDefinitionKeyInput>>>>;
};

export enum CurrencyCode {
  Aed = 'AED',
  Afn = 'AFN',
  All = 'ALL',
  Amd = 'AMD',
  Ars = 'ARS',
  Aud = 'AUD',
  Azn = 'AZN',
  Bam = 'BAM',
  Bdt = 'BDT',
  Bgn = 'BGN',
  Bhd = 'BHD',
  Bif = 'BIF',
  Bnd = 'BND',
  Bob = 'BOB',
  Brl = 'BRL',
  Bwp = 'BWP',
  Byn = 'BYN',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Chf = 'CHF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Crc = 'CRC',
  Cve = 'CVE',
  Czk = 'CZK',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Eek = 'EEK',
  Egp = 'EGP',
  Ern = 'ERN',
  Etb = 'ETB',
  Eur = 'EUR',
  Gbp = 'GBP',
  Gel = 'GEL',
  Ghs = 'GHS',
  Gnf = 'GNF',
  Gtq = 'GTQ',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Hrk = 'HRK',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Inr = 'INR',
  Iqd = 'IQD',
  Irr = 'IRR',
  Isk = 'ISK',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Khr = 'KHR',
  Kmf = 'KMF',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kzt = 'KZT',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Ltl = 'LTL',
  Lvl = 'LVL',
  Lyd = 'LYD',
  Mad = 'MAD',
  Mdl = 'MDL',
  Mga = 'MGA',
  Mkd = 'MKD',
  Mmk = 'MMK',
  Mop = 'MOP',
  Mur = 'MUR',
  Mxn = 'MXN',
  Myr = 'MYR',
  Mzn = 'MZN',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nio = 'NIO',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pen = 'PEN',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Pyg = 'PYG',
  Qar = 'QAR',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sdg = 'SDG',
  Sek = 'SEK',
  Sgd = 'SGD',
  Sos = 'SOS',
  Syp = 'SYP',
  Thb = 'THB',
  Tnd = 'TND',
  Top = 'TOP',
  Try = 'TRY',
  Ttd = 'TTD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Ugx = 'UGX',
  Usd = 'USD',
  Uyu = 'UYU',
  Uzs = 'UZS',
  Vef = 'VEF',
  Vnd = 'VND',
  Xaf = 'XAF',
  Xof = 'XOF',
  Yer = 'YER',
  Zar = 'ZAR',
  Zmk = 'ZMK',
  Zwl = 'ZWL'
}

export enum CurrentUserApiVersion {
  V2 = 'V2'
}

/** Input for specifying a custom attribute */
export type CustomAttributeInput = {
  /** Attribute key (e.g., 'domain') */
  key: Scalars['String']['input'];
  /** Namespace attribute belongs to */
  namespaceId?: InputMaybe<Scalars['String']['input']>;
  /**
   * Values of attribute
   * Although this is a string list for flexibility, it will be validated against the attribute type
   * E.g., boolean attributes will expect exactly 1 entry in the 'values' list, with the value
   * 'true' or 'false'.
   * Number attributes will expect exactly 1 entry in the 'values' list with the value being a stringified number
   * such as '5'
   */
  values: Array<Scalars['String']['input']>;
};

export type Customer = {
  __typename?: 'Customer';
  customer_aws_account_id?: Maybe<Scalars['ID']['output']>;
  customer_name?: Maybe<Scalars['String']['output']>;
  sfdc_account_id?: Maybe<Scalars['String']['output']>;
  sfdc_line_item_id?: Maybe<Scalars['String']['output']>;
  sfdc_opportunity_id?: Maybe<Scalars['String']['output']>;
};

export type CustomerInput = {
  customer_aws_account_id: Scalars['ID']['input'];
};

export type DeactivateCatalogGroupInput = {
  id: Scalars['ID']['input'];
};

export type DeactivateCatalogItemVersionInput = {
  id: Scalars['ID']['input'];
  semanticVersion: SemanticVersionInput;
};

export type DeleteCatalogCommentInput = {
  commentId: Scalars['ID']['input'];
  commentLink: Scalars['AWSURL']['input'];
  entityId: Scalars['String']['input'];
};

export type DeleteCatalogItemVersionInput = {
  id: Scalars['ID']['input'];
  semanticVersion: SemanticVersionInput;
};

/** Result of the delete operation */
export type DeleteCatalogItemVersionResult = {
  __typename?: 'DeleteCatalogItemVersionResult';
  /** Indicates whether the delete operation was successful */
  success: Scalars['Boolean']['output'];
};

export type DeleteCommentInput = {
  id: Scalars['ID']['input'];
  /** Optional, will send notification if included */
  notificationConfig?: InputMaybe<NotificationConfiguration>;
  updatedBy: Scalars['String']['input'];
};

export type DeleteCommentsOnFileInput = {
  commentId: Scalars['ID']['input'];
  commentLink: Scalars['AWSURL']['input'];
  entityId: Scalars['String']['input'];
};

export type DeleteCommentsOnLearningContainerInput = {
  commentId: Scalars['ID']['input'];
  commentLink: Scalars['AWSURL']['input'];
  entityId: Scalars['String']['input'];
};

export type DeleteFileEntityCommentInput = {
  commentId: Scalars['ID']['input'];
  commentLink: Scalars['AWSURL']['input'];
  entityId: Scalars['String']['input'];
};

export type DeleteTranscriptEntityInput = {
  catalogItemId?: InputMaybe<Scalars['ID']['input']>;
  catalogItemVersionedId?: InputMaybe<Scalars['ID']['input']>;
  hardDelete?: InputMaybe<Scalars['Boolean']['input']>;
  learningActivityGroupId?: InputMaybe<Scalars['ID']['input']>;
  program?: InputMaybe<ProgramType>;
  userId: Scalars['ID']['input'];
};

export type DeleteTranscriptInput = {
  vendorCatalogItemId: Scalars['Int']['input'];
  vendorCatalogType: Scalars['String']['input'];
  vendorUserId: Scalars['Int']['input'];
};

export type DeleteUserInput = {
  statusReason?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

export type DeliverySession = {
  __typename?: 'DeliverySession';
  delivery_session_type?: Maybe<Scalars['String']['output']>;
  end_timestamp?: Maybe<Scalars['AWSTimestamp']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  instructors?: Maybe<Array<Maybe<Instructor>>>;
  start_timestamp?: Maybe<Scalars['AWSTimestamp']['output']>;
  v_ilt_info?: Maybe<VIltInfo>;
};

export type DeliverySessionInput = {
  end_timestamp?: InputMaybe<Scalars['AWSTimestamp']['input']>;
  id: Scalars['ID']['input'];
  start_timestamp?: InputMaybe<Scalars['AWSTimestamp']['input']>;
};

export type DevDemoEventInput = {
  id: Scalars['String']['input'];
  version: Scalars['Int']['input'];
};

export type DigitalFulfillmentDetails = {
  __typename?: 'DigitalFulfillmentDetails';
  emailDomains?: Maybe<Array<Scalars['String']['output']>>;
  identityProvider?: Maybe<DigitalFulfillmentIdentityProvider>;
  idpAttributeMapping?: Maybe<DigitalFulfillmentIdpAttributes>;
  idpMetaDataLinks?: Maybe<FulfillmentItemUploadUrLs>;
  learningAdmins: Array<DigitalFulfillmentUser>;
  organizationName: Scalars['String']['output'];
  ssoContact?: Maybe<DigitalFulfillmentUser>;
  ssoEnabled?: Maybe<Scalars['Boolean']['output']>;
};

export type DigitalFulfillmentDetailsInput = {
  emailDomains?: InputMaybe<Array<Scalars['String']['input']>>;
  identityProvider?: InputMaybe<DigitalFulfillmentIdentityProvider>;
  idpAttributeMapping?: InputMaybe<DigitalFulfillmentIdpAttributesInput>;
  idpMetaDataLinks?: InputMaybe<FulfillmentItemUploadLinksInput>;
  learningAdmins?: InputMaybe<Array<DigitalFulfillmentUserInput>>;
  organizationName?: InputMaybe<Scalars['String']['input']>;
  ssoContact?: InputMaybe<DigitalFulfillmentUserInput>;
  ssoEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DigitalFulfillmentIdpAttributes = {
  __typename?: 'DigitalFulfillmentIDPAttributes';
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type DigitalFulfillmentIdpAttributesInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export enum DigitalFulfillmentIdentityProvider {
  Adfs = 'ADFS',
  Azure = 'AZURE',
  Duo = 'DUO',
  Google = 'GOOGLE',
  Jump = 'JUMP',
  Okta = 'OKTA',
  Onelogin = 'ONELOGIN',
  Other = 'OTHER',
  Ping = 'PING'
}

export type DigitalFulfillmentUser = {
  __typename?: 'DigitalFulfillmentUser';
  emailAddress: Scalars['AWSEmail']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
};

export type DigitalFulfillmentUserInput = {
  emailAddress: Scalars['AWSEmail']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

/** For Digital LP & Course */
export type DigitalTranscript = TranscriptBase & {
  __typename?: 'DigitalTranscript';
  auditMetadata: AuditMetadata;
  catalogItemCategory: Scalars['String']['output'];
  catalogItemModality?: Maybe<TranscriptCatalogItemModality>;
  catalogItemType: Scalars['String']['output'];
  catalogRootItem?: Maybe<CatalogRootItem>;
  completionDate?: Maybe<Scalars['AWSDateTime']['output']>;
  courseDetails?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  digitalTranscriptStatus: DigitalTranscriptStatus;
  enrollmentDate?: Maybe<Scalars['AWSDateTime']['output']>;
  highestScore?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  lastAccessDate?: Maybe<Scalars['AWSDateTime']['output']>;
  learner: User;
  moduleDetails?: Maybe<Array<Maybe<ModuleDetail>>>;
  numberOfCompletedCourses?: Maybe<Scalars['Int']['output']>;
  prevVersionedCatalogItem?: Maybe<CatalogItem>;
  program: ProgramType;
  registration: Registration;
  startDate?: Maybe<Scalars['AWSDateTime']['output']>;
  versionedCatalogItem: CatalogItem;
};

export enum DigitalTranscriptStatus {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  NotStarted = 'NOT_STARTED'
}

export type DisassociateCatalogItemFromGroupInput = {
  groupId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
};

export type DisassociateCatalogRootItemFromGroupInput = {
  groupId: Scalars['ID']['input'];
  rootItemId: Scalars['ID']['input'];
};

export type Discount = {
  __typename?: 'Discount';
  percentageDiscount: Scalars['Int']['output'];
  seatThreshold: Scalars['Int']['output'];
};

export enum DislikeReason {
  Harmful = 'HARMFUL',
  Inaccurate = 'INACCURATE',
  Incomplete = 'INCOMPLETE',
  Other = 'OTHER'
}

export enum Domain {
  Us = 'US'
}

export type EditCatalogCommentInput = {
  commentId: Scalars['ID']['input'];
  commentLink: Scalars['AWSURL']['input'];
  content: Scalars['String']['input'];
  entityId: Scalars['String']['input'];
};

export type EditCommentInput = {
  content: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  /** Optional, will send notification if included */
  notificationConfig?: InputMaybe<NotificationConfiguration>;
  updatedBy: Scalars['String']['input'];
};

export type EditCommentsOnFileInput = {
  commentId: Scalars['ID']['input'];
  commentLink: Scalars['AWSURL']['input'];
  content: Scalars['String']['input'];
  entityId: Scalars['String']['input'];
};

export type EditCommentsOnLearningContainerInput = {
  commentId: Scalars['ID']['input'];
  commentLink: Scalars['AWSURL']['input'];
  content: Scalars['String']['input'];
  entityId: Scalars['String']['input'];
};

export type EditFileEntityCommentInput = {
  commentId: Scalars['ID']['input'];
  commentLink: Scalars['AWSURL']['input'];
  content: Scalars['String']['input'];
  entityId: Scalars['String']['input'];
};

export type EditPublishingWorkflowCommentInput = {
  commentId: Scalars['ID']['input'];
  content: Scalars['String']['input'];
  publishingWorkflowId: Scalars['ID']['input'];
};

export type Email = {
  __typename?: 'Email';
  emailAddress: Scalars['String']['output'];
  mailboxDisplayName?: Maybe<Scalars['String']['output']>;
};

export type EmailAttachment = {
  attachmentBody: Scalars['String']['input'];
  attachmentName: Scalars['String']['input'];
  attachmentType: MimeTypes;
};

export type EmailInput = {
  emailAddress: Scalars['String']['input'];
  mailboxDisplayName?: InputMaybe<Scalars['String']['input']>;
};

export type EmailProfile = {
  __typename?: 'EmailProfile';
  copyEmails?: Maybe<Array<Maybe<Email>>>;
  primaryEmail: Email;
};

export type EmailProfileInput = {
  copyEmails?: InputMaybe<Array<InputMaybe<EmailInput>>>;
  primaryEmail: EmailInput;
};

/** Comments associated with an entity */
export type EntityComments = {
  __typename?: 'EntityComments';
  /** The comments for this entity. */
  comments: CommentsConnection;
  /** ID of the entity */
  entityId: Scalars['String']['output'];
  /** Name of the entity */
  entityName: Scalars['String']['output'];
};


/** Comments associated with an entity */
export type EntityCommentsCommentsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export enum EntityType {
  File = 'FILE',
  FileEntity = 'FILE_ENTITY',
  FileLcRelation = 'FILE_LC_RELATION',
  LearningContainer = 'LEARNING_CONTAINER',
  Link = 'LINK'
}

export type EntityUserGroup = {
  __typename?: 'EntityUserGroup';
  auditMetadata: AuditMetadata;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** Provides the ownership entity a Group belongs to. */
  ownerEntity?: Maybe<EntityUserGroupOwnerEntity>;
  /**
   * List of users belonging to a Group.
   *
   * Maximum page size is 100. Higher page sizes will be capped at 100.
   */
  userAssociations?: Maybe<UserAssociationConnection>;
  userCount?: Maybe<Scalars['Int']['output']>;
  vendorId?: Maybe<Scalars['String']['output']>;
};


export type EntityUserGroupUserAssociationsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type EntityUserGroupAssociation = {
  __typename?: 'EntityUserGroupAssociation';
  auditMetadata: AuditMetadata;
  group: EntityUserGroup;
  parentResourceId: Scalars['String']['output'];
  parentResourceType: AssociationResourceType;
};

export type EntityUserGroupAssociationConnection = {
  __typename?: 'EntityUserGroupAssociationConnection';
  nodes: Array<EntityUserGroupAssociation>;
  pageInfo?: Maybe<PageInfo>;
};

export type EntityUserGroupConnection = {
  __typename?: 'EntityUserGroupConnection';
  nodes: Array<EntityUserGroup>;
  pageInfo?: Maybe<PageInfo>;
};

export enum EntityUserGroupLevel {
  Organization = 'ORGANIZATION',
  Team = 'TEAM'
}

export type EntityUserGroupOwnerEntity = Organization | Team;

/** Entity workflows for a specific entity */
export type EntityWorkflows = {
  __typename?: 'EntityWorkflows';
  /** ID of the entity */
  entityId: Scalars['ID']['output'];
  /** Name of the entity */
  entityName: Scalars['String']['output'];
  /** Version of the entity */
  entityVersion?: Maybe<Scalars['String']['output']>;
  workflows: WorkflowConnection;
};


/** Entity workflows for a specific entity */
export type EntityWorkflowsWorkflowsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export enum EnvStage {
  Beta = 'BETA',
  Gamma = 'GAMMA',
  Prod = 'PROD'
}

export type Evaluation = {
  __typename?: 'Evaluation';
  catalogItem?: Maybe<CatalogItem>;
  evaluationStatus: EvaluationStatus;
  evaluationUrl?: Maybe<Scalars['AWSURL']['output']>;
  learningActivity?: Maybe<LearningActivity>;
  transcriptId: Scalars['ID']['output'];
  user: User;
};

export type EvaluationConnection = {
  __typename?: 'EvaluationConnection';
  nodes: Array<Evaluation>;
  pageInfo?: Maybe<PageInfo>;
};

export type EvaluationFilterInput = {
  catalogItemCategory?: InputMaybe<Scalars['String']['input']>;
  catalogItemTypeValue?: InputMaybe<Scalars['String']['input']>;
  catalogModalityDelivery?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EvaluationStatus>;
};

export enum EvaluationStatus {
  Completed = 'COMPLETED',
  Incomplete = 'INCOMPLETE',
  NotRequired = 'NOT_REQUIRED'
}

export type ExitResult = {
  __typename?: 'ExitResult';
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type ExitSurveyInput = {
  orderId?: InputMaybe<Scalars['ID']['input']>;
  source: Scalars['String']['input'];
  surveyResult: Scalars['String']['input'];
  vibeId: Scalars['String']['input'];
};

export type ExpireOfferInput = {
  id: Scalars['ID']['input'];
};

export type FailFulfillmentItemInput = {
  currentStepIndex?: InputMaybe<Scalars['Int']['input']>;
  failureDatetime: Scalars['AWSDateTime']['input'];
  fulfillmentId: Scalars['ID']['input'];
  fulfillmentItemId: Scalars['ID']['input'];
  reasonForFailure?: InputMaybe<Scalars['String']['input']>;
};

export type FailPublishingWorkflowStepInput = {
  publishingWorkflowId: Scalars['ID']['input'];
  publishingWorkflowStepId: Scalars['ID']['input'];
  values?: InputMaybe<Scalars['AWSJSON']['input']>;
};

export type FailWorkflowStepInput = {
  WorkflowId: Scalars['ID']['input'];
  WorkflowStepId: Scalars['ID']['input'];
  statusReason: Scalars['String']['input'];
  values?: InputMaybe<Array<UserInputWithFieldDefinitionKeyInput>>;
};

export type FederatedIdentity = {
  __typename?: 'FederatedIdentity';
  loginId: Scalars['String']['output'];
  loginMethod: Scalars['String']['output'];
};

export type FederatedIdentityActionInput = {
  federatedId: FederatedIdentityInput;
  userId: Scalars['ID']['input'];
};

export type FederatedIdentityInput = {
  loginId: Scalars['String']['input'];
  loginMethod: Scalars['String']['input'];
};

export enum FeedbackType {
  ThumbsDown = 'THUMBS_DOWN',
  ThumbsUp = 'THUMBS_UP'
}

export type FieldDefinition = {
  __typename?: 'FieldDefinition';
  canEdit: Scalars['Boolean']['output'];
  defaultValue?: Maybe<Scalars['String']['output']>;
  defaultValueArray?: Maybe<Array<Scalars['String']['output']>>;
  description?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  key: Scalars['String']['output'];
  lowerCharacterLimit?: Maybe<Scalars['Int']['output']>;
  nestedFieldDefinitions?: Maybe<Array<FieldDefinition>>;
  options?: Maybe<Array<Scalars['String']['output']>>;
  required: Scalars['Boolean']['output'];
  type: FieldType;
  upperCharacterLimit?: Maybe<Scalars['Int']['output']>;
};

export type FieldDefinitionInput = {
  canEdit: Scalars['Boolean']['input'];
  defaultValue?: InputMaybe<Scalars['String']['input']>;
  defaultValueArray?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
  key: Scalars['String']['input'];
  lowerCharacterLimit?: InputMaybe<Scalars['Int']['input']>;
  nestedFieldDefinitions?: InputMaybe<Array<FieldDefinitionInput>>;
  options?: InputMaybe<Array<Scalars['String']['input']>>;
  required: Scalars['Boolean']['input'];
  type: FieldType;
  upperCharacterLimit?: InputMaybe<Scalars['Int']['input']>;
};

export enum FieldType {
  Date = 'DATE',
  LargeText = 'LARGE_TEXT',
  MultiInput = 'MULTI_INPUT',
  MultiSelect = 'MULTI_SELECT',
  Number = 'NUMBER',
  SingleSelect = 'SINGLE_SELECT',
  SmallText = 'SMALL_TEXT',
  Time = 'TIME',
  Toggle = 'TOGGLE'
}

export type File = {
  __typename?: 'File';
  mimeType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  size: Scalars['Int']['output'];
};

/** File entity information */
export type FileEntity = {
  __typename?: 'FileEntity';
  /** Catalog items associated with this file entity for LCMS */
  catalogItems: CatalogItemConnection;
  comments?: Maybe<EntityComments>;
  createdAt: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  customAttributes?: Maybe<Scalars['AWSJSON']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /** downloadLocation will require additional S3 permissions to allow access */
  downloadLocation?: Maybe<Scalars['String']['output']>;
  extension: Scalars['String']['output'];
  /** Unique identifier for the file entity */
  id: Scalars['ID']['output'];
  lastUpdatedAt: Scalars['String']['output'];
  lastUpdatedBy: Scalars['String']['output'];
  mimeType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  org: Scalars['String']['output'];
  owner: Scalars['String']['output'];
  presignedDownloadUrl?: Maybe<Scalars['AWSURL']['output']>;
  presignedUploadUrl?: Maybe<Scalars['AWSURL']['output']>;
  processingStatus: FileProcessingStatus;
  relatedItems?: Maybe<Array<ContentRelatedItem>>;
  scopeChangeAndNotes: ScopeChangeAndNotes;
  semanticVersion: SemanticVersion;
  size: MeasuredValue;
  state: State;
  tags?: Maybe<Array<KeyValuePair>>;
  /** uploadLocation will require additional S3 permissions to allow access */
  uploadLocation?: Maybe<Scalars['String']['output']>;
  /** Version of the file entity */
  version: Scalars['Int']['output'];
  xAPIOutline?: Maybe<Scalars['AWSJSON']['output']>;
};


/** File entity information */
export type FileEntityCatalogItemsArgs = {
  associationType: FileEntityCatalogItemAssociationType;
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


/** File entity information */
export type FileEntityRelatedItemsArgs = {
  state?: InputMaybe<State>;
};

/** Types of associations between file entities and catalog items */
export enum FileEntityCatalogItemAssociationType {
  Content = 'CONTENT',
  Source = 'SOURCE'
}

export type FileEntityConnection = {
  __typename?: 'FileEntityConnection';
  nodes?: Maybe<Array<Maybe<FileEntity>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type FileEntityIdVersion = {
  id: Scalars['ID']['input'];
  version: Scalars['Int']['input'];
};

export type FileEntityRelatedItemInput = {
  fileEntityId: Scalars['ID']['input'];
  relatedItem: ContentRelatedItemInput;
};

export type FileEntitySearchFilters = {
  query?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<State>;
};

export type FileLocation = {
  __typename?: 'FileLocation';
  fileName: Scalars['String']['output'];
  location: Scalars['String']['output'];
};

export type FileMetadata = {
  __typename?: 'FileMetadata';
  auditMetadata: AuditMetadata;
  comments?: Maybe<EntityComments>;
  contentType?: Maybe<ContentType>;
  description: Scalars['String']['output'];
  file: File;
  fileProcessingStatus?: Maybe<FileProcessingStatus>;
  id: Scalars['ID']['output'];
  isAccessibilityCompliant?: Maybe<Scalars['Boolean']['output']>;
  isApprovedForReuse?: Maybe<Scalars['Boolean']['output']>;
  languageCode: LanguageCode;
  learningContainers?: Maybe<LearningContainerConnection>;
  lxp?: Maybe<Lxp>;
  lxpAttributes?: Maybe<Array<UserInputWithFieldDefinitionKey>>;
  permission?: Maybe<ContentPermission>;
  presignedDownloadUrl?: Maybe<Scalars['AWSURL']['output']>;
  productOwner?: Maybe<Scalars['String']['output']>;
  relatedItems: Array<ContentRelatedItem>;
  s3ObjectKey?: Maybe<Scalars['String']['output']>;
  scopeChangeAndNotes?: Maybe<ScopeChangeAndNotes>;
  semanticVersion: SemanticVersion;
  state?: Maybe<ContentState>;
  tags?: Maybe<Array<Maybe<KeyValuePair>>>;
  title: Scalars['String']['output'];
  version: Scalars['Int']['output'];
  watcherEmail: Array<Scalars['String']['output']>;
};


export type FileMetadataRelatedItemsArgs = {
  state?: InputMaybe<State>;
};

export type FileMetadataConnection = {
  __typename?: 'FileMetadataConnection';
  nodes: Array<FileMetadata>;
  pageInfo?: Maybe<PageInfo>;
};

export type FileMetadataSearchFilters = {
  query?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export enum FileProcessingStatus {
  AttributeParsingFailed = 'ATTRIBUTE_PARSING_FAILED',
  AttributeParsingInProgress = 'ATTRIBUTE_PARSING_IN_PROGRESS',
  Clean = 'CLEAN',
  Infected = 'INFECTED',
  InProgress = 'IN_PROGRESS',
  NoFileUploaded = 'NO_FILE_UPLOADED',
  PresignedUrlCreated = 'PRESIGNED_URL_CREATED',
  ProcessingComplete = 'PROCESSING_COMPLETE',
  ProcessingFailed = 'PROCESSING_FAILED'
}

export type FileRelatedItemInput = {
  fileMetadataId: Scalars['ID']['input'];
  relatedItem: ContentRelatedItemInput;
};

export type FileToLearningContainerAssignmentInput = {
  fileMetadataId: Scalars['ID']['input'];
  fileMetadataVersion: Scalars['Int']['input'];
  learningContainerId: Scalars['ID']['input'];
  learningContainerVersion: Scalars['Int']['input'];
};

export type FilterOptions = {
  /** Filter the completion certificates in the response to match the specific catalogItemId */
  completionCertificateCatalogItemId?: InputMaybe<Scalars['ID']['input']>;
};

/** Configures how the Finalize Checkout Session Component is displayed */
export type FinalizeCheckoutSessionComponent = {
  __typename?: 'FinalizeCheckoutSessionComponent';
  /** Text of submit CTA */
  submitText: Scalars['String']['output'];
  /** Terms to display on component */
  terms: Array<CheckoutSessionTerm>;
};

export type FinalizeCheckoutSessionComponentInput = {
  submitText: Scalars['String']['input'];
  terms: Array<CheckoutSessionTermInput>;
};

export type FinalizeCheckoutSessionInput = {
  sessionId: Scalars['ID']['input'];
};

export type FinalizeTeamSubscriptionInput = {
  aceOpportunityId?: InputMaybe<Scalars['String']['input']>;
  allowedEmailDomains?: InputMaybe<Scalars['String']['input']>;
  awsToken: Scalars['String']['input'];
  businessEmail: Scalars['String']['input'];
  companyName: Scalars['String']['input'];
  customerCountry?: InputMaybe<Scalars['String']['input']>;
  discountPercentage: Scalars['Float']['input'];
  endCustomerCompanyName?: InputMaybe<Scalars['String']['input']>;
  endCustomerCountry?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  geo: Geo;
  language: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  learningAdministrators: Array<LearningAdministratorInput>;
  linkedAccountId: Scalars['String']['input'];
  numberOfSeats: Scalars['Int']['input'];
  partnerRequest?: InputMaybe<Scalars['Boolean']['input']>;
  payerAccountId: Scalars['String']['input'];
  preferredStartDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  price: Scalars['Float']['input'];
  signInMethod: SignInMethod;
  spmsId?: InputMaybe<Scalars['Int']['input']>;
  tcEmail?: InputMaybe<Scalars['String']['input']>;
  tcFirstName?: InputMaybe<Scalars['String']['input']>;
  tcLastName?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
  vibeId?: InputMaybe<Scalars['String']['input']>;
};

export type FreeTrialDetails = {
  __typename?: 'FreeTrialDetails';
  freeTrialEndsAt: Scalars['AWSDateTime']['output'];
  freeTrialStartsAt: Scalars['AWSDateTime']['output'];
};

export type FreeTrialDetailsInput = {
  freeTrialEndsAt: Scalars['AWSDateTime']['input'];
  freeTrialStartsAt: Scalars['AWSDateTime']['input'];
};

export type Fulfillment = {
  __typename?: 'Fulfillment';
  fulfillmentItems: Array<FulfillmentItem>;
  id: Scalars['ID']['output'];
  learningAccount?: Maybe<LearningAccount>;
  order: Order;
  organization?: Maybe<Organization>;
  status: FulfillmentStatus;
  statusDate?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type FulfillmentItem = {
  __typename?: 'FulfillmentItem';
  /** The current step of the item's fulfillment process. */
  currentStepIndex: Scalars['Int']['output'];
  /**
   * Note: THIS FIELD IS TEMPORARY. Will be replaced by a generic `details` field in the future.
   * The fulfillment details entered by a Learning Admin for Digital modality.
   */
  digitalFulfillmentDetails?: Maybe<DigitalFulfillmentDetails>;
  id: Scalars['ID']['output'];
  /** The list of order items associated with this fulfillment item */
  orderItems?: Maybe<Array<Maybe<OrderItem>>>;
  /** Reason for failing a fulfillment item */
  reasonForFailure?: Maybe<Scalars['String']['output']>;
  /** Resulting data after fulfillment of this item has completed as a list of key-value pairs. ex. Branch ID or ticket ID */
  resultData: Array<KeyValuePair>;
  /** A general status of this item's fulfillment (PENDING, IN_PROGRESS, COMPLETED, FAILED). */
  status?: Maybe<FulfillmentItemStatus>;
  /** Date when a fulfillment item reached a status. ex: acknowledgedDateTime is when Fulfillment item was acknowledged */
  statusDate?: Maybe<Scalars['AWSDateTime']['output']>;
  /**
   * The steps of the fulfillment item, provided by the Modality Integration Service.
   * Used for surfacing fulfillment status details to users.
   */
  steps: Array<FulfillmentItemStep>;
  /** The type of fulfillment that needs to happen. ex. TeamSubscription */
  type?: Maybe<Scalars['String']['output']>;
};

export enum FulfillmentItemStatus {
  /** The Fulfillment Service stop processing awaiting addditional IDP information from customer. */
  AwaitingAdditionalInfo = 'AWAITING_ADDITIONAL_INFO',
  /**
   * If an OrderItem is canceled prior to Fulfillment completed, the FulfillmentItem
   * will be Canceled as well.
   */
  Canceled = 'CANCELED',
  /** The Modality Integration Service has completed fulfillment of this item. */
  Completed = 'COMPLETED',
  /** The FulfillmentItem has been created but has not started fulfillment. */
  Created = 'CREATED',
  /** The Modality Integration Service failed to fulfill the item. */
  Failed = 'FAILED',
  /** The Modality Integration Service has started fulfillment of this item. */
  InProgress = 'IN_PROGRESS',
  /** The Fulfillment Service has requested the item be fulfilled by a Modality Integration Service. */
  Pending = 'PENDING'
}

export type FulfillmentItemStep = {
  __typename?: 'FulfillmentItemStep';
  /** The 0-indexed step in the fulfillment process. */
  index?: Maybe<Scalars['Int']['output']>;
  /** The name of the step to display on the Fulfillment Item. */
  name?: Maybe<Scalars['String']['output']>;
};

export type FulfillmentItemStepInput = {
  index?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type FulfillmentItemUploadLinksInput = {
  nonProdIDPMetaDataLink?: InputMaybe<Scalars['String']['input']>;
  prodIDPMetaDataLink?: InputMaybe<Scalars['String']['input']>;
};

export type FulfillmentItemUploadUrLs = {
  __typename?: 'FulfillmentItemUploadURLs';
  nonProdURL?: Maybe<Scalars['String']['output']>;
  prodURL?: Maybe<Scalars['String']['output']>;
};

export enum FulfillmentStatus {
  /**
   * If an Order is canceled prior to Fulfillment details being captured, the Fulfillment
   * will be Canceled as well.
   */
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  Started = 'STARTED'
}

/** hatId subject to change when gandalf figures out merging */
export type GandalfDetails = {
  __typename?: 'GandalfDetails';
  allAudiencePaths?: Maybe<Array<Scalars['String']['output']>>;
  audiencePath?: Maybe<Scalars['String']['output']>;
  hatId: Scalars['String']['output'];
  lastViasUpdateAt?: Maybe<Scalars['AWSDateTime']['output']>;
  vibeId: Scalars['String']['output'];
};

export type GeneratedQuestionInput = {
  answers: Array<Scalars['String']['input']>;
  bloomsTaxonomyLevel: Scalars['String']['input'];
  options: Array<Scalars['String']['input']>;
  question: Scalars['String']['input'];
  rationale: Scalars['String']['input'];
};

export enum Geo {
  Amer = 'AMER',
  Apj = 'APJ',
  Emea = 'EMEA'
}

export type Group = {
  __typename?: 'Group';
  auditMetadata: AuditMetadata;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isSystemUserGroup?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  /** Provides the ownership entity a Group belongs to. */
  ownerEntity?: Maybe<EntityUserGroupOwnerEntity>;
  /**
   * List of users belonging to a Group.
   *
   * Maximum page size is 100. Higher page sizes will be capped at 100.
   */
  userAssociations?: Maybe<UserAssociationConnection>;
  userCount?: Maybe<Scalars['Int']['output']>;
  vendorId?: Maybe<Scalars['String']['output']>;
};


export type GroupUserAssociationsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type GroupConnection = {
  __typename?: 'GroupConnection';
  nodes: Array<Group>;
  pageInfo?: Maybe<PageInfo>;
};

export type GroupLicenseAssignmentResult = {
  __typename?: 'GroupLicenseAssignmentResult';
  failedAssignmentCount: Scalars['Int']['output'];
  status: AssignmentStatus;
  successfulAssignmentCount: Scalars['Int']['output'];
  userGroupId: Scalars['ID']['output'];
};

export enum GroupStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

/**
 * Determines the behavior of session creation when
 * the there is already a open session for the user
 * created by the client
 */
export enum HandleOpenClientSessionStrategy {
  AllowMultiple = 'ALLOW_MULTIPLE',
  AllowSingle = 'ALLOW_SINGLE',
  ReplaceExisting = 'REPLACE_EXISTING'
}

export type HelloWorld = {
  __typename?: 'HelloWorld';
  id: Scalars['ID']['output'];
  recursiveHelloWorlds?: Maybe<Array<Maybe<HelloWorld>>>;
};

export enum IrStatus {
  Approved = 'APPROVED',
  Delivered = 'DELIVERED',
  PartiallyDelivered = 'PARTIALLY_DELIVERED',
  Scheduled = 'SCHEDULED'
}

export type IUserBulkOperation = {
  auditMetadata: AuditMetadata;
  context: UserBulkOperationContext;
  contextDetails: UserBulkOperationContextDetails;
  inputFileMetadata: BulkOperationFileMetadata;
  operationId: Scalars['ID']['output'];
  processDetails?: Maybe<UserBulkOperationProcessDetails>;
  requestor?: Maybe<User>;
  status: UserBulkOperationStatus;
};

export type IdentityQueryInput = {
  email?: InputMaybe<Scalars['AWSEmail']['input']>;
  federatedId: FederatedIdentityInput;
};

export type IdentitySearchResult = {
  __typename?: 'IdentitySearchResult';
  invitationUser?: Maybe<User>;
  user?: Maybe<User>;
};

export type IltEvaluation = {
  __typename?: 'IltEvaluation';
  evaluationStatus: EvaluationStatus;
  evaluationUrl?: Maybe<Scalars['AWSURL']['output']>;
  learningActivity: LearningActivity;
  transcriptId: Scalars['ID']['output'];
  user: User;
};

export type IltEvaluationConnection = {
  __typename?: 'IltEvaluationConnection';
  nodes: Array<IltEvaluation>;
  pageInfo?: Maybe<PageInfo>;
};

export type Installment = {
  __typename?: 'Installment';
  installmentAttemptDates: Array<Scalars['AWSDateTime']['output']>;
  installmentAttemptDatesWithTimeOut?: Maybe<Array<Maybe<InstallmentAttemptDateWithTimeOut>>>;
  installmentEndDate: Scalars['AWSDateTime']['output'];
  installmentStartDate: Scalars['AWSDateTime']['output'];
};

export type InstallmentAttemptDateWithTimeOut = {
  __typename?: 'InstallmentAttemptDateWithTimeOut';
  installmentAttemptDate: Scalars['AWSDateTime']['output'];
  timeoutHours?: Maybe<Scalars['Int']['output']>;
};

export type InstallmentAttemptDateWithTimeOutInput = {
  installmentAttemptDate: Scalars['AWSDateTime']['input'];
  timeoutHours?: InputMaybe<Scalars['Int']['input']>;
};

export type InstallmentInput = {
  installmentAttemptDates: Array<Scalars['AWSDateTime']['input']>;
  installmentAttemptDatesWithTimeOut?: InputMaybe<Array<InputMaybe<InstallmentAttemptDateWithTimeOutInput>>>;
  installmentEndDate: Scalars['AWSDateTime']['input'];
  installmentStartDate: Scalars['AWSDateTime']['input'];
};

export type InstallmentPlan = {
  __typename?: 'InstallmentPlan';
  billingPeriod: OfferBillingPeriod;
  commercePlatformOfferId: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  scheduledInstallments: Array<Installment>;
};

export type InstallmentPlanInput = {
  billingPeriod: OfferBillingPeriod;
  commercePlatformOfferId: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  scheduledInstallments: Array<InstallmentInput>;
};

export type Instructor = {
  __typename?: 'Instructor';
  email?: Maybe<Scalars['AWSEmail']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pk?: Maybe<Scalars['ID']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type InstructorInput = {
  email: Scalars['AWSEmail']['input'];
  role: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type InvitationActionInput = {
  consent: UserConsentInput;
  invitationUserId?: InputMaybe<Scalars['ID']['input']>;
  invitingOrganizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type InvitationQueryInput = {
  email?: InputMaybe<Scalars['AWSEmail']['input']>;
  loginMethod: Scalars['String']['input'];
};

export type Invoice = {
  __typename?: 'Invoice';
  awsAccountId: Scalars['String']['output'];
  baseCurrencyCode?: Maybe<CurrencyCode>;
  baseCurrencyInvoiceAmountAfterTax?: Maybe<Scalars['Float']['output']>;
  baseCurrencyInvoiceAmountBeforeTax?: Maybe<Scalars['Float']['output']>;
  commercePlatformBillId: Scalars['String']['output'];
  createdAt: Scalars['AWSDateTime']['output'];
  currencyType: CurrencyCode;
  documentArn: Scalars['String']['output'];
  errorMessage?: Maybe<Scalars['String']['output']>;
  externalBillId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  invoiceAmountAfterTax?: Maybe<Scalars['Float']['output']>;
  invoiceAmountBeforeTax?: Maybe<Scalars['Float']['output']>;
  invoiceCurrentStatus: InvoiceStatus;
  invoiceDueDate: Scalars['AWSDateTime']['output'];
  invoiceIssuedDate: Scalars['AWSDateTime']['output'];
  invoiceOutstandingBalance?: Maybe<Scalars['Float']['output']>;
  invoiceSettledDate?: Maybe<Scalars['String']['output']>;
  invoiceTotalAmountApplied?: Maybe<Scalars['Float']['output']>;
  invoiceType?: Maybe<InvoiceType>;
  isOverdue?: Maybe<Scalars['Boolean']['output']>;
  modality?: Maybe<Scalars['String']['output']>;
  paymentTerms?: Maybe<Scalars['String']['output']>;
  settlementStatus?: Maybe<SettlementStatus>;
  statementArn: Scalars['String']['output'];
};

export type InvoiceDetailInput = {
  invoiceDate: Scalars['AWSDateTime']['input'];
  invoiceId: Scalars['String']['input'];
};

export type InvoiceDocument = {
  __typename?: 'InvoiceDocument';
  contentType: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  documentData: Scalars['String']['output'];
  invoiceId: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export enum InvoiceStatus {
  CreditMemoIssueSucceeded = 'CREDIT_MEMO_ISSUE_SUCCEEDED',
  InvoiceIssueFailed = 'INVOICE_ISSUE_FAILED',
  InvoiceIssueSucceeded = 'INVOICE_ISSUE_SUCCEEDED'
}

export enum InvoiceType {
  CreditMemo = 'CREDIT_MEMO',
  CreditMemoPreAuthorization = 'CREDIT_MEMO_PRE_AUTHORIZATION',
  DemitMemo = 'DEMIT_MEMO',
  Invoice = 'INVOICE'
}

export type JamConfig = {
  __typename?: 'JamConfig';
  endsOn: Scalars['AWSTimestamp']['output'];
  startsOn: Scalars['AWSTimestamp']['output'];
  teamSize: Scalars['Int']['output'];
};

export type JamConfigInput = {
  endsOn: Scalars['AWSTimestamp']['input'];
  startsOn: Scalars['AWSTimestamp']['input'];
  teamSize: Scalars['Int']['input'];
};

export type KeyValuePair = {
  __typename?: 'KeyValuePair';
  key: Scalars['String']['output'];
  value?: Maybe<Scalars['AWSJSON']['output']>;
};

export type KeyValuePairInput = {
  key: Scalars['String']['input'];
  value: Scalars['AWSJSON']['input'];
};

export enum Lxp {
  Knet = 'KNET',
  Skillbuilder = 'SKILLBUILDER'
}

/** Known language codes */
export enum LanguageCode {
  ArSa = 'AR_SA',
  DeDe = 'DE_DE',
  ElGr = 'EL_GR',
  EnUs = 'EN_US',
  Es_419 = 'ES_419',
  EsEs = 'ES_ES',
  FrCa = 'FR_CA',
  FrFr = 'FR_FR',
  HeIl = 'HE_IL',
  IdId = 'ID_ID',
  ItIt = 'IT_IT',
  JaJp = 'JA_JP',
  KoKr = 'KO_KR',
  PlPl = 'PL_PL',
  PtBr = 'PT_BR',
  RuRu = 'RU_RU',
  ThTh = 'TH_TH',
  TrTr = 'TR_TR',
  ViVn = 'VI_VN',
  ZhCn = 'ZH_CN',
  ZhTw = 'ZH_TW'
}

export type LearnerActivity = {
  __typename?: 'LearnerActivity';
  actorId: Scalars['String']['output'];
  completionPercentage?: Maybe<Scalars['Int']['output']>;
  hasLearnerActivities: Scalars['Boolean']['output'];
  lastActivityTimestamp?: Maybe<Scalars['AWSDateTime']['output']>;
  objectId: Scalars['String']['output'];
  registrationId?: Maybe<Scalars['String']['output']>;
};

export type LearnerActivityConnection = {
  __typename?: 'LearnerActivityConnection';
  learnerActivities: Array<Maybe<LearnerActivity>>;
  pageInfo?: Maybe<PageInfo>;
};

export type LearningAccount = {
  __typename?: 'LearningAccount';
  auditMetadata: AuditMetadata;
  awsAccountId: Scalars['String']['output'];
  awsAssociation?: Maybe<AwsAssociation>;
  billingAddress?: Maybe<Address>;
  billingAwsAccountIds?: Maybe<Array<Scalars['String']['output']>>;
  customerSince?: Maybe<Scalars['AWSDateTime']['output']>;
  id: Scalars['ID']['output'];
  learningAccountSettings?: Maybe<OrganizationSettings>;
  learningAccountVisibilityClassification?: Maybe<OrganizationVisibilityClassification>;
  name: Scalars['String']['output'];
  /** List of orderItems for this Learning Account. */
  orderItems: OrderItemConnection;
  /** List of orders for this Learning Account. */
  orders: OrderConnection;
  orgType: OrganizationType;
  phoneNumber?: Maybe<Scalars['AWSPhone']['output']>;
  sfdcAccountId?: Maybe<Scalars['String']['output']>;
  sfdcParentAccountId?: Maybe<Scalars['String']['output']>;
  skillBuilderBranchId?: Maybe<Scalars['String']['output']>;
  skillBuilderNumericBranchId?: Maybe<Scalars['Int']['output']>;
  status: LearningAccountStatus;
};


export type LearningAccountOrderItemsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  input?: InputMaybe<OrdersFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type LearningAccountOrdersArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  input?: InputMaybe<OrdersFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export enum LearningAccountStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type LearningAccountsConnection = {
  __typename?: 'LearningAccountsConnection';
  nodes: Array<LearningAccount>;
  pageInfo?: Maybe<PageInfo>;
};

export type LearningActivitiesInput = {
  activityAudience?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  activityModality?: InputMaybe<Scalars['String']['input']>;
  activityPks?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  activityStatus?: InputMaybe<Array<InputMaybe<ActivityStatus>>>;
  courseName?: InputMaybe<Scalars['String']['input']>;
  customerAwsAccountIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  deliveryCity?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  deliveryTimezone?: InputMaybe<Scalars['String']['input']>;
  endTimestamp?: InputMaybe<Scalars['AWSTimestamp']['input']>;
  from?: InputMaybe<Scalars['Int']['input']>;
  inviteOnly?: InputMaybe<Scalars['Boolean']['input']>;
  nmbsAgreementIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  program?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  startTimestamp?: InputMaybe<Scalars['AWSTimestamp']['input']>;
};

export type LearningActivity = {
  __typename?: 'LearningActivity';
  activity_audience: Scalars['String']['output'];
  activity_modality: Scalars['String']['output'];
  activity_name?: Maybe<Scalars['String']['output']>;
  activity_status: Scalars['String']['output'];
  activity_type: Scalars['String']['output'];
  additional_owners?: Maybe<Array<Maybe<AdditionalOwner>>>;
  assignLicenseToUser: License;
  attendedCounter: Scalars['Int']['output'];
  billing_invoices?: Maybe<Array<Maybe<BillingInvoice>>>;
  class_size?: Maybe<Scalars['Int']['output']>;
  classrooms_arn?: Maybe<Scalars['String']['output']>;
  classrooms_student_url?: Maybe<Scalars['String']['output']>;
  course_catalog_item?: Maybe<CatalogItem>;
  course_name?: Maybe<Scalars['String']['output']>;
  created_timestamp?: Maybe<Scalars['Int']['output']>;
  customers?: Maybe<Array<Maybe<Customer>>>;
  delivery_address_1?: Maybe<Scalars['String']['output']>;
  delivery_address_2?: Maybe<Scalars['String']['output']>;
  delivery_city?: Maybe<Scalars['String']['output']>;
  delivery_country?: Maybe<Scalars['String']['output']>;
  delivery_geo?: Maybe<Scalars['String']['output']>;
  delivery_language?: Maybe<Scalars['String']['output']>;
  delivery_postal_code?: Maybe<Scalars['String']['output']>;
  delivery_region?: Maybe<Scalars['String']['output']>;
  delivery_sessions: Array<Maybe<DeliverySession>>;
  delivery_state?: Maybe<Scalars['String']['output']>;
  delivery_timezone?: Maybe<Scalars['String']['output']>;
  instructors?: Maybe<Array<Maybe<Instructor>>>;
  invite_only?: Maybe<Scalars['Boolean']['output']>;
  learning_activity_group?: Maybe<LearningActivityGroup>;
  /** List of Licenses for this LearningActivity. */
  licenses: LicenseConnection;
  lms_type?: Maybe<Scalars['String']['output']>;
  modified_timestamp?: Maybe<Scalars['Int']['output']>;
  operations_owner?: Maybe<Scalars['String']['output']>;
  operations_owner_email?: Maybe<Scalars['String']['output']>;
  pk: Scalars['ID']['output'];
  program?: Maybe<Scalars['String']['output']>;
  record_type: Scalars['String']['output'];
  registrationCounter: Scalars['Int']['output'];
  scheduler?: Maybe<Scalars['String']['output']>;
  scheduler_email?: Maybe<Scalars['String']['output']>;
  unassignLicenseFromUser: License;
  v_ilt_id?: Maybe<Scalars['String']['output']>;
  v_ilt_meeting_id?: Maybe<Scalars['String']['output']>;
  v_ilt_type?: Maybe<Scalars['String']['output']>;
  waitlist_enabled?: Maybe<WaitlistEnabled>;
  waitlisted?: Maybe<Scalars['Int']['output']>;
};


export type LearningActivityAssignLicenseToUserArgs = {
  input: LearningActivityAssignLicenseToUserInput;
};


export type LearningActivityLicensesArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  licenseStatus?: InputMaybe<LicenseStatus>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type LearningActivityUnassignLicenseFromUserArgs = {
  input: LearningActivityUnassignLicenseFromUserInput;
};

export type LearningActivityAssignLicenseToUserInput = {
  userId: Scalars['ID']['input'];
};

export type LearningActivityAttendanceRecord = {
  __typename?: 'LearningActivityAttendanceRecord';
  deliverySessionAttendanceRecords: Array<Maybe<AttendanceRecord>>;
  learner: User;
  learningActivity: LearningActivity;
  learningActivityAttendanceStatus: AttendanceStatus;
};

export type LearningActivityGroup = {
  __typename?: 'LearningActivityGroup';
  catalog_item?: Maybe<CatalogItem>;
  end_timestamp?: Maybe<Scalars['AWSTimestamp']['output']>;
  learning_activities?: Maybe<Array<Maybe<LearningActivity>>>;
  name: Scalars['String']['output'];
  pk: Scalars['ID']['output'];
  program_name: Scalars['String']['output'];
  start_timestamp?: Maybe<Scalars['AWSTimestamp']['output']>;
  status: ActivityStatus;
};

export enum LearningActivityLmsType {
  Aci = 'ACI',
  CustomerLms = 'CustomerLMS',
  Kiku = 'Kiku',
  NotDefined = 'NotDefined',
  MyClass = 'myClass'
}

export enum LearningActivityRegistrationStatus {
  Active = 'Active',
  Archived = 'Archived',
  Canceled = 'Canceled',
  Completed = 'Completed',
  /**
   * List of statuses available at
   * https://w.amazon.com/bin/view/AWS_Training_and_Certification/Product_Development/ILT/Grimsby/Schedule_Management_Tool/FunctionalSpecification/#HActivityStatus
   * Type to be deprecated once made available as a sharable type in Learning Activity Data Service (LADS)
   */
  Hold = 'Hold',
  Merged = 'Merged',
  PendingReview = 'PendingReview',
  Tentative = 'Tentative'
}

export type LearningActivityUnassignLicenseFromUserInput = {
  userId: Scalars['ID']['input'];
};

export type LearningAdministrator = {
  __typename?: 'LearningAdministrator';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
};

export type LearningAdministratorInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type LearningContainer = {
  __typename?: 'LearningContainer';
  auditMetadata: AuditMetadata;
  comments?: Maybe<EntityComments>;
  description: Scalars['String']['output'];
  files?: Maybe<FileMetadataConnection>;
  id: Scalars['ID']['output'];
  isAccessibilityCompliant?: Maybe<Scalars['Boolean']['output']>;
  languageCode: LanguageCode;
  lxp?: Maybe<Lxp>;
  lxpAttributes?: Maybe<Array<UserInputWithFieldDefinitionKey>>;
  lxpContentId?: Maybe<Scalars['ID']['output']>;
  lxpCourseUid?: Maybe<Scalars['String']['output']>;
  modality?: Maybe<Modality>;
  objectives?: Maybe<Scalars['String']['output']>;
  permission?: Maybe<ContentPermission>;
  publishingWorkflows?: Maybe<PublishingWorkflowConnection>;
  relatedItems: Array<ContentRelatedItem>;
  relevantTechnologies?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  scopeChangeAndNotes?: Maybe<ScopeChangeAndNotes>;
  semanticVersion: SemanticVersion;
  simLink?: Maybe<Scalars['String']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  state?: Maybe<ContentState>;
  tags?: Maybe<Array<Maybe<KeyValuePair>>>;
  title: Scalars['String']['output'];
  version?: Maybe<Scalars['Int']['output']>;
  watcherEmail: Array<Scalars['String']['output']>;
};


export type LearningContainerRelatedItemsArgs = {
  state?: InputMaybe<State>;
};

export type LearningContainerConnection = {
  __typename?: 'LearningContainerConnection';
  nodes: Array<LearningContainer>;
  pageInfo?: Maybe<PageInfo>;
};

export type LearningContainerInput = {
  audience?: InputMaybe<Audience>;
  description: Scalars['String']['input'];
  domain: Domain;
  duration: Scalars['Int']['input'];
  industry?: InputMaybe<Scalars['String']['input']>;
  isAccessibilityCompliant?: InputMaybe<Scalars['Boolean']['input']>;
  languageCode: LanguageCode;
  level?: InputMaybe<Level>;
  lxp?: InputMaybe<Lxp>;
  lxpAttributes?: InputMaybe<Array<UserInputWithFieldDefinitionKeyInput>>;
  modality: Modality;
  objectives: Scalars['String']['input'];
  permission?: InputMaybe<ContentPermissionInput>;
  prerequisites?: InputMaybe<Array<Scalars['String']['input']>>;
  relevantServices?: InputMaybe<Array<Scalars['String']['input']>>;
  relevantTechnologies?: InputMaybe<Array<Scalars['String']['input']>>;
  role?: InputMaybe<Scalars['String']['input']>;
  segment?: InputMaybe<Scalars['String']['input']>;
  state: State;
  tags?: InputMaybe<Array<InputMaybe<KeyValuePairInput>>>;
  title: Scalars['String']['input'];
};

export type LearningContainerRelatedItemInput = {
  learningContainerId: Scalars['ID']['input'];
  relatedItem: ContentRelatedItemInput;
};

export type LearningContainerSearchFilters = {
  query?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type LearningObjectiveScore = {
  __typename?: 'LearningObjectiveScore';
  learningObjectiveId: Scalars['ID']['output'];
  learningObjectiveName?: Maybe<Scalars['String']['output']>;
  maxScore?: Maybe<Scalars['Float']['output']>;
  numSkipped?: Maybe<Scalars['Int']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
};

/** Legacy Input for creating a catalog item */
export type LegacyCreateCatalogItemInput = {
  /** Details of the item */
  item: CatalogItemInput;
  /** ID of the namespace for the item */
  namespaceId?: InputMaybe<Scalars['String']['input']>;
  /** Semantic version of the item */
  semanticVersion: SemanticVersionInput;
  /** Status of the item */
  status: CatalogItemStatus;
  /** Variant scope of the item */
  variantScope?: InputMaybe<CatalogItemVariantScopeInput>;
};

/** Legacy Input for updating a catalog item version */
export type LegacyUpdateCatalogItemVersionInput = {
  /** ID (non-versioned) of the item to update */
  id: Scalars['ID']['input'];
  /** Updated details of the item */
  item: CatalogItemInput;
  /** Semantic version of the item to update */
  semanticVersion: SemanticVersionInput;
  /** Updated status of the item */
  status: CatalogItemStatus;
};

export enum Level {
  Advanced = 'ADVANCED',
  Fundamental = 'FUNDAMENTAL',
  Intermediate = 'INTERMEDIATE'
}

export type License = {
  __typename?: 'License';
  accessDetails: Array<KeyValuePair>;
  activatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  assignedEntity?: Maybe<LicenseAssignedEntity>;
  assignmentCount?: Maybe<Scalars['Int']['output']>;
  auditMetadata: AuditMetadata;
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
  endDate?: Maybe<Scalars['AWSDateTime']['output']>;
  id: Scalars['ID']['output'];
  lastAssignedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  lastUnassignedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  learningActivity?: Maybe<LearningActivity>;
  licenseMetaData?: Maybe<Array<KeyValuePair>>;
  licenseType?: Maybe<LicenseType>;
  name: Scalars['String']['output'];
  orderItemAssociations: Array<Maybe<LicenseOrderItemAssociation>>;
  productModality?: Maybe<ProductModality>;
  startDate?: Maybe<Scalars['AWSDateTime']['output']>;
  status: LicenseStatus;
  statusReason: Scalars['String']['output'];
  terminatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  user?: Maybe<User>;
};

export type LicenseAllocation = {
  __typename?: 'LicenseAllocation';
  assignedCount: Scalars['Int']['output'];
  assignedEntity: LicenseAssignedEntity;
  availableCount: Scalars['Int']['output'];
  entityType: LicenseEntityType;
  id: Scalars['ID']['output'];
  orderItem: OrderItem;
  orderItemDescription: Scalars['String']['output'];
  orderItemEndDate?: Maybe<Scalars['String']['output']>;
  orderItemStartDate?: Maybe<Scalars['String']['output']>;
  organizationType?: Maybe<OrganizationType>;
  productModality?: Maybe<ProductModality>;
  purchasingOrg: Organization;
};

export type LicenseAllocationConnection = {
  __typename?: 'LicenseAllocationConnection';
  nodes: Array<LicenseAllocation>;
  pageInfo?: Maybe<PageInfo>;
};

export type LicenseAllocationFilterInput = {
  activeOrFutureAllocations?: InputMaybe<Scalars['Boolean']['input']>;
  endDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  greaterThanZeroAvailableCount?: InputMaybe<Scalars['Boolean']['input']>;
  numberOfUsers?: InputMaybe<Scalars['Int']['input']>;
  orderItemId?: InputMaybe<Scalars['String']['input']>;
  prioritizeTeamAndOrgAllocations?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sortByEndDate?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type LicenseAssignedEntity = Organization | Team;

/** Union Type used for defining the catalog/items accessible for LicenseConfiguration on a given Offer */
export type LicenseCatalogResource = CatalogGroup | CatalogItem;

/**
 * This denotes the access for an individual type of configuration
 * LWA and APN are set within seperate offers
 */
export type LicenseConfiguration = {
  __typename?: 'LicenseConfiguration';
  catalogResources?: Maybe<Array<LicenseCatalogResource>>;
  doceboConfiguration?: Maybe<Scalars['String']['output']>;
  licenseOffsetInHours?: Maybe<Scalars['Int']['output']>;
  viasConfiguration?: Maybe<Scalars['String']['output']>;
};

export type LicenseConnection = {
  __typename?: 'LicenseConnection';
  nodes: Array<License>;
  pageInfo?: Maybe<PageInfo>;
};

export enum LicenseEntityType {
  Organization = 'ORGANIZATION',
  Team = 'TEAM'
}

export type LicenseOrderItemAssociation = {
  __typename?: 'LicenseOrderItemAssociation';
  auditMetadata: AuditMetadata;
  orderItem: OrderItem;
  status?: Maybe<OrderItemAssociationStatus>;
  statusDate?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type LicensePolicy = {
  __typename?: 'LicensePolicy';
  actions: Array<AuthorizationActionType>;
  auditMetadata: AuditMetadata;
  id: Scalars['ID']['output'];
  policyStatus: LicensePolicyStatus;
  principal: AuthorizationPrincipal;
  resource: AuthorizationResource;
};

export type LicensePolicyConnection = {
  __typename?: 'LicensePolicyConnection';
  nodes: Array<LicensePolicy>;
  pageInfo?: Maybe<PageInfo>;
};

export enum LicensePolicyStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum LicenseStatus {
  Active = 'ACTIVE',
  Scheduled = 'SCHEDULED',
  Terminated = 'TERMINATED',
  Unused = 'UNUSED'
}

export enum LicenseType {
  B2B = 'B2B',
  B2I = 'B2I'
}

export type LineItem = {
  __typename?: 'LineItem';
  agreementId?: Maybe<Scalars['String']['output']>;
  chargeAmountBeforeTax: Scalars['Float']['output'];
  chargePeriodEndDate: Scalars['AWSDateTime']['output'];
  chargePeriodStartDate: Scalars['AWSDateTime']['output'];
  currencyCode: CurrencyCode;
  customTaxAddress?: Maybe<CustomTaxAddress>;
  customTaxAddressId?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  endUserAwsAccountId?: Maybe<Scalars['String']['output']>;
  itemType: Scalars['String']['output'];
  lineItemAmount?: Maybe<Scalars['Float']['output']>;
  lineItemBalanceAmount?: Maybe<Scalars['Float']['output']>;
  lineNumber?: Maybe<Scalars['String']['output']>;
  purchaseId?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Float']['output'];
  region: Scalars['String']['output'];
  sfdcOpportunityId?: Maybe<Scalars['String']['output']>;
  sfdcOpportunityLineItemId?: Maybe<Scalars['String']['output']>;
  unitPrice?: Maybe<Scalars['Float']['output']>;
  usageUnitName?: Maybe<Scalars['String']['output']>;
};

export type LineItemInput = {
  agreementId?: InputMaybe<Scalars['String']['input']>;
  chargeAmountBeforeTax: Scalars['Float']['input'];
  chargePeriodEndDate: Scalars['AWSDateTime']['input'];
  chargePeriodStartDate: Scalars['AWSDateTime']['input'];
  currencyCode: CurrencyCode;
  customTaxAddress?: InputMaybe<CustomTaxAddressInput>;
  description: Scalars['String']['input'];
  endUserAwsAccountId?: InputMaybe<Scalars['String']['input']>;
  itemType: Scalars['String']['input'];
  lineItemAmount?: InputMaybe<Scalars['Float']['input']>;
  lineItemBalanceAmount?: InputMaybe<Scalars['Float']['input']>;
  quantity: Scalars['Float']['input'];
  region: Scalars['String']['input'];
  sfdcOpportunityId?: InputMaybe<Scalars['String']['input']>;
  sfdcOpportunityLineItemId?: InputMaybe<Scalars['String']['input']>;
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
  usageUnitName?: InputMaybe<Scalars['String']['input']>;
};

export type LinkConfig = {
  __typename?: 'LinkConfig';
  /** Display text to render for link */
  displayText: Scalars['String']['output'];
  /**
   * When a link is inserted inline, this replacement key is used to look up the text to replace
   *
   * For example, if you want to render text "View your order [here]" with a link on "here",
   * the provided text could be "View your order %(ORDER_LINK)"
   * the inlineReplacementKey is "ORDER_LINK",
   * and the displayText is "here".
   *
   * This field is only applicable when the link is used in inline text
   */
  inlineReplacementKey?: Maybe<Scalars['String']['output']>;
  /** URL to link to */
  url: Scalars['String']['output'];
};

export type LinkConfigInput = {
  displayText: Scalars['String']['input'];
  inlineReplacementKey?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

/** A Array to hold localization values for individual feilds */
export type Localization = {
  __typename?: 'Localization';
  description: Scalars['String']['output'];
  languageCode: LanguageCode;
  name: Scalars['String']['output'];
};

export type LocalizedStringV2 = {
  __typename?: 'LocalizedStringV2';
  id: Scalars['String']['output'];
  value: Scalars['String']['output'];
  vendorId?: Maybe<Scalars['String']['output']>;
};

export type Location = {
  __typename?: 'Location';
  address?: Maybe<PhysicalAddress>;
  locationType: LocationType;
  url?: Maybe<Scalars['AWSURL']['output']>;
  virtualLocationSource?: Maybe<VirtualLocationSource>;
};

export type LocationInput = {
  address?: InputMaybe<PhysicalAddressInput>;
  locationType: LocationType;
  url?: InputMaybe<Scalars['AWSURL']['input']>;
  virtualLocationSource?: InputMaybe<VirtualLocationSource>;
};

export enum LocationType {
  Physical = 'PHYSICAL',
  Virtual = 'VIRTUAL'
}

export enum LoginMethod {
  AwsBuilderId = 'AWSBuilderID',
  AwsPartnerNetwork = 'AWSPartnerNetwork',
  AmazonFederate = 'AmazonFederate',
  Esso = 'ESSO',
  Idc = 'IDC',
  LoginWithAmazon = 'LoginWithAmazon',
  Otp = 'OTP'
}

export type LumosEntity = {
  __typename?: 'LumosEntity';
  id: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

export type LxpAttributeTemplate = {
  __typename?: 'LxpAttributeTemplate';
  auditMetadata: AuditMetadata;
  description?: Maybe<Scalars['String']['output']>;
  entity: EntityType;
  fieldDefinitions: Array<FieldDefinition>;
  id: Scalars['ID']['output'];
  lxp?: Maybe<Lxp>;
  name: Scalars['String']['output'];
};

export type LxpAttributeTemplateConnection = {
  __typename?: 'LxpAttributeTemplateConnection';
  nodes: Array<LxpAttributeTemplate>;
  pageInfo?: Maybe<PageInfo>;
};

export enum MimeTypes {
  Html = 'HTML',
  Ics = 'ICS',
  Pdf = 'PDF'
}

export type MailingAddress = {
  __typename?: 'MailingAddress';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  addressLine3?: Maybe<Scalars['String']['output']>;
  attentionName?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  countryCode: Scalars['String']['output'];
  districtOrCounty?: Maybe<Scalars['String']['output']>;
  municipality?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  stateOrRegion?: Maybe<Scalars['String']['output']>;
};

export type MailingAddressInput = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  addressLine3?: InputMaybe<Scalars['String']['input']>;
  attentionName?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  countryCode: Scalars['String']['input'];
  districtOrCounty?: InputMaybe<Scalars['String']['input']>;
  municipality?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  stateOrRegion?: InputMaybe<Scalars['String']['input']>;
};

export type MarkAttendanceInput = {
  deliverySessionId: Scalars['ID']['input'];
  learnerAttendance: Array<LearnerAttendanceInput>;
};

export enum MarketingEmailOption {
  Optin = 'OPTIN',
  Optout = 'OPTOUT'
}

export type MeasuredValue = {
  __typename?: 'MeasuredValue';
  amount: Scalars['Float']['output'];
  unit: Scalars['String']['output'];
};

export type MeasuredValueInput = {
  amount: Scalars['Float']['input'];
  unit: Scalars['String']['input'];
};

export enum Modality {
  Curriculum = 'CURRICULUM',
  Digital = 'DIGITAL',
  Event = 'EVENT'
}

export type ModuleDetail = {
  __typename?: 'ModuleDetail';
  highestScore?: Maybe<Scalars['Int']['output']>;
  moduleLevelCatalogItemID: Scalars['ID']['output'];
  moduleVersion?: Maybe<Scalars['String']['output']>;
  status: DigitalTranscriptStatus;
  version: Scalars['String']['output'];
};

export type MoveUserBetweenOrganizationsInput = {
  destinationOrganizationId: Scalars['ID']['input'];
  sourceOrganizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type MoveUserBetweenSystemUserGroupsInput = {
  destinationGroupId: Scalars['ID']['input'];
  sourceGroupId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Assigns the publishing workflow to the requestor */
  acceptPublishingWorkflows: Array<PublishingWorkflow>;
  acceptUserInvitation?: Maybe<User>;
  acceptWorkflows: Array<Workflow>;
  acknowledgeFulfillmentItem?: Maybe<Fulfillment>;
  /** Marks a catalog group as 'ACTIVE' or in use. Can be called when a group is 'INACTIVE' */
  activateCatalogGroup: CatalogGroup;
  /**
   * Marks a given catalog item version as 'ACTIVE'. This means it's viewable/consumable by customers (provided licensing permissions).
   * This can be called when the item version is in 'DRAFT' or 'MAINTENANCE' status.
   */
  activateCatalogItemVersion: CatalogItem;
  /** Adds a comment to a catalogGroup (LCMS) */
  addCatalogGroupComment: CatalogGroup;
  /** Adds a comment to a catalogItem (LCMS) */
  addCatalogItemComment: CatalogItem;
  addComment: Comment;
  addCommentsToFile: FileMetadata;
  addCommentsToLearningContainer: LearningContainer;
  /** Add a new association between a FileEntity and a FileEntity or an external URL. */
  addFileEntityAssociation: FileEntity;
  addFileEntityComment: FileEntity;
  addFulfillmentItemResultData?: Maybe<Fulfillment>;
  /** Add key value pairs to licenseMetadata */
  addLicenseMetadata: License;
  /**
   * Associates a payment method with a checkout session.
   *
   * Only allowed for "NEW_ORDER" session types, and when they are "OPEN"
   */
  addPaymentMethodToCheckoutSession: CheckoutSession;
  addPublishingWorkflowComment: PublishingWorkflow;
  addPublishingWorkflowTag: PublishingWorkflow;
  addPublishingWorkflowWatcher: PublishingWorkflow;
  /** Add User Associtation Role(s) for a user in Org/Team */
  addUserAssociationRoles: UserAssociation;
  /** Add a user to an existing entity user group. */
  addUserToEntityUserGroup: UserAssociation;
  /** Add a user to an existing group. */
  addUserToGroup?: Maybe<UserAssociation>;
  /** Add a user association to an Organization. */
  addUserToOrganization?: Maybe<UserAssociation>;
  /** Add a user to an existing system user group. */
  addUserToSystemUserGroup: UserAssociation;
  /** Add a user to a Team. */
  addUserToTeam: UserAssociation;
  /**
   * Add an exiting catalog item as a variant to given catalog root item.
   * Currently, an item can be a variant of at most one root.
   * This operation cannot be undone.
   */
  addVariantToCatalogRootItem: CatalogRootItem;
  /**
   * Marks a catalog item as 'ARCHIVED'. These items are hidden from LCMS view.
   * An item with any active versions cannot be archived.
   * Archived items cannot be modified until they are unarchived.
   */
  archiveCatalogItem: CatalogItem;
  /** Archive a FileMetadata. */
  archiveFileEntity: FileEntity;
  /** Archive a FileMetadata. */
  archiveFileMetadata: FileMetadata;
  /** Archive a LearningContainer. */
  archiveLearningContainer: LearningContainer;
  /** Async assign classroom training to userGroup */
  assignClassroomTrainingToUserGroups: TrainingAssignmentTask;
  /** Sync assign classroom training to users */
  assignClassroomTrainingToUsers: Array<TrainingAssignmentResult>;
  /** Assign a license to a user */
  assignLicenseToUser: License;
  /** Assign a number of Licenses from one Organization or Team to another Organization or Team */
  assignLicensesInOrgHierarchy: LicenseConnection;
  /** Assigns Licenses from a LicenseAllocation to a list of Users. */
  assignLicensesToUserGroups: Array<GroupLicenseAssignmentResult>;
  /** Assigns Licenses from a LicenseAllocation to a list of Users. (Current list size is limited to 100) */
  assignLicensesToUsers: Array<UserLicenseAssignmentResult>;
  /** Async assign training to systemGroup */
  assignTrainingToSystemGroup: TrainingAssignmentTask;
  /** Async assign training to userGroup */
  assignTrainingToUserGroup: TrainingAssignmentTask;
  /** Assign training to users */
  assignTrainingToUsers: Array<TrainingAssignmentResult>;
  /**
   * DEPRECATED: Places a catalog item in a group.
   * Please use 'associateCatalogRootItemWithGroup'.
   */
  associateCatalogItemWithGroup: CatalogGroup;
  /**
   * Places a catalog root item in a group. This automatically adds all variants to the group.
   * Can be called for items with PRODUCT classification.
   */
  associateCatalogRootItemWithGroup: CatalogGroup;
  associateFederatedId?: Maybe<User>;
  /** Add a new association between a File and an external URL. */
  associateFileToExternalUrl: FileMetadata;
  /** Add a new association between a File and a File. */
  associateFileToFile: FileMetadata;
  /** Add a new association between a LearningContainer and a File. */
  associateFileToLearningContainer: LearningContainer;
  /** Add a new association between a LearningContainer and an external URL. */
  associateLearningContainerToExternalUrl: LearningContainer;
  /** Add a new association between a LearningContainer and a LearningContainer. */
  associateLearningContainerToLearningContainer: LearningContainer;
  /** Add a new association between a LearningContainer and a related FileMetadata. */
  associateLearningContainerToRelatedFile: LearningContainer;
  /** Bulk operate N users to M entity user groups. */
  bulkOperateEntityUserGroups: BulkEntityUserGroupOperationOutput;
  /** Perform a bulk operation on Organization users. */
  bulkOperateOrganizationUsers?: Maybe<BulkOperateOrganizationUsersOutput>;
  /** Bulk operate N users to M system user groups. */
  bulkOperateSystemUserGroups: BulkSystemUserGroupOperationOutput;
  /** Register bulk users to a LearningActivity */
  bulkRegisterLearningActivity?: Maybe<RegistrationConnection>;
  /** Unwaitlist bulk users from a LearningActivity */
  bulkUnwaitlistLearningActivity?: Maybe<RegistrationConnection>;
  /** Withdraw bulk users from a LearningActivity */
  bulkWithdrawLearningActivity?: Maybe<RegistrationConnection>;
  cancelClassroom: ClassroomAck;
  cancelOrder: Order;
  cancelOrderItem: Order;
  cancelPublishingWorkflow: PublishingWorkflow;
  cancelWorkflow: Workflow;
  /** Check license availability and assign a license to a B2B user */
  checkAndAssignLicenseToB2BUser: CheckAndAssignLicenseToB2BUserResult;
  completeFulfillmentItem?: Maybe<Fulfillment>;
  completePublishingWorkflowStep: PublishingWorkflow;
  completeWorkflowStep: Workflow;
  /** Creates a payment method for customer using their AWS account */
  createAWSAccountPaymentMethod: PaymentMethod;
  /** Create ACI Course Load Transcript */
  createAciCourseLoadTranscript: AciCourseLoadTranscript;
  createAssessment?: Maybe<Assessment>;
  createAssessmentLearningObjective?: Maybe<AssessmentMetadataObject>;
  createAssessmentQuestion?: Maybe<AssessmentQuestion>;
  createAssessmentQuestionBank?: Maybe<AssessmentMetadataObject>;
  createAssessmentTag?: Maybe<AssessmentMetadataObject>;
  createAssessmentUser?: Maybe<AssessmentUser>;
  /** Create/Update LearningActivity, DeliverySession, AttendancePolicyAttributes locally */
  createAttendanceEntities: AttendancePolicyAttributes;
  /**
   * Generate a location with presigned url for user to upload bulk operation file.
   * @deprecated Use `initiateUserBulkOperation` instead.
   */
  createBulkOperationFileUploadUrl?: Maybe<BulkOperationFile>;
  /**
   * DEPRECATED: Creates a new custom attribute schema in a namespace, which allows catalog items to have custom attributes according to this schema.
   * To onboard an attribute schema, please talk with the catalog team.
   */
  createCatalogCustomAttributeSchema: CatalogAttributeSchema;
  /**
   * Creates a new catalog group.
   * This is used to place multiple catalog root items (previously catalog items) into a group for some use case (permissions, experiences, etc.).
   */
  createCatalogGroup: CatalogGroup;
  /**
   * Creates a new catalog item (variant).
   * When created, it will also create the initial 'version' of the Catalog Item.
   */
  createCatalogItem: CatalogItem;
  /**
   * Creates a new version (w/ x.y.z semantic version identifier) of an existing catalog item.
   * This is typically used when the actual content/product this item represents is being fundamentally updated (e.g., new content).
   * If it's a metadata only update, typically should use updateCatalogItemVersion.
   */
  createCatalogItemVersion: CatalogItem;
  /**
   * DEPRECATED: Creates a new catalog namespace to allow defining new custom attribute schemas.
   * To create a namespace, please talk with the catalog team.
   */
  createCatalogNamespace: CatalogNamespace;
  /** Creates a new catalog root item, which is meant to tie multiple related catalog items (variants) together under a single root. */
  createCatalogRootItem: CatalogRootItem;
  createClassroom: ClassroomAck;
  createDevDemoEvent: Scalars['String']['output'];
  /** Create DigitalTranscript */
  createDigitalTranscript: DigitalTranscript;
  /** Create a new entity user group. */
  createEntityUserGroup: EntityUserGroup;
  /** Create a new External User. */
  createExternalUser?: Maybe<User>;
  /** Create a new FileEntity. */
  createFileEntity: FileEntity;
  /** Create FileMetadata. */
  createFileMetadata: FileMetadata;
  createFulfillmentItemUploadURL: Scalars['String']['output'];
  /** Create a new group. */
  createGroup?: Maybe<Group>;
  /** Create a new Internal User. Can only be done by another internal user by providing internal alias. */
  createInternalUser?: Maybe<User>;
  /** Create a new LearningAccount from SFDC/ADMS sync integration. */
  createLearningAccount?: Maybe<LearningAccount>;
  /** Create a new LearningContainer. */
  createLearningContainer: LearningContainer;
  /** Create a new License. */
  createLicense: License;
  /** Create a new license policy */
  createLicensePolicy: LicensePolicy;
  createLxpAttributeTemplate: LxpAttributeTemplate;
  /** Creates a new checkout session that when finalized will create a new order */
  createNewOrderCheckoutSession: CheckoutSession;
  /**
   * It is a SkillBuilder Digital use case specific API hosted as a resolver at back-end
   * Only used by Middle Man Page in CheckoutService front end
   * Firstly it validates:
   * - if user already has orders in Created or FulfilmentStarted status
   * - if user is applicable for free trial with the selected offer
   *
   * Secondly, it:
   * - creates a new checkout session at the SERVICE level for SkillBuilder Digital use case
   * - returns the checkout session redirect url string if succeed
   */
  createNewSbDigitalOrderCheckoutSession?: Maybe<Scalars['String']['output']>;
  createOffer: Offer;
  createOrder: Order;
  /** Create a new Organization. */
  createOrganization?: Maybe<Organization>;
  createOutOfCycleBill?: Maybe<OutOfCycleBill>;
  createPlaygroundEvent: Scalars['String']['output'];
  /** Create a new pre-signed Url for uploading a file to Lumos. */
  createPresignedFileUploadUrl: Scalars['AWSURL']['output'];
  createPublishingWorkflow: PublishingWorkflow;
  /** Create LearningActivity Registration Policy Attributes - store registration information locally */
  createRegistrationPolicyAttributes: RegistrationPolicyAttributes;
  /**
   * Creates a new checkout session that when finalized will replace an order item
   * (cancel it and create a new order)
   */
  createReplaceOrderItemCheckoutSession: CheckoutSession;
  /**
   * Create a review for a given catalog item.
   * Returns ID of created Review.
   */
  createReview: Scalars['ID']['output'];
  /** Create a new system user group. */
  createSystemUserGroup: SystemUserGroup;
  /** Create a new Team. */
  createTeam: Team;
  /** Create a new transcript for the user */
  createTranscript: TranscriptV1;
  /**
   * DEPRECATED: Creates or updates a catalog file (upsert).
   * This is only used for HC#Discover thumbnails, and this will be deprecated as files will be managed in CMS.
   */
  createUpdateCatalogFile: CatalogFile;
  /**
   * Creates a user. This API is for SERVICE to SERVICE calls only.
   * All user creation will be processed through this API after HC Admin.
   */
  createUser?: Maybe<User>;
  createUserAssessment?: Maybe<UserAssessment>;
  createWorkflow: Workflow;
  /**
   * Marks a catalog group as 'INACTIVE' or not in use.
   * Currently, group cannot have any items in it when being deactivated.
   */
  deactivateCatalogGroup: CatalogGroup;
  /**
   * Marks a given catalog item version as 'INACTIVE'. This is currently a terminal state for the item version, meaning once it's INACTIVE, it is fully done.
   * A new version of the same item can still be created.
   * If you want to temporarily disable the item, use 'setMaintenanceForCatalogItemVersion'.
   */
  deactivateCatalogItemVersion: CatalogItem;
  /** Deactivate an existing group. */
  deactivateGroup: Scalars['Boolean']['output'];
  /** Deactivate an organization. */
  deactivateOrganization: Scalars['Boolean']['output'];
  declineUserInvitation?: Maybe<User>;
  /** Deletes a comment on a catalogGroup (LCMS) */
  deleteCatalogGroupComment: Scalars['Boolean']['output'];
  /** Deletes a comment on a catalogItem (LCMS) */
  deleteCatalogItemComment: Scalars['Boolean']['output'];
  /**
   * Permanently deletes a specific version of a catalog item.
   * Currently, this operation is only allowed for draft versions.
   * This is a hard delete operation, meaning the record is completely removed from the database,
   * and the version number becomes available for future use.
   * Use with caution as this action cannot be undone.
   */
  deleteCatalogItemVersion: DeleteCatalogItemVersionResult;
  deleteComment: Scalars['Boolean']['output'];
  deleteCommentsOnFile: Scalars['Boolean']['output'];
  deleteCommentsOnLearningContainer: Scalars['Boolean']['output'];
  deleteFileEntityComment: Scalars['Boolean']['output'];
  deletePublishingWorkflowComment: Scalars['Boolean']['output'];
  /**
   * Idempotent API to delete a review by review ID
   * Returns ID of deleted Review.
   */
  deleteReviewById: Scalars['ID']['output'];
  /** Delete a transcript record */
  deleteTranscript: Scalars['Boolean']['output'];
  /** Soft/Hard Delete Transcript, handled by hardDelete flag */
  deleteTranscriptEntity: Scalars['Boolean']['output'];
  deleteUser?: Maybe<User>;
  /**
   * DEPRECATED: Removes a catalog item from a group.
   * Please use 'disassociateCatalogRootItemFromGroup'.
   */
  disassociateCatalogItemFromGroup: CatalogGroup;
  /**
   * Removes a catalog root item from a group. This automatically adds all variants to the group.
   * Can be called for items with PRODUCT classification.
   */
  disassociateCatalogRootItemFromGroup: CatalogGroup;
  /** Remove an association between a File and an external URL. */
  disassociateFileToExternalUrl: FileMetadata;
  /** Remove an association between a File and a File. */
  disassociateFileToFile: FileMetadata;
  /** Remove an association between a LearningContainer and a File. */
  disassociateFileToLearningContainer: LearningContainer;
  /** Remove an association between a LearningContainer and an external URL. */
  disassociateLearningContainerToExternalUrl: LearningContainer;
  /** Remove an association between a LearningContainer and a LearningContainer. */
  disassociateLearningContainerToLearningContainer: LearningContainer;
  /** Remove an association between a LearningContainer and a related FileMetadata. */
  disassociateLearningContainerToRelatedFile: LearningContainer;
  /** Edits a comment on a catalogGroup (LCMS) */
  editCatalogGroupComment: CatalogGroup;
  /** Edits a comment on a catalogItem (LCMS) */
  editCatalogItemComment: CatalogItem;
  editComment: Comment;
  editCommentsOnFile: FileMetadata;
  editCommentsOnLearningContainer: LearningContainer;
  editFileEntityComment: FileEntity;
  editPublishingWorkflowComment: PublishingWorkflow;
  expireOffer: Scalars['Boolean']['output'];
  failFulfillmentItem?: Maybe<Fulfillment>;
  failPublishingWorkflowStep: PublishingWorkflow;
  failWorkflowStep: Workflow;
  /**
   * Marks the checkout session as "COMPLETE", and depending on the type will:
   * - Create a new order
   * - Cancel an order item
   *
   * Can only be called when the session is "OPEN"
   */
  finalizeCheckoutSession: CheckoutSession;
  finalizeTeamSubscription?: Maybe<SubscriptionRequest>;
  generateDownloadablePublishingWorkflowAuditLog?: Maybe<Scalars['AWSURL']['output']>;
  generateDownloadableWorkflowAuditLog?: Maybe<Scalars['AWSURL']['output']>;
  /**
   * Creates a bulk operation in a pending state, when provided with a valid context as well as input file metadata. Returns
   * details of the operation, including a location (pre-signed S3 url) where user input can be provided, to continue the operation.
   */
  initiateUserBulkOperation: UserBulkOperation;
  /**
   * Creates a UserService user and associates them to the given Organization and Teams if any.
   * Will throw an error if the user cannot be created due to duplicate invitations or
   * an existing user in the Organization with the same email. Returns the created associations.
   */
  inviteUserToOrganization: Array<UserAssociation>;
  /**
   * Creates a UserService user and associates them to the given Team and organization
   * if not already associated.
   */
  inviteUserToTeam: Array<UserAssociation>;
  /**
   * DEPRECATED: Creates a new catalog item (variant).
   * This is only being used by VERC and will be removed after HC launch.
   * Please use createCatalogItem
   */
  legacyCreateCatalogItem: CatalogItem;
  /**
   * DEPRECATED: Updates properties of an existing version (w/ x.y.z semantic version identifier) of a given catalog item.
   * This is typically for metadata-only updates to an item. If content of item is being replaced, that should normally use createCatalogItemVersion to create a new version.
   * This is only being used by VERC and will be removed after HC launch.
   * Please use updateCatalogItemVersion
   */
  legacyUpdateCatalogItemVersion: CatalogItem;
  /** Mark Attendance */
  markAttendance: Array<Maybe<AttendanceRecord>>;
  /** Move an existing user from one organization to another organization. Move will migrate only Learner roles between Orgs. */
  moveUserBetweenOrganizations: UserAssociation;
  /** Move an existing user from one system user group to another system user group. */
  moveUserBetweenSystemUserGroups: UserAssociation;
  orderItemBillingAction: OrderItem;
  pauseAssessment?: Maybe<UserAssessment>;
  /** Register user to a Learning Activity */
  registerLearningActivity: Registration;
  /** Register user to a ACI course */
  registerLearningActivityGroup: Registration;
  /** Register userGroups to a LearningActivity */
  registerLearningActivityToUserGroups?: Maybe<RegistrationConnection>;
  /** Register user to a versioned CatalogItem */
  registerVersionedCatalogItem: Registration;
  /** Remove an existing entity user group. */
  removeEntityUserGroup: Scalars['Boolean']['output'];
  /** Remove an association between a FileEntity and an external URL or FileEntity. */
  removeFileEntityAssociation: FileEntity;
  removePublishingWorkflowTag: PublishingWorkflow;
  removePublishingWorkflowWatcher: PublishingWorkflow;
  /** Remove an existing system user group */
  removeSystemUserGroup: Scalars['Boolean']['output'];
  /** Remove an existing Team. */
  removeTeam: Scalars['Boolean']['output'];
  /** Remove User Associtation Role(s) for a user in Org/Team */
  removeUserAssociationRoles: Scalars['Boolean']['output'];
  /** Remove an existing user from an existing entity user group. */
  removeUserFromEntityUserGroup: Scalars['Boolean']['output'];
  /** Remove an existing user from an existing group. */
  removeUserFromGroup: Scalars['Boolean']['output'];
  /** Remove a user from an organization. Will also remove any associations on user declining invitation. */
  removeUserFromOrganization: Scalars['Boolean']['output'];
  /** Remove an existing user from an existing system user group. */
  removeUserFromSystemUserGroup: Scalars['Boolean']['output'];
  /** Remove an existing user from an existing team. */
  removeUserFromTeam: Scalars['Boolean']['output'];
  replaceOrderItem: ReplaceOrderItemOutput;
  /** Use an older version of a FileMetadata as the latest data. */
  restoreFileEntityVersion: FileEntity;
  /** Use an older version of a FileMetadata as the latest data. */
  restoreFileMetadataVersion: FileMetadata;
  /** Use an older version of a LearningContainer as the latest data. */
  restoreLearningContainerVersion: LearningContainer;
  /** Save an evaluation response id to a transcript */
  saveEvaluationResponse: Evaluation;
  /** Save an evaluation response id to a ILT transcript (deprecated) */
  saveIltEvaluationResponse: IltEvaluation;
  /** Assign learning activity to a collection of users. Can be used for single user (learner UX) or multiple users (admin UX), up to 25 */
  selectLearningActivityFromGroup: Array<Registration>;
  sendNotificationWithQuery?: Maybe<Notification>;
  /**
   * Marks a given catalog item version as under 'MAINTENANCE'.
   * This means it's effectively unlisted, as it won't show up under Search or Recommendations, but is still visible from transcript.
   * Content is not accessible.
   */
  setMaintenanceForCatalogItemVersion: CatalogItem;
  /** Creates a 'shell' or 'invitation' User. */
  setUserStatus?: Maybe<User>;
  startAssessment?: Maybe<UserAssessment>;
  startAssessmentQuestionsGenerationWorkflow?: Maybe<AssessmentQuestionsStartWorkflowOutput>;
  startPublishingWorkflowStep: PublishingWorkflow;
  startWorkflowStep: Workflow;
  submitAssessment: UserAssessment;
  /** Records the exit survey results */
  submitExit: ExitResult;
  submitFulfillmentItemDetails: Scalars['Boolean']['output'];
  submitQuestionFeedback: SubmitQuestionFeedbackOutput;
  syncOrderItemWithSFDC: OrderItem;
  /** Terminate a License. */
  terminateLicense: License;
  /** Service only resolver for transferring resource associations from one userId to another. */
  transferOrganizationAssociations: Scalars['Boolean']['output'];
  /**
   * Request user data transfer from a source to target user. True is returned if success, error is returned for failure.
   * Emits event consumed by Org, Transcript, Registration, and Liscense Services to do their respective transfer operations.
   * These services call back to updateTransferStep, which updates UserTransferSteps DDB with SUCCESS or FAILURE upon operation completion.
   * Source user is deleted following successful transfer process if inactivateSource == true.
   */
  transferUserData?: Maybe<TransferUserDataOutput>;
  /** Marks a catalog item as not 'ARCHIVED'. */
  unarchiveCatalogItem: CatalogItem;
  /** Unarchive a FileMetadata. */
  unarchiveFileEntity: FileEntity;
  /** Unarchive a FileMetadata. */
  unarchiveFileMetadata: FileMetadata;
  /** Unarchive a LearningContainer. */
  unarchiveLearningContainer: LearningContainer;
  /** Unassigned user classroom training */
  unassignClassroomTrainingByAssignmentIds: Array<ClassroomTrainingAssignment>;
  /** Unassign a license from a user */
  unassignLicenseFromUser: License;
  /** Unassign user training */
  unassignTraining: TrainingAssignment;
  /** Unwaitlist use from a Learning Activity */
  unwaitlistLearningActivity: Registration;
  /** Update activity status */
  updateActivityStatus?: Maybe<LearningActivity>;
  updateAssessment?: Maybe<Assessment>;
  updateAssessmentAnswer: Scalars['ID']['output'];
  updateAssessmentLearningObjective?: Maybe<AssessmentMetadataObject>;
  updateAssessmentQuestion?: Maybe<AssessmentQuestion>;
  updateAssessmentQuestionBank?: Maybe<AssessmentMetadataObject>;
  updateAssessmentTag?: Maybe<AssessmentMetadataObject>;
  updateAssessmentUser?: Maybe<AssessmentUser>;
  updateAttendanceEntities: AttendancePolicyAttributes;
  /**
   * DEPRECATED: Updates an existing custom attribute schema.
   * To update an attribute schema, please talk with the catalog team.
   */
  updateCatalogCustomAttributeSchema: CatalogAttributeSchema;
  /** Updates mutable fields of an existing catalog group. */
  updateCatalogGroup: CatalogGroup;
  /**
   * Updates mutable properties of an existing version (w/ x.y.z semantic version identifier) of a given catalog item.
   * One optional input is metadata change used to track metadata-only update requiring approval in LCMS product version publishing.
   * This is typically for metadata-only updates to an item.
   * If content of item is being replaced, that should normally use createCatalogItemVersion to create a new version.
   */
  updateCatalogItemVersion: CatalogItem;
  /**
   * Updates the semantic version number of an existing catalog item draft version.
   * This mutation allows changing the version number without modifying other attributes of the item.
   * This only works for draft versions. Note that this will update the versionedId of the item
   */
  updateCatalogItemVersionNumber: CatalogItem;
  /**
   * DEPRECATED: Updates mutable fields of a catalog namespace.
   * To update a namespace, please talk with the catalog team.
   */
  updateCatalogNamespace: CatalogNamespace;
  /** Updates mutable fields of an existing catalog root item. */
  updateCatalogRootItem: CatalogRootItem;
  updateClassroom: ClassroomAck;
  /** Update an existing entity user group. */
  updateEntityUserGroup: EntityUserGroup;
  /** Update a FileEntity's data. */
  updateFileEntity: FileEntity;
  /** Update a FileMetadata's data. */
  updateFileMetadata: FileMetadata;
  /** Update an existing group. */
  updateGroup?: Maybe<Group>;
  /** Update existing LearningAccount from SFDC/ADMS sync integration. */
  updateLearningAccount?: Maybe<LearningAccount>;
  /** Update a LearningContainer's data. */
  updateLearningContainer: LearningContainer;
  /** Update a license policy */
  updateLicensePolicy: LicensePolicy;
  /** Update a License to OrderItem association. */
  updateLicenseToOrderItemAssociation: License;
  updateLxpAttributeTemplate: LxpAttributeTemplate;
  /** Update an existing Organization. */
  updateOrganization?: Maybe<Organization>;
  updatePublishingWorkflow: PublishingWorkflow;
  updatePublishingWorkflowStepValues: PublishingWorkflow;
  /** Update recording session url */
  updateRecordedSessionUrl?: Maybe<DeliverySession>;
  /** Update LearningActivity Registration Policy Attributes - store registration information locally */
  updateRegistrationPolicyAttributes: RegistrationPolicyAttributes;
  /** Update an existing system user group. */
  updateSystemUserGroup: SystemUserGroup;
  /** Update an existing Team. */
  updateTeam: Team;
  /** Update a transcript record. */
  updateTranscript: TranscriptV1;
  updateTransferStep?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Update a User entity. Typically used for updating userRole or supporting information.
   *
   * This mutation does not support the deletion of fields; leaving out an optional field
   * from the input will not remove that field from the user.
   */
  updateUser?: Maybe<User>;
  /** Update user's association status with Organization. */
  updateUserOrganizationAssociationStatus: UserAssociation;
  updateUserProfile?: Maybe<User>;
  /** Add user to the waiting list for a Learning Activity */
  waitlistLearningActivity: Registration;
  /** Withdraw user from a Learning Activity */
  withdrawLearningActivity: Registration;
  /** Withdraw user from a ACI course */
  withdrawLearningActivityGroup: Registration;
};


export type MutationAcceptPublishingWorkflowsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type MutationAcceptUserInvitationArgs = {
  input: InvitationActionInput;
};


export type MutationAcceptWorkflowsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type MutationAcknowledgeFulfillmentItemArgs = {
  input?: InputMaybe<AcknowledgeFulfillmentItemInput>;
};


export type MutationActivateCatalogGroupArgs = {
  input: ActivateCatalogGroupInput;
};


export type MutationActivateCatalogItemVersionArgs = {
  input: ActivateCatalogItemVersionInput;
};


export type MutationAddCatalogGroupCommentArgs = {
  input: AddCatalogCommentInput;
};


export type MutationAddCatalogItemCommentArgs = {
  input: AddCatalogCommentInput;
};


export type MutationAddCommentArgs = {
  input?: InputMaybe<AddCommentInput>;
};


export type MutationAddCommentsToFileArgs = {
  input: AddCommentsToFileInput;
};


export type MutationAddCommentsToLearningContainerArgs = {
  input: AddCommentsToLearningContainerInput;
};


export type MutationAddFileEntityAssociationArgs = {
  input: FileEntityRelatedItemInput;
};


export type MutationAddFileEntityCommentArgs = {
  input: AddFileEntityCommentInput;
};


export type MutationAddFulfillmentItemResultDataArgs = {
  input: AddFulfillmentItemResultDataInput;
};


export type MutationAddLicenseMetadataArgs = {
  input: AddLicenseMetadataInput;
};


export type MutationAddPaymentMethodToCheckoutSessionArgs = {
  input: AddPaymentMethodToCheckoutSessionInput;
};


export type MutationAddPublishingWorkflowCommentArgs = {
  input?: InputMaybe<AddPublishingWorkflowCommentInput>;
};


export type MutationAddPublishingWorkflowTagArgs = {
  input?: InputMaybe<AddPublishingWorkflowTagInput>;
};


export type MutationAddPublishingWorkflowWatcherArgs = {
  input?: InputMaybe<AddPublishingWorkflowWatcherInput>;
};


export type MutationAddUserAssociationRolesArgs = {
  input: AddUserAssociationRolesInput;
};


export type MutationAddUserToEntityUserGroupArgs = {
  input: AddEntityUserGroupUserInput;
};


export type MutationAddUserToGroupArgs = {
  input: AddGroupUserInput;
};


export type MutationAddUserToOrganizationArgs = {
  input: AddOrganizationUserInput;
};


export type MutationAddUserToSystemUserGroupArgs = {
  input: AddSystemUserGroupUserInput;
};


export type MutationAddUserToTeamArgs = {
  input: AddTeamUserInput;
};


export type MutationAddVariantToCatalogRootItemArgs = {
  input: AddVariantToCatalogRootItemInput;
};


export type MutationArchiveCatalogItemArgs = {
  input: ArchiveCatalogItemInput;
};


export type MutationArchiveFileEntityArgs = {
  id: Scalars['ID']['input'];
};


export type MutationArchiveFileMetadataArgs = {
  input: ArchiveFileMetadataInput;
};


export type MutationArchiveLearningContainerArgs = {
  input: ArchiveLearningContainerInput;
};


export type MutationAssignClassroomTrainingToUserGroupsArgs = {
  input: ClassroomTrainingAssignmentUserGroupsInput;
};


export type MutationAssignClassroomTrainingToUsersArgs = {
  input: ClassroomTrainingAssignmentInput;
};


export type MutationAssignLicenseToUserArgs = {
  input: AssignLicenseToUserInput;
};


export type MutationAssignLicensesInOrgHierarchyArgs = {
  input: AssignLicensesInOrgHierarchyInput;
};


export type MutationAssignLicensesToUserGroupsArgs = {
  input: AssignLicensesToUserGroupsInput;
};


export type MutationAssignLicensesToUsersArgs = {
  input: AssignLicensesToUsersInput;
};


export type MutationAssignTrainingToSystemGroupArgs = {
  input: TrainingAssignmentSystemGroupInput;
};


export type MutationAssignTrainingToUserGroupArgs = {
  input: TrainingAssignmentUserGroupInput;
};


export type MutationAssignTrainingToUsersArgs = {
  input: TrainingAssignmentInput;
};


export type MutationAssociateCatalogItemWithGroupArgs = {
  input: AssociateCatalogItemWithGroupInput;
};


export type MutationAssociateCatalogRootItemWithGroupArgs = {
  input: AssociateCatalogRootItemWithGroupInput;
};


export type MutationAssociateFederatedIdArgs = {
  input: FederatedIdentityActionInput;
};


export type MutationAssociateFileToExternalUrlArgs = {
  input: FileRelatedItemInput;
};


export type MutationAssociateFileToFileArgs = {
  input: FileRelatedItemInput;
};


export type MutationAssociateFileToLearningContainerArgs = {
  input: FileToLearningContainerAssignmentInput;
};


export type MutationAssociateLearningContainerToExternalUrlArgs = {
  input: LearningContainerRelatedItemInput;
};


export type MutationAssociateLearningContainerToLearningContainerArgs = {
  input: LearningContainerRelatedItemInput;
};


export type MutationAssociateLearningContainerToRelatedFileArgs = {
  input: LearningContainerRelatedItemInput;
};


export type MutationBulkOperateEntityUserGroupsArgs = {
  input: BulkEntityUserGroupOperationInput;
};


export type MutationBulkOperateOrganizationUsersArgs = {
  input: BulkOperateOrganizationUsersInput;
};


export type MutationBulkOperateSystemUserGroupsArgs = {
  input: BulkSystemUserGroupOperationInput;
};


export type MutationBulkRegisterLearningActivityArgs = {
  learningActivityId: Scalars['ID']['input'];
  userIds: Array<Scalars['ID']['input']>;
};


export type MutationBulkUnwaitlistLearningActivityArgs = {
  learningActivityId: Scalars['ID']['input'];
  userIds: Array<Scalars['ID']['input']>;
};


export type MutationBulkWithdrawLearningActivityArgs = {
  learningActivityId: Scalars['ID']['input'];
  userIds: Array<Scalars['ID']['input']>;
};


export type MutationCancelClassroomArgs = {
  classroomArn: Scalars['String']['input'];
};


export type MutationCancelOrderArgs = {
  input: CancelOrderInput;
};


export type MutationCancelOrderItemArgs = {
  input: CancelOrderItemInput;
};


export type MutationCancelPublishingWorkflowArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCancelWorkflowArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCheckAndAssignLicenseToB2BUserArgs = {
  catalogItemId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationCompleteFulfillmentItemArgs = {
  input?: InputMaybe<CompleteFulfillmentItemInput>;
};


export type MutationCompletePublishingWorkflowStepArgs = {
  input?: InputMaybe<CompletePublishingWorkflowStepInput>;
};


export type MutationCompleteWorkflowStepArgs = {
  input: CompleteWorkflowStepInput;
};


export type MutationCreateAwsAccountPaymentMethodArgs = {
  input: CreateAwsAccountPaymentMethodInput;
};


export type MutationCreateAciCourseLoadTranscriptArgs = {
  input: CreateAciCourseLoadTranscriptInput;
};


export type MutationCreateAssessmentArgs = {
  input: AssessmentInput;
};


export type MutationCreateAssessmentLearningObjectiveArgs = {
  input: AssessmentMetadataInput;
};


export type MutationCreateAssessmentQuestionArgs = {
  input: AssessmentQuestionInput;
};


export type MutationCreateAssessmentQuestionBankArgs = {
  input: AssessmentMetadataInput;
};


export type MutationCreateAssessmentTagArgs = {
  input: AssessmentMetadataInput;
};


export type MutationCreateAssessmentUserArgs = {
  input: CreateAssessmentUserInput;
};


export type MutationCreateAttendanceEntitiesArgs = {
  input: CreateOrUpdateAttendanceEntitiesInput;
};


export type MutationCreateBulkOperationFileUploadUrlArgs = {
  fileMetadata: BulkOperationFileMetadataInput;
  learningAccountId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationCreateCatalogCustomAttributeSchemaArgs = {
  input: CreateCatalogCustomAttributeSchemaInput;
};


export type MutationCreateCatalogGroupArgs = {
  input: CreateCatalogGroupInput;
};


export type MutationCreateCatalogItemArgs = {
  input: CreateCatalogItemInput;
};


export type MutationCreateCatalogItemVersionArgs = {
  input: CreateCatalogItemVersionInput;
};


export type MutationCreateCatalogNamespaceArgs = {
  input: CreateCatalogNamespaceInput;
};


export type MutationCreateCatalogRootItemArgs = {
  input: CreateCatalogRootItemInput;
};


export type MutationCreateClassroomArgs = {
  input: ClassroomInput;
};


export type MutationCreateDevDemoEventArgs = {
  input?: InputMaybe<DevDemoEventInput>;
};


export type MutationCreateDigitalTranscriptArgs = {
  input: CreateDigitalTranscriptInput;
};


export type MutationCreateEntityUserGroupArgs = {
  input: CreateEntityUserGroupInput;
};


export type MutationCreateExternalUserArgs = {
  input: CreateExternalUserInput;
};


export type MutationCreateFileEntityArgs = {
  input: CreateFileEntityInput;
};


export type MutationCreateFileMetadataArgs = {
  input: CreateFileMetadataInput;
};


export type MutationCreateFulfillmentItemUploadUrlArgs = {
  input?: InputMaybe<CreateFulfillmentItemUploadUrlInput>;
};


export type MutationCreateGroupArgs = {
  input: CreateGroupInput;
};


export type MutationCreateInternalUserArgs = {
  input: CreateInternalUserInput;
};


export type MutationCreateLearningAccountArgs = {
  input?: InputMaybe<CreateLearningAccountInput>;
};


export type MutationCreateLearningContainerArgs = {
  input: CreateLearningContainerInput;
};


export type MutationCreateLicenseArgs = {
  input: CreateLicenseInput;
};


export type MutationCreateLicensePolicyArgs = {
  input: CreateLicensePolicyInput;
};


export type MutationCreateLxpAttributeTemplateArgs = {
  input: CreateLxpAttributeTemplateInput;
};


export type MutationCreateNewOrderCheckoutSessionArgs = {
  input: CreateNewOrderCheckoutSessionInput;
};


export type MutationCreateNewSbDigitalOrderCheckoutSessionArgs = {
  input: CreateNewSbDigitalOrderCheckoutSessionInput;
};


export type MutationCreateOfferArgs = {
  input: CreateOfferInput;
};


export type MutationCreateOrderArgs = {
  input: CreateOrderInput;
};


export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};


export type MutationCreateOutOfCycleBillArgs = {
  input: CreateOutOfCycleBillInput;
};


export type MutationCreatePlaygroundEventArgs = {
  input?: InputMaybe<PlaygroundEventInput>;
};


export type MutationCreatePresignedFileUploadUrlArgs = {
  input: CreatePresignedFileUploadUrlInput;
};


export type MutationCreatePublishingWorkflowArgs = {
  input?: InputMaybe<CreatePublishingWorkflowInput>;
};


export type MutationCreateRegistrationPolicyAttributesArgs = {
  input: RegistrationPolicyAttributesInput;
};


export type MutationCreateReplaceOrderItemCheckoutSessionArgs = {
  input: CreateReplaceOrderItemCheckoutSessionInput;
};


export type MutationCreateReviewArgs = {
  input: CreateReviewInput;
};


export type MutationCreateSystemUserGroupArgs = {
  input: CreateSystemUserGroupInput;
};


export type MutationCreateTeamArgs = {
  input: CreateTeamInput;
};


export type MutationCreateTranscriptArgs = {
  input: CreateTranscriptInput;
};


export type MutationCreateUpdateCatalogFileArgs = {
  input: CreateUpdateCatalogFileInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationCreateUserAssessmentArgs = {
  input: UserAssessmentInput;
};


export type MutationCreateWorkflowArgs = {
  input: CreateWorkflowInput;
};


export type MutationDeactivateCatalogGroupArgs = {
  input: DeactivateCatalogGroupInput;
};


export type MutationDeactivateCatalogItemVersionArgs = {
  input: DeactivateCatalogItemVersionInput;
};


export type MutationDeactivateGroupArgs = {
  groupId: Scalars['ID']['input'];
};


export type MutationDeactivateOrganizationArgs = {
  organizationId: Scalars['ID']['input'];
};


export type MutationDeclineUserInvitationArgs = {
  input: InvitationActionInput;
};


export type MutationDeleteCatalogGroupCommentArgs = {
  input: DeleteCatalogCommentInput;
};


export type MutationDeleteCatalogItemCommentArgs = {
  input: DeleteCatalogCommentInput;
};


export type MutationDeleteCatalogItemVersionArgs = {
  input: DeleteCatalogItemVersionInput;
};


export type MutationDeleteCommentArgs = {
  input?: InputMaybe<DeleteCommentInput>;
};


export type MutationDeleteCommentsOnFileArgs = {
  input: DeleteCommentsOnFileInput;
};


export type MutationDeleteCommentsOnLearningContainerArgs = {
  input: DeleteCommentsOnLearningContainerInput;
};


export type MutationDeleteFileEntityCommentArgs = {
  input: DeleteFileEntityCommentInput;
};


export type MutationDeletePublishingWorkflowCommentArgs = {
  commentId: Scalars['ID']['input'];
};


export type MutationDeleteReviewByIdArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTranscriptArgs = {
  input: DeleteTranscriptInput;
};


export type MutationDeleteTranscriptEntityArgs = {
  input: DeleteTranscriptEntityInput;
};


export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};


export type MutationDisassociateCatalogItemFromGroupArgs = {
  input: DisassociateCatalogItemFromGroupInput;
};


export type MutationDisassociateCatalogRootItemFromGroupArgs = {
  input: DisassociateCatalogRootItemFromGroupInput;
};


export type MutationDisassociateFileToExternalUrlArgs = {
  input: FileRelatedItemInput;
};


export type MutationDisassociateFileToFileArgs = {
  input: FileRelatedItemInput;
};


export type MutationDisassociateFileToLearningContainerArgs = {
  input: FileToLearningContainerAssignmentInput;
};


export type MutationDisassociateLearningContainerToExternalUrlArgs = {
  input: LearningContainerRelatedItemInput;
};


export type MutationDisassociateLearningContainerToLearningContainerArgs = {
  input: LearningContainerRelatedItemInput;
};


export type MutationDisassociateLearningContainerToRelatedFileArgs = {
  input: LearningContainerRelatedItemInput;
};


export type MutationEditCatalogGroupCommentArgs = {
  input: EditCatalogCommentInput;
};


export type MutationEditCatalogItemCommentArgs = {
  input: EditCatalogCommentInput;
};


export type MutationEditCommentArgs = {
  input?: InputMaybe<EditCommentInput>;
};


export type MutationEditCommentsOnFileArgs = {
  input: EditCommentsOnFileInput;
};


export type MutationEditCommentsOnLearningContainerArgs = {
  input: EditCommentsOnLearningContainerInput;
};


export type MutationEditFileEntityCommentArgs = {
  input: EditFileEntityCommentInput;
};


export type MutationEditPublishingWorkflowCommentArgs = {
  input?: InputMaybe<EditPublishingWorkflowCommentInput>;
};


export type MutationExpireOfferArgs = {
  input: ExpireOfferInput;
};


export type MutationFailFulfillmentItemArgs = {
  input?: InputMaybe<FailFulfillmentItemInput>;
};


export type MutationFailPublishingWorkflowStepArgs = {
  input?: InputMaybe<FailPublishingWorkflowStepInput>;
};


export type MutationFailWorkflowStepArgs = {
  input: FailWorkflowStepInput;
};


export type MutationFinalizeCheckoutSessionArgs = {
  input: FinalizeCheckoutSessionInput;
};


export type MutationFinalizeTeamSubscriptionArgs = {
  input: FinalizeTeamSubscriptionInput;
};


export type MutationGenerateDownloadablePublishingWorkflowAuditLogArgs = {
  id: Scalars['ID']['input'];
};


export type MutationGenerateDownloadableWorkflowAuditLogArgs = {
  id: Scalars['ID']['input'];
};


export type MutationInitiateUserBulkOperationArgs = {
  input: UserBulkOperationInput;
};


export type MutationInviteUserToOrganizationArgs = {
  input: UserInvitationInput;
};


export type MutationInviteUserToTeamArgs = {
  input: UserTeamInvitationInput;
};


export type MutationLegacyCreateCatalogItemArgs = {
  input: LegacyCreateCatalogItemInput;
};


export type MutationLegacyUpdateCatalogItemVersionArgs = {
  input: LegacyUpdateCatalogItemVersionInput;
};


export type MutationMarkAttendanceArgs = {
  input: MarkAttendanceInput;
};


export type MutationMoveUserBetweenOrganizationsArgs = {
  input: MoveUserBetweenOrganizationsInput;
};


export type MutationMoveUserBetweenSystemUserGroupsArgs = {
  input: MoveUserBetweenSystemUserGroupsInput;
};


export type MutationOrderItemBillingActionArgs = {
  input: BillingActionInput;
};


export type MutationPauseAssessmentArgs = {
  input: ChangeAssessmentStatusInput;
};


export type MutationRegisterLearningActivityArgs = {
  learningActivityId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationRegisterLearningActivityGroupArgs = {
  learningActivityGroupId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationRegisterLearningActivityToUserGroupsArgs = {
  learningActivityId: Scalars['ID']['input'];
  userGroupIds: Array<Scalars['ID']['input']>;
};


export type MutationRegisterVersionedCatalogItemArgs = {
  catalogItemVersionedId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationRemoveEntityUserGroupArgs = {
  groupId: Scalars['ID']['input'];
};


export type MutationRemoveFileEntityAssociationArgs = {
  input: FileEntityRelatedItemInput;
};


export type MutationRemovePublishingWorkflowTagArgs = {
  input?: InputMaybe<RemovePublishingWorkflowTagInput>;
};


export type MutationRemovePublishingWorkflowWatcherArgs = {
  input?: InputMaybe<RemovePublishingWorkflowWatcherInput>;
};


export type MutationRemoveSystemUserGroupArgs = {
  groupId: Scalars['ID']['input'];
};


export type MutationRemoveTeamArgs = {
  teamId: Scalars['ID']['input'];
};


export type MutationRemoveUserAssociationRolesArgs = {
  input: RemoveUserAssociationRolesInput;
};


export type MutationRemoveUserFromEntityUserGroupArgs = {
  groupId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationRemoveUserFromGroupArgs = {
  groupId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationRemoveUserFromOrganizationArgs = {
  organizationId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationRemoveUserFromSystemUserGroupArgs = {
  groupId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationRemoveUserFromTeamArgs = {
  teamId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationReplaceOrderItemArgs = {
  input: ReplaceOrderItemInput;
};


export type MutationRestoreFileEntityVersionArgs = {
  input: RestoreFileEntityVersionInput;
};


export type MutationRestoreFileMetadataVersionArgs = {
  input: RestoreFileMetadataVersionInput;
};


export type MutationRestoreLearningContainerVersionArgs = {
  input: RestoreLearningContainerVersionInput;
};


export type MutationSaveEvaluationResponseArgs = {
  evaluationResponseId: Scalars['ID']['input'];
  transcriptId: Scalars['ID']['input'];
};


export type MutationSaveIltEvaluationResponseArgs = {
  evaluationResponseId: Scalars['ID']['input'];
  transcriptId: Scalars['ID']['input'];
};


export type MutationSelectLearningActivityFromGroupArgs = {
  learningActivityId: Scalars['ID']['input'];
  userIds: Array<Scalars['ID']['input']>;
};


export type MutationSendNotificationWithQueryArgs = {
  input: SendNotificationWithQuery;
};


export type MutationSetMaintenanceForCatalogItemVersionArgs = {
  input: SetMaintenanceForCatalogItemVersionInput;
};


export type MutationSetUserStatusArgs = {
  input: UserStatusInput;
};


export type MutationStartAssessmentArgs = {
  input: ChangeAssessmentStatusInput;
};


export type MutationStartAssessmentQuestionsGenerationWorkflowArgs = {
  input?: InputMaybe<AssessmentQuestionsStartWorkflowInput>;
};


export type MutationStartPublishingWorkflowStepArgs = {
  input?: InputMaybe<StartPublishingWorkflowStepInput>;
};


export type MutationStartWorkflowStepArgs = {
  input: StartWorkflowStepInput;
};


export type MutationSubmitAssessmentArgs = {
  input: ChangeAssessmentStatusInput;
};


export type MutationSubmitExitArgs = {
  input: ExitSurveyInput;
};


export type MutationSubmitFulfillmentItemDetailsArgs = {
  input: SubmitFulfillmentItemDetailsInput;
};


export type MutationSubmitQuestionFeedbackArgs = {
  input: SubmitQuestionFeedbackInput;
};


export type MutationSyncOrderItemWithSfdcArgs = {
  orderItemId: Scalars['ID']['input'];
};


export type MutationTerminateLicenseArgs = {
  input: TerminateLicenseInput;
};


export type MutationTransferOrganizationAssociationsArgs = {
  input: TransferAssociationsInput;
};


export type MutationTransferUserDataArgs = {
  input: TransferUserDataInput;
};


export type MutationUnarchiveCatalogItemArgs = {
  input: UnarchiveCatalogItemInput;
};


export type MutationUnarchiveFileEntityArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUnarchiveFileMetadataArgs = {
  input: UnarchiveFileMetadataInput;
};


export type MutationUnarchiveLearningContainerArgs = {
  input: UnarchiveLearningContainerInput;
};


export type MutationUnassignClassroomTrainingByAssignmentIdsArgs = {
  classroomTrainingAssignmentId: Array<Scalars['ID']['input']>;
};


export type MutationUnassignLicenseFromUserArgs = {
  input: UnassignLicenseFromUserInput;
};


export type MutationUnassignTrainingArgs = {
  assignmentId: Scalars['ID']['input'];
};


export type MutationUnwaitlistLearningActivityArgs = {
  learningActivityId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationUpdateActivityStatusArgs = {
  input: UpdateActivityStatus;
};


export type MutationUpdateAssessmentArgs = {
  input: AssessmentUpdateInput;
};


export type MutationUpdateAssessmentAnswerArgs = {
  input: AssessmentAnswerUpdateInput;
};


export type MutationUpdateAssessmentLearningObjectiveArgs = {
  input: AssessmentMetadataUpdateInput;
};


export type MutationUpdateAssessmentQuestionArgs = {
  input: AssessmentQuestionUpdateInput;
};


export type MutationUpdateAssessmentQuestionBankArgs = {
  input: AssessmentMetadataUpdateInput;
};


export type MutationUpdateAssessmentTagArgs = {
  input: AssessmentMetadataUpdateInput;
};


export type MutationUpdateAssessmentUserArgs = {
  input: UpdateAssessmentUserInput;
};


export type MutationUpdateAttendanceEntitiesArgs = {
  input: CreateOrUpdateAttendanceEntitiesInput;
};


export type MutationUpdateCatalogCustomAttributeSchemaArgs = {
  input: UpdateCatalogCustomAttributeSchemaInput;
};


export type MutationUpdateCatalogGroupArgs = {
  input: UpdateCatalogGroupInput;
};


export type MutationUpdateCatalogItemVersionArgs = {
  input: UpdateCatalogItemVersionInput;
};


export type MutationUpdateCatalogItemVersionNumberArgs = {
  input: UpdateCatalogItemVersionNumberInput;
};


export type MutationUpdateCatalogNamespaceArgs = {
  input: UpdateCatalogNamespaceInput;
};


export type MutationUpdateCatalogRootItemArgs = {
  input: UpdateCatalogRootItemInput;
};


export type MutationUpdateClassroomArgs = {
  classroomArn: Scalars['String']['input'];
  input: UpdateClassroomInput;
  version: Scalars['Int']['input'];
};


export type MutationUpdateEntityUserGroupArgs = {
  input: UpdateEntityUserGroupInput;
};


export type MutationUpdateFileEntityArgs = {
  input: UpdateFileEntityInput;
};


export type MutationUpdateFileMetadataArgs = {
  input: UpdateFileMetadataInput;
};


export type MutationUpdateGroupArgs = {
  input: UpdateGroupInput;
};


export type MutationUpdateLearningAccountArgs = {
  input?: InputMaybe<UpdateLearningAccountInput>;
};


export type MutationUpdateLearningContainerArgs = {
  input: UpdateLearningContainerInput;
};


export type MutationUpdateLicensePolicyArgs = {
  input: UpdateLicensePolicyInput;
};


export type MutationUpdateLicenseToOrderItemAssociationArgs = {
  input: UpdateLicenseToOrderItemAssociationInput;
};


export type MutationUpdateLxpAttributeTemplateArgs = {
  input: UpdateLxpAttributeTemplateInput;
};


export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput;
};


export type MutationUpdatePublishingWorkflowArgs = {
  input?: InputMaybe<UpdatePublishingWorkflowInput>;
};


export type MutationUpdatePublishingWorkflowStepValuesArgs = {
  input?: InputMaybe<UpdatePublishingWorkflowStepValuesInput>;
};


export type MutationUpdateRecordedSessionUrlArgs = {
  input: UpdateRecordedSessionUrl;
};


export type MutationUpdateRegistrationPolicyAttributesArgs = {
  input: RegistrationPolicyAttributesInput;
};


export type MutationUpdateSystemUserGroupArgs = {
  input: UpdateSystemUserGroupInput;
};


export type MutationUpdateTeamArgs = {
  input: UpdateTeamInput;
};


export type MutationUpdateTranscriptArgs = {
  input: UpdateTranscriptInput;
};


export type MutationUpdateTransferStepArgs = {
  input: UpdateTransferStepInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateUserOrganizationAssociationStatusArgs = {
  input: UpdateUserOrganizationAssociationStatusInput;
};


export type MutationUpdateUserProfileArgs = {
  input: UpdateUserProfileInput;
};


export type MutationWaitlistLearningActivityArgs = {
  learningActivityId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationWithdrawLearningActivityArgs = {
  learningActivityId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationWithdrawLearningActivityGroupArgs = {
  learningActivityGroupId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type NmbsBillingDetails = {
  __typename?: 'NMBSBillingDetails';
  agreementId?: Maybe<Scalars['String']['output']>;
  billId?: Maybe<Scalars['String']['output']>;
  billInitiatedAt?: Maybe<Scalars['String']['output']>;
  billingConsoleUILink?: Maybe<Scalars['String']['output']>;
  billingErrorMessage?: Maybe<Scalars['String']['output']>;
  billingStatus: B2BBillingStatus;
  billingStatusUpdatedAt: Scalars['String']['output'];
  invoiceId?: Maybe<Scalars['String']['output']>;
};

export type NmbsBillingDetailsInput = {
  billingStatus: B2BBillingStatus;
};

export type Name = {
  __typename?: 'Name';
  fullName: Scalars['String']['output'];
  nameFields?: Maybe<NameFields>;
};

export type NameFields = {
  __typename?: 'NameFields';
  familyName?: Maybe<Scalars['String']['output']>;
  giveName: Scalars['String']['output'];
  middleNameOrInitials?: Maybe<Scalars['String']['output']>;
  suffix?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type NameFieldsInput = {
  familyName?: InputMaybe<Scalars['String']['input']>;
  giveName: Scalars['String']['input'];
  middleNameOrInitials?: InputMaybe<Scalars['String']['input']>;
  suffix?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type NameInput = {
  fullName: Scalars['String']['input'];
  nameFields?: InputMaybe<NameFieldsInput>;
};

export type Notification = {
  __typename?: 'Notification';
  cultureCode?: Maybe<Scalars['String']['output']>;
  deliveryType?: Maybe<Scalars['String']['output']>;
  notificationId: Scalars['String']['output'];
  notificationType?: Maybe<Scalars['String']['output']>;
  receivedAt: Scalars['AWSDateTime']['output'];
  templateValues?: Maybe<Scalars['AWSJSON']['output']>;
};

export type NotificationConfiguration = {
  link: Scalars['AWSURL']['input'];
  /** Must be in the form email@email.com */
  recipientEmails: Array<Scalars['String']['input']>;
};

export type NotificationOverride = {
  __typename?: 'NotificationOverride';
  benefitIds?: Maybe<Array<Scalars['ID']['output']>>;
  notificationConfigurations?: Maybe<Array<OfferNotificationConfiguration>>;
};

/** The Notifications we want to have configured within an Offer */
export enum NotificationType {
  EntitlementCancelation = 'ENTITLEMENT_CANCELATION',
  FreetrialCancelation = 'FREETRIAL_CANCELATION',
  FreetrialEnd = 'FREETRIAL_END',
  FreetrialStart = 'FREETRIAL_START',
  FreetrialThreeDayReminder = 'FREETRIAL_THREE_DAY_REMINDER',
  MonthlyLeaseRenewalReminder = 'MONTHLY_LEASE_RENEWAL_REMINDER',
  MonthlyRenewalReminder = 'MONTHLY_RENEWAL_REMINDER',
  PaidStart = 'PAID_START',
  PaymentFailureCancelation = 'PAYMENT_FAILURE_CANCELATION',
  PaymentFailureWarning = 'PAYMENT_FAILURE_WARNING',
  RenewsToday = 'RENEWS_TODAY',
  SubscriptionCancelation = 'SUBSCRIPTION_CANCELATION',
  YearlyRenewalReminderFiveDays = 'YEARLY_RENEWAL_REMINDER_FIVE_DAYS',
  YearlyRenewalReminderFortyfiveDays = 'YEARLY_RENEWAL_REMINDER_FORTYFIVE_DAYS'
}

export type Offer = {
  __typename?: 'Offer';
  /** Identifying which benefits can be applied to a given offer */
  applicableBenefits?: Maybe<Array<Maybe<OfferBenefit>>>;
  /**
   * Retrieves offer benefits applicable to a user with selected offer
   * Default eligible to free trial if not specify isFreeTrialEligible field
   */
  applicableBenefitsForUser?: Maybe<Array<OfferBenefit>>;
  /** Offer Object metadata */
  auditMetadata: AuditMetadata;
  /** When should an offer cancel when a custoemr attempts to cancel */
  cancellationConfiguration?: Maybe<CancellationConfiguration>;
  /** Description of the offer */
  description: Scalars['String']['output'];
  /** Discounted price for Offer with benefits */
  discountedPrice?: Maybe<Scalars['Float']['output']>;
  /** When the offer expires */
  expiresAt?: Maybe<Scalars['AWSDateTime']['output']>;
  /** Unique ID of an Offer */
  id: Scalars['ID']['output'];
  /** License configuration stores information on details related to the entitlements a user may recieve on purchase of the Offer. This could be VIAS and docebo values */
  licenseConfiguration?: Maybe<LicenseConfiguration>;
  localizationByCode?: Maybe<Localization>;
  /** Avalible Localizations for an Offer */
  localizations?: Maybe<Array<Localization>>;
  /** Which service this offer is being provided to */
  modality: OfferModality;
  /** Given Name of an Offer */
  name: Scalars['String']['output'];
  /** Notification Configuration holds the data on specific notifications ot send for a given offer. As such this will detail which notifications need to be sent and when */
  notificationConfigurations?: Maybe<Array<OfferNotificationConfiguration>>;
  /** Notification configurations to override when applying benefits */
  notificationOverrides?: Maybe<Array<NotificationOverride>>;
  /** Base price for Offer without benefits */
  offerPrice: Scalars['Float']['output'];
  /** Payment configuration which stores information on the basic details that downstream services need within an offer to complete a purchase. */
  paymentConfiguration?: Maybe<PaymentConfiguration>;
  /** How the offer is being purchased and through what steps */
  purchasingPlan?: Maybe<PurchasingPlan>;
  /** Current Status of an offer */
  status: OfferStatus;
  /** What type of offer is provided */
  type: OfferType;
};


export type OfferApplicableBenefitsForUserArgs = {
  isFreeTrialEligible?: InputMaybe<Scalars['Boolean']['input']>;
  userId: Scalars['ID']['input'];
};


export type OfferLocalizationByCodeArgs = {
  locale: Scalars['String']['input'];
};

export type OfferBenefit = {
  __typename?: 'OfferBenefit';
  benefitRules?: Maybe<BenefitRules>;
  description: Scalars['String']['output'];
  discountAmount?: Maybe<Scalars['Float']['output']>;
  discountPercentage?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  localizationByCode?: Maybe<Localization>;
  localizations?: Maybe<Array<Localization>>;
  name: Scalars['String']['output'];
  platformConfiguration?: Maybe<PlatformConfiguration>;
  scheduledPaymentOffsetHours?: Maybe<Scalars['Int']['output']>;
  type: BenefitType;
};


export type OfferBenefitLocalizationByCodeArgs = {
  locale: Scalars['String']['input'];
};

export enum OfferBillingPeriod {
  Annual = 'ANNUAL',
  Monthly = 'MONTHLY',
  MonthlyLease = 'MONTHLY_LEASE',
  OneTime = 'ONE_TIME'
}

export enum OfferModality {
  AwsJam = 'AWS_JAM',
  Digital = 'DIGITAL',
  Padawan = 'PADAWAN',
  Sbts = 'SBTS'
}

export type OfferNotificationConfiguration = {
  __typename?: 'OfferNotificationConfiguration';
  eventName?: Maybe<Scalars['String']['output']>;
  notificationType: NotificationType;
  offsetInHours?: Maybe<Scalars['Int']['output']>;
  templateId: Scalars['ID']['output'];
};

export type OfferRetryConfiguration = {
  __typename?: 'OfferRetryConfiguration';
  attemptAmount: Scalars['Int']['output'];
  attemptCadenceHours: Scalars['Int']['output'];
};

export enum OfferStatus {
  Active = 'ACTIVE',
  Expired = 'EXPIRED'
}

export enum OfferType {
  Alacarte = 'ALACARTE',
  Subscription = 'SUBSCRIPTION',
  Tuition = 'TUITION'
}

export type Order = {
  __typename?: 'Order';
  auditMetadata: AuditMetadata;
  billingAwsAccountId?: Maybe<Scalars['String']['output']>;
  businessDevelopmentManager?: Maybe<ProductOnboardingUser>;
  fulfillment?: Maybe<Fulfillment>;
  id: Scalars['ID']['output'];
  items: Array<OrderItem>;
  /** Paginated orderItem query for ordersByOpportunityId */
  itemsPaginated?: Maybe<OrderItemConnection>;
  learningAccount?: Maybe<LearningAccount>;
  noOfExistingLicenses?: Maybe<Scalars['Int']['output']>;
  opportunityId?: Maybe<Scalars['String']['output']>;
  opportunityName?: Maybe<Scalars['String']['output']>;
  orderCanceledTicketId?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Organization>;
  parentOpportunityId?: Maybe<Scalars['String']['output']>;
  paymentMethod?: Maybe<PaymentMethod>;
  status: OrderStatus;
  type?: Maybe<OrderType>;
  typeOfContract?: Maybe<TypeOfContract>;
  user?: Maybe<User>;
};


export type OrderItemsPaginatedArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type OrderConnection = {
  __typename?: 'OrderConnection';
  nodes: Array<Order>;
  pageInfo?: Maybe<PageInfo>;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  /** List for benefits actually applied to the order item with selected offer */
  appliedBenefits?: Maybe<Array<OfferBenefit>>;
  billingAwsAccountId?: Maybe<Scalars['String']['output']>;
  billingPeriod?: Maybe<BillingPeriod>;
  cancellationDetails?: Maybe<CancellationDetails>;
  classEndDate?: Maybe<Scalars['AWSDateTime']['output']>;
  classStartDate?: Maybe<Scalars['AWSDateTime']['output']>;
  contractingRequestCreator?: Maybe<ContractingRequestCreator>;
  effectiveAt?: Maybe<Scalars['AWSDateTime']['output']>;
  freeTrialDetails?: Maybe<FreeTrialDetails>;
  id: Scalars['ID']['output'];
  learningAccount?: Maybe<LearningAccount>;
  learningActivity?: Maybe<LearningActivity>;
  licenses?: Maybe<Array<License>>;
  metadata?: Maybe<Array<KeyValuePair>>;
  nmbsBillingDetails?: Maybe<NmbsBillingDetails>;
  offer?: Maybe<Offer>;
  opportunityLineItemId?: Maybe<Scalars['String']['output']>;
  orderId: Scalars['ID']['output'];
  orderType?: Maybe<OrderType>;
  organization?: Maybe<Organization>;
  paginatedLicenses: LicenseConnection;
  /** List of payments for Order Item */
  payments?: Maybe<PaymentConnection>;
  productModality?: Maybe<ProductModality>;
  productOnboardingDetails?: Maybe<ProductOnboardingDetails>;
  productTitle?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Int']['output'];
  ssoEnabled?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<OrderItemStatus>;
  statusReason?: Maybe<Scalars['String']['output']>;
  statusUpdatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  subTotal?: Maybe<Scalars['Float']['output']>;
  subscriptionType?: Maybe<TypeOfContract>;
  unitPrice?: Maybe<Scalars['Float']['output']>;
};


export type OrderItemPaginatedLicensesArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  onlyActiveAssociations?: InputMaybe<Scalars['Boolean']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type OrderItemPaymentsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export enum OrderItemAssociationStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type OrderItemConnection = {
  __typename?: 'OrderItemConnection';
  nodes: Array<OrderItem>;
  pageInfo?: Maybe<PageInfo>;
};

export enum OrderItemIltClassStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  NotApplicable = 'NOT_APPLICABLE',
  Pending = 'PENDING',
  Scheduled = 'SCHEDULED'
}

export enum OrderItemStatus {
  Canceled = 'CANCELED',
  Created = 'CREATED',
  FulfillmentCompleted = 'FULFILLMENT_COMPLETED',
  FulfillmentStarted = 'FULFILLMENT_STARTED'
}

export enum OrderStatus {
  Canceled = 'CANCELED',
  Created = 'CREATED',
  FulfillmentStarted = 'FULFILLMENT_STARTED'
}

/** Configures how the Post Checkout Order Summary is displayed */
export type OrderSummaryComponent = {
  __typename?: 'OrderSummaryComponent';
  items: Array<OrderSummaryItem>;
  subtext: TextSection;
};

export type OrderSummaryComponentInput = {
  items: Array<OrderSummaryItemInput>;
  subtext: TextSectionInput;
};

/** An item to display within the Order Summary component */
export type OrderSummaryItem = {
  __typename?: 'OrderSummaryItem';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type OrderSummaryItemInput = {
  label: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export enum OrderType {
  B2B = 'B2B',
  B2I = 'B2I'
}

export type OrdersFilterInput = {
  endDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  iltClassStatus?: InputMaybe<OrderItemIltClassStatus>;
  orgList?: InputMaybe<Array<Scalars['ID']['input']>>;
  search: Scalars['String']['input'];
  startDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type Organization = {
  __typename?: 'Organization';
  auditMetadata: AuditMetadata;
  awsAccountId: Scalars['String']['output'];
  awsAssociation?: Maybe<AwsAssociation>;
  billingAddress?: Maybe<Address>;
  billingAwsAccountIds?: Maybe<Array<Scalars['String']['output']>>;
  /**
   * Whether this organization requires consent in order to join
   *
   * This value is calculated by checking if the org is a CUSTOMER_B2I or PARTNER_B2I org type
   */
  consentRequirement: ConsentRequirement;
  customerSince?: Maybe<Scalars['AWSDateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /**
   * List of groups belonging to an Organization with option to include groups from Teams under that Organization.
   *
   * Maximum page size is 100. Higher page sizes will be capped at 100.
   */
  entityUserGroupAssociations?: Maybe<EntityUserGroupAssociationConnection>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** List of orderItems for this Organization. */
  orderItems: OrderItemConnection;
  /** List of orders for this Organization. */
  orders: OrderConnection;
  organizationSettings?: Maybe<OrganizationSettings>;
  organizationType: OrganizationType;
  organizationVisibilityClassification?: Maybe<OrganizationVisibilityClassification>;
  phoneNumber?: Maybe<Scalars['AWSPhone']['output']>;
  sfdcAccountId?: Maybe<Scalars['String']['output']>;
  sfdcParentAccountId?: Maybe<Scalars['String']['output']>;
  status: OrganizationStatus;
  /**
   * List of teams belonging to an Organization.
   *
   * Maximum page size is 100. Higher page sizes will be capped at 100.
   */
  teamAssociations?: Maybe<TeamAssociationConnection>;
  /**
   * List of users belonging to an Organization.
   *
   * Maximum page size is 100. Higher page sizes will be capped at 100.
   */
  userAssociations?: Maybe<UserAssociationConnection>;
  userCount?: Maybe<UserCount>;
  vendorResourceCode?: Maybe<Scalars['String']['output']>;
  vendorResourceId?: Maybe<Scalars['Int']['output']>;
};


export type OrganizationEntityUserGroupAssociationsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  includeGroupsFromTeams?: InputMaybe<Scalars['Boolean']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type OrganizationOrderItemsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  input?: InputMaybe<OrdersFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type OrganizationOrdersArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  input?: InputMaybe<OrdersFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type OrganizationTeamAssociationsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type OrganizationUserAssociationsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type OrganizationConnection = {
  __typename?: 'OrganizationConnection';
  nodes: Array<Organization>;
  pageInfo?: Maybe<PageInfo>;
};

export type OrganizationFilterInput = {
  organizationType?: InputMaybe<OrganizationType>;
  visibility?: InputMaybe<OrganizationVisibilityClassification>;
};

export enum OrganizationLoginMethod {
  AwsBuilderId = 'AWSBuilderID',
  AwsPartnerNetwork = 'AWSPartnerNetwork',
  AmazonFederate = 'AmazonFederate',
  Esso = 'ESSO',
  Idc = 'IDC'
}

export type OrganizationSettings = Settings & {
  __typename?: 'OrganizationSettings';
  autoAssignDigitalSubscription?: Maybe<Scalars['Boolean']['output']>;
  idpValue?: Maybe<Scalars['String']['output']>;
  loginMethod?: Maybe<OrganizationLoginMethod>;
  trainingDataVisibilityStatus?: Maybe<PrivacyStatus>;
};

export type OrganizationSettingsInput = {
  autoAssignDigitalSubscription?: InputMaybe<Scalars['Boolean']['input']>;
  idpValue?: InputMaybe<Scalars['String']['input']>;
  loginMethod?: InputMaybe<OrganizationLoginMethod>;
  trainingDataVisibilityStatus?: InputMaybe<PrivacyStatus>;
};

export enum OrganizationStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum OrganizationType {
  Amazon = 'AMAZON',
  CustomersB2B = 'CUSTOMERS_B2B',
  CustomersB2I = 'CUSTOMERS_B2I',
  PartnersB2B = 'PARTNERS_B2B',
  PartnersB2I = 'PARTNERS_B2I'
}

export enum OrganizationVisibilityClassification {
  General = 'GENERAL',
  Restricted = 'RESTRICTED'
}

export type OutOfCycleBill = {
  __typename?: 'OutOfCycleBill';
  agreementId: Scalars['String']['output'];
  awsCustomerId?: Maybe<Scalars['String']['output']>;
  billAccountId: Scalars['String']['output'];
  billDate: Scalars['AWSDateTime']['output'];
  billInitiatedAt: Scalars['AWSDateTime']['output'];
  commercePlatformBillId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['AWSDateTime']['output'];
  customTaxAddress?: Maybe<CustomTaxAddress>;
  customTaxAddressId?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  errorMessage?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lineItems: Array<Maybe<LineItem>>;
  lineOfBusiness: Scalars['String']['output'];
  marketplaceId: Scalars['String']['output'];
  payerAccountId: Scalars['String']['output'];
  purchaseOrder?: Maybe<PurchaseOrder>;
  serviceCode: Scalars['String']['output'];
  sor: Scalars['String']['output'];
  status: Scalars['String']['output'];
  submittedBy?: Maybe<EmailProfile>;
};

export type Ptrr = {
  __typename?: 'PTRR';
  accountName: Scalars['String']['output'];
  address1: Scalars['String']['output'];
  address2: Scalars['String']['output'];
  awsBillingAccount: Scalars['String']['output'];
  awsEndUserAccount: Scalars['String']['output'];
  billingNotes?: Maybe<Scalars['String']['output']>;
  billingTerms?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  createdDate: Scalars['AWSDateTime']['output'];
  customerTaxVATId?: Maybe<Scalars['String']['output']>;
  geo: Scalars['String']['output'];
  legalEntityNameTraining: Scalars['String']['output'];
  opportunityName?: Maybe<Scalars['String']['output']>;
  ownerId: Scalars['String']['output'];
  paymentMethod?: Maybe<Scalars['String']['output']>;
  poNumberOnInvoice?: Maybe<Scalars['String']['output']>;
  ptfTemplateName?: Maybe<Scalars['String']['output']>;
  ptrrId: Scalars['String']['output'];
  ptrrStatus: PtrrStatus;
  ptrrStatusType: PtrrStatusType;
  recordTypeId: Scalars['String']['output'];
  region: Scalars['String']['output'];
  state: Scalars['String']['output'];
  topsEmail?: Maybe<Scalars['String']['output']>;
  topsName?: Maybe<Scalars['String']['output']>;
  trainingBillingContactEmail: Scalars['String']['output'];
  trainingBillingContactName: Scalars['String']['output'];
  zipCode: Scalars['String']['output'];
};

export enum PtrrStatus {
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  ContractingInProgress = 'CONTRACTING_IN_PROGRESS',
  CustomerAccepted = 'CUSTOMER_ACCEPTED',
  New = 'NEW',
  OnboardingComplete = 'ONBOARDING_COMPLETE',
  PendingCustomer = 'PENDING_CUSTOMER',
  PendingExceptionApproval = 'PENDING_EXCEPTION_APPROVAL',
  PendingInternal = 'PENDING_INTERNAL',
  Recalled = 'RECALLED',
  Rejected = 'REJECTED',
  SchedulingCancelled = 'SCHEDULING_CANCELLED',
  SchedulingComplete = 'SCHEDULING_COMPLETE',
  SchedulingInProgress = 'SCHEDULING_IN_PROGRESS',
  SubmittedForScheduling = 'SUBMITTED_FOR_SCHEDULING',
  TopsInProgress = 'TOPS_IN_PROGRESS',
  Unknown = 'UNKNOWN'
}

export enum PtrrStatusType {
  Contracting = 'CONTRACTING',
  Default = 'DEFAULT',
  Scheduling = 'SCHEDULING'
}

/** Pagination information for connections */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** Token for getting next page of data */
  cursor?: Maybe<Scalars['String']['output']>;
  /** Number of results returned in request. */
  size?: Maybe<Scalars['Int']['output']>;
};

export type PayerAccountOfferPriceInput = {
  accountId: Scalars['String']['input'];
  partnerRequest?: InputMaybe<Scalars['Boolean']['input']>;
  spmsId?: InputMaybe<Scalars['Int']['input']>;
  userEmail: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type PayerAccountOfferPriceOutput = {
  __typename?: 'PayerAccountOfferPriceOutput';
  discountList?: Maybe<Array<Discount>>;
  payerAccountId: Scalars['String']['output'];
  pricePerSeat: Scalars['Float']['output'];
  pricePerSeatMap?: Maybe<Array<Maybe<TerritoryPrice>>>;
  resellAuthorizedTerritories?: Maybe<Array<Scalars['String']['output']>>;
};

/** Represents a single payment */
export type Payment = {
  __typename?: 'Payment';
  /** Indicating whether the payment has benefits applied */
  appliedBenefits?: Maybe<Array<Maybe<OfferBenefit>>>;
  auditMetadata: AuditMetadata;
  /** Time when the payment is canceled */
  canceledAt?: Maybe<Scalars['AWSDateTime']['output']>;
  /** Time payment cancellation actually began to be processed by processor */
  cancellationInitiatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  /** Indicating the payment cancellation reason */
  cancellationReason?: Maybe<PaymentCancellationReason>;
  /** Time the payment cancellation is scheduled to go through */
  cancellationScheduledAt?: Maybe<Scalars['AWSDateTime']['output']>;
  /** unique identifier of payment in Payment Service */
  id: Scalars['ID']['output'];
  /** Time installment payment actually began to be processed by processor */
  initiatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  /** Indicating whether the payment amount is prorated for monthly subscription */
  isProrated?: Maybe<Scalars['Boolean']['output']>;
  /** Indicating whether the payment is a renew payment */
  isRenewal?: Maybe<Scalars['Boolean']['output']>;
  /** Offer associated with payment. */
  offer: Offer;
  /** Order associated with payment. */
  order: Order;
  /** Order item associated with payment. */
  orderItem: OrderItem;
  /** Payment method associated with this payment */
  paymentMethod: PaymentMethod;
  /** Time the installment payment is scheduled to go through */
  scheduledAt: Scalars['AWSDateTime']['output'];
  /** Time payment is actually settled by (paid vs failed) */
  settledAt?: Maybe<Scalars['AWSDateTime']['output']>;
  /** Status of payment */
  status: PaymentStatus;
  /** Type of payment. Currently only installment payment type for Padawan */
  type: PaymentType;
  /** User making this payment */
  user: User;
};

export enum PaymentCancellationReason {
  /** Payment canceled for fraudulent payment method */
  FraudPaymentMethod = 'FRAUD_PAYMENT_METHOD',
  /** Payment canceled when user or on-call cancels the order */
  OrderCanceled = 'ORDER_CANCELED',
  /** Payment canceled after multiple retry failures */
  PaymentFailure = 'PAYMENT_FAILURE',
  /** Payment canceled from unexpected SDMS subscription canceled status when Payment supposed to be active */
  SdmsCanceled = 'SDMS_CANCELED'
}

export type PaymentConfiguration = {
  __typename?: 'PaymentConfiguration';
  offerBillingPeriod: OfferBillingPeriod;
  paymentContexts: Array<PaymentContext>;
  paymentScheduleConfiguration?: Maybe<ScheduleConfiguration>;
  platformConfiguration: PlatformConfiguration;
  retryConfiguration: OfferRetryConfiguration;
};

export type PaymentConnection = {
  __typename?: 'PaymentConnection';
  nodes: Array<Payment>;
  pageInfo?: Maybe<PageInfo>;
};

/** In the future will maintain context like locale and currency code which will have deeper implementation in the catalog project */
export type PaymentContext = {
  __typename?: 'PaymentContext';
  price: Scalars['Float']['output'];
};

/** A wrapper payment method in Payment Service */
export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  auditMetadata: AuditMetadata;
  /** AWS account ID if payment method type is AWS account */
  awsAccountId?: Maybe<Scalars['String']['output']>;
  /** Unique identifier of payment method in Payment Service */
  id: Scalars['ID']['output'];
  /** Is PaymentMethod Potentially Fraudulent */
  isFraudPaymentMethod?: Maybe<Scalars['Boolean']['output']>;
  /** Type of payment method. Currently, AWS account is the only supported type */
  type: PaymentMethodType;
  /** User payment method belongs to */
  user: User;
};

/** Type of payment methods for checkout session */
export enum PaymentMethodType {
  /** AWS account payment method */
  AwsAccount = 'AWS_ACCOUNT'
}

export enum PaymentStatus {
  /** Payment is being authorized (used for monthly subscription) */
  Authorized = 'AUTHORIZED',
  /** Payment canceled before initiated */
  Canceled = 'CANCELED',
  /** Payment not paid successfully by settled timeout window */
  Failed = 'FAILED',
  /** Payment is being processed */
  Initiated = 'INITIATED',
  /** Payment successfully paid */
  Paid = 'PAID',
  /** Payment scheduled for some time in future */
  Scheduled = 'SCHEDULED'
}

export enum PaymentType {
  /** Annual payment for SkillBuilder */
  AnnualSubscription = 'ANNUAL_SUBSCRIPTION',
  /** Installment payment for Padawan */
  Installment = 'INSTALLMENT',
  /** Monthly lease payment for SkillBuilder */
  MonthlyLeaseSubscription = 'MONTHLY_LEASE_SUBSCRIPTION',
  /** Monthly payment for SkillBuilder */
  MonthlySubscription = 'MONTHLY_SUBSCRIPTION',
  /** One time payment for ALACARTE like AWS Jam */
  OneTime = 'ONE_TIME'
}

export type PaymentsFilterInput = {
  orderId?: InputMaybe<Scalars['String']['input']>;
  scheduledAtEndDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  scheduledAtStartDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  statuses?: InputMaybe<Array<PaymentStatus>>;
};

export type Phone = {
  __typename?: 'Phone';
  label?: Maybe<Scalars['String']['output']>;
  phoneNumber: Scalars['String']['output'];
};

export type PhoneInput = {
  label?: InputMaybe<Scalars['String']['input']>;
  phoneNumber: Scalars['String']['input'];
};

export type PhysicalAddress = {
  __typename?: 'PhysicalAddress';
  addressLine1: Scalars['String']['output'];
  addressLine2?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

export type PhysicalAddressInput = {
  addressLine1: Scalars['String']['input'];
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

/** Multiple platforms require discrete information so creating this for future platforms */
export type PlatformConfiguration = CommercePlatformConfiguration;

export type PlaygroundEventInput = {
  id: Scalars['String']['input'];
  version: Scalars['Int']['input'];
};

export type PointOfContact = {
  __typename?: 'PointOfContact';
  email: Scalars['AWSEmail']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phone?: Maybe<Scalars['AWSPhone']['output']>;
};

/** Configures how the Post-Checkout Page is displayed */
export type PostCheckoutPage = {
  __typename?: 'PostCheckoutPage';
  /** Confirmation component config of post-checkout page */
  confirmationComponent: ConfirmationComponent;
  /** Order summary component config of post-checkout page */
  orderSummaryComponent: OrderSummaryComponent;
  /** Title text of pre-checkout page */
  title: Scalars['String']['output'];
};

export type PostCheckoutPageInput = {
  confirmationComponent: ConfirmationComponentInput;
  orderSummaryComponent: OrderSummaryComponentInput;
  title: Scalars['String']['input'];
};

/** Configures how the Pre-Checkout Page is displayed */
export type PreCheckoutPage = {
  __typename?: 'PreCheckoutPage';
  /** Cart component config of pre-checkout page */
  cartComponent: CartComponent;
  /** Finalize checkout session component config of pre-checkout page */
  finalizeCheckoutSessionComponent: FinalizeCheckoutSessionComponent;
  /** Title text of pre-checkout page */
  title: Scalars['String']['output'];
};

export type PreCheckoutPageInput = {
  cartComponent: CartComponentInput;
  finalizeCheckoutSessionComponent: FinalizeCheckoutSessionComponentInput;
  title: Scalars['String']['input'];
};

export type Preferences = {
  __typename?: 'Preferences';
  language?: Maybe<Scalars['String']['output']>;
  marketingEmailOption?: Maybe<MarketingEmailOption>;
  timezone?: Maybe<Scalars['String']['output']>;
};

export type PreferencesInput = {
  language?: InputMaybe<Scalars['String']['input']>;
  marketingEmailOption?: InputMaybe<MarketingEmailOption>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type PrincipalAuthorizedResourcesInput = {
  action: AuthorizationActionType;
  principal: AuthorizationPrincipalIdentityInput;
  resourceTypes: Array<AuthorizationResourceType>;
};

export enum PrivacyStatus {
  Optin = 'OPTIN',
  Optout = 'OPTOUT'
}

export enum ProductModality {
  AwsJam = 'AWS_JAM',
  Digital = 'DIGITAL',
  Ilt = 'ILT',
  Padawan = 'PADAWAN'
}

export enum ProductOnboardingAuthenticationMethod {
  Apn = 'APN',
  AwsBuilderId = 'AWS_BUILDER_ID',
  Lwa = 'LWA',
  Sso = 'SSO'
}

export type ProductOnboardingDetails = {
  __typename?: 'ProductOnboardingDetails';
  authenticationMethod?: Maybe<ProductOnboardingAuthenticationMethod>;
  emailDomains: Array<Scalars['String']['output']>;
  identityProvider?: Maybe<ProductOnboardingIdentityProvider>;
  learningAdmins: Array<ProductOnboardingUser>;
  organizationName: Scalars['String']['output'];
  ssoContact?: Maybe<ProductOnboardingUser>;
};

export type ProductOnboardingDetailsInput = {
  authenticationMethod?: InputMaybe<ProductOnboardingAuthenticationMethod>;
  emailDomains: Array<Scalars['String']['input']>;
  identityProvider?: InputMaybe<ProductOnboardingIdentityProvider>;
  learningAdmins: Array<ProductOnboardingUserInput>;
  organizationName: Scalars['String']['input'];
  ssoContact?: InputMaybe<ProductOnboardingUserInput>;
};

export enum ProductOnboardingIdentityProvider {
  Adfs = 'ADFS',
  Azure = 'AZURE',
  Duo = 'DUO',
  Google = 'GOOGLE',
  Jump = 'JUMP',
  Okta = 'OKTA',
  Onelogin = 'ONELOGIN',
  Other = 'OTHER',
  Ping = 'PING'
}

export type ProductOnboardingUser = {
  __typename?: 'ProductOnboardingUser';
  emailAddress: Scalars['AWSEmail']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  userId?: Maybe<Scalars['String']['output']>;
};

export type ProductOnboardingUserInput = {
  emailAddress: Scalars['AWSEmail']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type ProfessionalInformation = {
  __typename?: 'ProfessionalInformation';
  awsExperienceLevel?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  goalForUsingSB?: Maybe<Scalars['String']['output']>;
  industry?: Maybe<Scalars['String']['output']>;
  jobRole?: Maybe<Scalars['String']['output']>;
};

export type ProfessionalInformationInput = {
  awsExperienceLevel?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  goalForUsingSB?: InputMaybe<Scalars['String']['input']>;
  industry?: InputMaybe<Scalars['String']['input']>;
  jobRole?: InputMaybe<Scalars['String']['input']>;
};

export enum ProfileCompletionStatus {
  Complete = 'COMPLETE',
  Incomplete = 'INCOMPLETE'
}

export type Program = {
  __typename?: 'Program';
  programType?: Maybe<ProgramType>;
};

export enum ProgramType {
  Aci = 'ACI',
  SkillBuilder = 'SkillBuilder'
}

export enum PublishingScope {
  Major = 'MAJOR',
  Minor = 'MINOR',
  NewCourse = 'NEW_COURSE',
  Patch = 'PATCH'
}

export type PublishingWorkflow = {
  __typename?: 'PublishingWorkflow';
  assignedTo?: Maybe<Scalars['String']['output']>;
  auditLog?: Maybe<WorkflowAuditLogConnection>;
  auditMetadata: AuditMetadata;
  comments?: Maybe<EntityComments>;
  currentWorkflowStep?: Maybe<WorkflowStep>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  learningContainer: LearningContainer;
  notes?: Maybe<Scalars['String']['output']>;
  status: WorkflowStatus;
  tags?: Maybe<Array<KeyValuePair>>;
  targetLxp: Lxp;
  targetLxpEnvStages?: Maybe<Array<EnvStage>>;
  targetPublishDate?: Maybe<Scalars['AWSDateTime']['output']>;
  watchers?: Maybe<Array<Scalars['String']['output']>>;
  workflowSteps: Array<WorkflowStep>;
};


export type PublishingWorkflowAuditLogArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type PublishingWorkflowConnection = {
  __typename?: 'PublishingWorkflowConnection';
  nodes: Array<PublishingWorkflow>;
  pageInfo?: Maybe<PageInfo>;
};

export type PublishingWorkflowsByLearningContainerInput = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  learningContainerId: Scalars['ID']['input'];
  learningContainerVersion?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type PurchaseOrder = {
  __typename?: 'PurchaseOrder';
  billFromList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  orderEffectiveDate: Scalars['AWSDateTime']['output'];
  orderExpiryDate: Scalars['AWSDateTime']['output'];
  orderNumber: Scalars['String']['output'];
};

export type PurchaseOrderInput = {
  billFromList?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  orderEffectiveDate: Scalars['AWSDateTime']['input'];
  orderExpiryDate: Scalars['AWSDateTime']['input'];
  orderNumber: Scalars['String']['input'];
  paymentTerm?: InputMaybe<PurchaseOrderPaymentTerm>;
  totalAmount?: InputMaybe<Scalars['Float']['input']>;
};

export enum PurchaseOrderPaymentTerm {
  Net_0 = 'NET_0',
  Net_15 = 'NET_15',
  Net_30 = 'NET_30',
  Net_45 = 'NET_45',
  Net_60 = 'NET_60',
  Net_75 = 'NET_75',
  Net_90 = 'NET_90',
  Net_105 = 'NET_105',
  Net_120 = 'NET_120'
}

export type PurchasingPlan = InstallmentPlan;

/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type Query = {
  __typename?: 'Query';
  /** Get digital training entitlement type by userId and catalogItemId */
  accessByUserIdAndCatalogItemId: AccessTypeResult;
  /** Get a specific ACI Course Load transcript by UserId and LearningActivityGroupId */
  aciTranscriptByUserIdAndLearningActivityGroupId?: Maybe<AciCourseLoadTranscript>;
  /** Get list of ACI Course Load Transcript by UserId  (for ACI Dashboard Page) */
  aciTranscriptsByUserId?: Maybe<Array<AciCourseLoadTranscript>>;
  /** Query to fetch admin reports including embedding URL and event notifications. */
  adminReport?: Maybe<ReportsData>;
  assessment?: Maybe<Assessment>;
  assessmentLearningObjective?: Maybe<AssessmentMetadataObject>;
  assessmentLearningObjectives: AssessmentMetadataSearchOutput;
  assessmentQuestion?: Maybe<AssessmentQuestion>;
  assessmentQuestionAuditLog?: Maybe<AuditLogOutput>;
  assessmentQuestionBank?: Maybe<AssessmentMetadataObject>;
  assessmentQuestionBanks: AssessmentMetadataSearchOutput;
  assessmentQuestions?: Maybe<AssessmentQuestionsOutput>;
  assessmentTag?: Maybe<AssessmentMetadataObject>;
  assessmentTags: AssessmentMetadataSearchOutput;
  assessmentUser?: Maybe<AssessmentUser>;
  assessmentUsers: AssessmentUsersOutput;
  assessments?: Maybe<AssessmentsOutput>;
  benefitById: OfferBenefit;
  /**
   * Returns the catalog attribute schemas (defined natively here rather than in a custom namespace).
   * This is meant for systems that need to programmatically consume attribute schemas for some use case
   * (e.g., Search service consuming localizations for indexing).
   * Caller can filter schemas by language codes.
   */
  catalogAttributeSchemas: CatalogAttributeSchemaConnection;
  /** Returns a catalog group by its ID. */
  catalogGroupById: CatalogGroup;
  /**
   * Returns paginated catalog groups that caller has access to view, with optional query input for search.
   * This resolver is currently meant only for internal admins in LCMS, not external customers or backend services.
   */
  catalogGroups: CatalogGroupConnection;
  /**
   * Returns catalog groups that map to this custom identifier.
   * Note that if the custom identifier was 'unique', this should return at most 1 group.
   * If the identifier was not unique, there may be multiple groups.
   * 'namespaceId' is optional, and kept just for backward compatibility
   * for groups which were created within specific namespaces
   */
  catalogGroupsByCustomIdentifier: CatalogGroupConnection;
  /** Returns a catalog item (variant) with the passed ID. If semantic version passed, will return that version. Otherwise, will return default version. */
  catalogItemById: CatalogItem;
  /**
   * Returns a catalog item (variant) by its root item ID (instead of its variant ID) plus the variant dimensions that identify the given variant.
   * E.g., if a root item has id ABC and has 2 variants, one English and one Spanish,
   * you can retrieve the English one with the root id 'ABC' and variant dimension of language code 'en-US'.
   * If no variant dimensions passed, the default variant is returned.
   */
  catalogItemByRootId: CatalogItem;
  /** Returns a catalog item (variant) with the passed versioned ID, which is the item ID with a version suffix (<id>:<xxx.yyy.zzz>, example: ABC:001.002.003) */
  catalogItemByVersionedId: CatalogItem;
  /** Returns paginated versions of a catalog item (variant). Optional semantic version filters. */
  catalogItemVersionsById: CatalogItemConnection;
  /**
   * Returns paginated catalog items (variants) that caller has access to view, with optional query input for search.
   * This resolver is currently meant only for internal admins in LCMS, not external customers or backend services.
   */
  catalogItems: CatalogItemConnection;
  /**
   * Returns catalog items that map to this custom identifier.
   * Note that if the custom identifier was 'unique', this should return at most 1 item.
   * If the identifier was not unique, there may be multiple items.
   * 'namespaceId' is optional, and kept just for backward compatibility
   * for items which were created within specific namespaces
   */
  catalogItemsByCustomIdentifier: CatalogItemConnection;
  /**
   * Returns paginated catalog items (variants) that caller has access to view, with optional query input for search.
   * This resolver is meant only for external admins, namely on the training management page
   */
  catalogItemsExternal: CatalogItemConnection;
  /** Returns a catalog namespace which defines custom attribute schemas */
  catalogNamespaceById: CatalogNamespace;
  /** Returns a catalog root item by its root ID. A root item ties together multiple variant items. */
  catalogRootItemById: CatalogRootItem;
  /**
   * Returns catalog root items that map to this custom identifier.
   * Note that if the custom identifier was 'unique', this should return at most 1 root item.
   * If the identifier was not unique, there may be multiple root items.
   */
  catalogRootItemsByCustomIdentifier: CatalogRootItemConnection;
  /** Get a Base64 endcoded string of Certificate by Transcript id Query */
  certificate?: Maybe<Scalars['String']['output']>;
  /** Retrieves a session by ID and optional version */
  checkoutSessionById: CheckoutSession;
  /** Get attendance records */
  classAttendance: Array<Maybe<LearningActivityAttendanceRecord>>;
  /** Get Classroom Training Assignment by Learning Activity Id. */
  classroomTrainingAssignmentsByLearningActivityId: ClassroomTrainingAssignmentConnection;
  /** Get Classroom Training Assignment by UserId. */
  classroomTrainingAssignmentsByUserId: ClassroomTrainingAssignmentConnection;
  classroomsPing: Scalars['String']['output'];
  commentsByEntity: CommentsConnection;
  /** Get current user */
  currentUser: User;
  /** Fetch delivery session based on delivery session id */
  deliverySession?: Maybe<DeliverySession>;
  /** Generates Base64-encoded string for a completion certificate PDF given a rewardId. */
  downloadCompletionCertificateByRewardId: CompletionCertificate;
  /** Get user's entityAssociations by its id. It returns all results within one call without pagination. */
  entityAssociationsByUserId?: Maybe<UserEntityAssociationConnection>;
  /** Get an Entity User Group by its id. */
  entityUserGroupById?: Maybe<EntityUserGroup>;
  /** Get the transcript's required evaluation, or null. */
  evaluationByTranscriptId?: Maybe<Evaluation>;
  /** Get the transcript's required evaluation by userId and catalogItemVersionedId, or null. */
  evaluationByUserIdAndCatalogItemVersionedId?: Maybe<Evaluation>;
  /** Get a list of evaluations by userId. Can add filter and pagination options. */
  evaluationsByUserId: EvaluationConnection;
  /** Search for FileEntities */
  fileEntities: FileEntityConnection;
  /** Get an FileEntity by its ID. */
  fileEntityById: FileEntity;
  /** Get an FileEntity by its ID and version. */
  fileEntityByIdAndVersion: FileEntity;
  /** Get all versions of FileEntity by its ID. */
  fileEntityVersionsById: FileEntityConnection;
  /** Get file location by user alias and file ID and version. */
  fileLocationByIdAndVersion: FileLocation;
  /** Get a specific FileMetadata latest version by its ID. */
  fileMetadataById: FileMetadata;
  /** Get a specific FileMetadata version by its ID and version. */
  fileMetadataByIdAndVersion: FileMetadata;
  /** Get all versions of FileMetadata by its ID. */
  fileMetadataVersionsById: FileMetadataConnection;
  /** Get files metadata based on search criteria. */
  filesMetadata: FileMetadataConnection;
  fulfillmentById: Fulfillment;
  getClassroom: Classroom;
  getOrderItemByOpportunityLineItemId?: Maybe<OrderItem>;
  getPayerAccountOfferPrice?: Maybe<PayerAccountOfferPriceOutput>;
  getSalesforceOpportunityById: SalesforceOpportunity;
  getSalesforceOpportunityLineByLineItemId: SalesforceOpportunityLineItem;
  /**
   * Gets ToBeBilledActivies for NMBS
   * accountIds: Retrieve To-Be-Billed activity IDs associated with specific account IDs
   * startTimestamp: earliest acceptable starting delivery session
   * endTimestamp: latest acceptable starting delivery session
   */
  getToBeBilledActivities?: Maybe<Array<Array<Scalars['ID']['output']>>>;
  /** Get a Group by its id. */
  groupById?: Maybe<Group>;
  /** Get a Group by its vendor id. */
  groupByVendorId?: Maybe<Group>;
  /** Confirm if an awsAccountId has associated active payment subscriptions over an threshold (1 by default) */
  hasInitiatedOrScheduledPayments: Scalars['Boolean']['output'];
  hello?: Maybe<Scalars['String']['output']>;
  helloDevDemo?: Maybe<Scalars['String']['output']>;
  helloDevDemoNew?: Maybe<Scalars['String']['output']>;
  helloWorld?: Maybe<HelloWorld>;
  hola?: Maybe<Scalars['String']['output']>;
  /**
   * Returns the list of all users with a matching email address for the user (not including the user itself).
   * For each user in the list the identityLinkingCandidate of that user is set to true/false depending on if
   * that user can be used in ID linking with the userId provided
   */
  identityLinkingCandidatesById: Array<User>;
  /** Get a list of ilt evaluations by userId (deprecated) */
  iltEvaluationsByUserId?: Maybe<IltEvaluationConnection>;
  /** Get a list of all internal Users. */
  internalUsers?: Maybe<UserConnection>;
  invoiceById?: Maybe<Invoice>;
  invoiceDocumentByInvoiceId?: Maybe<InvoiceDocument>;
  /** Fetches authorization data related to users and scopes. */
  isAuthorized: ResourceAuthorizationCheckOutput;
  /** Get learner activities by actor ID (e.g. user Vibe ID), object IDs (e.g. xAPI file activity IDs, or T&C Catalog Service catalog item IDs), and optionally registration ID. */
  learnerActivities?: Maybe<LearnerActivityConnection>;
  /** Get a Learning Account by its ID. */
  learningAccountById?: Maybe<LearningAccount>;
  /** Get a Learning Account by SFDC Account ID. */
  learningAccountBySfdcAccountId?: Maybe<LearningAccount>;
  /** Get a Learning Account by its associated SkillBuilder Branch ID, if one exists. */
  learningAccountBySkillBuilderBranchId?: Maybe<LearningAccount>;
  /** Get a list of Learning Accounts, with optional search filter. */
  learningAccounts?: Maybe<LearningAccountsConnection>;
  /** Get Learning Accounts by awsAccountId. */
  learningAccountsByAwsAccountId?: Maybe<Array<Maybe<LearningAccount>>>;
  /** Get Learning Accounts by Parent SFDC Account ID. */
  learningAccountsBySfdcParentAccountId?: Maybe<Array<LearningAccount>>;
  /** Fetch learning activities by given search criteria */
  learningActivities?: Maybe<Array<Maybe<LearningActivity>>>;
  /** Fetch learning activity based on PK */
  learningActivity?: Maybe<LearningActivity>;
  /** Fetch the Activity info for a specific LearningActivityGroup */
  learningActivityGroup?: Maybe<LearningActivityGroup>;
  /** Get a specific LearningContainer latest version by its ID. */
  learningContainerById: LearningContainer;
  /** Get a specific LearningContainer version by its ID and version. */
  learningContainerByIdAndVersion: LearningContainer;
  /** Get all versions of LearningContainer by its ID. */
  learningContainerVersionsById: LearningContainerConnection;
  /** Get learning containers based on search criteria. */
  learningContainers: LearningContainerConnection;
  /** Get a License by its ID and optional version. */
  licenseById: License;
  /** Get a LicensePolicy by its ID and optional version. */
  licensePolicyById: LicensePolicy;
  licensesAggregates: LicenseAllocationConnection;
  /** Get a LicenseCollection by OrderItemId. */
  licensesByOrderItemId: LicenseConnection;
  /** Get a LicenseCollection by VibeId. */
  licensesByVibeId: LicenseConnection;
  lxpAttributeTemplateByLxpAndEntity: LxpAttributeTemplate;
  lxpAttributeTemplatesByLxp: LxpAttributeTemplateConnection;
  offerById: Offer;
  orderById: Order;
  orderByOpportunityId?: Maybe<Order>;
  orderItemById: OrderItem;
  orders: OrderConnection;
  ordersByOpportunityId: OrderConnection;
  /** Get an organization by its idpValue for SSO Organizations */
  organizationByIDP?: Maybe<Organization>;
  /** Get an Organization by its ID. */
  organizationById?: Maybe<Organization>;
  /** Get an Organization by its SFDC account id. */
  organizationBySfdcAccountId?: Maybe<Organization>;
  /** Get an Organization by its vendor code. */
  organizationByVendorCode?: Maybe<Organization>;
  /** Get an Organization by its vendorId. */
  organizationByVendorId?: Maybe<Organization>;
  /** Get a list of Organizations, with optional search filter. */
  organizations?: Maybe<OrganizationConnection>;
  /** Get list of Organizations by their Aws Account Id. */
  organizationsByAwsAccountId?: Maybe<OrganizationConnection>;
  /** Get list of Organizations by their SFDC parent account id. */
  organizationsBySfdcParentAccountId?: Maybe<OrganizationConnection>;
  outOfCycleBillByExternalBillId?: Maybe<OutOfCycleBill>;
  /** Retrieve payment by its ID */
  paymentById: Payment;
  /** Retrieve payment method by its ID */
  paymentMethodById: PaymentMethod;
  playgroundQuery?: Maybe<Scalars['String']['output']>;
  pollAssessmentQuestionsGenerationWorkflow?: Maybe<AssessmentQuestionsPollWorkflowOutput>;
  /** Fetches Authorized resources for a given Principal and Action */
  principalAuthorizedResources: AuthorizedResourcesOutput;
  /** Get LicensePolicies based on principal. */
  principalLicensePolicies: LicensePolicyConnection;
  publishingWorkflowById: PublishingWorkflow;
  publishingWorkflows?: Maybe<PublishingWorkflowConnection>;
  publishingWorkflowsByLearningContainer?: Maybe<PublishingWorkflowConnection>;
  recommendations?: Maybe<Array<Recommendation>>;
  /** Get Registration by catalogItemId and userId */
  registrationByCatalogItemIdAndUserId?: Maybe<Registration>;
  /** Get Registration record by Registration Record Id */
  registrationById?: Maybe<Registration>;
  /** Get the Registration entity by a LearningActivityGroupId and userId */
  registrationByLearningActivityGroupIdAndUserId: Registration;
  /** Get Registration record by Learning Activity Id and User Id */
  registrationByLearningActivityIdAndUserId?: Maybe<Registration>;
  /** Get the Registration entities by a LearningActivityGroupId */
  registrationsByLearningActivityGroupId: RegistrationConnection;
  /** Get Registrations entities by Instructor Led Training LearningActivityId */
  registrationsByLearningActivityId?: Maybe<RegistrationConnection>;
  /** Get the Registration entities by userId with an optional filter */
  registrationsByUserId: RegistrationConnection;
  /** Get LicensePolicies based on resource. */
  resourceLicensePolicies: LicensePolicyConnection;
  /** Fetch a review for a user id and referent. */
  reviewByUserIdAndReferent?: Maybe<Review>;
  /** Fetch all reviews for a given user id. */
  reviewsByUserId: ReviewConnection;
  /** Fetch all rewards for a given userId and catalogRootItemId. Can optionally filter the completion certificates down to one matching a catalogItemId. */
  rewardsByUserIdAndCatalogRootItemId: Array<Reward>;
  /** Frontend query used to search user information from consolidated datastore. */
  searchAllUsers?: Maybe<SearchAllUsersConnection>;
  /** Search Query -- Queries Kendra using a query string and optional filters. */
  searchV2?: Maybe<SearchResultV2>;
  subscriptionRequestById?: Maybe<SubscriptionRequest>;
  subscriptionRequestsByUserId?: Maybe<SubscriptionRequestByUserIdOutput>;
  subscriptionRequestsByVibeId?: Maybe<SubscriptionRequestByUserIdOutput>;
  /** Get a System User Group by its id. */
  systemUserGroupById?: Maybe<SystemUserGroup>;
  /** Get a list of System User Groups, with optional filters to search */
  systemUserGroups: SystemUserGroupConnection;
  /** Get a Team by its id. */
  teamById?: Maybe<Team>;
  /** Get a Team by its vendor id. */
  teamByVendorId?: Maybe<Team>;
  /** Get Training Assignment record by Assignment Record Id */
  trainingAssignmentById?: Maybe<TrainingAssignment>;
  /** Get Training Assignment by UserId and CatalogRootItemId. */
  trainingAssignmentByUserIdAndCatalogRootItemId?: Maybe<TrainingAssignment>;
  /** Get Training Assignment Task by Id */
  trainingAssignmentTaskById?: Maybe<TrainingAssignmentTask>;
  /** Get Training Assignment Tasks by orgId */
  trainingAssignmentTasksByOrgId: TrainingAssignmentTaskConnection;
  /** Get Training Assignment entities by orgId and optionally specify a catalogRootItemId if needed just for a particular catalogRootItem for an org. */
  trainingAssignmentsByOrgId: TrainingAssignmentConnection;
  /** Get Training Assignment entities by TeamId and optionally specify a catalogRootItemId if needed just for a particular catalogRootItem for a team. */
  trainingAssignmentsByTeamId: TrainingAssignmentConnection;
  /** Get Training Assignment entities by UserId and OrgId */
  trainingAssignmentsByUserIdAndOrgId: TrainingAssignmentConnection;
  /** Get a single Transcript by Transcript id Query */
  transcript?: Maybe<Transcript>;
  /** Get a single transcript by ID */
  transcriptById: TranscriptEntity;
  /** Get a specific SB(Digital LP or Course) transcript by UserId and CatalogItemId */
  transcriptByUserIdAndCatalogItemId?: Maybe<DigitalTranscript>;
  /** Get a specific SB(Digital LP or Course) transcript by UserId and CatalogItemVersionedId */
  transcriptByUserIdAndCatalogItemVersionedId?: Maybe<DigitalTranscript>;
  /** Get transcript record by vibe ID and catalog item ID */
  transcriptByUserVibeIdAndCatalogItemId?: Maybe<TranscriptV1>;
  /** Get transcript record by vendorUserID and vendorCatalogItem ID */
  transcriptByVendorUserIdAndVendorCatalogItemId?: Maybe<TranscriptV1>;
  /** Get list of all SB(Digital LP and Course) and ACI(Course Load level) transcripts by UserId (for Transcript Page) */
  transcriptEntitiesByUserId: Array<TranscriptEntity>;
  /** Get Transcript list by User Id Query */
  transcriptsByUserId?: Maybe<TranscriptConnection>;
  /** Get list of specific SB(Digital LP or Course) CatalogItem's all versions transcript by UserId and CatalogItemId */
  transcriptsByUserIdAndCatalogItemId?: Maybe<Array<DigitalTranscript>>;
  /** Get transcripts by vibe ID */
  transcriptsByUserVibeId?: Maybe<TranscriptConnectionV1>;
  /**
   * Get the status of a user data transfer by id.
   * Returns list of failed steps if overall status is FAILED (one or more steps failed).
   */
  transferStatusById: TransferStatusOutput;
  userAssessment?: Maybe<UserAssessment>;
  /** Get details of an operation, including its status and progress. */
  userBulkOperationById: UserBulkOperation;
  /**
   * Get a specific file uploaded by userId and fileId.
   * @deprecated Use `userBulkOperationById` instead.
   */
  userBulkOperationFileById?: Maybe<BulkOperationFile>;
  /**
   * Get the template of bulk user operation file, for the user to download and fill out with user info.
   * @deprecated Use `userBulkOperationTemplate` instead.
   */
  userBulkOperationFileTemplate: BulkOperationFile;
  /**
   * Get a list of files uploaded by this user.
   * @deprecated Use `userBulkOperations` instead.
   */
  userBulkOperationFiles?: Maybe<UserBulkOperationFileConnection>;
  /** Get a downloadable template for a bulk user operation input file. The template will differ, depending on the context of the operation. */
  userBulkOperationTemplate: UserBulkOperationFileTemplate;
  /**
   * Get a list of operations for the provided context. This will exclude PENDING operations. Optionally, provide a filter to filter the list.
   * Operations will be returned in descending order by date. Max pageSize is 100.
   */
  userBulkOperations: UserBulkOperationConnection;
  /**
   * Get a User based on provided email address.
   * @deprecated Resolver is deprecated. Please query by 'usersByEmail' instead.
   */
  userByEmail?: Maybe<User>;
  userByFederatedId: IdentitySearchResult;
  /** Get a User by its HatID. */
  userByHatId?: Maybe<User>;
  /** Get a User by its ID. Id should be synced to VIAS so it exists in JWT claims. */
  userById?: Maybe<User>;
  /** Get a User based on their external vendor id. */
  userByVendorId?: Maybe<User>;
  /** Get a User by its VibeID. */
  userByVibeId?: Maybe<User>;
  /** Get Users based on provided email address. */
  usersByEmail: Array<User>;
  validateATPUserBySPMSId?: Maybe<Scalars['Boolean']['output']>;
  workflowById: Workflow;
  workflows?: Maybe<WorkflowConnection>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryAccessByUserIdAndCatalogItemIdArgs = {
  catalogItemId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryAciTranscriptByUserIdAndLearningActivityGroupIdArgs = {
  learningActivityGroupId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryAciTranscriptsByUserIdArgs = {
  userId: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryAssessmentArgs = {
  id: Scalars['String']['input'];
  language?: InputMaybe<AssessmentLanguage>;
  version: Scalars['Int']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryAssessmentLearningObjectiveArgs = {
  input: AssessmentMetadataQueryInput;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryAssessmentLearningObjectivesArgs = {
  input: AssessmentMetadataSearchInput;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryAssessmentQuestionArgs = {
  id: Scalars['String']['input'];
  language?: InputMaybe<AssessmentLanguage>;
  version: Scalars['Int']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryAssessmentQuestionAuditLogArgs = {
  input: AuditLogInput;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryAssessmentQuestionBankArgs = {
  input: AssessmentMetadataQueryInput;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryAssessmentQuestionBanksArgs = {
  input: AssessmentMetadataSearchInput;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryAssessmentQuestionsArgs = {
  input: AssessmentQuestionsInput;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryAssessmentTagArgs = {
  input: AssessmentMetadataQueryInput;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryAssessmentTagsArgs = {
  input: AssessmentMetadataSearchInput;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryAssessmentUserArgs = {
  id: Scalars['String']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryAssessmentUsersArgs = {
  input: AssessmentUsersInput;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryAssessmentsArgs = {
  input: AssessmentsInput;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryBenefitByIdArgs = {
  id: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryCatalogAttributeSchemasArgs = {
  filter?: InputMaybe<CatalogAttributeSchemaFilter>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryCatalogGroupByIdArgs = {
  id: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryCatalogGroupsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryCatalogGroupsByCustomIdentifierArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  namespaceId?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  scope?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['String']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryCatalogItemByIdArgs = {
  id: Scalars['ID']['input'];
  semanticVersion?: InputMaybe<SemanticVersionInput>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryCatalogItemByRootIdArgs = {
  rootId: Scalars['ID']['input'];
  variantDimensions: CatalogItemVariantDimensionsInput;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryCatalogItemByVersionedIdArgs = {
  versionedId: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryCatalogItemVersionsByIdArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  major?: InputMaybe<Scalars['Int']['input']>;
  minor?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryCatalogItemsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  ownerAlias?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryCatalogItemsByCustomIdentifierArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  namespaceId?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  scope?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['String']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryCatalogItemsExternalArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  groupIds: Array<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  query: Scalars['String']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryCatalogNamespaceByIdArgs = {
  id: Scalars['String']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryCatalogRootItemByIdArgs = {
  id: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryCatalogRootItemsByCustomIdentifierArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  value: Scalars['String']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryCertificateArgs = {
  transcriptId: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryCheckoutSessionByIdArgs = {
  id: Scalars['ID']['input'];
  version?: InputMaybe<Scalars['Int']['input']>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryClassAttendanceArgs = {
  searchCriteria: ClassAttendanceQueryInput;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryClassroomTrainingAssignmentsByLearningActivityIdArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  learningActivityId: Scalars['ID']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryClassroomTrainingAssignmentsByUserIdArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryCommentsByEntityArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  entityId: Scalars['String']['input'];
  entityName: Scalars['String']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryCurrentUserArgs = {
  version?: InputMaybe<CurrentUserApiVersion>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryDeliverySessionArgs = {
  id: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryDownloadCompletionCertificateByRewardIdArgs = {
  input: DownloadCompletionCertificateByRewardIdInput;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryEntityAssociationsByUserIdArgs = {
  associationStatus?: InputMaybe<UserAssociationStatus>;
  userAccess?: InputMaybe<UserAccess>;
  userId: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryEntityUserGroupByIdArgs = {
  groupId: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryEvaluationByTranscriptIdArgs = {
  id: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryEvaluationByUserIdAndCatalogItemVersionedIdArgs = {
  catalogItemVersionedId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryEvaluationsByUserIdArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<EvaluationFilterInput>;
  id: Scalars['ID']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryFileEntitiesArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  searchFilters?: InputMaybe<FileEntitySearchFilters>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryFileEntityByIdArgs = {
  id: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryFileEntityByIdAndVersionArgs = {
  id: Scalars['ID']['input'];
  version: Scalars['Int']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryFileEntityVersionsByIdArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryFileLocationByIdAndVersionArgs = {
  id: Scalars['ID']['input'];
  userAlias: Scalars['String']['input'];
  version: Scalars['Int']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryFileMetadataByIdArgs = {
  id: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryFileMetadataByIdAndVersionArgs = {
  id: Scalars['ID']['input'];
  version: Scalars['Int']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryFileMetadataVersionsByIdArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryFilesMetadataArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  searchFilters?: InputMaybe<FileMetadataSearchFilters>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryFulfillmentByIdArgs = {
  id: Scalars['ID']['input'];
  version?: InputMaybe<Scalars['Int']['input']>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryGetClassroomArgs = {
  classroomArn: Scalars['String']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryGetOrderItemByOpportunityLineItemIdArgs = {
  opportunityLineItemId: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryGetPayerAccountOfferPriceArgs = {
  input: PayerAccountOfferPriceInput;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryGetSalesforceOpportunityByIdArgs = {
  includeAllLines?: InputMaybe<Scalars['Boolean']['input']>;
  opportunityId: Scalars['String']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryGetSalesforceOpportunityLineByLineItemIdArgs = {
  opportunityLineItemId: Scalars['String']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryGetToBeBilledActivitiesArgs = {
  accountIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  endTimestamp?: InputMaybe<Scalars['AWSTimestamp']['input']>;
  startTimestamp?: InputMaybe<Scalars['AWSTimestamp']['input']>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryGroupByIdArgs = {
  groupId: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryGroupByVendorIdArgs = {
  groupStatus?: InputMaybe<GroupStatus>;
  vendorId: Scalars['String']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryHasInitiatedOrScheduledPaymentsArgs = {
  awsAccountId: Scalars['String']['input'];
  threshold?: InputMaybe<Scalars['Int']['input']>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryHelloWorldArgs = {
  id: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryIdentityLinkingCandidatesByIdArgs = {
  userId: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryIltEvaluationsByUserIdArgs = {
  userId: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryInternalUsersArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryInvoiceByIdArgs = {
  id: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryInvoiceDocumentByInvoiceIdArgs = {
  id: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryIsAuthorizedArgs = {
  input: ResourceAuthorizationCheckInput;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryLearnerActivitiesArgs = {
  actorId: Scalars['String']['input'];
  objectIds: Array<Scalars['String']['input']>;
  registrationId?: InputMaybe<Scalars['String']['input']>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryLearningAccountByIdArgs = {
  id: Scalars['ID']['input'];
  version?: InputMaybe<Scalars['Int']['input']>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryLearningAccountBySfdcAccountIdArgs = {
  sfdcAccountId: Scalars['String']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryLearningAccountBySkillBuilderBranchIdArgs = {
  skillBuilderBranchId: Scalars['String']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryLearningAccountsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryLearningAccountsByAwsAccountIdArgs = {
  awsAccountId: Scalars['String']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryLearningAccountsBySfdcParentAccountIdArgs = {
  sfdcParentAccountId: Scalars['String']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryLearningActivitiesArgs = {
  learningActivitiesInput: LearningActivitiesInput;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryLearningActivityArgs = {
  pk: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryLearningActivityGroupArgs = {
  pk: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryLearningContainerByIdArgs = {
  id: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryLearningContainerByIdAndVersionArgs = {
  id: Scalars['ID']['input'];
  version: Scalars['Int']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryLearningContainerVersionsByIdArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryLearningContainersArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  searchFilters?: InputMaybe<LearningContainerSearchFilters>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryLicenseByIdArgs = {
  id: Scalars['ID']['input'];
  version?: InputMaybe<Scalars['Int']['input']>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryLicensePolicyByIdArgs = {
  id: Scalars['ID']['input'];
  version?: InputMaybe<Scalars['Int']['input']>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryLicensesAggregatesArgs = {
  additionalFilterOptions?: InputMaybe<LicenseAllocationFilterInput>;
  assignedEntityId: Scalars['String']['input'];
  cursor?: InputMaybe<Scalars['String']['input']>;
  entityType: LicenseEntityType;
  includeAggregatesFromTeams?: InputMaybe<Scalars['Boolean']['input']>;
  modality: ProductModality;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryLicensesByOrderItemIdArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  onlyActiveAssociations?: InputMaybe<Scalars['Boolean']['input']>;
  orderItemId: Scalars['ID']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryLicensesByVibeIdArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  vibeId: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryLxpAttributeTemplateByLxpAndEntityArgs = {
  entity: EntityType;
  lxp: Lxp;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryLxpAttributeTemplatesByLxpArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  lxp: Lxp;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryOfferByIdArgs = {
  appliedBenefits?: InputMaybe<Array<Scalars['ID']['input']>>;
  id: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryOrderByIdArgs = {
  id: Scalars['ID']['input'];
  version?: InputMaybe<Scalars['Int']['input']>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryOrderByOpportunityIdArgs = {
  opportunityId: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryOrderItemByIdArgs = {
  id: Scalars['ID']['input'];
  version?: InputMaybe<Scalars['Int']['input']>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryOrdersArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  input?: InputMaybe<OrdersFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryOrdersByOpportunityIdArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  opportunityId: Scalars['ID']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryOrganizationByIdpArgs = {
  idpValue: Scalars['String']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryOrganizationByIdArgs = {
  id: Scalars['ID']['input'];
  version?: InputMaybe<Scalars['Int']['input']>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryOrganizationBySfdcAccountIdArgs = {
  sfdcAccountId: Scalars['String']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryOrganizationByVendorCodeArgs = {
  vendorCode: Scalars['String']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryOrganizationByVendorIdArgs = {
  vendorId: Scalars['Int']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryOrganizationsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<OrganizationFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryOrganizationsByAwsAccountIdArgs = {
  awsAccountId: Scalars['String']['input'];
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryOrganizationsBySfdcParentAccountIdArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sfdcParentAccountId: Scalars['String']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryOutOfCycleBillByExternalBillIdArgs = {
  id: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryPaymentByIdArgs = {
  id: Scalars['ID']['input'];
  version?: InputMaybe<Scalars['Int']['input']>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryPaymentMethodByIdArgs = {
  id: Scalars['ID']['input'];
  version?: InputMaybe<Scalars['Int']['input']>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryPollAssessmentQuestionsGenerationWorkflowArgs = {
  input?: InputMaybe<AssessmentQuestionsPollWorkflowInput>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryPrincipalAuthorizedResourcesArgs = {
  input: PrincipalAuthorizedResourcesInput;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryPrincipalLicensePoliciesArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  policyStatus?: InputMaybe<LicensePolicyStatus>;
  principalId: Scalars['ID']['input'];
  principalType: AuthorizationPrincipalType;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryPublishingWorkflowByIdArgs = {
  id: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryPublishingWorkflowsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryPublishingWorkflowsByLearningContainerArgs = {
  input?: InputMaybe<PublishingWorkflowsByLearningContainerInput>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryRecommendationsArgs = {
  input?: InputMaybe<RecommendationsInput>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryRegistrationByCatalogItemIdAndUserIdArgs = {
  catalogItemId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryRegistrationByIdArgs = {
  id: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryRegistrationByLearningActivityGroupIdAndUserIdArgs = {
  learningActivityGroupId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryRegistrationByLearningActivityIdAndUserIdArgs = {
  learningActivityId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryRegistrationsByLearningActivityGroupIdArgs = {
  learningActivityGroupId: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryRegistrationsByLearningActivityIdArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  learningActivityId: Scalars['ID']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryRegistrationsByUserIdArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<RegistrationFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryResourceLicensePoliciesArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  policyStatus?: InputMaybe<LicensePolicyStatus>;
  resourceId: Scalars['ID']['input'];
  resourceType: AuthorizationResourceType;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryReviewByUserIdAndReferentArgs = {
  input: ReviewByUserIdAndReferentInput;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryReviewsByUserIdArgs = {
  input: ReviewsByUserIdInput;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryRewardsByUserIdAndCatalogRootItemIdArgs = {
  input: RewardsByUserIdAndCatalogRootItemIdInput;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QuerySearchAllUsersArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  input?: InputMaybe<SearchAllUsersInput>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QuerySearchV2Args = {
  input: SearchInputV2;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QuerySubscriptionRequestByIdArgs = {
  id: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QuerySubscriptionRequestsByUserIdArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QuerySubscriptionRequestsByVibeIdArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  vibeId: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QuerySystemUserGroupByIdArgs = {
  groupId: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QuerySystemUserGroupsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryTeamByIdArgs = {
  id: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryTeamByVendorIdArgs = {
  id: Scalars['String']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryTrainingAssignmentByIdArgs = {
  id: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryTrainingAssignmentByUserIdAndCatalogRootItemIdArgs = {
  catalogRootItemId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryTrainingAssignmentTaskByIdArgs = {
  id: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryTrainingAssignmentTasksByOrgIdArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['ID']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryTrainingAssignmentsByOrgIdArgs = {
  catalogRootItemId?: InputMaybe<Scalars['ID']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['ID']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryTrainingAssignmentsByTeamIdArgs = {
  catalogRootItemId?: InputMaybe<Scalars['ID']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  teamId: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryTrainingAssignmentsByUserIdAndOrgIdArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['ID']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryTranscriptArgs = {
  id: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryTranscriptByIdArgs = {
  id: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryTranscriptByUserIdAndCatalogItemIdArgs = {
  catalogItemId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryTranscriptByUserIdAndCatalogItemVersionedIdArgs = {
  catalogItemVersionedId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryTranscriptByUserVibeIdAndCatalogItemIdArgs = {
  catalogItemId: Scalars['ID']['input'];
  userVibeId: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryTranscriptByVendorUserIdAndVendorCatalogItemIdArgs = {
  vendorCatalogItemId: Scalars['Int']['input'];
  vendorCatalogType: Scalars['String']['input'];
  vendorUserId: Scalars['Int']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryTranscriptEntitiesByUserIdArgs = {
  userId: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryTranscriptsByUserIdArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryTranscriptsByUserIdAndCatalogItemIdArgs = {
  catalogItemId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryTranscriptsByUserVibeIdArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  userVibeId: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryTransferStatusByIdArgs = {
  transferId: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryUserAssessmentArgs = {
  input: UserAssessmentQueryInput;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryUserBulkOperationByIdArgs = {
  id: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryUserBulkOperationFileByIdArgs = {
  fileId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryUserBulkOperationFilesArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  fromTimeStamp?: InputMaybe<Scalars['AWSDateTime']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  toTimeStamp?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userId: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryUserBulkOperationTemplateArgs = {
  context: UserBulkOperationContext;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryUserBulkOperationsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  input: UserBulkOperationsInput;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryUserByEmailArgs = {
  email: Scalars['AWSEmail']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryUserByFederatedIdArgs = {
  input: IdentityQueryInput;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryUserByHatIdArgs = {
  hatId: Scalars['String']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryUserByIdArgs = {
  id: Scalars['ID']['input'];
  version?: InputMaybe<Scalars['Int']['input']>;
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryUserByVendorIdArgs = {
  vendorId: Scalars['String']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryUserByVibeIdArgs = {
  version?: InputMaybe<Scalars['Int']['input']>;
  vibeId: Scalars['String']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryUsersByEmailArgs = {
  email: Scalars['AWSEmail']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryValidateAtpUserBySpmsIdArgs = {
  id: Scalars['Int']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryWorkflowByIdArgs = {
  id: Scalars['ID']['input'];
};


/** Query type for SBAIR Dashboard Reporting Service Backend Subgraph. */
export type QueryWorkflowsArgs = {
  input: WorkflowInput;
};

/**
 * Count distribution of ratings for a given rating dimension
 * (e.g. 41 1-star reviews, 33 2-star reviews, etc)
 */
export type RatingHistogram = {
  __typename?: 'RatingHistogram';
  fiveStar: RatingHistogramData;
  fourStar: RatingHistogramData;
  oneStar: RatingHistogramData;
  threeStar: RatingHistogramData;
  twoStar: RatingHistogramData;
};

/** Data about a rating interval. */
export type RatingHistogramData = {
  __typename?: 'RatingHistogramData';
  /** Count of ratings per interval. e.g. 20 1-star ratings. */
  count: Scalars['Int']['output'];
  /** Percentage to display in histogram */
  percentage: Scalars['Int']['output'];
};

export type Recommendation = {
  __typename?: 'Recommendation';
  catalogItem?: Maybe<CatalogItem>;
  id?: Maybe<Scalars['String']['output']>;
};

export enum RecommendationType {
  Personalized = 'PERSONALIZED',
  Popular = 'POPULAR'
}

export type RecommendationsInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  recommendationType?: InputMaybe<RecommendationType>;
};

export type RedemptionPeriod = {
  __typename?: 'RedemptionPeriod';
  endDate?: Maybe<Scalars['AWSDateTime']['output']>;
  startDate?: Maybe<Scalars['AWSDateTime']['output']>;
};

/**
 * Enumeration of entity types Review Service supports
 * New entity types can onboard, but requires critical business justification.
 */
export enum ReferentType {
  /** Anything modeled as a catalog item (e.g. learning plans, course) */
  CatalogItem = 'CATALOG_ITEM'
}

export type Registration = {
  __typename?: 'Registration';
  auditMetadata: AuditMetadata;
  catalogItem?: Maybe<CatalogItem>;
  id: Scalars['ID']['output'];
  learningActivity?: Maybe<LearningActivity>;
  learningActivityGroup?: Maybe<LearningActivityGroup>;
  organization?: Maybe<Organization>;
  registrationModalityType?: Maybe<RegistrationModalityType>;
  registrationStatus: RegistrationStatus;
  trainingAssignment?: Maybe<TrainingAssignment>;
  user: User;
};

export type RegistrationConnection = {
  __typename?: 'RegistrationConnection';
  nodes: Array<Registration>;
  pageInfo?: Maybe<PageInfo>;
};

export type RegistrationFilterInput = {
  learningActivityGroup?: InputMaybe<Scalars['ID']['input']>;
  registrationModalityType?: InputMaybe<RegistrationModalityType>;
  registrationStatus?: InputMaybe<RegistrationStatus>;
};

/** TBD if we align this with Catalog ModalityDelivery */
export enum RegistrationModalityType {
  Blended = 'Blended',
  Digital = 'Digital',
  Ilt = 'ILT'
}

export type RegistrationPolicyAttributes = {
  __typename?: 'RegistrationPolicyAttributes';
  auditMetadata: AuditMetadata;
  awsAccountIds?: Maybe<Array<Scalars['ID']['output']>>;
  classSize: Scalars['Int']['output'];
  inviteOnly?: Maybe<Scalars['Boolean']['output']>;
  learningActivityId: Scalars['ID']['output'];
  learningActivityStatus: LearningActivityRegistrationStatus;
  learningActivityTimeZone: Scalars['String']['output'];
  lmsType: LearningActivityLmsType;
  registrationCutOffTimeStamp: Scalars['AWSTimestamp']['output'];
  sfdcAccountIds?: Maybe<Array<Scalars['ID']['output']>>;
  withdrawalCutOffTimeStamp: Scalars['AWSTimestamp']['output'];
};

export type RegistrationPolicyAttributesInput = {
  awsAccountIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  classSize: Scalars['Int']['input'];
  inviteOnly?: InputMaybe<Scalars['Boolean']['input']>;
  learningActivityId: Scalars['ID']['input'];
  learningActivityStatus: LearningActivityRegistrationStatus;
  learningActivityTimeZone: Scalars['String']['input'];
  lmsType: LearningActivityLmsType;
  registrationCutOffTimeStamp: Scalars['AWSTimestamp']['input'];
  sfdcAccountIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  withdrawalCutOffTimeStamp: Scalars['AWSTimestamp']['input'];
};

/** TBD if to use a shared programType */
export enum RegistrationProgramType {
  Aci = 'ACI',
  SkillBuilder = 'SkillBuilder',
  MyClass = 'myClass'
}

export enum RegistrationStatus {
  Canceled = 'CANCELED',
  Registered = 'REGISTERED',
  Unwaitlisted = 'UNWAITLISTED',
  Waitlisted = 'WAITLISTED',
  Withdrawn = 'WITHDRAWN'
}

export type RelativeScheduleConfiguration = {
  __typename?: 'RelativeScheduleConfiguration';
  offsetInHours?: Maybe<Scalars['Int']['output']>;
  recurrence?: Maybe<OfferBillingPeriod>;
};

export type RemovePublishingWorkflowTagInput = {
  publishingWorkflowId: Scalars['ID']['input'];
  tag: KeyValuePairInput;
};

export type RemovePublishingWorkflowWatcherInput = {
  publishingWorkflowId: Scalars['ID']['input'];
  watcher: Scalars['String']['input'];
};

export type RemoveUserAssociationRolesInput = {
  parentResourceId: Scalars['ID']['input'];
  parentResourceType: AssociationResourceType;
  userId: Scalars['ID']['input'];
  userRoles: Array<RoleName>;
};

export type ReplaceOrderItemInput = {
  associatedLicenseIds: Array<Scalars['String']['input']>;
  newOrderItemInput: CreateOrderItemInput;
  orderId: Scalars['String']['input'];
  orderItemId: Scalars['String']['input'];
};

export type ReplaceOrderItemOutput = {
  __typename?: 'ReplaceOrderItemOutput';
  newOrder: Order;
  originalOrder: Order;
};

/** Type representing a notification message. */
export type ReportNotification = {
  __typename?: 'ReportNotification';
  /** Code indicating the type of notification. */
  reportNotificationCode: ReportNotificationCode;
  /** Message content of the notification. */
  reportNotificationMessage: Scalars['String']['output'];
};

/** Enumeration of notification codes. */
export enum ReportNotificationCode {
  Blue = 'BLUE',
  Red = 'RED',
  Yellow = 'YELLOW'
}

/** Data type representing reports including embedding URL and event notifications. */
export type ReportsData = {
  __typename?: 'ReportsData';
  /** List of string representing different access. */
  accessList: Array<Scalars['String']['output']>;
  /** URL to embed QuickSight dashboard for administrative reports. */
  reportEmbeddingUrl?: Maybe<Scalars['String']['output']>;
  /** List of notifications related to administrative events. */
  reportNotifications: Array<ReportNotification>;
};

export type ResourceAuthorizationCheckInput = {
  action: AuthorizationActionType;
  principal: AuthorizationPrincipalIdentityInput;
  resources: Array<AuthorizationResourceInput>;
};

export type ResourceAuthorizationCheckOutput = {
  __typename?: 'ResourceAuthorizationCheckOutput';
  results: Array<ResourcesAuthorizationResult>;
};

export type ResourcesAuthorizationResult = {
  __typename?: 'ResourcesAuthorizationResult';
  action: AuthorizationActionType;
  authorizationResult: AuthorizationResult;
  resourceId: Scalars['ID']['output'];
  resourceType: AuthorizationResourceType;
};

export type RestoreFileEntityVersionInput = {
  id: Scalars['ID']['input'];
  version: Scalars['Int']['input'];
};

export type RestoreFileMetadataVersionInput = {
  id: Scalars['ID']['input'];
  version: Scalars['Int']['input'];
};

export type RestoreLearningContainerVersionInput = {
  id: Scalars['ID']['input'];
  version: Scalars['Int']['input'];
};

/** A single review provided by a user. */
export type Review = {
  __typename?: 'Review';
  /** Unique identifier for a review. Auto-generated by service */
  id: Scalars['ID']['output'];
  /** Numerical rating from 1-5 */
  rating: Scalars['Int']['output'];
  /** Subject of the review */
  referent: ReviewReferent;
  /** User Service id of the user providing the review. */
  userId: Scalars['ID']['output'];
};

/** Input for ReviewByUserIDAndReferent */
export type ReviewByUserIdAndReferentInput = {
  /** Domain-specific referent ID */
  referentId: Scalars['ID']['input'];
  /** Referent type, only CATALOG_ITEM supported right now */
  referentType: ReferentType;
  /** User Service ID: Input Validation: Valid userId format */
  userId: Scalars['ID']['input'];
};

/**
 * Paginated list of reviews
 * https://graphql.org/learn/pagination/#end-of-list-counts-and-connections
 */
export type ReviewConnection = {
  __typename?: 'ReviewConnection';
  /** Collection of reviews */
  nodes: Array<Review>;
  /** Pagination info */
  pageInfo?: Maybe<PageInfo>;
};

/**
 * Represents aggregated review data for a given referent across all versions
 * Provides data for UX of showing average star-rating, review count, and rating distribution
 */
export type ReviewInsights = {
  __typename?: 'ReviewInsights';
  /** Floating-point average across all versions */
  averageRating: Scalars['Float']['output'];
  /** Distribution of reviews for a given rating dimension */
  ratingHistogram: RatingHistogram;
  /** Domain-specific referent ID (e.g. catalog item id) */
  referentId: Scalars['ID']['output'];
  /** Referent Type (e.g. CATALOG_ITEM) */
  referentType: ReferentType;
  /** Number of reviews contributed to aggregated view */
  reviewCount: Scalars['Int']['output'];
};

/** An entity that a review is tied to. Would have used the word entity, but could cause namespace collision */
export type ReviewReferent = {
  /** Domain-specific ID (e.g. Catalog Item ID) */
  id: Scalars['ID']['output'];
  /** Type of referent (e.g. Catalog item) */
  type: ReferentType;
};

/** Input for ReviewsByUserId */
export type ReviewsByUserIdInput = {
  /** Token for getting next page of data */
  cursor?: InputMaybe<Scalars['String']['input']>;
  /** Number of max records to fetch. Does not guarantee max records returned, just that it will not exceed. Number between 1-100 */
  size?: InputMaybe<Scalars['Int']['input']>;
  /** User Service ID */
  userId: Scalars['ID']['input'];
};

export type Reward = {
  __typename?: 'Reward';
  /** Info for the associated CatalogItem. */
  catalogItem?: Maybe<CatalogItem>;
  /** Info for the associated CatalogRootItem. */
  catalogRootItem: CatalogRootItem;
  /** Unique Id maintained by the reward provider and external to the RewardService. */
  externalRewardId?: Maybe<Scalars['ID']['output']>;
  /** Auto-generated unique Id for a reward. */
  id: Scalars['ID']['output'];
  /** Modality of the training, e.g. DIGITAL, LIVE, BLENDED, etc. Should match CatalogModalityDelivery enum from CatalogService. */
  modalityType: Scalars['String']['output'];
  /** Provider of the reward, e.g. Credly, AWS, etc. */
  rewardProvider: RewardProvider;
  /** Type of the reward, e.g. DIGITAL_BADGE, COMPLETION_CERTIFICATE, etc. */
  rewardType: RewardType;
  /** Date when the transcript was marked as completed. Used to indicate issuance date for the reward. */
  transcriptCompletionDate: Scalars['String']['output'];
  /** Info for the associated user. */
  user: User;
};

export enum RewardProvider {
  Aws = 'AWS',
  Credly = 'Credly'
}

export enum RewardType {
  CompletionCertificate = 'COMPLETION_CERTIFICATE',
  DigitalBadge = 'DIGITAL_BADGE'
}

export type Role = {
  __typename?: 'Role';
  accessLevel: RoleAccessLevel;
  associationLevels: Array<RoleAssociationLevel>;
  classification: RoleClassification;
  description?: Maybe<Scalars['String']['output']>;
  designation: RoleDesignation;
  name: RoleName;
  permissions: Array<RolePermission>;
  piiAccess?: Maybe<RolePiiAccess>;
  primaryTrainingSpace?: Maybe<RolePrimaryTrainingSpace>;
  uiFriendlyName?: Maybe<Scalars['String']['output']>;
};

export enum RoleAccessLevel {
  All = 'ALL',
  Organization = 'ORGANIZATION',
  OrganizationOrTeam = 'ORGANIZATION_OR_TEAM',
  Self = 'SELF',
  Team = 'TEAM'
}

export enum RoleAssociationLevel {
  Organization = 'ORGANIZATION',
  Team = 'TEAM'
}

export enum RoleClassification {
  General = 'GENERAL',
  Restricted = 'RESTRICTED'
}

export enum RoleDesignation {
  External = 'EXTERNAL',
  Internal = 'INTERNAL'
}

export enum RoleName {
  AciInstructor = 'ACI_INSTRUCTOR',
  AciProgramManager = 'ACI_PROGRAM_MANAGER',
  Cem = 'CEM',
  ContentAuthors = 'CONTENT_AUTHORS',
  CustomerProductInsight = 'CUSTOMER_PRODUCT_INSIGHT',
  DctAdmin = 'DCT_ADMIN',
  DctAu = 'DCT_AU',
  DctSt = 'DCT_ST',
  DctUk = 'DCT_UK',
  DctUs = 'DCT_US',
  Learner = 'LEARNER',
  LearningAdmin = 'LEARNING_ADMIN',
  LearningAdminManager = 'LEARNING_ADMIN_MANAGER',
  ProgramManagerPartner = 'PROGRAM_MANAGER_PARTNER',
  PublishingManagement = 'PUBLISHING_MANAGEMENT',
  ReleaseManagement = 'RELEASE_MANAGEMENT',
  RoleAdminManager = 'ROLE_ADMIN_MANAGER',
  SbPmt = 'SB_PMT',
  Seller = 'SELLER',
  Tcsa = 'TCSA',
  TcCsm = 'TC_CSM',
  TcTams = 'TC_TAMS',
  TeamManager = 'TEAM_MANAGER',
  Tops = 'TOPS'
}

export enum RolePiiAccess {
  Full = 'FULL',
  None = 'NONE'
}

export type RolePermission = {
  __typename?: 'RolePermission';
  actions: Array<Maybe<Scalars['String']['output']>>;
  subject: Scalars['String']['output'];
};

export enum RolePrimaryTrainingSpace {
  Aci = 'ACI',
  Admin = 'ADMIN',
  Digital = 'DIGITAL'
}

export enum SbtsStatus {
  Active = 'ACTIVE',
  CancellationInProgress = 'CANCELLATION_IN_PROGRESS',
  Cancelled = 'CANCELLED',
  InvoiceGenerated = 'INVOICE_GENERATED',
  OnboardingComplete = 'ONBOARDING_COMPLETE',
  OnboardingInProcess = 'ONBOARDING_IN_PROCESS',
  Paid = 'PAID',
  PaymentFailed = 'PAYMENT_FAILED',
  RequestSubmitted = 'REQUEST_SUBMITTED'
}

export type SsoDetails = {
  __typename?: 'SSODetails';
  idp?: Maybe<SsoIdentityProvider>;
  ssoEnabled: Scalars['Boolean']['output'];
  technicalPOC?: Maybe<PointOfContact>;
};

export enum SsoIdentityProvider {
  Adfs = 'ADFS',
  Azure = 'AZURE',
  Duo = 'DUO',
  Google = 'GOOGLE',
  Jump = 'JUMP',
  Okta = 'OKTA',
  Onelogin = 'ONELOGIN',
  Other = 'OTHER',
  Ping = 'PING'
}

export type SalesforceOpportunity = {
  __typename?: 'SalesforceOpportunity';
  activeInvestmentRequest?: Maybe<ActiveInvestmentRequest>;
  awsAccountInfo: AwsAccountInfo;
  bdm: PointOfContact;
  billingCurrency?: Maybe<Scalars['String']['output']>;
  closeDate?: Maybe<Scalars['AWSDateTime']['output']>;
  doNotApplyVolumeDiscount?: Maybe<Scalars['Boolean']['output']>;
  grandTotal: Scalars['Float']['output'];
  irFundingType?: Maybe<Scalars['String']['output']>;
  irTotal?: Maybe<Scalars['Float']['output']>;
  items: Array<SalesforceOpportunityLineItem>;
  lmsId?: Maybe<Scalars['String']['output']>;
  noOfExistingLicenses: Scalars['Int']['output'];
  opportunityId: Scalars['String']['output'];
  opportunityName: Scalars['String']['output'];
  opportunitySubType?: Maybe<Scalars['String']['output']>;
  optionalDatesContract?: Maybe<Scalars['Boolean']['output']>;
  sfdcAccountId: Scalars['String']['output'];
  sfdcAccountName: Scalars['String']['output'];
  sfdcParentAccountId?: Maybe<Scalars['String']['output']>;
  sfdcParentAccountName?: Maybe<Scalars['String']['output']>;
  sfdcParentOpportunityId?: Maybe<Scalars['String']['output']>;
  specialDiscountAmount?: Maybe<Scalars['Float']['output']>;
  specialDiscountPercent?: Maybe<Scalars['Float']['output']>;
  typeOfContract?: Maybe<TypeOfContract>;
  volumeDiscount?: Maybe<Scalars['Float']['output']>;
};

export type SalesforceOpportunityLineItem = {
  __typename?: 'SalesforceOpportunityLineItem';
  allowedEmailDomains?: Maybe<Array<Scalars['String']['output']>>;
  authenticationMethod?: Maybe<AuthenticationMethod>;
  awsBillingAccountId?: Maybe<Scalars['String']['output']>;
  billingPeriod: BillingPeriod;
  cancellationReason?: Maybe<Scalars['String']['output']>;
  classEndDate: Scalars['AWSDateTime']['output'];
  classEndTime?: Maybe<Scalars['AWSDateTime']['output']>;
  classLocation?: Maybe<Scalars['String']['output']>;
  classStartDate: Scalars['AWSDateTime']['output'];
  classStartTime?: Maybe<Scalars['AWSDateTime']['output']>;
  classStatus?: Maybe<ClassStatus>;
  contractingPTRR?: Maybe<Ptrr>;
  contractingPtrrId?: Maybe<Scalars['String']['output']>;
  contractingRequestCreatedBy?: Maybe<PointOfContact>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  deliveryLanguage?: Maybe<Scalars['String']['output']>;
  discountAmount: Scalars['Float']['output'];
  fundsUsed?: Maybe<Scalars['Float']['output']>;
  grimsbyActivityLink?: Maybe<Scalars['String']['output']>;
  lmsId?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  opportunityId?: Maybe<Scalars['String']['output']>;
  opportunityLineItemId: Scalars['String']['output'];
  primaryTrainingPOC: PointOfContact;
  productName: Scalars['String']['output'];
  productValueNetAmount?: Maybe<Scalars['Float']['output']>;
  quantity: Scalars['Int']['output'];
  schedulingPTRR?: Maybe<Ptrr>;
  schedulingPtrrId?: Maybe<Scalars['String']['output']>;
  secondaryTrainingPOC?: Maybe<PointOfContact>;
  sellerOfRecord?: Maybe<Scalars['String']['output']>;
  ssoDetails: SsoDetails;
  subscriptionType?: Maybe<TypeOfContract>;
  timeZone?: Maybe<Scalars['String']['output']>;
  totalBilledRevenue: Scalars['Float']['output'];
  totalPrice: Scalars['Float']['output'];
  trainingDeliveryType?: Maybe<Scalars['String']['output']>;
  trainingTE?: Maybe<Scalars['Float']['output']>;
};

export type ScheduleConfiguration = AbsoluteScheduleConfiguration | RelativeScheduleConfiguration;

export type ScopeChangeAndNotes = {
  __typename?: 'ScopeChangeAndNotes';
  notes?: Maybe<Scalars['String']['output']>;
  updateScope?: Maybe<SemanticVersionScope>;
};

export type ScopeChangeAndNotesInput = {
  notes?: InputMaybe<Scalars['String']['input']>;
  updateScope?: InputMaybe<SemanticVersionScope>;
};

export type SearchAllUsersConnection = {
  __typename?: 'SearchAllUsersConnection';
  nodes: Array<SearchAllUsersEntry>;
  pageInfo?: Maybe<PageInfo>;
};

export enum SearchAllUsersConsentOutcome {
  Denied = 'DENIED',
  Granted = 'GRANTED'
}

export type SearchAllUsersEntry = {
  __typename?: 'SearchAllUsersEntry';
  assignedSubscriptions?: Maybe<Array<UserSubscriptionDetails>>;
  consentOutcome?: Maybe<SearchAllUsersConsentOutcome>;
  createdAt?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organizationDetails?: Maybe<Array<UserEntityDetails>>;
  status?: Maybe<SearchAllUsersStatus>;
  teamDetails?: Maybe<Array<UserEntityDetails>>;
};

export type SearchAllUsersInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  entityId?: InputMaybe<Scalars['String']['input']>;
  entityName?: InputMaybe<Scalars['String']['input']>;
  entityType?: InputMaybe<AssociationResourceType>;
  ignoreEntityGroupId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationType?: InputMaybe<OrganizationType>;
  role?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<SearchAllUsersStatus>;
  subscriptionStatus?: InputMaybe<SearchAllUsersSubscriptionStatus>;
  systemGroupId?: InputMaybe<Scalars['String']['input']>;
  systemGroupName?: InputMaybe<Scalars['String']['input']>;
};

export enum SearchAllUsersLicenseType {
  B2B = 'B2B',
  B2I = 'B2I'
}

export enum SearchAllUsersStatus {
  Active = 'ACTIVE',
  ConsentRevoked = 'CONSENT_REVOKED',
  Inactive = 'INACTIVE',
  Invited = 'INVITED',
  InvitedRejected = 'INVITED_REJECTED',
  Suspended = 'SUSPENDED'
}

export enum SearchAllUsersSubscriptionStatus {
  No = 'NO',
  Yes = 'YES'
}

export type SearchCatalogItem = {
  __typename?: 'SearchCatalogItem';
  id: Scalars['String']['output'];
};

export type SearchFacetV2 = {
  __typename?: 'SearchFacetV2';
  key: Scalars['String']['output'];
  name: LocalizedStringV2;
  values?: Maybe<Array<SearchFacetValueV2>>;
};

export type SearchFacetValueV2 = {
  __typename?: 'SearchFacetValueV2';
  totalResults: Scalars['Int']['output'];
  value: LocalizedStringV2;
};

export type SearchFilterV2 = {
  key: Scalars['String']['input'];
  values?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SearchInputV2 = {
  enableLanguageFacet?: InputMaybe<Scalars['Boolean']['input']>;
  filters?: InputMaybe<Array<SearchFilterV2>>;
  language?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<SearchResultOrder>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sortCriterion?: InputMaybe<SearchSortCriterion>;
};

export type SearchResultItemV2 = {
  __typename?: 'SearchResultItemV2';
  feedbackId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  item?: Maybe<CatalogItem>;
};

export enum SearchResultOrder {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING'
}

export type SearchResultV2 = {
  __typename?: 'SearchResultV2';
  facets?: Maybe<Array<SearchFacetV2>>;
  queryId?: Maybe<Scalars['String']['output']>;
  results?: Maybe<Array<SearchResultItemV2>>;
  sortCriteria?: Maybe<Array<SearchSortCriterion>>;
  totalResults: Scalars['Int']['output'];
};

export enum SearchSortCriterion {
  CourseLevel = 'COURSE_LEVEL',
  Date = 'DATE',
  Name = 'NAME',
  Rating = 'RATING',
  Relevance = 'RELEVANCE'
}

/** Represents a semantic version number */
export type SemanticVersion = {
  __typename?: 'SemanticVersion';
  /** Major version number */
  major?: Maybe<Scalars['Int']['output']>;
  /** Minor version number */
  minor?: Maybe<Scalars['Int']['output']>;
  /** Patch version number */
  patch?: Maybe<Scalars['Int']['output']>;
};

export type SemanticVersionInput = {
  major: Scalars['Int']['input'];
  minor: Scalars['Int']['input'];
  patch: Scalars['Int']['input'];
};

export enum SemanticVersionScope {
  Major = 'MAJOR',
  Minor = 'MINOR',
  Patch = 'PATCH'
}

export type SendNotificationWithQuery = {
  attachmentInfo?: InputMaybe<Array<EmailAttachment>>;
  clientIdentifier?: InputMaybe<Scalars['String']['input']>;
  cultureCode?: InputMaybe<Scalars['String']['input']>;
  deliveryType: Scalars['String']['input'];
  generatedEmailContent?: InputMaybe<Scalars['AWSJSON']['input']>;
  notificationType: Scalars['String']['input'];
  queryVariables: Scalars['AWSJSON']['input'];
  templateValues?: InputMaybe<Scalars['AWSJSON']['input']>;
};

export type Service = {
  __typename?: 'Service';
  sdl?: Maybe<Scalars['String']['output']>;
};

export type SetMaintenanceForCatalogItemVersionInput = {
  id: Scalars['ID']['input'];
  semanticVersion: SemanticVersionInput;
};

export type Settings = {
  autoAssignDigitalSubscription?: Maybe<Scalars['Boolean']['output']>;
};

export enum SettlementStatus {
  Closed = 'CLOSED',
  Open = 'OPEN'
}

export enum SignInMethod {
  BuilderId = 'BUILDER_ID',
  PartnerNetwork = 'PARTNER_NETWORK',
  Sso = 'SSO'
}

export type StartPublishingWorkflowStepInput = {
  publishingWorkflowId: Scalars['ID']['input'];
  publishingWorkflowStepId: Scalars['ID']['input'];
};

export type StartWorkflowStepInput = {
  WorkflowId: Scalars['ID']['input'];
  WorkflowStepId: Scalars['ID']['input'];
  values?: InputMaybe<Array<UserInputWithFieldDefinitionKeyInput>>;
};

export enum State {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED'
}

export type SubProviderCapacity = {
  __typename?: 'SubProviderCapacity';
  capacity: Scalars['Int']['output'];
  providerArn: Scalars['String']['output'];
};

export type SubProviderCapacityInput = {
  capacity: Scalars['Int']['input'];
  providerArn: Scalars['String']['input'];
};

export type SubmitFulfillmentItemDetailsInput = {
  digitalFulfillmentDetails: DigitalFulfillmentDetailsInput;
  fulfillmentId: Scalars['ID']['input'];
  fulfillmentItemId: Scalars['ID']['input'];
};

export type SubmitQuestionFeedbackInput = {
  dislikeReasons?: InputMaybe<Array<DislikeReason>>;
  feedbackType: FeedbackType;
  freeTextFeedback?: InputMaybe<Scalars['String']['input']>;
  generatedQuestion: GeneratedQuestionInput;
  learningObjectiveId: Scalars['String']['input'];
  learningObjectiveVersion: Scalars['Int']['input'];
  model: Scalars['String']['input'];
  numQuestions: Scalars['Int']['input'];
  questionDifficulty: Scalars['Int']['input'];
  questionType: Scalars['String']['input'];
};

export type SubmitQuestionFeedbackOutput = {
  __typename?: 'SubmitQuestionFeedbackOutput';
  feedbackId: Scalars['String']['output'];
};

export type SubscriptionRequest = {
  __typename?: 'SubscriptionRequest';
  branchName: Scalars['String']['output'];
  businessEmail: Scalars['String']['output'];
  companyName: Scalars['String']['output'];
  createdAt: Scalars['AWSDateTime']['output'];
  createdBy: Scalars['String']['output'];
  customerCountry?: Maybe<Scalars['String']['output']>;
  discountPercentage: Scalars['Float']['output'];
  endCustomerCompanyName?: Maybe<Scalars['String']['output']>;
  endCustomerCountry?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  language?: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  lastUpdatedAt: Scalars['AWSDateTime']['output'];
  lastUpdatedBy: Scalars['String']['output'];
  learningAdministrators: Array<LearningAdministrator>;
  linkedAccountId: Scalars['String']['output'];
  numberOfSeats: Scalars['Int']['output'];
  orderId?: Maybe<Scalars['String']['output']>;
  partnerRequest?: Maybe<Scalars['Boolean']['output']>;
  payerAccountId: Scalars['String']['output'];
  pricePerSeat: Scalars['Float']['output'];
  sfdcOpportunityId?: Maybe<Scalars['String']['output']>;
  signInMethod: SignInMethod;
  simId: Scalars['String']['output'];
  status: SbtsStatus;
  subscriptionEndDate?: Maybe<Scalars['AWSDateTime']['output']>;
  subscriptionStartDate?: Maybe<Scalars['AWSDateTime']['output']>;
  userId: Scalars['String']['output'];
  version: Scalars['Int']['output'];
  vibeId?: Maybe<Scalars['String']['output']>;
};

export type SubscriptionRequestByUserIdOutput = {
  __typename?: 'SubscriptionRequestByUserIdOutput';
  nodes: Array<SubscriptionRequest>;
  pageInfo?: Maybe<PageInfo>;
};

export type SystemUserGroup = {
  __typename?: 'SystemUserGroup';
  auditMetadata: AuditMetadata;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /**
   * List of users belonging to a Group.
   *
   * Maximum page size is 100. Higher page sizes will be capped at 100.
   */
  userAssociations?: Maybe<UserAssociationConnection>;
  userCount?: Maybe<Scalars['Int']['output']>;
  vendorId?: Maybe<Scalars['String']['output']>;
};


export type SystemUserGroupUserAssociationsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type SystemUserGroupConnection = {
  __typename?: 'SystemUserGroupConnection';
  nodes: Array<SystemUserGroup>;
  pageInfo?: Maybe<PageInfo>;
};

export type Tag = {
  __typename?: 'Tag';
  domain: Scalars['String']['output'];
  tagName: Scalars['String']['output'];
};

export type TagInput = {
  domain: Scalars['String']['input'];
  tagName: Scalars['String']['input'];
};

export type Team = {
  __typename?: 'Team';
  auditMetadata: AuditMetadata;
  description?: Maybe<Scalars['String']['output']>;
  /**
   * List of groups belonging to a Team.
   *
   * Maximum page size is 100. Higher page sizes will be capped at 100.
   */
  entityUserGroupAssociations?: Maybe<EntityUserGroupAssociationConnection>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organization?: Maybe<Organization>;
  teamSettings?: Maybe<TeamSettings>;
  /**
   * List of users belonging to a Team.
   *
   * Maximum page size is 100. Higher page sizes will be capped at 100.
   */
  userAssociations?: Maybe<UserAssociationConnection>;
  userCount?: Maybe<UserCount>;
  vendorId?: Maybe<Scalars['String']['output']>;
};


export type TeamEntityUserGroupAssociationsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type TeamUserAssociationsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type TeamAssociation = {
  __typename?: 'TeamAssociation';
  auditMetadata: AuditMetadata;
  parentResourceId: Scalars['String']['output'];
  parentResourceType: AssociationResourceType;
  team: Team;
};

export type TeamAssociationConnection = {
  __typename?: 'TeamAssociationConnection';
  nodes: Array<TeamAssociation>;
  pageInfo?: Maybe<PageInfo>;
};

export type TeamConnection = {
  __typename?: 'TeamConnection';
  nodes: Array<Team>;
  pageInfo?: Maybe<PageInfo>;
};

export type TeamSettings = Settings & {
  __typename?: 'TeamSettings';
  autoAssignDigitalSubscription?: Maybe<Scalars['Boolean']['output']>;
};

export type TeamSettingsInput = {
  autoAssignDigitalSubscription?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TerminateLicenseInput = {
  id: Scalars['ID']['input'];
  terminationDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  terminationReason?: InputMaybe<Scalars['String']['input']>;
};

export type TerritoryPrice = {
  __typename?: 'TerritoryPrice';
  pricePerSeat: Scalars['Float']['output'];
  territory: Scalars['String']['output'];
};

/** Used in some locations to configure how a text block is rendered */
export type TextSection = {
  __typename?: 'TextSection';
  /** inline links to add to text */
  inlineLinks: Array<LinkConfig>;
  /** Style of how term should be rendered (e.g., BOLD or NORMAL) */
  style: TextStyle;
  /** Text of term */
  text: Scalars['String']['output'];
};

export type TextSectionInput = {
  inlineLinks?: InputMaybe<Array<LinkConfigInput>>;
  style: TextStyle;
  text: Scalars['String']['input'];
};

/**
 * Used in some locations to configure how a text block is rendered.
 *
 * Currently only applicable for "terms", will be ignored for other fields
 */
export enum TextStyle {
  Bold = 'BOLD',
  Normal = 'NORMAL'
}

export type TrainingAssignment = {
  __typename?: 'TrainingAssignment';
  auditMetadata: AuditMetadata;
  catalogItem?: Maybe<CatalogItem>;
  catalogRootItem: CatalogRootItem;
  id: Scalars['ID']['output'];
  organization: Organization;
  team?: Maybe<Team>;
  trainingAssignmentStatus: TrainingAssignmentStatus;
  user: User;
};

export type TrainingAssignmentConnection = {
  __typename?: 'TrainingAssignmentConnection';
  nodes?: Maybe<Array<Maybe<TrainingAssignment>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type TrainingAssignmentInput = {
  authorizationActionType?: InputMaybe<AuthorizationActionType>;
  catalogItemId?: InputMaybe<Scalars['ID']['input']>;
  catalogRootItemId: Scalars['ID']['input'];
  licenseAllocationId?: InputMaybe<Scalars['ID']['input']>;
  orgId?: InputMaybe<Scalars['ID']['input']>;
  teamId?: InputMaybe<Scalars['ID']['input']>;
  userIds: Array<Scalars['ID']['input']>;
};

export type TrainingAssignmentResult = {
  __typename?: 'TrainingAssignmentResult';
  reasonCode?: Maybe<TrainingAssignmentStatusReasonCode>;
  status: TrainingAssignmentResultStatus;
  user: User;
};

export enum TrainingAssignmentResultStatus {
  AssignmentFailure = 'ASSIGNMENT_FAILURE',
  AssignmentSuccess = 'ASSIGNMENT_SUCCESS'
}

export enum TrainingAssignmentStatus {
  Assigned = 'ASSIGNED',
  Unassigned = 'UNASSIGNED'
}

export enum TrainingAssignmentStatusReasonCode {
  AssignmentAlreadyExists = 'ASSIGNMENT_ALREADY_EXISTS',
  AssignmentFailed = 'ASSIGNMENT_FAILED',
  InvalidUser = 'INVALID_USER',
  NoLicenseAvailable = 'NO_LICENSE_AVAILABLE',
  Success = 'SUCCESS'
}

/** Data needed to assign a training to users in a system group */
export type TrainingAssignmentSystemGroupInput = {
  catalogItemId?: InputMaybe<Scalars['ID']['input']>;
  catalogRootItemId: Scalars['ID']['input'];
  systemGroupId: Scalars['ID']['input'];
};

export type TrainingAssignmentTask = {
  __typename?: 'TrainingAssignmentTask';
  auditMetadata: AuditMetadata;
  failureCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  status: TrainingAssignmentTaskStatus;
  successCount: Scalars['Int']['output'];
};

export type TrainingAssignmentTaskConnection = {
  __typename?: 'TrainingAssignmentTaskConnection';
  nodes?: Maybe<Array<Maybe<TrainingAssignmentTask>>>;
  pageInfo?: Maybe<PageInfo>;
};

export enum TrainingAssignmentTaskStatus {
  Completed = 'COMPLETED',
  Created = 'CREATED',
  InProgress = 'IN_PROGRESS'
}

export type TrainingAssignmentUserGroupInput = {
  catalogItemId?: InputMaybe<Scalars['ID']['input']>;
  catalogRootItemId: Scalars['ID']['input'];
  licenseAllocationId?: InputMaybe<Scalars['ID']['input']>;
  orgId: Scalars['ID']['input'];
  teamId?: InputMaybe<Scalars['ID']['input']>;
  userGroupId: Scalars['ID']['input'];
};

export type Transcript = {
  __typename?: 'Transcript';
  auditMetadata: AuditMetadata;
  completionDate: Scalars['AWSDateTime']['output'];
  id: Scalars['ID']['output'];
  learningActivity: LearningActivity;
  transcriptStatus: TranscriptStatus;
  user: User;
};

export type TranscriptBase = {
  auditMetadata: AuditMetadata;
  completionDate?: Maybe<Scalars['AWSDateTime']['output']>;
  id: Scalars['ID']['output'];
  learner: User;
  program: ProgramType;
};

export enum TranscriptCatalogItemCategory {
  Course = 'COURSE',
  LearningPlan = 'LEARNING_PLAN'
}

export enum TranscriptCatalogItemModality {
  Blended = 'BLENDED',
  Digital = 'DIGITAL',
  Ilt = 'ILT'
}

export enum TranscriptCatalogItemType {
  AwsCloudInstituteCourse = 'aws_cloud_institute_course',
  AwsCloudInstituteDigitalCourse = 'aws_cloud_institute_digital_course',
  DigitalCourse = 'digital_course',
  LearningPlan = 'learning_plan'
}

export type TranscriptConnection = {
  __typename?: 'TranscriptConnection';
  nodes: Array<Transcript>;
  pageInfo?: Maybe<PageInfo>;
};

/**
 * Notes:
 * TransriptConnectionV1 used for Homecoming Discover phase
 * This status will be deprecated and replaced to the newer version in the next phase
 */
export type TranscriptConnectionV1 = {
  __typename?: 'TranscriptConnectionV1';
  nodes: Array<TranscriptV1>;
  pageInfo?: Maybe<PageInfo>;
};

export type TranscriptEntity = AciCourseLoadTranscript | DigitalTranscript;

export enum TranscriptStatus {
  Attended = 'ATTENDED',
  NoShow = 'NO_SHOW',
  PartiallyAttended = 'PARTIALLY_ATTENDED'
}

/**
 * Notes:
 * TransriptStatusV1 used for Homecoming Discover phase
 * This status will be deprecated and replaced to the newer version in the next phase
 *
 * Enum listing all the used enrollment status values in vendor
 */
export enum TranscriptStatusV1 {
  /** Course completed */
  Completed = 'COMPLETED',
  /** Registered and ready to begin */
  Enrolled = 'ENROLLED',
  /** Pending invitation acceptance for live sessions */
  EnrollmentToConfirm = 'ENROLLMENT_TO_CONFIRM',
  /** Learning in progress */
  InProgress = 'IN_PROGRESS',
  /** In overbooking status */
  Overbooking = 'OVERBOOKING',
  /** Course is no longer available (e.g. suspended subscription) */
  Suspended = 'SUSPENDED',
  /** Waiting for seat in a fixed capacity course */
  WaitingUsers = 'WAITING_USERS'
}

export type TranscriptV1 = {
  __typename?: 'TranscriptV1';
  auditMetadata: AuditMetadata;
  catalogItem?: Maybe<CatalogItem>;
  completedDate?: Maybe<Scalars['String']['output']>;
  coursesCompleted?: Maybe<Scalars['Int']['output']>;
  dateAssigned?: Maybe<Scalars['String']['output']>;
  enrollmentDate?: Maybe<Scalars['String']['output']>;
  firstAccessDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastAccessDate?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['String']['output']>;
  status: TranscriptStatusV1;
  userVibeID?: Maybe<Scalars['String']['output']>;
};

export type TransferAssociationsInput = {
  associationStatus?: InputMaybe<UserAssociationStatus>;
  destinationUserId: Scalars['ID']['input'];
  sourceUserId: Scalars['ID']['input'];
};

export enum TransferStatus {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Succeeded = 'SUCCEEDED'
}

export type TransferStatusOutput = {
  __typename?: 'TransferStatusOutput';
  failedSteps?: Maybe<Array<TransferStep>>;
  transferStatus: TransferStatus;
};

export enum TransferStep {
  DeleteSourceUser = 'DELETE_SOURCE_USER',
  DigitalAciTranscript = 'DIGITAL_ACI_TRANSCRIPT',
  Evaluations = 'EVALUATIONS',
  IltAttendance = 'ILT_ATTENDANCE',
  IltTranscript = 'ILT_TRANSCRIPT',
  InactivateSourceUser = 'INACTIVATE_SOURCE_USER',
  OrgGroups = 'ORG_GROUPS',
  Registration = 'REGISTRATION',
  RemoveSourceUserFromOrg = 'REMOVE_SOURCE_USER_FROM_ORG',
  Rewards = 'REWARDS',
  SbtSeats = 'SBT_SEATS',
  SystemGroups = 'SYSTEM_GROUPS',
  Teams = 'TEAMS',
  TrainingAssignment = 'TRAINING_ASSIGNMENT'
}

export type TransferUserDataInput = {
  inactivateSource: Scalars['Boolean']['input'];
  sourceUserId: Scalars['String']['input'];
  targetUserId: Scalars['String']['input'];
};

export type TransferUserDataOutput = {
  __typename?: 'TransferUserDataOutput';
  id: Scalars['ID']['output'];
};

export enum TypeOfContract {
  MidTermAddition = 'MID_TERM_ADDITION',
  New = 'NEW',
  Renewal = 'RENEWAL',
  Upgrade = 'UPGRADE'
}

export type UnarchiveCatalogItemInput = {
  id: Scalars['ID']['input'];
};

export type UnarchiveFileMetadataInput = {
  id: Scalars['ID']['input'];
};

export type UnarchiveLearningContainerInput = {
  id: Scalars['ID']['input'];
};

export type UnassignLicenseFromUserInput = {
  id: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type UnknownPrincipal = {
  __typename?: 'UnknownPrincipal';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type UpdateActivityStatus = {
  newStatus: ActivityStatus;
  pk: Scalars['ID']['input'];
};

export type UpdateAssessmentUserInput = {
  id: Scalars['ID']['input'];
  programs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCatalogCustomAttributeSchemaInput = {
  defaultValuesOrOptions?: InputMaybe<Array<Scalars['String']['input']>>;
  key: Scalars['String']['input'];
  keyDisplayStrings: Array<CatalogLocalizedStringInput>;
  namespaceId: Scalars['String']['input'];
  selectOptions?: InputMaybe<Array<CatalogCustomAttributeSchemaSelectOptionInput>>;
};

export type UpdateCatalogGroupInput = {
  group: CatalogGroupInput;
  id: Scalars['ID']['input'];
};

/** Input for updating a catalog item version */
export type UpdateCatalogItemVersionInput = {
  /** See CatalogItem.accessibilityCompliant */
  accessibilityCompliant?: InputMaybe<Scalars['Boolean']['input']>;
  /** See CatalogItem.activationDate */
  activationDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  /** See CatalogItem.approvedForReuse */
  approvedForReuse?: InputMaybe<Scalars['Boolean']['input']>;
  /** See CatalogItem.awsServices */
  awsServices?: InputMaybe<Array<Scalars['String']['input']>>;
  /** See CatalogItem.badgeImageUrl */
  badgeImageUrl?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.badgeName */
  badgeName?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.badgeTemplateId */
  badgeTemplateId?: InputMaybe<Scalars['String']['input']>;
  /**
   * See CatalogItem.children
   * Use this if the parent should point to any version of the child (i.e., the default version) ; e.g., a learning plan version pointing to a course default version
   * This is allowed for products that contain other products
   */
  childIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /**
   * See CatalogItem.children
   * Use this if the parent should point to a specific version of the child; e.g., a course version pointing to a specific version of a module
   * This is allowed for products that contain modules.
   * For ID "someidentifier" and semantic version 001.000.000, the format of the versioned ID is "someidentifier:001.000.000".
   */
  childVersionedIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** See CatalogItem.completionBadge */
  completionBadge?: InputMaybe<Scalars['Boolean']['input']>;
  /** See CatalogItem.completionCertificate */
  completionCertificate?: InputMaybe<Scalars['Boolean']['input']>;
  /** See CatalogItem.completionCriteria */
  completionCountCriteria?: InputMaybe<CatalogItemCompletionCountCriteriaInput>;
  /** See CatalogItem.completionCriteria */
  completionMarkerCriteria?: InputMaybe<CatalogItemCompletionMarkerCriteriaInput>;
  /** See CatalogItem.completionCriteria */
  completionPercentageCriteria?: InputMaybe<CatalogItemCompletionPercentageCriteriaInput>;
  /** See CatalogItem.contentFiles */
  contentFiles?: InputMaybe<Array<FileEntityIdVersion>>;
  /** See CatalogItem.customAttributes */
  customAttributes?: InputMaybe<Array<CustomAttributeInput>>;
  /** See CatalogItem.description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.devTeam */
  devTeam?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.domain */
  domain?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.duration */
  duration?: InputMaybe<CatalogDurationInput>;
  /** See CatalogItem.evaluationTemplate */
  evaluationTemplate?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.examCertificationImageUrl */
  examCertificationImageUrl?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.examCertificationName */
  examCertificationName?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.expirationDate */
  expirationDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  /** See CatalogItem.goal */
  goal?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.id */
  id: Scalars['ID']['input'];
  /** See CatalogItem.industrySegments */
  industrySegments?: InputMaybe<Array<Scalars['String']['input']>>;
  /** See CatalogItem.industryVerticals */
  industryVerticals?: InputMaybe<Array<Scalars['String']['input']>>;
  /** See CatalogItem.intendedAudience */
  intendedAudience?: InputMaybe<Array<Scalars['String']['input']>>;
  /** See CatalogItem.learningStyles */
  learningStyles?: InputMaybe<Array<Scalars['String']['input']>>;
  /** See CatalogItemMetadataChange */
  metadataChangeToStage?: InputMaybe<CatalogItemMetadataChangeInput>;
  /** See CatalogItem.name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.navigationSettings */
  navigationSettings?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.objectives */
  objectives?: InputMaybe<Array<Scalars['String']['input']>>;
  /** See CatalogItem.organization */
  organization?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.outline */
  outline?: InputMaybe<Scalars['AWSJSON']['input']>;
  /** See CatalogItem.prerequisites */
  prerequisites?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.previewVideoUrl */
  previewVideoUrl?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.productOwners */
  productOwners?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** See CatalogItem.rating */
  rating?: InputMaybe<Scalars['Float']['input']>;
  /** See CatalogItem.roles */
  roles?: InputMaybe<Array<Scalars['String']['input']>>;
  /** See CatalogItem.semanticVersion */
  semanticVersion: SemanticVersionInput;
  /** See CatalogItem.skillLevel */
  skillLevel?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.sku */
  sku?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.sourceFiles */
  sourceFiles?: InputMaybe<Array<FileEntityIdVersion>>;
  /** See CatalogItem.sourceUrls */
  sourceUrls?: InputMaybe<Array<Scalars['AWSURL']['input']>>;
  /** See CatalogItem.systemRequirement */
  systemRequirement?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.tags */
  tags?: InputMaybe<Array<CatalogItemTagInput>>;
  /** See CatalogItem.technologies */
  technologies?: InputMaybe<Array<Scalars['String']['input']>>;
  /** See CatalogItem.thumbnailFile */
  thumbnailFileId?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.thumbnailImageUrl */
  thumbnailImageUrl?: InputMaybe<Scalars['String']['input']>;
  /** See CatalogItem.trainingDeliveryUrl */
  trainingDeliveryUrl?: InputMaybe<Scalars['String']['input']>;
};

/** Input for updating the semantic version number of a catalog item version */
export type UpdateCatalogItemVersionNumberInput = {
  /** The current semantic version of the catalog item version to be updated */
  currentSemanticVersion: SemanticVersionInput;
  /** The unique identifier of the catalog item */
  id: Scalars['ID']['input'];
  /**
   * The new semantic version to be assigned to the catalog item version
   * This must be different from the current version and should follow semantic versioning principles.
   */
  newSemanticVersion: SemanticVersionInput;
};

export type UpdateCatalogNamespaceInput = {
  description: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

/** Input for updating a catalog root item */
export type UpdateCatalogRootItemInput = {
  /** ID of the default variant for this root item */
  defaultVariantId?: InputMaybe<Scalars['ID']['input']>;
  /** Updated description of the root item */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Updated name of the root item */
  name?: InputMaybe<Scalars['String']['input']>;
  /** ID of the root item to update */
  rootId: Scalars['ID']['input'];
};

export type UpdateClassroomInput = {
  capacity?: InputMaybe<CapacityInput>;
  clientRequestToken: Scalars['String']['input'];
  courseId?: InputMaybe<Scalars['String']['input']>;
  endTime?: InputMaybe<Scalars['AWSTimestamp']['input']>;
  instructors?: InputMaybe<Array<InputMaybe<Scalars['AWSEmail']['input']>>>;
  jamConfig?: InputMaybe<JamConfigInput>;
  learningActivityId?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<LocationInput>;
  startTime?: InputMaybe<Scalars['AWSTimestamp']['input']>;
};

export type UpdateEntityUserGroupInput = {
  auditMetadata?: InputMaybe<AuditMetadataInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFileDataInput = {
  contentType?: InputMaybe<ContentType>;
  description: Scalars['String']['input'];
  file: UpdateFileInput;
  isAccessibilityCompliant?: InputMaybe<Scalars['Boolean']['input']>;
  isApprovedForReuse?: InputMaybe<Scalars['Boolean']['input']>;
  languageCode: LanguageCode;
  location?: InputMaybe<Scalars['String']['input']>;
  lxp?: InputMaybe<Lxp>;
  lxpAttributes?: InputMaybe<Array<UserInputWithFieldDefinitionKeyInput>>;
  permission?: InputMaybe<ContentPermissionInput>;
  productOwner?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<InputMaybe<KeyValuePairInput>>>;
  tempLocation?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type UpdateFileEntityInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  extension: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  mimeType: Scalars['String']['input'];
  name: Scalars['String']['input'];
  scopeChangeAndNotes: ScopeChangeAndNotesInput;
  size: MeasuredValueInput;
  tags?: InputMaybe<Array<KeyValuePairInput>>;
};

export type UpdateFileInput = {
  mimeType: Scalars['String']['input'];
  name: Scalars['String']['input'];
  size?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateFileMetadataInput = {
  data: UpdateFileDataInput;
  id: Scalars['ID']['input'];
  newS3Asset?: InputMaybe<Scalars['Boolean']['input']>;
  scopeChangeAndNotes?: InputMaybe<ScopeChangeAndNotesInput>;
};

export type UpdateGroupInput = {
  auditMetadata?: InputMaybe<AuditMetadataInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type UpdateLearningAccountInput = {
  awsAccountId: Scalars['String']['input'];
  awsAssociation?: InputMaybe<AwsAssociation>;
  billingAddress?: InputMaybe<AddressInput>;
  customerSince?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id: Scalars['ID']['input'];
  learningAccountSettings?: InputMaybe<OrganizationSettingsInput>;
  learningAccountVisibilityClassification?: InputMaybe<OrganizationVisibilityClassification>;
  name: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['AWSPhone']['input']>;
  sfdcAccountId?: InputMaybe<Scalars['String']['input']>;
  sfdcParentAccountId?: InputMaybe<Scalars['String']['input']>;
  sfdcParentAccountName?: InputMaybe<Scalars['String']['input']>;
  skillBuilderBranchId?: InputMaybe<Scalars['String']['input']>;
  skillBuilderNumericBranchId?: InputMaybe<Scalars['Int']['input']>;
  status: LearningAccountStatus;
};

export type UpdateLearningContainerInput = {
  data: LearningContainerInput;
  id: Scalars['ID']['input'];
  scopeChangeAndNotes?: InputMaybe<ScopeChangeAndNotesInput>;
};

export type UpdateLicensePolicyInput = {
  actions?: InputMaybe<Array<AuthorizationActionType>>;
  policyId: Scalars['ID']['input'];
  policyStatus: LicensePolicyStatus;
};

export type UpdateLicenseToOrderItemAssociationInput = {
  licenseId: Scalars['ID']['input'];
  newOrderItemId: Scalars['ID']['input'];
  oldOrderItemId: Scalars['ID']['input'];
};

export type UpdateLxpAttributeTemplateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  entity?: InputMaybe<EntityType>;
  fieldDefinitions?: InputMaybe<Array<FieldDefinitionInput>>;
  id: Scalars['ID']['input'];
  lxp?: InputMaybe<Lxp>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOrganizationInput = {
  /**
   * The following attribute(s) are only allowed to be used by SERVICE to SERVICE interactions.
   * They are required for importing of external vendor attributes.
   */
  auditMetadata?: InputMaybe<AuditMetadataInput>;
  awsAccountId?: InputMaybe<Scalars['String']['input']>;
  awsAssociation?: InputMaybe<AwsAssociation>;
  billingAddress?: InputMaybe<AddressInput>;
  customerSince?: InputMaybe<Scalars['AWSDateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  organizationSettings?: InputMaybe<OrganizationSettingsInput>;
  organizationType: OrganizationType;
  organizationVisibilityClassification?: InputMaybe<OrganizationVisibilityClassification>;
  phoneNumber?: InputMaybe<Scalars['AWSPhone']['input']>;
  sfdcAccountId?: InputMaybe<Scalars['String']['input']>;
  sfdcParentAccountId?: InputMaybe<Scalars['String']['input']>;
  sfdcParentAccountName?: InputMaybe<Scalars['String']['input']>;
  status: OrganizationStatus;
  vendorCode?: InputMaybe<Scalars['String']['input']>;
  vendorId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdatePublishingWorkflowInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<InputMaybe<KeyValuePairInput>>>;
  targetPublishDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type UpdatePublishingWorkflowStepValuesInput = {
  publishingWorkflowId: Scalars['ID']['input'];
  publishingWorkflowStepId: Scalars['ID']['input'];
  values: Array<KeyValuePairInput>;
};

export type UpdateRecordedSessionUrl = {
  deliverySessionId: Scalars['ID']['input'];
  recordedSessionUrl: Scalars['String']['input'];
};

export type UpdateSystemUserGroupInput = {
  auditMetadata?: InputMaybe<AuditMetadataInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTeamInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  teamId: Scalars['ID']['input'];
  teamSettings?: InputMaybe<TeamSettingsInput>;
};

export type UpdateTranscriptInput = {
  completedDate?: InputMaybe<Scalars['String']['input']>;
  coursesCompleted?: InputMaybe<Scalars['Int']['input']>;
  enrollmentDate?: InputMaybe<Scalars['String']['input']>;
  firstAccessDate?: InputMaybe<Scalars['String']['input']>;
  lastAccessDate?: InputMaybe<Scalars['String']['input']>;
  lastUpdatedAt: Scalars['String']['input'];
  score?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<TranscriptStatusV1>;
  vendorCatalogItemId: Scalars['Int']['input'];
  vendorCatalogType: Scalars['String']['input'];
  vendorChildCourses?: InputMaybe<Array<Scalars['Int']['input']>>;
  vendorUserId: Scalars['Int']['input'];
};

export type UpdateTransferStepInput = {
  status: TransferStatus;
  statusReason?: InputMaybe<Scalars['String']['input']>;
  step: TransferStep;
  transferId: Scalars['ID']['input'];
};

export type UpdateUserInput = {
  auditMetadata?: InputMaybe<AuditMetadataInput>;
  emailAddress?: InputMaybe<Scalars['AWSEmail']['input']>;
  emailVerifiedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hatId?: InputMaybe<Scalars['String']['input']>;
  identityLinkingCandidate?: InputMaybe<Scalars['Boolean']['input']>;
  is3PLearningAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  lastSignedInAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  partnerAttributes?: InputMaybe<AwsPartnerNetworkAttributeInput>;
  profileCompletionStatus?: InputMaybe<ProfileCompletionStatus>;
  profileInput?: InputMaybe<UpdateUserProfileInput>;
  status?: InputMaybe<UserStatus>;
  statusReason?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
  userRole?: InputMaybe<UserRole>;
  /**
   * The following attributes are only allowed to be used by SERVICE to SERVICE interactions.
   * They are required for importing of external vendor attributes.
   */
  vendorId?: InputMaybe<Scalars['String']['input']>;
  vendorStatus?: InputMaybe<UserStatus>;
  vibeId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserOrganizationAssociationStatusInput = {
  associationStatus: UserAssociationStatus;
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type UpdateUserProfileInput = {
  country?: InputMaybe<Scalars['String']['input']>;
  preferences?: InputMaybe<PreferencesInput>;
  professionalInformation?: InputMaybe<ProfessionalInformationInput>;
  profileCompletionStatus?: InputMaybe<ProfileCompletionStatus>;
  userConsent?: InputMaybe<UserConsentInput>;
  userId: Scalars['ID']['input'];
};

/** User information */
export type User = {
  __typename?: 'User';
  auditMetadata: AuditMetadata;
  country?: Maybe<Scalars['String']['output']>;
  emailAddress: Scalars['AWSEmail']['output'];
  emailAddressCaseSensitive: Scalars['AWSEmail']['output'];
  emailVerifiedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  /** List of all Org/Team associations with user roles based on user access level */
  entityAssociations?: Maybe<UserEntityAssociationConnection>;
  /**
   * List of Entity System User Groups a User belongs to.
   *
   * Maximum page size is 100. Higher page sizes will be capped at 100.
   */
  entityUserGroups?: Maybe<UserEntityUserGroupAssociationConnection>;
  federatedIdentities?: Maybe<Array<FederatedIdentity>>;
  firstName: Scalars['String']['output'];
  gandalfDetails?: Maybe<GandalfDetails>;
  /**
   * List of Groups a User belongs to.
   *
   * Maximum page size is 100. Higher page sizes will be capped at 100.
   */
  groups?: Maybe<GroupConnection>;
  hasAliasEmail?: Maybe<Scalars['Boolean']['output']>;
  /** Unique identifier for the user */
  id: Scalars['ID']['output'];
  identityLinkingCandidate?: Maybe<Scalars['Boolean']['output']>;
  is3PLearningAdmin: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  lastSignedInAt?: Maybe<Scalars['AWSDateTime']['output']>;
  legacyUserId?: Maybe<Scalars['ID']['output']>;
  /** List of Licenses for this User. */
  licenses: LicenseConnection;
  name: Scalars['String']['output'];
  /** Retrieves the open checkout sessions for a given user */
  openCheckoutSessions: CheckoutSessionConnection;
  /** List of orders for this User. */
  orders: OrderConnection;
  /**
   * List of Organizations a User belongs to.
   *
   * Maximum page size is 100. Higher page sizes will be capped at 100.
   */
  organizations?: Maybe<OrganizationConnection>;
  partnerAttributes?: Maybe<AwsPartnerNetworkAttributes>;
  /** List of payments for User */
  payments?: Maybe<PaymentConnection>;
  preferences?: Maybe<Preferences>;
  professionalInformation?: Maybe<ProfessionalInformation>;
  profileCompletedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  profileCompletionStatus?: Maybe<ProfileCompletionStatus>;
  status: UserStatus;
  statusReason?: Maybe<Scalars['String']['output']>;
  /**
   * List of System User Groups a User belongs to.
   *
   * Maximum page size is 100. Higher page sizes will be capped at 100.
   */
  systemUserGroups?: Maybe<UserSystemUserGroupAssociationConnection>;
  userConsent?: Maybe<UserConsent>;
  userRole: UserRole;
  vendorId?: Maybe<Scalars['String']['output']>;
  vendorStatus?: Maybe<UserStatus>;
};


/** User information */
export type UserEntityAssociationsArgs = {
  associationStatus?: InputMaybe<UserAssociationStatus>;
  userAccess?: InputMaybe<UserAccess>;
};


/** User information */
export type UserEntityUserGroupsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


/** User information */
export type UserGroupsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


/** User information */
export type UserLicensesArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  licenseStatus?: InputMaybe<LicenseStatus>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


/** User information */
export type UserOpenCheckoutSessionsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


/** User information */
export type UserOrdersArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


/** User information */
export type UserOrganizationsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


/** User information */
export type UserPaymentsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  input?: InputMaybe<PaymentsFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


/** User information */
export type UserSystemUserGroupsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export enum UserAccess {
  All = 'ALL',
  Management = 'MANAGEMENT',
  Membership = 'MEMBERSHIP'
}

export type UserAssessment = {
  __typename?: 'UserAssessment';
  assessmentId: Scalars['ID']['output'];
  assessmentVersion: Scalars['Int']['output'];
  completionMessage?: Maybe<Scalars['String']['output']>;
  createdBy: Scalars['String']['output'];
  createdTimestamp: Scalars['AWSTimestamp']['output'];
  displaySetting: Scalars['String']['output'];
  endTime?: Maybe<Scalars['AWSTimestamp']['output']>;
  finalScore?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  isCategoryScoreEnabled: Scalars['Boolean']['output'];
  isCopyPasteEnabled: Scalars['Boolean']['output'];
  isCorrectAnswersShown: Scalars['String']['output'];
  isDetailedResultsEnabled: Scalars['String']['output'];
  isFinalScoreEnabled: Scalars['Boolean']['output'];
  isFlaggingEnabled: Scalars['Boolean']['output'];
  isNextBackEnabled: Scalars['Boolean']['output'];
  isNotesEnabled: Scalars['Boolean']['output'];
  isPausingEnabled: Scalars['Boolean']['output'];
  isSkippedQuestionIncorrect: Scalars['Boolean']['output'];
  isSubmittedResponsesShown: Scalars['String']['output'];
  language: Scalars['String']['output'];
  maxAttempts: Scalars['Int']['output'];
  modifiedBy: Scalars['String']['output'];
  modifiedTimestamp: Scalars['AWSTimestamp']['output'];
  passingScore: Scalars['Float']['output'];
  pauseCount: Scalars['Int']['output'];
  pauseTime?: Maybe<Scalars['AWSTimestamp']['output']>;
  questions?: Maybe<Array<AssessmentQuestion>>;
  questionsAnsweredCount?: Maybe<Scalars['Int']['output']>;
  questionsTotalCount?: Maybe<Scalars['Int']['output']>;
  recordType: Scalars['String']['output'];
  scoreDisplay: Scalars['String']['output'];
  scoresByLearningObjective?: Maybe<Array<Maybe<LearningObjectiveScore>>>;
  startTime?: Maybe<Scalars['AWSTimestamp']['output']>;
  status: Scalars['String']['output'];
  timeLimit?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
  totalTimePaused: Scalars['Int']['output'];
  totalTimeSpent: Scalars['Int']['output'];
  type: Scalars['String']['output'];
  url: Scalars['String']['output'];
  userAnswers: Array<Maybe<UserAssessmentAnswer>>;
  userId: Scalars['ID']['output'];
  version: Scalars['Int']['output'];
  waitTime: Scalars['Int']['output'];
};

export type UserAssessmentAnswer = {
  __typename?: 'UserAssessmentAnswer';
  isFlagged?: Maybe<Scalars['Boolean']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  questionId: Scalars['ID']['output'];
  questionVersion: Scalars['Int']['output'];
  score?: Maybe<Scalars['Float']['output']>;
  selectedAnswerIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
};

export type UserAssessmentInput = {
  assessmentId: Scalars['ID']['input'];
  assessmentVersion?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<AssessmentLanguage>;
  userId: Scalars['ID']['input'];
};

export type UserAssessmentQueryInput = {
  assessmentId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type UserAssociatedEntity = Organization | Team;

export type UserAssociation = {
  __typename?: 'UserAssociation';
  associationStatus?: Maybe<UserAssociationStatus>;
  auditMetadata: AuditMetadata;
  parentResourceId: Scalars['String']['output'];
  parentResourceType: AssociationResourceType;
  user: User;
  userRoles?: Maybe<Array<Role>>;
};

export type UserAssociationConnection = {
  __typename?: 'UserAssociationConnection';
  nodes: Array<UserAssociation>;
  pageInfo?: Maybe<PageInfo>;
};

export enum UserAssociationStatus {
  Active = 'ACTIVE',
  Invited = 'INVITED',
  InvitedRejected = 'INVITED_REJECTED'
}

/**
 * Details of a bulk operation.
 *
 * inputFileName: name of the file the user provided
 * errorCode: will only be populated if the status is FAILED, and will provide additional information on the failure
 * requestor: in the case of an operation being triggered by our system user, instead of an admin user, this will not be populated
 * inputFileUploadUrl: the location where the user should provide the file - will only be populated on the initial creation of the operation
 * processDetails: will not be populated while the operation is in a PENDING state.
 * report: a downloadable report detailing overall operation details, and per user, sub-operation details. The report will only be populated
 *         when the operation has reached a terminal status
 */
export type UserBulkOperation = IUserBulkOperation & {
  __typename?: 'UserBulkOperation';
  auditMetadata: AuditMetadata;
  context: UserBulkOperationContext;
  contextDetails: UserBulkOperationContextDetails;
  errorCode?: Maybe<Scalars['String']['output']>;
  inputFileMetadata: BulkOperationFileMetadata;
  inputFileUploadUrl?: Maybe<Scalars['String']['output']>;
  operationId: Scalars['ID']['output'];
  processDetails?: Maybe<UserBulkOperationProcessDetails>;
  report?: Maybe<UserBulkOperationReport>;
  requestor?: Maybe<User>;
  status: UserBulkOperationStatus;
};

export type UserBulkOperationConnection = {
  __typename?: 'UserBulkOperationConnection';
  nodes: Array<Maybe<UserBulkOperationLite>>;
  pageInfo?: Maybe<PageInfo>;
};

/**
 * Users can be imported into and operated on from one of these contexts when doing a bulk operation.
 * - SYSTEM: a non org specific operation where users are imported into a B2I org and optionally, system user groups.
 * - ORGANIZATION: an org specific operation where users are imported into an org, a team within that org, optionally,
 *   and entity user groups belonging to the org or selected team
 * - TEAM: a team specific operation where users are imported into the team, its owning org, and optionally entity
 *   user groups belonging to the team
 */
export enum UserBulkOperationContext {
  Organization = 'ORGANIZATION',
  System = 'SYSTEM',
  Team = 'TEAM'
}

export type UserBulkOperationContextDetails = {
  __typename?: 'UserBulkOperationContextDetails';
  entityUserGroupsIds?: Maybe<Array<Scalars['ID']['output']>>;
  organizationId?: Maybe<Scalars['ID']['output']>;
  organizationType?: Maybe<UserBulkSystemOperationOrganizationType>;
  systemUserGroupIds?: Maybe<Array<Scalars['ID']['output']>>;
  team?: Maybe<Team>;
  teamId?: Maybe<Scalars['ID']['output']>;
};

/**
 * The details provided here must agree with the context.
 * - SYSTEM: organizationType is required, systemUserGroupIds are optional - all other inputs ignored
 * - ORGANIZATION: organizationId is required, teamId and entityUserGroupsIds are optional - all other inputs ignored
 * - TEAM: organizationId, teamId are required, entityUserGroupsIds are optional - all other inputs ignored
 */
export type UserBulkOperationContextDetailsInput = {
  entityUserGroupsIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  organizationType?: InputMaybe<UserBulkSystemOperationOrganizationType>;
  systemUserGroupIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  teamId?: InputMaybe<Scalars['ID']['input']>;
};

export type UserBulkOperationFileConnection = {
  __typename?: 'UserBulkOperationFileConnection';
  nodes: Array<BulkOperationFile>;
  pageInfo?: Maybe<PageInfo>;
};

/** Contents of the file template will differ depending on the context of the operation. */
export type UserBulkOperationFileTemplate = {
  __typename?: 'UserBulkOperationFileTemplate';
  downloadUrl: Scalars['String']['output'];
};

/** Provides caller the ability to filter a list of bulk operations. */
export type UserBulkOperationFilterInput = {
  inputFileName: Scalars['String']['input'];
};

/**
 * To initiate a bulk operation, information about the context of the operation
 * and the input file must be provided.
 */
export type UserBulkOperationInput = {
  context: UserBulkOperationContext;
  fileMetadata: BulkOperationFileMetadataInput;
  operationContextDetails: UserBulkOperationContextDetailsInput;
};

/**
 * A watered down version of a bulk operation.
 * This supports the use case of listing all operations for a context.
 * No urls are included in this version.
 */
export type UserBulkOperationLite = IUserBulkOperation & {
  __typename?: 'UserBulkOperationLite';
  auditMetadata: AuditMetadata;
  context: UserBulkOperationContext;
  contextDetails: UserBulkOperationContextDetails;
  inputFileMetadata: BulkOperationFileMetadata;
  operationId: Scalars['ID']['output'];
  processDetails: UserBulkOperationProcessDetails;
  reportIsExpired: Scalars['Boolean']['output'];
  requestor?: Maybe<User>;
  status: UserBulkOperationStatus;
};

/** Information about the progress of an operation. */
export type UserBulkOperationProcessDetails = {
  __typename?: 'UserBulkOperationProcessDetails';
  failedRows: Scalars['Int']['output'];
  processedRows: Scalars['Int']['output'];
  successfulRows: Scalars['Int']['output'];
  totalRows: Scalars['Int']['output'];
};

/**
 * Reports exprire after 30 days, once the report has expired, isExpired will
 * be true and reportUrl will no longer be populated.
 */
export type UserBulkOperationReport = {
  __typename?: 'UserBulkOperationReport';
  isExpired?: Maybe<Scalars['Boolean']['output']>;
  reportUrl?: Maybe<Scalars['String']['output']>;
};

/**
 * The status describes the state of the operation. A operation will be in pending
 * until a user provides an input file. The upload will trigger the start of the
 * operation and it will move to in progress. From there, the opration can fail,
 * meaning the job was unable to process the provided users, succeed, meaning all
 * users were processed successfully, or partially succeed, meaning some users
 * were processed successfully, but not all were. The resulting report can provide
 * details on each user.
 *
 * Status flow:
 *           PENDING
 *              |
 *         IN_PROGRESS
 *        /     |     \
 *       /      |      \
 * SUCCEEDED  FAILED  PARTIALLY_SUCCEEDED
 */
export enum UserBulkOperationStatus {
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  PartiallySucceeded = 'PARTIALLY_SUCCEEDED',
  Pending = 'PENDING',
  Succeeded = 'SUCCEEDED'
}

export type UserBulkOperationsInput = {
  context: UserBulkOperationContext;
  filter?: InputMaybe<UserBulkOperationFilterInput>;
  operationContextDetails: UserBulkOperationContextDetailsInput;
};

/** The type of org, an operation with context SYSTEM, is importing users into. */
export enum UserBulkSystemOperationOrganizationType {
  CustomersB2I = 'CUSTOMERS_B2I',
  PartnersB2I = 'PARTNERS_B2I'
}

export type UserConnection = {
  __typename?: 'UserConnection';
  nodes: Array<User>;
  pageInfo?: Maybe<PageInfo>;
};

export type UserConsent = {
  __typename?: 'UserConsent';
  eulaConsent?: Maybe<ConsentOutcome>;
  organizationMembership?: Maybe<ConsentOutcome>;
  shareTrainingData?: Maybe<ConsentOutcome>;
};

export type UserConsentInput = {
  eulaConsent?: InputMaybe<ConsentOutcome>;
  organizationMembership?: InputMaybe<ConsentOutcome>;
  shareTrainingData?: InputMaybe<ConsentOutcome>;
};

export type UserCount = {
  __typename?: 'UserCount';
  invitedUserCount?: Maybe<Scalars['Int']['output']>;
  totalUserCount?: Maybe<Scalars['Int']['output']>;
};

export type UserEntityAssociation = {
  __typename?: 'UserEntityAssociation';
  associationStatus?: Maybe<UserAssociationStatus>;
  parentResource: UserAssociatedEntity;
  userRoles?: Maybe<Array<Role>>;
};

export type UserEntityAssociationConnection = {
  __typename?: 'UserEntityAssociationConnection';
  nodes: Array<UserEntityAssociation>;
  pageInfo?: Maybe<PageInfo>;
};

export type UserEntityDetails = {
  __typename?: 'UserEntityDetails';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  roles?: Maybe<Array<Scalars['String']['output']>>;
};

export type UserEntityUserGroupAssociation = {
  __typename?: 'UserEntityUserGroupAssociation';
  auditMetadata: AuditMetadata;
  entityUserGroup: EntityUserGroup;
};

export type UserEntityUserGroupAssociationConnection = {
  __typename?: 'UserEntityUserGroupAssociationConnection';
  nodes: Array<UserEntityUserGroupAssociation>;
  pageInfo?: Maybe<PageInfo>;
};

export type UserInputWithFieldDefinitionKey = {
  __typename?: 'UserInputWithFieldDefinitionKey';
  key: Scalars['String']['output'];
  userInput: Scalars['AWSJSON']['output'];
};

export type UserInputWithFieldDefinitionKeyInput = {
  key: Scalars['String']['input'];
  userInput: Scalars['AWSJSON']['input'];
};

export type UserInvitationInput = {
  email: Scalars['AWSEmail']['input'];
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  roles: Array<RoleName>;
  teamIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type UserLicenseAssignmentResult = {
  __typename?: 'UserLicenseAssignmentResult';
  status: AssignmentStatus;
  user: User;
};

export enum UserOperationStatus {
  Failed = 'FAILED',
  Processing = 'PROCESSING',
  Succeeded = 'SUCCEEDED'
}

export enum UserRole {
  IltInstructor = 'ILT_INSTRUCTOR',
  InternalEmployee = 'INTERNAL_EMPLOYEE',
  Learner = 'LEARNER',
  LearningAccountItContact = 'LEARNING_ACCOUNT_IT_CONTACT',
  LearningAdmin = 'LEARNING_ADMIN',
  Service = 'SERVICE',
  SuperUser = 'SUPER_USER',
  TrainingOperations = 'TRAINING_OPERATIONS'
}

export enum UserStatus {
  Active = 'ACTIVE',
  Deactivated = 'DEACTIVATED',
  Deleted = 'DELETED',
  Pending = 'PENDING',
  Suspended = 'SUSPENDED'
}

export type UserStatusInput = {
  status: UserStatus;
  statusReason?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

export type UserSubscriptionDetails = {
  __typename?: 'UserSubscriptionDetails';
  assignedEntityId?: Maybe<Scalars['String']['output']>;
  expiration?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  licenseType?: Maybe<SearchAllUsersLicenseType>;
};

export type UserSystemUserGroupAssociation = {
  __typename?: 'UserSystemUserGroupAssociation';
  auditMetadata: AuditMetadata;
  systemUserGroup: SystemUserGroup;
};

export type UserSystemUserGroupAssociationConnection = {
  __typename?: 'UserSystemUserGroupAssociationConnection';
  nodes: Array<UserSystemUserGroupAssociation>;
  pageInfo?: Maybe<PageInfo>;
};

export type UserTeamInvitationInput = {
  email: Scalars['AWSEmail']['input'];
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  roles: Array<RoleName>;
  teamId: Scalars['ID']['input'];
};

export type VIltInfo = {
  __typename?: 'VIltInfo';
  recording_url?: Maybe<Scalars['String']['output']>;
  type?: Maybe<VIltType>;
  url?: Maybe<Scalars['String']['output']>;
};

export enum VIltType {
  Pvilt = 'PVILT',
  WebEx = 'WebEx'
}

export enum VirtualLocationSource {
  Default = 'DEFAULT',
  Lads = 'LADS'
}

export type VoicePhones = {
  __typename?: 'VoicePhones';
  primaryPhone?: Maybe<Phone>;
};

export type VoicePhonesInput = {
  primaryPhone?: InputMaybe<PhoneInput>;
};

export enum WaitlistEnabled {
  Automatic = 'Automatic',
  Disabled = 'Disabled',
  Manual = 'Manual'
}

/** Workflow information for an entity */
export type Workflow = {
  __typename?: 'Workflow';
  assignedTo?: Maybe<Scalars['String']['output']>;
  auditLog?: Maybe<WorkflowAuditLogConnection>;
  auditMetadata: AuditMetadata;
  /** Associated catalog item for this workflow */
  catalogItem?: Maybe<CatalogItem>;
  currentWorkflowStep?: Maybe<WorkflowStep>;
  description?: Maybe<Scalars['String']['output']>;
  /** ID of the entity */
  entityId: Scalars['ID']['output'];
  /** Name of the entity */
  entityName: Scalars['String']['output'];
  /** Version of the entity */
  entityVersion?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  status: WorkflowStatus;
  templateId: Scalars['ID']['output'];
  values?: Maybe<Array<Maybe<UserInputWithFieldDefinitionKey>>>;
  watchers?: Maybe<Array<Scalars['String']['output']>>;
  workflowSteps: Array<WorkflowStep>;
};


/** Workflow information for an entity */
export type WorkflowAuditLogArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type WorkflowAuditLog = {
  __typename?: 'WorkflowAuditLog';
  actionBy: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  timestamp: Scalars['AWSDateTime']['output'];
  value: Scalars['AWSJSON']['output'];
};

export type WorkflowAuditLogConnection = {
  __typename?: 'WorkflowAuditLogConnection';
  nodes: Array<WorkflowAuditLog>;
  pageInfo?: Maybe<PageInfo>;
};

export type WorkflowConnection = {
  __typename?: 'WorkflowConnection';
  nodes: Array<Workflow>;
  pageInfo?: Maybe<PageInfo>;
};

export type WorkflowFilterInput = {
  operation: WorkflowFilterOperationType;
  tokens: Array<WorkflowFilterToken>;
};

export enum WorkflowFilterOperationType {
  And = 'AND',
  Or = 'OR'
}

export enum WorkflowFilterOperator {
  BeginWith = 'BEGIN_WITH',
  Contain = 'CONTAIN',
  EqualTo = 'EQUAL_TO',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  NotBeginWith = 'NOT_BEGIN_WITH',
  NotContain = 'NOT_CONTAIN',
  NotEqualTo = 'NOT_EQUAL_TO'
}

export type WorkflowFilterToken = {
  operator: WorkflowFilterOperator;
  propertyKey: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type WorkflowInput = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  entityName: Scalars['String']['input'];
  filter?: InputMaybe<WorkflowFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export enum WorkflowStatus {
  Accepted = 'ACCEPTED',
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Rejected = 'REJECTED',
  Submitted = 'SUBMITTED'
}

export type WorkflowStep = {
  __typename?: 'WorkflowStep';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  integrationStepId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  simLink?: Maybe<Scalars['String']['output']>;
  status: WorkflowStepStatus;
  statusReason?: Maybe<Scalars['String']['output']>;
  type: WorkflowStepType;
  values?: Maybe<Array<UserInputWithFieldDefinitionKey>>;
};

export enum WorkflowStepStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Initial = 'INITIAL',
  InProgress = 'IN_PROGRESS'
}

export enum WorkflowStepType {
  Approval = 'APPROVAL',
  CreateSim = 'CREATE_SIM',
  CreateTicket = 'CREATE_TICKET',
  Integration = 'INTEGRATION',
  Manual = 'MANUAL'
}

export type CreateOutOfCycleBillInput = {
  agreementId: Scalars['String']['input'];
  billAccountId: Scalars['String']['input'];
  billDate: Scalars['AWSDateTime']['input'];
  customTaxAddress?: InputMaybe<CustomTaxAddressInput>;
  description: Scalars['String']['input'];
  lineItems: Array<InputMaybe<LineItemInput>>;
  purchaseOrder?: InputMaybe<PurchaseOrderInput>;
  sellerOfRecordLegalName?: InputMaybe<Scalars['String']['input']>;
  serviceCode: Scalars['String']['input'];
  submittedBy?: InputMaybe<EmailProfileInput>;
};

export type CustomTaxAddress = {
  __typename?: 'customTaxAddress';
  attributes?: Maybe<Array<Maybe<Attribute>>>;
  contactPurposes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  emailAddresses?: Maybe<EmailProfile>;
  faxPhone?: Maybe<Phone>;
  label?: Maybe<Scalars['String']['output']>;
  mailingAddress: MailingAddress;
  name: Name;
  ownerCustomerId: Scalars['String']['output'];
  skipMailingAddressValidation?: Maybe<Scalars['Boolean']['output']>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  voicePhones?: Maybe<VoicePhones>;
};

export type CustomTaxAddressInput = {
  attributes?: InputMaybe<Array<InputMaybe<AttributeInput>>>;
  contactPurposes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  emailAddresses?: InputMaybe<EmailProfileInput>;
  faxPhone?: InputMaybe<PhoneInput>;
  label?: InputMaybe<Scalars['String']['input']>;
  mailingAddress: MailingAddressInput;
  name: NameInput;
  skipMailingAddressValidation?: InputMaybe<Scalars['Boolean']['input']>;
  tags?: InputMaybe<Array<InputMaybe<TagInput>>>;
  voicePhones?: InputMaybe<VoicePhonesInput>;
};

export type DownloadCompletionCertificateByRewardIdInput = {
  /** Id of the completion certificate reward */
  rewardId: Scalars['ID']['input'];
};

export enum Join__Graph {
  Awstcassessmentsservice = 'AWSTCASSESSMENTSSERVICE',
  Awstcdigitaltms = 'AWSTCDIGITALTMS',
  Awstcevaluationservice = 'AWSTCEVALUATIONSERVICE',
  Awstclearningrecordservice = 'AWSTCLEARNINGRECORDSERVICE',
  Awstcrecommendations = 'AWSTCRECOMMENDATIONS',
  Awstcreviewservice = 'AWSTCREVIEWSERVICE',
  Awstcrewardservice = 'AWSTCREWARDSERVICE',
  Awstcsbtsservice = 'AWSTCSBTSSERVICE',
  Awstcskillbuildersearch = 'AWSTCSKILLBUILDERSEARCH',
  Beakerclassroomsservice = 'BEAKERCLASSROOMSSERVICE',
  Catalogservice = 'CATALOGSERVICE',
  Cdkconstructsplaygroundservice = 'CDKCONSTRUCTSPLAYGROUNDSERVICE',
  Checkoutservice = 'CHECKOUTSERVICE',
  Commentservice = 'COMMENTSERVICE',
  Contentmanagementservice = 'CONTENTMANAGEMENTSERVICE',
  Contentpublishingworkflow = 'CONTENTPUBLISHINGWORKFLOW',
  Devdemoservice = 'DEVDEMOSERVICE',
  Fulfillmentservice = 'FULFILLMENTSERVICE',
  Helloworldservice = 'HELLOWORLDSERVICE',
  Holamundoservice = 'HOLAMUNDOSERVICE',
  Iltattendancemanagementservice = 'ILTATTENDANCEMANAGEMENTSERVICE',
  Iltlearningactivitydataservice = 'ILTLEARNINGACTIVITYDATASERVICE',
  Iltregistrationmanagementservice = 'ILTREGISTRATIONMANAGEMENTSERVICE',
  Ilttranscriptmanagementservice = 'ILTTRANSCRIPTMANAGEMENTSERVICE',
  Learningaccountservice = 'LEARNINGACCOUNTSERVICE',
  Licenseservice = 'LICENSESERVICE',
  Nonmeteredbillingservice = 'NONMETEREDBILLINGSERVICE',
  Notificationservice = 'NOTIFICATIONSERVICE',
  Offerservice = 'OFFERSERVICE',
  Orderservice = 'ORDERSERVICE',
  Padawanfulfillmentservice = 'PADAWANFULFILLMENTSERVICE',
  Paymentservice = 'PAYMENTSERVICE',
  Sbairdashboardservice = 'SBAIRDASHBOARDSERVICE',
  Sfdcintegrationservice = 'SFDCINTEGRATIONSERVICE',
  Transcriptservice = 'TRANSCRIPTSERVICE',
  Userservice = 'USERSERVICE'
}

export type LearnerAttendanceInput = {
  attendanceDurationPercentage: Scalars['Int']['input'];
  learner: Scalars['ID']['input'];
};

export enum Link__Purpose {
  /** `EXECUTION` features provide metadata necessary for operation execution. */
  Execution = 'EXECUTION',
  /** `SECURITY` features provide metadata necessary to securely resolve fields. */
  Security = 'SECURITY'
}

export type RewardsByUserIdAndCatalogRootItemIdInput = {
  /** Id of the CatalogRootItem */
  catalogRootItemId: Scalars['ID']['input'];
  /** Optional parameters to filter the response */
  filterOptions?: InputMaybe<FilterOptions>;
  /** UserService User Id of the user */
  userId: Scalars['ID']['input'];
};

export type CreateAssessmentMutationVariables = Exact<{
  title: Scalars['String']['input'];
  status: Scalars['String']['input'];
  passingScore: Scalars['Float']['input'];
  maxAttempts: Scalars['Int']['input'];
  waitTime: Scalars['Int']['input'];
  timeLimit?: InputMaybe<Scalars['Int']['input']>;
  displaySetting: Scalars['String']['input'];
  programs: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
  questions: Array<InputMaybe<AssessmentLevelQuestionInput>> | InputMaybe<AssessmentLevelQuestionInput>;
  learningObjectives: Array<InputMaybe<AssessmentLevelLearningObjectiveInput>> | InputMaybe<AssessmentLevelLearningObjectiveInput>;
  completionMessages: Array<InputMaybe<AssessmentCompletionMessageInput>> | InputMaybe<AssessmentCompletionMessageInput>;
}>;


export type CreateAssessmentMutation = { __typename?: 'Mutation', createAssessment?: { __typename?: 'Assessment', id: string } };

export type CreateAssessmentQuestionMutationVariables = Exact<{
  status: Scalars['String']['input'];
  type: Scalars['String']['input'];
  scoringMethod?: InputMaybe<Scalars['String']['input']>;
  difficulty?: InputMaybe<Scalars['Int']['input']>;
  questionText: Scalars['String']['input'];
  answers: Array<InputMaybe<AssessmentAnswerInput>> | InputMaybe<AssessmentAnswerInput>;
  programs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  questionBanks?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  learningObjectives: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<AssessmentLanguage>;
}>;


export type CreateAssessmentQuestionMutation = { __typename?: 'Mutation', createAssessmentQuestion?: { __typename?: 'AssessmentQuestion', id: string, attachments?: Array<string>, createdBy: string, createdTimestamp: number, difficulty?: number, modifiedBy: string, modifiedTimestamp: number, questionBanks?: Array<string>, programs?: Array<string>, questionText?: string, recordType: string, scoringMethod?: string, status: string, tags?: Array<string>, type: string, version: number, answers: Array<{ __typename?: 'AssessmentAnswer', answerText?: string, explanation?: string, isCorrect?: boolean }> } };

export type CreateLearningObjectiveMutationVariables = Exact<{
  name: Scalars['String']['input'];
  programs: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
  status: Scalars['String']['input'];
  language?: InputMaybe<AssessmentLanguage>;
  associatedMetadata?: InputMaybe<Array<InputMaybe<AssessmentMetadataObjectUpdateAssociationInput>> | InputMaybe<AssessmentMetadataObjectUpdateAssociationInput>>;
}>;


export type CreateLearningObjectiveMutation = { __typename?: 'Mutation', createAssessmentLearningObjective?: { __typename?: 'AssessmentMetadataObject', createdBy: string, createdTimestamp: number, modifiedBy?: string, id: string, modifiedTimestamp?: number, name: string, programs: Array<string>, recordType: string, status: string, version: number, associatedMetadata?: Array<{ __typename?: 'AssessmentMetadataObjectAssociation', id: string, metadataType: string }> } };

export type CreateQuestionBankMutationVariables = Exact<{
  name: Scalars['String']['input'];
  programs: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
  status: Scalars['String']['input'];
  language?: InputMaybe<AssessmentLanguage>;
  associatedMetadata: Array<InputMaybe<AssessmentMetadataObjectUpdateAssociationInput>> | InputMaybe<AssessmentMetadataObjectUpdateAssociationInput>;
}>;


export type CreateQuestionBankMutation = { __typename?: 'Mutation', createAssessmentQuestionBank?: { __typename?: 'AssessmentMetadataObject', createdBy: string, createdTimestamp: number, modifiedBy?: string, id: string, modifiedTimestamp?: number, name: string, programs: Array<string>, recordType: string, status: string, version: number, associatedMetadata?: Array<{ __typename?: 'AssessmentMetadataObjectAssociation', id: string, metadataType: string }> } };

export type StartAssessmentQuestionGenerationWorkflowMutationVariables = Exact<{
  numQuestions: Scalars['Int']['input'];
  questionDifficulty: Scalars['Int']['input'];
  questionType: Scalars['String']['input'];
  learningObjectiveId: Scalars['String']['input'];
  learningObjectiveVersion: Scalars['Int']['input'];
  additionalContext?: InputMaybe<Scalars['String']['input']>;
}>;


export type StartAssessmentQuestionGenerationWorkflowMutation = { __typename?: 'Mutation', startAssessmentQuestionsGenerationWorkflow?: { __typename?: 'AssessmentQuestionsStartWorkflowOutput', executionId?: string } };

export type UpdateAssessmentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  version: Scalars['Int']['input'];
  title: Scalars['String']['input'];
  status: Scalars['String']['input'];
  passingScore: Scalars['Float']['input'];
  maxAttempts: Scalars['Int']['input'];
  waitTime: Scalars['Int']['input'];
  timeLimit: Scalars['Int']['input'];
  displaySetting: Scalars['String']['input'];
  programs: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
  questions: Array<InputMaybe<AssessmentLevelQuestionInput>> | InputMaybe<AssessmentLevelQuestionInput>;
  learningObjectives: Array<InputMaybe<AssessmentLevelLearningObjectiveInput>> | InputMaybe<AssessmentLevelLearningObjectiveInput>;
  completionMessages: Array<InputMaybe<AssessmentCompletionMessageInput>> | InputMaybe<AssessmentCompletionMessageInput>;
  isCorrectAnswersShown?: InputMaybe<Scalars['String']['input']>;
  isDetailedResultsEnabled?: InputMaybe<Scalars['String']['input']>;
  isSubmittedResponsesShown?: InputMaybe<Scalars['String']['input']>;
  questionOrdering?: InputMaybe<Scalars['String']['input']>;
  scoreDisplay?: InputMaybe<Scalars['String']['input']>;
  isCategoryScoreEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isCopyPasteEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isFinalScoreEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isFlaggingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isNextBackEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isNotesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isPausingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type UpdateAssessmentMutation = { __typename?: 'Mutation', updateAssessment?: { __typename?: 'Assessment', createdBy: string, createdTimestamp: number, displaySetting?: string, id: string, isCategoryScoreEnabled?: boolean, isCopyPasteEnabled?: boolean, isCorrectAnswersShown?: string, isDetailedResultsEnabled?: string, isFinalScoreEnabled?: boolean, isFlaggingEnabled?: boolean, isNextBackEnabled?: boolean, isNotesEnabled?: boolean, isPausingEnabled?: boolean, isSubmittedResponsesShown?: string, maxAttempts?: number, modifiedBy?: string, modifiedTimestamp?: number, passingScore?: number, programs?: Array<string>, questionOrdering?: string, recordType: string, scoreDisplay?: string, status: string, timeLimit?: number, title?: string, version: number, waitTime?: number, completionMessages?: Array<{ __typename?: 'AssessmentCompletionMessage', maxScore?: number, message?: string, minScore?: number }>, learningObjectives?: Array<{ __typename?: 'AssessmentLevelLearningObjective', id: string, numQuestions: number }>, questions?: Array<{ __typename?: 'AssessmentLevelQuestion', id: string, learningObjectives: Array<string> }> } };

export type UpdateAssessmentQuestionMutationVariables = Exact<{
  id: Scalars['String']['input'];
  version: Scalars['Int']['input'];
  status: Scalars['String']['input'];
  type: Scalars['String']['input'];
  scoringMethod: Scalars['String']['input'];
  difficulty: Scalars['Int']['input'];
  questionText: Scalars['String']['input'];
  answers: Array<InputMaybe<AssessmentAnswerInput>> | InputMaybe<AssessmentAnswerInput>;
  programs: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
  questionBanks?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  learningObjectives: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<AssessmentLanguage>;
}>;


export type UpdateAssessmentQuestionMutation = { __typename?: 'Mutation', updateAssessmentQuestion?: { __typename?: 'AssessmentQuestion', id: string, attachments?: Array<string>, createdBy: string, createdTimestamp: number, difficulty?: number, learningObjectives?: Array<string>, modifiedBy: string, modifiedTimestamp: number, programs?: Array<string>, questionBanks?: Array<string>, questionText?: string, recordType: string, scoringMethod?: string, status: string, tags?: Array<string>, type: string, version: number, answers: Array<{ __typename?: 'AssessmentAnswer', answerText?: string, explanation?: string, isCorrect?: boolean }> } };

export type UpdateLearningObjectiveMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  version: Scalars['Int']['input'];
  status: Scalars['String']['input'];
  name: Scalars['String']['input'];
}>;


export type UpdateLearningObjectiveMutation = { __typename?: 'Mutation', updateAssessmentLearningObjective?: { __typename?: 'AssessmentMetadataObject', id: string, createdBy: string, createdTimestamp: number, modifiedBy?: string, modifiedTimestamp?: number, name: string, programs: Array<string>, recordType: string, status: string, version: number } };

export type UpdateQuestionBankMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  version: Scalars['Int']['input'];
  status: Scalars['String']['input'];
  name: Scalars['String']['input'];
  programs: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
  associatedMetadata: Array<InputMaybe<AssessmentMetadataObjectUpdateAssociationInput>> | InputMaybe<AssessmentMetadataObjectUpdateAssociationInput>;
}>;


export type UpdateQuestionBankMutation = { __typename?: 'Mutation', updateAssessmentQuestionBank?: { __typename?: 'AssessmentMetadataObject', id: string, createdBy: string, createdTimestamp: number, modifiedBy?: string, modifiedTimestamp?: number, name: string, programs: Array<string>, recordType: string, status: string, version: number, associatedMetadata?: Array<{ __typename?: 'AssessmentMetadataObjectAssociation', id: string, metadataType: string }> } };

export type GetAssessmentQueryVariables = Exact<{
  id: Scalars['String']['input'];
  version: Scalars['Int']['input'];
}>;


export type GetAssessmentQuery = { __typename?: 'Query', assessment?: { __typename?: 'Assessment', createdBy: string, createdTimestamp: number, displaySetting?: string, id: string, isCategoryScoreEnabled?: boolean, isCopyPasteEnabled?: boolean, isCorrectAnswersShown?: string, isDetailedResultsEnabled?: string, isFinalScoreEnabled?: boolean, isFlaggingEnabled?: boolean, isNextBackEnabled?: boolean, isNotesEnabled?: boolean, isPausingEnabled?: boolean, isSubmittedResponsesShown?: string, maxAttempts?: number, modifiedBy?: string, modifiedTimestamp?: number, passingScore?: number, programs?: Array<string>, questionOrdering?: string, recordType: string, scoreDisplay?: string, status: string, timeLimit?: number, title?: string, version: number, waitTime?: number, isSkippedQuestionIncorrect: boolean, completionMessages?: Array<{ __typename?: 'AssessmentCompletionMessage', maxScore?: number, message?: string, minScore?: number }>, learningObjectives?: Array<{ __typename?: 'AssessmentLevelLearningObjective', id: string, numQuestions: number }>, questions?: Array<{ __typename?: 'AssessmentLevelQuestion', id: string, learningObjectives: Array<string> }> } };

export type GetAssessmentQuestionQueryVariables = Exact<{
  id: Scalars['String']['input'];
  version: Scalars['Int']['input'];
}>;


export type GetAssessmentQuestionQuery = { __typename?: 'Query', assessmentQuestion?: { __typename?: 'AssessmentQuestion', id: string, modifiedBy: string, learningObjectives?: Array<string>, modifiedTimestamp: number, programs?: Array<string>, questionBanks?: Array<string>, difficulty?: number, createdTimestamp: number, createdBy: string, attachments?: Array<string>, questionText?: string, recordType: string, scoringMethod?: string, status: string, tags?: Array<string>, type: string, version: number, answers: Array<{ __typename?: 'AssessmentAnswer', id: string, answerText?: string, explanation?: string, isCorrect?: boolean }> } };

export type GetAssessmentQuestionAuditLogQueryVariables = Exact<{
  id: Scalars['String']['input'];
  delta?: InputMaybe<Scalars['Boolean']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  paginationMarker?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetAssessmentQuestionAuditLogQuery = { __typename?: 'Query', assessmentQuestionAuditLog?: { __typename?: 'AuditLogOutput', currentCount: number, paginationMarker?: string, auditLog: Array<{ __typename?: 'AuditLogItem', resourceAuditSequence: number, resourceVersion: number, resourceModifiedBy: string, resourceModifiedTimestamp: number, operation: string, oldValue?: string, newValue?: string, delta?: string }> } };

export type GetAssessmentQuestionsQueryVariables = Exact<{
  difficulty?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>> | InputMaybe<Scalars['Int']['input']>>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  learningObjectives?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  programs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  from?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  questionBanks?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
}>;


export type GetAssessmentQuestionsQuery = { __typename?: 'Query', assessmentQuestions?: { __typename?: 'AssessmentQuestionsOutput', currentCount: number, totalCount: number, questions: Array<{ __typename?: 'AssessmentQuestion', id: string, modifiedBy: string, learningObjectives?: Array<string>, modifiedTimestamp: number, programs?: Array<string>, questionBanks?: Array<string>, difficulty?: number, createdTimestamp: number, createdBy: string, attachments?: Array<string>, questionText?: string, recordType: string, scoringMethod?: string, status: string, tags?: Array<string>, type: string, version: number, answers: Array<{ __typename?: 'AssessmentAnswer', id: string, answerText?: string, explanation?: string, isCorrect?: boolean }> }> } };

export type GetAssessmentUserQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetAssessmentUserQuery = { __typename?: 'Query', assessmentUser?: { __typename?: 'AssessmentUser', id: string, programs?: Array<string>, roles: Array<string>, status: string } };

export type GetAssessmentUsersQueryVariables = Exact<{
  id?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>>;
  programs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
}>;


export type GetAssessmentUsersQuery = { __typename?: 'Query', assessmentUsers: { __typename?: 'AssessmentUsersOutput', currentCount: number, totalCount: number, users: Array<{ __typename?: 'AssessmentUser', id: string, name?: string, email?: string, createdBy: string, createdTimestamp: number, lastSignin?: number, modifiedBy?: string, modifiedTimestamp?: number, programs?: Array<string>, recordType: string, roles: Array<string>, status: string }> } };

export type GetAssessmentsQueryVariables = Exact<{
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  learningObjectives?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
}>;


export type GetAssessmentsQuery = { __typename?: 'Query', assessments?: { __typename?: 'AssessmentsOutput', currentCount: number, totalCount: number, assessments: Array<{ __typename?: 'Assessment', createdBy: string, createdTimestamp: number, displaySetting?: string, id: string, isCategoryScoreEnabled?: boolean, isCopyPasteEnabled?: boolean, isCorrectAnswersShown?: string, isDetailedResultsEnabled?: string, isFinalScoreEnabled?: boolean, isFlaggingEnabled?: boolean, isNextBackEnabled?: boolean, isNotesEnabled?: boolean, isPausingEnabled?: boolean, isSubmittedResponsesShown?: string, maxAttempts?: number, modifiedBy?: string, modifiedTimestamp?: number, passingScore?: number, programs?: Array<string>, questionOrdering?: string, recordType: string, scoreDisplay?: string, status: string, timeLimit?: number, title?: string, version: number, waitTime?: number, isSkippedQuestionIncorrect: boolean, completionMessages?: Array<{ __typename?: 'AssessmentCompletionMessage', maxScore?: number, message?: string, minScore?: number }>, learningObjectives?: Array<{ __typename?: 'AssessmentLevelLearningObjective', id: string, numQuestions: number }>, questions?: Array<{ __typename?: 'AssessmentLevelQuestion', id: string, learningObjectives: Array<string> }> }> } };

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = { __typename?: 'Query', currentUser: { __typename?: 'User', id: string, emailAddress: string, firstName: string, lastName: string, status: UserStatus, userRole: UserRole, auditMetadata: { __typename?: 'AuditMetadata', createdAt: string }, gandalfDetails?: { __typename?: 'GandalfDetails', vibeId: string } } };

export type GetLearningObjectiveQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  version: Scalars['Int']['input'];
  language?: InputMaybe<AssessmentLanguage>;
}>;


export type GetLearningObjectiveQuery = { __typename?: 'Query', assessmentLearningObjective?: { __typename?: 'AssessmentMetadataObject', createdBy: string, createdTimestamp: number, id: string, modifiedBy?: string, modifiedTimestamp?: number, name: string, programs: Array<string>, recordType: string, status: string, version: number, associatedMetadata?: Array<{ __typename?: 'AssessmentMetadataObjectAssociation', id: string, metadataType: string }> } };

export type GetLearningObjectivesQueryVariables = Exact<{
  learningObjectives?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  programs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  from?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetLearningObjectivesQuery = { __typename?: 'Query', assessmentLearningObjectives: { __typename?: 'AssessmentMetadataSearchOutput', currentCount: number, totalCount: number, metadataObjects: Array<{ __typename?: 'AssessmentMetadataObject', createdBy: string, createdTimestamp: number, id: string, modifiedBy?: string, modifiedTimestamp?: number, name: string, programs: Array<string>, recordType: string, status: string, version: number, associatedMetadata?: Array<{ __typename?: 'AssessmentMetadataObjectAssociation', id: string, metadataType: string }> }> } };

export type GetQuestionBankQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  version: Scalars['Int']['input'];
  language?: InputMaybe<AssessmentLanguage>;
}>;


export type GetQuestionBankQuery = { __typename?: 'Query', assessmentQuestionBank?: { __typename?: 'AssessmentMetadataObject', createdBy: string, createdTimestamp: number, id: string, modifiedBy?: string, modifiedTimestamp?: number, name: string, programs: Array<string>, recordType: string, status: string, version: number, associatedMetadata?: Array<{ __typename?: 'AssessmentMetadataObjectAssociation', id: string, metadataType: string }> } };

export type GetQuestionBanksQueryVariables = Exact<{
  questionBanks?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  programs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  from?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetQuestionBanksQuery = { __typename?: 'Query', assessmentQuestionBanks: { __typename?: 'AssessmentMetadataSearchOutput', currentCount: number, totalCount: number, metadataObjects: Array<{ __typename?: 'AssessmentMetadataObject', createdBy: string, createdTimestamp: number, id: string, modifiedBy?: string, modifiedTimestamp?: number, name: string, programs: Array<string>, recordType: string, status: string, version: number, associatedMetadata?: Array<{ __typename?: 'AssessmentMetadataObjectAssociation', id: string, metadataType: string }> }> } };

export type PollAssessmentQuestionsGenerationWorkflowQueryVariables = Exact<{
  executionId: Scalars['String']['input'];
}>;


export type PollAssessmentQuestionsGenerationWorkflowQuery = { __typename?: 'Query', pollAssessmentQuestionsGenerationWorkflow?: { __typename?: 'AssessmentQuestionsPollWorkflowOutput', executionStatus: string, input: string, output?: string, startDate?: string, stopDate?: string } };


export const CreateAssessmentDocument = gql`
    mutation CreateAssessment($title: String!, $status: String!, $passingScore: Float!, $maxAttempts: Int!, $waitTime: Int!, $timeLimit: Int, $displaySetting: String!, $programs: [String]!, $questions: [AssessmentLevelQuestionInput]!, $learningObjectives: [AssessmentLevelLearningObjectiveInput]!, $completionMessages: [AssessmentCompletionMessageInput]!) {
  createAssessment(
    input: {title: $title, status: $status, passingScore: $passingScore, maxAttempts: $maxAttempts, waitTime: $waitTime, timeLimit: $timeLimit, displaySetting: $displaySetting, programs: $programs, questions: $questions, learningObjectives: $learningObjectives, completionMessages: $completionMessages}
  ) {
    id
  }
}
    `;
export type CreateAssessmentMutationFn = Apollo.MutationFunction<CreateAssessmentMutation, CreateAssessmentMutationVariables>;

/**
 * __useCreateAssessmentMutation__
 *
 * To run a mutation, you first call `useCreateAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssessmentMutation, { data, loading, error }] = useCreateAssessmentMutation({
 *   variables: {
 *      title: // value for 'title'
 *      status: // value for 'status'
 *      passingScore: // value for 'passingScore'
 *      maxAttempts: // value for 'maxAttempts'
 *      waitTime: // value for 'waitTime'
 *      timeLimit: // value for 'timeLimit'
 *      displaySetting: // value for 'displaySetting'
 *      programs: // value for 'programs'
 *      questions: // value for 'questions'
 *      learningObjectives: // value for 'learningObjectives'
 *      completionMessages: // value for 'completionMessages'
 *   },
 * });
 */
export function useCreateAssessmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateAssessmentMutation, CreateAssessmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAssessmentMutation, CreateAssessmentMutationVariables>(CreateAssessmentDocument, options);
      }
export type CreateAssessmentMutationHookResult = ReturnType<typeof useCreateAssessmentMutation>;
export type CreateAssessmentMutationResult = Apollo.MutationResult<CreateAssessmentMutation>;
export type CreateAssessmentMutationOptions = Apollo.BaseMutationOptions<CreateAssessmentMutation, CreateAssessmentMutationVariables>;
export const CreateAssessmentQuestionDocument = gql`
    mutation CreateAssessmentQuestion($status: String!, $type: String!, $scoringMethod: String, $difficulty: Int, $questionText: String!, $answers: [AssessmentAnswerInput]!, $programs: [String], $questionBanks: [String], $tags: [String], $learningObjectives: [String]!, $language: AssessmentLanguage) {
  createAssessmentQuestion(
    input: {answers: $answers, difficulty: $difficulty, programs: $programs, questionBanks: $questionBanks, questionText: $questionText, scoringMethod: $scoringMethod, status: $status, tags: $tags, type: $type, language: $language, learningObjectives: $learningObjectives}
  ) {
    id
    attachments
    createdBy
    createdTimestamp
    difficulty
    modifiedBy
    modifiedTimestamp
    questionBanks
    programs
    questionText
    recordType
    scoringMethod
    status
    tags
    type
    version
    answers {
      answerText
      explanation
      isCorrect
    }
  }
}
    `;
export type CreateAssessmentQuestionMutationFn = Apollo.MutationFunction<CreateAssessmentQuestionMutation, CreateAssessmentQuestionMutationVariables>;

/**
 * __useCreateAssessmentQuestionMutation__
 *
 * To run a mutation, you first call `useCreateAssessmentQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssessmentQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssessmentQuestionMutation, { data, loading, error }] = useCreateAssessmentQuestionMutation({
 *   variables: {
 *      status: // value for 'status'
 *      type: // value for 'type'
 *      scoringMethod: // value for 'scoringMethod'
 *      difficulty: // value for 'difficulty'
 *      questionText: // value for 'questionText'
 *      answers: // value for 'answers'
 *      programs: // value for 'programs'
 *      questionBanks: // value for 'questionBanks'
 *      tags: // value for 'tags'
 *      learningObjectives: // value for 'learningObjectives'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useCreateAssessmentQuestionMutation(baseOptions?: Apollo.MutationHookOptions<CreateAssessmentQuestionMutation, CreateAssessmentQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAssessmentQuestionMutation, CreateAssessmentQuestionMutationVariables>(CreateAssessmentQuestionDocument, options);
      }
export type CreateAssessmentQuestionMutationHookResult = ReturnType<typeof useCreateAssessmentQuestionMutation>;
export type CreateAssessmentQuestionMutationResult = Apollo.MutationResult<CreateAssessmentQuestionMutation>;
export type CreateAssessmentQuestionMutationOptions = Apollo.BaseMutationOptions<CreateAssessmentQuestionMutation, CreateAssessmentQuestionMutationVariables>;
export const CreateLearningObjectiveDocument = gql`
    mutation CreateLearningObjective($name: String!, $programs: [String]!, $status: String!, $language: AssessmentLanguage, $associatedMetadata: [AssessmentMetadataObjectUpdateAssociationInput]) {
  createAssessmentLearningObjective(
    input: {name: $name, programs: $programs, status: $status, language: $language, associatedMetadata: $associatedMetadata}
  ) {
    createdBy
    createdTimestamp
    modifiedBy
    id
    modifiedTimestamp
    name
    programs
    recordType
    status
    version
    associatedMetadata {
      id
      metadataType
    }
  }
}
    `;
export type CreateLearningObjectiveMutationFn = Apollo.MutationFunction<CreateLearningObjectiveMutation, CreateLearningObjectiveMutationVariables>;

/**
 * __useCreateLearningObjectiveMutation__
 *
 * To run a mutation, you first call `useCreateLearningObjectiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLearningObjectiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLearningObjectiveMutation, { data, loading, error }] = useCreateLearningObjectiveMutation({
 *   variables: {
 *      name: // value for 'name'
 *      programs: // value for 'programs'
 *      status: // value for 'status'
 *      language: // value for 'language'
 *      associatedMetadata: // value for 'associatedMetadata'
 *   },
 * });
 */
export function useCreateLearningObjectiveMutation(baseOptions?: Apollo.MutationHookOptions<CreateLearningObjectiveMutation, CreateLearningObjectiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLearningObjectiveMutation, CreateLearningObjectiveMutationVariables>(CreateLearningObjectiveDocument, options);
      }
export type CreateLearningObjectiveMutationHookResult = ReturnType<typeof useCreateLearningObjectiveMutation>;
export type CreateLearningObjectiveMutationResult = Apollo.MutationResult<CreateLearningObjectiveMutation>;
export type CreateLearningObjectiveMutationOptions = Apollo.BaseMutationOptions<CreateLearningObjectiveMutation, CreateLearningObjectiveMutationVariables>;
export const CreateQuestionBankDocument = gql`
    mutation CreateQuestionBank($name: String!, $programs: [String]!, $status: String!, $language: AssessmentLanguage, $associatedMetadata: [AssessmentMetadataObjectUpdateAssociationInput]!) {
  createAssessmentQuestionBank(
    input: {name: $name, programs: $programs, status: $status, language: $language, associatedMetadata: $associatedMetadata}
  ) {
    createdBy
    createdTimestamp
    modifiedBy
    id
    modifiedTimestamp
    name
    programs
    recordType
    status
    version
    associatedMetadata {
      id
      metadataType
    }
  }
}
    `;
export type CreateQuestionBankMutationFn = Apollo.MutationFunction<CreateQuestionBankMutation, CreateQuestionBankMutationVariables>;

/**
 * __useCreateQuestionBankMutation__
 *
 * To run a mutation, you first call `useCreateQuestionBankMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuestionBankMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuestionBankMutation, { data, loading, error }] = useCreateQuestionBankMutation({
 *   variables: {
 *      name: // value for 'name'
 *      programs: // value for 'programs'
 *      status: // value for 'status'
 *      language: // value for 'language'
 *      associatedMetadata: // value for 'associatedMetadata'
 *   },
 * });
 */
export function useCreateQuestionBankMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuestionBankMutation, CreateQuestionBankMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQuestionBankMutation, CreateQuestionBankMutationVariables>(CreateQuestionBankDocument, options);
      }
export type CreateQuestionBankMutationHookResult = ReturnType<typeof useCreateQuestionBankMutation>;
export type CreateQuestionBankMutationResult = Apollo.MutationResult<CreateQuestionBankMutation>;
export type CreateQuestionBankMutationOptions = Apollo.BaseMutationOptions<CreateQuestionBankMutation, CreateQuestionBankMutationVariables>;
export const StartAssessmentQuestionGenerationWorkflowDocument = gql`
    mutation StartAssessmentQuestionGenerationWorkflow($numQuestions: Int!, $questionDifficulty: Int!, $questionType: String!, $learningObjectiveId: String!, $learningObjectiveVersion: Int!, $additionalContext: String) {
  startAssessmentQuestionsGenerationWorkflow(
    input: {model: "ClaudeSonnet35", learningObjectives: [{numQuestions: $numQuestions, questionDifficulty: $questionDifficulty, questionType: $questionType, learningObjectiveId: $learningObjectiveId, learningObjectiveVersion: $learningObjectiveVersion, additionalContext: $additionalContext}]}
  ) {
    executionId
  }
}
    `;
export type StartAssessmentQuestionGenerationWorkflowMutationFn = Apollo.MutationFunction<StartAssessmentQuestionGenerationWorkflowMutation, StartAssessmentQuestionGenerationWorkflowMutationVariables>;

/**
 * __useStartAssessmentQuestionGenerationWorkflowMutation__
 *
 * To run a mutation, you first call `useStartAssessmentQuestionGenerationWorkflowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartAssessmentQuestionGenerationWorkflowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startAssessmentQuestionGenerationWorkflowMutation, { data, loading, error }] = useStartAssessmentQuestionGenerationWorkflowMutation({
 *   variables: {
 *      numQuestions: // value for 'numQuestions'
 *      questionDifficulty: // value for 'questionDifficulty'
 *      questionType: // value for 'questionType'
 *      learningObjectiveId: // value for 'learningObjectiveId'
 *      learningObjectiveVersion: // value for 'learningObjectiveVersion'
 *      additionalContext: // value for 'additionalContext'
 *   },
 * });
 */
export function useStartAssessmentQuestionGenerationWorkflowMutation(baseOptions?: Apollo.MutationHookOptions<StartAssessmentQuestionGenerationWorkflowMutation, StartAssessmentQuestionGenerationWorkflowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartAssessmentQuestionGenerationWorkflowMutation, StartAssessmentQuestionGenerationWorkflowMutationVariables>(StartAssessmentQuestionGenerationWorkflowDocument, options);
      }
export type StartAssessmentQuestionGenerationWorkflowMutationHookResult = ReturnType<typeof useStartAssessmentQuestionGenerationWorkflowMutation>;
export type StartAssessmentQuestionGenerationWorkflowMutationResult = Apollo.MutationResult<StartAssessmentQuestionGenerationWorkflowMutation>;
export type StartAssessmentQuestionGenerationWorkflowMutationOptions = Apollo.BaseMutationOptions<StartAssessmentQuestionGenerationWorkflowMutation, StartAssessmentQuestionGenerationWorkflowMutationVariables>;
export const UpdateAssessmentDocument = gql`
    mutation UpdateAssessment($id: ID!, $version: Int!, $title: String!, $status: String!, $passingScore: Float!, $maxAttempts: Int!, $waitTime: Int!, $timeLimit: Int!, $displaySetting: String!, $programs: [String]!, $questions: [AssessmentLevelQuestionInput]!, $learningObjectives: [AssessmentLevelLearningObjectiveInput]!, $completionMessages: [AssessmentCompletionMessageInput]!, $isCorrectAnswersShown: String, $isDetailedResultsEnabled: String, $isSubmittedResponsesShown: String, $questionOrdering: String, $scoreDisplay: String, $isCategoryScoreEnabled: Boolean, $isCopyPasteEnabled: Boolean, $isFinalScoreEnabled: Boolean, $isFlaggingEnabled: Boolean, $isNextBackEnabled: Boolean, $isNotesEnabled: Boolean, $isPausingEnabled: Boolean) {
  updateAssessment(
    input: {id: $id, version: $version, title: $title, status: $status, passingScore: $passingScore, maxAttempts: $maxAttempts, waitTime: $waitTime, timeLimit: $timeLimit, displaySetting: $displaySetting, programs: $programs, questions: $questions, learningObjectives: $learningObjectives, completionMessages: $completionMessages, isCorrectAnswersShown: $isCorrectAnswersShown, isDetailedResultsEnabled: $isDetailedResultsEnabled, isSubmittedResponsesShown: $isSubmittedResponsesShown, questionOrdering: $questionOrdering, scoreDisplay: $scoreDisplay, isCategoryScoreEnabled: $isCategoryScoreEnabled, isCopyPasteEnabled: $isCopyPasteEnabled, isFinalScoreEnabled: $isFinalScoreEnabled, isFlaggingEnabled: $isFlaggingEnabled, isNextBackEnabled: $isNextBackEnabled, isNotesEnabled: $isNotesEnabled, isPausingEnabled: $isPausingEnabled}
  ) {
    completionMessages {
      maxScore
      message
      minScore
    }
    createdBy
    createdTimestamp
    displaySetting
    id
    isCategoryScoreEnabled
    isCopyPasteEnabled
    isCorrectAnswersShown
    isDetailedResultsEnabled
    isFinalScoreEnabled
    isFlaggingEnabled
    isNextBackEnabled
    isNotesEnabled
    isPausingEnabled
    isSubmittedResponsesShown
    learningObjectives {
      id
      numQuestions
    }
    maxAttempts
    modifiedBy
    modifiedTimestamp
    passingScore
    programs
    questionOrdering
    questions {
      id
      learningObjectives
    }
    recordType
    scoreDisplay
    status
    timeLimit
    title
    version
    waitTime
  }
}
    `;
export type UpdateAssessmentMutationFn = Apollo.MutationFunction<UpdateAssessmentMutation, UpdateAssessmentMutationVariables>;

/**
 * __useUpdateAssessmentMutation__
 *
 * To run a mutation, you first call `useUpdateAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssessmentMutation, { data, loading, error }] = useUpdateAssessmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      version: // value for 'version'
 *      title: // value for 'title'
 *      status: // value for 'status'
 *      passingScore: // value for 'passingScore'
 *      maxAttempts: // value for 'maxAttempts'
 *      waitTime: // value for 'waitTime'
 *      timeLimit: // value for 'timeLimit'
 *      displaySetting: // value for 'displaySetting'
 *      programs: // value for 'programs'
 *      questions: // value for 'questions'
 *      learningObjectives: // value for 'learningObjectives'
 *      completionMessages: // value for 'completionMessages'
 *      isCorrectAnswersShown: // value for 'isCorrectAnswersShown'
 *      isDetailedResultsEnabled: // value for 'isDetailedResultsEnabled'
 *      isSubmittedResponsesShown: // value for 'isSubmittedResponsesShown'
 *      questionOrdering: // value for 'questionOrdering'
 *      scoreDisplay: // value for 'scoreDisplay'
 *      isCategoryScoreEnabled: // value for 'isCategoryScoreEnabled'
 *      isCopyPasteEnabled: // value for 'isCopyPasteEnabled'
 *      isFinalScoreEnabled: // value for 'isFinalScoreEnabled'
 *      isFlaggingEnabled: // value for 'isFlaggingEnabled'
 *      isNextBackEnabled: // value for 'isNextBackEnabled'
 *      isNotesEnabled: // value for 'isNotesEnabled'
 *      isPausingEnabled: // value for 'isPausingEnabled'
 *   },
 * });
 */
export function useUpdateAssessmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAssessmentMutation, UpdateAssessmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAssessmentMutation, UpdateAssessmentMutationVariables>(UpdateAssessmentDocument, options);
      }
export type UpdateAssessmentMutationHookResult = ReturnType<typeof useUpdateAssessmentMutation>;
export type UpdateAssessmentMutationResult = Apollo.MutationResult<UpdateAssessmentMutation>;
export type UpdateAssessmentMutationOptions = Apollo.BaseMutationOptions<UpdateAssessmentMutation, UpdateAssessmentMutationVariables>;
export const UpdateAssessmentQuestionDocument = gql`
    mutation UpdateAssessmentQuestion($id: String!, $version: Int!, $status: String!, $type: String!, $scoringMethod: String!, $difficulty: Int!, $questionText: String!, $answers: [AssessmentAnswerInput]!, $programs: [String]!, $questionBanks: [String], $tags: [String], $learningObjectives: [String]!, $language: AssessmentLanguage) {
  updateAssessmentQuestion(
    input: {id: $id, version: $version, answers: $answers, difficulty: $difficulty, programs: $programs, questionBanks: $questionBanks, questionText: $questionText, scoringMethod: $scoringMethod, status: $status, tags: $tags, type: $type, language: $language, learningObjectives: $learningObjectives}
  ) {
    id
    attachments
    createdBy
    createdTimestamp
    difficulty
    learningObjectives
    modifiedBy
    modifiedTimestamp
    programs
    questionBanks
    questionText
    recordType
    scoringMethod
    status
    tags
    type
    version
    answers {
      answerText
      explanation
      isCorrect
    }
  }
}
    `;
export type UpdateAssessmentQuestionMutationFn = Apollo.MutationFunction<UpdateAssessmentQuestionMutation, UpdateAssessmentQuestionMutationVariables>;

/**
 * __useUpdateAssessmentQuestionMutation__
 *
 * To run a mutation, you first call `useUpdateAssessmentQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssessmentQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssessmentQuestionMutation, { data, loading, error }] = useUpdateAssessmentQuestionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      version: // value for 'version'
 *      status: // value for 'status'
 *      type: // value for 'type'
 *      scoringMethod: // value for 'scoringMethod'
 *      difficulty: // value for 'difficulty'
 *      questionText: // value for 'questionText'
 *      answers: // value for 'answers'
 *      programs: // value for 'programs'
 *      questionBanks: // value for 'questionBanks'
 *      tags: // value for 'tags'
 *      learningObjectives: // value for 'learningObjectives'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useUpdateAssessmentQuestionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAssessmentQuestionMutation, UpdateAssessmentQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAssessmentQuestionMutation, UpdateAssessmentQuestionMutationVariables>(UpdateAssessmentQuestionDocument, options);
      }
export type UpdateAssessmentQuestionMutationHookResult = ReturnType<typeof useUpdateAssessmentQuestionMutation>;
export type UpdateAssessmentQuestionMutationResult = Apollo.MutationResult<UpdateAssessmentQuestionMutation>;
export type UpdateAssessmentQuestionMutationOptions = Apollo.BaseMutationOptions<UpdateAssessmentQuestionMutation, UpdateAssessmentQuestionMutationVariables>;
export const UpdateLearningObjectiveDocument = gql`
    mutation UpdateLearningObjective($id: ID!, $version: Int!, $status: String!, $name: String!) {
  updateAssessmentLearningObjective(
    input: {id: $id, version: $version, status: $status, name: $name}
  ) {
    id
    createdBy
    createdTimestamp
    modifiedBy
    modifiedTimestamp
    name
    programs
    recordType
    status
    version
  }
}
    `;
export type UpdateLearningObjectiveMutationFn = Apollo.MutationFunction<UpdateLearningObjectiveMutation, UpdateLearningObjectiveMutationVariables>;

/**
 * __useUpdateLearningObjectiveMutation__
 *
 * To run a mutation, you first call `useUpdateLearningObjectiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLearningObjectiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLearningObjectiveMutation, { data, loading, error }] = useUpdateLearningObjectiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      version: // value for 'version'
 *      status: // value for 'status'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateLearningObjectiveMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLearningObjectiveMutation, UpdateLearningObjectiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLearningObjectiveMutation, UpdateLearningObjectiveMutationVariables>(UpdateLearningObjectiveDocument, options);
      }
export type UpdateLearningObjectiveMutationHookResult = ReturnType<typeof useUpdateLearningObjectiveMutation>;
export type UpdateLearningObjectiveMutationResult = Apollo.MutationResult<UpdateLearningObjectiveMutation>;
export type UpdateLearningObjectiveMutationOptions = Apollo.BaseMutationOptions<UpdateLearningObjectiveMutation, UpdateLearningObjectiveMutationVariables>;
export const UpdateQuestionBankDocument = gql`
    mutation UpdateQuestionBank($id: ID!, $version: Int!, $status: String!, $name: String!, $programs: [String]!, $associatedMetadata: [AssessmentMetadataObjectUpdateAssociationInput]!) {
  updateAssessmentQuestionBank(
    input: {id: $id, version: $version, status: $status, name: $name, programs: $programs, associatedMetadata: $associatedMetadata}
  ) {
    id
    createdBy
    createdTimestamp
    modifiedBy
    modifiedTimestamp
    name
    programs
    recordType
    status
    version
    associatedMetadata {
      id
      metadataType
    }
  }
}
    `;
export type UpdateQuestionBankMutationFn = Apollo.MutationFunction<UpdateQuestionBankMutation, UpdateQuestionBankMutationVariables>;

/**
 * __useUpdateQuestionBankMutation__
 *
 * To run a mutation, you first call `useUpdateQuestionBankMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestionBankMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionBankMutation, { data, loading, error }] = useUpdateQuestionBankMutation({
 *   variables: {
 *      id: // value for 'id'
 *      version: // value for 'version'
 *      status: // value for 'status'
 *      name: // value for 'name'
 *      programs: // value for 'programs'
 *      associatedMetadata: // value for 'associatedMetadata'
 *   },
 * });
 */
export function useUpdateQuestionBankMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuestionBankMutation, UpdateQuestionBankMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateQuestionBankMutation, UpdateQuestionBankMutationVariables>(UpdateQuestionBankDocument, options);
      }
export type UpdateQuestionBankMutationHookResult = ReturnType<typeof useUpdateQuestionBankMutation>;
export type UpdateQuestionBankMutationResult = Apollo.MutationResult<UpdateQuestionBankMutation>;
export type UpdateQuestionBankMutationOptions = Apollo.BaseMutationOptions<UpdateQuestionBankMutation, UpdateQuestionBankMutationVariables>;
export const GetAssessmentDocument = gql`
    query GetAssessment($id: String!, $version: Int!) {
  assessment(id: $id, version: $version, language: en) {
    completionMessages {
      maxScore
      message
      minScore
    }
    createdBy
    createdTimestamp
    displaySetting
    id
    isCategoryScoreEnabled
    isCopyPasteEnabled
    isCorrectAnswersShown
    isDetailedResultsEnabled
    isFinalScoreEnabled
    isFlaggingEnabled
    isNextBackEnabled
    isNotesEnabled
    isPausingEnabled
    isSubmittedResponsesShown
    learningObjectives {
      id
      numQuestions
    }
    maxAttempts
    modifiedBy
    modifiedTimestamp
    passingScore
    programs
    questionOrdering
    questions {
      id
      learningObjectives
    }
    recordType
    scoreDisplay
    status
    timeLimit
    title
    version
    waitTime
    isSkippedQuestionIncorrect
  }
}
    `;

/**
 * __useGetAssessmentQuery__
 *
 * To run a query within a React component, call `useGetAssessmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useGetAssessmentQuery(baseOptions: Apollo.QueryHookOptions<GetAssessmentQuery, GetAssessmentQueryVariables> & ({ variables: GetAssessmentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssessmentQuery, GetAssessmentQueryVariables>(GetAssessmentDocument, options);
      }
export function useGetAssessmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssessmentQuery, GetAssessmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssessmentQuery, GetAssessmentQueryVariables>(GetAssessmentDocument, options);
        }
export function useGetAssessmentSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAssessmentQuery, GetAssessmentQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAssessmentQuery, GetAssessmentQueryVariables>(GetAssessmentDocument, options);
        }
export type GetAssessmentQueryHookResult = ReturnType<typeof useGetAssessmentQuery>;
export type GetAssessmentLazyQueryHookResult = ReturnType<typeof useGetAssessmentLazyQuery>;
export type GetAssessmentSuspenseQueryHookResult = ReturnType<typeof useGetAssessmentSuspenseQuery>;
export type GetAssessmentQueryResult = Apollo.QueryResult<GetAssessmentQuery, GetAssessmentQueryVariables>;
export const GetAssessmentQuestionDocument = gql`
    query GetAssessmentQuestion($id: String!, $version: Int!) {
  assessmentQuestion(id: $id, version: $version) {
    id
    modifiedBy
    learningObjectives
    modifiedTimestamp
    programs
    questionBanks
    difficulty
    createdTimestamp
    createdBy
    attachments
    questionText
    recordType
    scoringMethod
    status
    tags
    type
    version
    answers {
      id
      answerText
      explanation
      isCorrect
    }
  }
}
    `;

/**
 * __useGetAssessmentQuestionQuery__
 *
 * To run a query within a React component, call `useGetAssessmentQuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentQuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentQuestionQuery({
 *   variables: {
 *      id: // value for 'id'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useGetAssessmentQuestionQuery(baseOptions: Apollo.QueryHookOptions<GetAssessmentQuestionQuery, GetAssessmentQuestionQueryVariables> & ({ variables: GetAssessmentQuestionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssessmentQuestionQuery, GetAssessmentQuestionQueryVariables>(GetAssessmentQuestionDocument, options);
      }
export function useGetAssessmentQuestionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssessmentQuestionQuery, GetAssessmentQuestionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssessmentQuestionQuery, GetAssessmentQuestionQueryVariables>(GetAssessmentQuestionDocument, options);
        }
export function useGetAssessmentQuestionSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAssessmentQuestionQuery, GetAssessmentQuestionQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAssessmentQuestionQuery, GetAssessmentQuestionQueryVariables>(GetAssessmentQuestionDocument, options);
        }
export type GetAssessmentQuestionQueryHookResult = ReturnType<typeof useGetAssessmentQuestionQuery>;
export type GetAssessmentQuestionLazyQueryHookResult = ReturnType<typeof useGetAssessmentQuestionLazyQuery>;
export type GetAssessmentQuestionSuspenseQueryHookResult = ReturnType<typeof useGetAssessmentQuestionSuspenseQuery>;
export type GetAssessmentQuestionQueryResult = Apollo.QueryResult<GetAssessmentQuestionQuery, GetAssessmentQuestionQueryVariables>;
export const GetAssessmentQuestionAuditLogDocument = gql`
    query GetAssessmentQuestionAuditLog($id: String!, $delta: Boolean, $size: Int, $paginationMarker: String) {
  assessmentQuestionAuditLog(
    input: {id: $id, delta: $delta, size: $size, paginationMarker: $paginationMarker}
  ) {
    currentCount
    paginationMarker
    auditLog {
      resourceAuditSequence
      resourceVersion
      resourceModifiedBy
      resourceModifiedTimestamp
      operation
      oldValue
      newValue
      delta
    }
  }
}
    `;

/**
 * __useGetAssessmentQuestionAuditLogQuery__
 *
 * To run a query within a React component, call `useGetAssessmentQuestionAuditLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentQuestionAuditLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentQuestionAuditLogQuery({
 *   variables: {
 *      id: // value for 'id'
 *      delta: // value for 'delta'
 *      size: // value for 'size'
 *      paginationMarker: // value for 'paginationMarker'
 *   },
 * });
 */
export function useGetAssessmentQuestionAuditLogQuery(baseOptions: Apollo.QueryHookOptions<GetAssessmentQuestionAuditLogQuery, GetAssessmentQuestionAuditLogQueryVariables> & ({ variables: GetAssessmentQuestionAuditLogQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssessmentQuestionAuditLogQuery, GetAssessmentQuestionAuditLogQueryVariables>(GetAssessmentQuestionAuditLogDocument, options);
      }
export function useGetAssessmentQuestionAuditLogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssessmentQuestionAuditLogQuery, GetAssessmentQuestionAuditLogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssessmentQuestionAuditLogQuery, GetAssessmentQuestionAuditLogQueryVariables>(GetAssessmentQuestionAuditLogDocument, options);
        }
export function useGetAssessmentQuestionAuditLogSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAssessmentQuestionAuditLogQuery, GetAssessmentQuestionAuditLogQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAssessmentQuestionAuditLogQuery, GetAssessmentQuestionAuditLogQueryVariables>(GetAssessmentQuestionAuditLogDocument, options);
        }
export type GetAssessmentQuestionAuditLogQueryHookResult = ReturnType<typeof useGetAssessmentQuestionAuditLogQuery>;
export type GetAssessmentQuestionAuditLogLazyQueryHookResult = ReturnType<typeof useGetAssessmentQuestionAuditLogLazyQuery>;
export type GetAssessmentQuestionAuditLogSuspenseQueryHookResult = ReturnType<typeof useGetAssessmentQuestionAuditLogSuspenseQuery>;
export type GetAssessmentQuestionAuditLogQueryResult = Apollo.QueryResult<GetAssessmentQuestionAuditLogQuery, GetAssessmentQuestionAuditLogQueryVariables>;
export const GetAssessmentQuestionsDocument = gql`
    query GetAssessmentQuestions($difficulty: [Int], $status: [String], $type: [String], $learningObjectives: [String], $id: [String], $programs: [String], $from: Int, $size: Int, $questionBanks: [String]) {
  assessmentQuestions(
    input: {difficulty: $difficulty, status: $status, type: $type, learningObjectives: $learningObjectives, questionBanks: $questionBanks, id: $id, programs: $programs, from: $from, size: $size}
  ) {
    questions {
      id
      modifiedBy
      learningObjectives
      modifiedTimestamp
      programs
      questionBanks
      difficulty
      createdTimestamp
      createdBy
      attachments
      questionText
      recordType
      scoringMethod
      status
      tags
      type
      version
      answers {
        id
        answerText
        explanation
        isCorrect
      }
    }
    currentCount
    totalCount
  }
}
    `;

/**
 * __useGetAssessmentQuestionsQuery__
 *
 * To run a query within a React component, call `useGetAssessmentQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentQuestionsQuery({
 *   variables: {
 *      difficulty: // value for 'difficulty'
 *      status: // value for 'status'
 *      type: // value for 'type'
 *      learningObjectives: // value for 'learningObjectives'
 *      id: // value for 'id'
 *      programs: // value for 'programs'
 *      from: // value for 'from'
 *      size: // value for 'size'
 *      questionBanks: // value for 'questionBanks'
 *   },
 * });
 */
export function useGetAssessmentQuestionsQuery(baseOptions?: Apollo.QueryHookOptions<GetAssessmentQuestionsQuery, GetAssessmentQuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssessmentQuestionsQuery, GetAssessmentQuestionsQueryVariables>(GetAssessmentQuestionsDocument, options);
      }
export function useGetAssessmentQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssessmentQuestionsQuery, GetAssessmentQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssessmentQuestionsQuery, GetAssessmentQuestionsQueryVariables>(GetAssessmentQuestionsDocument, options);
        }
export function useGetAssessmentQuestionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAssessmentQuestionsQuery, GetAssessmentQuestionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAssessmentQuestionsQuery, GetAssessmentQuestionsQueryVariables>(GetAssessmentQuestionsDocument, options);
        }
export type GetAssessmentQuestionsQueryHookResult = ReturnType<typeof useGetAssessmentQuestionsQuery>;
export type GetAssessmentQuestionsLazyQueryHookResult = ReturnType<typeof useGetAssessmentQuestionsLazyQuery>;
export type GetAssessmentQuestionsSuspenseQueryHookResult = ReturnType<typeof useGetAssessmentQuestionsSuspenseQuery>;
export type GetAssessmentQuestionsQueryResult = Apollo.QueryResult<GetAssessmentQuestionsQuery, GetAssessmentQuestionsQueryVariables>;
export const GetAssessmentUserDocument = gql`
    query GetAssessmentUser($id: String!) {
  assessmentUser(id: $id) {
    id
    programs
    roles
    status
  }
}
    `;

/**
 * __useGetAssessmentUserQuery__
 *
 * To run a query within a React component, call `useGetAssessmentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAssessmentUserQuery(baseOptions: Apollo.QueryHookOptions<GetAssessmentUserQuery, GetAssessmentUserQueryVariables> & ({ variables: GetAssessmentUserQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssessmentUserQuery, GetAssessmentUserQueryVariables>(GetAssessmentUserDocument, options);
      }
export function useGetAssessmentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssessmentUserQuery, GetAssessmentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssessmentUserQuery, GetAssessmentUserQueryVariables>(GetAssessmentUserDocument, options);
        }
export function useGetAssessmentUserSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAssessmentUserQuery, GetAssessmentUserQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAssessmentUserQuery, GetAssessmentUserQueryVariables>(GetAssessmentUserDocument, options);
        }
export type GetAssessmentUserQueryHookResult = ReturnType<typeof useGetAssessmentUserQuery>;
export type GetAssessmentUserLazyQueryHookResult = ReturnType<typeof useGetAssessmentUserLazyQuery>;
export type GetAssessmentUserSuspenseQueryHookResult = ReturnType<typeof useGetAssessmentUserSuspenseQuery>;
export type GetAssessmentUserQueryResult = Apollo.QueryResult<GetAssessmentUserQuery, GetAssessmentUserQueryVariables>;
export const GetAssessmentUsersDocument = gql`
    query GetAssessmentUsers($id: [ID], $programs: [String], $roles: [String], $status: [String]) {
  assessmentUsers(
    input: {id: $id, programs: $programs, roles: $roles, status: $status}
  ) {
    currentCount
    totalCount
    users {
      id
      name
      email
      createdBy
      createdTimestamp
      lastSignin
      modifiedBy
      modifiedTimestamp
      programs
      recordType
      roles
      status
    }
  }
}
    `;

/**
 * __useGetAssessmentUsersQuery__
 *
 * To run a query within a React component, call `useGetAssessmentUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentUsersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      programs: // value for 'programs'
 *      roles: // value for 'roles'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetAssessmentUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetAssessmentUsersQuery, GetAssessmentUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssessmentUsersQuery, GetAssessmentUsersQueryVariables>(GetAssessmentUsersDocument, options);
      }
export function useGetAssessmentUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssessmentUsersQuery, GetAssessmentUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssessmentUsersQuery, GetAssessmentUsersQueryVariables>(GetAssessmentUsersDocument, options);
        }
export function useGetAssessmentUsersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAssessmentUsersQuery, GetAssessmentUsersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAssessmentUsersQuery, GetAssessmentUsersQueryVariables>(GetAssessmentUsersDocument, options);
        }
export type GetAssessmentUsersQueryHookResult = ReturnType<typeof useGetAssessmentUsersQuery>;
export type GetAssessmentUsersLazyQueryHookResult = ReturnType<typeof useGetAssessmentUsersLazyQuery>;
export type GetAssessmentUsersSuspenseQueryHookResult = ReturnType<typeof useGetAssessmentUsersSuspenseQuery>;
export type GetAssessmentUsersQueryResult = Apollo.QueryResult<GetAssessmentUsersQuery, GetAssessmentUsersQueryVariables>;
export const GetAssessmentsDocument = gql`
    query GetAssessments($status: [String], $learningObjectives: [String]) {
  assessments(input: {status: $status, learningObjectives: $learningObjectives}) {
    assessments {
      completionMessages {
        maxScore
        message
        minScore
      }
      createdBy
      createdTimestamp
      displaySetting
      id
      isCategoryScoreEnabled
      isCopyPasteEnabled
      isCorrectAnswersShown
      isDetailedResultsEnabled
      isFinalScoreEnabled
      isFlaggingEnabled
      isNextBackEnabled
      isNotesEnabled
      isPausingEnabled
      isSubmittedResponsesShown
      learningObjectives {
        id
        numQuestions
      }
      maxAttempts
      modifiedBy
      modifiedTimestamp
      passingScore
      programs
      questionOrdering
      questions {
        id
        learningObjectives
      }
      recordType
      scoreDisplay
      status
      timeLimit
      title
      version
      waitTime
      isSkippedQuestionIncorrect
    }
    currentCount
    totalCount
  }
}
    `;

/**
 * __useGetAssessmentsQuery__
 *
 * To run a query within a React component, call `useGetAssessmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentsQuery({
 *   variables: {
 *      status: // value for 'status'
 *      learningObjectives: // value for 'learningObjectives'
 *   },
 * });
 */
export function useGetAssessmentsQuery(baseOptions?: Apollo.QueryHookOptions<GetAssessmentsQuery, GetAssessmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssessmentsQuery, GetAssessmentsQueryVariables>(GetAssessmentsDocument, options);
      }
export function useGetAssessmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssessmentsQuery, GetAssessmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssessmentsQuery, GetAssessmentsQueryVariables>(GetAssessmentsDocument, options);
        }
export function useGetAssessmentsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAssessmentsQuery, GetAssessmentsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAssessmentsQuery, GetAssessmentsQueryVariables>(GetAssessmentsDocument, options);
        }
export type GetAssessmentsQueryHookResult = ReturnType<typeof useGetAssessmentsQuery>;
export type GetAssessmentsLazyQueryHookResult = ReturnType<typeof useGetAssessmentsLazyQuery>;
export type GetAssessmentsSuspenseQueryHookResult = ReturnType<typeof useGetAssessmentsSuspenseQuery>;
export type GetAssessmentsQueryResult = Apollo.QueryResult<GetAssessmentsQuery, GetAssessmentsQueryVariables>;
export const GetCurrentUserDocument = gql`
    query GetCurrentUser {
  currentUser {
    id
    emailAddress
    firstName
    lastName
    status
    userRole
    auditMetadata {
      createdAt
    }
    gandalfDetails {
      vibeId
    }
  }
}
    `;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
      }
export function useGetCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
        }
export function useGetCurrentUserSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
        }
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserSuspenseQueryHookResult = ReturnType<typeof useGetCurrentUserSuspenseQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const GetLearningObjectiveDocument = gql`
    query GetLearningObjective($id: ID!, $version: Int!, $language: AssessmentLanguage) {
  assessmentLearningObjective(
    input: {id: $id, version: $version, language: $language}
  ) {
    createdBy
    createdTimestamp
    id
    modifiedBy
    modifiedTimestamp
    name
    programs
    recordType
    status
    version
    associatedMetadata {
      id
      metadataType
    }
  }
}
    `;

/**
 * __useGetLearningObjectiveQuery__
 *
 * To run a query within a React component, call `useGetLearningObjectiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLearningObjectiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLearningObjectiveQuery({
 *   variables: {
 *      id: // value for 'id'
 *      version: // value for 'version'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetLearningObjectiveQuery(baseOptions: Apollo.QueryHookOptions<GetLearningObjectiveQuery, GetLearningObjectiveQueryVariables> & ({ variables: GetLearningObjectiveQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLearningObjectiveQuery, GetLearningObjectiveQueryVariables>(GetLearningObjectiveDocument, options);
      }
export function useGetLearningObjectiveLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLearningObjectiveQuery, GetLearningObjectiveQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLearningObjectiveQuery, GetLearningObjectiveQueryVariables>(GetLearningObjectiveDocument, options);
        }
export function useGetLearningObjectiveSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetLearningObjectiveQuery, GetLearningObjectiveQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLearningObjectiveQuery, GetLearningObjectiveQueryVariables>(GetLearningObjectiveDocument, options);
        }
export type GetLearningObjectiveQueryHookResult = ReturnType<typeof useGetLearningObjectiveQuery>;
export type GetLearningObjectiveLazyQueryHookResult = ReturnType<typeof useGetLearningObjectiveLazyQuery>;
export type GetLearningObjectiveSuspenseQueryHookResult = ReturnType<typeof useGetLearningObjectiveSuspenseQuery>;
export type GetLearningObjectiveQueryResult = Apollo.QueryResult<GetLearningObjectiveQuery, GetLearningObjectiveQueryVariables>;
export const GetLearningObjectivesDocument = gql`
    query GetLearningObjectives($learningObjectives: [ID], $status: [String], $programs: [String], $from: Int, $size: Int) {
  assessmentLearningObjectives(
    input: {id: $learningObjectives, status: $status, programs: $programs, from: $from, size: $size}
  ) {
    currentCount
    metadataObjects {
      createdBy
      createdTimestamp
      id
      modifiedBy
      modifiedTimestamp
      name
      programs
      recordType
      status
      version
      associatedMetadata {
        id
        metadataType
      }
    }
    totalCount
  }
}
    `;

/**
 * __useGetLearningObjectivesQuery__
 *
 * To run a query within a React component, call `useGetLearningObjectivesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLearningObjectivesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLearningObjectivesQuery({
 *   variables: {
 *      learningObjectives: // value for 'learningObjectives'
 *      status: // value for 'status'
 *      programs: // value for 'programs'
 *      from: // value for 'from'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useGetLearningObjectivesQuery(baseOptions?: Apollo.QueryHookOptions<GetLearningObjectivesQuery, GetLearningObjectivesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLearningObjectivesQuery, GetLearningObjectivesQueryVariables>(GetLearningObjectivesDocument, options);
      }
export function useGetLearningObjectivesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLearningObjectivesQuery, GetLearningObjectivesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLearningObjectivesQuery, GetLearningObjectivesQueryVariables>(GetLearningObjectivesDocument, options);
        }
export function useGetLearningObjectivesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetLearningObjectivesQuery, GetLearningObjectivesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLearningObjectivesQuery, GetLearningObjectivesQueryVariables>(GetLearningObjectivesDocument, options);
        }
export type GetLearningObjectivesQueryHookResult = ReturnType<typeof useGetLearningObjectivesQuery>;
export type GetLearningObjectivesLazyQueryHookResult = ReturnType<typeof useGetLearningObjectivesLazyQuery>;
export type GetLearningObjectivesSuspenseQueryHookResult = ReturnType<typeof useGetLearningObjectivesSuspenseQuery>;
export type GetLearningObjectivesQueryResult = Apollo.QueryResult<GetLearningObjectivesQuery, GetLearningObjectivesQueryVariables>;
export const GetQuestionBankDocument = gql`
    query GetQuestionBank($id: ID!, $version: Int!, $language: AssessmentLanguage) {
  assessmentQuestionBank(input: {id: $id, version: $version, language: $language}) {
    createdBy
    createdTimestamp
    id
    modifiedBy
    modifiedTimestamp
    name
    programs
    recordType
    status
    version
    associatedMetadata {
      id
      metadataType
    }
  }
}
    `;

/**
 * __useGetQuestionBankQuery__
 *
 * To run a query within a React component, call `useGetQuestionBankQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionBankQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionBankQuery({
 *   variables: {
 *      id: // value for 'id'
 *      version: // value for 'version'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetQuestionBankQuery(baseOptions: Apollo.QueryHookOptions<GetQuestionBankQuery, GetQuestionBankQueryVariables> & ({ variables: GetQuestionBankQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuestionBankQuery, GetQuestionBankQueryVariables>(GetQuestionBankDocument, options);
      }
export function useGetQuestionBankLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionBankQuery, GetQuestionBankQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuestionBankQuery, GetQuestionBankQueryVariables>(GetQuestionBankDocument, options);
        }
export function useGetQuestionBankSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetQuestionBankQuery, GetQuestionBankQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetQuestionBankQuery, GetQuestionBankQueryVariables>(GetQuestionBankDocument, options);
        }
export type GetQuestionBankQueryHookResult = ReturnType<typeof useGetQuestionBankQuery>;
export type GetQuestionBankLazyQueryHookResult = ReturnType<typeof useGetQuestionBankLazyQuery>;
export type GetQuestionBankSuspenseQueryHookResult = ReturnType<typeof useGetQuestionBankSuspenseQuery>;
export type GetQuestionBankQueryResult = Apollo.QueryResult<GetQuestionBankQuery, GetQuestionBankQueryVariables>;
export const GetQuestionBanksDocument = gql`
    query GetQuestionBanks($questionBanks: [ID], $status: [String], $programs: [String], $from: Int, $size: Int) {
  assessmentQuestionBanks(
    input: {id: $questionBanks, status: $status, programs: $programs, from: $from, size: $size}
  ) {
    currentCount
    metadataObjects {
      createdBy
      createdTimestamp
      id
      modifiedBy
      modifiedTimestamp
      name
      programs
      recordType
      status
      version
      associatedMetadata {
        id
        metadataType
      }
    }
    totalCount
  }
}
    `;

/**
 * __useGetQuestionBanksQuery__
 *
 * To run a query within a React component, call `useGetQuestionBanksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionBanksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionBanksQuery({
 *   variables: {
 *      questionBanks: // value for 'questionBanks'
 *      status: // value for 'status'
 *      programs: // value for 'programs'
 *      from: // value for 'from'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useGetQuestionBanksQuery(baseOptions?: Apollo.QueryHookOptions<GetQuestionBanksQuery, GetQuestionBanksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuestionBanksQuery, GetQuestionBanksQueryVariables>(GetQuestionBanksDocument, options);
      }
export function useGetQuestionBanksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionBanksQuery, GetQuestionBanksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuestionBanksQuery, GetQuestionBanksQueryVariables>(GetQuestionBanksDocument, options);
        }
export function useGetQuestionBanksSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetQuestionBanksQuery, GetQuestionBanksQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetQuestionBanksQuery, GetQuestionBanksQueryVariables>(GetQuestionBanksDocument, options);
        }
export type GetQuestionBanksQueryHookResult = ReturnType<typeof useGetQuestionBanksQuery>;
export type GetQuestionBanksLazyQueryHookResult = ReturnType<typeof useGetQuestionBanksLazyQuery>;
export type GetQuestionBanksSuspenseQueryHookResult = ReturnType<typeof useGetQuestionBanksSuspenseQuery>;
export type GetQuestionBanksQueryResult = Apollo.QueryResult<GetQuestionBanksQuery, GetQuestionBanksQueryVariables>;
export const PollAssessmentQuestionsGenerationWorkflowDocument = gql`
    query PollAssessmentQuestionsGenerationWorkflow($executionId: String!) {
  pollAssessmentQuestionsGenerationWorkflow(input: {executionId: $executionId}) {
    executionStatus
    input
    output
    startDate
    stopDate
  }
}
    `;

/**
 * __usePollAssessmentQuestionsGenerationWorkflowQuery__
 *
 * To run a query within a React component, call `usePollAssessmentQuestionsGenerationWorkflowQuery` and pass it any options that fit your needs.
 * When your component renders, `usePollAssessmentQuestionsGenerationWorkflowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePollAssessmentQuestionsGenerationWorkflowQuery({
 *   variables: {
 *      executionId: // value for 'executionId'
 *   },
 * });
 */
export function usePollAssessmentQuestionsGenerationWorkflowQuery(baseOptions: Apollo.QueryHookOptions<PollAssessmentQuestionsGenerationWorkflowQuery, PollAssessmentQuestionsGenerationWorkflowQueryVariables> & ({ variables: PollAssessmentQuestionsGenerationWorkflowQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PollAssessmentQuestionsGenerationWorkflowQuery, PollAssessmentQuestionsGenerationWorkflowQueryVariables>(PollAssessmentQuestionsGenerationWorkflowDocument, options);
      }
export function usePollAssessmentQuestionsGenerationWorkflowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PollAssessmentQuestionsGenerationWorkflowQuery, PollAssessmentQuestionsGenerationWorkflowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PollAssessmentQuestionsGenerationWorkflowQuery, PollAssessmentQuestionsGenerationWorkflowQueryVariables>(PollAssessmentQuestionsGenerationWorkflowDocument, options);
        }
export function usePollAssessmentQuestionsGenerationWorkflowSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PollAssessmentQuestionsGenerationWorkflowQuery, PollAssessmentQuestionsGenerationWorkflowQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PollAssessmentQuestionsGenerationWorkflowQuery, PollAssessmentQuestionsGenerationWorkflowQueryVariables>(PollAssessmentQuestionsGenerationWorkflowDocument, options);
        }
export type PollAssessmentQuestionsGenerationWorkflowQueryHookResult = ReturnType<typeof usePollAssessmentQuestionsGenerationWorkflowQuery>;
export type PollAssessmentQuestionsGenerationWorkflowLazyQueryHookResult = ReturnType<typeof usePollAssessmentQuestionsGenerationWorkflowLazyQuery>;
export type PollAssessmentQuestionsGenerationWorkflowSuspenseQueryHookResult = ReturnType<typeof usePollAssessmentQuestionsGenerationWorkflowSuspenseQuery>;
export type PollAssessmentQuestionsGenerationWorkflowQueryResult = Apollo.QueryResult<PollAssessmentQuestionsGenerationWorkflowQuery, PollAssessmentQuestionsGenerationWorkflowQueryVariables>;