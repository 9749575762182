import React, { useEffect, useState } from 'react';
import { SideNavigation, SideNavigationProps } from '@amzn/awsui-components-react';
import { useNavigate } from 'react-router-dom';
import {
    ASSESSMENT_LIST_ROUTE,
    DASHBOARD_ROUTE,
    LEARNING_OBJECTIVE_LIST_ROUTE,
    QUESTION_BANK_LIST_ROUTE,
    QUESTIONS_LIST_ROUTE,
    USERS_LIST_ROUTE,
} from '../../router/router';
import { isProd } from '../../common/nodeEnvironment';
import { AssessmentRoles } from '../../common/constants/users';
import { useUserInfo } from '../../hooks/useUserInfo';

const NavigationSidebar = () => {
    const navigate = useNavigate();
    const [items, setItems] = useState<SideNavigationProps.Item[]>([]);
    const { user } = useUserInfo();

    useEffect(() => {
        const navItems = [
            {
                type: 'link',
                text: DASHBOARD_ROUTE.title,
                href: DASHBOARD_ROUTE.path,
            },
            {
                type: 'link',
                text: LEARNING_OBJECTIVE_LIST_ROUTE.title,
                href: LEARNING_OBJECTIVE_LIST_ROUTE.path,
            },
            {
                type: 'link',
                text: QUESTIONS_LIST_ROUTE.title,
                href: QUESTIONS_LIST_ROUTE.path,
            },
            {
                type: 'link',
                text: QUESTION_BANK_LIST_ROUTE.title,
                href: QUESTION_BANK_LIST_ROUTE.path,
            },
            {
                type: 'link',
                text: ASSESSMENT_LIST_ROUTE.title,
                href: ASSESSMENT_LIST_ROUTE.path,
            },
        ];

        if (
            user &&
            user.assessmentRoles &&
            user.assessmentRoles.includes(AssessmentRoles.ADMIN) &&
            !isProd()
        ) {
            navItems.push({
                type: 'link',
                text: USERS_LIST_ROUTE.title,
                href: USERS_LIST_ROUTE.path,
            });
        }

        const items = [
            {
                type: 'section',
                text: 'Assessment Manager',
                items: navItems,
            },
        ] as SideNavigationProps.Item[];

        setItems(items);
    }, [user]);

    return (
        <SideNavigation
            items={items}
            onFollow={(e) => {
                e.preventDefault();
                navigate(e.detail.href);
            }}
        />
    );
};

export default NavigationSidebar;
