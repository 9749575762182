export enum AssessmentSettingsForm {
    FormId = 'assessment-settings-form',
    SubmitFormButton = 'assessment-settings-edit-submit',
    QuestionDisplayLabel = 'question-display-label',
    CorrectAnswerLabel = 'correct-answers-label',
    DetailedResultsLabel = 'detailed-results-label',
    ShowSubmittedResponsesLabel = 'show-submitted-label',
    QuestionOrderingLabel = 'question-ordering-label',
    ScoreDisplayLabel = 'score-display-label',
    ObjectiveScoreEnabledLabel = 'objective-score-enabled-label',
    CopyPasteLabel = 'copy-paste-label',
    ShowFinalScoreLabel = 'show-final-score-label',
    FlagQuestionsEnabledLabel = 'flag-questions-enabled-label',
    BackNextButtonEnabledLabel = ' back-next-enabled-label',
    NotesEnabled = 'notes-enabled-label',
    PauseAssessmentEnabled = 'pause-assessment-label',
    MaxAttemptsLabel = 'max-attempts-label',
    PassingScoreLabel = 'passing-score-label',
    TimeLimitLabel = 'time-limit-label',
    WaitTimeLabel = 'wait-time-label',
    ConfidenceLevelShownLabel = 'confidence-level-shown-label',
    ConfidenceLevelPromptEnabledLabel = 'confidence-level-prompt-enabled-label',
}
