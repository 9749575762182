import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setBreadcrumbs, setContentType } from '../../../reducers/navigationReducer';
import { ASSESSMENT_LIST_ROUTE } from '../../../router/router';
import AssessmentList from '../../../components/assessments/AssessmentList';

const AssessmentListPage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            setBreadcrumbs([
                {
                    text: ASSESSMENT_LIST_ROUTE.title,
                    href: ASSESSMENT_LIST_ROUTE.path,
                },
            ]),
        );
        dispatch(setContentType('table'));
    }, [dispatch]);

    return (
        <AssessmentList
            preferencesKey="assessmentsTablePreferences"
            columnWidthsKey="assessmentsTableColumnWidths"
        />
    );
};

export default AssessmentListPage;
