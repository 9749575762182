import React from 'react';
import { Autosuggest, FormField } from '@amzn/awsui-components-react';
import { AssessmentMetadataObject, AssessmentQuestionInput } from '../../../graphql';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { Dictionary } from '../../../interfaces/dictionary';

export const learningObjectiveSelectDataId = 'learning-objective-select-label';

export interface LearningObjectiveSelectProps {
    formValues: Partial<AssessmentQuestionInput>;
    handleFormValueChange: (formUpdates: Partial<AssessmentQuestionInput>) => void;
    errors: any;
    learningObjectiveOptions: OptionDefinition[];
    learningObjectiveDict?: Dictionary<AssessmentMetadataObject>;
}

const LearningObjectiveSelect = ({
    formValues,
    handleFormValueChange,
    errors,
    learningObjectiveOptions,
    learningObjectiveDict,
}: LearningObjectiveSelectProps) => {
    const [value, setValue] = React.useState(formValues.learningObjectives![0] ?? '');
    return (
        <FormField
            data-testid={learningObjectiveSelectDataId}
            label="Learning objective"
            errorText={errors.learningObjectives ?? null}
        >
            <Autosuggest
                value={
                    learningObjectiveDict?.hasOwnProperty(value)
                        ? learningObjectiveDict![value].name
                        : value
                }
                onChange={(e) => {
                    setValue(e.detail.value!);
                    handleFormValueChange({
                        learningObjectives: [e.detail.value!],
                    });
                }}
                options={learningObjectiveOptions}
                placeholder="Select learning objective"
                empty="No learning objectives available."
                enteredTextLabel={(text) => `Use: "${text}"`}
            />
        </FormField>
    );
};

export default LearningObjectiveSelect;
