import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setBreadcrumbs, setContentType } from '../../../reducers/navigationReducer';
import { USERS_LIST_ROUTE } from '../../../router/router';
import { Box, Spinner } from '@amzn/awsui-components-react';
import { useUserInfo } from '../../../hooks/useUserInfo';
import { AssessmentRoles } from '../../../common/constants/users';
import { UserMgmtAccessDenied } from '../../../components/users/UserMgmtAccessDenied';
import { UsersList } from '../../../components/users/UsersList';
import { UserMgmtTestIds } from '../../../common/dataTestIds/userMgmt';

const UsersListPage = () => {
    const dispatch = useDispatch();

    const { user: currentUser, loading, assessmentDataLoading } = useUserInfo();

    useEffect(() => {
        dispatch(
            setBreadcrumbs([
                {
                    text: USERS_LIST_ROUTE.title,
                    href: USERS_LIST_ROUTE.path,
                },
            ]),
        );
        dispatch(setContentType('table'));
    }, [dispatch]);

    if (!currentUser || assessmentDataLoading || loading) {
        return (
            <Box data-testid={UserMgmtTestIds.UserListLoading} textAlign="center">
                <Spinner />
                Loading...
            </Box>
        );
    }

    return currentUser.assessmentRoles?.includes(AssessmentRoles.ADMIN) ? (
        <UsersList />
    ) : (
        <UserMgmtAccessDenied message="You are not authorized to view this page!" />
    );
};

export default UsersListPage;
