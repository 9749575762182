import * as React from 'react';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import { Box, Form, FormField, Input, Select } from '@amzn/awsui-components-react';
import { AssessmentUserRolesConfig } from '../../../common/constants/users';
import { UserMgmtTestIds } from '../../../common/dataTestIds/userMgmt';
import { ApolloError } from '@apollo/client';
import { useCreateAssessmentUserMutation } from '../../../graphql';
import { handleSelectedOption } from '../../../utils/formUtils';
import { useNotifications } from '../../../context/NotificationsProvider';
import { ErrorMapper } from '../../../utils/errorCodesMapper/errorCodesMapper';
import { ProgramMultiSelect } from '../../common/formFields';

type AddUserModalProps = {
    showAddUserModal: boolean;
    setShowAddUserModal: (state: boolean) => void;
    tableReloadRequestHandler: () => void;
};

type AddUserFormValues = {
    email: string;
    programs: string[];
    assessmentRole: string | null;
};

const FEATURE_NAME = 'USER-MGMT';

const AddUserModal = ({
    showAddUserModal,
    setShowAddUserModal,
    tableReloadRequestHandler,
}: AddUserModalProps) => {
    const [createAssessmentUser, { loading: createAssessmentUserLoading }] =
        useCreateAssessmentUserMutation();
    const { addNotification } = useNotifications();

    //Get all the keys of const AssessmentUserRolesConfig and map to display values
    const assessmentRolesOptions = Object.values(AssessmentUserRolesConfig).map((role: any) => {
        return { label: role.label, value: role.value };
    });

    const initialFormState = {
        email: '',
        programs: [],
        assessmentRole: null,
    } as AddUserFormValues;

    const [addUserFormValues, setAddUserFormValues] = React.useState(initialFormState);

    const [addUserErrors, setAddUserErrors] = React.useState('');

    const handleAddUserFormValuesChange = (updates: any) => {
        setAddUserFormValues({
            ...addUserFormValues,
            ...updates,
        });
    };

    const isValidAddUserFormValues = (addUserFormValues: AddUserFormValues) => {
        if (
            !addUserFormValues.email.trim() ||
            !addUserFormValues.assessmentRole ||
            addUserFormValues.programs.length === 0
        ) {
            setAddUserErrors('All fields are required!');
            return false;
        }
        return true;
    };

    const handleCreateAssessmentUser = async (newUser: AddUserFormValues) => {
        try {
            const createAssessmentUserResponse = await createAssessmentUser({
                variables: {
                    email: newUser.email,
                    programs: newUser.programs,
                    roles: newUser.assessmentRole ?? '',
                },
            });
            if (createAssessmentUserResponse.data?.createAssessmentUser?.id) {
                setShowAddUserModal(false);
                setAddUserFormValues(initialFormState);
                setAddUserErrors('');
                addNotification({
                    id: `create-assessment-user-${Date.now()}`,
                    type: 'success',
                    content:
                        'User successfully added. Please allow a few moments for the changes to sync.',
                });
                // Reload users list table.
                tableReloadRequestHandler();
            }
        } catch (error) {
            if (error instanceof ApolloError) {
                setAddUserErrors(ErrorMapper.getDisplayErrorMessage(error.message, FEATURE_NAME));
                return;
            }
            setAddUserErrors('Something went wrong, please try again later');
        }
    };

    const handleAddUserFormSubmission = async () => {
        setAddUserErrors('');

        if (isValidAddUserFormValues(addUserFormValues)) {
            await handleCreateAssessmentUser(addUserFormValues);
        }
    };

    const handleModalDismiss = () => {
        setShowAddUserModal(false);
        setAddUserFormValues(initialFormState);
        setAddUserErrors('');
    };

    return (
        <Modal
            data-testid={UserMgmtTestIds.AddUserModalId}
            onDismiss={handleModalDismiss}
            visible={showAddUserModal}
            header={
                <Box padding={{ bottom: 'l' }} variant="h1">
                    Add new user
                </Box>
            }
        >
            <Form
                data-testid={UserMgmtTestIds.AddUserFormId}
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            data-testid={UserMgmtTestIds.AddUserFormCancelButton}
                            formAction="none"
                            variant="link"
                            onClick={handleModalDismiss}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            data-testid={UserMgmtTestIds.AddUserFormSubmissionButton}
                            onClick={handleAddUserFormSubmission}
                            disabled={createAssessmentUserLoading}
                        >
                            Add user
                        </Button>
                    </SpaceBetween>
                }
                errorText={addUserErrors}
            >
                <>
                    <SpaceBetween direction="vertical" size="l">
                        <FormField label="Amazon email address">
                            <Input
                                data-testid={UserMgmtTestIds.EmailAddUserFormField}
                                value={addUserFormValues.email}
                                onChange={(event) => {
                                    handleAddUserFormValuesChange({
                                        email: event.detail.value,
                                    });
                                }}
                            />
                        </FormField>
                        <ProgramMultiSelect
                            formValues={addUserFormValues}
                            handleFormValueChange={handleAddUserFormValuesChange}
                            errors={{}}
                        />
                        <FormField label="Role">
                            <Select
                                data-testid={UserMgmtTestIds.RolesAddUserFormField}
                                onChange={({ detail }) => {
                                    handleAddUserFormValuesChange({
                                        assessmentRole: detail.selectedOption.value,
                                    });
                                }}
                                options={assessmentRolesOptions}
                                selectedOption={handleSelectedOption({
                                    selectedOption: addUserFormValues.assessmentRole ?? '',
                                    options: assessmentRolesOptions,
                                })}
                                placeholder="Select role"
                            />
                        </FormField>
                    </SpaceBetween>
                </>
            </Form>
        </Modal>
    );
};

export default AddUserModal;
