import { createContext } from 'react';

interface AuthContext {
    login: (redirectTo?: string) => void;
    logout: (redirectTo?: string) => void;
    isLoading: boolean;
    isAuthed: boolean;
}

const DEFAULT_AUTH_CONTEXT: AuthContext = {
    login: () => {},
    logout: () => {},
    isLoading: true,
    isAuthed: false,
};

export const AuthContext = createContext<AuthContext>(DEFAULT_AUTH_CONTEXT);
