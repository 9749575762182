import React, { createContext, useContext } from 'react';
import {
    GetLearningObjectiveOptions,
    GetQuestionOptions,
    useQuestions,
} from '../hooks/useQuestion';
import { AssessmentMetadataObject, AssessmentQuestion } from '../graphql';
import { Dictionary } from '../interfaces/dictionary';

interface QuestionsContextType {
    questions: AssessmentQuestion[];
    learningObjectives?: AssessmentMetadataObject[];
    getQuestions: (variables: any, options?: GetQuestionOptions) => void;
    isLoading?: boolean;
    getQuestionsByPropertyFilter: (filters?: any) => Promise<void>;
    learningObjectiveDict: Dictionary<AssessmentMetadataObject> | undefined;
    currentPageIndex: number;
    pagesCount: number;
    handlePaginationChange: (event: any) => Promise<void>;
    currentCount: number;
    handleGetLearningObjectives: (
        questions: AssessmentQuestion[],
        additionalLearningObjectiveIds?: string[],
    ) => Promise<void>;
}

const QuestionsContext = createContext<QuestionsContextType | undefined>(undefined);

export const QuestionsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const questionsData = useQuestions();
    return <QuestionsContext.Provider value={questionsData}>{children}</QuestionsContext.Provider>;
};

export const useQuestionsContext = () => {
    const context = useContext(QuestionsContext);
    if (context === undefined) {
        throw new Error('useQuestionsContext must be used within a QuestionsProvider');
    }
    return context;
};
