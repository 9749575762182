export interface ErrorMessageMapping {
    error: string;
    message: string;
}

export interface ErrorMessageDetails {
    uiMessage: string;
    serverMessage: string;
}

export interface FeatureErrorMessages {
    [errorCode: string]: ErrorMessageDetails;
}

export interface ErrorMessagesByFeature {
    [feature: string]: FeatureErrorMessages;
}

export const errorMessagesByFeature: ErrorMessagesByFeature = {
    'USER-MGMT': {
        'TC3-0001': {
            serverMessage:
                'Error TC3-0001 - Expected user data not present in User Org API response',
            uiMessage:
                'We could not find a user associated with this email address. Please verify the email address provided.',
        },
        'TCAS-0003': {
            serverMessage:
                'Error TCAS-0003 - Insufficient assessment-service-level programs assigned to requesting user to access this endpoint.',
            uiMessage: 'Access Denied: Insufficient program permissions.',
        },
        'TCAS-0006': {
            // Unlikely to run into this error, but just in case.
            serverMessage: 'Error TCAS-0006 - Feature unsupported',
            uiMessage: 'This feature is currently not supported.',
        },
        'TCAS-0011': {
            // This is the same error code we send for validation failures for all inputs, so the ui message should also be generic.
            serverMessage:
                'Error TCAS-0011 - Schema validation failed: [email] invalid_string - Invalid email',
            uiMessage:
                "Something doesn't look right. Please review your information and try again.",
        },
        'TCAS-0014': {
            serverMessage: 'Error TCAS-0014 - User <vibeId> already exists in database.',
            uiMessage: 'This user already exists in our system.',
        },
    },
};
