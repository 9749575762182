import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import {
    Dashboard,
    QuestionCreate,
    QuestionsList,
    QuestionDetail,
    QuestionEdit,
    QuestionCreateGenAi,
    QuestionBankList,
    AssessmentList,
    LearningObjectiveList,
    AssessmentWizardCreate,
    AssessmentEditDetails,
    AssessmentDetail,
    LearningObjectiveCreate,
    LearningObjectiveDetail,
    LearningObjectiveEdit,
    AssessmentEditQuestions,
    AssessmentEditSettings,
    QuestionBankDetail,
    QuestionBankCreate,
    QuestionBankEdit,
} from '../pages';
import App from '../App';
import { getNodeEnvironment, NodeEnvironment } from '../common/nodeEnvironment';
import UsersListPage from '../pages/userManagement/usersList/UsersList';
import { QuestionsProvider } from '../context/QuestionsProvider';

const isProd = ![
    NodeEnvironment.LOCAL,
    NodeEnvironment.DEVELOPMENT,
    NodeEnvironment.BETA,
    NodeEnvironment.GAMMA,
].includes(getNodeEnvironment());

export const DASHBOARD_ROUTE = {
    element: <Dashboard />,
    index: true,
    path: '/',
    title: 'Dashboard',
};

// Questions
export const QUESTIONS_LIST_ROUTE = {
    element: <QuestionsList />,
    index: true,
    path: '/questions',
    title: 'Questions',
};

export const QUESTION_CREATE_ROUTE = {
    element: <QuestionCreate />,
    index: true,
    path: '/questions/create',
    title: 'Create Question',
};

export const QUESTION_DETAIL_ROUTE = {
    element: <QuestionDetail />,
    index: true,
    path: '/questions/:id/version/:version',
    title: 'Question Details',
};

export const QUESTION_EDIT_ROUTE = {
    element: (
        <QuestionsProvider>
            <QuestionEdit />
        </QuestionsProvider>
    ),
    index: true,
    path: '/questions/:id/version/:version/edit',
    title: 'Question Details',
};

export const QUESTION_CREATE_GENAI_ROUTE = {
    element: <QuestionCreateGenAi />,
    index: true,
    path: '/questions/create-with-genai',
    title: 'Generate Question with GenAi',
};

// Question banks
export const QUESTION_BANK_LIST_ROUTE = {
    element: <QuestionBankList />,
    index: true,
    path: '/question-banks',
    title: 'Question banks',
};

export const QUESTION_BANK_CREATE_ROUTE = {
    element: <QuestionBankCreate />,
    index: true,
    path: '/question-banks/create',
    title: 'Create question bank',
};

export const QUESTION_BANK_DETAIL_ROUTE = {
    element: (
        <QuestionsProvider>
            <QuestionBankDetail />
        </QuestionsProvider>
    ),
    index: true,
    path: '/question-banks/:id/version/:version',
    title: 'Question bank details',
};

export const QUESTION_BANK_EDIT_ROUTE = {
    element: (
        <QuestionsProvider>
            <QuestionBankEdit />
        </QuestionsProvider>
    ),
    index: true,
    path: '/question-banks/:id/version/:version/edit',
    title: 'Edit question bank',
};

// Assessments
export const ASSESSMENT_LIST_ROUTE = {
    element: <AssessmentList />,
    index: true,
    path: '/assessments',
    title: 'Assessments',
};

export const ASSESSMENT_CREATE_ROUTE = {
    element: <AssessmentWizardCreate />,
    index: true,
    path: '/assessments/create',
    title: 'Create Assessments',
};

export const ASSESSMENT_EDIT_DETAILS_ROUTE = {
    element: <AssessmentEditDetails />,
    index: true,
    path: '/assessments/:id/version/:version/edit/details',
    title: 'Edit Assessment Details',
};

export const ASSESSMENT_EDIT_QUESTIONS_ROUTE = {
    element: <AssessmentEditQuestions />,
    index: true,
    path: '/assessments/:id/version/:version/edit/questions/:loId',
    title: 'Edit Assessment Questions',
};

export const ASSESSMENT_EDIT_SETTINGS_ROUTE = {
    element: <AssessmentEditSettings />,
    index: true,
    path: '/assessments/:id/version/:version/edit/settings',
    title: 'Edit Assessment Settings',
};

export const ASSESSMENT_DETAIL_ROUTE = {
    element: <AssessmentDetail />,
    index: true,
    path: '/assessments/:id/version/:version',
    title: 'Assessment Details',
};

// Learning objectives
export const LEARNING_OBJECTIVE_LIST_ROUTE = {
    element: <LearningObjectiveList />,
    index: true,
    path: '/learning-objectives',
    title: 'Learning objectives',
};

export const LEARNING_OBJECTIVE_CREATE_ROUTE = {
    element: <LearningObjectiveCreate />,
    index: true,
    path: '/learning-objectives/create',
    title: 'Create learning objectives',
};

export const LEARNING_OBJECTIVE_DETAIL_ROUTE = {
    element: <LearningObjectiveDetail />,
    index: true,
    path: '/learning-objectives/:id/version/:version',
    title: 'Learning objective details',
};

export const LEARNING_OBJECTIVE_EDIT_ROUTE = {
    element: <LearningObjectiveEdit />,
    index: true,
    path: '/learning-objectives/:id/version/:version/edit',
    title: 'Edit Learning Objective',
};

export const USERS_LIST_ROUTE = {
    element: <UsersListPage />,
    index: true,
    path: '/users/',
    title: 'Manage Users',
};

export const APP_ROUTE = {
    element: <App />,
    children: [
        DASHBOARD_ROUTE,
        QUESTIONS_LIST_ROUTE,
        QUESTION_CREATE_ROUTE,
        QUESTION_DETAIL_ROUTE,
        QUESTION_EDIT_ROUTE,
        QUESTION_CREATE_GENAI_ROUTE,
        QUESTION_BANK_LIST_ROUTE,
        QUESTION_BANK_CREATE_ROUTE,
        QUESTION_BANK_EDIT_ROUTE,
        QUESTION_BANK_DETAIL_ROUTE,
        ASSESSMENT_LIST_ROUTE,
        LEARNING_OBJECTIVE_LIST_ROUTE,
        LEARNING_OBJECTIVE_CREATE_ROUTE,
        ASSESSMENT_CREATE_ROUTE,
        ASSESSMENT_EDIT_DETAILS_ROUTE,
        ASSESSMENT_DETAIL_ROUTE,
        LEARNING_OBJECTIVE_DETAIL_ROUTE,
        LEARNING_OBJECTIVE_EDIT_ROUTE,
        ASSESSMENT_EDIT_QUESTIONS_ROUTE,
        ASSESSMENT_EDIT_SETTINGS_ROUTE,
    ],
};

if (!isProd) {
    APP_ROUTE.children.push(USERS_LIST_ROUTE);
}

const router = createBrowserRouter([APP_ROUTE]);

export default router;
