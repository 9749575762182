import React, { useEffect, useMemo, useState } from 'react';
import {
    Box,
    Button,
    Header,
    Link,
    Pagination,
    PropertyFilterProps,
    SpaceBetween,
    Table,
} from '@amzn/awsui-components-react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setBreadcrumbs, setContentType } from '../../../reducers/navigationReducer';
import {
    LEARNING_OBJECTIVE_CREATE_ROUTE,
    LEARNING_OBJECTIVE_LIST_ROUTE,
} from '../../../router/router';
import LearningObjectiveListPropertyFilter, {
    LearningObjectivesListPropertyFilterProps,
    LOCAL_STORAGE_LEARNING_OBJECTIVE_FILTER_KEY,
    StoredLearningObjectiveFilters,
} from './LearningObjectiveListPropertyFilter';
import {
    AssessmentMetadataObject,
    useGetAssessmentUsersLazyQuery,
    useGetLearningObjectivesLazyQuery,
} from '../../../graphql';
import { Dictionary } from '../../../interfaces/dictionary';
import { NodeEnvironment, getNodeEnvironment } from '../../../common/nodeEnvironment';

interface LearningObjectiveTableHeaderProps {
    currentCount: number;
}

const LearningObjectiveListTableHeader = ({ currentCount }: LearningObjectiveTableHeaderProps) => {
    const navigate = useNavigate();
    return (
        <Header
            counter={`(${currentCount})`}
            actions={
                <SpaceBetween size="xs" direction="horizontal">
                    <SpaceBetween size="xs" direction="horizontal">
                        <Button
                            onClick={() => navigate(LEARNING_OBJECTIVE_CREATE_ROUTE.path)}
                            variant="primary"
                        >
                            Create learning objective
                        </Button>
                    </SpaceBetween>
                </SpaceBetween>
            }
        >
            Learning objectives
        </Header>
    );
};
const LearningObjectiveList = () => {
    const isProd = ![
        NodeEnvironment.LOCAL,
        NodeEnvironment.DEVELOPMENT,
        NodeEnvironment.BETA,
        NodeEnvironment.GAMMA,
    ].includes(getNodeEnvironment());
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [currentPageIndex, setCurrentPageIndex] = useState(1);
    const [_, setFrom] = useState(0);
    const [size] = useState(100);
    const [currentCount, setCurrentCount] = useState(0);
    const [pagesCount, setPagesCount] = useState(1);
    const [isTableLoading, setIsTableLoading] = useState(false);
    const [usersInfoDict, setUsersInfoDict] = useState<Dictionary<string>>({});

    const [learningObjectives, setLearningObjectives] = useState<Array<AssessmentMetadataObject>>(
        [],
    );

    const [query, setQuery] = useState<PropertyFilterProps.Query>({
        tokens: [],
        operation: 'and',
    });

    const [getLearningObjectives, { fetchMore }] = useGetLearningObjectivesLazyQuery({
        fetchPolicy: 'network-only',
    });

    const [getUsers] = useGetAssessmentUsersLazyQuery();

    useEffect(() => {
        dispatch(
            setBreadcrumbs([
                {
                    text: LEARNING_OBJECTIVE_LIST_ROUTE.title,
                    href: LEARNING_OBJECTIVE_LIST_ROUTE.path,
                },
            ]),
        );
        dispatch(setContentType('table'));
    }, [dispatch]);

    useEffect(() => {
        const storedFilterString = localStorage.getItem(
            LOCAL_STORAGE_LEARNING_OBJECTIVE_FILTER_KEY,
        );

        if (storedFilterString) {
            try {
                let savedFilters = JSON.parse(storedFilterString) as StoredLearningObjectiveFilters;
                try {
                    if (savedFilters.propertyFilters) {
                        setQuery(savedFilters.propertyFilters);
                    }
                    handleGetLearningObjectives(savedFilters);
                } catch (e) {}
            } catch (e) {}
        } else {
            handleGetLearningObjectives();
        }
    }, []);

    const getUsersNamesForIds = async (userIds: Set<string>): Promise<any> => {
        const { data } = await getUsers({
            variables: {
                id: Array.from(userIds),
            },
        });
        let userDict = {};
        const users = data?.assessmentUsers?.users;
        if (users) {
            users.forEach((u) => {
                userDict = {
                    ...userDict,
                    [u.id]: u.name,
                };
            });
        }
        return userDict;
    };

    const handleGetUserInfo = async (learningObjectives: AssessmentMetadataObject[]) => {
        const userIds = new Set<string>();
        learningObjectives.forEach((lo) => {
            lo.modifiedBy && userIds.add(lo.modifiedBy);
            lo.createdBy && userIds.add(lo.createdBy);
        });
        const usersInfoDict = await getUsersNamesForIds(userIds);
        setUsersInfoDict(usersInfoDict);
    };

    const handleGetLearningObjectives = async (filters?: any) => {
        setIsTableLoading(true);
        let tokens = [];
        if (filters && filters.propertyFilters.tokens.length !== 0) {
            tokens = filters.propertyFilters.tokens.map(
                (token: PropertyFilterProps.FilteringOption) => {
                    return { [token.propertyKey]: [token.value] };
                },
            );
        }
        const searchQuery = Object.assign({}, ...tokens);
        const { data } = await getLearningObjectives({
            variables: {
                ...searchQuery,
            },
        });
        const learningObjectives = [...data?.assessmentLearningObjectives.metadataObjects!];
        const sortedLearningObjectives = learningObjectives.sort((a, b) =>
            a.modifiedTimestamp! < b.modifiedTimestamp! ? 1 : -1,
        );
        const totalCount = data?.assessmentLearningObjectives?.totalCount!;
        const totalPagesCount = Math.ceil(totalCount / size);
        setPagesCount(totalPagesCount);
        setCurrentCount(totalCount);
        let learningObjectiveDict = {};
        learningObjectives.forEach((objective) => {
            // Learning objective dictionary
            learningObjectiveDict = {
                ...learningObjectiveDict,
                [objective.id]: objective,
            };
        });
        handleGetUserInfo(sortedLearningObjectives);
        setLearningObjectives(sortedLearningObjectives);
        setIsTableLoading(false);
    };

    const handlePaginationChange = async (event: any) => {
        setIsTableLoading(true);
        const { currentPageIndex } = event.detail;

        const updatedPageIndex = currentPageIndex;
        const from = (updatedPageIndex - 1) * size;

        setCurrentPageIndex(updatedPageIndex);
        setFrom(from);
        const learningObjectiveData = await fetchMore({
            variables: {
                from,
                size,
            },
        });
        const learningObjectives =
            learningObjectiveData.data.assessmentLearningObjectives.metadataObjects;
        if (learningObjectives) {
            const totalCount = learningObjectiveData.data.assessmentLearningObjectives?.totalCount!;
            const totalPagesCount = Math.ceil(totalCount / size);
            const sortedLearningObjectives = learningObjectives.sort((a, b) =>
                a.modifiedTimestamp! < b.modifiedTimestamp! ? 1 : -1,
            );
            setPagesCount(totalPagesCount);
            setCurrentCount(totalCount);
            setLearningObjectives(sortedLearningObjectives);
            setIsTableLoading(false);
        }
    };

    const columnDefinitions = useMemo(() => {
        const cols = [
            {
                id: 'learningObjectiveName',
                header: 'Learning objective name',
                cell: (item: AssessmentMetadataObject) => (
                    <Link
                        variant="secondary"
                        href={`/learning-objectives/${item.id}/version/${item.version}`}
                        onFollow={(e) => {
                            e.preventDefault();
                            navigate(`/learning-objectives/${item.id}/version/${item.version}`, {
                                state: { ...item },
                            });
                        }}
                    >
                        {item.name}
                    </Link>
                ),
                isRowHeader: true,
            },
            {
                id: 'learningObjectivePrograms',
                header: 'Programs',
                cell: (item: AssessmentMetadataObject) => item.programs?.join(', '),
                isRowHeader: true,
            },
            {
                id: 'learningObjectiveStatus',
                header: 'Status',
                cell: (item: AssessmentMetadataObject) => item.status,
                isRowHeader: true,
            },
            {
                id: 'actions',
                header: 'Actions',
                cell: (item: AssessmentMetadataObject) => (
                    <SpaceBetween direction="vertical" size="xs">
                        <Button
                            href={`/learning-objectives/${item.id}/version/${item.version}/edit`}
                            onFollow={(e) => {
                                e.preventDefault();
                                navigate(
                                    `/learning-objectives/${item.id}/version/${item.version}/edit`,
                                    {
                                        state: { ...item },
                                    },
                                );
                            }}
                            variant="inline-link"
                        >
                            Edit
                        </Button>
                    </SpaceBetween>
                ),
            },
        ];
        // Remove once CHIRONASSMNTS-459 is complete
        if (!isProd) {
            const createdByCol = {
                id: 'createdByName',
                header: 'Created By',
                cell: (item: AssessmentMetadataObject) =>
                    (item.createdBy && usersInfoDict[item.createdBy]) || '',
                isRowHeader: true,
            };
            const modifiedByCol = {
                id: 'modifiedByName',
                header: 'Modifed By',
                cell: (item: AssessmentMetadataObject) =>
                    (item.modifiedBy && usersInfoDict[item.modifiedBy]) || '',
                isRowHeader: true,
            };
            const colsLen = cols.length;
            cols.splice(colsLen - 1, 0, createdByCol, modifiedByCol);
        }
        return cols;
    }, [usersInfoDict]);

    const learningObjectiveTableHeader: LearningObjectiveTableHeaderProps = {
        currentCount,
    };

    const learningObjectivesListFilterProps: LearningObjectivesListPropertyFilterProps = {
        query,
        setQuery,
        handleGetLearningObjectives,
    };

    return (
        <Table
            stickyHeader={true}
            header={<LearningObjectiveListTableHeader {...learningObjectiveTableHeader} />}
            variant="full-page"
            items={learningObjectives}
            columnDefinitions={columnDefinitions}
            loadingText="Loading learning objectives"
            enableKeyboardNavigation
            loading={isTableLoading}
            pagination={
                <Pagination
                    currentPageIndex={currentPageIndex}
                    pagesCount={pagesCount}
                    onChange={handlePaginationChange}
                />
            }
            filter={<LearningObjectiveListPropertyFilter {...learningObjectivesListFilterProps} />}
            empty={
                <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
                    No learning objectives found.
                </Box>
            }
            stickyColumns={{ first: 0, last: 1 }}
        />
    );
};

export default LearningObjectiveList;
