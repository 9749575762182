import { errorMessagesByFeature } from './errorMessagesByFeature';

export class ErrorMapper {
    private static parseErrorMessage(errorMessage: string) {
        // Errors are in the format: `Error ${errorCode} - ${message}`
        return errorMessage.split(' ')[1];
    }

    static getDisplayErrorMessage(errorMessage: string, feature: string): string {
        const errorCode = this.parseErrorMessage(errorMessage);
        return errorMessagesByFeature?.[feature]?.[errorCode]?.uiMessage ?? 'Something went wrong!';
    }
}
