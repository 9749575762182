import React, { SetStateAction, useEffect, useState } from 'react';
import {
    Box,
    Button,
    ButtonDropdown,
    ButtonDropdownProps,
    Cards,
    CardsProps,
    Header,
    Link,
    SpaceBetween,
    Tiles,
} from '@amzn/awsui-components-react';
import { AssessmentQuestionInput, useCreateAssessmentQuestionMutation } from '../../../graphql';
import {
    initialFormValues,
    QUESTION_WITHOUT_ANSWERS_ERROR,
} from '../../../common/constants/questions';
import { useNotifications } from '../../../context/NotificationsProvider';
import GenAiQuestionCardHeader, { GenAiQuetionCardHeaderProps } from './GenAiQuestionCardHeader';
import { useNavigate } from 'react-router-dom';
import { QUESTIONS_LIST_ROUTE } from '../../../router/router';
import './genAiQuestionCards.scss';
import GenAiQuestionEdit, { GenAiQuestionEditProps } from './GenAiQuestionEdit';
import useFormValidation from '../../../hooks/useFormValidation';
import { QUESTION_INPUT_VALIDATION_FIELDS } from '../../../common/constants/validations';

export interface GenAiQuestion {
    index: number;
    learningObjective: string;
    question: string;
    options: string[];
    answer: string;
    rationale: any;
}

export interface GenAiQuestionReviewCardProps {
    setQuestionFormValues: (value: SetStateAction<GenAiQuestion[] | undefined>) => void;
    questionFormValues: GenAiQuestion[] | undefined;
    promptFormValues: AssessmentQuestionInput;
}

const GenAiQuestionCards = ({
    questionFormValues,
    setQuestionFormValues,
    promptFormValues,
}: GenAiQuestionReviewCardProps) => {
    const [formValues, setFormValues] = useState<AssessmentQuestionInput>(initialFormValues);
    const [isEditMode, setIsEditMode] = useState(false);
    const [currentEditIndex, setCurrentEditIndex] = useState(0);
    const [selectedItems, setSelectedItems] = useState<GenAiQuestion[]>();

    const navigate = useNavigate();
    const { addNotification } = useNotifications();

    const [createQuestion] = useCreateAssessmentQuestionMutation();
    const { isInvalid, validateForm, errors } =
        useFormValidation<Partial<AssessmentQuestionInput>>();
    const runInputValidations = () => {
        return validateForm(formValues!, {
            required: QUESTION_INPUT_VALIDATION_FIELDS.REQUIRED,
        });
    };
    useEffect(() => {
        if (isInvalid) {
            runInputValidations();
        }
    }, [formValues, isInvalid, validateForm]);

    const handleQuestionReview = (genAiQuestion: GenAiQuestion) => {
        setCurrentEditIndex(genAiQuestion.index);
        setIsEditMode(true);
        setFormValues({
            ...formValues,
            questionText: genAiQuestion.question,
            answers: genAiQuestion.options.map((option) => ({
                answerText: option,
                isCorrect: option === genAiQuestion.answer,
                explanation: genAiQuestion.rationale[option],
            })),
        });
    };

    const handleCreateQuestion = async (genAiQuestion: GenAiQuestion) => {
        setIsEditMode(false);
        try {
            return await createQuestion({
                variables: {
                    ...formValues,
                    questionText: genAiQuestion.question,
                    learningObjectives: [promptFormValues.learningObjectives![0]],
                    answers: genAiQuestion.options.map((answer) => ({
                        answerText: answer,
                        isCorrect: answer === genAiQuestion.answer,
                        explanation: genAiQuestion.rationale[answer],
                    })),
                    programs: promptFormValues.programs,
                    difficulty: promptFormValues.difficulty,
                },
            });
        } catch (error) {}
    };

    const handleSaveQuestion = async (genAiQuestion: GenAiQuestion) => {
        const createdQuestion = await handleCreateQuestion(genAiQuestion);
        addNotification({
            id: `create-question-${Date.now()}`,
            ...(createdQuestion!.data?.createAssessmentQuestion
                ? {
                      type: 'success',
                      content: 'Question created successfully.',
                  }
                : {
                      type: 'error',
                      content: 'There was an error creating the question.',
                  }),
        });
        questionFormValues!.splice(genAiQuestion.index, 1);
        setQuestionFormValues(questionFormValues);
    };

    const handleSaveQuestions = async () => {
        const saveMultipleQuestions = selectedItems!.map((selectedAiGeneratedQuestion) =>
            handleCreateQuestion(selectedAiGeneratedQuestion),
        );
        Promise.all(saveMultipleQuestions).then((savedQuestions) => {
            const questionIds = savedQuestions.map((question) => ({
                id: question?.data?.createAssessmentQuestion!.id,
                questionText: question?.data?.createAssessmentQuestion!.questionText,
            }));
            addNotification({
                id: `create-question-${Date.now()}`,
                ...(questionIds.length > 0
                    ? {
                          type: 'success',
                          content: (
                              <ul>
                                  {questionIds.map((question) => (
                                      <li>
                                          Question{' '}
                                          <Link
                                              color="inverted"
                                              href={`/questions/${question.id}/version/1`}
                                          >
                                              {question.questionText}
                                          </Link>{' '}
                                          created successfully.
                                      </li>
                                  ))}
                              </ul>
                          ),
                      }
                    : {
                          type: 'error',
                          content: 'There was an error creating the question.',
                      }),
            });
        });
        navigate(QUESTIONS_LIST_ROUTE.path);
    };

    const handleDelete = (index: number) => {
        addNotification({
            id: `create-question-${Date.now()}`,
            type: 'success',
            content: 'Question deleted successfully.',
        });
        questionFormValues!.splice(index, 1);
        setQuestionFormValues(questionFormValues);
    };

    const handleEditQuestionsAndSave = async (index: number) => {
        // No correct answer
        const answerMustHaveCorrect = formValues.answers.filter((answer) => answer.isCorrect);
        if (answerMustHaveCorrect.length === 0) {
            return addNotification({
                id: `create-question-${Date.now()}-no-correct-answers`,
                type: 'error',
                content: 'Multiple choice questions must have 1 correct answer.',
            });
        }

        setIsEditMode(false);
        try {
            const createdQuestion = await createQuestion({
                variables: {
                    ...formValues,
                    learningObjectives: [promptFormValues.learningObjectives![0]],
                    programs: promptFormValues.programs,
                    difficulty: promptFormValues.difficulty,
                },
            });
            addNotification({
                id: `create-question-${Date.now()}`,
                ...(createdQuestion!.data?.createAssessmentQuestion
                    ? {
                          type: 'success',
                          content: 'Question created successfully.',
                      }
                    : {
                          type: 'error',
                          content: 'There was an error creating the question.',
                      }),
            });
            questionFormValues!.splice(index, 1);
            setQuestionFormValues(questionFormValues);
        } catch (error) {}
    };

    const handleCardActions = (
        e: CustomEvent<ButtonDropdownProps.ItemClickDetails>,
        item: GenAiQuestion,
    ) => {
        if (e.detail.id === 'delete') {
            handleDelete(item.index);
        }

        if (e.detail.id === 'save') {
            handleSaveQuestion(item);
        }

        if (e.detail.id === 'edit') {
            handleQuestionReview(item);
        }
    };

    const cardDefinitions: CardsProps.CardDefinition<GenAiQuestion> = {
        header: (item: GenAiQuestion) => {
            const genAiQuestionCardHeaderProps: GenAiQuetionCardHeaderProps = {
                genAiQuestion: item,
                isEditMode,
            };
            return <GenAiQuestionCardHeader {...genAiQuestionCardHeaderProps} />;
        },
        sections: [
            {
                id: 'answers',
                content: (item) => {
                    if (isEditMode && currentEditIndex === item.index) {
                        const genAiQuestionEditProps: GenAiQuestionEditProps = {
                            genAiQuestion: item,
                            setFormValues,
                            formValues,
                        };

                        return <GenAiQuestionEdit {...genAiQuestionEditProps} />;
                    }
                    return (
                        <Tiles
                            columns={1}
                            onChange={({ detail }) => {}}
                            value={item.answer}
                            items={item.options.map((option) => {
                                return {
                                    value: option,
                                    label: option,
                                    description: (
                                        <>
                                            <br />
                                            <strong>Rationale: </strong>
                                            {item.rationale[option]}
                                        </>
                                    ),
                                };
                            })}
                        />
                    );
                },
            },
            {
                id: 'footer',
                content: (item) => {
                    if (isEditMode && currentEditIndex === item.index) {
                        return (
                            <div className="cards-footer">
                                <Box float="right">
                                    <SpaceBetween size="s" direction="horizontal">
                                        <Button
                                            onClick={() => {
                                                setCurrentEditIndex(0);
                                                setIsEditMode(false);
                                            }}
                                            variant="link"
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            onClick={() => handleEditQuestionsAndSave(item.index)}
                                        >
                                            Save
                                        </Button>
                                    </SpaceBetween>
                                </Box>
                            </div>
                        );
                    }
                    return (
                        <div className="cards-footer">
                            <Box float="right">
                                <ButtonDropdown
                                    items={[
                                        { text: 'Save', id: 'save' },
                                        { text: 'Manually edit', id: 'edit' },
                                        { text: 'Delete', id: 'delete' },
                                    ]}
                                    onItemClick={(e) => handleCardActions(e, item)}
                                >
                                    Actions
                                </ButtonDropdown>
                            </Box>
                        </div>
                    );
                },
            },
        ],
    };
    return (
        <Cards
            selectionType="multi"
            cardDefinition={cardDefinitions}
            trackBy="index"
            items={
                questionFormValues
                    ? questionFormValues.map((questionFormValue, index) => ({
                          index,
                          question: questionFormValue.question,
                          options: questionFormValue.options,
                          rationale: questionFormValue.rationale,
                          answer: questionFormValue.answer,
                          learningObjective: promptFormValues.learningObjectives[0],
                      }))
                    : []
            }
            cardsPerRow={[{ cards: 1 }]}
            onSelectionChange={({ detail }) => setSelectedItems(detail?.selectedItems ?? [])}
            selectedItems={selectedItems}
            stickyHeader
            header={
                <Header
                    actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={() => navigate(QUESTIONS_LIST_ROUTE.path)}>
                                Cancel
                            </Button>
                            <Button onClick={handleSaveQuestions} variant="primary">
                                Save selected questions
                            </Button>
                        </SpaceBetween>
                    }
                    description="Revisions to the question details can be made here."
                >
                    Generated Questions
                </Header>
            }
        />
    );
};

export default GenAiQuestionCards;
