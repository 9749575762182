import React, { useEffect, useMemo, useState } from 'react';
import { Button, Container, Form, Header, SpaceBetween } from '@amzn/awsui-components-react';
import {
    AssessmentInput,
    AssessmentQuestion,
    AssessmentUpdateInput,
    useGetAssessmentQuery,
    useUpdateAssessmentMutation,
} from '../../../graphql';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ASSESSMENT_EDIT_DETAILS_ROUTE, ASSESSMENT_LIST_ROUTE } from '../../../router/router';
import { setBreadcrumbs, setContentType } from '../../../reducers/navigationReducer';
import { useNotifications } from '../../../context/NotificationsProvider';
import { initialAssessmentFormValues } from '../../../common/constants/assessments';
import useFormValidation from '../../../hooks/useFormValidation';
import { AssessmentQuestionList, AssessmentQuestionListProps } from '../../../components';
import AddQuestionModal, { AddQuestionModalProps } from '../../../components/AddQuestionModal';

const AssessmentEditQuestions = () => {
    const { state } = useLocation();
    const { id = '', version, loId = '' } = useParams();
    const dispatch = useDispatch();
    const { addNotification } = useNotifications();
    const navigate = useNavigate();
    const [showAddQuestionsModal, setShowAddQuestionsModal] = useState(false);
    const [selectedQuestions, setSelectedQuestions] = useState(new Set<AssessmentQuestion>());
    const [addQuestionsLearningObjective, setAddQuestionsLearningObjective] = useState('');
    const { isInvalid, validateForm, errors, setErrors } =
        useFormValidation<Partial<AssessmentInput>>();
    const [formValues, setFormValues] = useState<Partial<AssessmentUpdateInput>>(
        state ?? initialAssessmentFormValues,
    );

    const { data, loading } = useGetAssessmentQuery({
        variables: {
            id,
            version: Number(version),
        },
        fetchPolicy: 'cache-and-network',
    });

    const [updateAssessment, { loading: updatingAssessment }] = useUpdateAssessmentMutation();

    const handleFormValueChange = (formUpdates: Partial<AssessmentUpdateInput>) => {
        setFormValues({ ...formValues, ...formUpdates });
    };

    useEffect(() => {
        if (data && !loading) {
            const dataToFormValues: {} = {
                ...data!.assessment!,
            };
            setFormValues(dataToFormValues);
        }
    }, [data, loading]);

    useEffect(() => {
        dispatch(
            setBreadcrumbs([
                {
                    text: ASSESSMENT_LIST_ROUTE.title,
                    href: ASSESSMENT_LIST_ROUTE.path,
                },
                {
                    text: ASSESSMENT_EDIT_DETAILS_ROUTE.title,
                    href: ASSESSMENT_EDIT_DETAILS_ROUTE.path,
                },
            ]),
        );
        dispatch(setContentType('form'));
    }, [dispatch]);

    const runInputValidations = () => {
        let formValidations = {
            isInvalidForm: false,
            isInvalidFormControlArray: false,
        };
        const questionsByLearningObjective = formValues.questions!.filter(
            (question) => question.learningObjectives[0] === loId,
        );
        if (questionsByLearningObjective!.length < formValues.learningObjectives![0].numQuestions) {
            formValidations.isInvalidForm = true;
            setErrors({
                questions: `This learning objective currently requires ${
                    formValues.learningObjectives![0].numQuestions
                } questions. To change the number of questions, edit the learning objective in step one of the creation wizard.`,
            });
        }

        return formValidations;
    };

    const handleEditAssessment = async () => {
        const { isInvalidForm } = runInputValidations();
        if (isInvalidForm) {
            return;
        }
        const {
            id,
            version,
            title,
            status,
            passingScore,
            maxAttempts,
            waitTime,
            timeLimit,
            displaySetting,
            programs,
            questions,
            learningObjectives,
            completionMessages,
            isCorrectAnswersShown,
            isDetailedResultsEnabled,
            isSubmittedResponsesShown,
            questionOrdering,
            scoreDisplay,
            isCategoryScoreEnabled,
            isCopyPasteEnabled,
            isFinalScoreEnabled,
            isFlaggingEnabled,
            isNextBackEnabled,
            isNotesEnabled,
            isPausingEnabled,
        } = formValues;
        try {
            const { data } = await updateAssessment({
                variables: {
                    id: id!,
                    version: version!,
                    title: title!,
                    status: status!,
                    passingScore: passingScore!,
                    maxAttempts: maxAttempts!,
                    waitTime: waitTime!,
                    timeLimit: timeLimit!,
                    displaySetting: displaySetting!,
                    programs: programs!,
                    questions: questions!.map(({ id, learningObjectives }) => ({
                        id,
                        learningObjectives,
                    })),
                    learningObjectives: learningObjectives!.map(({ id, numQuestions }) => ({
                        id,
                        numQuestions,
                    })),
                    completionMessages: completionMessages!.map(
                        ({ maxScore, minScore, message }) => ({ maxScore, minScore, message }),
                    ),
                    isCorrectAnswersShown: isCorrectAnswersShown!,
                    isDetailedResultsEnabled: isDetailedResultsEnabled!,
                    isSubmittedResponsesShown: isSubmittedResponsesShown!,
                    questionOrdering: questionOrdering!,
                    scoreDisplay: scoreDisplay!,
                    isCategoryScoreEnabled: isCategoryScoreEnabled!,
                    isCopyPasteEnabled: isCopyPasteEnabled!,
                    isFinalScoreEnabled: isFinalScoreEnabled!,
                    isFlaggingEnabled: isFlaggingEnabled!,
                    isNextBackEnabled: isNextBackEnabled!,
                    isNotesEnabled: isNotesEnabled!,
                    isPausingEnabled: isPausingEnabled!,
                },
            });
            addNotification({
                id: `create-question-${Date.now()}`,
                ...(data?.updateAssessment
                    ? {
                          type: 'success',
                          content: 'Assessment updated successfully.',
                      }
                    : {
                          type: 'error',
                          content: 'There was an error updating the assessment.',
                      }),
            });
            if (data?.updateAssessment) {
                const assessment = data.updateAssessment;
                navigate(`/assessments/${assessment?.id}/version/${assessment?.version}`, {
                    state: { ...assessment },
                });
            }
        } catch (error) {}
    };

    const handleShowAddQuestionsModal = (learningObjectiveId: string) => {
        setAddQuestionsLearningObjective(learningObjectiveId);
        setShowAddQuestionsModal(true);
    };

    const addQuestionModalProps: AddQuestionModalProps = {
        showAddQuestionsModal,
        setShowAddQuestionsModal,
        formValues,
        handleFormValueChange,
        selectedQuestions,
        setSelectedQuestions,
        learningObjective: addQuestionsLearningObjective,
    };

    const addQuestionListProps: AssessmentQuestionListProps = {
        formValues,
        learningObjectiveId: loId,
        handleFormValueChange,
        handleShowAddQuestionsModal,
        errors,
    };

    return (
        <Form
            actions={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button onClick={() => navigate(-1)} formAction="none" variant="link">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleEditAssessment}
                        disabled={updatingAssessment}
                        variant="primary"
                    >
                        {updatingAssessment ? 'Saving' : 'Save'}
                    </Button>
                </SpaceBetween>
            }
            header={<Header variant="h1">Edit Assessment</Header>}
        >
            <Container
                header={
                    <Header
                        variant="h2"
                        description="Questions can be added for each learning objective below."
                    >
                        Add questions
                    </Header>
                }
            >
                <SpaceBetween direction="vertical" size="l">
                    <AssessmentQuestionList {...addQuestionListProps} />
                    {showAddQuestionsModal && <AddQuestionModal {...addQuestionModalProps} />}
                </SpaceBetween>
            </Container>
        </Form>
    );
};

export default AssessmentEditQuestions;
