import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import {
    Box,
    Button,
    ButtonDropdown,
    Header,
    Link,
    Pagination,
    PropertyFilterProps,
    Select,
    SpaceBetween,
    Table,
    TableProps,
} from '@amzn/awsui-components-react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setBreadcrumbs, setContentType } from '../../../reducers/navigationReducer';
import { QUESTION_CREATE_ROUTE, QUESTIONS_LIST_ROUTE } from '../../../router/router';
import QuestionsListPropertyFilter, {
    LOCAL_STORAGE_QUESTION_FILTER_KEY,
    QuestionsListPropertyFilterProps,
    StoredQuestionFilters,
} from './QuestionsListPropertyFilter';
import {
    AssessmentQuestion,
    useUpdateAssessmentQuestionMutation,
    useGetAssessmentUsersLazyQuery,
} from '../../../graphql/';
import { Dictionary } from '../../../interfaces/dictionary';
import { LegalModal, LegalModalProps } from '../../../components';
import { QuestionStatus } from '../../../common/constants/questions';
import { NodeEnvironment, getNodeEnvironment } from '../../../common/nodeEnvironment';
import { useNotifications } from '../../../context/NotificationsProvider';
import { Workbook } from 'exceljs';
import { ExcelUtil } from '../../../utils/excel';
import { useQuestions } from '../../../hooks/useQuestion';

interface QuestionTableHeaderProps {
    setShowLegalModal: Dispatch<SetStateAction<boolean>>;
    questions: AssessmentQuestion[];
    currentCount: number;
}

const QuestionsTableHeader = ({
    setShowLegalModal,
    questions,
    currentCount,
}: QuestionTableHeaderProps) => {
    const navigate = useNavigate();

    const onButtonItemClick = ({ detail }: any) => {
        if (detail.id === 'create-ai') {
            setShowLegalModal(true);
        } else {
            navigate(QUESTION_CREATE_ROUTE.path);
        }
    };

    const handleExportClick = () => {
        const workbook = new Workbook();
        const excelUtil = new ExcelUtil(workbook);
        excelUtil
            .buildAssessmentQuestionExportWorkbook()
            .populateAssessmentQuestionExportWorkbook(questions)
            .styleWorkbook()
            .cellProtection()
            .writeWorkbook();
    };

    return (
        <Header
            counter={`(${currentCount})`}
            actions={
                <SpaceBetween size="xs" direction="horizontal">
                    <SpaceBetween size="xs" direction="horizontal">
                        <Button onClick={handleExportClick}>Export questions</Button>
                        <ButtonDropdown
                            variant="primary"
                            onItemClick={onButtonItemClick}
                            items={[
                                { text: 'With GenAI', id: 'create-ai', disabled: false },
                                { text: 'With manual entry', id: 'create', disabled: false },
                            ]}
                        >
                            Create question
                        </ButtonDropdown>
                    </SpaceBetween>
                </SpaceBetween>
            }
        >
            Questions
        </Header>
    );
};

const QuestionList = () => {
    const isProd = ![
        NodeEnvironment.LOCAL,
        NodeEnvironment.DEVELOPMENT,
        NodeEnvironment.BETA,
        NodeEnvironment.GAMMA,
    ].includes(getNodeEnvironment());
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showLegalModal, setShowLegalModal] = useState(false);
    const { addNotification } = useNotifications();
    const [query, setQuery] = useState<PropertyFilterProps.Query>({
        tokens: [],
        operation: 'and',
    });
    const [usersInfoDict, setUsersInfoDict] = useState<Dictionary<string>>({});
    const {
        questions,
        learningObjectiveDict,
        getQuestions,
        handlePaginationChange,
        currentPageIndex,
        currentCount,
        pagesCount,
        isLoading,
        getQuestionsByPropertyFilter,
    } = useQuestions();

    const [updateQuestion] = useUpdateAssessmentQuestionMutation();
    const [getUsers] = useGetAssessmentUsersLazyQuery();

    useEffect(() => {
        dispatch(
            setBreadcrumbs([
                {
                    text: QUESTIONS_LIST_ROUTE.title,
                    href: QUESTIONS_LIST_ROUTE.path,
                },
            ]),
        );
        dispatch(setContentType('table'));
    }, [dispatch]);

    useEffect(() => {
        const storedFilterString = localStorage.getItem(LOCAL_STORAGE_QUESTION_FILTER_KEY);

        if (storedFilterString) {
            let savedFilters = JSON.parse(storedFilterString) as StoredQuestionFilters;
            try {
                if (savedFilters.propertyFilters) {
                    setQuery(savedFilters.propertyFilters);
                }
                getQuestionsByPropertyFilter(savedFilters);
            } catch (e) {}
        } else {
            getQuestions();
        }
    }, []);

    useEffect(() => {
        if (questions.length > 0) {
            handleGetUserInfo(questions);
        }
    }, [questions]);

    const getUsersNamesForIds = async (userIds: Set<string>): Promise<any> => {
        const { data } = await getUsers({
            variables: {
                id: Array.from(userIds),
            },
        });
        let userDict = {};
        const users = data?.assessmentUsers?.users;
        if (users) {
            users.forEach((u) => {
                userDict = {
                    ...userDict,
                    [u.id]: u.name,
                };
            });
        }
        return userDict;
    };

    const handleGetUserInfo = async (questions: AssessmentQuestion[]) => {
        const userIds = new Set<string>();
        questions.forEach((q) => {
            q.modifiedBy && userIds.add(q.modifiedBy);
            q.createdBy && userIds.add(q.createdBy);
        });
        const usersInfoDict = await getUsersNamesForIds(userIds);
        setUsersInfoDict(usersInfoDict);
    };

    const handleInlineEdit = async ({
        updatedQuestion,
        column,
        newValue,
        currentTableItem,
    }: {
        updatedQuestion: AssessmentQuestion;
        column: TableProps.ColumnDefinition<AssessmentQuestion>;
        newValue: any;
        currentTableItem: AssessmentQuestion;
    }) => {
        try {
            const {
                id,
                version,
                status,
                type,
                scoringMethod,
                answers,
                difficulty,
                questionText,
                programs,
                learningObjectives,
            } = updatedQuestion;
            const { data } = await updateQuestion({
                variables: {
                    id: id!,
                    version: version!,
                    status: status!,
                    type: type!,
                    scoringMethod: scoringMethod!,
                    answers: answers?.map(({ answerText, isCorrect, explanation }) => ({
                        answerText: answerText!,
                        isCorrect: isCorrect!,
                        explanation: explanation ?? '',
                    }))!,
                    difficulty: difficulty!,
                    questionText: questionText!,
                    programs: programs!,
                    learningObjectives: learningObjectives!,
                },
            });
            // @ts-ignore - TODO Manually updating the table till more research on updating the cache https://www.apollographql.com/docs/react/data/mutations/#updating-the-cache-directly
            currentTableItem[column.id] = newValue;
            addNotification({
                id: `edit-question-${Date.now()}`,
                ...(data?.updateAssessmentQuestion
                    ? {
                          type: 'success',
                          content: 'Question was updated successfully.',
                      }
                    : {
                          type: 'error',
                          content: 'There was an error updating the question.',
                      }),
            });
        } catch (error) {
            addNotification({
                id: `error-edit-question-${Date.now()}`,
                type: 'error',
                content: 'There was an error updating the question.',
            });
        }
    };

    const columnDefinitions = useMemo(() => {
        const cols = [
            {
                id: 'questionText',
                header: 'Question Text',
                cell: (item: AssessmentQuestion) => (
                    <Link
                        variant="secondary"
                        href={`/questions/${item.id}/version/${item.version}`}
                        onFollow={(e) => {
                            e.preventDefault();
                            navigate(`/questions/${item.id}/version/${item.version}`, {
                                state: { ...item },
                            });
                        }}
                    >
                        {item.questionText}
                    </Link>
                ),
            },
            {
                id: 'learningObjective',
                header: 'Learning Objective',
                cell: (item: AssessmentQuestion) =>
                    //@ts-ignore - Ignoring to avoid complicating typing - This is the whole LO meta object
                    item.learningObjectives![0] ? item.learningObjectives![0].name : '',
            },
            {
                id: 'status',
                header: 'Status',
                cell: (item: AssessmentQuestion) => item.status,
                minWidth: 200,
                editConfig: {
                    ariaLabel: 'Type',
                    editIconAriaLabel: 'editable',
                    editingCell: (
                        item: AssessmentQuestion,
                        {
                            currentValue,
                            setValue,
                        }: { currentValue: string; setValue: (item: any) => void },
                    ) => {
                        const value = currentValue ?? item.status;
                        const questionStatusOptions = Object.keys(QuestionStatus).map((prop) => ({
                            value: QuestionStatus[prop as keyof typeof QuestionStatus],
                            label: QuestionStatus[prop as keyof typeof QuestionStatus],
                        }));
                        return (
                            <Select
                                autoFocus={true}
                                expandToViewport={true}
                                selectedOption={
                                    questionStatusOptions.find(
                                        (option) => option.value === value,
                                    ) ?? null
                                }
                                onChange={(event) => {
                                    setValue(event.detail.selectedOption.value ?? currentValue);
                                }}
                                options={questionStatusOptions}
                            />
                        );
                    },
                },
            },
            {
                id: 'type',
                header: 'Type',
                cell: (item: AssessmentQuestion) => item.type,
            },
            {
                id: 'programs',
                header: 'Programs',
                cell: (item: AssessmentQuestion) => item.programs?.join(', '),
            },
            {
                id: 'difficulty',
                header: 'Difficulty',
                cell: (item: AssessmentQuestion) => item.difficulty,
            },
            {
                id: 'scoringMethod',
                header: 'Scoring Method',
                cell: (item: AssessmentQuestion) => item.scoringMethod,
            },
            {
                id: 'actions',
                header: 'Actions',
                cell: (item: AssessmentQuestion) => (
                    <SpaceBetween direction="vertical" size="xs">
                        <Button
                            href={`/questions/${item.id}/version/${item.version}/edit`}
                            onFollow={(e) => {
                                e.preventDefault();
                                navigate(`/questions/${item.id}/version/${item.version}/edit`, {
                                    state: {
                                        ...item,
                                        learningObjectives:
                                            // @ts-ignore
                                            item.learningObjectives!.map((lo) => lo.id),
                                    },
                                });
                            }}
                            variant="inline-link"
                        >
                            Edit
                        </Button>
                    </SpaceBetween>
                ),
            },
        ];
        // Remove once CHIRONASSMNTS-459 is complete
        if (!isProd) {
            const createdByCol = {
                id: 'createdByName',
                header: 'Created By',
                cell: (item: AssessmentQuestion) =>
                    (item.createdBy && usersInfoDict[item.createdBy]) || '',
            };
            const modifiedByCol = {
                id: 'modifiedByName',
                header: 'Modifed By',
                cell: (item: AssessmentQuestion) =>
                    (item.modifiedBy && usersInfoDict[item.modifiedBy]) || '',
            };
            const colsLen = cols.length;
            cols.splice(colsLen - 1, 0, createdByCol, modifiedByCol);
        }
        return cols;
    }, [learningObjectiveDict, usersInfoDict]);

    const questionsListPropertyFilterProps: QuestionsListPropertyFilterProps = {
        query,
        setQuery,
        getQuestionsByPropertyFilter,
        initialLearningObjectiveDict: learningObjectiveDict,
    };

    const questionsTableHeaderProps: QuestionTableHeaderProps = {
        setShowLegalModal,
        questions,
        currentCount,
    };

    const legalModalProps: LegalModalProps = {
        showLegalModal,
        setShowLegalModal,
    };

    return (
        <>
            {showLegalModal && <LegalModal {...legalModalProps} />}
            <Table
                stickyHeader={true}
                header={<QuestionsTableHeader {...questionsTableHeaderProps} />}
                variant="full-page"
                items={questions}
                columnDefinitions={columnDefinitions}
                loading={isLoading}
                loadingText="Loading questions"
                enableKeyboardNavigation
                filter={<QuestionsListPropertyFilter {...questionsListPropertyFilterProps} />}
                pagination={
                    <Pagination
                        currentPageIndex={currentPageIndex}
                        pagesCount={pagesCount}
                        onChange={handlePaginationChange}
                    />
                }
                empty={
                    <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
                        No questions found.
                    </Box>
                }
                stickyColumns={{ first: 0, last: 1 }}
                ariaLabels={{
                    activateEditLabel: (column, item) =>
                        `Edit ${item.questionText} ${column.header}`,
                    cancelEditLabel: (column) => `Cancel editing ${column.header}`,
                    submitEditLabel: (column) => `Submit editing ${column.header}`,
                    tableLabel: 'Question list with inline editing for the question status.',
                }}
                submitEdit={async (
                    item: AssessmentQuestion,
                    column: TableProps.ColumnDefinition<AssessmentQuestion>,
                    newValue: any,
                ) => {
                    const updatedQuestion = {
                        ...item,
                        // @ts-ignore - mapping the back to lo id's
                        learningObjectives: item.learningObjectives!.map((lo) => lo.id),
                        [`${column.id}`]: newValue,
                    };
                    await handleInlineEdit({
                        updatedQuestion,
                        column,
                        newValue,
                        currentTableItem: item,
                    });
                }}
            />
        </>
    );
};

export default QuestionList;
