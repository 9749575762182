import {
    Box,
    Button,
    Header,
    Icon,
    Link,
    Pagination,
    SpaceBetween,
    Table,
    TextFilter,
} from '@amzn/awsui-components-react';
import React, { useEffect, useMemo } from 'react';
import AddUserModal from './addUser/AddUserModal';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { UserMgmtTestIds } from '../../common/dataTestIds/userMgmt';
import { useGetAssessmentUsersLazyQuery, useGetAssessmentUsersNoPiiLazyQuery, AssessmentUser } from '../../graphql';
import { AssessmentUserRolesConfig } from '../../common/constants/users';
import { getTimestampValue } from '../../utils/timeUtils';
import { TABLE_DEFAULT_PAGE_SIZE } from '../../common/constants/tablePreferences';
import { Dictionary } from '../../interfaces/dictionary';

const ASSESSMENT_USERS_POLLING_BATCH_SIZE = 1000;

const EmptyState = ({ displayMessage }: { displayMessage: string }) => {
    return (
        <Box textAlign="center" color="inherit">
            <Box variant="strong" textAlign="center" color="inherit">
                {displayMessage}
            </Box>
        </Box>
    );
};

const blanksPlaceholder = (
    <i data-testid={UserMgmtTestIds.UserListBlankPlaceholders}>Not Available</i>
);

const userDataLoadingPlaceholder = (
    <i data-testid={UserMgmtTestIds.UserListBlankPlaceholders}>Loading...</i>
);


export const UsersList = () => {
    const [getAssessmentUsers] = useGetAssessmentUsersLazyQuery();
    const [getAssessmentUsersNoPii] = useGetAssessmentUsersNoPiiLazyQuery();
    const [showAddUserModal, setShowAddUserModal] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    const [allUsers, setAllUsers] = React.useState([] as AssessmentUser[]);
    const [loadingError, setLoadingError] = React.useState(false);
    type NameAndEmail = { name: string; email: string };
    const [isUserInfoLoading, setIsUserInfoLoading] = React.useState(true);
    const [userIds, setUserIds] = React.useState<Set<string>>();
    let usersInfo: Dictionary<NameAndEmail> = {};

    // A switch to load/reload the users list table. Setting it to true here so that the queries are run on page load here.
    const [loadTableSwitch, setLoadTableSwitch] = React.useState(true);

    useEffect(() => {
        const getUsersNamesForIds = async () => {
            if (!allUsers.length || !userIds) {
                return;
            }
            const { data } = await getAssessmentUsers({
                variables: {
                    id: Array.from(userIds),
                },
                fetchPolicy: 'cache-first',
            });
            const users = data?.assessmentUsers?.users;
            if (users) {
                users.forEach((u) => {
                    usersInfo[u.id] = {
                        name: u.name || '',
                        email: u.email || '',
                    };
                });
                const allUsersUpdated: AssessmentUser[] = [];
                allUsers.forEach((u) => {
                    const name = (usersInfo[u.id] && usersInfo[u.id].name) || "";
                    const email = (usersInfo[u.id] && usersInfo[u.id].email) || "";
                    const createdBy = (u.createdBy && usersInfo[u.createdBy] && usersInfo[u.createdBy].name) || "";
                    const modifiedBy = (u.modifiedBy && usersInfo[u.modifiedBy] && usersInfo[u.modifiedBy].name) || "";
                    allUsersUpdated.push({
                        ...u,
                        name,
                        email,
                        createdBy,
                        modifiedBy,
                    
                    });
                });
                setIsUserInfoLoading(false);
                setAllUsers(allUsersUpdated);
            }
        };
        if (isUserInfoLoading) {
            getUsersNamesForIds();
        }
    }, [userIds]);

    // Loop through and get all users
    useEffect(() => {
        const getAllUsers = async () => {
            try {
                const size = ASSESSMENT_USERS_POLLING_BATCH_SIZE;
                let allFetchedUsers: AssessmentUser[] = [];
                let currentPage = 0;
                let hasMoreData = true;
                let userIds = new Set<string>();
                while (hasMoreData) {
                    const { data, error } = await getAssessmentUsersNoPii({
                        variables: {
                            from: currentPage * size,
                            size: size,
                        },
                        fetchPolicy: loadTableSwitch ? 'network-only' : 'cache-first',
                    });

                    if (error || !data?.assessmentUsers) {
                        throw error ?? new Error('No data returned from getAssessmentUsers');
                    }

                    const { users, totalCount } = data.assessmentUsers;
                    allFetchedUsers = [...allFetchedUsers, ...users];

                    // Check if we've fetched all users
                    hasMoreData = allFetchedUsers.length < totalCount;
                    currentPage++;
                }
                allFetchedUsers.forEach((u) => {
                    u.id && userIds.add(u.id);
                    u.modifiedBy && userIds.add(u.modifiedBy);
                    u.createdBy && userIds.add(u.createdBy);
                });
                setAllUsers(allFetchedUsers);
                setUserIds(userIds);
            } catch (error) {
                // TODO: log this when we have a mechanism for it.

                // Clear all users, better to show an error message than an incomplete list of users.
                setAllUsers([]);
                setLoadingError(true);
            } finally {
                setLoadTableSwitch(false);
                setIsLoading(false);
            }
        };
        if (loadTableSwitch) {
            setIsUserInfoLoading(true);
            getAllUsers();
        }
    }, [loadTableSwitch]);

    const handleAddUserClick = () => {
        setShowAddUserModal(true);
    };

    const handleTableReloadRequest = () => {
        setIsLoading(true);
        setAllUsers([]);
        setLoadTableSwitch(true);
    };

    const columnDefinitions = [
        {
            id: 'email',
            header: 'Email',
            cell: (e: AssessmentUser) => (
                // Use OR operator here so we still get a link that users can click on if empty string is returned
                <Link href={`/users/update/${e.id}`}>{isUserInfoLoading ? userDataLoadingPlaceholder : (e.email || blanksPlaceholder)}</Link>
            ),
            sortingField: 'email',
            isRowHeader: true,
        },
        {
            id: 'name',
            header: 'Name',
            cell: (e: AssessmentUser) => (isUserInfoLoading ? userDataLoadingPlaceholder : e.name),
            sortingField: 'name',
        },
        {
            id: 'programs',
            header: 'Programs',
            cell: (e: AssessmentUser) => (e.programs ? e.programs.join(', ') : blanksPlaceholder),
        },
        {
            id: 'role',
            header: 'Role',
            cell: (e: AssessmentUser) =>
                e.roles.map((role: string) => AssessmentUserRolesConfig[role]?.label),
        },
        {
            id: 'createdBy',
            header: 'Created by',
            cell: (e: AssessmentUser) => (isUserInfoLoading ? userDataLoadingPlaceholder : e.createdBy),
        },
        {
            id: 'createdAt',
            header: 'Created at',
            cell: (e: AssessmentUser) =>
                e.createdTimestamp ? getTimestampValue(e.createdTimestamp) : blanksPlaceholder,
        },
        {
            id: 'modifiedBy',
            header: 'Modified by',
            cell: (e: AssessmentUser) => (isUserInfoLoading ? userDataLoadingPlaceholder : e.modifiedBy),
        },
        {
            id: 'modifiedAt',
            header: 'Modified at',
            cell: (e: AssessmentUser) =>
                e.modifiedTimestamp ? getTimestampValue(e.modifiedTimestamp) : blanksPlaceholder,
            sortingField: 'modifiedTimestamp',
        },
        {
            id: 'status',
            header: 'Status',
            cell: (e: AssessmentUser) => e.status,
        },
    ];

    const { items, filteredItemsCount, collectionProps, filterProps, paginationProps } =
        useCollection(allUsers, {
            filtering: {
                empty: <EmptyState displayMessage="No users" />,
                noMatch: <EmptyState displayMessage="No matches" />,
            },
            pagination: { pageSize: TABLE_DEFAULT_PAGE_SIZE },
            sorting: { defaultState: { sortingColumn: columnDefinitions[7], isDescending: true } },
            selection: {},
        });

    return (
        <>
            <Table
                {...collectionProps}
                renderAriaLive={({ firstIndex, lastIndex, totalItemsCount }) =>
                    `Displaying items ${firstIndex} to ${lastIndex} of ${totalItemsCount}`
                }
                columnDefinitions={columnDefinitions}
                items={items}
                loading={isLoading}
                loadingText="Loading users"
                variant="full-page"
                wrapLines
                empty={
                    <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
                        <SpaceBetween size="m">
                            {loadingError ? (
                                <b data-testid={UserMgmtTestIds.UserListError}>
                                    Something went wrong, please try again later
                                </b>
                            ) : (
                                <b>No users</b>
                            )}
                        </SpaceBetween>
                    </Box>
                }
                filter={
                    <TextFilter
                        data-testid={UserMgmtTestIds.UserListSearchInput}
                        {...filterProps}
                        filteringPlaceholder="Find users"
                        countText={filteredItemsCount?.toString() + ' matches'}
                    />
                }
                header={
                    <Header
                        actions={
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button
                                    variant="normal"
                                    data-testid={UserMgmtTestIds.ReloadButtonId}
                                    onClick={handleTableReloadRequest}
                                >
                                    <Icon variant="normal" name="refresh"></Icon>
                                </Button>
                                <Button
                                    variant="primary"
                                    data-testid={UserMgmtTestIds.AddUserButtonId}
                                    onClick={handleAddUserClick}
                                >
                                    Add new user
                                </Button>
                            </SpaceBetween>
                        }
                        counter={`(${allUsers.length})`}
                    >
                        Manage Users
                    </Header>
                }
                pagination={<Pagination {...paginationProps} />}
            />
            <AddUserModal
                showAddUserModal={showAddUserModal}
                setShowAddUserModal={setShowAddUserModal}
                tableReloadRequestHandler={handleTableReloadRequest}
            />
        </>
    );
};
