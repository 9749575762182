import { PropertyFilter, PropertyFilterProps } from '@amzn/awsui-components-react';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { QuestionDifficulty, QuestionStatus, QuestionType } from '../../common/constants/questions';
import { I18N_STRINGS } from '../../common/constants/propertyFilterKeys';
import { client } from '../../common/client';
import {
    AssessmentMetadataObject,
    GetCurrentUserDocument,
    useGetAssessmentUserLazyQuery,
    useGetLearningObjectivesLazyQuery,
} from '../../graphql';
import { Dictionary } from '../../interfaces/dictionary';
import { truncateText } from '../../utils/appUtils';
import { useQuestionBankContext } from '../../context/QuestionBankProvider';
import { GetQuestionOptions } from '../../hooks/useQuestion';

export interface StoredQuestionFilters {
    propertyFilters?: any;
}

export interface QuestionBankQuestionsPropertyFilterProps {
    query: PropertyFilterProps.Query;
    setQuery: Dispatch<SetStateAction<PropertyFilterProps.Query>>;
    getQuestions: (variables: any, options?: GetQuestionOptions) => void;
    initialLearningObjectiveDict?: Dictionary<AssessmentMetadataObject>;
    questionFilterVariables?: any;
}

const QuestionBankQuestionsPropertyFilter = ({
    query,
    setQuery,
    getQuestions,
    initialLearningObjectiveDict,
    questionFilterVariables,
}: QuestionBankQuestionsPropertyFilterProps) => {
    const [programs, setPrograms] = useState<string[]>([]);
    const [filteringOptions, setFilteringOptions] =
        useState<PropertyFilterProps.FilteringOption[]>();
    const [getAssessmentUser, { data: assessmentUserData }] = useGetAssessmentUserLazyQuery();
    const [learningObjectiveDict, setLearningObjectiveDict] =
        useState<Dictionary<AssessmentMetadataObject>>();
    const [getLearningObjectives, { loading: learningObjectiveLoading }] =
        useGetLearningObjectivesLazyQuery({
            fetchPolicy: 'network-only',
        });
    const { questionBank } = useQuestionBankContext();

    useEffect(() => {
        const readCurrentUser = client.readQuery({
            query: GetCurrentUserDocument,
        });

        if (readCurrentUser && readCurrentUser.currentUser) {
            getAssessmentUser({
                variables: {
                    id: readCurrentUser.currentUser.gandalfDetails.vibeId,
                },
            });
        }

        if (assessmentUserData) {
            setPrograms(assessmentUserData.assessmentUser?.programs!);
        }
    }, [assessmentUserData]);

    useEffect(() => {
        if (initialLearningObjectiveDict) {
            setLearningObjectiveDict(initialLearningObjectiveDict);
        }
    }, [initialLearningObjectiveDict]);

    const questionsFilterProperties: Array<PropertyFilterProps.FilteringProperty> = useMemo(() => {
        return [
            {
                key: 'searchText',
                defaultOperator: ':',
                propertyLabel: 'Text',
                groupValuesLabel: 'Question text value',
            },
            {
                key: 'difficulty',
                operators: ['='],
                propertyLabel: 'Difficulty',
                groupValuesLabel: 'Question difficulty value',
            },
            {
                key: 'status',
                operators: ['='],
                propertyLabel: 'Status',
                groupValuesLabel: 'Question status value',
            },
            {
                key: 'type',
                operators: ['='],
                propertyLabel: 'Type',
                groupValuesLabel: 'Question type value',
            },
            {
                key: 'learningObjectives',
                propertyLabel: 'Learning Objectives',
                groupValuesLabel: 'Learning objective value',
                operators: ['='].map((operator) => ({
                    operator,
                    format: (loId) => {
                        const loName =
                            learningObjectiveDict && learningObjectiveDict[loId]
                                ? learningObjectiveDict[loId].name
                                : '';
                        return learningObjectiveDict ? truncateText(loName, 50) : '';
                    },
                })),
            },
        ];
    }, [learningObjectiveDict]);

    const processTokens = (filters?: any) => {
        if (!filters || filters.propertyFilters.tokens.length === 0) {
            return [];
        }
        return filters.propertyFilters.tokens.map((token: PropertyFilterProps.FilteringOption) => {
            if (token.propertyKey === 'difficulty') {
                return { difficulty: [Number(token.value)] };
            }
            if (token.propertyKey === 'learningObjectives') {
                return { learningObjectives: [token.value] };
            }
            if (token.propertyKey === 'searchText') {
                return { searchText: token.value };
            }
            return { [token.propertyKey]: [token.value] };
        });
    };

    const buildSearchQuery = (tokens: any[]) => {
        return tokens.reduce((acc: any, token: any) => {
            const key = Object.keys(token)[0];
            if (Array.isArray(token[key])) {
                const value = token[key][0];
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(value);
            } else if (typeof token[key] === 'string') {
                if (!acc[key]) {
                    acc[key] = '';
                }
                acc[key] += token[key] + ' ';
            }
            return acc;
        }, {});
    };

    const handleFilterUpdate = async (e: any) => {
        const updatedQuery = { ...e.detail };
        const tokens = processTokens({ propertyFilters: e.detail });
        let searchQuery = buildSearchQuery(tokens);
        if (questionFilterVariables) {
            searchQuery = { ...searchQuery, ...questionFilterVariables };
        }

        const learningObjectiveToken = e.detail.tokens.find(
            (token: any) => token.propertyKey === 'learningObjectives',
        );
        if (learningObjectiveToken) {
            await getQuestions({
                ...searchQuery,
                learningObjectives: updatedQuery.tokens
                    .filter((token: any) => token.propertyKey === 'learningObjectives')
                    .map((lo: any) => lo.value),
            });
        } else {
            await getQuestions({
                ...searchQuery,
                learningObjectives: questionBank?.associatedMetadata
                    ?.filter((am) => am.metadataType === 'LEARNING_OBJECTIVE')
                    .map((lo) => lo.id),
            });
        }

        setQuery(updatedQuery);
    };

    return (
        <div style={{ flex: 1 }}>
            <PropertyFilter
                onChange={handleFilterUpdate}
                query={query}
                expandToViewport
                filteringOptions={filteringOptions}
                filteringProperties={questionsFilterProperties}
                disableFreeTextFiltering={true}
                hideOperations
                i18nStrings={I18N_STRINGS}
                filteringStatusType={learningObjectiveLoading ? 'loading' : 'finished'}
                filteringLoadingText="Loading..."
                onLoadItems={async ({ detail: { filteringProperty } }) => {
                    const difficulty = Object.keys(QuestionDifficulty).filter(
                        (v) => !isNaN(Number(v)),
                    );
                    const status = Object.keys(QuestionStatus).map(
                        (status) => QuestionStatus[status as keyof typeof QuestionStatus],
                    );
                    const type = Object.keys(QuestionType).map(
                        (type) => QuestionType[type as keyof typeof QuestionType],
                    );

                    let filteringOptions = difficulty.map((difficultyLevel) => ({
                        propertyKey: 'difficulty',
                        value: difficultyLevel,
                    }));

                    filteringOptions = [
                        ...filteringOptions,
                        ...status.map((status) => ({
                            propertyKey: 'status',
                            value: status,
                        })),
                        ...type.map((type) => ({
                            propertyKey: 'type',
                            value: type,
                        })),
                    ];
                    if (filteringProperty?.key === 'learningObjectives') {
                        const learningObjectives = await getLearningObjectives({
                            variables: {
                                programs,
                                size: 1000,
                                learningObjectives: questionBank?.associatedMetadata
                                    ?.filter((am) => am.metadataType === 'LEARNING_OBJECTIVE')
                                    .map((lo) => lo.id),
                            },
                        });
                        const loMetadata =
                            learningObjectives.data!.assessmentLearningObjectives.metadataObjects;
                        const filteringPropsWithLOs = [
                            ...filteringOptions!,
                            ...loMetadata.map((lo) => ({
                                propertyKey: 'learningObjectives',
                                value: lo.id,
                                label: lo.name,
                            })),
                        ];
                        setFilteringOptions(filteringPropsWithLOs);
                    } else {
                        setFilteringOptions(filteringOptions);
                    }
                }}
            />
        </div>
    );
};

export default QuestionBankQuestionsPropertyFilter;
