import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    Box,
    Button,
    ColumnLayout,
    Container,
    Header,
    SpaceBetween,
} from '@amzn/awsui-components-react';
import {
    AssessmentInput,
    AssessmentMetadataObject,
    AssessmentQuestion,
    useGetAssessmentQuestionsLazyQuery,
    useGetLearningObjectivesLazyQuery,
} from '../../../../graphql';
import { ValueWithLabel } from '../../../../components';
import { Dictionary } from '../../../../interfaces/dictionary';

export interface WizardReviewAndCreateProps {
    setActiveStepIndex: Dispatch<SetStateAction<number>>;
    formValues: Partial<AssessmentInput>;
}

const WizardReviewAndCreate = ({ setActiveStepIndex, formValues }: WizardReviewAndCreateProps) => {
    const [learningObjectiveDict, setLearningObjectiveDict] =
        useState<Dictionary<AssessmentMetadataObject>>();
    const [questionDict, setQuestionDict] = useState<Dictionary<AssessmentQuestion>>();

    const [questions, setQuestions] = useState<Array<AssessmentQuestion>>([]);

    const [getFilteredQuestions, { loading }] = useGetAssessmentQuestionsLazyQuery({
        fetchPolicy: 'cache-first',
    });
    const [getLearningObjectives, { loading: learningObjectivesLoading }] =
        useGetLearningObjectivesLazyQuery({
            fetchPolicy: 'cache-first',
        });

    useEffect(() => {
        if (formValues.questions!.length > 0) {
            handleGetQuestions();
        }
    }, [formValues.questions]);

    const handleGetQuestions = async () => {
        const formQuestionLearningObjectives = formValues.questions!.map((question) => question.id);
        const formAssessmentLearningObjectives = formValues.learningObjectives!.map((lo) => lo.id);
        const { data } = await getFilteredQuestions({
            variables: {
                id: formQuestionLearningObjectives,
            },
        });
        let tempQuestionDict = {};
        data?.assessmentQuestions?.questions.forEach((question) => {
            // Questions dictionary
            tempQuestionDict = {
                ...tempQuestionDict,
                [question.id]: question,
            };
        });
        setQuestionDict(tempQuestionDict);
        if (data?.assessmentQuestions?.questions.length !== 0) {
            handleGetLearningObjectives([
                ...formQuestionLearningObjectives,
                ...formAssessmentLearningObjectives,
            ]);
        }
    };

    const handleGetLearningObjectives = async (learningObjectives: string[]) => {
        const { data } = await getLearningObjectives({
            variables: {
                learningObjectives,
            },
        });
        let learningObjectiveDict = {};
        data!.assessmentLearningObjectives.metadataObjects.forEach((objective) => {
            // Learning objective dictionary
            learningObjectiveDict = {
                ...learningObjectiveDict,
                [objective.id]: objective,
            };
        });
        const questionsWithLO = questions.map((question) => {
            return {
                ...question,
                learningObjectives: question.learningObjectives?.map(
                    (lo) => learningObjectiveDict[lo as keyof typeof learningObjectiveDict],
                ),
            };
        });
        setQuestions(questionsWithLO);
        setLearningObjectiveDict(learningObjectiveDict);
    };
    return (
        <SpaceBetween size="m">
            <Header
                variant="h3"
                actions={<Button onClick={() => setActiveStepIndex(0)}>Edit</Button>}
            >
                Step 1: Assessment details
            </Header>
            <Container>
                <ColumnLayout columns={2} variant="text-grid">
                    <SpaceBetween size="l">
                        <ValueWithLabel label="Title">{formValues.title}</ValueWithLabel>
                        <ValueWithLabel label="Status">{formValues.status}</ValueWithLabel>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                        {formValues.learningObjectives?.map((lo) => (
                            <ValueWithLabel label="Learning objectives">
                                {learningObjectiveDict?.hasOwnProperty(lo.id)
                                    ? learningObjectiveDict![lo.id].name
                                    : ''}
                            </ValueWithLabel>
                        ))}
                    </SpaceBetween>
                </ColumnLayout>
            </Container>

            <Header
                variant="h3"
                actions={<Button onClick={() => setActiveStepIndex(0)}>Edit</Button>}
            >
                Step 2: Add questions
            </Header>
            {formValues.learningObjectives?.map((lo) => (
                <Container
                    header={
                        <Header variant="h2">
                            {learningObjectiveDict?.hasOwnProperty(lo.id)
                                ? learningObjectiveDict![lo.id].name
                                : ''}
                        </Header>
                    }
                >
                    <ColumnLayout columns={1} variant="text-grid">
                        <SpaceBetween size="l">
                            {formValues.questions
                                ?.filter((question) => question.learningObjectives[0] === lo.id)
                                .map((el, index) => (
                                    <ValueWithLabel label={`Question ${index + 1}`}>
                                        {questionDict?.hasOwnProperty(el.id)
                                            ? questionDict![el.id].questionText
                                            : '-'}
                                    </ValueWithLabel>
                                ))}
                        </SpaceBetween>
                    </ColumnLayout>
                </Container>
            ))}

            <Header
                variant="h3"
                actions={<Button onClick={() => setActiveStepIndex(0)}>Edit</Button>}
            >
                Step 3: Assessment Settings
            </Header>
            <Container>
                <ColumnLayout columns={2} variant="text-grid">
                    <SpaceBetween size="l">
                        <ValueWithLabel label="Question display setting">
                            {formValues.displaySetting}
                        </ValueWithLabel>
                        <ValueWithLabel label="Show correct answers">
                            {formValues.isCorrectAnswersShown}
                        </ValueWithLabel>
                        <ValueWithLabel label="Show detailed results">
                            {formValues.isDetailedResultsEnabled}
                        </ValueWithLabel>
                        <ValueWithLabel label="Show submitted responses">
                            {formValues.isSubmittedResponsesShown}
                        </ValueWithLabel>
                        <ValueWithLabel label="Question ordering">
                            {formValues.questionOrdering}
                        </ValueWithLabel>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                        <ValueWithLabel label="Score display">
                            {formValues.scoreDisplay}
                        </ValueWithLabel>
                        <ValueWithLabel label="Objective score enabled">
                            {formValues.isCategoryScoreEnabled}
                        </ValueWithLabel>
                        <ValueWithLabel label="Copy/Paste enabled">
                            {formValues.isCopyPasteEnabled}
                        </ValueWithLabel>
                        <ValueWithLabel label="Show final score">
                            {formValues.isFinalScoreEnabled}
                        </ValueWithLabel>
                        <ValueWithLabel label="Flag questions enabled">
                            {formValues.isFlaggingEnabled}
                        </ValueWithLabel>
                        <ValueWithLabel label="Back and next buttons enabled">
                            {formValues.isNextBackEnabled}
                        </ValueWithLabel>
                        <ValueWithLabel label="Notes enabled">
                            {formValues.isNotesEnabled}
                        </ValueWithLabel>
                        <ValueWithLabel label="Pause assessment enabled">
                            {formValues.isPausingEnabled}
                        </ValueWithLabel>
                    </SpaceBetween>
                </ColumnLayout>
            </Container>
        </SpaceBetween>
    );
};

export default WizardReviewAndCreate;
