import React from 'react';
import { FormField, Select } from '@amzn/awsui-components-react';
import { handleSelectedOption } from '../../../utils/formUtils';
import { AssessmentInput } from '../../../graphql';
import { AssessmentStatus } from '../../../common/constants/assessments';
import { AssessmentDetailsEditForm } from '../../../common/dataTestIds/assessmentDetails';

export interface AssessmentStatusSelectProps {
    formValues: Partial<AssessmentInput>;
    handleFormValueChange: (formUpdates: Partial<AssessmentInput>) => void;
}

const AssessmentStatusSelect = ({
    formValues,
    handleFormValueChange,
}: AssessmentStatusSelectProps) => {
    const assessmentStatusOptions = Object.keys(AssessmentStatus)
        .filter((prop) => {
            if (formValues.status === AssessmentStatus.PUBLISHED) {
                return (
                    AssessmentStatus[prop as keyof typeof AssessmentStatus] !==
                    AssessmentStatus.DRAFT
                );
            }
            return true;
        })
        .map((prop) => ({
            value: AssessmentStatus[prop as keyof typeof AssessmentStatus],
            label: AssessmentStatus[prop as keyof typeof AssessmentStatus],
        }));

    return (
        <FormField label="Status" data-testid={AssessmentDetailsEditForm.AssessmentStatusLabel}>
            <Select
                selectedOption={handleSelectedOption({
                    selectedOption: formValues.status ?? AssessmentStatus.DRAFT,
                    options: assessmentStatusOptions,
                })}
                onChange={(e) => {
                    handleFormValueChange({
                        status: e.detail.selectedOption.value! as AssessmentStatus,
                    });
                }}
                options={assessmentStatusOptions}
            />
        </FormField>
    );
};

export default AssessmentStatusSelect;
