import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setBreadcrumbs, setContentType } from '../../reducers/navigationReducer';
import { QUESTION_DETAIL_ROUTE, QUESTIONS_LIST_ROUTE } from '../../router/router';
import {
    AssessmentMetadataObject,
    AssessmentQuestion,
    AssessmentQuestionInput,
    useGetAssessmentQuestionQuery,
    useGetLearningObjectivesLazyQuery,
    useGetQuestionBanksLazyQuery,
} from '../../graphql';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Button,
    ColumnLayout,
    CollectionPreferencesProps,
    Container,
    ContentLayout,
    Header,
    SpaceBetween,
    TableProps,
    Tabs,
} from '@amzn/awsui-components-react';
import { AnswerList, ValueWithLabel, AuditLogList, AssessmentList } from '../../components';
import { QuestionDetails } from '../../common/dataTestIds/questionDetails';
import { Dictionary } from '../../interfaces/dictionary';
import { isProd } from '../../common/nodeEnvironment';

const QuestionDetail = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id = '', version } = useParams();
    const [question, setQuestion] = useState<AssessmentQuestion>();
    const [_, setLearningObjectiveDict] = useState({});
    const [questionBankDict, setQuestionBankDict] =
        useState<Dictionary<AssessmentMetadataObject>>();

    const [learningObjectiveName, setLearningObjectiveName] = useState('');

    const { data, loading } = useGetAssessmentQuestionQuery({
        variables: {
            id,
            version: Number(version),
        },
        fetchPolicy: 'cache-and-network',
    });

    const [getLearningObjectives] = useGetLearningObjectivesLazyQuery({
        fetchPolicy: 'network-only',
    });

    const [getQuestionBanks] = useGetQuestionBanksLazyQuery();

    useEffect(() => {
        dispatch(
            setBreadcrumbs([
                {
                    text: QUESTIONS_LIST_ROUTE.title,
                    href: QUESTIONS_LIST_ROUTE.path,
                },
                {
                    text: QUESTION_DETAIL_ROUTE.title,
                    href: QUESTION_DETAIL_ROUTE.path,
                },
            ]),
        );
        dispatch(setContentType('content'));
    }, [dispatch]);

    useEffect(() => {
        if (!loading && data) {
            const question = data.assessmentQuestion!;
            setQuestion(question);
            handleGetLearningObjectives(question);
            handleGetQuestionBanks(question);
        }
    }, [loading, data]);

    const handleGetLearningObjectives = async (question: AssessmentQuestion) => {
        const { data } = await getLearningObjectives({
            variables: {
                learningObjectives: question.learningObjectives,
            },
        });

        let learningObjectiveDict = {};
        data!.assessmentLearningObjectives.metadataObjects.forEach((objective) => {
            // Learning objective dictionary
            learningObjectiveDict = {
                ...learningObjectiveDict,
                [objective.id]: objective,
            };
            setLearningObjectiveName(objective.name);
        });
        setLearningObjectiveDict(learningObjectiveDict);
    };

    const handleGetQuestionBanks = async (question: AssessmentQuestion) => {
        const { data } = await getQuestionBanks({
            variables: {
                questionBanks: question?.questionBanks,
            },
        });

        let questionBankDict = {};

        data!.assessmentQuestionBanks.metadataObjects.forEach((questionBank) => {
            questionBankDict = {
                ...questionBankDict,
                [questionBank.id]: questionBank,
            };
        });
        setQuestionBankDict(questionBankDict);
    };

    const answerListProps = {
        formValues: question as Partial<AssessmentQuestionInput>,
        handleFormValueChange: () => {},
        mode: 'detail',
    };

    const assessmentsListProps = {
        preferencesKey: 'questionAssessmentsTablePreferences',
        columnWidthsKey: 'questionAssessmentsTableColumnWidths',
        variant: 'container' as TableProps.Variant,
        enableAddButton: false,
        description: 'Assessments utilizing this question',
        visibleColumns: ['assessmentTitle', 'assessmentPrograms', 'assessmentStatus'],
        enabledFilters: ['status'],
        defaultFilterCriteria: {
            questions: [question?.id],
        } as { [key: string]: any },
    };

    const tabs = [
        {
            label: 'Details',
            id: 'details',
            content: <AnswerList {...answerListProps} />,
        },
        {
            label: 'Assessments',
            id: 'associatedAssessments',
            content: <AssessmentList {...assessmentsListProps} />,
        },
    ];
    if (!isProd()) {
        tabs.push({
            label: 'Audit Log',
            id: 'auditLog',
            content: <AuditLogList />,
        });
    }
    const QuestionDetailsSection = <Tabs data-testid="question-details-tabs" tabs={tabs} />;

    return (
        <ContentLayout
            header={
                <Header
                    variant="h1"
                    actions={
                        <SpaceBetween size="s" direction="horizontal">
                            <Button
                                key={`edit-question-button`}
                                data-testid="edit-question-button"
                                onClick={() =>
                                    navigate(
                                        `/questions/${question?.id}/version/${question?.version}/edit`,
                                        {
                                            state: { ...question },
                                        },
                                    )
                                }
                            >
                                Edit Question
                            </Button>
                        </SpaceBetween>
                    }
                >
                    Question Details
                </Header>
            }
        >
            <SpaceBetween size="l">
                <Container>
                    <ColumnLayout columns={2} variant="text-grid">
                        <SpaceBetween size="l">
                            <ValueWithLabel
                                testId={QuestionDetails.TitleLabel}
                                label="Question Type"
                            >
                                {question?.type}
                            </ValueWithLabel>
                            <ValueWithLabel
                                testId={QuestionDetails.StatusLabel}
                                label="Question Status"
                            >
                                {question?.status}
                            </ValueWithLabel>
                            <ValueWithLabel
                                testId={QuestionDetails.QuestionTextLabel}
                                label="Question Text"
                            >
                                {question?.questionText}
                            </ValueWithLabel>
                            <ValueWithLabel
                                testId={QuestionDetails.QuestionScoringMethodLabel}
                                label="Scoring Method"
                            >
                                {question?.scoringMethod}
                            </ValueWithLabel>
                        </SpaceBetween>

                        <SpaceBetween size="l">
                            <ValueWithLabel
                                testId={QuestionDetails.QuestionProgramLabel}
                                label="Program"
                            >
                                {question && question.programs!.length
                                    ? question.programs![0]
                                    : ' - '}
                            </ValueWithLabel>
                            <ValueWithLabel
                                testId={QuestionDetails.QuestionDifficultyLabel}
                                label="Difficulty level"
                            >
                                {question?.difficulty}
                            </ValueWithLabel>
                            <ValueWithLabel
                                testId={QuestionDetails.QuestionLearningObjectivesLabel}
                                label="Learning Objectives"
                            >
                                {learningObjectiveName}
                            </ValueWithLabel>
                            <ValueWithLabel
                                testId={QuestionDetails.QuestionQuestionBanksLabel}
                                label="Question banks"
                            >
                                {question?.questionBanks?.length! !== 0
                                    ? question?.questionBanks
                                          ?.map((bank) => questionBankDict?.[bank].name ?? '-')
                                          .join(', ')
                                    : '-'}
                            </ValueWithLabel>
                        </SpaceBetween>
                    </ColumnLayout>
                </Container>
                {QuestionDetailsSection}
            </SpaceBetween>
        </ContentLayout>
    );
};

export default QuestionDetail;
