export enum UserMgmtTestIds {
    UserListLoading = 'user-mgmt-loading',
    UserListAccessDenied = 'user-mgmt-access-denied',
    RemoveUserButtonId = 'remove-user-button-id',
    AddUserButtonId = 'add-user-button-id',
    AddUserModalId = 'add-user-modal-id',
    AddUserFormId = 'add-user-form-id',
    AliasAddUserFormField = 'alias-form-field',
    ProgramsAddUserFormField = 'programs-form-field',
    RolesAddUserFormField = 'role-form-field',
    AddUserFormSubmissionButton = 'add-user-form-submit',
    AddUserFormCancelButton = 'add-user-form-cancel',
}
