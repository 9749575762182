export enum QuestionBankIds {
    // Form field labels
    Name = 'question-bank-name-label',
    Program = 'question-bank-program-label',
    Status = 'question-bank-status-label',
    LearningObjectives = 'question-bank-learning-objectives-label',

    // Form field controls
    NameTextArea = 'question-bank-name-textarea',
    StatusSelect = 'question-bank-status-select',
    ProgramSelect = 'question-bank-program-select',

    // Form field editors
    LearningObjectivesEditor = 'question-bank-learning-objectives-editor',

    // Buttons
    SaveButtonId = 'question-bank-save-button',
    CreateButton = 'question-bank-create-button',
    CancelButton = 'question-bank-cancel-button',

    // Containers
    FormId = 'question-bank-form',
    EditTabs = 'question-bank-edit-tabs',
    DetailTabs = 'question-bank-detail-tabs',
    Table = 'question-bank-table'
}
