import { PropertyFilter, PropertyFilterProps } from '@amzn/awsui-components-react';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import {
    QuestionDifficulty,
    QuestionStatus,
    QuestionType,
} from '../../../common/constants/questions';
import { I18N_STRINGS } from '../../../common/constants/propertyFilterKeys';
import { client } from '../../../common/client';
import {
    AssessmentMetadataObject,
    GetCurrentUserDocument,
    useGetAssessmentUserLazyQuery,
    useGetLearningObjectivesLazyQuery,
} from '../../../graphql';
import { Dictionary } from '../../../interfaces/dictionary';
import { truncateText } from '../../../utils/appUtils';

export const LOCAL_STORAGE_QUESTION_FILTER_KEY = 'assessments-questions-filter-key';
export const LOCAL_STORAGE_ADD_QUESTION_FILTER_KEY = 'assessments-add-questions-filter-key';
export const LOCAL_STORAGE_ADD_QUESTION_TO_QUESTION_BANK_FILTER_KEY =
    'add-question-to-question-bank-key';
export interface StoredQuestionFilters {
    propertyFilters?: any;
}

export interface QuestionsListPropertyFilterProps {
    query: PropertyFilterProps.Query;
    setQuery: Dispatch<SetStateAction<PropertyFilterProps.Query>>;
    getQuestionsByPropertyFilter: (filters: any) => Promise<void>;
    initialLearningObjectiveDict?: Dictionary<AssessmentMetadataObject>;
}

const QuestionsListPropertyFilter = ({
    query,
    setQuery,
    getQuestionsByPropertyFilter,
    initialLearningObjectiveDict,
}: QuestionsListPropertyFilterProps) => {
    const [programs, setPrograms] = useState<string[]>([]);
    const [filteringOptions, setFilteringOptions] =
        useState<PropertyFilterProps.FilteringOption[]>();
    const [getAssessmentUser, { data: assessmentUserData }] = useGetAssessmentUserLazyQuery();
    const [learningObjectiveDict, setLearningObjectiveDict] =
        useState<Dictionary<AssessmentMetadataObject>>();
    const [getLearningObjectives, { loading: learningObjectiveLoading }] =
        useGetLearningObjectivesLazyQuery({
            fetchPolicy: 'network-only',
        });

    useEffect(() => {
        const readCurrentUser = client.readQuery({
            query: GetCurrentUserDocument,
        });

        if (readCurrentUser && readCurrentUser.currentUser) {
            getAssessmentUser({
                variables: {
                    id: readCurrentUser.currentUser.gandalfDetails.vibeId,
                },
            });
        }

        if (assessmentUserData) {
            setPrograms(assessmentUserData.assessmentUser?.programs!);
        }
    }, [assessmentUserData]);

    useEffect(() => {
        if (initialLearningObjectiveDict) {
            setLearningObjectiveDict(initialLearningObjectiveDict);
        }
    }, [initialLearningObjectiveDict]);

    const questionsFilterProperties: Array<PropertyFilterProps.FilteringProperty> = useMemo(() => {
        return [
            {
                key: 'searchText',
                defaultOperator: ':',
                propertyLabel: 'Text',
                groupValuesLabel: 'Question text value',
            },
            {
                key: 'difficulty',
                operators: ['='],
                propertyLabel: 'Difficulty',
                groupValuesLabel: 'Question difficulty value',
            },
            {
                key: 'status',
                operators: ['='],
                propertyLabel: 'Status',
                groupValuesLabel: 'Question status value',
            },
            {
                key: 'type',
                operators: ['='],
                propertyLabel: 'Type',
                groupValuesLabel: 'Question type value',
            },
            {
                key: 'programs',
                operators: ['='],
                propertyLabel: 'Programs',
                groupValuesLabel: 'Program value',
            },
            {
                key: 'learningObjectives',
                propertyLabel: 'Learning Objectives',
                groupValuesLabel: 'Learning objective value',
                operators: ['='].map((operator) => ({
                    operator,
                    format: (loId) => {
                        const loName =
                            learningObjectiveDict && learningObjectiveDict[loId]
                                ? learningObjectiveDict[loId].name
                                : '';
                        return learningObjectiveDict ? truncateText(loName, 50) : '';
                    },
                })),
            },
        ];
    }, [learningObjectiveDict]);

    const handleFilterUpdate = async (e: any) => {
        setQuery(e.detail);
        const storedFilters = localStorage.getItem(LOCAL_STORAGE_QUESTION_FILTER_KEY);
        let newFilter = {} as StoredQuestionFilters;
        storedFilters
            ? (newFilter = {
                  ...JSON.parse(storedFilters),
                  propertyFilters: e.detail,
              })
            : (newFilter = { propertyFilters: e.detail });

        localStorage.setItem(LOCAL_STORAGE_QUESTION_FILTER_KEY, JSON.stringify({ ...newFilter }));
        getQuestionsByPropertyFilter({ propertyFilters: e.detail });
    };

    return (
        <div style={{ flex: 1 }}>
            <PropertyFilter
                onChange={handleFilterUpdate}
                query={query}
                expandToViewport
                filteringOptions={filteringOptions}
                filteringProperties={questionsFilterProperties}
                hideOperations
                disableFreeTextFiltering={true}
                i18nStrings={I18N_STRINGS}
                filteringStatusType={learningObjectiveLoading ? 'loading' : 'finished'}
                filteringLoadingText="Loading..."
                onLoadItems={async ({ detail: { filteringProperty } }) => {
                    const difficulty = Object.keys(QuestionDifficulty).filter(
                        (v) => !isNaN(Number(v)),
                    );
                    const status = Object.keys(QuestionStatus).map(
                        (status) => QuestionStatus[status as keyof typeof QuestionStatus],
                    );
                    const type = Object.keys(QuestionType).map(
                        (type) => QuestionType[type as keyof typeof QuestionType],
                    );

                    let filteringOptions = difficulty.map((difficultyLevel) => ({
                        propertyKey: 'difficulty',
                        value: difficultyLevel,
                    }));

                    let programOptions = [{ propertyKey: 'programs', value: '' }];
                    if (programs.length > 0) {
                        programOptions = programs.map((program) => ({
                            value: program,
                            propertyKey: 'programs',
                        }));
                    }

                    filteringOptions = [
                        ...filteringOptions,
                        ...status.map((status) => ({
                            propertyKey: 'status',
                            value: status,
                        })),
                        ...type.map((type) => ({
                            propertyKey: 'type',
                            value: type,
                        })),
                        ...programOptions,
                    ];
                    if (filteringProperty?.key === 'learningObjectives') {
                        const learningObjectives = await getLearningObjectives({
                            variables: {
                                programs,
                                size: 1000,
                            },
                        });
                        const loMetadata =
                            learningObjectives.data!.assessmentLearningObjectives.metadataObjects;
                        const filteringPropsWithLOs = [
                            ...filteringOptions!,
                            ...loMetadata.map((lo) => ({
                                propertyKey: 'learningObjectives',
                                value: lo.id,
                                label: lo.name,
                            })),
                        ];
                        setFilteringOptions(filteringPropsWithLOs);
                    } else {
                        setFilteringOptions(filteringOptions);
                    }
                }}
            />
        </div>
    );
};

export default QuestionsListPropertyFilter;
