import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { QUESTION_CREATE_GENAI_ROUTE, QUESTIONS_LIST_ROUTE } from '../../../router/router';
import { setBreadcrumbs, setContentType } from '../../../reducers/navigationReducer';
import {
    Button,
    ContentLayout,
    ExpandableSection,
    Form,
    FormField,
    Header,
    Input,
    SpaceBetween,
    Textarea,
} from '@amzn/awsui-components-react';
import { useNavigate } from 'react-router-dom';
import { initialFormValues } from '../../../common/constants/questions';
import {
    AssessmentMetadataObject,
    AssessmentQuestionInput,
    useGetLearningObjectivesQuery,
    usePollAssessmentQuestionsGenerationWorkflowLazyQuery,
    useStartAssessmentQuestionGenerationWorkflowMutation,
} from '../../../graphql';
import useFormValidation from '../../../hooks/useFormValidation';
import {
    GenAiQuestion,
    GenAiQuestionCards,
    LoadingModal,
    LoadingModalProps,
    QuestionBankAttributeEditor,
    QuestionBankAttributeEditorProps,
} from '../../../components';
import { GENAI_PROMPT_VALIDATION_FIELDS } from '../../../common/constants/validations';
import { useNotifications } from '../../../context/NotificationsProvider';
import {
    LearningObjectiveSelect,
    LearningObjectiveSelectProps,
    ProgramSelectProps,
    ProgramSelect,
    QuestionDifficultySelect,
    QuestionDifficultySelectProps,
    QuestionTypeSelect,
    QuestionTypeSelectProps,
} from '../../../components/common/formFields';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { Dictionary } from '../../../interfaces/dictionary';
import { GenAiTestIds } from '../../../common/dataTestIds/genAi';
import { QuestionBankAttributeEditorItem } from '../../../components/common/formFields/QuestionBankAttributeEditor';

const QuestionCreateGenAi = () => {
    const [formValues, setFormValues] = useState(initialFormValues);
    const [questionFormValues, setQuestionFormValues] = useState<GenAiQuestion[]>();
    const [learningObjectiveDict, setLearningObjectiveDict] =
        useState<Dictionary<AssessmentMetadataObject>>();
    const [isLoadingModalVisible, setIsLoadingModalVisible] = useState(false);
    const [numQuestions, setNumQuestions] = useState('1');
    const [hasFormValueQuestions, setHasFormValueQuestions] = useState(false);
    const [additionalContext, setAdditionalContext] = useState('');
    const [questionBankAttributeEditorItems, setQuestionBankAttributeEditorItems] = useState<
        QuestionBankAttributeEditorItem[]
    >([{ id: '' }]);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { addNotification } = useNotifications();

    const { isInvalid, validateForm, errors, setErrors } =
        useFormValidation<Partial<AssessmentQuestionInput>>();
    const [startQuestionGenerationWorkflow] =
        useStartAssessmentQuestionGenerationWorkflowMutation();
    const [pollQuestionGeneratioinWorkflow, { data: pollingData, stopPolling }] =
        usePollAssessmentQuestionsGenerationWorkflowLazyQuery({
            pollInterval: 3000,
        });
    const [learningObjectiveOptions, setLearningObjectiveOptions] = useState<OptionDefinition[]>(
        [],
    );

    const { data, loading } = useGetLearningObjectivesQuery({
        variables: {
            status: 'Active',
            programs: formValues.programs,
            size: 500,
        },
    });

    useEffect(() => {
        if (data && !loading) {
            let options: OptionDefinition[] = [];
            let learningObjectiveDict = {};

            data.assessmentLearningObjectives.metadataObjects.forEach((objective) => {
                // Option definitions
                options.push({
                    value: objective.id,
                    label: objective.name,
                });
                // Learning objective dictionary
                learningObjectiveDict = {
                    ...learningObjectiveDict,
                    [objective.id]: objective,
                };
            });

            setLearningObjectiveOptions(options);
            setLearningObjectiveDict(learningObjectiveDict);
        }
    }, [data]);

    const runInputValidations = () => {
        if (Number(numQuestions) > 10 || Number(numQuestions) < 1) {
            setErrors({
                ...errors,
                // @ts-ignore - Ignoring this property as we just need to add an error to input
                numQuestions: 'Number of questions should be between 1 and 10',
            });
            return true;
        }

        if (
            !learningObjectiveOptions.find(
                (option) => option.value === formValues.learningObjectives![0],
            )
        ) {
            setErrors({
                ...errors,
                // @ts-ignore - Ignoring this property as we just need to add an error to input
                learningObjectives: 'Please select learning objective associated with program',
            });
            return true;
        }

        return validateForm(formValues!, {
            required: GENAI_PROMPT_VALIDATION_FIELDS.REQUIRED,
        });
    };

    useEffect(() => {
        setFormValues((prevValues) => ({ ...prevValues, learningObjectives: [] }));
        setAdditionalContext('');
    }, [formValues.programs]);

    useEffect(() => {
        if (isInvalid) {
            runInputValidations();
        }
    }, [formValues, isInvalid, validateForm, numQuestions]);

    useEffect(() => {
        dispatch(
            setBreadcrumbs([
                {
                    text: QUESTIONS_LIST_ROUTE.title,
                    href: QUESTIONS_LIST_ROUTE.path,
                },
                {
                    text: QUESTION_CREATE_GENAI_ROUTE.title,
                    href: QUESTION_CREATE_GENAI_ROUTE.path,
                },
            ]),
        );
        dispatch(setContentType('form'));
    }, [dispatch]);

    useEffect(() => {
        if (pollingData) {
            const { executionStatus, output } =
                pollingData?.pollAssessmentQuestionsGenerationWorkflow!;
            if (executionStatus === 'SUCCEEDED') {
                stopPolling();
                setQuestionFormValues(JSON.parse(output!).questions);
                setIsLoadingModalVisible(false);
            }
            if (executionStatus === 'FAILED') {
                stopPolling();
                setIsLoadingModalVisible(false);
                addNotification({
                    id: `create-genai-question-${Date.now()}`,

                    type: 'error',
                    content: 'There was an error.',
                });
            }
        }
    }, [pollingData]);

    useEffect(() => {
        if (questionFormValues && questionFormValues.length > 0) {
            setHasFormValueQuestions(true);
        }
    }, [questionFormValues]);

    const handleFormValueChange = (formUpdates: Partial<AssessmentQuestionInput>) => {
        setFormValues({ ...formValues, ...formUpdates });
    };

    const handleStartQuestionGenerationWorkflow = useCallback(async () => {
        const invalid = runInputValidations();
        if (invalid) {
            return;
        }

        setIsLoadingModalVisible(true);
        // Prepare additionalContext for API
        const preparedAdditionalContext =
            additionalContext.trim() !== ''
                ? btoa(encodeURIComponent(additionalContext.trim()))
                : '';
        try {
            const { data, errors } = await startQuestionGenerationWorkflow({
                variables: {
                    numQuestions: parseInt(numQuestions),
                    questionDifficulty: formValues.difficulty!,
                    questionType: formValues.type,
                    learningObjectiveId: formValues.learningObjectives![0],
                    learningObjectiveVersion:
                        learningObjectiveDict![formValues.learningObjectives![0]].version,
                    additionalContext: preparedAdditionalContext,
                },
            });

            if (errors && errors.length > 0) {
                throw new Error(errors[0].message);
            }

            const executionId = data?.startAssessmentQuestionsGenerationWorkflow?.executionId;
            if (executionId) {
                await pollQuestionGeneratioinWorkflow({
                    variables: {
                        executionId,
                    },
                });
            } else {
                throw new Error('Failed to start question generation. Please try again.');
            }
        } catch (error) {
            setIsLoadingModalVisible(false);
            let errorMessage = 'An unexpected error occurred. Please try again.';

            if (error instanceof Error) {
                errorMessage = error.message;
            }

            addNotification({
                id: `generate-questions-error-${Date.now()}`,
                type: 'error',
                content: errorMessage,
            });
        }
    }, [
        additionalContext,
        numQuestions,
        formValues.difficulty,
        formValues.type,
        formValues.learningObjectives,
        learningObjectiveDict,
        startQuestionGenerationWorkflow,
        pollQuestionGeneratioinWorkflow,
        addNotification,
    ]);

    const genAiLoadingModalProps: LoadingModalProps = {
        isLoadingModalVisible,
        setIsLoadingModalVisible,
    };

    const questionDifficultySelectProps: QuestionDifficultySelectProps = {
        formValues,
        handleFormValueChange,
    };

    const questionTypeSelectProps: QuestionTypeSelectProps = {
        formValues,
        handleFormValueChange,
    };

    const learningObjectiveSelectProps: LearningObjectiveSelectProps = {
        formValues,
        handleFormValueChange,
        errors,
        learningObjectiveOptions,
        learningObjectiveDict,
    };

    const programSelectProps: ProgramSelectProps = {
        formValues: formValues,
        handleFormValueChange,
        errors,
    };

    const questionBankAtttributeEditorProps: QuestionBankAttributeEditorProps = {
        handleFormValueChange,
        errors,
        selectedPrograms: formValues.programs,
        questionBankAttributeEditorItems,
        setQuestionBankAttributeEditorItems,
    };

    return (
        <ContentLayout header={<Header variant="h1">{QUESTION_CREATE_GENAI_ROUTE.title}</Header>}>
            <SpaceBetween direction="vertical" size="l">
                <LoadingModal {...genAiLoadingModalProps} />
                <ExpandableSection
                    defaultExpanded={!hasFormValueQuestions}
                    variant="container"
                    headerText="Question details"
                >
                    <Form
                        actions={
                            <>
                                {hasFormValueQuestions ? (
                                    <Button
                                        onClick={handleStartQuestionGenerationWorkflow}
                                        iconName="gen-ai"
                                    >
                                        Regenerate with AI
                                    </Button>
                                ) : (
                                    <SpaceBetween direction="horizontal" size="xs">
                                        <Button
                                            onClick={() => navigate(-1)}
                                            formAction="none"
                                            variant="link"
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            onClick={handleStartQuestionGenerationWorkflow}
                                            iconName="gen-ai"
                                            variant="primary"
                                        >
                                            Generate
                                        </Button>
                                    </SpaceBetween>
                                )}
                            </>
                        }
                    >
                        <SpaceBetween direction="vertical" size="l">
                            <ProgramSelect {...programSelectProps} />
                            {formValues.programs && formValues.programs?.length > 0 && (
                                <LearningObjectiveSelect {...learningObjectiveSelectProps} />
                            )}
                            {formValues.programs && formValues.programs?.length > 0 && (
                                <QuestionBankAttributeEditor
                                    {...questionBankAtttributeEditorProps}
                                />
                            )}
                            {formValues.learningObjectives &&
                                formValues.learningObjectives.length > 0 && (
                                    <FormField
                                        data-testid={GenAiTestIds.AdditionalContentLabel}
                                        label="Additional context"
                                        description="Provide any additional context. Our LLM model will include this, with Learning objective, to generate questions"
                                    >
                                        <Textarea
                                            value={additionalContext}
                                            onChange={({ detail }) => {
                                                // Preserve newlines and tabs, but remove other control characters
                                                setAdditionalContext(detail.value);
                                            }}
                                            placeholder="Enter additional context (optional)"
                                        />
                                    </FormField>
                                )}
                            <QuestionDifficultySelect {...questionDifficultySelectProps} />
                            <FormField
                                data-testid={GenAiTestIds.NumberQuestionsLabel}
                                description="Note: Currently only 10 or less questions is supported."
                                label="Number of questions"
                                // @ts-ignore - Ignoring this property as we just need to add an error to input
                                errorText={errors.numQuestions ?? null}
                            >
                                <Input
                                    onChange={({ detail }) => setNumQuestions(detail.value)}
                                    value={numQuestions}
                                    inputMode="numeric"
                                />
                            </FormField>
                            <QuestionTypeSelect {...questionTypeSelectProps} />
                        </SpaceBetween>
                    </Form>
                </ExpandableSection>

                {questionFormValues && (
                    <GenAiQuestionCards
                        setQuestionFormValues={setQuestionFormValues}
                        questionFormValues={questionFormValues}
                        promptFormValues={formValues}
                    />
                )}
            </SpaceBetween>
        </ContentLayout>
    );
};

export default QuestionCreateGenAi;
