import React from 'react';
import { Box } from '@amzn/awsui-components-react';
import { QuestionDetails } from '../../common/dataTestIds/questionDetails';

interface ValueWithLabelProps {
    label: string;
    children: React.ReactNode;
    testId?: string; // TODO optional for now
}

const ValueWithLabel = ({ testId, label, children }: ValueWithLabelProps) => {
    return (
        <div>
            <Box data-testid={testId} variant="awsui-key-label">
                {label}
            </Box>
            <Box data-testid={`${testId}-value`}>{children?.toString()}</Box>
        </div>
    );
};

export default ValueWithLabel;
