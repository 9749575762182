import * as React from 'react';
import { useEffect, useState } from 'react';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import { Form, FormField, Select } from '@amzn/awsui-components-react';
import {
    AssessmentRoles,
    AssessmentUserRolesConfig,
    assessmentUserStatusConfig,
} from '../../common/constants/users';
import ProgramMultiSelect from '../../components/common/formFields/ProgramMultiSelect';
import { UserMgmtTestIds } from '../../common/dataTestIds/userMgmt';
import {
    AssessmentUser,
    useGetAssessmentUserLazyQuery,
    useUpdateAssessmentUserMutation,
} from '../../graphql';
import { ValueWithLabel } from '../../components';
import { useNavigate, useParams } from 'react-router-dom';
import { Container } from '@amzn/awsui-components-react';
import { handleSelectedOption } from '../../utils/formUtils';
import { useNotifications } from '../../context/NotificationsProvider';
import { useUserInfo } from '../../hooks/useUserInfo';
import { UserMgmtAccessDenied } from '../../components/users/UserMgmtAccessDenied';
import { USERS_LIST_ROUTE } from '../../router/router';
import { ApolloError } from '@apollo/client';
import { ErrorMapper } from '../../utils/errorCodesMapper/errorCodesMapper';

const FEATURE_NAME = 'USER-MGMT';

const UpdateUser = () => {
    const { id = '' } = useParams();
    const navigate = useNavigate();
    const { addNotification } = useNotifications();
    const assessmentRolesOptions = Object.values(AssessmentUserRolesConfig).map((role: any) => {
        return { label: role.label, value: role.value };
    });
    const initialFormState = {
        status: '',
        programs: [''],
        assessmentRole: '',
        name: '',
        email: '',
    };

    const { user: currentUser, loading: loadingCurrentUser } = useUserInfo();
    const [user, setUser] = useState<AssessmentUser>();
    const [getAssessmentUser, { data: userToUpdateData }] = useGetAssessmentUserLazyQuery();
    const [updateUserFormValues, setUpdateUserFormValues] = React.useState(initialFormState);
    const [updateAssessmentUser] = useUpdateAssessmentUserMutation();

    const handleUpdateUserFormValuesChange = (updates: any) => {
        setUpdateUserFormValues({
            ...updateUserFormValues,
            ...updates,
        });
    };
    const handleUpdateUserFormSubmission = async () => {
        try {
            await updateAssessmentUser({
                variables: {
                    id,
                    programs: updateUserFormValues.programs,
                    roles: [updateUserFormValues.assessmentRole],
                    status: updateUserFormValues.status,
                },
            });
            addNotification({
                id: `success-edit-user-${Date.now()}`,
                type: 'success',
                content: `User successfully updated. Please allow a few moments for the changes to sync.`,
            });
            navigate(USERS_LIST_ROUTE.path);
        } catch (error) {
            const displayMessage =
                error instanceof ApolloError
                    ? ErrorMapper.getDisplayErrorMessage(error.message, FEATURE_NAME)
                    : 'Something went wrong, please try again later';

            addNotification({
                id: `error-edit-user-${Date.now()}`,
                type: 'error',
                content: displayMessage,
            });
        }
    };

    useEffect(() => {
        getAssessmentUser({
            variables: {
                id,
            },
        });
        if (userToUpdateData && userToUpdateData.assessmentUser) {
            const assessmentUser = userToUpdateData.assessmentUser;
            setUser(assessmentUser);
            const updatedFormValues = {
                status: assessmentUser.status,
                programs: assessmentUser.programs!,
                assessmentRole: assessmentUser.roles[0],
                name: assessmentUser.name || '',
                email: assessmentUser.email || '',
            };
            setUpdateUserFormValues(updatedFormValues);
        }
    }, [userToUpdateData]);

    return currentUser && currentUser.assessmentRoles?.includes(AssessmentRoles.ADMIN) ? (
        <Container data-testid={UserMgmtTestIds.UpdateUserContainer}>
            <SpaceBetween direction="vertical" size="l">
                <ValueWithLabel data-testid={UserMgmtTestIds.UpdateUserNameLabel} label="Name">
                    {user?.name}
                </ValueWithLabel>
                <ValueWithLabel data-testid={UserMgmtTestIds.UpdateUserEmailLabel} label="Email">
                    {user?.email}
                </ValueWithLabel>
                <Form
                    data-testid={UserMgmtTestIds.UpdateUserFormId}
                    actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={() => navigate(-1)} formAction="none" variant="link">
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                data-testid={UserMgmtTestIds.UpdateUserFormSubmissionButton}
                                onClick={handleUpdateUserFormSubmission}
                                disabled={
                                    loadingCurrentUser ||
                                    (currentUser &&
                                        currentUser.assessmentRoles &&
                                        currentUser.assessmentRoles[0] !== AssessmentRoles.ADMIN)
                                }
                            >
                                Update User
                            </Button>
                        </SpaceBetween>
                    }
                >
                    <>
                        <SpaceBetween direction="vertical" size="l">
                            <ProgramMultiSelect
                                formValues={updateUserFormValues}
                                handleFormValueChange={handleUpdateUserFormValuesChange}
                                errors={{}}
                            />
                            <FormField label="Role">
                                <Select
                                    data-testid={UserMgmtTestIds.RolesUpdateUserFormField}
                                    onChange={({ detail }) => {
                                        handleUpdateUserFormValuesChange({
                                            assessmentRole: detail.selectedOption.value,
                                        });
                                    }}
                                    options={assessmentRolesOptions}
                                    selectedOption={handleSelectedOption({
                                        selectedOption: updateUserFormValues.assessmentRole,
                                        options: assessmentRolesOptions,
                                    })}
                                    placeholder="Select role"
                                />
                            </FormField>
                            <FormField label="Status">
                                <Select
                                    data-testid={UserMgmtTestIds.StatusUpdateUserFormField}
                                    onChange={({ detail }) => {
                                        handleUpdateUserFormValuesChange({
                                            status: detail.selectedOption.value,
                                        });
                                    }}
                                    options={assessmentUserStatusConfig}
                                    selectedOption={handleSelectedOption({
                                        selectedOption: updateUserFormValues.status,
                                        options: assessmentUserStatusConfig,
                                    })}
                                    placeholder="Status"
                                />
                            </FormField>
                        </SpaceBetween>
                    </>
                </Form>
            </SpaceBetween>
        </Container>
    ) : (
        <UserMgmtAccessDenied message="You are not authorized to view this page!" />
    );
};

export default UpdateUser;
