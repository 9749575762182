import React from 'react';
import { Box } from '@amzn/awsui-components-react';

const EmptyState = ({ displayMessage }: { displayMessage: string }) => {
    return (
        <Box variant="strong" textAlign="center" color="inherit">
            {displayMessage}
        </Box>
    );
};

export default EmptyState;
