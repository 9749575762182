import React, { createContext, useContext } from 'react';
import {
    AssessmentMetadataObject,
    AssessmentQuestion,
    UpdateQuestionBankMutation,
    UpdateQuestionBankMutationVariables,
} from '../graphql';
import { useQuestionBanks } from '../hooks/useQuestionBank';
import { FetchResult } from '@apollo/client';

export interface QuestionBankContextType {
    questionBank: AssessmentMetadataObject | undefined;
    questionBanks: AssessmentMetadataObject[];
    associatedMetadata?: AssessmentMetadataObject[];
    updatingQuestionBank: boolean;
    updatingQuestion: boolean;
    handleGetQuestionBank: (id: string, version?: number) => Promise<void>;
    handleGetQuestionBanks: (searchQuery?: any) => Promise<void>;
    handleGetAssociatedMetadata: ({
        associatedMetadataIds,
    }: {
        associatedMetadataIds: string[];
    }) => Promise<void>;
    handleUpdateQuestions: (questions: AssessmentQuestion[]) => Promise<void>;
    handleUpdateQuestionBank: ({
        questionBankId,
        version,
        formValues,
    }: {
        questionBankId: string;
        version: string;
        formValues: Partial<UpdateQuestionBankMutationVariables>;
    }) => Promise<FetchResult<UpdateQuestionBankMutation>>;
}

const QuestionBankContext = createContext<QuestionBankContextType | undefined>(undefined);

export const QuestionBankProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const questionBankData = useQuestionBanks();
    return (
        <QuestionBankContext.Provider value={questionBankData}>
            {children}
        </QuestionBankContext.Provider>
    );
};

export const useQuestionBankContext = () => {
    const context = useContext(QuestionBankContext);
    if (context === undefined) {
        throw new Error('useQuestionBankContext must be used within a QuestionBankProvider');
    }
    return context;
};
